import api from './api';

export default {
  /**
     * Get Security Settings access restrictions.
     */
  getAccessRestrictions() {
    return api()
      .get('/allow-nets')
      .then(response => response.data);
  },

  /**
     * Update access restriction by id.
     * @param params
     * @returns {Promise<T>}
     */
  updateAccessRestriction(params) {
    return api()
      .put(`/allow-nets/${params.netId}`, params)
      .then(response => response.data);
  },

  /**
     * Create access restriction.
     * @param params
     * @returns {Promise<T>}
     */
  createAccessRestriction(params) {
    return api()
      .post('/allow-nets', params)
      .then(response => response.data);
  },

  /**
     * Delete access restriction.
     * @param params
     * @returns {Promise<T>}
     */
  deleteAccessRestriction(params) {
    return api()
      .delete(`/allow-nets/${params.netId}`)
      .then(response => response.data);
  },
};
