<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      persistent
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else-if="entry">
      <v-card-title>
        <span :class="$style.title">{{ entry.courseTitle }} - Course Evaluation</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <div class="mb-4">
          We're excited to hear what you think! Your feedback is important to us.
          Please take a moment to complete. A Course Evaluation submission
          is required in order to receive CE credit.
        </div>
        <div :class="$style.chooseAnswersTitle">
          Choose the best answer for the following:
        </div>

        <v-alert
            v-if="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
            class="mt-2"
        >
          {{ errorMessage }}
        </v-alert>

        <v-form
            ref="evaluationForm"
            @submit.prevent="evaluate"
        >
          <v-simple-table
            class="mb-4"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                  </th>
                  <th
                    class="text-center pl-1 pr-1"
                    style="width: 70px"
                  >
                    Strongly Disagree
                  </th>
                  <th
                      class="text-center pl-1 pr-1"
                      style="width: 70px"
                  >
                    Disagree
                  </th>
                  <th
                      class="text-center pl-1 pr-1"
                      style="width: 70px"
                  >
                    Neutral
                  </th>
                  <th
                      class="text-center pl-1 pr-1"
                      style="width: 70px"
                  >
                    Agree
                  </th>
                  <th
                      class="text-center pl-1 pr-1"
                      style="width: 70px"
                  >
                    Strongly Agree
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr
                    v-for="question in rateQuestions"
                    v-bind:key="'question_' + question.id"
                  >
                    <td>
                      <span>{{ question.question }}</span>
                      <span :class="$style.asterisk">&nbsp;*</span></td>
                    <td class="pl-1 pr-1">
                      <v-radio-group
                        v-model="question.rate"
                        :rules="requiredRules"
                        dense
                        hide-details
                        class="mt-0 pt-0 ml-4"
                      >
                        <v-radio
                            :value="1"
                            :ripple="false"
                        />
                      </v-radio-group>
                    </td>
                    <td class="pl-1 pr-1">
                      <v-radio-group
                          v-model="question.rate"
                          :rules="requiredRules"
                          dense
                          hide-details
                          class="mt-0 pt-0 ml-4"
                      >
                        <v-radio
                            :value="2"
                            :ripple="false"
                        />
                      </v-radio-group>
                    </td>
                    <td class="pl-1 pr-1">
                      <v-radio-group
                          v-model="question.rate"
                          :rules="requiredRules"
                          dense
                          hide-details
                          class="mt-0 pt-0 ml-4"
                      >
                        <v-radio
                            :value="3"
                            :ripple="false"
                        />
                      </v-radio-group>
                    </td>
                    <td class="pl-1 pr-1">
                      <v-radio-group
                          v-model="question.rate"
                          :rules="requiredRules"
                          dense
                          hide-details
                          class="mt-0 pt-0 ml-4"
                      >
                        <v-radio
                            :value="4"
                            :ripple="false"
                        />
                      </v-radio-group>
                    </td>
                    <td class="pl-1 pr-1">
                      <v-radio-group
                          v-model="question.rate"
                          :rules="requiredRules"
                          dense
                          hide-details
                          class="mt-0 pt-0 ml-4"
                      >
                        <v-radio
                            :value="5"
                            :ripple="false"
                        />
                      </v-radio-group>
                    </td>
                  </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-layout
              v-for="question in textQuestions"
              v-bind:key="'question_' + question.id"
              wrap
          >
            <v-flex xs12>
              <span :class="$style.textQuestion">{{ question.question }}</span>
              <span :class="$style.asterisk">&nbsp;*</span>
            </v-flex>
            <v-textarea
                v-model="question.answer"
                :rules="requiredRules"
                :maxlength="1000"
                outlined
                rows="2"
                counter="1000"
                no-resize
            />
          </v-layout>
          <v-layout wrap>
            <v-flex
              xs12
            >
              Please write your First and Last names to acknowledge you have
              completed the evaluation:
            </v-flex>
            <v-text-field
                v-model="firstName"
                :rules="requiredRules"
                label="First Name"
                outlined
                class="mr-2"
            />
            <v-text-field
                v-model="lastName"
                :rules="requiredRules"
                label="Last Name"
                outlined
            />
          </v-layout>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            :disabled="submitting"
            text
            color="blue darken-1"
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
            :loading="submitting"
            color="primary"
            @click="evaluate"
        >
          Submit Evaluation form
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash';
import ceHours from '@/api/ceHours';
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'EvaluateEntryPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      loading: false,
      submitting: false,
      dialog: false,
      firstName: null,
      lastName: null,
      entry: null,
      rateQuestions: [],
      textQuestions: [],
      radioGroup: null,
      requiredRules: [
        value => !!value || 'The field is required',
      ],
    };
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Show dialog.
     */
    async show(entry) {
      this.loading = true;
      this.dialog = true;
      this.entry = entry;
      const questions = await ceHours.getEvaluationQuestions();
      const rateQuestions = [];
      const textQuestions = [];
      questions.forEach(question => {
        const questionModel = _.cloneDeep(question);
        if (question.type === 'rate') {
          questionModel.rate = null;
          rateQuestions.push(questionModel);
        }
        if (question.type === 'answer') {
          questionModel.answer = null;
          textQuestions.push(question);
        }
      });
      this.rateQuestions = rateQuestions;
      this.textQuestions = textQuestions;
      this.loading = false;
    },

    async evaluate() {
      if (!this.$refs.evaluationForm.validate()) {
        return;
      }
      try {
        this.submitting = true;
        const submitData = this.prepareSubmitData();
        await ceHours.evaluateEntry(this.entry.id, submitData);
        this.$emit('evaluated');
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.submitting = false;
      }
    },

    prepareSubmitData() {
      const answers = [];
      this.rateQuestions.forEach(question => {
        answers.push({
          questionId: question.id,
          rate: question.rate,
          answer: null,
        });
      });
      this.textQuestions.forEach(question => {
        answers.push({
          questionId: question.id,
          rate: null,
          answer: question.answer,
        });
      });
      return {
        answers,
        signFirstName: this.firstName,
        signLastName: this.lastName,
      };
    },
  },
};
</script>
<style lang="scss" module>
.title {
  word-break: normal;
}
.chooseAnswersTitle {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.textQuestion {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
.asterisk {
  color: red;
}
</style>
