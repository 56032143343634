export default {
  save: 'Save',
  cancel: 'Cancel',
  delete: 'Delete',
  deactivate: 'Deactivate',
  ok: 'Ok',
  close: 'Close',
  add: '+ ADD',
  export: 'Export',
  exportXls: 'Export XLS',
};
