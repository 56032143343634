<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        overlap
        :content="user.notifications.toString()"
        offset-y="25"
        class="mr-5"
        color="#b3352b"
      >
        <v-tooltip
          v-model="notify"
          nudge-bottom="50"
          :position-x="10000"
          color="primary"
        >
          <span>{{ titleNotification }}</span>
        </v-tooltip>
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="loadFirstPage"
        >
          <v-icon size="25">notifications</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card
      width="40rem"
      :loading="loading"
    >
      <v-card-title
          class="pt-1 pb-1"
          style="font-size: 1rem"
      >Notifications
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-0">
        <v-list
          class="overflow-auto pb-0"
          max-height="23rem"
        >
          <v-list-item v-if="loading && !items.length">
            <v-list-item-content>
              <span style="text-align: center">Loading ...</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else-if="!loading && !items.length">
            <v-list-item-content>
              <span style="text-align: center">No notifications</span>
            </v-list-item-content>
          </v-list-item>
          <div
            v-for="(item, index) in items"
            v-else
            :key="index"
          >
            <component
              v-bind:is="getComponentName(item.type)"
              v-if="componentExists(item.type)"
              :item="item"
              @removeItem="removeNotification"
              @updateNotificationList="updateNotificationList"
            />
            <v-divider/>
          </div>
          <v-list-item
              v-if="items.length < totalItems"
              style="min-height: 1.25rem;"
          >
            <v-list-item-action class="mx-auto my-0">
              <v-btn
                class="mt-1"
                :disabled="loading"
                small
                text
                @click="loadMore"
              >Load more</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { SET_USER_UNREAD_NOTIFICATIONS } from '@/store/mutations';
import notifications from '@/api/notifications';
import DefaultNotification from '@/components/Notifications/NotificationTypes/DefaultNotification';
import UserFlagNotification from '@/components/Notifications/NotificationTypes/UserFlagNotification';
import SharePresetFieldsNotification from '@/components/Notifications/NotificationTypes/SharePresetFieldsNotification';
import CreateCeEntryNotification from '@/components/Notifications/NotificationTypes/CreateCeEntryNotification';
import EvaluateCeEntryNotification from '@/components/Notifications/NotificationTypes/EvaluateCeEntryNotification';
import ApprovedOrRejectedCeEntryNotification from '@/components/Notifications/NotificationTypes/ApprovedOrRejectedCeEntryNotification';

export default {
  name: 'NotificationMenu',

  components: {
    default_notification_type: DefaultNotification,
    user_flag_notification_type: UserFlagNotification,
    share_preset_fields_notification_type: SharePresetFieldsNotification,
    create_ce_entry_notification: CreateCeEntryNotification,
    evaluate_ce_entry_notification: EvaluateCeEntryNotification,
    approved_or_rejected_ce_entry_notification: ApprovedOrRejectedCeEntryNotification,
  },

  data() {
    return {
      notify: false,
      titleNotification: '',
      loading: false,
      limit: 15,
      items: [],
      lastLoadedPage: 0,
      totalItems: 0,
      typeList: {
        DefaultNotification: 'default_notification_type',
        UserFlagNotification: 'user_flag_notification_type',
        SharePresetFieldsNotification: 'share_preset_fields_notification_type',
        CreateEntryNotification: 'create_ce_entry_notification',
        EvaluateEntryNotification: 'evaluate_ce_entry_notification',
        ApprovedEntryNotification: 'approved_or_rejected_ce_entry_notification',
        RejectedEntryNotification: 'approved_or_rejected_ce_entry_notification',
      },
    };
  },

  computed: {
    ...mapState(['user']),

    echoChannel() {
      return this.user ? `user.${this.user.registryId}` : null;
    },
  },

  created() {
    if (this.echoChannel) {
      this.$echo.private(this.echoChannel)
        .notification((notification) => {
          this.setTotalUnread();
          this.titleNotification = notification.title.toString();
          this.notify = true;
          setTimeout(
            () => {
              this.notify = false;
              this.titleNotification = '';
            },
            5000,
          );
        });
    }
  },

  beforeDestroy() {
    this.$echo.leave(this.echoChannel);
  },

  methods: {
    ...mapMutations({
      setUserUnreadNotifications: SET_USER_UNREAD_NOTIFICATIONS,
    }),

    async loadFirstPage() {
      this.items = [];
      this.totalItems = 0;
      this.loading = true;

      try {
        const response = await this.getItemsList(1);
        this.setData(response);
      } finally {
        this.loading = false;
      }
    },

    async loadMore() {
      this.loading = true;

      try {
        const response = await this.getItemsList(this.lastLoadedPage + 1);
        this.setData(response);
      } finally {
        this.loading = false;
      }
    },

    async getItemsList(page) {
      const data = await notifications.getNotifications({ per_page: this.limit, page });
      await this.setTotalUnread();

      return data;
    },

    async setTotalUnread() {
      const data = await notifications.getTotalUnreadNotifications();
      this.setUserUnreadNotifications(data);
    },

    setData(data) {
      data.results.forEach(item => this.items.push(item));
      this.lastLoadedPage = data.pagination.current_page;
      this.totalItems = data.pagination.total;
    },

    getComponentName(type) {
      if (type in this.typeList) {
        return this.typeList[type];
      }

      return this.typeList.DefaultNotification;
    },

    componentExists(type) {
      return Object.prototype.hasOwnProperty.call(
        this.$options.components,
        this.getComponentName(type),
      );
    },

    async removeNotification(item) {
      await notifications.deleteNotification(item.id);
      this.updateNotificationList(item);
    },

    updateNotificationList(item) {
      this.items.splice(this.items.indexOf(item), 1);
      this.totalItems--;
    },
  },
};
</script>
