<template>
  <ce-entry-card
    :loading="loading"
    :data-loaded="dataLoaded"
    :color-number="colorNumber"
    :width="width"
  >
    <template>
      <v-row class="pl-4 text-center">
        <v-col
            md="6"
            class="pb-0 text-center"
        >
          <v-row>
            <v-col class="pt-0 pb-0">
              <span :class="[$style.cardTitle]">Compliance Status</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <span
                v-if="percentage < 100 && !isCurrentPeriod"
                :class="[$style.status, $style.notComplete]"
              >Not Complete</span>
              <span
                  v-if="percentage < 100 && !isShortOfHours && isCurrentPeriod"
                  :class="[$style.status, $style.inProgress]"
              >In Progress</span>
              <span
                  v-if="percentage < 100 && isShortOfHours && isCurrentPeriod"
                  :class="[$style.status, $style.notComplete]"
              >Short of hours</span>
              <span
                  v-if="percentage === 100"
                  :class="[$style.status, $style.completed]"
              >Completed</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col
            md="6"
            class="pb-0 pt-0"
        >
          <vue-apex-charts
              v-if="series.length"
              type="radialBar"
              height="110"
              :options="chartOptions"
              :series="series || []"
          />
        </v-col>
      </v-row>
    </template>
  </ce-entry-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import CeEntryCard from './CeEntryCard';

export default {
  name: 'ComplianceStatusCard',
  components: { VueApexCharts, CeEntryCard },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
    isShortOfHours: {
      type: Boolean,
      default: null,
    },
    isCurrentPeriod: {
      type: Boolean,
      default: null,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    series() {
      return [this.percentage];
    },
    colorNumber() {
      if (this.percentage < 100) {
        if (this.isCurrentPeriod) {
          return this.isShortOfHours ? 3 : 1;
        }
        return 3;
      }
      return 5;
    },
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    chartOptions() {
      return {
        chart: {
          animations: {
            enabled: !this.isPrinting,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '50%',
            },
            track: {
              margin: 7,
              strokeWidth: '90%',
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                offsetY: 4,
                fontSize: '14px',
                fontWeight: 800,
                color: 'white',
              },
            },
          },
        },
        labels: ['Cricket'],
      };
    },
  },
};
</script>
<style lang="scss" module>
.cardTitle {
  font-weight: bolder;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 20px;
}
.status {
  font-weight: bolder;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 18px;
}
.notComplete {
  color: #cc2424;
}
.inProgress {
  color: #1e49bd;
}
.completed {
  color: #0c750f;
}
</style>
