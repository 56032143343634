<template>
  <v-card
    class="agency-overview-userlist"
    :loading="loading"
  >
    <v-row>
      <v-col
          md="3"
          class="pt-0 pb-0 pr-0"
      >
        <div class="lisense-types-tabs">
          <v-tabs
              v-model="tabIndex"
              vertical
              :height="windowHeight - 170"
              background-color="#f5f5f6"
          >
            <v-tab
              v-for="(item, index) in dividedByLicenseUsers"
              :key="'usertab_' + index"
            >
              {{ item.name }}&nbsp;
              <template v-if="item.users.length">({{ item.users.length }})</template>
            </v-tab>
          </v-tabs>
        </div>
      </v-col>

      <v-col
          md="9"
          class="pt-0 pb-0 pl-0"
      >
        <div
            v-bind:style="{ height: (windowHeight - 170) + 'px' }"
        >
          <v-toolbar
              flat
              class="mb-4 ce-userlist-toolbar"
          >
            <date-range-picker-with-periods
                v-model="dateRange"
                class="mr-5"
                position="right"
                label="Expiration Range"
            />

            <v-text-field
                v-model="search"
                label="Search Name"
                class="mr-5"
                hide-details
                style="max-width: 300px"
            />

            <v-checkbox
                v-model="hideUntrackedUsers"
                label="Hide Untracked Users"
                hide-details
                class="float-right"
            ></v-checkbox>
          </v-toolbar>
          <v-tabs-items :value="tabIndex">
            <v-tab-item
              v-for="(item, index) in dividedByLicenseUsers"
              :key="'usersList_' + index"
            >
              <v-data-table
                  :headers="headers"
                  :items="item.users"
                  fixed-header
                  :height="windowHeight - 310"
              >
                <template v-slot:item.name="{ item }">
                  <a
                    v-if="item.periodId"
                    @click="$emit('showUser', item)"
                  >{{ item.name }}</a>
                  <span v-else>{{ item.name }}</span>
                </template>
                <template v-slot:item.periodTo="{ item }">
                  <v-tooltip
                      v-if="item.periodId && item.expired"
                      top
                      max-width="400"
                  >
                    <template v-slot:activator="{ on }">
                      <span
                          v-if="item.periodId"
                          class="period-expired"
                          v-on="on"
                      >
                        {{ item.periodTo | dateFormat }}
                      </span>
                    </template>
                    <span>The education period is expired</span>
                  </v-tooltip>
                  <span
                    v-else-if="item.periodId"
                  >
                    {{ item.periodTo | dateFormat }}
                  </span>
                  <span v-else>—</span>
                </template>
                <template v-slot:item.hours="{ item }">
                  <template v-if="item.periodId">
                    <v-tooltip
                        v-if="item.isShortOfHours"
                        top
                        max-width="400"
                    >
                      <template v-slot:activator="{ on }">
                          <span
                              v-if="item.periodId"
                              class="is-late"
                              v-on="on"
                          >
                            {{ convertNumberToTime(item.hours) }}
                          </span>
                      </template>
                        <span>
                          Is running late to earn the education hours
                        </span>
                    </v-tooltip>
                    <span v-else>{{ convertNumberToTime(item.hours) }}</span>
                  </template>
                  <span v-else>—</span>
                </template>
                <template v-slot:item.remaining="{ item }">
                  <template v-if="item.periodId">
                    <span v-if="item.remaining > 0">
                      <v-tooltip
                          v-if="item.expired"
                          top
                          max-width="400"
                      >
                        <template v-slot:activator="{ on }">
                          <span
                              v-if="item.periodId"
                              class="period-expired"
                              v-on="on"
                          >
                            {{ convertNumberToTime(item.remaining) }}
                          </span>
                        </template>
                        <span>
                          The education period is expired and uncompleted,
                          {{ convertNumberToTime(item.remaining) }} hours left to complete.
                        </span>
                      </v-tooltip>
                      <span v-else>{{ convertNumberToTime(item.remaining) }}</span>
                    </span>
                    <v-tooltip
                        v-else
                        top
                        max-width="400"
                    >
                      <template v-slot:activator="{ on }">
                        <span
                            class="period-completed"
                            v-on="on"
                        >
                          0
                          <span v-if="item.remaining < 0">
                            &nbsp;(extra {{ convertNumberToTime(item.remaining * -1) }})
                          </span>
                        </span>
                      </template>
                      <span>Education completed</span>
                    </v-tooltip>

                  </template>
                  <template v-else>—</template>
                </template>
                <template v-slot:item.skills="{ item }">
                  <template v-if="item.periodId">
                    <template v-if="item.skillsGoal">
                      {{ item.skills || 0 }} / {{ item.skillsGoal }}
                    </template>
                    <template v-else>
                      {{ item.skills || 0 }}
                    </template>
                  </template>
                  <template v-else>—</template>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      v-if="!item.periodId || item.expired"
                      x-small
                      @click="$emit('addPeriodClick', {
                        userId: item.id,
                        licenseTypeId: item.licenseTypeId,
                        period: null
                      })"
                  >Add education period</v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import fixedHeader from '@/mixins/fixedHeader';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import ceHours from '@/api/ceHours';
import { convertNumberToTime } from '@/utils/convertNumberToTime';

export default {
  name: 'UserList',
  components: { DateRangePickerWithPeriods },
  mixins: [fixedHeader],
  data() {
    return {
      convertNumberToTime,
      loading: false,
      tabIndex: 0,
      hideUntrackedUsers: false,
      search: null,
      dateRange: [],
      allUsers: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        { text: 'Expiration', value: 'periodTo' },
        { text: 'Total Hours', value: 'hours' },
        { text: 'Remaining', value: 'remaining' },
        { text: 'Skills', value: 'skills' },
        {
          text: '', value: 'actions', align: 'right', sortable: false,
        },
      ],
    };
  },
  computed: {
    preparedUsers() {
      const prepared = [];
      this.allUsers.forEach(item => {
        const itemPrepared = item;
        const todayMoment = this.getTodayMoment();
        itemPrepared.remaining = item.periodId ? item.goal - item.hours : null;
        itemPrepared.expired = moment.parseZone(item.periodTo).isBefore(todayMoment);
        prepared.push(itemPrepared);
      });
      return prepared;
    },
    dividedByLicenseUsers() {
      const tabsInitial = {
        9925007: {
          name: 'Paramedics',
          users: [],
        },
        9925005: {
          name: 'EMTs',
          users: [],
        },
      };
      let tabs = {};
      if (this.sortedUsers.length) {
        tabs = _.cloneDeep(tabsInitial);
        this.sortedUsers.forEach(item => {
          if (!Object.prototype.hasOwnProperty.call(tabs, item.licenseTypeId)) {
            tabs[item.licenseTypeId] = {
              name: item.licenseType,
              users: [],
            };
          }
          const user = item;
          user.name = `${item.firstName} ${item.lastName}`;
          tabs[item.licenseTypeId].users.push(user);
        });
      }
      return tabs;
    },
    filteredUsers() {
      const filtered = [];
      this.preparedUsers.forEach(item => {
        if (this.hideUntrackedUsers && !item.periodId) {
          return;
        }
        const name = `${item.firstName} ${item.lastName}`;
        if (this.search && name.toLowerCase().indexOf(this.search.toLowerCase()) === -1) {
          return;
        }
        if (this.dateRange && this.dateRange.length === 2) {
          if (!item.periodTo) {
            return;
          }
          let [dateFrom, dateTo] = this.dateRange;
          dateFrom = moment(dateFrom);
          dateTo = moment(dateTo);
          const expiration = moment(item.periodTo);
          if (expiration.isBefore(dateFrom) || expiration.isAfter(dateTo)) {
            return;
          }
        }
        filtered.push(item);
      });
      return filtered;
    },
    sortedUsers() {
      const expireAfterCurrent = [];
      const expiredInThePast = [];
      const noPeriods = [];
      const todayMoment = this.getTodayMoment();
      this.filteredUsers.forEach(item => {
        if (!item.periodId) {
          noPeriods.push(item);
        } else if (moment.parseZone(item.periodTo).isAfter(todayMoment)) {
          expireAfterCurrent.push(item);
        } else {
          expiredInThePast.push(item);
        }
      });
      expireAfterCurrent.sort((a, b) => {
        const first = moment(a.periodTo);
        const second = moment(b.periodTo);
        if (first < second) {
          return -1;
        }
        return (first > second) ? 1 : 0;
      });
      expiredInThePast.sort((a, b) => {
        const first = moment(a.periodTo);
        const second = moment(b.periodTo);
        if (first < second) {
          return 1;
        }
        return (first > second) ? -1 : 0;
      });
      let merged = [];
      merged = expireAfterCurrent.concat(expiredInThePast);
      merged = merged.concat(noPeriods);
      return merged;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getTodayMoment() {
      const now = moment.tz('America/Los_Angeles');
      const todayString = now.format('YYYY-MM-DD');
      return moment.parseZone(todayString);
    },
    async fetchData() {
      try {
        this.loading = true;
        this.tabIndex = 0;
        this.allUsers = await ceHours.getAllUsers();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.agency-overview-userlist {
  .lisense-types-tabs {
    background-color: #f5f5f6;
    padding-left: 10px;
    border-right: 1px solid #e1e1e3;
    .v-tab {
      justify-content: left;
      text-transform: none;
      margin-left: 0px !important;
      max-width: none;
    }
  }
  .ce-userlist-toolbar {
    z-index: 1 !important;
  }
  .v-progress-linear {
    z-index: 2 !important;
  }
  .period-expired {
    color: #e04337;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed #e04337;
  }
  .period-completed {
    color: #098c02;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed #098c02;
  }
  .is-late {
    color: #ffbd2e;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed #ffbd2e;
  }
}
</style>
