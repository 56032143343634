<template>
  <ul :class="isBugs ? $style.bugs : $style.features">
    <li
      v-for="(issue, issueKey) in issues"
      :key="issueKey"
    >
      <release-notes-issue
        :issue="issue"
        :show-task-link="showTaskLink"
      />
      <release-notes-issues-list
        v-if="issue.subtasks"
        :issues="issue.subtasks"
        :is-bugs="isBugs"
        :show-task-link="showTaskLink"
      />
    </li>

  </ul>
</template>

<script>
import ReleaseNotesIssue from './ReleaseNotesIssue';
// eslint-disable-next-line import/no-self-import
import ReleaseNotesIssuesList from './ReleaseNotesIssuesList';

export default {
  name: 'ReleaseNotesIssuesList',

  components: {
    ReleaseNotesIssue,
    ReleaseNotesIssuesList,
  },

  props: {
    issues: {
      type: Object,
      required: true,
    },
    isBugs: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTaskLink: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.features {
  background-color: $blue-light;
}

.bugs {
  background-color: $red-light;
}
</style>
