<template>
  <v-row>
    <v-col md="12">
      Upload files:
    </v-col>
    <v-col
        md="4"
        class="mb-2"
    >
      <upload-button
          ref="uploadBtn"
          title="Choose Files"
          :accept="mimeTypes.join(',')"
          :multiple="true"
          :no-title-update="true"
          :class="[$style.uploadButton]"
          @file-update="update"
      >
        <template v-slot:[buttonSlotName]>
          <v-icon class="mr-1">mdi-file-document-plus-outline</v-icon>
        </template>
      </upload-button>
    </v-col>
    <v-col
        md="8"
        class="mb-4"
    >
      <div
          v-for="item in fileList"
          v-bind:key="item.file"
      >
        <v-chip
            close
            label
            class="mb-2"
            :title="item.name"
            @click:close="$emit('deleteFile', item)"
        >
          <div style="max-width: 280px; text-overflow: ellipsis; overflow: hidden">
            {{ item.name }}
          </div>
        </v-chip>
        <br />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UploadButton from 'vuetify-upload-button';
import { getPresignedData, upload } from '@/api/uploader';

export default {
  name: 'UploadFiles',
  components: {
    UploadButton,
  },
  props: {
    fileList: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      mimeTypes: ['application/pdf', 'image/jpeg', 'image/png'],
      buttonSlotName: 'icon-left',
    };
  },
  methods: {
    /**
     * Update list of files.
     *
     * @param files
     */
    update(files) {
      files.forEach((file) => {
        this.$emit('addNewFile', file);
      });
    },

    /**
     * Delete file from list of files.
     *
     * @param file
     */
    deleteFile(file) {
      this.$emit('deleteFile', file);
    },

    /**
     * Upload list of file in temporary directory.
     *
     * @param files
     *
     * @returns {Promise}
     */
    async uploadFiles(files) {
      const uploadedFiles = await Promise.all(files.map(async (file) => {
        const uploadedFile = await this.uploadFile(file);
        return uploadedFile;
      }));

      return uploadedFiles;
    },

    /**
     * Upload file in temporary directory.
     *
     * @param file
     *
     * @returns {Promise}
     */
    async uploadFile(file) {
      const presignedData = await getPresignedData(file.name);
      await upload(presignedData, file);

      return { name: file.name, file: presignedData.tmpFilename };
    },
  },
};
</script>

<style lang="scss" module>
.uploadButton {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
