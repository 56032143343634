<template>
  <div>
    <div class="ml-3">
      * <span class="ins">Duplicated Incident</span> (<span class="del">Original Incident</span>)
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="paginationOptions"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :height="tableHeight"
      fixed-header
      class="header-nowrap duplicate-incidents-table"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <NoDataAvailable
            :headers="headers"
            :items="items"
          />
          <tr
            v-for="item in items"
            :key="item.id"
            @click="showIncident(item)"
          >
            <td><span>{{ item.client.name }}</span></td>
            <td><span>{{ item.sequenceNumber }}</span></td>
            <td>
              <span :class="isDifferWithOriginal(item, 'incidentDataUpdatedAt') ? 'ins' : ''">
                {{ item.incidentDataUpdatedAt | datetimeToClientTimezone }}
              </span>
              <span v-if="isDifferWithOriginal(item, 'incidentDataUpdatedAt')">
                (<span class="del">
                  <span v-if="item.originalIncident">
                    {{ item.originalIncident.incidentDataUpdatedAt | datetimeToClientTimezone }}
                  </span>
                  <span v-else>N/A</span>
                </span>)
              </span>
            </td>
            <td
              v-for="(field, index) in dataFields"
              :key="index"
            >
              <span :class="isDifferDataWithOriginal(item, field) ? 'ins' : ''">
                {{ item | comparedDuplicateValue(field) }}
              </span>
              <span v-if="isDifferDataWithOriginal(item, field)">
                (<span class="del">{{ item | comparedOrigValue(field) }}</span>)
              </span>
            </td>
            <td>
              <span :class="isDifferWithOriginal(item, 'jsonFileSize') ? 'ins' : ''">
                {{ item.jsonFileSize | humanFileSize }}
              </span>
              <span v-if="isDifferWithOriginal(item, 'jsonFileSize')">
                (<span :class="isDifferWithOriginal(item, 'jsonFileSize') ? 'del' : ''">
                  <span v-if="item.originalIncident">
                    {{ item.originalIncident.jsonFileSize | humanFileSize }}
                  </span>
                  <span v-else>not found</span>
                </span>)
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'FlaggedUsersTable',
  filters: {
    /**
     * Filter for show data from duplicate incident field.
     *
     * @param {Object} item - Duplicated incident object
     * @param {String} name - Field name
     */
    comparedDuplicateValue(item, name) {
      let duplicateValue = item.data[name];
      if (item.originalIncident && item.data[name] !== item.originalIncident.data[name]) {
        duplicateValue = duplicateValue || 'N/A';
      }
      return duplicateValue;
    },

    /**
     * Filter for show data from original incident field.
     *
     * @param {Object} item - Duplicated incident object
     * @param {String} name - Field name
     */
    comparedOrigValue(item, name) {
      if (!item.originalIncident) {
        return 'N/A';
      }
      let origValue = item.originalIncident.data[name];
      if (item[name] !== item.originalIncident.data[name]) {
        origValue = origValue || 'N/A';
      }
      return origValue;
    },
  },
  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader],
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      dataFields: [
        'II_IncidentNumber',
        'II_IncidentStreetName',
        'II_IncidentStreetNumber',
        'PA_Age',
        'PA_Sex',
        'TI_Contact',
        'PI_FirstName',
        'PI_LastName',
      ],
      headers: [
        {
          text: this.$t('duplicateIncidents.tableHeaders.clientName'),
          value: 'client.name',
          sortable: false,
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.sequenceNumber'),
          value: 'sequenceNumber',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.dateTimeSubmission'),
          value: 'incidentDataUpdatedAt',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.incidentNumber'),
          value: 'incidentNumber',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.streetName'),
          value: 'data.II_IncidentStreetName',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.streetNumber'),
          value: 'data.II_IncidentStreetNumber',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.age'),
          value: 'data.PA_Age',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.sex'),
          value: 'data.PA_Sex',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.contact'),
          value: 'data.TI_Contact',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.patientFirstName'),
          value: 'data.PI_FirstName',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.patientLastName'),
          value: 'data.PI_LastName',
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.jsonFileSize'),
          value: 'jsonFileSize',
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    /**
     * Filter for show data from original incident field.
     *
     * @param {Object} incident - Duplicated incident object
     */
    showIncident(incident) {
      const selection = window.getSelection();
      if (selection.type !== 'Range') {
        this.$emit('showIncident', incident);
      }
    },

    /**
     * Check difference of fields between original and duplicate.
     *
     * @param {Object} incident - Duplicated incident object
     * @param {Object} field - Field name
     */
    isDifferWithOriginal(incident, field) {
      return (
        (incident.originalIncident
          && incident[field] !== incident.originalIncident[field])
        || !incident.originalIncident
      );
    },

    /**
     * Check difference of data fields between original and duplicate.
     *
     * @param {Object} incident - Duplicated incident object
     * @param {Object} field - Field name
     */
    isDifferDataWithOriginal(incident, field) {
      return (
        (incident.originalIncident
            && incident.data[field] !== incident.originalIncident.data[field])
        || !incident.originalIncident
      );
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables';

.duplicate-incidents-table tbody tr {
  cursor: pointer;
}

.duplicate-incidents-table tbody tr td span {
  cursor: text;
}

.del {
  color: $diff-del-color;
  background: $diff-del-background-color;
}

.ins {
  color: $diff-ins-color;
  background: $diff-ins-background-color;
}
</style>
