<template>
  <div class="fixed-header">
    <v-layout
      class="pl-9 pr-9"
      row
      wrap
    >
      <v-flex xs4>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search types"
        />
      </v-flex>
      <v-spacer/>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="addItem()"
      >
        {{ $t('buttons.add') }}
      </v-btn>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-container>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-layout wrap>
                <v-flex md12>
                  <v-text-field
                    v-model="type.name"
                    v-validate="'required'"
                    :error-messages="errors.collect('name')"
                    label="Name"
                    name="name"
                    data-vv-as="Name"
                  />
                </v-flex>
              </v-layout>
              <v-alert
                :value="errorAlert"
                type="error"
                outlined
                transition="scale-transition"
              >
                {{ errorMessage }}
              </v-alert>
            </v-card-text>

            <v-card-actions class="pb-0 pt-0">
              <v-spacer/>
              <v-btn
                color="blue darken-1"
                text
                @click.native="close"
              >Cancel
              </v-btn>
              <v-btn
                :loading="loading"
                color="primary"
                text
                @click.native="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-data-table
      ref="dataTable"
      :headers="header"
      :items="hospitalsTypes"
      :search="search"
      :loading="loading"
      :height="tableHeight"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      fixed-header
    >
      <template v-slot:body="{ items }">
        <tbody>
          <v-hover
            v-for="item in items"
            :key="item.name"
          >
            <tr slot-scope="{ hover }">
              <td>{{ item.name }}</td>
              <td class="text-right no-wrap">
                <v-icon
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="editItem(item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showDelete(item)"
                >
                  delete
                </v-icon>
              </td>
            </tr>
          </v-hover>
        </tbody>
      </template>
    </v-data-table>
    <confirmation
      ref="confirmationPopup"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SET_TITLE } from '@/store/mutations';
import { FETCH_DATA } from '@/store/HospitalsTypes/actions';
import {
  updateHospitalsType,
  deleteHospitalsType,
  createHospitalsType,
} from '@/api/hospitalsTypes';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import Confirmation from '@/components/Confirmation';
import i18n from '@/lang/i18n';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'HospitalsTypes',
  components: {
    Confirmation,
  },
  mixins: [errorAlertMixins, fixedHeader],
  data() {
    return {
      dialog: false,
      loading: true,
      search: '',
      header: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          sortable: false,
        },
      ],
      type: {},
      editedIndex: -1,
    };
  },
  computed: {
    ...mapState('hospitalsTypes', ['hospitalsTypes']),
    formTitle() {
      return this.editedIndex === -1
        ? 'Add Hospitals Type'
        : 'Edit Hospitals Type';
    },
  },
  created() {
    this.$validator.localize('en', this.dictionary);
    this.$store.commit(SET_TITLE, this.$store.state.hospitalsTypes.title);
    this.getData();
  },
  methods: {
    ...mapActions({
      FETCH_DATA: `hospitalsTypes/${FETCH_DATA}`,
    }),
    getData() {
      this.FETCH_DATA().then(() => {
        this.loading = false;
      });
    },
    addItem() {
      this.hideError();
      this.type = {};
      this.editedIndex = -1;
      this.dialog = true;
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    editItem(item) {
      this.hideError();
      this.errors.clear();
      this.editedIndex = this.hospitalsTypes.indexOf(item);
      this.type = { ...item };
      this.dialog = true;
    },
    /**
     * Show delete confirmation popup.
     *
     * @param {Object} type - Type object.
     */
    showDelete(type) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('hospitalTypes.deleteConfirmationTitle'),
        i18n.t('hospitalTypes.deleteConfirmationText'),
        async () => {
          await deleteHospitalsType(type.id);
          this.hospitalsTypes.splice(this.hospitalsTypes.indexOf(type), 1);
        },
      );
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.hideError();
    },
    save() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        this.loading = true;
        if (this.editedIndex > -1) {
          updateHospitalsType(this.type.id, this.type)
            .then(() => {
              Object.assign(this.hospitalsTypes[this.editedIndex], this.type);
              this.close();
            })
            .catch(error => {
              this.loading = false;
              this.parseErrorResponse(error.response);
            });
        } else {
          createHospitalsType(this.type)
            .then(response => {
              this.type = response.item;
              this.hospitalsTypes.push(this.type);
              this.close();
            })
            .catch(error => {
              this.loading = false;
              this.parseErrorResponse(error.response);
            });
        }
      });
    },
  },
};
</script>
