<template>
  <v-card flat>
    <v-card-title>Questions to rate:</v-card-title>
    <v-alert
        v-if="errorAlert"
        type="error"
        outlined
        transition="scale-transition"
        class="mt-2"
    >
      {{ errorMessage }}
    </v-alert>
    <v-form ref="questionsForm">
      <v-card-text>
        <ul>
          <v-text-field
              v-for="question in rateQuestions"
              v-bind:key="'questions_id_' + (question.id || question.tmpId)"
              v-model="question.question"
              :rules="questionRules"
              maxlength="1000"
              append-icon="mdi-window-close"
              placeholder="Question"
              @click:append="deleteQuestion(question)"
          />
        </ul>
        <v-btn
            :disabled="loading"
            color="primary"
            text
            @click="addQuestion('rate')"
        >
          + Add Question
        </v-btn>
      </v-card-text>
      <v-card-title>Questions to answer:</v-card-title>
      <v-card-text>
        <ul>
          <v-text-field
              v-for="question in textQuestions"
              v-bind:key="'questions_id_' + (question.id || question.tmpId)"
              v-model="question.question"
              :rules="questionRules"
              maxlength="1000"
              append-icon="mdi-window-close"
              placeholder="Question"
              @click:append="deleteQuestion(question)"
          />
        </ul>
        <v-btn
            :disabled="loading"
            color="primary"
            text
            @click="addQuestion('answer')"
        >
          + Add Question
        </v-btn>
      </v-card-text>
    </v-form>

    <confirmation ref="confirmationPopup" />
  </v-card>
</template>
<script>
import _ from 'lodash';
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import ceHoursApi from '@/api/ceHours';
import ceHoursSettings from '@/api/ceHoursSettings';
import Confirmation from '@/components/Confirmation';

export default {
  name: 'EvaluationQuestions',
  mixins: [componentMixins, errorAlertMixins],
  components: {
    Confirmation,
  },
  data() {
    return {
      settingsData: [],
      settingsDataOriginal: [],
      questionRules: [
        (value) => !!value || 'The question is required',
      ],
    };
  },
  computed: {
    rateQuestions() {
      return this.settingsData.filter(question => question.type === 'rate');
    },
    textQuestions() {
      return this.settingsData.filter(question => question.type === 'answer');
    },
    questionIdIndex() {
      const map = {};
      this.settingsData.forEach((question, index) => {
        const id = question.id || question.tmpId;
        map[id] = index;
      });
      return map;
    },
  },
  mounted() {
    this.fetchEvaluationQuestions();
  },
  methods: {
    async fetchEvaluationQuestions() {
      try {
        this.loading = true;
        const questions = await ceHoursApi.getEvaluationQuestions();
        this.settingsData = _.cloneDeep(questions);
        this.settingsDataOriginal = _.cloneDeep(questions);
      } finally {
        this.loading = false;
      }
    },
    deleteQuestion(question) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'Do you really want to delete the question?',
        async () => {
          const id = question.id || question.tmpId;
          const index = this.questionIdIndex[id];
          this.settingsData.splice(index, 1);
        },
      );
    },
    async save() {
      this.hideError();
      if (!this.$refs.questionsForm || !this.$refs.questionsForm.validate()) {
        return;
      }
      if (!this.settingsData || !this.settingsData.length) {
        this.showError('Please add at least one question.');
        return;
      }
      try {
        this.saving = true;
        await ceHoursSettings.saveEvaluationQuestions(this.settingsData);
        const questions = await ceHoursApi.getEvaluationQuestions();
        this.settingsData = _.cloneDeep(questions);
        this.settingsDataOriginal = _.cloneDeep(questions);
      } finally {
        this.saving = false;
      }
    },
    addQuestion(type) {
      const questions = _.cloneDeep(this.settingsData);
      questions.push({
        id: null,
        question: '',
        type,
        tmpId: Date.now(),
      });
      this.settingsData = questions;
    },
  },
};
</script>
