import api from './api';

export default {
  /**
   * Get uncompleted user.
   *
   * @param {String} token - Token to fetch user
   *
   * @return Promise<Object>
   */
  getUncompletedUser(token) {
    return api()
      .get('/clients/uncompleted-user', { params: { token } })
      .then(response => response.data);
  },

  /**
   * Complete user.
   *
   * @param {Object} user - User object to save
   */
  completeUser(user) {
    return api()
      .post('clients/complete-user', user)
      .then(response => response.data);
  },

  /**
   * Patch user profile.
   *
   * @param {number} userId - Identifier of user.
   * @param {Object} data - Data of user.
   */
  patch(userId, data) {
    return api().patch(`/users/${userId}`, data);
  },

  /**
   * Fetch list of users.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  fetchList(params) {
    return api()
      .get('/users', { params })
      .then(response => response.data);
  },

  /**
   * Get license types of users.
   *
   * @returns Promise<Object>
   */
  getLicenseTypes() {
    return api()
      .get('/users/license-types')
      .then(response => response.data);
  },

  /**
   * Get client's user.
   *
   * @return Promise<Object>
   */
  getClientUsers() {
    return api()
      .get('/client/users')
      .then(response => response.data);
  },

  /**
   * Export users to Excel.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  export(params) {
    return api()
      .get('/users/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Delete user.
   *
   * @param {number} userId - Identifier of user.
   */
  deleteUser(userId) {
    return api()
      .delete(`/users/${userId}`)
      .then(response => response.data);
  },

  /**
   * Restore user.
   *
   * @param {number} userId - Identifier of user.
   */
  restoreUser(userId) {
    return api()
      .post(`/users/${userId}/restore`)
      .then(response => response.data);
  },

  /**
   * Create new user.
   *
   * @param {Object} user - User object
   */
  createUser(user) {
    return api()
      .post('/users', user)
      .then(response => response.data);
  },

  /**
   * Update user.
   *
   * @param {Object} user - User object to save
   */
  saveUser(user) {
    return api()
      .put(`/users/${user.registryId}`, user)
      .then(response => response.data);
  },
};
