<template>
  <v-radio-group
      v-model="textValue"
      row
      :label="label + ':'"
  >
    <v-radio
        label="Yes"
        value="Y"
        @click="setValue('Y')"
    ></v-radio>
    <v-radio
        label="No"
        value="N"
        @click="setValue('N')"
    ></v-radio>
  </v-radio-group>
</template>
<script>
export default {
  name: 'YesNoValue',
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setValue(value) {
      if (this.textValue === value) {
        this.textValue = '';
      } else {
        this.textValue = value;
      }
    },
  },
};
</script>
