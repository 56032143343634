import {
  adminFetchProviders,
  publicFetchProviders,
  fetchProvider,
  fetchSftpStatus,
} from '@/api/cadMonitoring';

import { SET_PROVIDERS, SET_SFTP_STATUS } from './mutations';

export const ADMIN_FETCH_PROVIDERS = 'ADMIN_FETCH_PROVIDERS';
export const PUBLIC_FETCH_PROVIDERS = 'PUBLIC_FETCH_PROVIDERS';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const FETCH_SFTP_STATUS = 'FETCH_SFTP_STATUS';

export default {
  [ADMIN_FETCH_PROVIDERS]: ({ commit }) => adminFetchProviders().then(data => {
    const providers = {};
    Object.keys(data).forEach(key => {
      providers[data[key].clientID] = data[key];
    });
    commit(SET_PROVIDERS, providers);
  }),
  [PUBLIC_FETCH_PROVIDERS]: ({ commit }, filters) => publicFetchProviders(filters).then(data => {
    const providers = {};
    Object.keys(data).forEach(key => {
      providers[data[key].clientID] = data[key];
    });
    commit(SET_PROVIDERS, providers);
  }),

  [UPDATE_PROVIDER]: ({ commit, state }, payload) => fetchProvider(payload.clientID).then(data => {
    const providers = { ...state.providers };
    providers[payload.clientID] = data;
    commit(SET_PROVIDERS, providers);
  }),

  [FETCH_SFTP_STATUS]: ({ commit }) => fetchSftpStatus().then(data => {
    commit(SET_SFTP_STATUS, data);
  }),
};
