<template>
  <v-dialog
    v-model="showEditPopup"
    persistent
    class="hospital-edit"
    max-width="600"
    @close="$emit('close')"
  >
    <v-card v-if="hospital">
      <v-container>
        <v-card-title class="headline pt-0">
          {{ isNew ? $t('hospitals.editPopup.headerNew') : $t('hospitals.editPopup.headerEdit') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div
            v-if="loadingHospital"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              color="grey darken"
              center
              indeterminate
            />
          </div>
          <div v-else>
            <v-alert
                v-model="errorAlert"
                type="error"
                outlined
                dismissible
                transition="scale-transition"
                class="mb-4"
            >
              <div v-html="errorMessage" />
            </v-alert>

            <v-layout wrap>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.name"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.name')"
                  :error-messages="errors.collect('name')"
                  name="name"
                  maxlength="255"
                />
              </v-flex>

              <v-flex
                xs5
                offset-xs2
              >
                <v-text-field
                  v-model="hospital.mapTypeID"
                  v-validate="'required|length:3|validate-hospital-unique:'
                  + (hospital.registryID ? hospital.registryID : '') + ',mapTypeID'"
                  :data-vv-delay="500"
                  :label="$t('hospitals.editPopup.code')"
                  :error-messages="errors.collect('code')"
                  maxlength="3"
                  name="code"
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="hospital.address"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.address')"
                  :error-messages="errors.collect('address')"
                  name="address"
                  maxlength="255"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.city"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.city')"
                  :error-messages="errors.collect('city')"
                  name="city"
                  maxlength="255"
                />
              </v-flex>
              <v-flex
                xs2
                offset-xs2
              >
                <v-select
                  v-model="hospital.stateID"
                  v-validate="'required'"
                  :items="states"
                  :label="$t('hospitals.editPopup.state')"
                  :error-messages="errors.collect('state')"
                  name="state"
                />
              </v-flex>
              <v-flex
                xs2
                offset-xs1
              >
                <v-text-field
                  v-model="hospital.zip"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.zip')"
                  :error-messages="errors.collect('zip')"
                  name="zip"
                  maxlength="5"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.latitude"
                  v-validate="'required|decimal'"
                  :label="$t('hospitals.editPopup.latitude')"
                  :error-messages="errors.collect('latitude')"
                  name="latitude"
                />
              </v-flex>

              <v-flex
                xs5
                offset-xs2
              >
                <v-text-field
                  v-model="hospital.longitude"
                  v-validate="'required|decimal'"
                  :label="$t('hospitals.editPopup.longitude')"
                  :error-messages="errors.collect('longitude')"
                  name="longitude"
                />
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="hospital.types"
                  :items="types"
                  :label="$t('hospitals.editPopup.types')"
                  multiple
                  small-chips
                  deletable-chips
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.email"
                  v-validate="'required|email'"
                  :label="$t('hospitals.editPopup.email')"
                  :error-messages="errors.collect('email')"
                  name="email"
                />
              </v-flex>
              <v-flex
                xs5
                offset-xs2
              >
                <v-checkbox
                  v-model="hospital.sendEmail"
                  :label="$t('hospitals.editPopup.sendEmail')"
                  required
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.phone"
                  v-validate="'required|max:40'"
                  :label="$t('hospitals.editPopup.phone')"
                  :error-messages="errors.collect('phone')"
                  name="phone"
                  counter="40"
                />
              </v-flex>

              <v-flex
                xs5
                offset-xs2
              >
                <v-text-field
                  v-model="hospital.fax"
                  v-validate="'max:40'"
                  :label="$t('hospitals.editPopup.fax')"
                  :error-messages="errors.collect('fax')"
                  name="fax"
                  counter="40"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                    ref="password"
                    v-model="hospital.password"
                    v-validate="{
                    'required': isNew ? true : false,
                    'min': 12,
                    'validate-password' : true
                  }"
                    label="Generic Password"
                    :error-messages="errors.collect('password')"
                    type="password"
                    name="password"
                    prepend-inner-icon="lock"
                />
              </v-flex>

              <v-flex
                  xs5
                  offset-xs2
              >
                <v-text-field
                    v-model="hospital.password_confirmation"
                    v-validate="{
                    'required': hospital.password ? true :false,
                    'confirmed': 'password'
                  }"
                    :label="$t('hospitals.editPopup.retypePassword')"
                    :error-messages="errors.collect('password_confirmation')"
                    type="password"
                    data-vv-as="password"
                    name="password_confirmation"
                    prepend-inner-icon="lock"
                />
              </v-flex>

              <v-flex xs12>
                <v-label color="grey">
                  <i>{{ $t('general.passwordFormat') }}</i>
                </v-label>
              </v-flex>
            </v-layout>
            <div
              v-if="isNew"
              class="mt-10"
            >
              <b class="mt-10">Hospital user credentials:</b>
              <v-divider />
            </div>

            <v-layout
              v-if="isNew"
              wrap
            >
              <v-flex xs12>
                <v-text-field
                  v-model="hospital.userName"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.userName')"
                  :error-messages="errors.collect('userName')"
                  name="userName"
                  maxlength="255"
                  data-vv-as="User Name"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  ref="userPassword"
                  v-model="hospital.userPassword"
                  v-validate="{
                    'required': isNew ? true : false,
                    'min': 12,
                    'validate-password' : true
                  }"
                  :label="$t('hospitals.editPopup.password')"
                  :error-messages="errors.collect('userPassword')"
                  type="password"
                  name="userPassword"
                  data-vv-as="Password"
                  prepend-inner-icon="lock"
                />
              </v-flex>

              <v-flex
                xs5
                offset-xs2
              >
                <v-text-field
                  v-model="hospital.userPassword_confirmation"
                  v-validate="{
                    'required': hospital.userPassword ? true :false,
                    'confirmed': 'userPassword'
                  }"
                  :label="$t('hospitals.editPopup.retypePassword')"
                  :error-messages="errors.collect('userPassword_confirmation')"
                  type="password"
                  data-vv-as="password"
                  name="userPassword_confirmation"
                  prepend-inner-icon="lock"
                />
              </v-flex>

              <v-flex xs12>
                <v-label color="grey">
                  <i>{{ $t('general.passwordFormat') }}</i>
                </v-label>
              </v-flex>

            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            :loading="saving"
            color="primary"
            text
            @click.native="validateAndSave()"
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import { SET_SHOW_EDIT_DIALOG, SET_HOSPITAL } from '@/store/Hospitals/mutations';
import { FETCH_DATA } from '@/store/HospitalsTypes/actions';
import { LOAD_STATES } from '@/store/LookupStates/actions';
import { HOSPITALS_SEARCH } from '@/store/Hospitals/actions';
import hospitalsApi from '@/api/hospitals';
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'HospitalsEditPopup',
  mixins: [errorAlertMixins],

  data() {
    return {
      saving: false,
      dictionary: {
        attributes: {
          code: 'Hospital code',
        },
      },
    };
  },

  computed: {
    ...mapState('hospitals', [
      'selectedHospital',
      'loadingHospital',
    ]),

    /**
     * Show edit popup property.
     */
    showEditPopup: {
      get() {
        return this.$store.state.hospitals.showEditPopup;
      },
      set(value) {
        this.$store.commit(`hospitals/${SET_SHOW_EDIT_DIALOG}`, value);
      },
    },

    /**
     * Hospital object.
     */
    hospital: {
      get() {
        return this.$store.state.hospitals.hospital;
      },
      set(value) {
        this.$store.commit(`hospitals/${SET_HOSPITAL}`, value);
      },
    },

    /**
     * Hospital types.
     */
    types() {
      return this.$store.state.hospitalsTypes.hospitalsTypes.map(type => ({
        text: type.name,
        value: type.id,
      }));
    },

    /**
     * Lookup states.
     */
    states() {
      return this.$store.state.lookupStates.states.map(state => state.stateID);
    },

    /**
     * True if add new hospital.
     */
    isNew() {
      return !this.selectedHospital || !this.selectedHospital.registryID;
    },
  },
  watch: {
    showEditPopup() {
      this.$validator.reset();
    },
  },
  mounted() {
    this.loadTypes();
    this.loadStates();
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapActions({
      loadTypes: `hospitalsTypes/${FETCH_DATA}`,
      loadStates: `lookupStates/${LOAD_STATES}`,
      hospitalsSearch: `hospitals/${HOSPITALS_SEARCH}`,
    }),

    /**
     * Validate hospital values and save if it's ok.
     */
    async validateAndSave() {
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      try {
        this.saving = true;
        const hospital = _.clone(this.hospital);
        hospital.types = hospital.types ? hospital.types.map(type => type.value) : [];

        if (this.selectedHospital && this.selectedHospital.registryID) {
          await hospitalsApi.saveHospital(hospital);
        } else {
          await hospitalsApi.createHospital(hospital);
        }
        this.hospitalsSearch();
        this.showEditPopup = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
