var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transaction-logs-table"},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.entity.items || [],"options":_vm.pagination,"server-items-length":_vm.entity.total,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [10,25,50]
      },"height":_vm.tableHeight,"hide-default-header":"","fixed-header":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,staticClass:"field-col text-left",class:[
                header.sortable ? 'sortable' : '',
                header.value === _vm.pagination.sortBy[0] ? 'active' : '',
                header.align === 'left' ? 'text-left' : '',
                _vm.pagination.sortDesc[0] ? 'desc': 'asc'
              ],style:(header.width ? 'width: ' + header.width : null),on:{"click":function($event){header.sortable ? _vm.changeSort(header.value) : null}}},[_c('span',{domProps:{"innerHTML":_vm._s(header.text)}}),(header.sortable)?_c('v-icon',{staticClass:"v-data-table-header__icon",attrs:{"small":""}},[_vm._v("mdi-arrow-up")]):_vm._e()],1)}),0),_c('tr',_vm._l((headers),function(header){return _c('th',{key:'search_' + header.value,class:['filter-col', (header.value === 'created' ? 'daterangepicker-th' : '')]},[(header.searchable)?[(header.value !== 'created')?_c('v-text-field',{attrs:{"label":header.text,"prepend-inner-icon":"search","clearable":"","hide-details":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e(),(header.value === 'created')?_c('date-range-picker-with-periods',{attrs:{"clearable":true,"label":header.text,"position":"right","hide-details":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}):_vm._e()]:_vm._e()],2)}),0)])]}},{key:"item.sequenceNumber",fn:function({ item }){return [(item.incidentID && _vm.$can('view', 'incident'))?_c('router-link',{attrs:{"to":{ name: _vm.routes.INCIDENT_VIEW, params: { id: item.incidentID }}}},[_c('b',[_vm._v(_vm._s(item.sequenceNumber))])]):_c('b',[_vm._v(_vm._s(item.sequenceNumber))])]}},{key:"item.created",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("datetimeToClientTimezone")(item.created))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }