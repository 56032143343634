<template>
  <v-card
      class="skills-overview-userlist"
      :loading="loading"
  >
    <v-row>
      <v-col
          md="3"
          class="pt-0 pb-0 pr-0"
      >
        <div class="lisense-types-tabs">
          <v-tabs
              v-model="tabIndex"
              vertical
              background-color="#f5f5f6"
          >
            <v-tab
                v-for="(item, index) in dividedByLicenseUsers"
                :key="'usertab_' + index"
            >
              {{ item.name }}&nbsp;
              <template v-if="item.users.length">({{ item.users.length }})</template>
            </v-tab>
          </v-tabs>
        </div>
      </v-col>

      <v-col
          md="9"
          class="pt-0 pb-0 pl-0"
      >
        <div
            class="userslist-container"
        >
          <v-toolbar
              flat
              class="mb-4 ce-skills-overview-toolbar"
          >
            <date-range-picker-with-periods
                v-model="dateRange"
                class="mr-5"
                position="right"
                label="Date Range"
            />

            <v-text-field
                v-model="search"
                label="Search Name"
                class="mr-5"
                hide-details
                style="max-width: 300px"
            />

            <v-checkbox
                v-model="hideNoSkillsUsers"
                label="Hide no skills users"
                hide-details
                class="float-right"
            ></v-checkbox>
            <v-spacer />
            <v-btn
                color="primary"
                :loading="downloading"
                @click="downloadXls"
            >
              Export XLS
            </v-btn>
          </v-toolbar>
          <v-tabs-items :value="tabIndex">
            <v-tab-item
                v-for="(item, index) in dividedByLicenseUsers"
                :key="'usersList_' + index"
            >
              <v-data-table
                  :headers="headers"
                  :items="item.users"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  fixed-header
                  class="userslist-datatable"
              >
                <template v-slot:item.skills="{ item }">
                  <div class="pt-1">
                  <v-chip
                      v-for="itemSkill in item.skills"
                      v-bind:key="'skill_' + itemSkill.id"
                      color="dark-gray"
                      outlined
                      label
                      small
                      class="mr-1 mb-1"
                  >
                  {{ itemSkill.skill }}
                  </v-chip>
                  </div>
                </template>

              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import _ from 'lodash';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import ceHours from '@/api/ceHours';
import { convertNumberToTime } from '@/utils/convertNumberToTime';
import fileDownload from 'js-file-download';

export default {
  name: 'SkillsOverview',
  components: { DateRangePickerWithPeriods },
  filters: {
    skillsSemicolonSeparated(skills) {
      const skillsList = skills.map(skillItem => skillItem.skill);
      return skillsList.join(' | ');
    },
  },
  data() {
    return {
      convertNumberToTime,
      loading: false,
      downloading: false,
      tabIndex: 0,
      hideNoSkillsUsers: false,
      search: null,
      dateRange: [],
      allUsers: [],
      sortBy: 'skillsNumber',
      sortDesc: true,
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          width: 200,
        },
        {
          text: 'Skills Number',
          sortable: true,
          value: 'skillsNumber',
          width: 150,
        },
        {
          text: 'Skills',
          sortable: false,
          value: 'skills',
        },
      ],
    };
  },
  computed: {
    preparedUsers() {
      const prepared = [];
      this.allUsers.forEach(item => {
        const itemPrepared = item;
        itemPrepared.skillsNumber = item.skills.length;
        itemPrepared.name = `${item.firstName} ${item.lastName}`;
        prepared.push(itemPrepared);
      });
      return prepared;
    },
    dividedByLicenseUsers() {
      const tabsInitial = {
        9925007: {
          name: 'Paramedics',
          users: [],
        },
        9925005: {
          name: 'EMTs',
          users: [],
        },
      };
      const tabs = _.cloneDeep(tabsInitial);
      if (this.preparedUsers.length) {
        this.preparedUsers.forEach(item => {
          if (!Object.prototype.hasOwnProperty.call(tabs, item.licenseTypeId)) {
            tabs[item.licenseTypeId] = {
              name: item.licenseType,
              users: [],
            };
          }
        });
      }

      if (this.filteredUsers.length) {
        this.filteredUsers.forEach(item => {
          tabs[item.licenseTypeId].users.push(item);
        });
      }
      return tabs;
    },
    filteredUsers() {
      const filtered = [];
      this.preparedUsers.forEach(item => {
        if (this.hideNoSkillsUsers && !item.skillsNumber) {
          return;
        }
        const name = `${item.firstName} ${item.lastName}`;
        if (this.search && name.toLowerCase().indexOf(this.search.toLowerCase()) === -1) {
          return;
        }
        filtered.push(item);
      });
      return filtered;
    },
  },
  watch: {
    /**
     * Watch dateRange filter and update list
     */
    dateRange: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    updateData() {
      this.fetchData();
    },
    async fetchData() {
      try {
        this.loading = true;
        const params = {
          dateFrom: null,
          dateTo: null,
        };
        if (this.dateRange && this.dateRange.length === 2) {
          // eslint-disable-next-line prefer-destructuring
          params.dateFrom = this.dateRange[0];
          // eslint-disable-next-line prefer-destructuring
          params.dateTo = this.dateRange[1];
        }
        this.allUsers = await ceHours.getUsersSkillsOverview(params);
      } finally {
        this.loading = false;
      }
    },

    async downloadXls() {
      try {
        this.downloading = true;
        const params = {
          dateFrom: null,
          dateTo: null,
          search: this.search,
          hideNoSkillsUsers: this.hideNoSkillsUsers ? 1 : 0,
        };
        if (this.dateRange && this.dateRange.length === 2) {
          // eslint-disable-next-line prefer-destructuring
          params.dateFrom = this.dateRange[0];
          // eslint-disable-next-line prefer-destructuring
          params.dateTo = this.dateRange[1];
        }
        const response = await ceHours.exportUsersSkillsOverview(params);
        fileDownload(response, 'skills_overview.xlsx');
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.skills-overview-userlist {
  .lisense-types-tabs {
    background-color: #f5f5f6;
    padding-left: 10px;
    border-right: 1px solid #e1e1e3;

    .v-item-group {
      height: calc(100vh - 170px);
    }

    .v-tab {
      justify-content: left;
      text-transform: none;
      margin-left: 0px !important;
      max-width: none;
    }
  }
  .userslist-container {
    height: calc(100vh - 170px);
  }
  .userslist-datatable {
    .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
    thead {
      th {
        z-index: 1 !important;
      }
    }
  }

  .ce-skills-overview-toolbar {
    z-index: 2 !important;
  }

  .v-progress-linear {
    z-index: 2 !important;
  }

  .period-expired {
    color: #e04337;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed #e04337;
  }

  .period-completed {
    color: #098c02;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed #098c02;
  }

  .is-late {
    color: #ffbd2e;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed #ffbd2e;
  }
}
</style>
