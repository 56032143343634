<template>
  <v-list-item>
    <item-content :item="item"/>
    <view-flag-item-action
      v-if="!isActiveDelete"
        :item="item"
    />
    <delete-item-action
      :item="item"
      @removeItem="removeItem"
      @isActiveOrInactiveDelete="setIsActiveDelete"
    />
  </v-list-item>
</template>

<script>
import ItemContent from '@/components/Notifications/NotificationTypes/ItemComponents/ItemContent';
import ViewFlagItemAction from '@/components/Notifications/NotificationTypes/ItemComponents/ViewFlagItemAction';
import DeleteItemAction from '@/components/Notifications/NotificationTypes/ItemComponents/DeleteItemAction';

export default {
  name: 'UserFlagNotification',

  components: {
    ItemContent,
    ViewFlagItemAction,
    DeleteItemAction,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isActiveDelete: false,
    };
  },

  methods: {
    setIsActiveDelete(isActive) {
      this.isActiveDelete = isActive;
    },

    removeItem(item) {
      try {
        this.$emit('removeItem', item);
      } finally {
        this.isActiveDelete = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
