import transmittedReports from '@/api/transmittedReports';
import { SET_ITEMS, SET_CLIENTS } from '@/store/TransmittedReports/mutations';

export const LOAD_ITEMS = 'LOAD_ITEMS';
export const LOAD_CLIENTS = 'LOAD_CLIENTS';

export default {
  /**
   * Load items.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_ITEMS]: async ({ commit, state }, payload) => {
    const { pagination } = state;
    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      type: payload.type,
    };

    if (payload.sequenceNumber !== null) {
      params.sequenceNumber = payload.sequenceNumber;
    }

    const results = await transmittedReports.fetchTransmittedReports(params);
    commit(SET_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });
  },

  /**
   * Load clients.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_CLIENTS]: async ({ commit }) => {
    const results = await transmittedReports.fetchClientsList();
    commit(SET_CLIENTS, results);
  },
};
