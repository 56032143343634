import api from './api';

export function getSystemLogs(payload) {
  const {
    logStream: channel,
    search,
    dateFrom,
    dateTo,
    cursor,
  } = payload;
  const params = new URLSearchParams();
  const group = process.env.VUE_APP_LOGS_GROUP;

  params.set('group', group);
  params.set('channel', channel);

  if (search) {
    params.set('search', search);
  }

  if (dateFrom) {
    params.set('dateFrom', dateFrom.toISOString());
  }

  if (dateTo) {
    params.set('dateTo', dateTo.toISOString());
  }

  if (cursor) {
    params.set('cursor', cursor);
  }

  return api('v4')
    .get('/admin/system-logs', { params })
    .then(response => response.data);
}

export default {
  getSystemLogs,
};
