<template>
  <div class="fields-selector">
    <v-tabs
        v-model="activeTab"
        v-ripple="false"
        vertical
    >
      <template v-for="(item, index) in allFields">
        <v-tab
            :key="'item' + index"
            :disabled="!filteredFields[index].length"
        >
          <v-simple-checkbox
              v-model="selectedCategories[index]"
              :indeterminate="!selectedCategories[index] && countSelected[index] ? true : false"
              hide-details
              dense
              @click="selectCategory(index)"
          ></v-simple-checkbox> {{ item.categoryName }}
          <span v-if="countSelected[index]"> ({{ countSelected[index] }})</span>
          <v-icon
              v-if="showHistoricalFields && allFields[index].historicalFields.length"
              color="gray"
          >mdi-history</v-icon>
        </v-tab>
      </template>
      <v-tab-item
          v-for="(fields, index) in groupedFields"
          :key="'fields-item' + index"
      >
        <v-expansion-panels
            v-if="activeTab === parseInt(index) && fields.length"
            accordion
            flat
        >
          <v-expansion-panel
              v-for="(field,i) in fields"
              :key="'field'+i"
              :readonly="!field.fields"
          >
            <v-expansion-panel-header
                :hide-actions="!field.fields"
                @click="!field.fields ? selectField(field.field) : null"
            >
              <v-simple-checkbox
                  v-if="field.fields"
                  v-model="selectedGroupByValues[field.groupId]"
                  hide-details
                  dense
                  :indeterminate="countInGroupByValuesSelected[field.groupId]
                      && countInGroupByValuesSelected[field.groupId] < field.fields.length
                      ? true : false"
                  @click="selectGroup(field.groupId)"
              />
              <v-simple-checkbox
                  v-if="!field.fields"
                  v-model="selectedFields[field.field]"
                  hide-details
                  dense
                  :disabled="locked.indexOf(field.field) !== -1"
                  @click="selectField(field.field)"
              />
              <span>
                    <span
                        :class="[
                        (locked.indexOf(field.field) !== -1 ? 'locked-field' : null)
                        ]"
                    >{{ field.name }}
                    </span>
                    <span v-if="field.fields">
                      ({{ countInGroupByValuesSelected[field.groupId] }}/{{ field.fields.length }})
                    </span>
                  </span>

              <v-chip
                  v-if="field.isHistorical"
                  class="ma-2"
                  small
                  :ripple="false"
              >
                v{{ field.xsdVersion }}
              </v-chip>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="field.fields">
              <div class="group-fields-container">
                <v-row
                    v-for="(groupedField, j) in field.fields"
                    :key="'groupedField'+j"
                >
                  <v-simple-checkbox
                      v-model="selectedFields[groupedField.field]"
                      :class="[(j===0 ? 'mt-0' : '')]"
                      @click="selectField(groupedField.field)"
                  ></v-simple-checkbox>
                  <span
                      class="checkbox-label"
                      @click="selectField(groupedField.field)"
                  >{{ groupedField.name }}</span>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
            v-else-if="!fields.length"
            class="fluid text-center mt-10"
        >
          Fields not found</div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import _ from 'lodash';
import FIELDS_GROUP_BY from '@/enums/fieldsGroupBy';

export default {
  props: {
    allFields: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    showHistoricalFields: {
      type: Boolean,
      required: true,
    },
    groupBy: {
      type: String,
      required: true,
    },
    locked: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    /**
     * List of merged current and historical fields, depends of flag show historical or not.
     *
     * @return {Array}
     */
    mergedFields() {
      const merged = [];
      this.allFields.forEach((categoryItem, index) => {
        merged[index] = _.clone(categoryItem.currentFields);
        if (this.showHistoricalFields) {
          categoryItem.historicalFields.forEach(historicalField => {
            const field = _.clone(historicalField);
            field.isHistorical = true;
            merged[index].push(field);
          });
        }
      });
      return merged;
    },

    /**
     * List of merged fields filtered by search query.
     *
     * @return {Array}
     */
    filteredFields() {
      const filtered = this.mergedFields;
      if (!this.search) {
        return filtered;
      }
      return filtered.map(fields => fields.filter(fieldItem => {
        const findByField = fieldItem.field.toLowerCase().indexOf(this.search.toLowerCase());
        const findByName = fieldItem.name.toLowerCase().indexOf(this.search.toLowerCase());
        return findByField !== -1 || findByName !== -1;
      }));
    },

    /**
     * List of fields grouped by Value
     *
     * @return {Array}
     */
    groupedByValues() {
      const grouped = [];
      this.filteredFields.forEach(items => {
        const groupedItems = [];
        const groupMap = {};
        items.forEach(fieldItem => {
          if (fieldItem.groupId) {
            if (Object.prototype.hasOwnProperty.call(groupMap, fieldItem.groupId)) {
              groupedItems[groupMap[fieldItem.groupId]].fields.push(fieldItem);
            } else {
              const newKey = groupedItems.push({
                name: fieldItem.name,
                groupId: fieldItem.groupId,
                fields: [
                  fieldItem,
                ],
              });
              groupMap[fieldItem.groupId] = newKey - 1;
            }
          } else {
            groupedItems.push(fieldItem);
          }
        });
        grouped.push(groupedItems);
      });
      return grouped;
    },

    /**
     * List of grouped fields.
     *
     * @return {Array}
     */
    groupedFields() {
      if (this.groupBy === FIELDS_GROUP_BY.VALUES) {
        return this.groupedByValues;
      }
      if (this.groupBy === FIELDS_GROUP_BY.RECORDS) {
        return this.filteredFields;
      }
      return this.filteredFields;
    },

    /**
     * Selected categories, used for model of category checkboxes.
     *
     * @return {Object}
     */
    selectedCategories() {
      const selected = {};
      this.mergedFields.forEach((items, index) => {
        let allSelected = true;
        items.forEach(fieldItem => {
          if (!this.isLocked(fieldItem.field) && this.selected.indexOf(fieldItem.field) === -1) {
            allSelected = false;
          }
        });
        if (!items.length) {
          allSelected = false;
        }
        selected[index] = allSelected;
      });
      return selected;
    },

    /**
     * Selected fields, used for fields checkboxes.
     *
     * @return {Object}
     */
    selectedFields() {
      const fields = {};
      this.mergedFields.forEach(items => {
        items.forEach(fieldItem => {
          fields[fieldItem.field] = this.selected.indexOf(fieldItem.field) !== -1
              || this.isLocked(fieldItem.field);
        });
      });
      return fields;
    },

    /**
     * Selected group by values, used for model of group checkboxes.
     *
     * @return {Object}
     */
    selectedGroupByValues() {
      const selected = {};
      this.mergedFields.forEach(items => {
        items.forEach(fieldItem => {
          if (!fieldItem.groupId) {
            return;
          }
          if (!Object.prototype.hasOwnProperty.call(selected, fieldItem.groupId)) {
            selected[fieldItem.groupId] = true;
          }
          if (this.selected.indexOf(fieldItem.field) === -1) {
            selected[fieldItem.groupId] = false;
          }
        });
      });
      return selected;
    },

    /**
     * Show count of selected fields in group.
     *
     * @return {Object}
     */
    countInGroupByValuesSelected() {
      const countSelected = {};
      this.mergedFields.forEach(items => {
        items.forEach(fieldItem => {
          if (!fieldItem.groupId) {
            return;
          }
          if (!Object.prototype.hasOwnProperty.call(countSelected, fieldItem.groupId)) {
            countSelected[fieldItem.groupId] = 0;
          }
          if (this.selectedFields[fieldItem.field]) {
            countSelected[fieldItem.groupId]++;
          }
        });
      });
      return countSelected;
    },

    /**
     * Show count of selected fields in category.
     *
     * @return {Object}
     */
    countSelected() {
      const countSelected = {};
      this.mergedFields.forEach((items, index) => {
        countSelected[index] = 0;
        items.forEach(fieldItem => {
          if (this.selectedFields[fieldItem.field]) {
            countSelected[index]++;
          }
        });
      });
      return countSelected;
    },
  },
  watch: {
    showHistoricalFields(enabled) {
      if (!enabled) {
        this.deleteHistoricalFromSelected();
      }
    },
  },

  methods: {
    /**
     * Check if field is locked.
     *
     * @param {string} field - Field to check
     *
     * @return {Boolean}
     */
    isLocked(field) {
      return this.locked.indexOf(field) !== -1;
    },

    /**
     * Handler for click by category checkbox.
     */
    selectCategory(index) {
      const categoryChecked = this.selectedCategories[index];
      this.mergedFields[index].forEach(fieldItem => {
        if (this.locked.indexOf(fieldItem.field) !== -1) {
          return;
        }
        const fieldIndex = this.selected.indexOf(fieldItem.field);
        // If category unchecked
        if (!categoryChecked) {
          if (fieldIndex !== -1) {
            this.selected.splice(fieldIndex, 1);
          }
        } else if (fieldIndex === -1) {
          this.selected.push(fieldItem.field);
        }
      });
      this.$emit('update:selected', this.selected);
    },

    /**
     * Handler for click by field checkbox.
     */
    selectField(field) {
      if (this.locked.indexOf(field) !== -1) {
        return;
      }
      const index = this.selected.indexOf(field);
      if (index === -1) {
        this.selected.push(field);
      } else {
        this.selected.splice(index, 1);
      }
      this.$emit('update:selected', this.selected);
    },

    /**
     * Handler for click by group checkbox.
     */
    selectGroup(groupId) {
      const groupChecked = this.selectedGroupByValues[groupId];
      this.groupedFields.forEach(items => {
        items.forEach(fieldItem => {
          if (fieldItem.fields && fieldItem.groupId === groupId) {
            fieldItem.fields.forEach(groupedField => {
              const fieldIndex = this.selected.indexOf(groupedField.field);
              // If category unchecked
              if (!groupChecked) {
                if (fieldIndex !== -1) {
                  this.selected.splice(fieldIndex, 1);
                }
              } else if (fieldIndex === -1) {
                this.selected.push(groupedField.field);
              }
            });
          }
        });
      });
      this.$emit('update:selected', this.selected);
    },

    deleteHistoricalFromSelected() {
      this.allFields.forEach(categoryItem => {
        categoryItem.historicalFields.forEach(historicalField => {
          const index = this.selected.indexOf(historicalField.field);
          if (index !== -1) {
            this.selected.splice(index, 1);
          }
        });
      });
      this.$emit('update:selected', this.selected);
    },
  },
};
</script>
<style lang="scss">
.fields-selector {
  .v-slide-group__wrapper {
    max-height: 400px;
    min-height: 400px;
    min-width: 310px;
    overflow-y: scroll;
  }
  .v-window {
    max-height: 400px;
    min-height: 400px;
    overflow-y: scroll;
  }
  .v-tab {
    justify-content: left;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .v-simple-checkbox {
    margin-right: 10px;
    max-width: 25px;
  }
  .v-chip {
    max-width: 55px;
  }
  .group-fields-container {
    padding-left: 30px;
  }
  .checkbox-label {
    cursor: pointer;
  }
  .locked-field {
    color: gray;
  }
}

</style>
