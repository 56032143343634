<template>
  <v-card class="white elevation-3 ma-4">
    <v-container
      grid-list-md
      text-center
      fluid
    >
      <v-layout
        row
        wrap
        pa-4
      >
        <v-flex
          v-for="(utility, index) in utilities"
          :key="index"
          xs12
          sm12
          md6
          lg4
          xl3
        >
          <utility-button
            :icon="utility.icon"
            :title="utility.title"
            :description="utility.description"
            :route="utility.route ? utility.route: ''"
            :link="utility.link ? utility.link : ''"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import getNavigation from '@/data/Navigation';
import UtilityButton from '@/components/Utilities/UtilityButton';
import navigationCheckAccessMixins from '@/mixins/navigationCheckAccessMixins';
import ROUTES from '@/enums/routes';

export default {
  components: {
    UtilityButton,
  },

  mixins: [navigationCheckAccessMixins],

  computed: {
    /**
     * Utility list accessible for current user.
     *
     * @return {Array}
     */
    utilities() {
      const navigation = getNavigation(this.$store);
      const utilities = [];
      navigation.forEach(item => {
        if (item.route === ROUTES.UTILITIES) {
          item.items.forEach(group => {
            group.items.forEach(nestedItem => {
              utilities.push(nestedItem);
            });
          });
        }
      });
      return utilities.filter(
        value => this.haveAccess(value)
          && (typeof value.isAvailable === 'undefined'
            || (typeof value.isAvailable !== 'undefined' && value.isAvailable())),
      );
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('utilities.header'));
  },
};
</script>
