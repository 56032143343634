<template>
  <materials
    :show-export-xls="false"
    :show-client-selection="true"
    :need-client-id="false"
    :show-county-paramedic-tab="false"
    @loadCategories="loadAdminCategories"
    @loadDocuments="loadAdminDocuments"
    @exportDocuments="exportAdminDocuments"
/>
</template>

<script>
import { mapActions } from 'vuex';
import {
  ADMIN_EXPORT_DOCUMENTS,
  ADMIN_LOAD_CATEGORIES,
  ADMIN_LOAD_DOCUMENTS,
} from '@/store/Documents/actions';
import Materials from '../Materials';

export default {
  components: {
    Materials,
  },
  methods: {
    ...mapActions({
      loadAdminDocuments: `documents/${ADMIN_LOAD_DOCUMENTS}`,
      exportAdminDocuments: `documents/${ADMIN_EXPORT_DOCUMENTS}`,
      loadAdminCategories: `documents/${ADMIN_LOAD_CATEGORIES}`,
    }),
  },
};
</script>
