<template>
  <v-container
    grid-list-xl
    fluid
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xl2
      >
        <v-text-field
          v-model="filters.search"
          :label="$t('firstRunDevices.filters.search')"
          clearable
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'FirstRunDevicesToolbar',

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.query.search) {
      this.filters.search = this.$route.query.search;
    }
  },
};
</script>
