<template>
  <v-dialog
      v-model="dialog"
      class="column-manager"
      max-width="800px"
      persistent
  >
    <v-card>
      <v-toolbar flat>
        <b>Add Fields</b>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            label="Search fields..."
            append-icon="search"
            dense
            hide-details
        ></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 400px;">
        <fields-selector
            ref="fieldsSelector"
            :all-fields="allFields"
            :search="search"
            :show-historical-fields="showHistoricalFields"
            :group-by="groupBy"
            :locked="[]"
            v-bind:selected.sync="selected"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-checkbox
            v-model="showHistoricalFields"
            label="Show historical fields"
        ></v-checkbox>

        <v-spacer />
        <v-btn
            text
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="addFields()"
        >
          Add fields
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FIELDS_GROUP_BY from '@/enums/fieldsGroupBy';
import FieldsSelector from '@/components/Incidents/FieldsSelector';

export default {
  components: { FieldsSelector },
  props: {
    allFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchGroupId: null,
      search: null,
      groupBy: FIELDS_GROUP_BY.NONE,
      dialog: false,
      selected: [],
      showHistoricalFields: false,
      fieldsGroupBy: FIELDS_GROUP_BY,
    };
  },
  methods: {
    /**
     * Show add search fields dialog.
     *
     * @param {Number} searchGroupId - Group identifier.
     */
    show(searchGroupId) {
      this.dialog = true;
      this.selected = [];
      this.search = null;
      this.searchGroupId = searchGroupId;
    },

    /**
     * Handler for click by add fields button.
     */
    addFields() {
      this.$emit('add-fields', this.selected, this.searchGroupId);
      this.dialog = false;
    },
  },
};
</script>
