<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text>
          <div class="pt-3">
            Loading...
            <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <span>Export Settings</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          v-if="dialog"
          class="pb-0"
        >
          <v-alert
              v-if="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          <v-row>
            <v-col sm="6">
              <v-text-field
                  v-model="settingsData.fdId"
                  v-validate="{ required: isRequired, 'integer-number': true, min: 5}"
                  label="Fire Department ID"
                  maxlength="5"
                  name="fdId"
                  :error-messages="errors.collect('fdId')"
              ></v-text-field>

              <v-text-field
                  v-model="settingsData.vendorId"
                  v-validate="{ required: isRequired, min: 10 }"
                  label="Vendor ID"
                  maxlength="10"
                  name="vendorId"
                  :error-messages="errors.collect('vendorId')"
              ></v-text-field>

              <v-text-field
                  v-model="settingsData.softwareId"
                  v-validate="{ required: isRequired, min: 5 }"
                  label="Software ID"
                  maxlength="5"
                  name="softwareId"
                  :error-messages="errors.collect('softwareId')"
              ></v-text-field>

              <v-select
                  ref="callTypes"
                  v-model="settingsData.filterCallTypes"
                  :items="callTypeItems"
                  class="callTypesSelect"
                  label="Filtered Call Types"
                  multiple
                  small-chips
                  deletable-chips
                  counter
                  @change="callTypesChanged"
              />
            </v-col>
            <v-col
                sm="6"
                class="nfirs-right-container"
            >
              <v-select
                  v-model="settingsData.interval"
                  :items="intervals"
                  :hint="settingsData.interval
                  ? 'Note: time is 0600 (PST) for all options. Query period: ' + getPeriod()
                  : null"
                  persistent-hint
                  label="Export interval"
              />

              <v-select
                  v-model="settingsData.uploadType"
                  v-validate="{ required: !!settingsData.interval }"
                  :disabled="!settingsData.interval"
                  :items="uploadTypes"
                  :error-messages="errors.collect('uploadType')"
                  name="uploadType"
                  item-value="value"
                  item-text="name"
                  label="Upload Type"
                  clearable
              />

              <v-text-field
                  v-if="showFtpFields"
                  key="ftpServer"
                  v-model="settingsData.ftpServer"
                  v-validate="{ required: !!settingsData.interval }"
                  :disabled="!settingsData.interval"
                  :error-messages="errors.collect('ftpServer')"
                  label="Server"
                  placeholder="server:port"
                  maxlength="255"
                  name="ftpServer"
              ></v-text-field>

              <v-text-field
                  v-if="showFtpFields"
                  key="ftpUser"
                  v-model="settingsData.ftpUser"
                  v-validate="{ required: !!settingsData.interval }"
                  :disabled="!settingsData.interval"
                  :error-messages="errors.collect('ftpUser')"
                  label="Username"
                  maxlength="100"
                  name="ftpUser"
              ></v-text-field>

              <v-text-field
                  v-if="showFtpFields"
                  key="ftpPassword"
                  v-model="settingsData.ftpPassword"
                  v-validate="{ required: !!settingsData.interval }"
                  :disabled="!settingsData.interval"
                  :error-messages="errors.collect('ftpPassword')"
                  label="Password"
                  maxlength="100"
                  name="ftpPassword"
                  type="password"
              ></v-text-field>

              <v-text-field
                  v-if="showFtpFields || settingsData.uploadType === uploadTypesEnum.MC_SFTP"
                  key="uploadDirectory"
                  v-model="settingsData.uploadDirectory"
                  :disabled="!settingsData.interval"
                  :error-messages="errors.collect('uploadDirectory')"
                  label="Upload Directory"
                  maxlength="255"
                  name="uploadDirectory"
              ></v-text-field>

              <v-text-field
                  v-if="settingsData.uploadType === uploadTypesEnum.NFIRS_SITE"
                  key="nfirsLogin"
                  v-model="settingsData.nfirsLogin"
                  v-validate="{ required: !!settingsData.interval }"
                  :disabled="!settingsData.interval"
                  :error-messages="errors.collect('nfirsLogin')"
                  label="NFIRS Site Login"
                  maxlength="100"
                  name="nfirsLogin"
              ></v-text-field>

              <v-text-field
                  v-if="settingsData.uploadType === uploadTypesEnum.NFIRS_SITE"
                  key="nfirsPassword"
                  v-model="settingsData.nfirsPassword"
                  v-validate="{ required: !!settingsData.interval }"
                  :disabled="!settingsData.interval"
                  :error-messages="errors.collect('nfirsPassword')"
                  label="NFIRS Site Password"
                  maxlength="100"
                  name="nfirsPassword"
                  type="password"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="validateAndSave"
          >
            <template>Save</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import { SHOW_SNACKBAR } from '@/store/actions';
import { mapActions } from 'vuex';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import nfirsExport from '@/api/nfirsExport';
import EXPORT_INTERVALS from '@/enums/exportIntervals';
import NFIRS_UPLOAD_TYPES from '@/enums/nfirsUploadTypes';
import moment from 'moment';

export default {
  name: 'SettingsPopup',
  mixins: [errorAlertMixins],
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      callTypes: {},
      uploadTypes: [],
      uploadTypesEnum: NFIRS_UPLOAD_TYPES,
      retryAfterChange: false,
      settingsData: {
        fdId: null,
        vendorId: null,
        softwareId: null,
        filterCallTypes: null,
        interval: null,
        uploadType: null,
        ftpServer: null,
        ftpUser: null,
        ftpPassword: null,
        uploadDirectory: null,
        nfirsLogin: null,
        nfirsPassword: null,
      },
      saving: false,
      dictionary: {
        attributes: {
          fdId: 'Fire Dep. ID',
          vendorId: 'Vendor ID',
          softwareId: 'Software ID',
          uploadType: 'Upload Type',
          ftpServer: 'Server',
          ftpUser: 'Username',
          ftpPassword: 'Password',
          uploadDirectory: 'Upload Directory',
          nfirsLogin: 'NFIRS Site Login',
          nfirsPassword: 'NFIRS Site Password',
        },
      },
      intervals: [
        { value: null, text: 'No Export' },
        { value: EXPORT_INTERVALS.DAILY, text: 'Every Day' },
        { value: EXPORT_INTERVALS.WEEKLY, text: 'Every Monday' },
        { value: EXPORT_INTERVALS.MONTHLY, text: 'First of Every Month' },
      ],
    };
  },

  computed: {
    isRequired() {
      return !!(this.settingsData.fdId
          || this.settingsData.vendorId
          || this.settingsData.softwareId
          || (this.settingsData.filterCallTypes && this.settingsData.filterCallTypes.length)
          || this.settingsData.interval
      );
    },
    isClear() {
      return !this.settingsData.fdId
          && !this.settingsData.vendorId
          && !this.settingsData.softwareId
          && !this.settingsData.interval;
    },
    showFtpFields() {
      return this.settingsData.uploadType === this.uploadTypesEnum.FTP
          || this.settingsData.uploadType === this.uploadTypesEnum.SFTP;
    },
    callTypeItems() {
      const items = [];
      _.forEach(this.callTypes, (values, section) => {
        items.push({ header: section });
        items.push({ divider: true });
        values.forEach((value) => {
          items.push({ text: value, value });
        });
      });
      return items;
    },
  },

  watch: {
    settingsData: {
      handler() {
        if (!this.isRequired) {
          this.$validator.reset();
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Handler for change call type action.
     */
    callTypesChanged() {
      if (this.retryAfterChange) {
        this.validateAndSave();
      }
    },

    /**
     * Show Export Settings dialog.
     */
    async show() {
      this.hideError();
      this.$validator.reset();
      this.settingsData = _.cloneDeep(this.settings);
      this.dialog = true;
      const promise = [
        this.fetchUploadTypes(),
      ];
      if (Object.keys(this.callTypes).length === 0) {
        promise.push(this.fetchCallTypes());
      }
      this.loading = true;
      try {
        await Promise.all(promise);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Fetch list of upload types.
     */
    async fetchUploadTypes() {
      this.uploadTypes = await nfirsExport.getUploadTypes();
    },

    /**
     * Fetch list of call types.
     */
    async fetchCallTypes() {
      this.callTypes = await nfirsExport.getCallTypes();
    },

    /**
     * Validate form and save if is valid.
     */
    async validateAndSave() {
      if (this.$refs.callTypes.lazySearch && !this.retryAfterChange) {
        this.retryAfterChange = true;
        return;
      }
      this.retryAfterChange = false;

      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.saving = true;
      try {
        if (this.isClear) {
          await nfirsExport.deleteSettings();
        } else {
          const data = this.getDataToSave();
          await nfirsExport.saveSettings(data);
        }
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.saving = false;
      }
      this.$emit('saved');
    },

    /**
     * Prepare data to save.
     *
     * @return {Object}
     */
    getDataToSave() {
      const data = _.cloneDeep(this.settingsData);

      if (!data.interval) {
        data.uploadType = null;
      }

      if (data.uploadType === this.uploadTypesEnum.FTP
          || data.uploadType === this.uploadTypesEnum.SFTP
          || data.uploadType === null
      ) {
        data.nfirsLogin = null;
        data.nfirsPassword = null;
      }
      if (this.settingsData.uploadType === this.uploadTypesEnum.MC_SFTP
          || data.uploadType === null
      ) {
        data.nfirsLogin = null;
        data.nfirsPassword = null;
        data.ftpServer = null;
        data.ftpUser = null;
        data.ftpPassword = null;
      }
      if (this.settingsData.uploadType === this.uploadTypesEnum.NFIRS_SITE
          || data.uploadType === null
      ) {
        data.ftpServer = null;
        data.ftpUser = null;
        data.ftpPassword = null;
        data.uploadDirectory = null;
      }
      return data;
    },

    /**
     * Get example of export period.
     *
     * @return {String}
     */
    getPeriod() {
      if (this.settingsData.interval === EXPORT_INTERVALS.DAILY) {
        return moment().format('MM/DD/YYYY');
      }
      if (this.settingsData.interval === EXPORT_INTERVALS.WEEKLY) {
        return `${moment().startOf('week').add(1, 'days').format('MM/DD/YYYY')} - ${
          moment().endOf('week').add(1, 'days').format('MM/DD/YYYY')}`;
      }
      if (this.settingsData.interval === EXPORT_INTERVALS.MONTHLY) {
        return `${moment().startOf('month').format('MM/DD/YYYY')} - ${
          moment().endOf('month').format('MM/DD/YYYY')}`;
      }
      return 'none';
    },
  },
};
</script>
<style lang="scss">
.callTypesSelect {
  .v-select__selections {
    max-height: 32px;
    overflow: clip;
  }
}
.nfirs-right-container {
  border-left: 1px solid #e1e1e3;
}
</style>
