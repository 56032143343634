const METRICS = {
  INCIDENTS_BY_LOCATION: 'incidentsByLocation',
  COVID_INCIDENTS_BY_LOCATION: 'covidIncidentsByLocation',
  AVG_TIME_RESPONSE: 'avgTimeResponse',
  AVG_TIME_RESPONSE_BY_DISPATCH_ID: 'avgTimeResponseByDispatchId',
  AVG_TIME_RESPONSE_BY_VIA: 'avgTimeResponseByVia',
  AVG_TIME_ON_SCENE: 'avgTimeOnScene',
  AVG_TIME_BY_RECEIVING_FACILITY: 'avgTimeByReceivingFacility',
  AVG_TIME_TOTAL: 'avgTimeTotal',
  AVG_TIME_BY_VIA: 'avgTimeByVia',
  AVG_TIME_BY_DISPATCH_ID: 'avgTimeByDispatchId',
  TOTAL_BY_RUN_TYPE: 'totalByRunType',
  RECEIVING_FACILITY: 'receivingFacility',
  AMA: 'ama',
  TRANSPORT_VIA: 'transportVia',
  TRANSPORT_REASON: 'transportReason',
  TRANSPORTED_TO: 'transportedTo',
  PROTOCOL: 'protocol',
  CONTACT: 'contact',
  SEX: 'sex',
  AVERAGE_AGE: 'averageAge',
  AVERAGE_WEIGHT: 'averageWeight',
  DISTRESS_LEVEL: 'distressLevel',
  COMPLAINT: 'complaint',
  MECHANISM_OF_INJURY: 'mechanismOfInjury',
  PROVIDER_IMPRESSION: 'providerImpression',
  FALLOUT_CODE: 'falloutCode',
  PERCENTAGE_FALLOUT_CODE: 'percentageFalloutCode',
  SPECIAL_CIRCUMSTANCES: 'specialCircumstances',
  PROVIDER_IMPRESSIONS_BY_VIA: 'providerImpressionsByVia',
  APOT_1: 'apot1',
  APOT_2: 'apot2',
  APOT_1_BY_UNITS: 'apot1ByUnits',
  APOT_2_BY_UNITS: 'apot2ByUnits',
  APOT_DELAY_EXCEEDING_60: 'apotDelayExceeding60',
  APOT_DELAY_EXCEEDING_30: 'apotDelayExceeding30',
  APOT_DELAY_EXCEEDING_20: 'apotDelayExceeding20',
  APOT_DELAY_EXCEEDING_10: 'apotDelayExceeding10',
};

export default METRICS;
