export const SET_ADMINS = 'SET_ADMINS';
export const SET_DIALOG = 'SET_DIALOG';
export const SET_EDITED_ITEM = 'SET_EDITED_INDEX';

export default {
  [SET_ADMINS]: (state, data) => {
    state.admins = [...data];
  },
  [SET_DIALOG]: (state, data) => {
    state.dialog = data;
  },
  [SET_EDITED_ITEM]: (state, data) => {
    state.editedItem = data;
  },
};
