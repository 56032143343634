import axios from 'axios';
import apiUrl from './apiUrl';
import sitecode from '../sitecode';

const apiv3 = axios.create({
  baseURL: apiUrl(sitecode, 'v3'),
  withCredentials: true,
});

export default apiv3;
