<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
        scrollable
    >
      <v-card>
        <v-card-title>Validation</v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="waiting">
          <v-progress-linear
              indeterminate
              height="20"
              class="mt-5"
          >
            Validation, please wait...
          </v-progress-linear>
        </v-card-text>
        <div
          v-if="!waiting && error"
          :class="[$style['validation-description'], 'mt-3']"
        >
          <span :class="[$style['error-text']]">Unexpected error, please try again later.</span>
        </div>

        <div v-if="!waiting && !error">
          <div v-if="validationResult">
            <div :class="[$style['validation-description'], 'mt-3', 'mb-3']">
              These fields are invalid, please correct them if desired.
              You still have the ability to save the data without corrections.
            </div>
            <v-divider />
          </div>
          <div
              v-else
              :class="[$style['validation-description'], 'mt-3']"
          >
            All mandatory fields are filled
          </div>
        </div>

        <v-card-text
            v-if="!waiting && !error && validationResult"
            style="max-height: 300px;"
            :class="[$style['validation-container'], 'pa-0']"
        >
          <template
              v-for="(items, category) in validationResult"
          >
            <v-divider
              v-bind:key="'item_divider_' + category"
              class="mt-3"
            />
            <div
              v-bind:key="'item_' + category"
              class="mt-3 mr-3 ml-3"
            >
                  <span :class="[$style['category-name']]">
                    {{ category }} ({{ items.length }})
                  </span>
              <ul :class="[$style['validation-items']]">
                <li
                    v-for="(item, index) in items"
                    v-bind:key="'item_validation_' + category + index"
                >{{ item }}</li>
              </ul>
            </div>
          </template>
        </v-card-text>

        <v-divider class="mt-3"/>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="hide"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'ValidationPopup',
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      waiting: true,
      validationResult: null,
      error: false,
    };
  },
  computed: {
    echoChannel() {
      return `incident.${this.incidentId}`;
    },
  },
  methods: {
    waitValidationResult() {
      this.dialog = true;
      this.waiting = true;
      this.error = false;
      this.validationResult = null;
      this.$echo.private(this.echoChannel).listen('.IncidentValidated', (data) => {
        this.waiting = false;
        if (Object.keys(data.validation).length) {
          this.validationResult = data.validation;
        } else {
          this.$emit('validated');
          this.validationResult = null;
        }
        this.$echo.leave(this.echoChannel);
      });
      this.$echo.private(this.echoChannel).listen('.IncidentValidationError', () => {
        this.waiting = false;
        this.error = true;
        this.$echo.leave(this.echoChannel);
      });
    },
    hide() {
      this.dialog = false;
      this.$echo.leave(this.echoChannel);
    },
  },
};
</script>

<style lang="scss" module>
  .validation-description {
    text-align: center;
  }
  .validation-container {
    hr:first-child {
      display: none;
    }
  }
  .category-name {
    font-weight: bold;
    color: red;
  }
  .error-text {
    color: red;
  }
  .validation-items {
    list-style-type: none;
    li {
      color: #2177c6;
      font-style: italic;
    }
    li:before {
      display: inline-block;
      content: "-";
      width: 1em;
      margin-left: -1em;
      color: #000000;
    }
  }
</style>
