<template>
  <div>
    <v-toolbar class="search-results-tab">
      <v-subheader
        v-if="entity.totalItems"
        class="subheader pl-0"
      >
        <span
          class="title"
        >Search Results:</span> {{ entity.totalItems }}
      </v-subheader>
      <v-spacer></v-spacer>
      <template v-if="entity.columnPresets && entity.columnPresets.length">
        <span class="mr-5">Column Preset: </span>
        <v-autocomplete
          v-model="preset"
          :items="presetsItems"
          item-value="id"
          item-text="name"
          hide-details
          style="max-width: 300px"
          @change="preset ? setSelectedFields(presetById[preset].data.selected, preset) : null"
        />
      </template>
      <column-manager
          ref="columnManager"
          :all-fields="entity.tableFields || []"
          :selected="entity.selectedFields"
          :loading="externalLoading"
          :preset="presetById[preset]"
          @applied="setSelectedFields"
      />
      <v-btn
          class="ml-5"
          :loading="loadingExportPopup"
          :disabled="!entity.items || entity.items.length === 0"
          @click="showExport"
      >
        Export
      </v-btn>
      <selective-export
          ref="selectiveExport"
          :all-fields="entity.exportFields"
          :selected="entity.selectedFields"
          :exporting="exporting"
          @export="exportResult"
      />
    </v-toolbar>
    <incidents-table
        :items="entity.items || []"
        :pagination="entity.pagination"
        :total-items="entity.totalItems"
        :table-fields="entity.tableFields || []"
        :selected-fields="entity.selectedFields"
        :loading="loading || externalLoading"
        :is-deleted-incidents-type="type === searchTypes.DELETED"
        :is-unfinished-incidents-type="type === searchTypes.UNFINISHED"
        :show-flagged-incidents-columns="type === searchTypes.FLAGGED"
        :show-view-incident-link="![searchTypes.DELETED, searchTypes.UNFINISHED].includes(type)"
        @update:pagination="updatePagination"
        @add-flag="addFlag"
        @incident-deleted="$emit('incident-deleted')"
        @commit-to-main-db="(incident) => {$emit('commit-to-main-db', incident)}"
        @overwrite-incident="(incident) => {$emit('overwrite-incident', incident)}"
        @view-incident="viewIncidentHandle"
    />
    <confirmation ref="confirmationPopup"/>
    <export-error-message ref="exportErrorMessagePopup"/>
  </div>
</template>
<script>
import SEARCH_TYPES from '@/enums/searchTypes';
import IncidentsTable from '@/components/Incidents/IncidentsTable';
import { createNamespacedHelpers } from 'vuex';
import { SET_PAGINATION, SET_SELECTED_FIELDS, SET_CURRENT_COLUMN_PRESET } from '@/store/Incidents/mutations';
import {
  LOAD_INCIDENTS, LOAD_EXPORT_FIELDS, LOAD_EXPORT_PRESETS,
  EXPORT_INCIDENTS, EXPORT_INCIDENTS_TO_EMAIL,
} from '@/store/Incidents/actions';
import _ from 'lodash';
import ColumnManager from '@/components/Incidents/ColumnManager';
import SelectiveExport from '@/components/Incidents/SelectiveExport';
import Confirmation from '@/components/Confirmation';
import ExportErrorMessage from '@/components/Incidents/ExportErrorMessage';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('incidents');

export default {
  components: {
    Confirmation, SelectiveExport, ColumnManager, IncidentsTable, ExportErrorMessage,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    externalLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      exporting: false,
      loadingExportPopup: false,
      searchTypes: SEARCH_TYPES,
      directExportLimit: 10000,
      maxExportTimeMin: 7,
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),
    preset: {
      get() {
        return this.entity.currentColumnPreset;
      },
      set(value) {
        this.setCurrentColumnPreset({
          value,
        });
      },
    },
    presetById() {
      const items = {};
      if (this.entity.columnPresets) {
        this.entity.columnPresets.forEach(preset => {
          items[preset.id] = preset;
        });
      }
      return items;
    },
    presetsItems() {
      const items = [{
        id: null,
        name: 'Custom',
      }];
      if (this.entity.columnPresets) {
        this.entity.columnPresets.forEach(presetItem => {
          const item = _.clone(presetItem);

          if (item.isCollaborate) {
            item.name = `${presetItem.name} (Collaborated)`;
          }

          items.push(item);
        });
      }
      return items;
    },
  },

  created() {
    if (this.entity.items === null) {
      this.reloadItems();
    }
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PAGINATION,
      setFields: SET_SELECTED_FIELDS,
      setCurrentColumnPreset: SET_CURRENT_COLUMN_PRESET,
    }),
    ...mapActions({
      loadIncidents: LOAD_INCIDENTS,
      loadExportFields: LOAD_EXPORT_FIELDS,
      loadExportPresets: LOAD_EXPORT_PRESETS,
      exportIncidents: EXPORT_INCIDENTS,
      exportIncidentsToEmail: EXPORT_INCIDENTS_TO_EMAIL,
    }),

    async showExport() {
      if (this.entity.exportFields.length === 0 || this.entity.exportPresets === null) {
        this.loadingExportPopup = true;
        try {
          await Promise.all([
            this.loadExportFields(),
            this.loadExportPresets(),
          ]);
        } finally {
          this.loadingExportPopup = false;
        }
      }
      this.$refs.selectiveExport.show();
    },

    updatePagination(value) {
      if (!_.isEqual(value, this.entity.pagination)) {
        this.setPagination(value);
        this.reloadItems();
      }
    },

    setSelectedFields(fields, preset) {
      this.setFields(fields);
      this.preset = preset;
      this.reloadItems();
    },

    /**
     * Reloads paginated list for current filter.
     */
    async reloadItems() {
      this.loading = true;
      try {
        await this.loadIncidents(this.type);
      } finally {
        this.loading = false;
      }
    },

    viewIncidentHandle(incident) {
      this.$emit('view-incident', incident, this.type);
    },

    addFlag(incident) {
      this.$emit('add-flag', incident);
    },
    async exportResult(selectedFields) {
      try {
        if (!this.checkOpportunityExport(selectedFields)) {
          throw Error('No opportunity of export');
        }

        this.exporting = true;
        if (this.entity.totalItems >= this.directExportLimit) {
          this.$refs.confirmationPopup.showConfirm(
            'Warning',
            'Because this export contains > 10k rows of data, this report will take ~1 minute to produce. '
              + 'Once the export has been completed, you will receive an email with a link to download the file.',
            async () => {
              await this.exportIncidentsToEmail({
                type: this.type, selectedFields,
              });
            },
          );
        } else {
          await this.exportIncidents({
            type: this.type, selectedFields,
          });
        }
      } catch (e) {
        if (e.message === 'No opportunity of export') {
          this.$refs.exportErrorMessagePopup.show(
            'Export Size Exceeded',
            `It seems you are trying to export an XSLX file that might take more than ${this.maxExportTimeMin}`
              + ' minutes to process. Due to system limitations, please reduce your row number before attempting '
              + 'to export your selection. <br><br> One suggestion includes reducing the date range '
              + '(splitting it by year, and/or quarter) and trying again.',
            true,
          );
        }
      } finally {
        this.$refs.selectiveExport.close();
        this.exporting = false;
      }
    },

    /**
     * Check opportunity of export.
     *
     * @param selectedFields List of selected fields
     *
     * @returns {boolean}
     */
    checkOpportunityExport(selectedFields) {
      const countOfFields = (selectedFields === undefined || selectedFields.length === 0)
        ? 1
        : selectedFields.length;
      const averageSpeed = this.calculateSpeed(countOfFields);

      return (this.entity.totalItems / averageSpeed) < this.maxExportTimeMin * 60;
    },

    /**
     * This method calculates average speed for incident export. The calculating makes
     * the method of linear interpolation. Intervals of calculating for linear interpolation
     * got method of testing different intervals, count of records and timing of execution.
     *
     * @param {Number} providedCountFields Count of fields in request
     *
     * @returns {Number}
     */
    calculateSpeed(providedCountFields) {
      const countsSpeeds = [
        { fields: 1, speed: 2836 },
        { fields: 106, speed: 801 },
        { fields: 211, speed: 369 },
        { fields: 422, speed: 199 },
        { fields: 845, speed: 109 },
      ];

      let countFields;

      if (providedCountFields < countsSpeeds[0].fields) {
        countFields = countsSpeeds[0].fields;
      } else if (providedCountFields > countsSpeeds[4].fields) {
        countFields = countsSpeeds[4].fields;
      } else {
        countFields = providedCountFields;
      }

      const element = countsSpeeds.find((elem) => countFields === elem.fields);

      if (element !== undefined) {
        return element.speed;
      }

      const elementLessThanCountFields = countsSpeeds.find(
        (elem, index, array) => countFields > elem.fields && countFields < array[index + 1].fields,
      );
      const elementGreaterThanCountFields = countsSpeeds.find(
        (elem, index, array) => countFields < elem.fields && countFields > array[index - 1].fields,
      );

      const speed = elementLessThanCountFields.speed
          - (elementLessThanCountFields.speed - elementGreaterThanCountFields.speed)
          * ((countFields - elementLessThanCountFields.fields)
          / (elementGreaterThanCountFields.fields - elementLessThanCountFields.fields));

      return Math.round(speed);
    },
  },
};
</script>

<style lang="scss">
.search-results-tab {
  .subheader {
    font-size: 18px;
    .title {
      font-weight: 500;
      margin-right: 8px;
    }
  }
}
</style>
