export const SET_ITEMS = 'SET_ITEMS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';

export default {
  /**
   * Save first run devices data.
   *
   * @param {Object} state - State.
   * @param {{ items: Array, totalItems: number }} data - Data to set.
   */
  [SET_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.totalItems = totalItems;
  },

  /**
   * Save first run devices pagination.
   *
   * @param {Object} state - State.
   * @param {Object} pagination - Pagination data.
   */
  [SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters.
   *
   * @param {Object} state - State.
   * @param {Object} filters - Filters to set.
   */
  [SET_FILTERS]: (state, filters) => {
    state.filters = filters;
  },
};
