<template>
  <utilities-export></utilities-export>
</template>

<script>
import UtilitiesExport from '@/components/UtilitiesExport/Index';

export default {
  components: {
    UtilitiesExport,
  },
};
</script>
