<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-card-title>{{ title }}</v-card-title>
    <vue-apex-charts
        v-if="!loading && data.length"
        type="treemap"
        :height="chartHeight"
        :options="chartOptions"
        :series="data || []"
    />
    <div
        v-if="!loading && !data.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'MultiDimensionalTreemap',
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          show: true,
        },
        chart: {
          type: 'treemap',
        },
      },
    };
  },
  computed: {
    chartHeight() {
      return this.height - 100;
    },
  },
};
</script>
