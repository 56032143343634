<template>
  <div>
    <monitors
        ref="monitors"
        :loading="isLoading"
        :monitors="monitors || []"
        :all-fields="allFields"
        @edit="editMonitor"
        @run-query="runQuery"
    />
    <edit-monitor-popup
      ref="editMonitor"
      @deleted="fetchMonitors"
      @saved="fetchMonitors"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { SET_MONITOR_TMP } from '@/store/Incidents/mutations';
import { SET_TITLE } from '@/store/mutations';
import monitors from '@/api/monitors';
import Monitors from '@/components/Monitors/Monitors';
import EditMonitorPopup from '@/components/Monitors/EditMonitorPopup';
import ROUTES from '@/enums/routes';
import incidents from '@/api/incidents';

export default {
  components: { EditMonitorPopup, Monitors },
  data() {
    return {
      loading: false,
      loadingFields: false,
      monitors: null,
      searchFields: null,
    };
  },

  computed: {
    isLoading() {
      return this.loading || this.loadingFields;
    },

    allFields() {
      const fields = {};
      const allFields = this.searchFields || [];
      allFields.forEach(category => {
        category.currentFields.forEach(fieldItem => {
          fields[fieldItem.field] = fieldItem;
        });
        category.historicalFields.forEach(fieldItem => {
          fields[fieldItem.field] = fieldItem;
        });
      });
      return fields;
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, 'Incident Monitors/Alerts');
    this.fetchMonitors();
    this.fetchSearchFields();
  },

  methods: {
    ...mapMutations('incidents', {
      setMonitor: SET_MONITOR_TMP,
    }),

    async fetchMonitors() {
      this.loading = true;
      try {
        this.monitors = await monitors.getMonitors();
      } finally {
        this.loading = false;
      }
    },

    async fetchSearchFields() {
      this.loadingFields = true;
      try {
        this.searchFields = await incidents.fetchSearchFields();
      } finally {
        this.loadingFields = false;
      }
    },

    editMonitor(monitor) {
      this.$refs.editMonitor.edit(monitor);
    },

    runQuery(monitor) {
      this.setMonitor(monitor);
      this.$router.push({ name: ROUTES.INCIDENTS_REGULAR });
    },
  },
};
</script>
