const METRIC_NAMES = {
  incidentsByLocation: 'Incidents by Location',
  covidIncidentsByLocation: 'Covid-19 Incidents by location',
  avgTimeResponse: 'Avg. Response Times',
  avgTimeResponseByDispatchId: 'Avg. Response Times by Units',
  avgTimeResponseByVia: 'Avg. Response Times by Crew Type',
  avgTimeOnScene: 'Avg. On Scene Time',
  avgTimeByReceivingFacility: 'Avg. Total Time at Hospital',
  avgTimeTotal: 'Avg. Total Incident Time',
  avgTimeByVia: 'Avg. Total Incident Time by Crew',
  avgTimeByDispatchId: 'Avg. Total Incident Time by Unit',
  totalByRunType: 'Total Incidents by Type',
  receivingFacility: 'Facility Most Transported To',
  ama: 'Total AMA\'s',
  transportVia: 'Transport Via',
  transportReason: 'Transport Reason',
  transportedTo: 'Transport To',
  protocol: 'Protocol Most Used',
  contact: 'Contact Made To',
  sex: 'Male v/s Female',
  averageAge: 'Average Age of Patient',
  averageWeight: 'Average Patient Weight',
  distressLevel: 'By Distress Level',
  complaint: 'Total patients by Chief Complaint',
  mechanismOfInjury: 'Total patients by Mechanism of Injury',
  providerImpression: 'Total patients by Provider Impression',
  falloutCode: 'Total based on Fallout Code',
  percentageFalloutCode: 'Percentage based on Fallout Code',
  specialCircumstances: 'Total patients by Special Circumstances',
  providerImpressionsByVia: 'Provider Impressions by Via',
  apot1: 'APOT-1',
  apot2: 'APOT-2',
  apot1ByUnits: 'APOT-1, By Units',
  apot2ByUnits: 'APOT-2, By Units',
  apotDelayExceeding60: 'Count of Transports w/ Delay Exceeding 60 Minutes',
  apotDelayExceeding30: 'Count of Transports w/ Delay Exceeding 30 Minutes',
  apotDelayExceeding20: 'Count of Transports w/ Delay Exceeding 20 Minutes',
  apotDelayExceeding10: 'Count of Transports w/ Delay Exceeding 10 Minutes',
};

export default METRIC_NAMES;
