<template>
  <span>
    {{ field.name || condition.field }} <b>is Null</b>
  </span>
</template>
<script>

export default {
  name: 'IsNull',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
};
</script>
