<template>
  <view-resolver>
    <materials-admin slot="adminView" />
    <materials-client slot="clientView" />
  </view-resolver>
</template>
<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import MaterialsAdmin from './MaterialsAdmin';
import MaterialsClient from './MaterialsClient';

export default {
  components: {
    MaterialsClient,
    MaterialsAdmin,
    ViewResolver,
  },
  mounted() {
    this.$store.commit(SET_TITLE, this.$t('documents.header'));
  },
};
</script>
