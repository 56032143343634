import externalServiceExports from '@/api/externalServiceExports';

import { SET_HDE_EXPORTS_ITEMS } from './mutations';

export const ADMIN_LOAD_HDE_EXPORTS = 'ADMIN_LOAD_HDE_EXPORTS';

export default {
  /**
   * Load od map exports list for admin role.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Flagged users state.
   *
   * @return {Promise}
   */
  [ADMIN_LOAD_HDE_EXPORTS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await externalServiceExports.adminFetchList(params);
    commit(SET_HDE_EXPORTS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

};
