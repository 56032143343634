import api from './api';

export default {
  /**
  * Fetch NFIRS data.
  *
  * @param {Object} params - Request params
  *
  * @return Promise<Object>
  */
  fetchData(params) {
    return api()
      .get('/export-nfirs', { params })
      .then(response => response.data);
  },

  /**
   * Get NFIRS export file.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  export(params) {
    return api()
      .get('/export-nfirs/export', { params, responseType: 'blob' });
  },
  /**
   * Generate and send NFIRS export file to email.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  exportToEmail(params) {
    return api()
      .get('/export-nfirs/export-to-email', { params });
  },

  /**
   * Fetch NFIRS export status.
   *
   * @return Promise<Object>
   */
  getStatus() {
    return api()
      .get('/export-nfirs/status')
      .then(response => response.data);
  },

  /**
   * Retry failed export.
   *
   * @return Promise<Object>
   */
  retryExport() {
    return api()
      .post('/export-nfirs/retry')
      .then(response => response.data);
  },

  /**
   * Fetch NFIRS export settings.
   *
   * @return Promise<Object>
   */
  getSettings() {
    return api()
      .get('/export-nfirs/settings')
      .then(response => response.data);
  },

  /**
   * Save NFIRS export settings.
   *
   * @param {Object} settings - Settings data
   *
   * @return Promise<Object>
   */
  saveSettings(settings) {
    return api()
      .post('/export-nfirs/settings', settings)
      .then(response => response.data);
  },

  /**
   * Delete NFIRS export settings.
   *
   * @return Promise<Object>
   */
  deleteSettings() {
    return api()
      .delete('/export-nfirs/settings')
      .then(response => response.data);
  },

  /**
   * Get list of available Call Types
   *
   * @return Promise<Object>
   */
  getCallTypes() {
    return api()
      .get('/export-nfirs/call-types')
      .then(response => response.data);
  },

  /**
   * Get list of upload Types
   *
   * @return Promise<Object>
   */
  getUploadTypes() {
    return api()
      .get('/export-nfirs/upload-types')
      .then(response => response.data);
  },
};
