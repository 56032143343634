<template>
  <tr v-if="!items.length">
    <td :colspan="headers.length">
      <div class="no-data-available">
        <div class="container">
          <div class="middle">
            <div class="inner">
              No data available
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'NoDataAvailable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.no-data-available {
  height: 250px;
}

.no-data-available .container {
  display: table;
  width: 100%;
  height: 100%;
}

.no-data-available .middle {
  display: table-cell;
  vertical-align: middle;
}

.no-data-available .inner {
  display: table;
  margin: 0 auto;
}
</style>
