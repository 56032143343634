<template>
  <div>
    <v-row>
      <v-col md="6" />
      <v-col md="6">
        <v-row>
          <v-col md="5">
            <img
                :src="require('@/assets/img/MobileMedic_logo1_blackred.png')"
                alt=""
                class="float-right"
                style="max-width: 200px"
            >
          </v-col>
          <v-col md="6">
            <div class="invoice-header-line">
              <b class="mr-1">Company:</b>
              <span :title="headerData.company">{{ headerData.company }}</span>
            </div>
            <div class="invoice-header-line">
              <b class="mr-1">Address:</b>
              <span :title="headerData.address">{{ headerData.address }}</span>
            </div>
            <div class="invoice-header-line">
              <b class="mr-1">Phone:</b>
              <span :title="headerData.phone">{{ headerData.phone }}</span>
            </div>
            <div class="invoice-header-line">
              <b class="mr-1">URL:</b>
              <span :title="headerData.url">{{ headerData.url }}</span>
            </div>
            <div class="invoice-header-line">
              <b class="mr-1">Email:</b>
              <span :title="headerData.email">{{ headerData.email }}</span>
            </div>
          </v-col>
          <v-col md="1">
            <v-btn
              :loading="loadingHeader"
              icon
              @click="$refs.headerEditPopup.show()"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <toolbar
        ref="invoiceToolbar"
      class="ml-4 mr-4"
      @changeClientId="changeClient"
      @clickAddNew="$refs.invoicePopup.show(client)"
    />
    <v-card class="ma-4">
      <v-card-title>
        Latest Invoices Created ({{ client ? client.name : 'ALL' }})
      </v-card-title>
      <invoices-table
          :invoices="invoices"
          :loading="loadingInvoices"
          :client-clickable="!client"
          @edit="edit"
          @clientClick="clickClientHandler"
      />
    </v-card>

    <edit-header-popup
      ref="headerEditPopup"
      :header-data.sync="headerData"
    />
    <invoice-popup
      ref="invoicePopup"
      @saved="getInvoices"
    />
  </div>

</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import invoices from '@/api/invoices';
import EditHeaderPopup from '@/components/Invoices/EditHeaderPopup';
import Toolbar from '@/components/Invoices/Toolbar';
import InvoicePopup from '@/components/Invoices/InvoicePopup';
import InvoicesTable from '@/components/Invoices/InvoicesTable';

export default {
  name: 'Invoices',
  components: {
    InvoicesTable, InvoicePopup, Toolbar, EditHeaderPopup,
  },
  data() {
    return {
      loading: false,
      loadingHeader: false,
      loadingInvoices: false,
      headerData: {},
      invoices: [],
      client: null,
    };
  },

  mounted() {
    this.$store.commit(SET_TITLE, 'Invoicing');
    this.fetchData();
    this.getInvoices();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      try {
        await this.getHeaderInfo();
      } finally {
        this.loading = false;
      }
    },

    async getHeaderInfo() {
      this.loadingHeader = true;
      try {
        this.headerData = await invoices.getHeader();
      } finally {
        this.loadingHeader = false;
      }
    },

    async getInvoices() {
      this.loadingInvoices = true;
      try {
        if (this.client) {
          this.invoices = await invoices.getInvoices(this.client.registryId);
        } else {
          this.invoices = await invoices.getLastInvoices();
        }
      } finally {
        this.loadingInvoices = false;
      }
    },

    changeClient(client) {
      this.client = client;
      this.getInvoices();
    },

    /**
     * Edit invoice.
     *
     * @param {Object} client - Client object
     */
    clickClientHandler(client) {
      this.$refs.invoiceToolbar.setClient(client.registryId);
    },

    /**
     * Edit invoice.
     *
     * @param {Object} invoice - Invoice to edit
     */
    edit(invoice) {
      this.$refs.invoicePopup.show(invoice.client, invoice);
    },
  },
};

</script>
<style lang="scss">
.invoice-header-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
