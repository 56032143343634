<template>
  <div>
    <slot
      v-if="this.$can('adminView', 'viewResolver')"
      name="adminView"
    ></slot>
    <slot
      v-else-if="this.$can('clientView', 'viewResolver')"
      name="clientView"
    ></slot>
    <slot
        v-else-if="this.$can('hospitalView', 'viewResolver')"
        name="hospitalView"
    ></slot>
    <slot
      v-else-if="this.$can('publicView', 'viewResolver')"
      name="publicView"
    ></slot>
  </div>
</template>

<script>
export default {
  name: 'ViewResolver',
};
</script>
