<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-card-title>{{ title }}</v-card-title>

    <div
      id="mapContainer"
      style="position:relative;"
    >
      <div id="map-actions">
        <div
          id="viewMarkers"
          :class="['actionBtn', view === 'marker' ? 'active' : '']"
          @click="showMarkers()"
        >
          View Markers
        </div>
        <div
          id="viewAsHeatmap"
          :class="['actionBtn', view === 'heat' ? 'active' : '']"
          @click="showHeatmap()"
        >
          View as Heatmap
        </div>
        <div
            v-if="showSlider"
            id="radiusSlider"
            title="Intensity"
        >
          <v-slider
              v-model="slider"
              min="1"
              max="11"
              color="blue"
              @change="setHeatmapRadiusLevel"
          ></v-slider>
        </div>
      </div>
      <div
          ref="googleMap"
          class="google-map"
          v-bind:style="{ height: chartHeight + 'px' }"
      />
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';

/* eslint-disable no-undef */
export default {
  name: 'GoogleMap',
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
  },
  data() {
    return {
      slider: 6,
      view: 'marker',
      showSlider: false,
      bounds: null,
      map: null,
      markerCluster: null,
      heatmap: null,
      mapOptions: {
        zoom: 9,
        // Los angeles coordinates
        center: new google.maps.LatLng(34.052235, -118.243683),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      },
      heatmapSettings: {
        0: { radius: 1, maxIntensity: 2500 },
        1: { radius: 1, maxIntensity: 2500 },
        2: { radius: 1, maxIntensity: 2500 },
        3: { radius: 0.8, maxIntensity: 2500 },
        4: { radius: 0.4, maxIntensity: 2500 },
        5: { radius: 0.3, maxIntensity: 150 },
        6: { radius: 0.2, maxIntensity: 150 },
        7: { radius: 0.2, maxIntensity: 150 },
        8: { radius: 0.1, maxIntensity: 150 },
        9: { radius: 0.08, maxIntensity: 150 },
        10: { radius: 0.02, maxIntensity: 30 },
        11: { radius: 0.01, maxIntensity: 12 },
        12: { radius: 0.007, maxIntensity: 10 },
        13: { radius: 0.004, maxIntensity: 5 },
        14: { radius: 0.003, maxIntensity: 5 },
        15: { radius: 0.0036, maxIntensity: 5 },
        16: { radius: 0.0036, maxIntensity: 5 },
        17: { radius: 0.0002, maxIntensity: 5 },
        18: { radius: 0.0001, maxIntensity: 5 },
        19: { radius: 0.00005, maxIntensity: 4 },
        20: { radius: 0.00003, maxIntensity: 2.5 },
        21: { radius: 0.00003, maxIntensity: 2.5 },
        22: { radius: 0.00003, maxIntensity: 2.5 },
      },
      radiusLevelRate: {
        1: 0.3,
        2: 0.4,
        3: 0.5,
        4: 0.6,
        5: 0.8,
        6: 1,
        7: 1.3,
        8: 1.5,
        9: 1.7,
        10: 2,
        11: 3,
      },
      radiusLevel: 6,
      currentInfo: null,
    };
  },
  computed: {
    chartHeight() {
      return this.height - 64;
    },
    items() {
      return this.correctSameCoordinates(this.data);
    },
    positions() {
      return this.items.map(item => new google.maps.LatLng(item.II_Latitude, item.II_Longitude));
    },
    markers() {
      if (!this.map) {
        return [];
      }
      const { map } = this;
      return this.items.map(item => {
        const position = new google.maps.LatLng(item.II_Latitude, item.II_Longitude);
        const marker = new google.maps.Marker({
          position,
          map,
          title: item.II_SequenceNumber,
        });
        google.maps.event.addListener(marker, 'click', (event) => {
          if (this.currentInfo !== null) {
            this.currentInfo.close();
          }
          this.currentInfo = new google.maps.InfoWindow({
            maxWidth: 350,
            position: event.latLng,
            content: `${`<div class="infowindow"><h4 class="info-title">${item.II_SequenceNumber}</h4>`
                + `<p class="color-red"><span class="inline-span">Date:</span> ${item.TI_DispatchDate1 || ''}</p>`
                + `<p class="color-red"><span class="inline-span">Time:</span> ${item.TI_DispatchTime1 || ''}</p>`
                + `<p class="color-red"><span class="inline-span"> C/C:</span> ${item.complaints || ''}</p>`
                + `<p class="color-red"><span class="inline-span">Unit:</span> ${item.TI_Unit1 || ''}</p>`}${
              this.$can('seeLinkToIncident', 'googleMap')
                ? `<p style="text-align:center;"><a href="/incidents/${item.id}"target="_blank">`
                  + 'Link to Incident</a></p>'
                : ''
            }</div>`,
          });
          this.currentInfo.open(this.map);
        });
        return marker;
      });
    },
  },
  mounted() {
    this.map = new google.maps.Map(this.$refs.googleMap, this.mapOptions);
  },
  watch: {
    data() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.map) {
        return;
      }
      this.initBounds();
      this.initMarkerCluster();
      this.initHeatmap();
      this.view = 'marker';
      this.showSlider = false;
      this.hideHeatmap();
    },

    initHeatmap() {
      if (this.heatmap) {
        this.hideHeatmap();
      }
      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: this.positions,
      });
      this.map.addListener('zoom_changed', () => {
        this.setupHeatmap();
      });
    },

    initMarkerCluster() {
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
      }
      this.markerCluster = new MarkerClusterer(this.map, this.markers, {
        imagePath: '/spa/images/markerclusterer/m',
        maxZoom: 17,
      });
    },

    initBounds() {
      const bounds = new google.maps.LatLngBounds();
      this.positions.forEach(position => {
        bounds.extend(position);
      });
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        if (this.positions.length) {
          this.map.fitBounds(bounds);
        }
      });
      if (this.positions.length) {
        this.map.fitBounds(bounds);
        this.map.setCenter(bounds.getCenter());
      }
    },

    setupHeatmap() {
      this.heatmap.set('opacity', 0.8);
      this.heatmap.set('dissipating', false);
      this.setHeatmapRadiusLevel(this.radiusLevel);
      const settings = this.heatmapSettings[this.map.getZoom()]
        ? this.heatmapSettings[this.map.getZoom()]
        : this.heatmapSettings[0];
      this.heatmap.set('maxIntensity', settings.maxIntensity);
    },

    setHeatmapRadiusLevel(level) {
      const zoom = this.map.getZoom();
      const settings = this.heatmapSettings[zoom]
        ? this.heatmapSettings[zoom]
        : this.heatmapSettings[0];
      this.heatmap.set('radius', settings.radius * this.radiusLevelRate[level]);
    },

    /**
     * Find incidents with same coordinates and move it little bit.
     *
     * @param {Array} data - List of location data
     */
    correctSameCoordinates(data) {
      const result = [];
      const grouped = data.reduce((accumulator, currentValue) => {
        const accum = accumulator;
        const key = `${currentValue.II_Latitude}|${currentValue.II_Longitude}`;
        (accum[key] = accumulator[key] || []).push(currentValue);
        return accum;
      }, {});
      _.forEach(grouped, (value) => {
        if (value.length === 1) {
          result.push(value[0]);
        } else {
          this.correctLocList(value);
          value.forEach(item => {
            result.push(item);
          });
        }
      });

      return result;
    },

    /**
     * To correct coordinates to group of incidents with same coordinates.
     *
     * @param {Array} loclist - List incidents with same coordinates
     */
    correctLocList(loclist) {
      const lngRadius = 0.00003;
      const latRadius = 0.00003;
      const loclen = loclist.length;
      const step = (2 * Math.PI) / loclen;
      let angle = 0.5; // starting angle, in radians
      loclist.forEach(item => {
        const loc = item;
        loc.II_Latitude += (Math.cos(angle) * lngRadius);
        loc.II_Longitude += (Math.sin(angle) * latRadius);
        angle += step;
      });
    },

    showMarkers() {
      if (this.view === 'marker') {
        return;
      }
      this.showSlider = false;
      this.view = 'marker';
      this.markers.forEach(marker => {
        marker.setVisible(true);
      });
      this.markerCluster.addMarkers(this.markers);
      this.hideHeatmap();
    },

    hideMarkers() {
      this.markers.forEach(marker => {
        marker.setVisible(false);
      });
      this.markerCluster.clearMarkers();
      this.view = 'heat';
    },

    showHeatmap() {
      if (this.view === 'heat') {
        return;
      }
      this.view = 'heat';
      this.showSlider = true;
      this.heatmap.setMap(this.map);
      this.setupHeatmap();
      this.hideMarkers();
    },

    hideHeatmap() {
      this.heatmap.setMap(null);
      this.view = 'marker';
      this.showSlider = false;
      this.radiusLevel = 6;
      this.slider = 6;
    },
  },
};
/* eslint-enable no-undef */
</script>

<style lang="scss">
.google-map {
  width: 100%;
}
.inline-span {
  display: inline-block;
  width: 38px;
  text-align: right;
  color: black;
}
.infowindow {
  width: 250px;
}
.info-title {
  text-align:center;
  margin-bottom: 10px;
  font-weight: bold;
}
.color-red {
  color:red;
}

#map-actions {
  position: absolute;
  top: 10px;
  height: 40px;
  width: auto;
  left: 25%;
  z-index: 5;
  background-color: #fff;
  text-align: center;
  font-family: 'Roboto','sans-serif';
  line-height: 30px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

#map-actions .actionBtn {
  float: left;
  color: rgb(86, 86, 86);
  font-size: 18px;
  font-family: 'Roboto';
  line-height: 40px;
  padding: 0px 10px;
  font-weight: 400;
}

#map-actions .actionBtn.active {
  color: rgb(0, 0, 0);
  font-weight: 500;
}

#map-actions .actionBtn:hover {
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  color: rgb(0, 0, 0);
}

#map-actions #viewMarkers {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid rgb(226, 226, 226);
  width: 140px;
  text-align: center;
}

#map-actions #viewAsHeatmap {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  width: 170px;
  text-align: center;
}

#map-actions #radiusSlider {
  clear: both;
  padding-top: 8px;
}
</style>
