<template>
  <div>
    <v-row>
      <v-col
          sm="6"
          class="pt-0 pb-0"
      >
        <v-autocomplete
            v-model="condition.insight"
            :items="insightValues"
            label="Insight"
            placeholder="Entire Agency"
            hide-details
            outlined
            dense
            clearable
        />
      </v-col>
      <v-col
          sm="6"
          class="pt-0 pb-0"
      >
        <v-autocomplete
            v-model="condition.focus"
            :items="focusValues"
            placeholder="Focus"
            hide-details
            outlined
            dense
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>

import _ from 'lodash';

export default {
  name: 'InsightsVitals',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Get list of insight values for select control.
     *
     * @return {Array}
     */
    insightValues() {
      if (!this.field.insightValues) {
        return [];
      }
      const items = [];
      _.forEach(this.field.insightValues, (text, code) => {
        items.push({
          text,
          value: code,
        });
      });
      items.push({
        text: 'Unknown',
        value: 'unknown',
      });
      return items;
    },

    /**
     * Get list of focus values for select control.
     *
     * @return {Array}
     */
    focusValues() {
      if (!this.field.focusValues) {
        return [];
      }
      const items = [];
      _.forEach(this.field.focusValues, (text, code) => {
        items.push({
          text,
          value: code,
        });
      });
      return items;
    },
  },
  watch: {
    condition: {
      handler() {
        this.$emit('update:condition', this.condition);
      },
      deep: true,
    },
  },
};
</script>
