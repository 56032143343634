<template>
  <div>
    <v-radio-group
        v-model="condition.signed"
        row
    >
      <v-radio
          label="Yes"
          :value="true"
      ></v-radio>
      <v-radio
          label="No"
          :value="false"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>

export default {
  name: 'HasSignature',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  watch: {
    condition: {
      handler() {
        this.$emit('update:condition', this.condition);
      },
      deep: true,
    },
  },
};
</script>
