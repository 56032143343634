<template>
  <div class="ce-settings">
    <v-card
        class="ce-settings-categories"
    >
      <v-row>
        <v-col
            md="3"
            class="pt-0 pb-0 pr-0"
        >
          <div class="categories-tabs">
            <v-tabs
                v-model="tabIndex"
                vertical
                background-color="#f5f5f6"
            >
              <v-tab
                  v-for="(item, index) in categories"
                  :key="'categorytab_' + index"
              >
                {{ item.name }}&nbsp;<span v-if="componentsStatuses[item.component].edited">*</span>
              </v-tab>
            </v-tabs>
          </div>
        </v-col>

        <v-col
            md="9"
            class="pt-0 pb-0 pl-0"
        >
          <div
              class="settings-content-container"
          >
            <v-card
              :loading="loading"
              flat
            >
              <v-card-title v-if="currentCategory">
                <span class="mr-4">{{ currentCategory.title }}</span>
                <v-tooltip
                    v-if="currentCategory.showTooltip"
                    bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>
                        mdi-message-question-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-html="currentCategory.tooltip" />
                </v-tooltip>

                <v-spacer />
                <template v-if="currentCategory.showAddActionButton">
                  <v-btn
                      color="primary"
                      @click="addNew"
                  >
                    + Add
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                      :disabled="!edited || saving"
                      class="mr-3"
                      @click="cancelChanges"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      :disabled="!edited"
                      :loading="saving"
                      color="primary"
                      @click="saveChanges"
                  >
                    Save
                  </v-btn>
                </template>
              </v-card-title>
              <v-divider />
              <v-card-text class="settings-content-container-text">
                <v-tabs-items :value="tabIndex">
                  <v-tab-item
                      v-for="(item, index) in categories"
                      :key="'categorySettings_' + index"
                  >
                    <div v-if="componentExists(item.component)">
                      <component
                          v-bind:is="item.component"
                          :ref="item.component"
                          @loading="changeLoading"
                          @edited="changeEdited"
                          @saving="changeSaving"
                          @licenseHoursUpdated="licenseHoursUpdatedHandler"
                          @licenseSkillsUpdated="$emit('licenseSkillsUpdated')"
                          @skillsListUpdated="$emit('skillsListUpdated')"
                      ></component>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <confirmation ref="confirmationPopup" />
  </div>
</template>
<script>
import EvaluationQuestions from '@/components/CeEntry/Settings/EvaluationQuestions';
import Certificates from '@/components/CeEntry/Settings/Certificates';
import LicenseHours from '@/components/CeEntry/Settings/LicenseHours';
import LicenseSkills from '@/components/CeEntry/Settings/LicenseSkills';
import SkillsList from '@/components/CeEntry/Settings/SkillsList';
import NotificationSettings from '@/components/CeEntry/Settings/NotificationSettings';
import ProviderList from '@/components/CeEntry/Settings/ProviderList';
import Confirmation from '@/components/Confirmation';

export default {
  name: 'Settings',
  components: {
    Confirmation,
    LicenseHours,
    LicenseSkills,
    SkillsList,
    EvaluationQuestions,
    Certificates,
    NotificationSettings,
    ProviderList,
  },
  data() {
    return {
      tabIndex: 0,
      additionalIndent: 170,
      componentsStatuses: {
        LicenseHours: {
          loading: false,
          edited: false,
          saving: false,
        },
        LicenseSkills: {
          loading: false,
          edited: false,
          saving: false,
        },
        SkillsList: {
          loading: false,
          edited: false,
          saving: false,
        },
        EvaluationQuestions: {
          loading: false,
          edited: false,
          saving: false,
        },
        Certificates: {
          loading: false,
          edited: false,
          saving: false,
        },
        NotificationSettings: {
          loading: false,
          edited: false,
          saving: false,
        },
        ProviderList: {
          loading: false,
          edited: false,
          saving: false,
        },
      },
      categories: [
        {
          name: 'License Hours',
          component: 'LicenseHours',
          title: 'License Hours Settings',
          showTooltip: true,
          tooltip: 'Here you can set the required number of hours needed to complete <br />'
              + 'Compliance training for different license levels over the course of <br />'
              + 'the training period. To disable Continuing Education tracking for specific <br />'
              + 'license types, simply uncheck the box next to the license type you wish to <br />'
              + 'deactivate.',
        },
        {
          name: 'License Skills',
          component: 'LicenseSkills',
          title: 'License Skills Settings',
          showTooltip: true,
          tooltip: 'If you want to track skills for specific license types, specify the number <br />'
              + 'of skills required to complete compliance training during the education period. <br />'
              + 'If you do not want to track skills, leave the field blank.',
        },
        {
          name: 'Skills List',
          component: 'SkillsList',
          title: 'Skills List',
          showTooltip: false,
          tooltip: null,
        },
        {
          name: 'Evaluation Questions',
          component: 'EvaluationQuestions',
          title: 'Evaluation Questions',
          showTooltip: false,
          tooltip: null,
        },
        {
          name: 'CE Certificate(s)',
          component: 'Certificates',
          title: 'CE Certificate Settings',
          showTooltip: false,
          tooltip: null,
        },
        {
          name: 'Notification Settings',
          component: 'NotificationSettings',
          title: 'Notification Settings',
          showTooltip: false,
          tooltip: null,
        },
        {
          name: 'Provider List',
          component: 'ProviderList',
          title: 'Provider List',
          showAddActionButton: true,
          showTooltip: false,
          tooltip: null,
        },
      ],
    };
  },
  computed: {
    currentCategory() {
      return this.categories[this.tabIndex];
    },
    currentSettingsComponent() {
      const componentName = this.currentCategory.component;
      if (Object.prototype.hasOwnProperty.call(this.$refs, componentName)) {
        return this.$refs[componentName][0];
      }
      return null;
    },
    loading() {
      return this.componentsStatuses[this.currentCategory.component].loading;
    },
    edited() {
      return this.componentsStatuses[this.currentCategory.component].edited;
    },
    saving() {
      return this.componentsStatuses[this.currentCategory.component].saving;
    },
  },
  methods: {
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
        this.$options.components,
        componentName,
      );
    },
    changeLoading(value, componentName) {
      this.componentsStatuses[componentName].loading = value;
    },
    changeEdited(value, componentName) {
      this.componentsStatuses[componentName].edited = value;
    },
    changeSaving(value, componentName) {
      this.componentsStatuses[componentName].saving = value;
    },
    cancelChanges() {
      this.$refs.confirmationPopup.showConfirm(
        'Cancel changes?',
        'Do you want to cancel editing? All changes will be lost.',
        () => {
          this.currentSettingsComponent.cancel();
        },
      );
    },
    addNew() {
      this.currentSettingsComponent.add();
    },
    saveChanges() {
      this.currentSettingsComponent.save();
    },
    licenseHoursUpdatedHandler() {
      this.$emit('licenseHoursUpdated');
      this.categories.forEach(category => {
        if (Object.prototype.hasOwnProperty.call(this.$refs, category.component)) {
          const component = this.$refs[category.component][0];
          component.updateLicenseHoursHandler();
        }
      });
    },
  },
};
</script>
<style>
.ce-settings {
  .v-tabs-bar {
    display: none;
  }
  .ce-settings-categories {
    .settings-content-container {
      height: calc(100vh - 170px);
      .settings-content-container-text {
        height: calc(100vh - 250px);
        overflow: auto;
      }
    }

    .categories-tabs {
      background-color: #f5f5f6;
      padding-left: 10px;
      border-right: 1px solid #e1e1e3;

      .v-item-group {
        height: calc(100vh - 170px);;
      }

      .v-tab {
        justify-content: left;
        text-transform: none;
        margin-left: 0px !important;
        max-width: none;
      }
    }

    .v-tabs-bar {
      display: flex;
    }
  }
}

</style>
