<template>
  <div>
    <v-toolbar
        class="nemsis-export-submission-logs-toolbar"
        flat
    >
      <v-autocomplete
          v-if="showClientsFilter"
          v-model="filtersData.clientId"
          :items="clients"
          label="Choose client"
          clearable
          hide-details
          style="max-width: 230px"
          class="mr-5"
      />
      <v-text-field
          v-model="filtersData.sequenceNumber"
          hide-details
          class="mr-5"
          label="Sequence Number"
          style="max-width: 230px"
      />
      <date-range-picker-with-periods
          v-model="filtersData.incidentDateRange"
          class="mr-5"
          position="right"
          label="Incident Date"
      />
      <v-checkbox
          v-model="filtersData.includeNew"
          value="1"
          class="mr-5"
          label="Include new"
          hide-details
      />
      <v-checkbox
          v-model="filtersData.includeProcessing"
          value="1"
          class="mr-5"
          label="In processing"
          hide-details
      />
      <v-spacer />
      <v-btn
          class="mr-3"
          color="primary"
          @click="$emit('search', filtersData)"
      >
        Search
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>

import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'UnsubmittedIncidentsToolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    showClientsFilter: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      filtersData: this.filters,
    };
  },

  computed: {
    clients() {
      const { clientsList } = this.$store.state;
      const initialList = [
        {
          text: 'All Clients',
          value: null,
        },
      ];
      if (!clientsList || !clientsList.length) {
        return initialList;
      }
      return initialList.concat(clientsList.map(client => ({
        text: client.name,
        value: client.registryId,
      })));
    },
  },

  methods: {

  },
};
</script>

<style lang="scss">
.nemsis-export-submission-logs-toolbar {
  z-index: 10;
}
</style>
