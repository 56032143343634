<template>
  <div>
    <v-card
        :loading="loading"
        class="elevation-3 ma-4"
        :min-height="56"
        flat
    >
      <v-expansion-panels
          v-if="monitors.length"
          class="incident-monitors"
      >
        <v-expansion-panel
            v-for="monitor in monitors"
            :key="monitor.id"
        >
          <v-expansion-panel-header>
            <div class="header">
              <v-chip
                  :color="monitor.error ? 'error' : 'green'"
                  class="ma-1"
                  text-color="white"
                  small
                  label
              >{{ monitor.error ? 'ERROR' : 'OK' }}</v-chip>:
              <span
                class="monitor-name"
                v-bind:style="{ maxWidth: titleWidth + 'px' }"
              >
                Name: <span :title="monitor.name"><b>{{ monitor.name }}</b></span></span> |

              <span>Frequency: <b>{{ monitor.frequency | frequencyText}}</b> | </span>
              <span>Last Run: <b>{{ monitor.lastRun | datetimeToClientTimezone }}
                {{ monitor.lastRun ? '' : 'never'}}
                <template v-if="monitor.lastRun">
                  ( <span
                    v-if="!monitor.error"
                    class="success-sent"
                  >
                     Success
                   </span>
                   <v-tooltip
                       v-if="monitor.error"
                       bottom
                   >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                            class="sent-error"
                            v-bind="attrs"
                            v-on="on"
                        >Error</span>
                      </template>
                      <span>{{ monitor.error }}</span>
                   </v-tooltip> )
                </template></b> | </span>
              <span>Last Matched: <b>{{ monitor.lastRunMatched || 0 }}</b></span>
            </div>

            <v-btn
                depressed
                small
                max-width="186"
                class="justify-space-between mr-2"
                @click="$emit('edit', monitor)"
            >
              Modify
              <v-icon right>settings</v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-card-text>
                <a @click="$emit('run-query', monitor)">Click here to run this query</a>
                <div>
                  Query criteria:<br />
                  <conditions-to-text
                    :all-fields="allFields"
                    :conditions="monitor.conditions"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-text
        v-else-if="!monitors.length && !loading"
        class="text-center"
      >
        There aren't any monitors, please add them first.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MONITOR_FREQUENCIES from '@/enums/monitorFrequencies';
import ConditionsToText from '@/components/Monitors/ConditionsToText';

export default {
  name: 'Monitors',
  components: { ConditionsToText },
  filters: {
    frequencyText(frequency) {
      const map = {};
      map[MONITOR_FREQUENCIES.DAILY] = 'Every Day';
      map[MONITOR_FREQUENCIES.WEEKLY] = 'Every Monday';
      map[MONITOR_FREQUENCIES.MONTHLY] = 'First of Every Month';
      map[MONITOR_FREQUENCIES.HALF_MONTH] = '15th of Every Month';
      return map[frequency];
    },
  },

  data() {
    return {
      titleWidth: 300,
      indent: 600,
      titleMinWidth: 300,
    };
  },

  props: {
    monitors: {
      type: Array,
      required: true,
    },
    allFields: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener('resize', this.recalculateTitleWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateTitleWidth);
  },

  updated() {
    this.recalculateTitleWidth();
  },

  methods: {
    recalculateTitleWidth() {
      const headers = document.getElementsByClassName('header');
      if (headers.length) {
        const width = headers[0].clientWidth - this.indent;
        this.titleWidth = width > this.titleMinWidth ? width : this.titleMinWidth;
      }
    },
  },
};
</script>

<style lang="scss">
.incident-monitors .header {
  display: block;
  .success-sent {
    color: green;
  }
  .sent-error {
    color: red;
  }
}
.monitor-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  vertical-align: sub;
}
</style>
