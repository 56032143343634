<template>
  <div class="transaction-logs-table">
    <v-data-table
        :headers="header"
        :items="entity.items || []"
        :options.sync="pagination"
        :server-items-length="entity.total"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        :height="tableHeight"
        hide-default-header
        fixed-header
    >
      <template v-slot:header="{ props: { headers } }">
        <thead class="v-data-table-header">
        <tr>
          <th
              v-for="header in headers"
              :key="header.text"
              :class="[
                  header.sortable ? 'sortable' : '',
                  header.value === pagination.sortBy[0] ? 'active' : '',
                  header.align === 'left' ? 'text-left' : '',
                  pagination.sortDesc[0] ? 'desc': 'asc'
                ]"
              class="field-col text-left"
              :style="header.width ? 'width: ' + header.width : null"
              @click="header.sortable ? changeSort(header.value) : null"
          >
            <span v-html="header.text"></span>
            <v-icon
                v-if="header.sortable"
                class="v-data-table-header__icon"
                small
            >mdi-arrow-up</v-icon>
          </th>
        </tr>
        <tr>
          <th
              v-for="header in headers"
              :key="'search_' + header.value"
              :class="['filter-col', (header.value === 'created' ? 'daterangepicker-th' : '')]"
          >
            <template v-if="header.searchable">
              <v-text-field
                v-if="header.value !== 'created'"
                v-model="filters[header.value]"
                :label="header.text"
                prepend-inner-icon="search"
                clearable
                hide-details
              ></v-text-field>
              <date-range-picker-with-periods
                v-if="header.value === 'created'"
                v-model="dateRange"
                :clearable="true"
                :label="header.text"
                position="right"
                hide-details
              />
            </template>
          </th>
        </tr>
        </thead>
      </template>
      <template v-slot:item.sequenceNumber="{ item }">
        <router-link
            v-if="item.incidentID && $can('view', 'incident')"
            :to="{ name: routes.INCIDENT_VIEW, params: { id: item.incidentID }}"
        ><b>{{ item.sequenceNumber }}</b></router-link>
        <b v-else>{{ item.sequenceNumber }}</b>
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | datetimeToClientTimezone }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import ROUTES from '@/enums/routes';
import { createNamespacedHelpers } from 'vuex';
import { SET_PAGINATION, SET_FILTERS } from '@/store/TransactionLogs/mutations';
import { LOAD_ITEMS } from '@/store/TransactionLogs/actions';
import fixedHeader from '@/mixins/fixedHeader';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import moment from 'moment';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('transactionLogs');

export default {
  name: 'TransactionLogsTable',
  components: { DateRangePickerWithPeriods },
  data() {
    return {
      loading: false,
      debounce: null,
      dateRange: null,
      header: [
        {
          text: 'Sequence Number',
          value: 'sequenceNumber',
          sortable: true,
          width: '16%',
          searchable: true,
        },
        {
          text: 'Date Time',
          value: 'created',
          sortable: true,
          width: '16%',
          searchable: true,
        },
        {
          text: 'User',
          value: 'username',
          sortable: true,
          searchable: true,
          width: '16%',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: true,
          width: '16%',
          searchable: true,
        },
        {
          text: 'Incident Number',
          value: 'incidentNumber',
          sortable: true,
          width: '16%',
          searchable: true,
        },
        {
          text: 'Patient Last Name',
          value: 'patientLastName',
          sortable: true,
          width: '16%',
          searchable: true,
        },
      ],
      routes: ROUTES,
    };
  },

  mixins: [fixedHeader],

  watch: {
    dateRange(newValue) {
      const filters = _.cloneDeep(this.filters);
      if (newValue && newValue.length) {
        const fromDate = moment(newValue[0]).startOf('day');
        const toDate = moment(newValue[1]).endOf('day');

        filters.rangeFrom = fromDate.utc().format('YYYY-MM-DD HH:mm:ss') || null;
        filters.rangeTo = toDate.utc().format('YYYY-MM-DD HH:mm:ss') || null;
      } else {
        filters.rangeFrom = null;
        filters.rangeTo = null;
      }
      this.filters = filters;
    },
    filters: {
      handler(newValue) {
        this.filters = newValue;
      },
      deep: true,
    },
    pagination: {
      handler(newValue) {
        this.pagination = newValue;
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),
    pagination: {
      get() {
        return this.entity.pagination;
      },
      set(value) {
        const isEqual = _.isEqual(value, this.entity.pagination);
        this.setPagination(value);
        if (!isEqual) {
          this.reloadItems();
        }
      },
    },
    filters: {
      get() {
        return this.entity.filters;
      },
      set(value) {
        this.setFilters(value);
        this.pagination.page = 1;
        if (_.isEqual(value, this.entity.pagination)) {
          return;
        }
        if (!this.debounce) {
          const that = this;
          this.debounce = _.debounce(() => {
            that.reloadItems();
          }, 300);
        }
        this.debounce();
      },
    },
  },

  created() {
    if (this.entity.items === null) {
      this.reloadItems();
    }
    this.recalculateHeights();
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PAGINATION,
      setFilters: SET_FILTERS,
    }),
    ...mapActions({
      loadLogs: LOAD_ITEMS,
    }),

    /**
     * Reloads paginated list for current filter.
     */
    async reloadItems() {
      this.loading = true;
      try {
        await this.loadLogs();
      } finally {
        this.loading = false;
      }
    },
    changeSort(column) {
      const pagination = _.clone(this.pagination);
      if (pagination.sortBy[0] === column) {
        if (pagination.sortDesc[0] === true) {
          pagination.sortBy = [];
          pagination.sortDesc = [];
        } else {
          pagination.sortDesc = [true];
        }
      } else {
        pagination.sortBy = [column];
        pagination.sortDesc = [false];
      }
      this.pagination = pagination;
    },
  },

};
</script>

<style lang="scss">
.transaction-logs-table {
  .filter-col {
    font-weight: normal;
    padding-bottom: 10px !important;
  }
  .daterangepicker-th {
    z-index: 100 !important;
  }
}
</style>
