export default {
  odMapHeader: 'OD MAP Exports',
  hdeHeader: 'HDE Exports',
  noMatchedIncident:
    'Incident data has been changed and no longer passes OD MAP criteria. '
    + 'However incident has already been exported to service and now requires '
    + 'manual removal.',
  tableHeaders: {
    sequenceNumber: 'Sequence Number',
    incidentNumber: 'Incident Number (Case Number)',
    incidentDateTime: 'Incident Date Time',
    status: 'Status',
    exportedAt: 'Exported At',
    error: 'Error',
  },
  filters: {
    selectClient: 'Select Client',
    sequenceNumber: 'Sequence Number',
  },
};
