<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    :height="tableHeight"
    class="header-nowrap incidents-table"
    fixed-header
    calculate-widths
  >
    <template v-slot:body="{ items }">
      <tbody>
        <no-data-available
          :headers="headers"
          :items="items"
        ></no-data-available>
        <tr
          v-for="(item, index) in items"
          :key="index"
          @click="incidentUnits(item)"
        >
          <td>
            <v-icon>chevron_right</v-icon>
          </td>
          <td><span>{{ item.id }}</span></td>
          <td><span>{{ item.IncidentNumber }}</span></td>
          <td><span>{{ item.IncidentDate | date }} {{ item.IncidentTime }}</span></td>
          <td><span>{{ item.IncidentStreetNumber }}</span></td>
          <td><span>{{ item.IncidentStreetDir }}</span></td>
          <td><span>{{ item.IncidentStreetName }}</span></td>
          <td><span>{{ item.IncidentStreetType }}</span></td>
          <td><span>{{ item.IncidentAptNumber }}</span></td>
          <td><span>{{ item.IncidentCityCode }}</span></td>
          <td><span>{{ item.IncidentZipcode }}</span></td>
          <td><span>{{ item.IncidentSceneGPSLocation }}</span></td>
          <td><span>{{ item.JurisdictionalStation }}</span></td>
          <td><span>{{ item.TactitalChannel }}</span></td>
          <td><span>{{ item.TriageInfo }}</span></td>
          <td><span>{{ item.IncidentType }}</span></td>
          <td><span>{{ item.FireBlock }}</span></td>
          <td><span>{{ item.CallCategory }}</span></td>
          <td><span>{{ item.EntryDate | date }} {{ item.EntryTime }}</span></td>
          <td><span>{{ item.lastUpdatedTime | datetime }}</span></td>
          <td><span>{{ item.ResponseTypeDate | date }} {{ item.ResponseTypeTime }}</span></td>
          <td><span>{{ item.ResponseTypeStart }}</span></td>
          <td><span>{{ item.ResponseTypeFinal }}</span></td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'IncidentsTable',
  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader],
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: '',
          sortable: false,
        },
        {
          text: this.$t('importedIncidents.tableHeaders.id'),
          value: 'id',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.number'),
          value: 'IncidentNumber',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.date'),
          value: 'IncidentDateTime',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.streetNumber'),
          value: 'IncidentStreetNumber',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.streetDir'),
          value: 'IncidentStreetDir',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.streetName'),
          value: 'IncidentStreetName',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.streetType'),
          value: 'IncidentStreetType',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.aptNumber'),
          value: 'IncidentAptNumber',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.cityCode'),
          value: 'IncidentCityCode',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.zip'),
          value: 'IncidentZipcode',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.gps'),
          sortable: false,
        },
        {
          text: this.$t('importedIncidents.tableHeaders.jurisdictionalStation'),
          value: 'JurisdictionalStation',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.tacticalChannel'),
          value: 'TactitalChannel',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.triageInfo'),
          value: 'TriageInfo',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.type'),
          value: 'IncidentType',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.fireBlock'),
          value: 'FireBlock',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.callCategory'),
          value: 'CallCategory',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.entryDate'),
          value: 'EntryDateTime',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.lastUpdatedTime'),
          value: 'lastUpdatedTime',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.responseTypeDate'),
          value: 'ResponseTypeDateTime',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.responseTypeStart'),
          value: 'ResponseTypeStart',
        },
        {
          text: this.$t('importedIncidents.tableHeaders.responseTypeFinal'),
          value: 'ResponseTypeFinal',
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },

  methods: {
    /**
     * Show incident units page if have not selection of text.
     *
     * @param {Object} incident - Object of incident
     */
    incidentUnits(incident) {
      const selection = window.getSelection();
      if (selection.type !== 'Range') {
        this.$emit('showUnits', incident);
      }
    },
  },
};
</script>

<style>
.incidents-table tbody tr {
  cursor: pointer;
}

.incidents-table tbody tr td span {
  cursor: text;
}
.incidents-table .v-data-table__wrapper {
  min-height: 573px;
}
</style>
