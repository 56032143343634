<template>
  <v-text-field
    v-model="textValue"
    :label="label"
    :maxlength="maxlength"
  />
</template>
<script>
export default {
  name: 'String',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    maxlength() {
      if (this.field.restrictions && this.field.restrictions.maxlen) {
        return this.field.restrictions.maxlen;
      }
      return 255;
    },
  },
};
</script>
