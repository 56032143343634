import {
  CLIENTS_LIST,
  TEMPLATES_LIST,
  TEMPLATES,
  TEMPLATE,
  FORM_DISABLED,
  CLIENT,
  EXPORT_TYPE,
  DHS,
  SELECTED_CLIENT,
  BILLING_TYPE,
  INCIDENTS,
  SEQUENCE_NUMBER,
  SEQUENCE_NUMBER_VALID,
  DATES,
} from './constants';

export default {
  [CLIENTS_LIST]: (state, getters, rootState) => {
    const { clientsList: clients } = rootState;

    if (!clients.length) return [];

    return clients.map(client => ({
      text: client.name,
      value: client.registryId,
    }));
  },
  [SELECTED_CLIENT]: (state, getters) => {
    const clientID = state[CLIENT];

    if (clientID === null) return '';

    const foundClient = getters[CLIENTS_LIST].find(
      client => client.value === clientID,
    );

    if (foundClient !== undefined && typeof foundClient === 'object') return foundClient.text;

    return '';
  },
  [BILLING_TYPE]: state => {
    if (state[EXPORT_TYPE] === DHS) return 'DHS';

    return 'Billing';
  },

  [TEMPLATES_LIST]: state => {
    if (!state[TEMPLATES].length) return [];

    return state[TEMPLATES].map(({ current, version }) => ({
      value: version,
      text: current ? `Template ${version} (Current)` : `Template ${version}`,
    }));
  },
  [SEQUENCE_NUMBER_VALID]: state => {
    const sequenceNumbers = state[SEQUENCE_NUMBER];

    if (!sequenceNumbers.length) return true;

    return sequenceNumbers.every(seqNum => /^[A-Z]{2}\d{6}(\d{4})?$/.test(seqNum));
  },
  [FORM_DISABLED]: (state, getters) => {
    if (state[CLIENT] === null) return true;
    if ((!state[DATES].from || !state[DATES].to) && !state[SEQUENCE_NUMBER].length) {
      return true;
    }

    if (state[EXPORT_TYPE] === DHS && state[TEMPLATE] === null) {
      return true;
    }

    return !getters[SEQUENCE_NUMBER_VALID];
  },
  incidents: state => {
    const { items, perPage, page } = state[INCIDENTS];

    return items.slice(perPage * (page - 1), perPage * (page - 1) + perPage);
  },
};
