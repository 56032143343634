import api from './api';

export default {
  /**
   * Fetch list of hospitals.
   *
   * @param {string} search - Params for search hospitals.
   * @param {int} page - Page number.
   * @param {int} perPage - Count of row on page.
   * @param {string} sortBy - Sorting param.
   * @param {string} descending - Desc or asc.
   */
  fetchSearchData(search, page, perPage, sortBy, descending) {
    const params = {
      ...search,
      page,
      per_page: perPage,
      order_by: sortBy,
      sort_order: descending ? 'desc' : 'asc',
    };

    return api()
      .get('hospitals', { params })
      .then(response => response.data);
  },

  /**
   * Delete hospital.
   *
   * @param {number} id - Identifier of hospital.
   */
  deleteHospital(id) {
    return api()
      .delete(`/hospitals/${id}`)
      .then(response => response.data);
  },

  /**
   * Restore hospital.
   *
   * @param {number} id - Identifier of hospital.
   */
  restoreHospital(id) {
    return api()
      .post(`/hospitals/${id}/restore`)
      .then(response => response.data);
  },

  /**
   * Restore hospital.
   *
   * @param {number} id - Identifier of hospital.
   */
  loadHospital(id) {
    return api()
      .get(`/hospitals/${id}`)
      .then(response => response.data);
  },

  /**
   * Update hospital profile.
   *
   * @param {Object} hospital - Hospital data.
   */
  saveHospitalProfile(hospital) {
    return api()
      .put('hospital-profile', hospital)
      .then(response => response.data);
  },

  /**
   * Update hospital.
   *
   * @param {Object} hospital - Hospital data.
   */
  saveHospital(hospital) {
    return api()
      .put(`/hospitals/${hospital.registryID}`, hospital)
      .then(response => response.data);
  },

  /**
   * Create new hospital.
   *
   * @param {Object} hospital - Hospital data.
   */
  createHospital(hospital) {
    return api()
      .post('/hospitals', hospital)
      .then(response => response.data);
  },

  /**
   * Validate unique value for hospital.
   *
   * @param {Object} value - Hospital field value.
   * @param {Object} hospitalID - Hospital identifier.
   * @param {Object} field - Hospital field name.
   */
  validateUnique(value, hospitalID, field) {
    const params = {
      value,
      field,
    };

    if (hospitalID) {
      params.hospitalID = hospitalID;
    }
    return api()
      .post('/hospitals/validate-unique', params)
      .then(response => response.data);
  },

  /**
   * Get url for impersonate hospital.
   *
   * @param {number} hospitalID - Hospital identifier.
   * @param {Boolean} createAdmin - Is there a need to create admin before impersonate.
   */
  getImpersonateUrl(hospitalID, createAdmin = false) {
    return api()
      .get(`/hospitals/${hospitalID}/impersonate`, { params: { createAdmin } })
      .then(response => response.data);
  },

  /**
   * Create hospital user.
   *
   * @param {Object} params - Create Hospital user data.
   */
  createUser(params) {
    return api()
      .post('/hospitals/user', params)
      .then(response => response.data);
  },

  /**
   * Search hospital incidents.
   *
   * @param {Object} params - Params for searching hospital incidents
   *
   * @returns Promise<Object>
   */
  searchHospitalIncidents(params) {
    return api()
      .get('/hospital-incidents', { params })
      .then(response => response.data);
  },

  /**
   * Get hospital incident fields.
   *
   * @param {string} incidentId - Incident ID
   *
   * @return Promise<Object>
   */
  fetchHospitalIncidentFields(incidentId) {
    return api()
      .get(`/hospital-incidents/${incidentId}/fields`)
      .then(response => response.data);
  },

  /**
   * Get short info about incident.
   *
   * @param {string} incidentId - Incident ID
   *
   * @returns Promise<Object>
   */
  fetchHospitalIncidentShortInfo(incidentId) {
    return api()
      .get(`/hospital-incidents/${incidentId}/short-info`)
      .then(responce => responce.data);
  },
};
