<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    class="units-table"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <no-data-available
          :headers="headers"
          :items="items"
        ></no-data-available>
        <tr
          v-for="(item, index) in items"
          :key="index"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.Unit }}</td>
          <td>{{ item.Type }}</td>
          <td>{{ item.DispatchDate | date }} {{ item.DispatchTime }}</td>
          <td>{{ item.ArrivalDate | date }} {{ item.ArrivalTime }}</td>
          <td>{{ item.LeftSceneDate | date }} {{ item.LeftSceneTime }}</td>
          <td>{{ item.AtFacilityDate | date }} {{ item.AtFacilityTime }}</td>
          <td>{{ item.AvailableDate | date }} {{ item.AvailableTime }}</td>
          <td>{{ item.EnrouteDate | date }} {{ item.EnrouteTime }}</td>
          <td>{{ item.AtPatientDate | date }} {{ item.AtPatientTime }}</td>
          <td v-if="showPreemptColumn">{{ item.PreemptDate | date }} {{ item.PreemptTime }}</td>
          <td>{{ item.updated | datetime }}</td>
          <td>{{ item.used | datetime }}</td>
          <td>{{ item.lastUpdatedTime | datetime }}</td>
          <td>{{ item.Seat1 }}</td>
          <td>{{ item.Seat2 }}</td>
          <td>{{ item.Seat3 }}</td>
          <td>{{ item.Seat4 }}</td>
          <td>{{ item.Seat5 }}</td>
          <td>{{ item.Seat6 }}</td>
          <td>{{ item.TransHospital }}</td>
          <td>{{ item.ModeOfTransport }}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'UnitsTable',
  components: {
    NoDataAvailable,
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showPreemptColumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t('importedIncidents.unitsTableHeaders.id'),
          value: 'id',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.unit'),
          value: 'Unit',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.type'),
          value: 'Type',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.dispatchDate'),
          value: 'DispatchDateTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.arrivalDate'),
          value: 'ArrivalDateTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.leftSceneDate'),
          value: 'LeftSceneDateTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.atFacilityDate'),
          value: 'AtFacilityDateTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.availableDate'),
          value: 'AvailableDateTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.enrouteDate'),
          value: 'EnrouteDateTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.atPatientDate'),
          value: 'AtPatientDateTime',
        },
      ];

      if (this.showPreemptColumn) {
        headers.push({
          text: this.$t('importedIncidents.unitsTableHeaders.preemptDate'),
          value: 'PreemptDateTime',
        });
      }

      headers = headers.concat([
        {
          text: this.$t('importedIncidents.unitsTableHeaders.updated'),
          value: 'updated',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.used'),
          value: 'used',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.lastUpdatedTime'),
          value: 'lastUpdatedTime',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.seat1'),
          value: 'Seat1',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.seat2'),
          value: 'Seat2',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.seat3'),
          value: 'Seat3',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.seat4'),
          value: 'Seat4',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.seat5'),
          value: 'Seat5',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.seat6'),
          value: 'Seat6',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.transHospital'),
          value: 'TransHospital',
        },
        {
          text: this.$t('importedIncidents.unitsTableHeaders.modeOfTransport'),
          value: 'ModeOfTransport',
        },
      ]);
      return headers;
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
};
</script>

<style>
.units-table .v-data-table__wrapper {
  min-height: 431px;
}
</style>
