<template>
  <v-autocomplete
    v-model="textValue"
    :label="label"
    :items="selectItems"
    clearable
  />
</template>
<script>
import _ from 'lodash';

export default {
  name: 'String',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        const updateValue = value === undefined ? null : value;
        this.$emit('input', updateValue);
      },
    },
    selectItems() {
      if (!this.field.values) {
        return null;
      }
      return _.map(this.field.values, (text, code) => ({
        text: `${code} - ${text}`,
        value: code,
      }));
    },
  },
};
</script>
