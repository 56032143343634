<template>
  <v-container
    grid-list-xl
    fluid
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        v-if="showClientsFilter"
        lg3
        xl3
      >
        <v-autocomplete
          v-model="filters.clientId"
          :items="clients"
          :label="$t('flaggedUsers.filters.selectClient')"
          item-value="registryId"
          item-text="name"
          single-line
        ></v-autocomplete>
      </v-flex>
      <v-flex
        lg3
        xl2
      >
        <date-picker
          v-model="filters.flagCreatedAt"
          :label="$t('flaggedUsers.filters.flagCreatedDate')"
          :max="today"
        ></date-picker>
      </v-flex>
      <v-flex
        lg3
        xl2
      >
        <v-text-field
          v-model="filters.sequenceNumber"
          :label="$t('flaggedUsers.filters.sequenceNumber')"
          clearable
        ></v-text-field>
      </v-flex>
      <v-flex
        lg3
        xl2
      >
        <v-select
          v-model="filters.status"
          :items="statuses"
          :label="$t('flaggedUsers.filters.status')"
          item-value="status"
          item-text="name"
          single-line
        ></v-select>
      </v-flex>
      <v-flex
        v-if="showButtons"
        :class="showClientsFilter ? 'xl3' : 'xl6'"
        lg12
        text-lg-right
      >
        <v-btn
          :loading="exportLoading"
          color="primary"
          dark
          class="ma-2"
          @click.native="$emit('exportToExcel')"
        >
          {{ $t('buttons.export') }}
        </v-btn>

        <v-btn
          :loading="resendLoading"
          color="primary"
          class="ma-2"
          @click.native="$emit('resendAll')"
        >
          Resend All Pending
          <v-icon
            right
            dark
          >send</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import FLAG_STATUSES from '@/enums/flagStatuses';
import DatePicker from '../DatePicker';

export default {
  name: 'FlaggedUsersToolbar',

  components: {
    DatePicker,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
    showClientsFilter: {
      type: Boolean,
      required: true,
    },
    showButtons: {
      type: Boolean,
      required: true,
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    resendLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      today: moment().toISOString(),
      statuses: [
        {
          status: null,
          name: this.$t('flaggedUsers.statuses.all'),
        },
        {
          status: FLAG_STATUSES.PENDING,
          name: this.$t('flaggedUsers.statuses.pending'),
        },
        {
          status: FLAG_STATUSES.UNDERSTOOD,
          name: this.$t('flaggedUsers.statuses.understood'),
        },
        {
          status: FLAG_STATUSES.DID_NOT_UNDERSTAND,
          name: this.$t('flaggedUsers.statuses.didNotUnderstand'),
        },
      ],
    };
  },

  computed: {
    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      return this.$store.state.clientsList;
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },
};
</script>
