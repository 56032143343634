export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_FORCE_SHOW_SITECODE = 'SET_FORCE_SHOW_SITECODE';
export const SET_SFTP_STATUS = 'SET_SFTP_STATUS';

export default {
  [SET_PROVIDERS]: (state, providers) => {
    state.providers = providers;
  },
  [SET_FORCE_SHOW_SITECODE]: (state, value) => {
    state.forceShowSiteCode = value;
  },
  [SET_SFTP_STATUS]: (state, value) => {
    state.sftpStatus = value;
  },
};
