var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"column-manager",attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"disabled":_vm.loading}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cog-outline ")]),_vm._v(" Column manager ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('b',[_vm._v("Manage columns")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search fields...","append-icon":"search","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('preset-manager',{ref:"presetManager",attrs:{"title":"Column Preset","type":_vm.presetTypes.COLUMN,"presets":_vm.entity.columnPresets,"data":_vm.presetData,"blank-data":{
        selected: [],
        groupBy: _vm.fieldsGroupBy.VALUES,
        showHistoricalFields: this.showHistoricalFields,
      },"pre-selected":_vm.preset ? _vm.preset.id : null},on:{"selected":_vm.setPreset,"deleted":_vm.deletePresetHandler,"updated":_vm.updatePresetHandler,"added":_vm.addPresetHandler}}),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"400px"}},[_c('fields-selector',{ref:"fieldsSelector",attrs:{"all-fields":_vm.allFields,"search":_vm.search,"show-historical-fields":_vm.showHistoricalFields,"group-by":_vm.groupBy,"locked":[
            _vm.incidentFields.SEQUENCE_NUMBER,
            _vm.incidentFields.INCIDENT_NUMBER,
            _vm.incidentFields.INCIDENT_DATE
        ],"selected":_vm.selectedClone},on:{"update:selected":function($event){_vm.selectedClone=$event}}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-checkbox',{attrs:{"label":"Show historical fields"},model:{value:(_vm.showHistoricalFields),callback:function ($$v) {_vm.showHistoricalFields=$$v},expression:"showHistoricalFields"}}),_c('span',{staticClass:"ml-5 mr-2"},[_vm._v("Group fields by:")]),_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"items":[_vm.fieldsGroupBy.NONE, _vm.fieldsGroupBy.VALUES],"dense":"","flat":""},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.apply()}}},[_vm._v(" Apply ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }