export const SET_STATES = 'SET_STATES';

export default {
  /**
   * Save lookup states to store.
   *
   * @param state - Vuex state.
   * @param data - List of states.
   */
  [SET_STATES]: (state, data) => {
    state.states = data;
  },
};
