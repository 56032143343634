<template>
  <v-card class="white elevation-3 ma-4 release-notes">
    <v-flex xs12>
      <div
        v-if="loading"
        class="text-center  pt-5 pb-5"
      >
        <v-progress-circular
          :size="100"
          color="grey darken"
          center
          indeterminate
        />
      </div>
      <div v-else>
        <v-tabs
          v-model="activeTab"
        >
          <v-tab>{{ $t('releaseNotes.all') }}</v-tab>
          <v-tab>{{ $t('releaseNotes.template') }}</v-tab>
          <v-tab>{{ $t('releaseNotes.backend') }}</v-tab>
          <v-tab>{{ $t('releaseNotes.ios') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item><release-notes-releases
            :releases="releaseNotes"
            :show-task-link="$can('seeTaskLink', 'releaseNotes')"
          /></v-tab-item>
          <v-tab-item><release-notes-releases
            :releases="templateReleaseNotes"
            :show-task-link="$can('seeTaskLink', 'releaseNotes')"
          /></v-tab-item>
          <v-tab-item><release-notes-releases
            :releases="backendReleaseNotes"
            :show-task-link="$can('seeTaskLink', 'releaseNotes')"
          /></v-tab-item>
          <v-tab-item><release-notes-releases
            :releases="iosReleaseNotes"
            :show-task-link="$can('seeTaskLink', 'releaseNotes')"
          /></v-tab-item>
        </v-tabs-items>
      </div>
    </v-flex>
  </v-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { SET_TITLE } from '@/store/mutations';
import ReleaseNotesReleases from '@/components/ReleaseNotes/ReleaseNotesReleases';
import RELEASE_NOTE_COMPONENTS from '@/enums/releaseNoteComponents';

export default {
  components: {
    ReleaseNotesReleases,
  },

  data() {
    return {
      loading: false,
      activeTab: 0,
      releaseNotesData: [],
    };
  },

  computed: {
    releaseNotesSorted() {
      const unreleased = _.values(
        _.pickBy(this.releaseNotesData, value => !value.info.released),
      );
      const released = _.values(
        _.pickBy(this.releaseNotesData, value => value.info.released),
      );

      const releasedSorted = _.sortBy(released,
        [(item) => item.info.releaseDate]).reverse();

      return unreleased.concat(releasedSorted);
    },

    /**
     * Filter only release note data.
     *
     * @return {Array}
     */
    releaseNotes() {
      if (this.$can('seeNotReleased', 'releaseNotes')) {
        return this.releaseNotesSorted;
      }
      return _.values(
        _.pickBy(this.releaseNotesSorted, value => value.info.released),
      );
    },

    /**
     * Filter only Template releases.
     *
     * @return {Array}
     */
    templateReleaseNotes() {
      return _.values(
        _.pickBy(
          this.releaseNotes,
          value => _.startsWith(value.info.name, RELEASE_NOTE_COMPONENTS.TEMPLATE),
        ),
      );
    },

    /**
     * Filter only Backend releases.
     *
     * @return {Array}
     */
    backendReleaseNotes() {
      return _.values(
        _.pickBy(
          this.releaseNotes,
          value => _.startsWith(value.info.name, RELEASE_NOTE_COMPONENTS.BACKEND),
        ),
      );
    },

    /**
     * Filter only iOS releases.
     *
     * @return {Array}
     */
    iosReleaseNotes() {
      return _.values(
        _.pickBy(
          this.releaseNotes,
          value => _.startsWith(value.info.name, RELEASE_NOTE_COMPONENTS.IOS),
        ),
      );
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('releaseNotes.header'));
    this.loadData();
  },

  methods: {
    /**
     * Load release notes json file.
     */
    async loadData() {
      this.loading = true;
      try {
        const randomStr = (Math.random() + 1).toString(36).substring(7);
        const response = await axios.get(`${process.env.VUE_APP_RELEASE_NOTES_URL}?anticache=${randomStr}`);
        this.releaseNotesData = response.data.reverse();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
