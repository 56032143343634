<template>
  <div>
    <v-snackbar
        v-for="(incident, id) in newIncidents"
        :key="incident"
        v-model="isShowMessage"
        :style="{'height': calculateMessageHeight(id)}"
        :timeout="-1"
        color="primary"
        right
    >
      <div :class="[$style['truncate-message']]">
        <router-link
            :class="[$style['new-incident-message']]"
            :to="{ name: routes.INCIDENT_VIEW, params: { id: incident.id }}"
            target="_blank"
        >
          {{ incident.agency }} has brought
          a {{ incident.age }} {{ incident.ageUnit }}
          with the following complaint(s): {{ incident.complaints.join(', ') }}
        </router-link>
      </div>
      <template v-slot:action>
        <v-btn
            icon
            @click="closeMessage(id)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="white elevation-3 ma-4">
      <hospital-incidents-toolbar
          :loading="loading"
          @search="search"
      />
    </v-card>
    <v-card class="white elevation-3 ma-4">
      <v-toolbar flat>
        <span :class="[$style['date'], 'mr-5']">{{ currentDateTime }}</span>
        <v-spacer />
        <v-switch
            v-model="isAlertOn"
            :label="`Alerts: ${isAlertOn ? 'On' : 'Off'}`"
        ></v-switch>
      </v-toolbar>
      <hospital-incidents-table
          ref="hospitalIncidentsTable"
          :items="incidents"
          :totalItems="incidentsTotal"
          :loading="loading"
          @updatePagination="searchIncidents"
          @downloaded="downloadedPdf"
      />
    </v-card>
  </div>
</template>

<script>
import hospitalsApi from '@/api/hospitals';
import usersApi from '@/api/users';
import profile from '@/api/profile';
import ROUTES from '@/enums/routes';
import HospitalIncidentsToolbar from '@/components/Hospitals/HospitalIncidentsToolbar';
import HospitalIncidentsTable from '@/components/Hospitals/HospitalIncidentsTable';
import { SET_TITLE, SET_IS_ALERT_ON, SET_USER } from '@/store/mutations';
import { mapMutations } from 'vuex';
import moment from 'moment';
import downloadPdf from '@/mixins/downloadPdf';

export default {
  components: {
    HospitalIncidentsToolbar,
    HospitalIncidentsTable,
  },
  mixins: [downloadPdf],
  data() {
    return {
      routes: ROUTES,
      loading: false,
      incidents: [],
      incidentsTotal: 0,
      interval: 60000,
      searchParams: {
        dateRangeFrom: null,
        dateRangeTo: null,
        rangeBy: null,
        sequenceNumber: null,
        lastName: null,
        agency: null,
        unit: null,
      },
      newIncidents: [],
      isShowMessage: true,
      currentDateTime: moment().format('MM/DD/YYYY h:mm A'),
    };
  },
  mounted() {
    this.setTitle('Incidents');
    if (this.isAlertOn) {
      this.syncProfile();
      this.listenNewHospitalIncidents();
    }
  },
  computed: {
    isAlertOn: {
      get() {
        return this.$store.state.user.isAlertOn;
      },
      set(value) {
        usersApi.patch(this.$store.state.user.registryId, { isAlertOn: value });
        this.$store.commit(SET_IS_ALERT_ON, value);
      },
    },
    echoChannel() {
      return `new-hospital-incident.${this.$store.state.user.hospitalId}`;
    },
  },
  watch: {
    isAlertOn(value) {
      this.syncProfile();
      if (value === true) {
        this.listenNewHospitalIncidents();
      } else {
        this.stopListenNewHospitalIncident();
      }
    },
  },
  methods: {
    ...mapMutations({
      setTitle: SET_TITLE,
      setUser: SET_USER,
    }),

    /**
     * Search incidents by filters.
     *
     * @param {Object} filters - Filters list
     */
    search(filters) {
      Object.keys(this.searchParams).forEach((key) => {
        this.searchParams[key] = filters[key] !== undefined ? filters[key] : null;
      });
      this.$refs.hospitalIncidentsTable.setPage(1);
      this.searchIncidents();
    },

    /**
     * Search incidents.
     *
     * @param {Object} pagination - Pagination params
     *
     * @returns {Promise}
     */
    async searchIncidents(pagination = {}) {
      try {
        this.loading = true;
        const params = this.prepareParams(pagination);
        const response = await hospitalsApi.searchHospitalIncidents(params);
        this.incidents = response.results.map((item) => {
          const incident = item;
          incident.isDownloadingPdf = false;
          return incident;
        });

        this.incidentsTotal = response.pagination.total;
        this.$refs.hospitalIncidentsTable.freshExpanded();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Pdf is downloaded.
     *
     * @param {Number} incidentId - incident identifier
     */
    downloadedPdf(incidentId) {
      this.incidents.forEach((incident, index) => {
        if (incident.id === incidentId) {
          this.incidents[index].isDownloadingPdf = false;
        }
      });
    },

    /**
     * Prepare pagination params.
     *
     * @param {Object} pagination - Pagination params
     *
     * @returns {Object}
     */
    prepareParams(pagination) {
      const params = {};
      Object.keys(this.searchParams).forEach((key) => {
        if (this.searchParams[key]) {
          params[key] = this.searchParams[key];
        }
      });
      params.page = pagination && pagination.page ? pagination.page : 1;
      params.per_page = pagination && pagination.itemsPerPage
        ? pagination.itemsPerPage
        : 25;
      return params;
    },

    /**
     * Reload profile for user.
     */
    async reloadUserProfile() {
      const user = await profile.fetchUser();
      this.setUser(user);
    },

    /**
     * Sync profile if alert is on.
     */
    syncProfile() {
      const intervalId = setInterval(() => {
        if (this.isAlertOn !== true) {
          clearInterval(intervalId);
          return;
        }
        this.reloadUserProfile();
      }, this.interval);
    },

    /**
     * Listen new hospital incidents.
     */
    async listenNewHospitalIncidents() {
      this.$echo.private(this.echoChannel).listen('.NewHospitalIncident', async (data) => {
        if (Object.keys(data).length && data.incidentId) {
          const incident = await this.getIncidentData(data.incidentId);

          if (Object.keys(incident).length) {
            this.newIncidents.push(incident);
          }
        }
      });
    },

    /**
     * Stop listen new hospital incident.
     */
    stopListenNewHospitalIncident() {
      this.$echo.leave(this.echoChannel);
    },

    /**
     * Calculate margin for message.
     *
     * @param {Number} id - Identifier of list incidents
     *
     * @return {string}
     */
    calculateMessageHeight(id) {
      return `${100 - 6 * id}vh`;
    },

    /**
     * Close message with id.
     *
     * @param {Number} id - Identifier of list incidents
     */
    closeMessage(id) {
      this.newIncidents.splice(id, 1);
    },

    /**
     * Get incident data.
     *
     * @param {String} incidentId - Incident ID
     *
     * @return {Promise<Object>|null}
     */
    async getIncidentData(incidentId) {
      let data;

      try {
        data = await hospitalsApi.fetchHospitalIncidentShortInfo(incidentId);
      } catch (e) {
        data = null;
      }

      return data;
    },
  },
};
</script>
<style lang="scss" module>
.date {
  font-size: 18px;
}
.new-incident-message {
  color: white !important;
  text-decoration: none;
}
.new-incident-message:hover {
  font-weight: bolder;
}
.truncate-message {
  max-width: 596px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
