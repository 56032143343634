<template>
  <div>
    <v-dialog
      v-model="showPopup"
      max-width="1000"
      @close="close"
    >
      <v-card>
        <v-container>
          <v-card-title class="headline pt-0">
            Diff Duplicate Incident:
            {{ duplicatedIncident ? duplicatedIncident.II_SequenceNumber : null }}
          </v-card-title>
          <v-card-text class="pt-0">
            <div
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                color="grey darken"
                center
                indeterminate
              />
            </div>
            <div v-else>
              <v-radio-group
                v-model="viewType"
                row
              >
                <v-radio
                  :label="$t('duplicateIncidents.diffTypeAll')"
                  value="all"
                />
                <v-radio
                  :label="$t('duplicateIncidents.diffTypeOnlyDifferences')"
                  value="onlyDiff"
                />
                <v-radio
                  :label="$t('duplicateIncidents.diffTypeHideEmpty')"
                  value="hideEmpty"
                />
              </v-radio-group>
              <div class="diff-container">
                <pre>
                  <span
                    v-for="(item, index) in diff"
                    :key="index"
                    :class="getClass(item)"
                  >{{ item.value }}</span>
                </pre>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              color="red darken-1"
              text
              @click.native="close"
            >{{ $t('buttons.close') }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { diffJson } from 'diff';
import { LOAD_INCIDENTS_DATA } from '@/store/DuplicateIncidents/actions';

const { mapState, mapActions } = createNamespacedHelpers('duplicateIncidents');

export default {
  name: 'DiffPopup',

  data() {
    return {
      showPopup: false,
      duplicatedIncident: null,
      loading: false,
      viewType: 'all',
    };
  },
  computed: {
    ...mapState({
      state: state => state,
    }),

    /**
     * Get original incident object with only difference fields with duplicate.
     *
     * @return {Object}
     */
    getOriginalOnlyDiff() {
      const { duplicateIncidentData } = this.state;
      return _.pickBy(
        this.state.originalIncidentData,
        (value, key) => value !== duplicateIncidentData[key],
      );
    },

    /**
     * Get duplicate incident object with only difference fields with original.
     *
     * @return {Object}
     */
    getDuplicateOnlyDiff() {
      const { originalIncidentData } = this.state;
      return _.pickBy(
        this.state.duplicateIncidentData,
        (value, key) => value !== originalIncidentData[key],
      );
    },

    /**
     * Get original incident object with hidden empty fields.
     *
     * @return {Object}
     */
    getOriginalHideEmpty() {
      const { duplicateIncidentData } = this.state;
      return _.pickBy(
        this.state.originalIncidentData,
        (value, key) => value || duplicateIncidentData[key],
      );
    },

    /**
     * Get duplicate incident object with hidden empty fields.
     *
     * @return {Object}
     */
    getDuplicateHideEmpty() {
      const { originalIncidentData } = this.state;
      return _.pickBy(
        this.state.duplicateIncidentData,
        (value, key) => value || originalIncidentData[key],
      );
    },

    /**
     * Get array of diff items.
     *
     * @return {Array}
     */
    diff() {
      let originalData = {};
      let duplicateData = {};
      switch (this.viewType) {
        case 'onlyDiff':
          originalData = this.getOriginalOnlyDiff;
          duplicateData = this.getDuplicateOnlyDiff;
          break;
        case 'hideEmpty':
          originalData = this.getOriginalHideEmpty;
          duplicateData = this.getDuplicateHideEmpty;
          break;
        default:
          originalData = this.state.originalIncidentData;
          duplicateData = this.state.duplicateIncidentData;
      }
      return diffJson(originalData, duplicateData);
    },
  },
  methods: {
    ...mapActions({
      loadIncidentsData: LOAD_INCIDENTS_DATA,
    }),

    /**
     * Show diff popup.
     *
     * @param {Object} incident - Duplicate incident object
     */
    async show(incident) {
      this.duplicatedIncident = incident;
      this.showPopup = true;
      this.loading = true;
      await this.loadIncidentsData({
        duplicateId: incident.id,
        originalId: incident.originalIncident.id,
      });
      this.loading = false;
    },

    /**
     * Get class by diff item object.
     *
     * @param {Object} diffItem - Object of diff item
     *
     * @return {String}
     */
    getClass(diffItem) {
      let diffClass = '';
      if (diffItem.removed) {
        diffClass = 'del';
      }
      if (diffItem.added) {
        diffClass = 'ins';
      }
      return diffClass;
    },

    /**
     * Close diff popup.
     */
    close() {
      this.showPopup = false;
      this.$emit('close', this.duplicatedIncident);
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables';

.diff-container {
  font-size: 11px;
  padding: 20px;
  background-color: $diff-background-color;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.del {
  text-decoration: none;
  color: $diff-del-color;
  background: $diff-del-background-color;
}

.ins {
  text-decoration: none;
  color: $diff-ins-color;
  background: $diff-ins-background-color;
}
</style>
