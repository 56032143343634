import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    title: 'Templates',
    templates: [],
    allTemplates: [],
    allTemplatesSortBy: 'name',
    search: {
      search: '',
      status: 'all',
    },
    pagination: {
      itemsPerPage: 25,
      sortDesc: [true],
      sortBy: ['name'],
    },
    paginationInfo: {},
    showEditPopup: false,
    selectedTemplate: {},
  },
  actions,
  mutations,
  getters,
};
