<template>
  <v-card
      elevation="3"
      class="ma-4"
  >
    <v-tabs v-model="tabIndex">
      <v-tab
          v-for="item in tabItems"
          :key="item.name"
          exact
          active-class="primary--text"
          class="subheading text-capitalize tab-item"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-divider class="mb-5"/>
    <v-tabs-items :value="tabIndex">
      <v-tab-item>
        <transaction-logs-table />
      </v-tab-item>
      <v-tab-item>
        <authorization-logs-table />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { CLEAR_STATE } from '@/store/TransactionLogs/mutations';
import { SET_TITLE } from '@/store/mutations';
import TransactionLogsTable from '@/components/Incidents/TransactionLogsTable';
import AuthorizationLogsTable from '@/components/Login/AuthorizationLogsTable';

export default {
  components: { AuthorizationLogsTable, TransactionLogsTable },
  data() {
    return {
      tabItems: [
        { name: 'Incident Logs' },
        { name: 'User Authorization Logs' },
      ],
      tabIndex: 0,

    };
  },
  created() {
    this.clearState();
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'Transaction Logs');
  },

  methods: {
    ...mapMutations({
      clearState: `transactionLogs/${CLEAR_STATE}`,
    }),
  },
};
</script>
