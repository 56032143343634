export default {
  header: {
    dhs: 'DHS Export',
    billing: 'Billing Export',
    unfiltered: 'Unfiltered Export',
  },
  tableHeaders: {
    dateTime: 'Export DateTime',
    records: '# of Records',
    dateRange: 'Export Data Range',
    status: 'Status',
  },
  detailsHeaders: {
    incidentDate: 'Incident Date',
    incidentNumber: 'Incident Number',
    sequenceNumber: 'Sequence Number',
    firstName: 'First Name',
    lastName: 'Last Name',
  },
};
