import { fetchHospitalsTypes } from '@/api/hospitalsTypes';

import { SET_HOSPITALS_TYPES } from './mutations';

export const FETCH_DATA = 'FETCH_DATA';

export default {
  [FETCH_DATA]: ({ commit }) => fetchHospitalsTypes()
    .then(data => commit(SET_HOSPITALS_TYPES, data)),
};
