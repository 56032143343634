export default {
  data() {
    return {
      localAccessRule: null,
    };
  },

  computed: {
    /**
     * Get map of application routes.
     *
     * @return {Array}
     */
    appRoutes() {
      const routerMap = {};
      this.$router.options.routes.forEach(route => {
        routerMap[route.name] = route;
      });
      return routerMap;
    },
  },

  methods: {
    /**
     * Extract access rule from navigation item.
     *
     * @return {mixed}
     */
    getRuleFromNavigationItem(item) {
      let rule = null;
      if ('route' in item) {
        const route = this.appRoutes[item.route];
        rule = route.meta.rule;
      }
      if ('rule' in item) {
        rule = item.rule;
      }
      return rule;
    },

    /**
     * Check access to access rule query.
     *
     * @return {Boolean}
     */
    checkAccess(rule) {
      if (typeof rule === 'string') {
        return this.$acl.check(rule);
      }
      this.localAccessRule = rule;
      return this.$acl.check('localAccessRule');
    },

    /**
     * Check access to navigation item.
     *
     * @return {Boolean}
     */
    haveAccess(item) {
      const rule = this.getRuleFromNavigationItem(item);
      return rule && this.checkAccess(rule);
    },
  },
};
