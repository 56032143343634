<template>
  <view-resolver>
    <cad-monitoring-admin slot="adminView"></cad-monitoring-admin>
    <cad-monitoring-client slot="clientView"></cad-monitoring-client>
    <cad-monitoring-public slot="publicView"></cad-monitoring-public>
  </view-resolver>
</template>
<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import CadMonitoringAdmin from './CadMonitoringAdmin';
import CadMonitoringClient from './CadMonitoringClient';
import CadMonitoringPublic from './CadMonitoringPublic';

export default {
  components: {
    ViewResolver,
    CadMonitoringAdmin,
    CadMonitoringClient,
    CadMonitoringPublic,
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$store.state.cadMonitoring.title);
  },
};
</script>
