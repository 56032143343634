import { render, staticRenderFns } from "./ApprovedOrRejectedCeEntryNotification.vue?vue&type=template&id=3722c3e7&scoped=true&"
import script from "./ApprovedOrRejectedCeEntryNotification.vue?vue&type=script&lang=js&"
export * from "./ApprovedOrRejectedCeEntryNotification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3722c3e7",
  null
  
)

export default component.exports