const INSIGHT_CATEGORIES = {
  THERAPY: 'therapy',
  PROVIDER_IMPRESSION: 'providerImpression',
  ADVANCED_AIRWAYS: 'advancedAirways',
  MEDS: 'meds',
  VITALS: 'vitals',
  TRANSPORT: 'transport',
  TRANSPORT_BY_PROVIDER_IMPRESSIONS: 'transportByProviderImpressions',
};

export default INSIGHT_CATEGORIES;
