<template>
  <v-dialog
    v-model="showPopup"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">{{ $t('duplicateIncidents.provideNewSeqNum') }}</v-card-title>
      <v-card-text>
        <v-alert
          v-model="errorAlert"
          type="error"
          outlined
          dismissible
          transition="scale-transition"
        >
          <div v-html="errorMessage" />
        </v-alert>
        <v-form @keyup.native.enter="confirm">
          <v-flex>
            <v-text-field
              v-model="sequenceNumber"
              v-validate="{ required: true }"
              :error-messages="errors.collect('sequenceNumber')"
              :label="$t('general.sequenceNumber')"
              name="sequenceNumber"
            />
          </v-flex>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click.native="showPopup = false"
        >{{ $t('buttons.cancel') }}</v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          text
          @click.native="changeAndCommit"
        >{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { COMMIT } from '@/store/DuplicateIncidents/actions';

const { mapActions } = createNamespacedHelpers('duplicateIncidents');

export default {
  name: 'ChangeAndCommitPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      showPopup: false,
      sequenceNumber: null,
      duplicatedIncident: null,
      loading: false,
      dictionary: {
        attributes: {
          sequenceNumber: this.$t('general.sequenceNumber'),
        },
      },
    };
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapActions({
      commit: COMMIT,
    }),

    /**
     * Show change and commit popup
     */
    show(incident) {
      this.duplicatedIncident = incident;
      this.showPopup = true;
      this.sequenceNumber = null;
      this.$validator.reset();
    },

    /**
     * Change Sequence Number and commit duplicate incident.
     */
    async changeAndCommit() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        try {
          this.loading = true;
          await this.commit({
            incident: this.duplicatedIncident,
            sequenceNumber: this.sequenceNumber,
          });
          this.showPopup = false;
          this.$emit('commited');
        } catch (e) {
          this.parseErrorResponse(e.response);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
