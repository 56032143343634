const defaultState = () => ({
  filters: {
    sequenceNumber: null,
    username: null,
    action: null,
    incidentNumber: null,
    patientLastName: null,
  },
  items: null,
  total: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true],
    page: 1,
    sortBy: ['created'],
    itemsPerPage: 25,
    totalItems: 0,
  },
});

export default defaultState;
