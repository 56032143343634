<template>
  <div>
    <v-dialog
        v-model="showProfilePopup"
        persistent
        max-width="600"
        @close="$emit('close')"
    >
      <v-card v-if="user">
        <v-container>
          <v-card-title class="headline pt-0">
            {{ $t('user.profile.header') }}
          </v-card-title>
          <v-card-text class="pt-0">
            <v-alert
                v-model="errorAlert"
                type="error"
                outlined
                dismissible
                transition="scale-transition"
                class="mb-4"
            >
              <div v-html="errorMessage" />
            </v-alert>

            <v-layout wrap>

              <v-flex xs5>
                <v-text-field
                    v-model="user.firstName"
                    v-validate="{ required: true }"
                    :label="$t('user.profile.firstName')"
                    :error-messages="errors.collect('firstName')"
                    name="firstName"
                />
              </v-flex>

              <v-flex
                  xs5
                  offset-xs1
              >
                <v-text-field
                    v-model="user.lastname"
                    v-validate="{ required: true }"
                    :label="$t('user.profile.lastName')"
                    :error-messages="errors.collect('lastName')"
                    name="lastName"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                    v-model="user.userName"
                    v-validate="{ required: true }"
                    :label="$t('user.profile.username')"
                    :error-messages="errors.collect('username')"
                    name="username"
                />
              </v-flex>

              <v-flex
                  :class="[$style.hidden]"
                  xs5
                  offset-xs1
              >
              </v-flex>

              <v-flex xs5>
                <v-text-field
                    v-model="user.phone"
                    append-outer-icon="mdi-pencil-outline"
                    :label="$t('user.profile.phone')"
                    readonly
                    name="phone"
                    @click:append-outer="editPhone"
                />
              </v-flex>

              <v-flex
                  xs5
                  offset-xs1
              >
                <v-text-field
                    v-model="user.email"
                    append-outer-icon="mdi-pencil-outline"
                    :label="$t('user.profile.email')"
                    readonly
                    name="email"
                    @click:append-outer="editEmail"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                    ref="password"
                    v-model="user.currentPassword"
                    v-validate="{ required: true }"
                    :error-messages="errors.collect('currentPassword')"
                    :label="$t('user.profile.password')"
                    name="currentPassword"
                    type="password"
                    append-icon="lock"
                    autocomplete="new-password"
                />
              </v-flex>

              <v-flex xs5 />

              <v-flex xs5>
                <v-text-field
                    ref="password"
                    v-model="user.password"
                    v-validate="{ regex: validatePasswordRegexp }"
                    :error-messages="errors.collect('password')"
                    :label="$t('user.profile.newPassword')"
                    name="password"
                    type="password"
                    append-icon="lock"
                    autocomplete="new-password"
                />
              </v-flex>
              <v-flex
                  xs5
                  offset-xs1
              >
                <v-text-field
                    v-model="user.retypePassword"
                    v-validate="{ confirmed: 'password' }"
                    :error-messages="errors.collect('retypePassword')"
                    :label="$t('user.profile.retypePassword')"
                    type="password"
                    name="retypePassword"
                    data-vv-as="password"
                    append-icon="lock"
                />
              </v-flex>

              <v-flex>
                <i>{{ $t('general.passwordFormat') }}</i>
              </v-flex>

            </v-layout>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
                color="blue darken-1"
                text
                @click.native="cancel()"
            >{{ $t('buttons.cancel') }}</v-btn>
            <v-btn
                :loading="false"
                color="primary"
                text
                @click.native="validateAndSave()"
            >{{ $t('buttons.save') }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <verify-phone-dialog
        ref="verifyPhoneDialog"
        @phoneUpdated="updatePhone"
    />
    <verify-email-dialog
        ref="verifyEmailDialog"
        @emailUpdated="updateEmail"
    />
  </div>
</template>

<script>
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import profile from '@/api/profile';
import VerifyPhoneDialog from '@/components/Profile/VerifyPhoneDialog';
import VerifyEmailDialog from '@/components/Profile/VerifyEmailDialog';

export default {
  name: 'ProfilePopup',
  components: { VerifyEmailDialog, VerifyPhoneDialog },
  mixins: [errorAlertMixins],
  data() {
    return {
      user: {},
      showProfilePopup: false,
      validatePasswordRegexp,
      dictionary: {
        attributes: {
          firstName: this.$t('user.profile.firstName'),
          lastName: this.$t('user.profile.lastName'),
          username: this.$t('user.profile.username'),
          currentPassword: this.$t('user.profile.password'),
        },
      },
    };
  },
  watch: {
    showProfilePopup() {
      this.$validator.reset();
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Hide profile popup.
     */
    cancel() {
      this.showProfilePopup = false;
    },

    /**
     * Show profile popup and load user clone.
     */
    showEdit(user) {
      this.user = user;
      this.showProfilePopup = true;
    },

    /**
     * Validate profile fields and save if it's ok.
     */
    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.savingUser = true;
      try {
        await profile.saveProfile(this.user);
        this.showProfilePopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.savingUser = false;
      }
    },

    /**
     * Show update phone dialog.
     */
    editPhone() {
      this.$refs.verifyPhoneDialog.show();
    },

    /**
     * Show update email dialog.
     */
    editEmail() {
      this.$refs.verifyEmailDialog.show();
    },

    updatePhone(phone) {
      this.user.phone = phone;
    },

    updateEmail(email) {
      this.user.email = email;
    },
  },
};
</script>

<style lang="scss" module>
.hidden {
  visibility: hidden;
}
</style>
