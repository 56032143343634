<template>
  <div class="joulesControl">
    <v-text-field
        v-model="textValue"
        :type="aedEnabled ? 'text' : 'number'"
        :label="label"
        :maxlength="maxlength"
        :readonly="aedEnabled"
    >
      <template v-slot:append-outer>
        <v-checkbox
            v-model="aedEnabled"
            label="AED"
            class="aedCheckbox mt-0"
            hide-details
            dense
        />
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  name: 'Joules',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    aedEnabled: {
      get() {
        return this.value && this.value === 'AED';
      },
      set(value) {
        if (value) {
          this.textValue = 'AED';
        } else {
          this.textValue = null;
        }
      },
    },
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    maxlength() {
      if (this.field.restrictions && this.field.restrictions.maxlen) {
        return this.field.restrictions.maxlen;
      }
      return 255;
    },
  },
};
</script>
<style lang="scss">
.joulesControl {
  .v-input__append-outer {
    margin-top: 5px !important;
  }
  .aedCheckbox label {
    top: 0px !important;
  }
}
</style>
