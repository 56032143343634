import {
  SET_SEQUENCE_NUMBER,
  SEQUENCE_NUMBER,
  SET_VALUE,
  SET_TEMPLATES,
  SET_DATE,
  TEMPLATES,
  DATES,
  SHOW_RESULTS,
  EXPORT_ID,
  IS_STARTED_PROCESSING,
  SET_EXPORT_ID,
  STATUS,
  SET_STATUS,
  INCIDENTS,
  SET_INCIDENTS_DATA,
  SET_INCIDENTS_ITEMS,
  SET_PARAM,
  SET_IS_STARTED_PROCESSING,
} from './constants';

export default {
  [SET_VALUE]: (state, { type, value }) => {
    state[type] = value;
  },
  [SET_SEQUENCE_NUMBER]: (state, data) => {
    state[SEQUENCE_NUMBER] = [...data];
  },
  [SET_TEMPLATES]: (state, data) => {
    state[TEMPLATES] = [...data];
  },
  [SET_DATE]: (state, { type, value }) => {
    state[DATES][type] = value;
  },
  [SHOW_RESULTS]: (state, flag) => {
    state[SHOW_RESULTS] = flag;
  },
  [SET_EXPORT_ID]: (state, value) => {
    state[EXPORT_ID] = value;
  },
  [SET_STATUS]: (state, status) => {
    state[STATUS] = status;
  },
  [SET_INCIDENTS_ITEMS]: (state, incidents) => {
    state[INCIDENTS].items = [...incidents];
  },
  [SET_INCIDENTS_DATA]: (state, { type, data }) => {
    state[INCIDENTS][type] = data;
  },
  [SET_PARAM]: (state, { type, value }) => {
    state[type] = value;
  },
  [SET_IS_STARTED_PROCESSING]: (state, value) => {
    state[IS_STARTED_PROCESSING] = value;
  },
};
