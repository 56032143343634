import _ from 'lodash';
import auditLog from '@/api/auditLog';
import { SET_AUDIT_LOG_ITEMS, SET_AUDIT_LOG_METHODS } from './mutations';

export const LOAD_AUDIT_LOG = 'LOAD_AUDIT_LOG';
export const LOAD_AUDIT_METHODS = 'LOAD_AUDIT_METHODS';

export default {
  /**
   * Load audit log list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} commit - Audit log state.
   *
   * @return Promise<Object>
   */
  [LOAD_AUDIT_LOG]: async ({ commit, state }) => {
    const { pagination, dynamicFilters } = state;
    const filters = _.cloneDeep(state.filters);

    if (filters.dateRange) {
      const [dateFrom, dateTo] = filters.dateRange;
      filters.dateRange = null;
      filters.dateFrom = dateFrom;
      filters.dateTo = dateTo;
    }

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...dynamicFilters,
      ...filters,
    };
    let results = {};

    try {
      results = await auditLog.fetchList(params);
      commit(SET_AUDIT_LOG_ITEMS, {
        items: results.results,
        totalItems: results.pagination.total,
      });
    } catch (error) {
      commit(SET_AUDIT_LOG_ITEMS, {
        items: [],
        totalItems: 0,
      });
      throw error;
    }

    return results;
  },

  /**
   * Load audit log methods.
   *
   * @param {Function} commit - State commit.
   *
   * @return Promise<Object>
   */
  [LOAD_AUDIT_METHODS]: async ({ commit }) => {
    const methods = await auditLog.fetchMethods();
    commit(SET_AUDIT_LOG_METHODS, methods.results);

    return methods;
  },
};
