<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      class="user-edit"
      max-width="700"
      @close="$emit('close')"
    >
      <v-card>
        <v-container>
          <v-card-title class="headline pt-0">
            {{ isNew ? $t('users.editPopup.headerNew') : $t('users.editPopup.headerEdit') }}
          </v-card-title>
          <v-card-text class="pt-0">
            <div
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                color="grey darken"
                center
                indeterminate
              />
            </div>
            <div v-else>
              <v-alert
                v-model="errorAlert"
                type="error"
                outlined
                dismissible
                transition="scale-transition"
                class="mb-4"
              >
                <div v-html="errorMessage" />
              </v-alert>
              <v-form>
                <v-layout wrap>
                  <v-flex xs5>
                    <v-text-field
                      v-model="user.firstName"
                      v-validate="{ required: true }"
                      :label="$t('users.editPopup.firstName')"
                      :error-messages="errors.collect('firstName')"
                      name="firstName"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    offset-xs1
                  >
                    <v-text-field
                      v-model="user.lastname"
                      v-validate="{ required: true }"
                      :label="$t('users.editPopup.lastName')"
                      :error-messages="errors.collect('lastname')"
                      name="lastname"
                    />
                  </v-flex>
                  <v-flex xs5>
                    <v-text-field
                      v-model="user.userName"
                      v-validate="{ required: true }"
                      :label="$t('users.editPopup.userName')"
                      :error-messages="errors.collect('userName')"
                      name="userName"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    offset-xs1
                  >
                    <v-text-field
                      v-model="user.email"
                      v-validate="{ required: true }"
                      :label="$t('users.editPopup.email')"
                      :error-messages="errors.collect('email')"
                      :disabled="!isNew && !canUpdateContactDetails"
                      name="email"
                    />
                  </v-flex>
                  <v-flex xs5>
                    <vue-tel-input-vuetify
                        v-if="showEditPopup"
                        v-model="phone"
                        :label="$t('users.editPopup.phone')"
                        :preferred-countries="['US']"
                        :disabled-fetching-country="true"
                        :error-messages="phoneNumberErrorMessages"
                        :disabled="!isNew && !canUpdateContactDetails"
                        class="user-phone"
                        outer="mdi-send"
                        mode="international"
                        @input="updatePhone"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    offset-xs1
                  >
                    <v-select
                      v-model="user.userTypeId"
                      v-validate="{ required: true }"
                      :items="userGroups"
                      :label="$t('users.editPopup.group')"
                      :error-messages="errors.collect('userTypeId')"
                      name="userTypeId"
                      @change="changeUserTypeHandler"
                    />
                  </v-flex>

                  <v-flex
                      v-if="siteRole === 'client'"
                      xs5
                  >
                    <v-text-field
                        v-model="user.nationalLicense"
                        :label="$t('users.editPopup.nationalLicense')"
                        :disabled="user.userTypeId === userRoles.CLIENT_QA_USER"
                        name="nationalLicense"
                    />
                  </v-flex>
                  <v-flex
                      v-if="siteRole === 'client'"
                      xs6
                      offset-xs1
                  >
                    <v-text-field
                        v-model="user.stateLicense"
                        :label="$t('users.editPopup.stateLicense')"
                        :disabled="user.userTypeId === userRoles.CLIENT_QA_USER"
                        name="stateLicense"
                    />
                  </v-flex>

                  <v-flex
                    v-if="siteRole === 'client'"
                    xs5
                  >
                    <v-text-field
                      v-model="user.EMT"
                      :label="$t('users.editPopup.emt')"
                      :disabled="user.userTypeId === userRoles.CLIENT_QA_USER"
                      name="emt"
                    />
                  </v-flex>

                  <v-flex
                      v-if="siteRole === 'client'"
                      xs6
                      offset-xs1
                  >
                    <v-select
                        v-model="user.licenseTypeId"
                        v-validate="{ required: user.userTypeId !== userRoles.CLIENT_QA_USER }"
                        :items="userLicenseTypes"
                        :label="$t('users.editPopup.licenseType')"
                        :error-messages="errors.collect('licenseTypeId')"
                        :disabled="user.userTypeId === userRoles.CLIENT_QA_USER"
                        name="licenseTypeId"
                    />
                  </v-flex>

                  <v-flex xs5>
                    <v-text-field
                      ref="password"
                      v-model="user.password"
                      v-validate="{ required: isNew, regex: validatePasswordRegexp }"
                      :error-messages="errors.collect('password')"
                      :label="$t('users.editPopup.password')"
                      name="password"
                      type="password"
                      prepend-icon="lock"
                      autocomplete="new-password"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    offset-xs1
                  >
                    <v-text-field
                      v-model="user.retypePassword"
                      v-validate="{ required: isNew, confirmed: 'password' }"
                      :error-messages="errors.collect('retypePassword')"
                      :label="$t('users.editPopup.retypePassword')"
                      type="password"
                      name="retypePassword"
                      data-vv-as="password"
                      prepend-icon="lock"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    mb-3
                  >
                    <i>{{ $t('general.passwordFormat') }}</i>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              v-if="!isNew"
              :disabled="savingUser"
              color="blue darken-1"
              text
              @click.native="$emit('clickDelete', user)"
            >{{ $t('buttons.delete') }}</v-btn>
            <v-btn
              :disabled="savingUser"
              color="blue darken-1"
              text
              @click.native="hidePopup()"
            >{{ $t('buttons.cancel') }}</v-btn>
            <v-btn
              :loading="savingUser"
              color="primary"
              text
              @click.native="validateAndSave()"
            >{{ $t('buttons.save') }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import apiUsers from '@/api/users';
import USER_ROLE_NAMES from '@/enums/userRoleNames';
import USER_ROLES from '@/enums/userRoles';

const { mapState } = createNamespacedHelpers('users');

export default {
  name: 'UsersEditPopup',

  mixins: [errorAlertMixins],

  props: {
    siteRole: {
      type: String,
      required: true,
    },
    canCreateAdmins: {
      type: Boolean,
      default: false,
    },
    isContinuingEducation: {
      type: Boolean,
      default: false,
    },
    canUpdateContactDetails: {
      type: Boolean,
      default: false,
    },
    licenseTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isNew: false,
      user: {},
      showEditPopup: false,
      loading: false,
      savingUser: false,
      validatePasswordRegexp,
      phone: null,
      phoneValid: false,
      userRoles: USER_ROLES,
      dictionary: {
        attributes: {
          firstName: this.$t('users.editPopup.firstName'),
          lastname: this.$t('users.editPopup.lastName'),
          userName: this.$t('users.editPopup.userName'),
          email: this.$t('users.editPopup.email'),
          userTypeId: this.$t('users.editPopup.group'),
          password: this.$t('users.editPopup.password'),
          retypePassword: this.$t('users.editPopup.retypePassword'),
          licenseTypeId: this.$t('users.editPopup.licenseType'),
        },
      },
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),

    phoneNumberErrorMessages() {
      const messages = this.errors.collect('phone');
      if (!this.phoneValid) {
        messages.push('The Phone field is invalid');
      }
      return messages;
    },

    userGroups() {
      let userGroups = [];
      if (this.siteRole === 'client') {
        if (this.isContinuingEducation) {
          userGroups = userGroups.concat([
            {
              text: USER_ROLE_NAMES.CLIENT_CE_USER,
              value: USER_ROLES.CLIENT_CE_USER,
            },
          ]);
        } else {
          userGroups = userGroups.concat([
            {
              text: USER_ROLE_NAMES.CLIENT_CAPTAIN_USER,
              value: USER_ROLES.CLIENT_CAPTAIN_USER,
            },
            {
              text: USER_ROLE_NAMES.CLIENT_FIREFIGHTER_USER,
              value: USER_ROLES.CLIENT_FIREFIGHTER_USER,
            },
            {
              text: USER_ROLE_NAMES.CLIENT_QA_USER,
              value: USER_ROLES.CLIENT_QA_USER,
            },
          ]);
        }

        if (this.canCreateAdmins) {
          userGroups.unshift({
            text: USER_ROLE_NAMES.CLIENT_ADMIN,
            value: USER_ROLES.CLIENT_ADMIN,
          });
        }
      } else if (this.siteRole === 'hospital') {
        userGroups = userGroups.concat([
          {
            text: USER_ROLE_NAMES.HOSPITAL_ADMIN,
            value: USER_ROLES.HOSPITAL_ADMIN,
          },
          {
            text: USER_ROLE_NAMES.HOSPITAL_USER,
            value: USER_ROLES.HOSPITAL_USER,
          },
        ]);
      }

      return userGroups;
    },
    userLicenseTypes() {
      const userLicenseTypes = [];

      this.licenseTypes.forEach((item) => {
        userLicenseTypes.push({
          text: item.name,
          value: item.licenseTypeId,
        });
      });

      return userLicenseTypes;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Save or create user.
     *
     * @return {Promise}
     */
    saveUser() {
      if (this.user.registryId) {
        return apiUsers.saveUser(this.user);
      }
      if (this.siteRole === 'client') {
        this.user.clientId = this.$store.state.user.clientId;
      } else if (this.siteRole === 'hospital') {
        this.user.hospitalId = this.$store.state.siteInfo.hospitalId;
      }
      return apiUsers.createUser(this.user);
    },

    /**
     * Validate and save user.
     */
    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      this.validatePhone();
      if (!await this.$validator.validateAll() || !this.phoneValid) {
        return;
      }
      this.savingUser = true;
      try {
        await this.saveUser();
        this.user = {};
        this.showEditPopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.savingUser = false;
      }
    },

    validatePhone() {
      if (!this.user.phone) {
        this.phoneValid = false;
      }
      return this.phoneValid;
    },

    /**
     * Show new edit popup.
     *
     * @param {Boolean} isNew - Flag determines user is new or edit existence
     * @param {Object} user - User object
     */
    showPopup(isNew, user) {
      this.user = {};
      this.$validator.reset();
      this.errors.clear();
      this.hideError();
      this.isNew = isNew;
      this.phone = user.phone;
      if (user.phoneVerified) {
        this.phone = user.phone;
        this.phoneValid = true;
      }
      if (this.isNew) {
        this.phoneValid = true;
      }
      this.user = user;
      this.showEditPopup = true;
    },

    /**
     * Hide edit user popup.
     */
    hidePopup() {
      this.user = {};
      this.showEditPopup = false;
    },

    /**
     * Show new user popup.
     */
    showNew() {
      this.showPopup(true, {
        firstName: null,
        lastname: null,
        userName: null,
        email: null,
        phone: null,
        userTypeId: null,
        EMT: '',
        password: null,
        retypePassword: null,
        weight: 0,
      });
    },

    /**
     * Show edit user popup.
     *
     * @param {Object} user - User object.
     */
    showEdit(user) {
      this.showPopup(false, user);
    },

    /**
     * Update phone.
     *
     * @param {String} number - Phone number
     * @param {Object} data - Phone data
     */
    updatePhone(number, data) {
      this.phoneValid = data.isValid;
      if (data.isValid === true) {
        this.user.phone = data.number.e164;
      } else {
        this.user.phone = '';
      }
    },

    changeUserTypeHandler() {
      if (this.user.userTypeId === this.userRoles.CLIENT_QA_USER) {
        this.user.licenseTypeId = null;
        this.user.EMT = null;
        this.user.stateLicense = null;
        this.user.nationalLicense = null;
      }
    },
  },
};
</script>
<style lang="scss">
.user-phone {
  @media (max-width: 665px) {
    div.country-code {
      display: none;
    }
  }

  div.v-text-field__details {
    div.v-messages__message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
