export default {
  header: 'Duplicate Incidents And JSON Uploading',
  duplicateIncident: 'Duplicate Incident',
  tableHeaders: {
    clientName: 'Client Name',
    sequenceNumber: 'Sequence Number',
    dateTimeSubmission: 'Date/Time Submission',
    incidentNumber: 'Incident Number',
    streetName: 'Street Name',
    streetNumber: 'Street Number',
    age: 'Age',
    sex: 'Sex',
    contact: 'Base Contact',
    patientFirstName: 'Patient First Name',
    patientLastName: 'Patient Last Name',
    jsonFileSize: 'JSON File Size',
    originalIncident: 'Original Incident',
    duplicateIncident: 'Duplicate Incident',
    json: 'JSON',
  },
  filters: {
    allClients: 'All Clients',
    selectClient: 'Select Client',
    sequenceNumber: 'Sequence Number',
  },
  buttons: {
    diff: 'Diff',
    overwrite: 'Overwrite',
    keepOriginal: 'Keep Original',
    change: 'Change',
  },
  diffTypeAll: 'All',
  diffTypeOnlyDifferences: 'Only Differences',
  diffTypeHideEmpty: 'Hide Empty',
  commitAndOwerwriteConfirmationTitle: 'Commit and Overwrite',
  commitAndOwerwriteConfirmationText:
    'Are you sure that you want to commit and overwrite this duplicate?',
  keepOriginalConfirmationTitle: 'Keep Original',
  keepOriginalConfirmationText:
    'Are you sure that you want to delete duplicate report and keep original one?',
  changeConfirmationTitle: 'Change SequenceNumber & Commit',
  changeConfirmationText:
    'Are you sure that you want to change SequenceNumber and commit this duplicate?',
  provideNewSeqNum: 'Provide new SequenceNumber',
};
