<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      :fullscreen="isPrinting"
      persistent
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card
        v-else-if="entry"
        :loading="loading || loadingForm"
        class="elevation-0"
    >
      <div class="attendees-evaluations">
        <v-tabs v-model="tabIndex">
          <v-tab>attendees</v-tab>
          <v-tab>form</v-tab>

          <v-tab-item>
            <v-card-title>
              <span>Attendees Evaluations</span>
            </v-card-title>
            <v-card-subtitle>
              {{entry.date|dateFormat}} — {{ entry.courseTitle }} — {{ entry.hours }} CE Hours
            </v-card-subtitle>
            <v-divider />
            <evaluation-attendees-table
                :attendees="attendees"
                class="mb-2"
                @evaluatedClick="showEvaluatedForm"
            />
          </v-tab-item>
          <v-tab-item>
            <div v-if="currentAttendee">
              <v-card-title>
                {{ entry.courseTitle }} - Course Evaluation
              </v-card-title>
              <v-card-subtitle>{{ currentAttendee.name }}</v-card-subtitle>
              <v-divider />

              <v-card-text
                  v-if="loadingForm"
                  class="mt-0 pt-3 text-center"
              >
                Loading...
              </v-card-text>
              <v-card-text
                  v-else
                  class="pt-4"
              >
                <v-alert
                    v-if="errorAlert"
                    type="error"
                    outlined
                    transition="scale-transition"
                    class="mt-2"
                >
                  {{ errorMessage }}
                </v-alert>
                <evaluation-answers-form
                    v-if="answers"
                    :first-name="answers.signFirstName"
                    :last-name="answers.signLastName"
                    :questions="questionsWithAnswers"
                />
              </v-card-text>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>

      <v-divider v-if="!isPrinting"></v-divider>
      <v-card-actions v-if="!isPrinting">
        <v-btn
            v-if="tabIndex === 1"
            rounded
            text
            @click="tabIndex = 0"
        >
          <v-icon left>chevron_left</v-icon>
          Back to Attendees
        </v-btn>
        <v-btn
            v-if="tabIndex === 0"
            text
            color="blue darken-1"
            @click="dialog = false"
        >
          Close
        </v-btn>
        <v-spacer />
        <v-btn
            v-if="tabIndex === 0"
            :loading="downloadingXlsx"
            :disabled="loading || loadingForm"
            color="primary"
            @click="downloadXlsx"
        >
          <v-icon left>mdi-download-outline</v-icon>
          XLSX
        </v-btn>
        <v-btn
            :disabled="loading || loadingForm"
            color="primary"
            @click="printDialog"
        >
          <v-icon left>mdi-printer-outline</v-icon>
          Print
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';
import ceHours from '@/api/ceHours';
import { SET_IS_PRINTING } from '@/store/mutations';
import EvaluationAttendeesTable from '@/components/CeEntry/EvaluationAttendeesTable';
import EvaluationAnswersForm from '@/components/CeEntry/EvaluationAnswersForm';
import fileDownload from 'js-file-download';

export default {
  name: 'EvaluationAttendeesPopup',
  components: { EvaluationAnswersForm, EvaluationAttendeesTable },
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      loadingForm: false,
      downloadingXlsx: false,
      entryId: null,
      entry: null,
      attendees: [],
      tabIndex: 0,
      currentAttendee: null,
      questions: null,
      answers: null,
    };
  },

  computed: {
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    answersByQuestionId() {
      const map = {};
      if (!this.answers) {
        return map;
      }
      this.answers.answers.forEach(answer => {
        map[answer.questionId] = answer;
      });
      return map;
    },
    questionsWithAnswers() {
      if (!this.questions) {
        return [];
      }
      return this.questions.map(question => {
        const questionWithAnswer = question;
        if (Object.prototype.hasOwnProperty.call(
          this.answersByQuestionId,
          question.id,
        )) {
          const answer = this.answersByQuestionId[question.id];
          questionWithAnswer.rate = answer.rate;
          questionWithAnswer.answer = answer.answer;
        }
        return questionWithAnswer;
      });
    },
  },

  methods: {
    async printDialog() {
      this.$store.commit(SET_IS_PRINTING, true);
      await this.$nextTick();
      await window.print();
      this.$store.commit(SET_IS_PRINTING, false);
    },

    /**
     * Show dialog.
     */
    async show(entry) {
      this.tabIndex = 0;
      this.dialog = true;
      this.loadingForm = false;
      this.entryId = entry.id;
      this.entry = entry;
      this.loadAttendees();
    },

    async loadAttendees() {
      this.loading = true;
      try {
        this.attendees = await ceHours.getAttendees(this.entryId);
      } finally {
        this.loading = false;
      }
    },

    async loadEvaluationForm() {
      this.loadingForm = true;
      try {
        const promises = [
          this.loadQuestions(),
          this.loadAnswers(),
        ];
        await Promise.all(promises);
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.loadingForm = false;
      }
    },

    async loadQuestions() {
      this.questions = await ceHours.getEvaluationQuestions();
    },

    async loadAnswers() {
      this.answers = await ceHours.getEvaluationAnswers(this.entryId, this.currentAttendee.id);
    },

    showEvaluatedForm(attendee) {
      this.currentAttendee = attendee;
      this.tabIndex = 1;
      this.loadEvaluationForm();
    },

    async downloadXlsx() {
      this.downloadingXlsx = true;
      const response = await ceHours.exportAttendees(this.entryId);
      this.downloadingXlsx = false;
      fileDownload(response, 'evaluations.xlsx');
    },
  },
};
</script>
<style>
.attendees-evaluations {
  .v-tabs-bar {
    display: none;
  }
}
@media print {
  .attendees-evaluations .v-window {
    overflow: visible !important;
  }
}
</style>
