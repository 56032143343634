import faxesHeaders from '@/data/TableHeaders/faxesHeaders';

export default {
  tableHeaders: faxesHeaders,
  filters: {
    client: 'Client',
    incident: 'Sequence number',
    status: 'Status',
    dateFrom: 'Date from',
    dateTo: 'Date to',
  },
};
