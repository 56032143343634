<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-card-title>{{ title }}</v-card-title>
    <vue-apex-charts
      v-if="!loading && data.series.length"
      type="bar"
      :height="chartHeight"
      :options="options"
      :series="data.series || []"
    />
    <div
        v-if="!loading && !data.series.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';

export default {
  name: 'StackedColumns',
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    measurement: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: 'string',
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  computed: {
    chartHeight() {
      return this.height - 100;
    },

    options() {
      const options = _.cloneDeep(this.chartOptions);
      options.xaxis.categories = this.data.dates || [];
      if (this.measurement) {
        const mesurement = this.measurement;
        options.tooltip = {
          y: {
            formatter(value) {
              return `${value} ${mesurement}`;
            },
          },
        };
      }
      return options;
    },
  },
};
</script>
