<template>
  <v-list-item>
    <item-content :item="item"/>
    <delete-item-action
      :item="item"
      @removeItem="$emit('removeItem', item)"
    />
  </v-list-item>
</template>

<script>
import ItemContent from '@/components/Notifications/NotificationTypes/ItemComponents/ItemContent';
import DeleteItemAction from '@/components/Notifications/NotificationTypes/ItemComponents/DeleteItemAction';

export default {
  name: 'DefaultNotification',

  components: {
    ItemContent,
    DeleteItemAction,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
</style>
