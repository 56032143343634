<template>
  <ce-entry-card
    :loading="loading"
    :data-loaded="dataLoaded"
    :color-number="1"
    :width="width"
  >
    <template v-if="dataLoaded">
      <v-row class="pl-4 pr-4">
        <v-col :class="['pb-0', $style['username-col']]">
          <span :class="[$style.username]">{{ userName }}</span>
        </v-col>
      </v-row>
      <v-row class="pl-4 mt-0">
        <v-col
          md="5"
          class="pt-0"
        >{{ licenseTypeItem | licenseTypeFilter }}</v-col>
        <v-col
            v-if="nationalLicense"
            md="7"
            :class="['pt-0', $style['license-col']]"
        >
          <span :class="[$style['license-span']]">
            <span :class="[$style.licenseTitles]">
            National:
            </span>
            <span
              :class="[$style.licenseValues]"
              :title="nationalLicense"
            >
              {{ nationalLicense }}
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-0 pl-4">
        <v-col
            v-if="stateLicense"
            md="5"
            :class="['pt-0', $style['license-col']]"
        >
          <span :class="[$style['license-span']]">
            <span :class="[$style.licenseTitles]">
              State:
            </span>
            <span
              :class="[$style.licenseValues]"
              :title="stateLicense"
            >
              {{ stateLicense }}
            </span>
          </span>
        </v-col>
        <v-col
            v-if="localLicense"
            md="7"
            :class="['pt-0', $style['license-col']]"
        >
          <span :class="[$style['license-span']]">
            <span :class="[$style.licenseTitles]">
              Local:
            </span>
            <span
              :class="[$style.licenseValues]"
              :title="localLicense"
            >
              {{ localLicense }}
            </span>
          </span>
        </v-col>
      </v-row>
    </template>
  </ce-entry-card>
</template>
<script>
import CeEntryCard from './CeEntryCard';

export default {
  name: 'UserCard',
  components: { CeEntryCard },
  filters: {
    licenseTypeFilter(item) {
      const map = {
        9925005: 'EMT',
      };
      if (item.licenseTypeId === null) {
        return 'No License Type';
      }
      if (map[item.licenseTypeId]) {
        return map[item.licenseTypeId];
      }
      return item.licenseType;
    },
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    userName: {
      type: String,
      default: '',
    },
    licenseTypeId: {
      type: Number,
      default: null,
    },
    licenseType: {
      type: String,
      default: '',
    },
    nationalLicense: {
      type: String,
      default: null,
    },
    stateLicense: {
      type: String,
      default: null,
    },
    localLicense: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    licenseTypeItem() {
      return {
        licenseType: this.licenseType,
        licenseTypeId: this.licenseTypeId,
      };
    },
  },
};
</script>
<style lang="scss" module>
  .license-col {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .license-span {
    white-space: nowrap;
  }
  .username-col {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .username {
    font-weight: bolder;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 22px;
    white-space: nowrap;
  }
  .licenseTitles {
    font-weight: bold;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 15px;
  }
  .licenseValues {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 15px;
    white-space: nowrap;
  }
</style>
