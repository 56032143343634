import { AbilityBuilder } from '@casl/ability';
import USER_ROLES from './enums/userRoles';

export const abilities = {};

abilities[USER_ROLES.SUPER_ADMIN] = [
  { actions: 'adminView', subject: 'viewResolver' },
  { actions: 'seeNotReleased', subject: 'releaseNotes' },
  { actions: 'seeTaskLink', subject: 'releaseNotes' },
  { actions: 'getAll', subject: 'clients' },
  { actions: 'view', subject: 'notificationBell' },
];

abilities[USER_ROLES.CLIENT_ADMIN] = [
  { actions: 'showIncidentsNestedMenu', subject: 'navigation' },
  { actions: 'clientView', subject: 'viewResolver' },
  { actions: 'doActionsOnAdmins', subject: 'users' },
  { actions: 'createAdmins', subject: 'users' },
  { actions: 'updateContactDetails', subject: 'users' },
  { actions: 'flag', subject: 'incident' },
  { actions: 'editForeignFlag', subject: 'incident' },
  { actions: 'view', subject: 'incident' },
  { actions: 'view', subject: 'notificationBell' },
  { actions: 'edit', subject: 'incident' },
  { actions: 'delete', subject: 'incident' },
  { actions: 'viewIncidentDialog', subject: 'incident' },
  { actions: 'showContextMenu', subject: 'incident' },
  { actions: 'showFlagIcon', subject: 'incident' },
  { actions: 'printNarcoticRequest', subject: 'incident' },
  { actions: 'seeTechnicalInfo', subject: 'incident' },
  { actions: 'seeParticipants', subject: 'flag' },
  { actions: 'create', subject: 'monitor' },
  { actions: 'showMenu', subject: 'incidentFile' },
  { actions: 'showForeignCommentFlag', subject: 'incident' },
  { actions: 'showForeignClosePendingFlagButton', subject: 'incident' },
  { actions: 'addAttendees', subject: 'ceHoursEntriesDialog' },
  { actions: 'seeAttendees', subject: 'ceHoursEntriesDialog' },
  { actions: 'addSkills', subject: 'ceHoursEntriesDialog' },
  { actions: 'editInstructorBasedType', subject: 'ceHoursEntriesDialog' },
  { actions: 'editEvaluationRequired', subject: 'ceHoursEntriesDialog' },
  { actions: 'seeAttendeesColumn', subject: 'ceHoursEntriesTable' },
  { actions: 'seeSkillsColumn', subject: 'ceHoursEntriesTable' },
  { actions: 'seeEvaluationsColumn', subject: 'ceHoursEntriesTable' },
  { actions: 'seeAdminTools', subject: 'ceHoursEntriesTable' },
  { actions: 'seeAttendeesFilter', subject: 'ceHoursEntriesToolbar' },
  { actions: 'view', subject: 'ceHoursAgencyOverview' },
  { actions: 'view', subject: 'ceHoursSkillsOverview' },
  { actions: 'seeLinkToIncident', subject: 'googleMap' },
  { actions: 'view', subject: 'ceSettings' },
];

abilities[USER_ROLES.CLIENT_CAPTAIN_USER] = [
  { actions: 'showIncidentsNestedMenu', subject: 'navigation' },
  { actions: 'clientView', subject: 'viewResolver' },
  { actions: 'flag', subject: 'incident' },
  { actions: 'view', subject: 'incident' },
  { actions: 'view', subject: 'notificationBell' },
  { actions: 'edit', subject: 'incident' },
  { actions: 'viewIncidentDialog', subject: 'incident' },
  { actions: 'showContextMenu', subject: 'incident' },
  { actions: 'showFlagIcon', subject: 'incident' },
  { actions: 'showMenu', subject: 'incidentFile' },
  { actions: 'showCommentFlag', subject: 'incident' },
  { actions: 'showClosePendingFlagButton', subject: 'incident' },
  { actions: 'seeExternalCeEntryAlert', subject: 'ceHoursEntriesDialog' },
  { actions: 'seeStatusColumn', subject: 'ceHoursEntriesTable' },
  { actions: 'seeLinkToIncident', subject: 'googleMap' },
];

abilities[USER_ROLES.CLIENT_QA_USER] = [
  { actions: 'showIncidentsRoot', subject: 'navigation' },
  { actions: 'showMonitorsRoot', subject: 'navigation' },
  { actions: 'showQaInsightsRoot', subject: 'navigation' },
  { actions: 'clientView', subject: 'viewResolver' },
  { actions: 'view', subject: 'notificationBell' },
  { actions: 'create', subject: 'monitor' },
];

abilities[USER_ROLES.CLIENT_CE_USER] = [
  { actions: 'view', subject: 'notificationBell' },
  { actions: 'seeExternalCeEntryAlert', subject: 'ceHoursEntriesDialog' },
  { actions: 'seeStatusColumn', subject: 'ceHoursEntriesTable' },
];

abilities[USER_ROLES.CLIENT_FIREFIGHTER_USER] = [
  { actions: 'showIncidentsNestedMenu', subject: 'navigation' },
  { actions: 'clientView', subject: 'viewResolver' },
  { actions: 'view', subject: 'incident' },
  { actions: 'viewIncidentDialog', subject: 'incident' },
  { actions: 'showContextMenu', subject: 'incident' },
  { actions: 'showFlagIcon', subject: 'incident' },
  { actions: 'view', subject: 'notificationBell' },
  { actions: 'seeExternalCeEntryAlert', subject: 'ceHoursEntriesDialog' },
  { actions: 'seeStatusColumn', subject: 'ceHoursEntriesTable' },
  { actions: 'seeLinkToIncident', subject: 'googleMap' },
];

abilities[USER_ROLES.HOSPITAL_ADMIN] = [
  { actions: 'hospitalView', subject: 'viewResolver' },
  { actions: 'updateContactDetails', subject: 'users' },
  { actions: 'view', subject: 'allowAccessNet' },
  { actions: 'view', subject: 'incident' },
  { actions: 'edit', subject: 'hospitalProfile' },
];

abilities[USER_ROLES.HOSPITAL_USER] = [
  { actions: 'hospitalView', subject: 'viewResolver' },
];

abilities[USER_ROLES.PUBLIC] = [
  { actions: 'publicView', subject: 'viewResolver' },
];

export function getAbilitiesForRole(role) {
  const { can, rules } = new AbilityBuilder();
  const listOfRules = role in abilities ? abilities[role] : [];
  listOfRules.forEach(rule => {
    can(rule.actions, rule.subject);
  });
  return rules;
}
