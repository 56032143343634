export const SET_OD_MAP_EXPORTS_ITEMS = 'SET_OD_MAP_EXPORTS_ITEMS';
export const SET_OD_MAP_EXPORTS_PAGINATION = 'SET_OD_MAP_EXPORTS_PAGINATION';
export const SET_OD_MAP_EXPORTS_FILTERS = 'SET_OD_MAP_EXPORTS_FILTERS';

export default {
  /**
   * Save od map exports data.
   *
   * @param {Object} state - Od map exports state.
   * @param {{ items: Array, totalItems: number }} data - Od map exports data to set
   */
  [SET_OD_MAP_EXPORTS_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.pagination.totalItems = totalItems;
  },

  /**
   * Save od map exports pagination.
   *
   * @param {Object} state - Od map exports state.
   * @param {Object} pagination - Od map exports pagination.
   */
  [SET_OD_MAP_EXPORTS_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters of od map exports.
   *
   * @param {Object} state - Od map exports state.
   * @param {Object} filters - Filters to set.
   */
  [SET_OD_MAP_EXPORTS_FILTERS]: (state, filters) => {
    state.filters = filters;
  },
};
