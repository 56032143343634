import api from './api';

export default {
  /**
   * Returns list of providers.
   *
   * @return Promise<Array>
   */
  fetchProviders() {
    return api()
      .get('/lookup/providers')
      .then(response => response.data);
  },

  /**
   * Returns list of nemsis agencies.
   *
   * @return Promise<Array>
   */
  fetchNemsisAgencies() {
    return api()
      .get('/lookup/nemsis-agencies')
      .then(response => response.data);
  },

  /**
   * Returns list of incident numbers.
   *
   * @param {string} search Search query
   *
   * @return Promise<Array>
   */
  getIncidentNumbers(search) {
    return api()
      .get('/incident-numbers', { params: { search } })
      .then(response => response.data);
  },
};
