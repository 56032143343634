const defaultState = () => ({
  sessionId: null,
  // All statuses of flagged incidents
  statuses: [0, 1, 2],
  currentColumnPreset: null,
  showAdvanced: false,
  advSearchGroups: [],
  advSearchPreset: null,
  basicSearchFilters: {
    regular: {
      dateRange: null,
      sequenceNumbers: null,
      incidentNumbers: null,
    },
    flagged: {
      dateRange: null,
      sequenceNumbers: null,
      incidentNumbers: null,
      status: null,
    },
    deleted: {
      dateRange: null,
      sequenceNumbers: null,
      incidentNumbers: null,
      dateRemoved: null,
    },
    unfinished: {
      dateRange: null,
      sequenceNumbers: null,
      incidentNumbers: null,
      deviceId: null,
      uploaded: null,
    },
  },
  advancedSearchFilters: [],
  items: null,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [],
    page: 1,
    sortBy: [],
    itemsPerPage: 25,
    totalItems: 0,
  },
  totalItems: 0,
  searchPresets: null,
  exportPresets: null,
  columnPresets: null,
  tableFields: null,
  searchFields: null,
  exportFields: [],
  selectedFields: [],
  monitor: null,
  monitorTmp: null,
});

export default defaultState;
