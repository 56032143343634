import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    pagination: {
      sortDesc: [true],
      page: 1,
      sortBy: ['id'],
      itemsPerPage: 25,
      totalItems: 0,
    },
    filters: {
      service: 'hde',
      clientId: null,
      sequenceNumber: null,
    },
  },
  actions,
  mutations,
  getters: {},
};
