<template>
  <v-card :class="$style.root">
    <main-form :disabled="resultsVisible"></main-form>
    <export-results v-if="resultsVisible"></export-results>
  </v-card>
</template>

<script>
import { UTILITIES_EXPORT } from '@/store/UtilitiesExport/constants';
import { SET_TITLE } from '@/store/mutations';
import MainForm from './MainForm';
import ExportResults from './ExportResults';

export default {
  components: {
    ExportResults,
    MainForm,
  },
  computed: {
    resultsVisible() {
      return this.$store.state[UTILITIES_EXPORT].showResults;
    },
  },
  created() {
    this.$store.commit(SET_TITLE, this.$store.state[UTILITIES_EXPORT].title);
  },
};
</script>

<style lang="scss" src="./stylesheets/style.scss" module />
