<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
      <v-card>
        <v-card-title>Respond for Flag</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
              :value="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          <div class="mt-3">
            Enter in your comments or concerns regarding this incident being flagged below:
          </div>
          <v-text-field
              v-model="message"
              v-validate="{ required: true }"
              name="message"
              :error-messages="errors.collect('message')"
              label="Comments"
              maxlength="255"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :disabled="sendingNoComment"
              :loading="sending"
              @click="send"
          >
            Send
          </v-btn>
          <v-btn
              text
              color="primary"
              :disabled="sending"
              :loading="sendingNoComment"
              @click="sendNoComment"
          >
            No Comment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';
import flaggedUsers from '@/api/flaggedUsers';

export default {
  name: 'RespondFlagPopup',
  mixins: [errorAlertMixins],
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      message: null,
      sending: false,
      action: null,
      sendingNoComment: false,
      dictionary: {
        attributes: {
          message: 'Comments',
        },
      },
    };
  },
  methods: {
    show(isUnderstood) {
      this.dialog = true;
      this.message = null;
      this.action = isUnderstood
        ? flaggedUsers.respondFlagUnderstood
        : flaggedUsers.respondFlagNotUnderstand;
    },
    hide() {
      this.dialog = false;
    },
    async send() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.sending = true;
      try {
        await this.action(this.incidentId, {
          message: this.message,
          isNoComment: false,
        });
        this.dialog = false;
        this.$emit('responded');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.sending = false;
      }
    },
    async sendNoComment() {
      this.sendingNoComment = true;
      try {
        await this.action(this.incidentId, {
          message: null,
          isNoComment: true,
        });
        this.dialog = false;
        this.$emit('responded');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.sendingNoComment = false;
      }
    },
  },
};
</script>
