<template>
  <div class="technical-info">
    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
        :transition="false"
    >
      <v-card v-if="loading">
        <v-card-text class="mt-3">
          Loading...
          <v-progress-linear
              indeterminate
              color="primary"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card
        v-else
        class="technical-info-card"
      >
        <v-card-title>Technical Info</v-card-title>
        <v-divider></v-divider>
        <v-row style="width: 100%">
          <v-col
            :sm="showActions ? 8 : 12"
            class="pa-0"
          >
            <div
                v-if="systemChanges"
                class="left-side fill-height pl-8 pt-5 pb-5"
            >
              <div class="mb-5">
                <template v-if="incident.originalTemplate !== incident.renderTemplate">
                  Original Template: <b>{{ incident.originalTemplate }}</b><br />
                </template>
                Render template: <b>{{ incident.renderTemplate }}</b>
              </div>
              <div>
                <b>The following XSD (DHS) fields were changed by the system during upload:</b>
              </div>
              <div class="mb-5">
                <div
                    v-for="(values, field) in systemChanges.changedXsdFields"
                    v-bind:key="field"
                >
                  {{ field }} ({{ values.original|showValue }} => {{ values.changed|showValue }})
                </div>
              </div>
              <div>
                <b>Non XSD (DHS) changed fields:</b>
              </div>
              <div
                  v-for="(values, field) in systemChanges.changedOtherFields"
                  v-bind:key="field"
              >
                {{ field }} ({{ values.original|showValue }} => {{ values.changed|showValue }})
              </div>
            </div>
          </v-col>
          <v-col
            v-if="showActions"
            sm="4"
          >
            <v-btn
                text
                small
                :loading="downloadingHtml"
                color="blue"
                @click="downloadHtml"
            >
              Download HTML
            </v-btn>
            <v-btn
                text
                small
                :loading="downloadingJson"
                color="blue"
                @click="downloadJson"
            >
              Download Original JSON
            </v-btn>
            <v-btn
                text
                small
                :loading="downloadingNemsis"
                color="blue"
                @click="downloadNemsis"
            >
              Download NEMSIS
            </v-btn>
            <v-btn
                text
                small
                :loading="regenerating"
                color="blue"
                @click="regeneratePdf"
            >
              Re-rendering
            </v-btn>
            <v-btn
                text
                small
                :loading="regeneratingIgnoreErrors"
                color="blue"
                @click="regeneratePdfIgnoreErrors"
            >
              Re-rendering (ignoring JS errors)
            </v-btn>
            <v-btn
                text
                small
                :loading="regeneratingFromImages"
                color="blue"
                @click="regenerateFromImages"
            >
              Generate PDF from images
            </v-btn>
            <v-checkbox
              v-model="ignoreNemsisExport"
              label="Ignore NEMSIS export"
              @click="ignoreNemsisExportHandeler"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import incidents from '@/api/incidents';
import fileDownload from 'js-file-download';
import { SHOW_SNACKBAR } from '@/store/actions';
import { mapActions } from 'vuex';

export default {
  name: 'TechnicalInfoPopup',
  props: {
    incident: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    showValue(value) {
      if (value === null) {
        return 'null';
      }
      if (value === '') {
        return 'empty';
      }
      return `"${value}"`;
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      downloadingJson: false,
      downloadingHtml: false,
      downloadingNemsis: false,
      regenerating: false,
      regeneratingIgnoreErrors: false,
      regeneratingFromImages: false,
      systemChanges: null,
      ignoreNemsisExport: null,
    };
  },

  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    async show() {
      this.dialog = true;
      this.loading = true;
      this.systemChanges = null;
      try {
        this.ignoreNemsisExport = this.incident.ignoreNemsisExport;
        this.systemChanges = await incidents.getSystemChangedFields(this.incident.id);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Handle for download JSON.
     */
    async downloadJson() {
      try {
        this.downloadingJson = true;
        const response = await incidents.downloadJson(this.incident.id);
        fileDownload(response.data, `${this.incident.sequenceNumber}.json`);
      } finally {
        this.downloadingJson = false;
      }
    },

    /**
     * Handle for download HTML.
     */
    async downloadHtml() {
      try {
        this.downloadingHtml = true;
        const response = await incidents.downloadHtml(this.incident.id);
        fileDownload(response.data, `${this.incident.sequenceNumber}.zip`);
      } finally {
        this.downloadingHtml = false;
      }
    },

    /**
     * Handle for download Nemsis zip file.
     */
    async downloadNemsis() {
      try {
        this.downloadingNemsis = true;
        const response = await incidents.downloadNemsis(this.incident.id);
        fileDownload(response.data, `${this.incident.sequenceNumber}.zip`);
      } catch (error) {
        const { message } = JSON.parse(await error.response.data.text());
        this.showSnackbar({
          title: message,
          color: 'red',
        });
      } finally {
        this.downloadingNemsis = false;
      }
    },

    /**
     * Handle for regenerate PDFs.
     */
    async regeneratePdf() {
      try {
        this.regenerating = true;
        await incidents.regeneratePdf(this.incident.id);
        this.showSnackbar('Regeneration process has started.');
      } finally {
        this.regenerating = false;
      }
    },

    /**
     * Handle for regenerate PDFs ignoring js errors.
     */
    async regeneratePdfIgnoreErrors() {
      try {
        this.regeneratingIgnoreErrors = true;
        await incidents.regeneratePdfIgnoreErrors(this.incident.id);
        this.showSnackbar('Regeneration process has started.');
      } finally {
        this.regeneratingIgnoreErrors = false;
      }
    },

    /**
     * Handle for regenerate PDFs using images.
     */
    async regenerateFromImages() {
      try {
        this.regeneratingFromImages = true;
        await incidents.regenerateFromImages(this.incident.id);
        this.showSnackbar('Regeneration process has started.');
      } finally {
        this.regeneratingFromImages = false;
      }
    },

    /**
     * Handle the click by checkbox.
     */
    async ignoreNemsisExportHandeler() {
      await incidents.patch(this.incident.id, {
        ignoreNemsisExport: this.ignoreNemsisExport,
      });
      this.incident.ignoreNemsisExport = this.ignoreNemsisExport;
    },
  },
};
</script>

<style lang="scss">
.technical-info-card {
  overflow: hidden !important;
}
.left-side{
  max-height: 500px;
  overflow-y: scroll;
  background-color: #f5f5f6;
}
</style>
