export default {
  methods: {
    isFlaggedByCurrentUser(incident) {
      return incident && incident.flag
          && this.$store.state.user.registryId === incident.flag.createdBy;
    },

    canAddFlag(incident) {
      return incident && (!incident.IsReadOnly && !incident.readOnly)
                && this.$can('flag', 'incident')
                && (!incident.flag
                    || this.isFlaggedByCurrentUser(incident)
                    || this.$can('editForeignFlag', 'incident')
                );
    },

    getMinStatus(alerts) {
      let minStatus = null;
      alerts.forEach(alert => {
        if (minStatus === null || minStatus > alert.status) {
          minStatus = alert.status;
        }
      });
      return minStatus;
    },

    getMaxStatus(alerts) {
      let maxStatus = null;
      alerts.forEach(alert => {
        if (maxStatus === null || maxStatus < alert.status) {
          maxStatus = alert.status;
        }
      });
      return maxStatus;
    },

    async addFlag(incident) {
      this.$refs.addFlagPopup.show(incident);
    },
  },
};
