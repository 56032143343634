import api from './api';

export default {
  /**
   * Fetch CE Hours License Hours settings.
   *
   * @return Promise<Object>
   */
  getLicenseHours() {
    return api()
      .get('/ce-hours/settings/license-hours')
      .then(response => response.data);
  },

  /**
   * Save CE Hours License Hours settings.
   *
   * @return Promise<Object>
   */
  saveLicenseHours(data) {
    return api()
      .post('/ce-hours/settings/license-hours', {
        data,
      })
      .then(response => response.data);
  },

  /**
   * Fetch CE Skills per License settings.
   *
   * @return Promise<Object>
   */
  getSkillsPerLicense() {
    return api()
      .get('/ce-hours/settings/skills-per-licenses')
      .then(response => response.data);
  },

  /**
   * Save certificates settings.
   *
   * @return Promise<Object>
   */
  saveCertificateSettings(settings) {
    return api()
      .post('/ce-hours/settings/certificate', settings)
      .then(response => response.data);
  },

  /**
   * Fetch certificates settings.
   *
   * @return Promise<Object>
   */
  getCertificateSettings() {
    return api()
      .get('/ce-hours/settings/certificate')
      .then(response => response.data);
  },

  /**
   * Save CE Skills per License settings.
   *
   * @return Promise<Object>
   */
  saveSkillsPerLicense(data) {
    return api()
      .post('/ce-hours/settings/skills-per-licenses', {
        data,
      })
      .then(response => response.data);
  },

  /**
   * Fetch CE Hours License Skills settings.
   *
   * @return Promise<Object>
   */
  getSkills() {
    return api()
      .get('/ce-hours/settings/license-skills')
      .then(response => response.data);
  },

  /**
   * Save CE Hours License Hours settings.
   *
   * @return Promise<Object>
   */
  saveSkills(data) {
    return api()
      .post('/ce-hours/settings/license-skills', {
        data,
      })
      .then(response => response.data);
  },

  /**
   * Save Notification settings.
   *
   * @return Promise<Object>
   */
  saveNotificationsSettings(data) {
    return api()
      .post('/notification-settings/ce-client-common-types', {
        data,
      })
      .then(response => response.data);
  },

  /**
   * Fetch CE Hours License Hours settings.
   *
   * @return Promise<Object>
   */
  getNotificationSettings() {
    return api()
      .get('/notification-settings/ce-client-common-types')
      .then(response => response.data);
  },

  /**
   * Get providers settings.
   *
   * @return Promise<Object>
   */
  getProviders() {
    return api()
      .get('/ce-hours/settings/providers')
      .then(response => response.data);
  },

  /**
   * Delete provider.
   *
   * @return Promise<Object>
   */
  deleteProvider(providerId) {
    return api()
      .delete(`/ce-hours/settings/providers/${providerId}`)
      .then(response => response.data);
  },

  /**
   * Update provider.
   *
   * @return Promise<Object>
   */
  updateProvider(providerId, data) {
    return api()
      .put(`/ce-hours/settings/providers/${providerId}`, data)
      .then(response => response.data);
  },

  /**
   * Create provider.
   *
   * @return Promise<Object>
   */
  createProvider(data) {
    return api()
      .post('/ce-hours/settings/providers', data)
      .then(response => response.data);
  },

  /**
   * Save list of evaluation questions.
   *
   * @return Promise<Object>
   */
  saveEvaluationQuestions(data) {
    return api()
      .post('/ce-hours/settings/evaluation-questions', { data })
      .then(response => response.data);
  },
};
