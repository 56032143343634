<template>
  <v-combobox
      v-model="textValue"
      :label="label"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      cache-items
      hide-no-data
      :maxlength="maxlength"
  />
</template>
<script>
import _debounce from 'lodash/debounce';
import lookup from '@/api/lookup';

export default {
  name: 'IncidentNumber',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      debounceFunc: null,
      items: [],
      loading: false,
      search: null,
    };
  },

  watch: {
    search(val) {
      if (val && val !== this.value) {
        if (!this.debounceFunc) {
          const that = this;
          this.debounceFunc = _debounce(() => {
            that.searchIncidentNumbers();
          }, 300);
        }
        this.debounceFunc();
      }
    },
  },
  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    maxlength() {
      if (this.field.restrictions && this.field.restrictions.maxlen) {
        return this.field.restrictions.maxlen;
      }
      return 255;
    },
  },
  methods: {
    async searchIncidentNumbers() {
      try {
        this.loading = true;
        this.items = await lookup.getIncidentNumbers(this.search);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
