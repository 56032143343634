import api from './api';

/**
 * Fetch list of all states.
 */
export default function fetchAllStates() {
  return api()
    .get('states')
    .then(response => response.data);
}
