const SEARCH_CRITERIA_TYPES = {
  EQ: 'eq',
  NOT_EQ: 'not_eq',
  RANGE: 'range',
  LIKE: 'like',
  IS_NULL: 'is_null',
  AGE: 'age',
  WEIGHT: 'weight',
  IMAGE_ATTACHED: 'image_attached',
  HAS_SIGNATURE: 'has_signature',
  INSIGHTS_THERAPY: 'insights_therapy',
  INSIGHTS_PROVIDER_IMPRESSION: 'insights_providerImpression',
  INSIGHTS_ADVANCED_AIRWAYS: 'insights_advancedAirways',
  INSIGHTS_MEDS: 'insights_meds',
  INSIGHTS_VITALS: 'insights_vitals',
  INSIGHTS_TRANSPORT: 'insights_transport',
  INSIGHTS_TRANSPORT_BY_PROVIDER_IMPRESSIONS: 'insights_transportByProviderImpressions',
};

export default SEARCH_CRITERIA_TYPES;
