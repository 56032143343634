<template>
  <v-data-table
    :headers="$t('faxes.tableHeaders')"
    :items="items"
    :loading="loading || resendLoading"
    :options.sync="tableParams"
    :server-items-length="serverPaginationInfo.total"
    :footer-props="{
        itemsPerPageOptions: [10, 25, 50]
      }"
    :height="tableHeight"
    fixed-header
    class="header-nowrap"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <NoDataAvailable
          :headers="$t('faxes.tableHeaders')"
          :items="items"
        ></NoDataAvailable>
        <v-hover
          v-for="item in items"
          :key="item.sendId"
        >
          <tr>
            <td>{{ item.client.name }}</td>
            <td>
              <a
                @click="impersonateToIncident(item.client.registryId, item.incident.id)"
              >
                {{ item.incident.sequenceNumber }}
              </a>
            </td>
            <td>{{ item.phone }}</td>
            <td>{{ item.additionalText }}</td>
            <td>{{ item.created | date }}</td>
            <td>{{ item.sent | date }}</td>
            <td>
              <v-checkbox
                v-model="item.secondPage"
                disabled
              >
              </v-checkbox>
            </td>
            <td>{{ faxStatusTitle(item) }}</td>
            <td>
              <v-icon
                v-if="showResendButton(item)"
                class="mr-3"
                color="grey darken-2"
                @click="resend(item)"
              >
                replay
              </v-icon>
            </td>
          </tr>
        </v-hover>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import _debounce from 'lodash/debounce';
import { createNamespacedHelpers } from 'vuex';
import fixedHeader from '@/mixins/fixedHeader';
import impersonateMixins from '@/mixins/impersonateMixins';
import { SET_TABLE_PARAMS } from '@/store/Faxes/mutations';
import { RESEND_FAX } from '@/store/Faxes/actions';
import NoDataAvailable from '../NoDataAvailable';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('faxes');

/**
 * Send fax errors constant object.
 */
const SEND_FAX_ERRORS = {
  generalError: 3,
  fileNotExistsError: 4,
};

export default {
  name: 'FaxesTable',
  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader, impersonateMixins],
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resendLoading: false,
    };
  },
  computed: {
    ...mapState({
      statuses: state => state.statuses,
      serverPaginationInfo: state => state.serverPaginationInfo,
      faxesTableParams: state => state.tableParams,
    }),
    /**
     * Table params: sorting and pagination.
     */
    tableParams: {
      get() {
        return this.faxesTableParams;
      },
      set(value) {
        this.setTableParams(value);
      },
    },
  },
  watch: {
    tableParams: {
      // eslint-disable-next-line
      handler: _debounce(function () {
        this.$emit('tableUpdate');
      }, 500),
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setTableParams: SET_TABLE_PARAMS,
    }),
    ...mapActions({
      resendFax: RESEND_FAX,
    }),
    /**
     * Show or hide resend fax button according to fax status
     * @param item Fax
     * @returns {boolean}
     */
    showResendButton(item) {
      const errorsStatusIds = [SEND_FAX_ERRORS.generalError, SEND_FAX_ERRORS.fileNotExistsError];

      return errorsStatusIds.includes(item.status);
    },
    /**
     * Get fax status title by status id.
     * @param item Fax
     * @returns {string}
     */
    faxStatusTitle(item) {
      const foundStatus = this.statuses.find(status => status.id === item.status);

      return foundStatus ? foundStatus.name : '';
    },
    /**
     * Resend fax and open popup with info.
     * @param fax
     */
    async resend(fax) {
      this.resendLoading = true;
      try {
        await this.resendFax(fax);
      } finally {
        this.resendLoading = false;
      }
      this.$emit('showPopup', fax);
    },
  },
};
</script>
