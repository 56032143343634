<template>
  <v-dialog
      v-model="showSharePopup"
      max-width="800px"
  >
    <v-card>
      <v-card-title>Share Preset</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-autocomplete
          v-model="userIds"
          v-validate="{ required: true }"
          name="userIds"
          :items="recipientsList"
          label="Choose Recipients"
          placeholder="Choose Recipients"
          :error-messages="errors.collect('userIds')"
          clearable
          multiple
          deletable-chips
          chips
        />
        <v-checkbox
            v-model="hasRightEdit"
            label="Collaborate preset"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            :disabled="loading"
            @click.native="cancel()"
        >{{ $t('buttons.cancel') }}</v-btn>
        <v-btn
            color="primary"
            text
            :loading="loading"
            :disabled="loading"
            @click.native="confirm()"
        >{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import presetManager from '@/api/presetManager';
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'SharePresetPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      showSharePopup: false,
      presetId: null,
      loading: false,
      userIds: [],
      hasRightEdit: false,
      recipients: [],
      teamList: [],
      dictionary: {
        attributes: {
          userIds: 'Recipients',
        },
      },
    };
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    async showPopup(presetId) {
      await this.$validator.reset();
      this.showSharePopup = true;
      this.presetId = presetId;
    },

    /**
     * Close popup.
     */
    cancel() {
      this.showSharePopup = false;
      this.presetId = null;
      this.userIds = [];
      this.hasRightEdit = false;
      this.hideError();
    },

    /**
     * Confirm sharing preset.
     */
    async confirm() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.loading = true;

      try {
        await this.sharePreset(
          this.presetId,
          { recipientIds: this.userIds, hasRightEdit: this.hasRightEdit },
        );
        this.cancel();
      } catch (error) {
        this.parseErrorResponse(error.response);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Load recipients.
     */
    async loadRecipients() {
      const response = await presetManager.fetchRecipients();
      this.teamList = response.results;
    },

    /**
     * Share preset.
     *
     * @param {Number} presetId - Preset ID
     * @param {Object} params - Prams of request
     *
     * @returns {Promise}
     */
    async sharePreset(presetId, params) {
      return presetManager.share(presetId, params);
    },
  },
  computed: {
    recipientsList() {
      if (this.recipients.length) {
        return this.recipients;
      }

      this.loadRecipients();
      this.teamList.forEach(item => {
        this.recipients.push({
          text: `${item.firstName} ${item.lastname}`,
          value: item.registryId,
        });
      });

      return this.recipients;
    },
  },
};
</script>
