export const SET_AUDIT_LOG_ITEMS = 'SET_AUDIT_LOG_ITEMS';
export const SET_AUDIT_LOG_PAGINATION = 'SET_AUDIT_LOG_PAGINATION';
export const SET_AUDIT_LOG_FILTERS = 'SET_AUDIT_LOG_FILTERS';
export const SET_AUDIT_LOG_DYNAMIC_FILTERS = 'SET_AUDIT_LOG_DYNAMIC_FILTERS';
export const SET_AUDIT_LOG_METHODS = 'SET_AUDIT_LOG_METHODS';

export default {
  /**
   * Save audit log data.
   *
   * @param {Object} state - Document's state.
   * @param {{ items: Array, totalItems: number }} data - Audit log data to set
   */
  [SET_AUDIT_LOG_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.totalItems = totalItems;
  },

  /**
   * Save audit log's pagination.
   *
   * @param {Object} state - Audit log's state.
   * @param {Object} pagination - Audit log pagination.
   */
  [SET_AUDIT_LOG_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters of audit log.
   *
   * @param {Object} state - Audit log's state.
   * @param {Object} filters - Filters to set.
   */
  [SET_AUDIT_LOG_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  /**
   * Saves filters of audit log.
   *
   * @param {Object} state - Audit log's state.
   * @param {Object} filters - Dynamic filters to set.
   */
  [SET_AUDIT_LOG_DYNAMIC_FILTERS]: (state, dynamicFilters) => {
    state.dynamicFilters = dynamicFilters;
  },

  /**
   * Saves api methods.
   *
   * @param {Object} state - Audit log's state.
   * @param {Object} filters - Methods to set.
   */
  [SET_AUDIT_LOG_METHODS]: (state, methods) => {
    state.methods = methods;
  },
};
