<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    :height="tableHeight"
    fixed-header
    class="header-nowrap"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <NoDataAvailable
          :headers="headers"
          :items="items"
        />
        <v-hover
          v-for="item in items"
          :key="item.UDID"
        >
          <tr
            slot-scope="{ hover }"
          >
            <td>{{ item.UDID }}</td>
            <td>{{ item.address }}</td>
            <td><a
              :href="getGoogleMapsLink(item)"
              target="_blank"
            >{{ item.latitude }}, {{ item.longitude }}</a></td>
            <td>{{ item.createdAt | datetimeToClientTimezone }}</td>
            <td :class="[$style.actions, 'text-right', 'no-wrap']">
              <div>
                <v-icon
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickRegister', item)"
                >
                  devices
                </v-icon>
                <v-icon
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickDelete', item)"
                >
                  delete
                </v-icon>
              </div>
            </td>
          </tr>
        </v-hover>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'FirstRunDevicesTable',

  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader],

  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('firstRunDevices.tableHeaders.UDID'),
          value: 'UDID',
        },
        {
          text: this.$t('firstRunDevices.tableHeaders.address'),
          value: 'address',
        },
        {
          text: this.$t('firstRunDevices.tableHeaders.location'),
          sortable: false,
        },
        {
          text: this.$t('firstRunDevices.tableHeaders.createdAt'),
          value: 'createdAt',
        },
        {
          sortable: false,
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    getGoogleMapsLink(item) {
      return `https://www.google.com/maps/place/${item.latitude},${
        item.longitude
      }`;
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}
</style>
