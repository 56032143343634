import actions from './actions';
import mutations from './mutations';
import defaultState from './defaultState';

export default {
  namespaced: true,
  state: defaultState(),
  actions,
  mutations,
  getters: {},
};
