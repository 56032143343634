<template>
  <v-toolbar flat>
    <v-select
        v-model="monthValue"
        :items="months"
        hide-details
        label="Select Month"
        single-line
        class="mr-4"
        style="max-width: 200px"
    />

    <v-select
        v-model="yearValue"
        :items="years"
        hide-details
        label="Select Year"
        single-line
        style="max-width: 200px"
    />

    <v-spacer />

    <v-menu
        :disabled="downloading"
        offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
            :loading="downloading"
            :disabled="downloading"
            color="primary"
            class="ma-2"
            v-on="on"
        >
          {{ downloadName }}
          <span slot="loader">Downloading...</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$emit('download', 'xls', 'half')">
            <v-list-item-content>
              <v-list-item-title>XLS (half month)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$emit('download','xls', 'full')">
            <v-list-item-content>
              <v-list-item-title>XLS (full month)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$emit('download','txt', 'full')">
            <v-list-item-content>
              <v-list-item-title>TXT (full month)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-menu
        :disabled="exporting"
        offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
            :loading="exporting"
            :disabled="exporting"
            color="primary"
            class="ma-2"
            v-on="on"
        >
          Export All To SFTP
          <span slot="loader">Uploading...</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$emit('export','xls', 'half')">
            <v-list-item-content>
              <v-list-item-title>XLS (half month)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('export','xls', 'full')">
            <v-list-item-content>
              <v-list-item-title>XLS (full month)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('export','txt', 'full')">
            <v-list-item-content>
              <v-list-item-title>TXT (full month)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

  </v-toolbar>
</template>
<script>
import allMonths from '@/data/Dropdowns/months';

export default {
  name: 'Toolbar',

  props: {
    downloading: {
      type: Boolean,
      required: true,
    },
    exporting: {
      type: Boolean,
      required: true,
    },
    downloadName: {
      type: String,
      default: 'Download',
    },
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      yearValue: this.year,
      monthValue: this.month,
    };
  },

  computed: {
    months() {
      const months = allMonths;

      months.forEach((month, key) => {
        months[key].disabled = false;
        if (this.yearValue === new Date().getFullYear()
            && month.value > new Date().getMonth() + 1
        ) {
          months[key].disabled = true;
        }
      });
      return months;
    },

    years() {
      let currentYear = new Date().getFullYear();
      const startYear = currentYear - 5;
      const years = [];
      for (currentYear; currentYear >= startYear; currentYear--) {
        years.push(currentYear);
      }
      return years;
    },
  },

  watch: {
    /**
     * Watch Year property and update month.
     */
    yearValue() {
      if (
        this.yearValue === new Date().getFullYear()
          && this.monthValue > new Date().getMonth() + 1
      ) {
        this.monthValue = new Date().getMonth() + 1;
      }
      this.$emit('update:year', this.yearValue);
    },

    /**
     * Watch month and update prop.
     */
    monthValue() {
      this.$emit('update:month', this.monthValue);
    },
  },
};

</script>
