/* eslint func-names:0 */
import incidentsApi from '@/api/incidents';

export default {
  data() {
    return {
      downloading: false,
      pdfWaiting: false,
    };
  },
  methods: {
    async downloadPdf(incidentId, type, num) {
      try {
        this.downloading = true;
        const pdfs = await incidentsApi.getPdfs(incidentId);
        let selectedPdf = null;
        pdfs.forEach(pdf => {
          if (pdf.type === type && pdf.page_num === num) {
            selectedPdf = pdf;
          }
        });
        if (selectedPdf) {
          incidentsApi.downloadLog(incidentId);
          this.$emit('downloaded', incidentId);
          const win = window.open(selectedPdf.url, '_blank');
          win.focus();
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.pdfWaiting = true;
          const channel = `incident.${incidentId}`;
          this.$echo.private(channel).listen('.IncidentRendered', () => {
            this.pdfWaiting = false;
            this.downloadPdf(incidentId, type, num);
            this.$echo.leave(channel);
          });
        }
      } finally {
        this.downloading = false;
      }
    },
  },
};
