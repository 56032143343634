<template>
  <div>
    <upload-btn
      ref="uploadBtn"
      :accept="allowedMimeTypes.join(',')"
      :fixed-width="fixedWidth"
      :title="titleComputed"
      :no-title-update="noTitleUpdateComputed"
      :disabled="uploading || disabled"
      @file-update="update"
    />
  </div>

</template>

<script>
import UploadButton from 'vuetify-upload-button';
import { getPresignedData, upload } from '@/api/uploader';

export default {
  name: 'Uploader',
  components: {
    'upload-btn': UploadButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    allowedMimeTypes: {
      type: Array,
      required: true,
    },
    value: {
      type: File,
      required: false,
      default: null,
    },
    fixedWidth: {
      type: String,
      required: false,
      default: null,
    },
    noTitleUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showUploadingProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      uploading: false,
      percentage: 0,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    titleComputed() {
      if (this.showUploadingProgress && this.uploading) {
        return `Uploading: ${this.percentage}%`;
      }
      return this.title || this.$t('general.uploadFile');
    },
    noTitleUpdateComputed() {
      if (this.showUploadingProgress && this.uploading) {
        return true;
      }
      return this.noTitleUpdate;
    },
  },
  methods: {
    /**
     * Handler of event when file is selected in upload button.
     *
     * @param {File|null} file - Selected file object.
     */
    update(file) {
      if (file) {
        if (
          !this.allowedMimeTypes.length
          || this.allowedMimeTypes.indexOf(file.type) !== -1
        ) {
          this.model = file;
        } else {
          this.$refs.uploadBtn.clear();
        }
      } else {
        this.model = null;
      }
      this.$emit('file-update', file);
    },

    /**
     * Get presigned request and upload file to s3 to temporary directory.
     *
     * @return {Object}
     */
    async upload() {
      let presignedData = null;
      if (this.model) {
        try {
          this.uploading = true;
          this.percentage = 0;
          presignedData = await getPresignedData(this.model.name);
          await upload(presignedData, this.model, (percent) => {
            this.percentage = percent;
          });
        } finally {
          this.uploading = false;
        }
      }
      return presignedData;
    },

    /**
     * Clear selected file in upload button.
     */
    clear() {
      this.$refs.uploadBtn.clear();
    },
  },
};
</script>
<style lang="scss">
.upload-btn .v-btn__content {
  height: 36px;
}
</style>
