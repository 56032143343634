<template>
  <v-checkbox
    v-model="textValue"
    :label="label"
  />
</template>
<script>
export default {
  name: 'YesValue',
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    textValue: {
      get() {
        return this.value === 'Y';
      },
      set(value) {
        this.$emit('input', value ? 'Y' : '');
      },
    },
  },
};
</script>
