import hospitalsApi from '@/api/hospitals';

import {
  SET_HOSPITALS_SEARCH_RESULT,
  SET_HOSPITALS_SEARCH_LOADING,
  SET_SELECTED_HOSPITAL,
  SET_SHOW_EDIT_DIALOG,
  SET_LOADING_HOSPITAL,
  SET_HOSPITAL,
} from './mutations';

export const HOSPITALS_SEARCH = 'HOSPITALS_SEARCH';
export const DELETE_HOSPITAL = 'DELETE_HOSPITAL';
export const OPEN_HOSPITALS_RESTORE = 'OPEN_HOSPITALS_RESTORE';
export const RESTORE_HOSPITAL = 'RESTORE_HOSPITAL';
export const OPEN_HOSPITAL_EDIT = 'OPEN_HOSPITAL_EDIT';
export const OPEN_HOSPITAL_ADD = 'OPEN_HOSPITAL_ADD';

export default {
  /**
   * Search hospitals by params.
   *
   * @param commit - State commit.
   * @param state - Hospitals state.
   */
  [HOSPITALS_SEARCH]: async ({ commit, state }) => {
    commit(SET_HOSPITALS_SEARCH_LOADING, true);
    const result = await hospitalsApi.fetchSearchData(
      state.search,
      state.pagination.page,
      state.pagination.itemsPerPage,
      state.pagination.sortBy[0],
      state.pagination.sortDesc[0],
    );
    commit(SET_HOSPITALS_SEARCH_RESULT, result);
    commit(SET_HOSPITALS_SEARCH_LOADING, false);
  },

  /**
   * Open edit selected hospital.
   *
   * @param commit - Vuex commit.
   * @param state - Root state.
   * @param payload - Selected hospital for edit.
   */
  [OPEN_HOSPITAL_EDIT]: async ({ commit, rootState }, payload) => {
    commit(SET_SELECTED_HOSPITAL, payload);
    commit(SET_SHOW_EDIT_DIALOG, true);
    commit(SET_LOADING_HOSPITAL, true);

    const hospital = await hospitalsApi.loadHospital(payload.registryID);
    hospital.types = hospital.types.map(typeID => {
      const type = rootState.hospitalsTypes.hospitalsTypes.find(
        el => el.id === typeID,
      );
      return {
        text: type.name,
        value: type.id,
      };
    });
    commit(SET_HOSPITAL, hospital);
    commit(SET_LOADING_HOSPITAL, false);
  },

  /**
   * Open add new hospital.
   *
   * @param commit - State commit.
   */
  [OPEN_HOSPITAL_ADD]: ({ commit }) => {
    commit(SET_SELECTED_HOSPITAL, {});
    commit(SET_SHOW_EDIT_DIALOG, true);
    commit(SET_LOADING_HOSPITAL, false);
    commit(SET_HOSPITAL, {});
  },
};
