export const SET_DUPLICATE_INCIDENTS_ITEMS = 'SET_DUPLICATE_INCIDENTS_ITEMS';
export const SET_DUPLICATE_INCIDENTS_PAGINATION = 'SET_DUPLICATE_INCIDENTS_PAGINATION';
export const SET_DUPLICATE_INCIDENTS_FILTERS = 'SET_DUPLICATE_INCIDENTS_FILTERS';
export const SET_DUPLICATE_INCIDENT_DATA = 'SET_DUPLICATE_INCIDENT_DATA';
export const SET_ORIGINAL_INCIDENT_DATA = 'SET_ORIGINAL_INCIDENT_DATA';

export default {
  /**
   * Save duplicate incidents data.
   *
   * @param {Object} state - Duplicate incidents state.
   * @param {{ items: Array, totalItems: number }} data - Duplicate incidents data to set
   */
  [SET_DUPLICATE_INCIDENTS_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.pagination.totalItems = totalItems;
  },

  /**
   * Save duplicate incidents pagination.
   *
   * @param {Object} state - Duplicate incidents state.
   * @param {Object} pagination - Pagination info.
   */
  [SET_DUPLICATE_INCIDENTS_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters of duplicate incidents.
   *
   * @param {Object} state - Duplicate incidents state.
   * @param {Object} filters - Filters to set.
   */
  [SET_DUPLICATE_INCIDENTS_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  /**
   * Set duplicate incident data.
   *
   * @param {Object} state - Duplicate incidents state.
   * @param {Object} data - Data to set.
   */
  [SET_DUPLICATE_INCIDENT_DATA]: (state, data) => {
    state.duplicateIncidentData = data;
  },

  /**
   * Set original incident data.
   *
   * @param {Object} state - Duplicate incidents state.
   * @param {Object} data - Data to set.
   */
  [SET_ORIGINAL_INCIDENT_DATA]: (state, data) => {
    state.originalIncidentData = data;
  },
};
