<template>
  <v-layout
    class="pl-9 pr-9"
    row
    wrap
  >
    <v-flex xs4>
      <v-text-field
        v-model="search.search"
        :label="$t('hospitals.search')"
        append-icon="search"
        single-line
      />
    </v-flex>
    <v-flex xs2>
      <v-select
        v-model="search.status"
        :items="statuses"
        :label="$t('hospitals.status')"
        class="ml-4"
        hide-details
        single-line
      />
    </v-flex>
    <v-spacer/>
    <v-btn
      color="primary"
      dark
      class="mb-2"
      @click="addHospital()"
    >
      {{ $t('buttons.add') }}
    </v-btn>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_HOSPITALS_SEARCH_PARAMS } from '@/store/Hospitals/mutations';
import { OPEN_HOSPITAL_ADD } from '@/store/Hospitals/actions';

export default {
  name: 'HospitalsSearchTools',
  computed: {
    /**
     * Hospital statuses.
     */
    statuses() {
      return this.$t('hospitals.statuses').map(status => ({
        text: status,
        value: status.toLowerCase(),
      }));
    },

    /**
     * Search text.
     */
    search: {
      get() {
        return this.$store.state.hospitals.search;
      },
      set(value) {
        this.$store.commit(`hospitals/${SET_HOSPITALS_SEARCH_PARAMS}`, value);
      },
    },
  },
  mounted() {
    this.search.status = 'active';
  },
  methods: {
    ...mapActions({
      addHospital: `hospitals/${OPEN_HOSPITAL_ADD}`,
    }),

    /**
     * Redirect to hospitals types page.
     */
    goToTypes() {
      this.$router.push({ name: 'hospitalsTypes' });
    },
  },
};
</script>
