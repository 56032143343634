import xsd from '@/api/xsd';
import {
  startExport,
  getExportInfo,
  getExportProgress,
  startUpload,
  getUploadInfo,
} from '@/api/exportUtilities';
import {
  SEQUENCE_NUMBER,
  SEND_DATA,
  FETCH_TEMPLATES,
  SET_TEMPLATES,
  CLIENT,
  TEMPLATE,
  FORMAT,
  DATES,
  EXPORT_TYPE,
  INCLUDE_UPDATED,
  USE_CLIENT_EXPORT_SETTINGS,
  DHS,
  BILLING,
  SHOW_RESULTS,
  SET_EXPORT_ID,
  EXPORT_ID,
  GET_EXPORT_INFO,
  GET_EXPORT_PROGRESS,
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  SET_STATUS,
  SET_INCIDENTS_DATA,
  SET_INCIDENTS,
  SET_INCIDENTS_ITEMS,
  SET_IS_STARTED_PROCESSING,
  TOTAL,
  PROCESSED,
  RESET_INCIDENTS,
  RESET_EXPORT_DATA,
  SET_PARAM,
  STATUS,
  EXPORT_STATUS,
  EXPORT_PROGRESS,
  QUEUED,
  PROCESSING,
  START_UPLOAD,
  GET_UPLOAD_INFO,
  UPLOAD_ID,
  FILE_URL,
  UPLOAD_STATUS,
  UPLOAD_PROGRESS,
  EXPORT_ERROR,
  IDLE,
  RESET_UPLOAD_DATA,
  UPLOAD_ERROR,
  IS_STARTED_PROCESSING,
  FAILED,
} from './constants';

const REQUEST_DELAY = 1000;

export default {
  [START_LOADING]: ({ commit }) => {
    commit(SET_PARAM, { type: STATUS, value: true });
  },
  [STOP_LOADING]: ({ commit }) => {
    commit(SET_STATUS, SUCCESS);
  },
  /**
   * Set fetched incidents data to the store.
   * @param commit
   * @param payload
   */
  [SET_INCIDENTS]: ({ commit }, payload) => {
    commit(SET_INCIDENTS_ITEMS, payload.items);
    commit(SET_INCIDENTS_DATA, { type: TOTAL, data: payload[TOTAL] });
    commit(SET_INCIDENTS_DATA, { type: PROCESSED, data: payload[PROCESSED] });
  },
  [RESET_INCIDENTS]: ({ dispatch, commit }) => {
    dispatch(SET_INCIDENTS, {
      [TOTAL]: 0,
      [PROCESSED]: 0,
      items: [],
    });
    commit(SET_INCIDENTS_DATA, { type: 'page', data: 1 });
  },
  [RESET_EXPORT_DATA]: ({ dispatch, commit }) => {
    dispatch(RESET_INCIDENTS);
    commit(SET_PARAM, { type: EXPORT_ID, value: null });
    commit(SET_PARAM, { type: EXPORT_ERROR, value: null });
    commit(SET_PARAM, { type: EXPORT_PROGRESS, value: 0 });
    commit(SET_PARAM, { type: FILE_URL, value: null });
    commit(SET_PARAM, { type: EXPORT_STATUS, value: IDLE });
  },
  [RESET_UPLOAD_DATA]: ({ commit }) => {
    commit(SET_PARAM, { type: UPLOAD_ID, value: null });
    commit(SET_PARAM, { type: UPLOAD_ERROR, value: null });
    commit(SET_PARAM, { type: UPLOAD_PROGRESS, value: 0 });
    commit(SET_PARAM, { type: UPLOAD_STATUS, value: null });
  },
  /**
   * Send filled data from the main form.
   * @param state
   * @param commit
   * @param dispatch
   */
  [SEND_DATA]: ({ state, commit, dispatch }) => {
    dispatch(START_LOADING);

    // Prepare parameters for API methods
    const data = {
      clientId: state[CLIENT],
      rangeFrom: state[DATES].from,
      rangeTo: state[DATES].to,
      sequenceNumbers: [...state[SEQUENCE_NUMBER]],
      includeUpdated: state[INCLUDE_UPDATED],
    };

    // Check export type
    const exportType = state[EXPORT_TYPE];

    if (exportType === DHS) {
      data.xsdVersion = state[TEMPLATE];
    } else if (exportType === BILLING) {
      data.format = state[FORMAT];
      data.useClientExportSettings = state[USE_CLIENT_EXPORT_SETTINGS];

      if (state[USE_CLIENT_EXPORT_SETTINGS]) {
        data.sequenceNumbers = [];
        data.includeUpdated = false;
      }
    }

    // Send API request according to export type
    startExport(data, exportType).then(
      result => {
        commit(SHOW_RESULTS, true);

        // Set export ID
        commit(SET_EXPORT_ID, result.id);

        // Set export status
        commit(SET_PARAM, { type: EXPORT_STATUS, value: result.status });
        commit(SET_IS_STARTED_PROCESSING, false);
        dispatch(GET_EXPORT_PROGRESS);
      },
      error => {
        commit(SHOW_RESULTS, true);
        commit(SET_PARAM, { type: EXPORT_ERROR, value: error.response.data.message });
      },
    );
  },
  /**
   * Fetch export info from the backend.
   * @param state
   * @param dispatch
   * @param commit
   */
  [GET_EXPORT_INFO]: ({ state, dispatch, commit }) => {
    const exportID = state[EXPORT_ID];

    if (exportID === null) return;

    dispatch(START_LOADING);

    getExportInfo(exportID).then(result => {
      dispatch(STOP_LOADING);
      dispatch(SET_INCIDENTS, {
        [TOTAL]: Number(result.total),
        [PROCESSED]: Number(result.countProcessed),
        items: result.incidents,
      });
      commit(SET_PARAM, { type: EXPORT_STATUS, value: result.status });
      if (result.status === PROCESSING) {
        // Formula of calculate delay depends of count of processing incidents
        // (min: 3, max: 10 seconds).
        const delay = Math.min(
          3 * Math.max(1, Math.floor(result.total / 50)),
          10,
        );
        setTimeout(() => {
          dispatch(GET_EXPORT_INFO);
        }, delay * 1000);
      }

      switch (result.status) {
        case PROCESSED:
          commit(SET_PARAM, { type: FILE_URL, value: result.fileUrl });
          break;

        case FAILED:
          commit(SET_PARAM, { type: EXPORT_ERROR, value: result.error });
          break;

        default:
          break;
      }
    });
  },
  /**
   * Check export progress
   * @param state
   * @param commit
   * @param dispatch
   */
  [GET_EXPORT_PROGRESS]: ({ state, commit, dispatch }) => {
    const exportID = state[EXPORT_ID];
    const isStartedProcessing = state[IS_STARTED_PROCESSING];

    if (exportID === null) return;

    getExportProgress(exportID).then(result => {
      const { progress, status } = result;

      commit(SET_PARAM, { type: EXPORT_PROGRESS, value: progress });

      if (status === QUEUED || status === PROCESSING) {
        commit(SET_PARAM, { type: EXPORT_STATUS, value: status });
        setTimeout(() => {
          dispatch(GET_EXPORT_PROGRESS);
        }, REQUEST_DELAY);
        if (status === PROCESSING && !isStartedProcessing) {
          commit(SET_IS_STARTED_PROCESSING, true);
          dispatch(GET_EXPORT_INFO);
        }
      } else {
        dispatch(GET_EXPORT_INFO);
      }
    });
  },
  /**
   * Start uploading process.
   * @param state
   * @param commit
   * @param dispatch
   */
  [START_UPLOAD]: ({ state, commit, dispatch }) => {
    const exportID = state[EXPORT_ID];

    if (exportID === null) return;

    startUpload(exportID).then(result => {
      commit(SET_PARAM, { type: UPLOAD_ID, value: result.id });
      dispatch(GET_UPLOAD_INFO);
    });
  },
  /**
   * Check upload progress status
   * @param state
   * @param commit
   * @param dispatch
   */
  [GET_UPLOAD_INFO]: ({ state, commit, dispatch }) => {
    const uploadID = state[UPLOAD_ID];

    if (uploadID === null) return;

    getUploadInfo(uploadID).then(result => {
      const { status, progress } = result;

      commit(SET_PARAM, { type: UPLOAD_PROGRESS, value: progress });
      commit(SET_PARAM, { type: UPLOAD_STATUS, value: status });

      if (result.error) {
        commit(SET_PARAM, { type: UPLOAD_ERROR, value: result.error });
      }

      if (status === QUEUED || status === PROCESSING) {
        setTimeout(() => {
          dispatch(GET_UPLOAD_INFO);
        }, REQUEST_DELAY);
      }
    });
  },
  /**
   * Fetch templates list from backend.
   * @param commit
   * @returns {Promise<void>}
   */
  [FETCH_TEMPLATES]: async ({ commit }) => {
    const templates = await xsd.fetchXSDVersions();

    commit(SET_TEMPLATES, templates);

    // Set default template after fetching
    const currentTemplate = templates.find(template => template.current);

    if (currentTemplate && typeof currentTemplate === 'object') {
      const { version: value } = currentTemplate;

      commit(SET_PARAM, { type: TEMPLATE, value });
    }
  },
};
