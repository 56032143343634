<template>
  <div class="transaction-logs-table">
    <v-data-table
        :headers="header"
        :items="entity.items || []"
        :options.sync="pagination"
        :server-items-length="entity.total"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        :height="tableHeight"
        fixed-header
    >
      <template v-slot:item.IncidentDate="{ item }">
        {{ item.IncidentDate | date }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | date }}
      </template>
      <template v-slot:item.allowedTo="{ item }">
        {{ item | allowedTo }}
      </template>
      <template v-slot:item.dispatchId="{ item }">
        {{ item.dispatchId }} (#{{ item.inventoryNumber }})
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$store.getters.isImpersonated && item.isConstant && isRemoteHandoff"
          color="green"
          class="mt-2"
        >
          mdi-lock-outline
        </v-icon>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                class="float-right"
                v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="$emit('reassignClick', item)">
              <v-list-item-icon>
                <v-icon>
                  mdi-link-lock
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Reassign To Unit</v-list-item-title>
            </v-list-item>
            <template v-if="$store.getters.isImpersonated && isRemoteHandoff">
              <v-list-item
                  v-if="item.isConstant"
                  @click="markASConstantly(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    mdi-lock-open-variant-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Unmark As Constantly</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-else
                  @click="markASConstantly(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    mdi-lock-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Mark As Constantly</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import ROUTES from '@/enums/routes';
import { createNamespacedHelpers } from 'vuex';
import transmittedReports from '@/api/transmittedReports';
import { SET_PAGINATION } from '@/store/TransactionLogs/mutations';
import { LOAD_ITEMS } from '@/store/TransmittedReports/actions';
import fixedHeader from '@/mixins/fixedHeader';
import TRANSMITTED_TYPES from '@/enums/transmittedTypes';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('transmittedReports');

export default {
  name: 'TransmittedReportsTable',

  filters: {
    allowedTo(item) {
      if (item.assignedToAll) {
        return 'All Your Units';
      }
      if (item.countAssigned) {
        return 'Custom Units';
      }
      if (item.countCadAssigned) {
        return 'CAD only';
      }
      return 'No Units';
    },
  },

  props: {
    type: {
      type: String,
      required: true,
    },
    sequenceNumber: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      debounce: null,
      routes: ROUTES,
      seqNum: null,
    };
  },

  mixins: [fixedHeader],

  watch: {
    pagination: {
      handler(newValue) {
        this.pagination = newValue;
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),
    isRemoteHandoff() {
      return this.type === TRANSMITTED_TYPES.REMOTE_HANDOFF;
    },
    header() {
      const header = [
        {
          text: 'Sequence Number',
          value: 'SequenceNumber',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Incident Date',
          value: 'IncidentDate',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Incident Number',
          value: 'IncidentNumber',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Last Name',
          value: 'LastName',
          sortable: true,
        },
        {
          text: 'Transmitting Unit',
          value: 'dispatchId',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Transmitting Date',
          value: 'created_at',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Allowed To',
          value: 'allowedTo',
          sortable: false,
          width: '12%',
        },
      ];
      if (this.$store.getters.isImpersonated) {
        header.push({
          text: 'Template',
          value: 'templateName',
          sortable: true,
          width: '12%',
        });
      }
      header.push({
        text: '',
        value: 'actions',
        sortable: false,
        width: '120px',
      });
      return header;
    },
    pagination: {
      get() {
        return this.entity.pagination;
      },
      set(value) {
        const isEqual = _.isEqual(value, this.entity.pagination);
        this.setPagination(value);
        if (!isEqual) {
          this.reloadItems();
        }
      },
    },
  },

  created() {
    if (this.sequenceNumber !== null) {
      this.seqNum = this.sequenceNumber;
    }

    if (this.entity.items === null) {
      this.reloadItems();
    }
    this.recalculateHeights();
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PAGINATION,
    }),
    ...mapActions({
      loadItems: LOAD_ITEMS,
    }),

    /**
     * Reloads paginated list for current filter.
     *
     * @param {String|null} seqNum - Sequence number
     */
    async reloadItems(seqNum = null) {
      this.loading = true;

      if (seqNum !== null) {
        this.seqNum = seqNum;
      }

      try {
        await this.loadItems({ type: this.type, sequenceNumber: this.seqNum });
      } finally {
        this.loading = false;
      }
    },
    changeSort(column) {
      const pagination = _.clone(this.pagination);
      if (pagination.sortBy[0] === column) {
        if (pagination.sortDesc[0] === true) {
          pagination.sortBy = [];
          pagination.sortDesc = [];
        } else {
          pagination.sortDesc = [true];
        }
      } else {
        pagination.sortBy = [column];
        pagination.sortDesc = [false];
      }
      this.pagination = pagination;
    },
    async markASConstantly(item) {
      try {
        this.loading = true;
        if (item.isConstant) {
          await transmittedReports.unmarkAsConstantly(item.id);
        } else {
          await transmittedReports.markAsConstantly(item.id);
        }
        await this.reloadItems();
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>

<style lang="scss">
.transaction-logs-table {
  .filter-col {
    font-weight: normal;
  }
}
</style>
