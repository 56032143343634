import profileSettings from '@/api/profileSettings';
import allowedAccessNets from '@/api/allowedAccessNets';
import {
  SET_NOTIFICATION_RULES,
  SET_NOTIFICATION_EMAILS,
  SET_NOTIFICATION_TIME,
  ADD_NOTIFICATION_RULE,
  UPDATE_NOTIFICATION_RULE,
  SET_CE_ADMIN_NOTIFICATION,
  SET_BILLING_EXPORT_SETTINGS,
  SET_DHS_EXPORT_SETTINGS,
  SET_MINDBASE_EXPORT_SETTINGS,
  SET_DHS_EXPORT_UPLOAD_TYPES,
  SET_BILLING_EXPORT_UPLOAD_TYPES,
  SET_UNFILTERED_EXPORT_SETTINGS,
  SET_SECURITY_SETTINGS_API_KEY,
  SET_SECURITY_SETTINGS_ACCESS_RESTRICTION,
  SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS,
  SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY,
} from './mutations';

export const GET_ALL_SETTINGS = 'GET_ALL_SETTINGS';

// Notifications.
export const UPDATE_REPORT_NOTIFICATION = 'UPDATE_REPORT_NOTIFICATION';
export const GET_DEVICE_NOTIFICATION = 'GET_DEVICE_NOTIFICATION';
export const FETCH_DEVICE_NOTIFICATION_RULE = 'FETCH_DEVICE_NOTIFICATION_RULE';
export const UPDATE_DEVICE_NOTIFICATION_TIME = 'UPDATE_DEVICE_NOTIFICATION_TIME';
export const GET_TRANSMITTED_NOTIFICATION = 'GET_TRANSMITTED_NOTIFICATION';
export const FETCH_TRANSMITTED_NOTIFICATION_RULE = 'FETCH_TRANSMITTED_NOTIFICATION_RULE';
export const UPDATE_TRANSMITTED_NOTIFICATION_TIME = 'UPDATE_TRANSMITTED_NOTIFICATION_TIME';
export const DELETE_NOTIFICATION_RULE = 'DELETE_NOTIFICATION_RULE';

// Export settings.
export const GET_BILLING_EXPORT_UPLOAD_TYPES = 'GET_BILLING_EXPORT_UPLOAD_TYPES';
export const UPDATE_BILLING_EXPORT_SETTINGS = 'UPDATE_BILLING_EXPORT_SETTINGS';
export const UPDATE_UNFILTERED_EXPORT_SETTINGS = 'UPDATE_UNFILTERED_EXPORT_SETTINGS';
export const GET_DHS_EXPORT_UPLOAD_TYPES = 'GET_DHS_EXPORT_TYPES';
export const UPDATE_DHS_EXPORT_SETTINGS = 'UPDATE_DHS_EXPORT_SETTINGS';
export const UPDATE_MINDBASE_EXPORT_SETTINGS = 'UPDATE_MINDBASE_EXPORT_SETTINGS';

export const UPDATE_CE_ADMINS_NOTIFIABLE = 'UPDATE_CE_ADMINS_NOTIFIABLE';

// Security settings.
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const DELETE_ACCESS_RESTRICTION = 'DELETE_ACCESS_RESTRICTION';
export const GET_ACCESS_RESTRICTIONS = 'GET_ACCESS_RESTRICTIONS';

// Deleted unique forms notification.
export const FETCH_DELETED_UNIQUE_FORMS_NOTIFICATION = 'FETCH_DELETED_UNIQUE_FORMS_NOTIFICATION';
export const UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION = 'UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION';

export default {
  /**
   * Get all settings for profile: notifications, exports and security.
   */
  [GET_ALL_SETTINGS]: async ({ commit }) => {
    const results = await Promise.all([
      profileSettings.getReportNotification(),
      profileSettings.getDeviceNotification(),
      profileSettings.getTransmittedNotification(),
      profileSettings.getCeAdminNotifications(),
      profileSettings.getBillingExportSettings(),
      profileSettings.getDhsExportSettings(),
      profileSettings.getMindBaseExportSettings(),
      profileSettings.getUnfilteredExportSettings(),
      profileSettings.getSecuritySettingsApiKey(),
      allowedAccessNets.getAccessRestrictions(),
      profileSettings.getDeletedUniqueFormsNotification(),
    ]);
    const [
      notificationSettings,
      deviceNotificationSettings,
      transmittedNotification,
      ceAdminNotifications,
      billingExportSettings,
      dhsExportSettings,
      mindBaseExportSettings,
      unfilteredExportSettings,
      apiKey,
      accessRestrictions,
      deletedUniqueFormsNotificationSetting,
    ] = results;

    commit(SET_NOTIFICATION_EMAILS, notificationSettings.emails);
    commit(SET_NOTIFICATION_TIME, { time: notificationSettings.time, module: 'reportNotification' });

    commit(SET_NOTIFICATION_RULES, { rules: deviceNotificationSettings.rules, module: 'deviceNotification' });
    commit(SET_NOTIFICATION_TIME, { time: deviceNotificationSettings.time, module: 'deviceNotification' });

    commit(SET_NOTIFICATION_RULES, { rules: transmittedNotification.rules, module: 'transmittedNotification' });
    commit(SET_NOTIFICATION_TIME, { time: transmittedNotification.time, module: 'transmittedNotification' });

    commit(SET_CE_ADMIN_NOTIFICATION, ceAdminNotifications);

    commit(SET_BILLING_EXPORT_SETTINGS, billingExportSettings);

    commit(SET_DHS_EXPORT_SETTINGS, dhsExportSettings);

    commit(SET_MINDBASE_EXPORT_SETTINGS, mindBaseExportSettings);

    commit(SET_UNFILTERED_EXPORT_SETTINGS, unfilteredExportSettings);

    commit(SET_SECURITY_SETTINGS_API_KEY, apiKey);
    commit(SET_SECURITY_SETTINGS_ACCESS_RESTRICTION, accessRestrictions.results);

    commit(
      SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS,
      deletedUniqueFormsNotificationSetting.emails,
    );
    commit(
      SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY,
      deletedUniqueFormsNotificationSetting.frequency,
    );

    return results;
  },

  /**
   * Update emails or time for notification.
   * @param params
   * @returns {Promise<*>}
   */
  [UPDATE_REPORT_NOTIFICATION]: async ({ commit }, params) => {
    const results = await profileSettings.updateReportNotification(params);

    commit(SET_NOTIFICATION_EMAILS, results.emails);
    commit(SET_NOTIFICATION_TIME, { time: results.time, module: 'reportNotification' });

    return results;
  },

  /**
   * Get time and rules for device notifications and save its to state.
   * @param commit
   * @returns {Promise<T>}
   */
  [GET_DEVICE_NOTIFICATION]: async ({ commit }) => {
    const results = await profileSettings.getDeviceNotification();
    const { rules, time } = results;

    commit(SET_NOTIFICATION_RULES, { rules, module: 'deviceNotification' });
    commit(SET_NOTIFICATION_TIME, { time, module: 'deviceNotification' });

    return results;
  },

  /**
   * Update or create report on device notification rule.
   * @param rule Rule data
   * @param ruleId Rule id (for already created rules)
   * @returns {Promise<T>}
   */
  [FETCH_DEVICE_NOTIFICATION_RULE]: async ({ commit }, { rule, ruleId }) => {
    const params = { ...rule };
    let results;

    if (!ruleId) {
      results = await profileSettings.createDeviceNotificationRule(params);

      commit(ADD_NOTIFICATION_RULE, { rule: results, module: 'deviceNotification' });
    } else {
      params.ruleId = ruleId;
      results = await profileSettings.updateDeviceNotificationRule(params);

      commit(UPDATE_NOTIFICATION_RULE, { rule: results, module: 'deviceNotification' });
    }

    return results;
  },

  /**
   * Update device notification time.
   * @param time New time
   * @returns {Promise<T>}
   */
  [UPDATE_DEVICE_NOTIFICATION_TIME]: async ({ commit }, time) => {
    const results = await profileSettings.updateDeviceNotificationTime({ time });

    commit(SET_NOTIFICATION_RULES, { rules: results.rules, module: 'deviceNotification' });
    commit(SET_NOTIFICATION_TIME, { time: results.time, module: 'deviceNotification' });

    return results;
  },

  /**
   * Create or update transmitted notification rule.
   * @param rule Rule data
   * @param ruleId Rule id (for already created rules)
   * @returns {Promise<T>}
   */
  [FETCH_TRANSMITTED_NOTIFICATION_RULE]: async ({ commit }, { rule, ruleId }) => {
    const params = { ...rule };
    let results;

    if (!ruleId) {
      results = await profileSettings.createTransmittedNotificationRule(params);

      commit(ADD_NOTIFICATION_RULE, { rule: results, module: 'transmittedNotification' });
    } else {
      params.ruleId = ruleId;
      results = await profileSettings.updateTransmittedNotificationRule(params);

      commit(UPDATE_NOTIFICATION_RULE, { rule: results, module: 'transmittedNotification' });
    }

    return results;
  },

  /**
   * Delete notification rule by id.
   * @param ruleId
   * @returns {Promise<T>}
   */
  [DELETE_NOTIFICATION_RULE]: (state, ruleId) => profileSettings.deleteNotificationRule({ ruleId }),

  /**
   * Update transmitted notification time.
   * @param time New time
   * @returns {Promise<T>}
   */
  [UPDATE_TRANSMITTED_NOTIFICATION_TIME]: async ({ commit }, time) => {
    const results = await profileSettings.updateTransmittedNotificationTime({ time });

    commit(SET_NOTIFICATION_RULES, { rules: results.rules, module: 'transmittedNotification' });
    commit(SET_NOTIFICATION_TIME, { time: results.time, module: 'transmittedNotification' });

    return results;
  },

  /**
   * Get transmitted notification (rules and time).
   * @param commit
   * @returns {Promise<T>}
   */
  [GET_TRANSMITTED_NOTIFICATION]: async ({ commit }) => {
    const results = await profileSettings.getTransmittedNotification();
    const { rules, time } = results;

    commit(SET_NOTIFICATION_RULES, { rules, module: 'transmittedNotification' });
    commit(SET_NOTIFICATION_TIME, { time, module: 'transmittedNotification' });

    return results;
  },

  /**
   * Get DHS Export upload types.
   */
  [GET_DHS_EXPORT_UPLOAD_TYPES]: async ({ commit }) => {
    const dhsExportTypes = await profileSettings.getDhsExportUploadTypes();

    commit(SET_DHS_EXPORT_UPLOAD_TYPES, dhsExportTypes);
  },

  /**
   * Get Billing Export upload types.
   */
  [GET_BILLING_EXPORT_UPLOAD_TYPES]: async ({ commit }) => {
    const billingExportTypes = await profileSettings.getBillingExportUploadTypes();

    commit(SET_BILLING_EXPORT_UPLOAD_TYPES, billingExportTypes);
  },

  /**
   * Update billing export settings.
   */
  [UPDATE_BILLING_EXPORT_SETTINGS]: async ({ commit }, settings) => {
    const results = await profileSettings.updateBillingExportSettings(settings);

    commit(SET_BILLING_EXPORT_SETTINGS, results);

    return results;
  },

  /**
   * Update unfiltered export settings.
   */
  [UPDATE_UNFILTERED_EXPORT_SETTINGS]: async ({ commit }, settings) => {
    const results = await profileSettings.updateUnfilteredExportSettings(settings);

    commit(SET_UNFILTERED_EXPORT_SETTINGS, results);

    return results;
  },

  /**
   * Update dhs export settings.
   */
  [UPDATE_DHS_EXPORT_SETTINGS]: async ({ commit }, settings) => {
    const results = await profileSettings.updateDhsExportSettings(settings);

    commit(SET_DHS_EXPORT_SETTINGS, results);

    return results;
  },

  /**
   * Update MindBase export settings.
   */
  [UPDATE_MINDBASE_EXPORT_SETTINGS]: async ({ commit }, settings) => {
    const results = await profileSettings.updateMindBaseExportSettings(settings);

    commit(SET_MINDBASE_EXPORT_SETTINGS, results);

    return results;
  },

  /**
   * Update CE admins notifiable.
   */
  [UPDATE_CE_ADMINS_NOTIFIABLE]: async ({ commit }, notifiableAdminIds) => {
    const results = await profileSettings.updateCeAdminNotifications(notifiableAdminIds);

    commit(SET_CE_ADMIN_NOTIFICATION, results);

    return results;
  },

  /**
   * Refresh API key.
   */
  [UPDATE_API_KEY]: async ({ commit }) => {
    const results = await profileSettings.updateSecuritySettingsApiKey();

    commit(SET_SECURITY_SETTINGS_API_KEY, results);

    return results;
  },

  /**
   * Delete notification rule by id.
   * @param netId
   * @returns {Promise<T>}
   */
  [DELETE_ACCESS_RESTRICTION]: (state, netId) => allowedAccessNets.deleteAccessRestriction({
    netId,
  }),

  /**
   * Get access restrictions list.
   * @param commit
   * @returns {Promise<void>}
   */
  [GET_ACCESS_RESTRICTIONS]: async ({ commit }) => {
    const results = await allowedAccessNets.getAccessRestrictions();

    commit(SET_SECURITY_SETTINGS_ACCESS_RESTRICTION, results.results);

    return results;
  },

  /**
   * Fetch deleted unique form notification data
   *
   * @param commit
   *
   * @returns {Promise<void>}
   */
  [FETCH_DELETED_UNIQUE_FORMS_NOTIFICATION]: async ({ commit }) => {
    const results = await profileSettings.getDeletedUniqueFormsNotification();

    commit(SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS, results.emails);
    commit(SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY, results.frequency);

    return results;
  },

  [UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION]: async ({ commit }, settings) => {
    const results = await profileSettings.updateDeletedUniqueFormsNotification(settings);

    commit(SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS, results.emails);
    commit(SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY, results.frequency);

    return results;
  },
};
