import api from './api';

export default {
  /**
     * Fetch list of presets.
     *
     * @param {Object} params - Request params
     *
     * @return Promise<Object>
     */
  fetchList(params) {
    return api()
      .get('/client/preset-manager', { params })
      .then(response => response.data);
  },

  /**
   * Create new preset.
   *
   * @param {Object} preset - Preset object to save
   */
  create(preset) {
    return api()
      .post('/client/preset-manager', preset)
      .then(response => response.data);
  },

  /**
   * Update preset
   *
   * @param {Object} preset - Preset object to save
   */
  update(preset) {
    return api()
      .put(`/client/preset-manager/${preset.id}`, preset)
      .then(response => response.data);
  },

  /**
   * Delete preset
   *
   * @param {integer} id - Preset object identifier
   */
  delete(id) {
    return api()
      .delete(`/client/preset-manager/${id}`)
      .then(response => response.data);
  },

  /**
   * Fetch list of all possible recipients.
   *
   * @returns Promise<Object>
   */
  fetchRecipients() {
    return api()
      .get('/client/preset-manager/recipients')
      .then(response => response.data);
  },

  /**
   * Share preset.
   *
   * @param {integer} id - Preset identifier
   * @param {Object} params - Params for sharing
   *
   * @returns {Promise}
   */
  share(id, params) {
    return api()
      .post(`/client/preset-manager/${id}/share`, params);
  },

  /**
   * Accept shared preset.
   *
   * @param {integer} id - Preset identifier
   * @param {Object} params - Params for accepting
   *
   * @returns {Promise}
   */
  accept(id, params) {
    return api()
      .post(`/client/preset-manager/${id}/accept`, params);
  },

  /**
   * Reject shared preset.
   *
   * @param {integer} id - Preset identifier
   * @param {Object} params - Params for rejecting
   *
   * @returns {Promise}
   */
  reject(id, params) {
    return api()
      .post(`/client/preset-manager/${id}/reject`, params);
  },
};
