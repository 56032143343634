export default {
  header: 'First Run Devices',
  deleteConfirmationTitle: 'Delete device registration request',
  deleteConfirmationText:
    'Do you really want to delete registration request of device with UDID: {UDID}?',
  tableHeaders: {
    UDID: 'UDID',
    address: 'Address',
    location: 'Location',
    createdAt: 'Created At',
  },
  filters: {
    search: 'Search',
  },
};
