import INCIDENT_FIELDS from '@/enums/incidentFields';
import _ from 'lodash';
import convertFilters from '@/utils/basicSearchFiltersConverter';
import SEARCH_TYPES from '@/enums/searchTypes';

/* eslint func-names:0 */
export default function getSearchParams(state, type, selectedFields) {
  const {
    pagination, basicSearchFilters, advancedSearchFilters,
  } = state;
  const paginationElement = pagination;
  const basicFilters = basicSearchFilters[type];
  const advancedFilters = advancedSearchFilters;
  const fields = _.clone(selectedFields);
  fields.push(INCIDENT_FIELDS.SEQUENCE_NUMBER,
    INCIDENT_FIELDS.INCIDENT_NUMBER,
    INCIDENT_FIELDS.INCIDENT_DATE);
  let filters = [];
  const basicConditionsGroup = convertFilters(basicFilters);
  if (basicConditionsGroup) {
    filters.push(basicConditionsGroup);
  }
  if (advancedFilters.length) {
    filters = filters.concat(advancedFilters);
  }
  const params = {
    type,
    fields,
    page: paginationElement.page,
    per_page: paginationElement.itemsPerPage,
    order_by: paginationElement.sortBy[0],
    sort_order: paginationElement.sortDesc[0] ? 'desc' : 'asc',
    filters,
  };
  if (type === SEARCH_TYPES.FLAGGED) {
    // Search all statuses if filter by status is empty
    params.statuses = basicFilters.status !== null ? [basicFilters.status] : [0, 1, 2];
  }
  return params;
}
