<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    :height="tableHeight"
    fixed-header
    class="header-nowrap"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <no-data-available
          :headers="headers"
          :items="items"
        ></no-data-available>
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.datetime | datetime }}</td>
          <td>
            {{ $t('apiAuditLog.items.deviceID') }}: <strong>{{ item.deviceId }}</strong>
            <template v-if="item.inventoryNumber">
              <br>
              {{ $t('apiAuditLog.items.inventoryNumber') }}:
              <strong>
                {{ item.inventoryNumber }}
              </strong>
            </template>
          </td>
          <td>
            <template v-if="item.dispatchId">
              {{ $t('apiAuditLog.items.dispatchId') }}: <strong>{{ item.dispatchId }}</strong>
            </template>
            <template v-if="item.UDID">
              <br>
              {{ $t('apiAuditLog.items.UDID') }}: <strong>{{ item.UDID }}</strong>
            </template>
          </td>
          <td>{{ item.methodName }}</td>
          <td>
            <vue-json-pretty
              v-if="Array.isArray(item.significantData)"
              :data="item.significantData"
              :deep="0"
            ></vue-json-pretty>
            <template v-else>
              {{ item.significantData }}
            </template>
          </td>
          <td
            v-if="item.APIStatus"
            class="green--text"
          >
            {{ $t('apiAuditLog.statuses.success') }}
          </td>
          <td
            v-else
            class="red--text"
          >
            {{ $t('apiAuditLog.statuses.fail') }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import fixedHeader from '@/mixins/fixedHeader';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'AuditLogTable',
  components: {
    VueJsonPretty,
    NoDataAvailable,
  },
  mixins: [fixedHeader],
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('apiAuditLog.tableHeaders.datetime'),
          align: 'left',
          value: 'datetime',
          sortable: true,
        },
        {
          text: this.$t('apiAuditLog.tableHeaders.deviceInfo1'),
          sortable: false,
        },
        {
          text: this.$t('apiAuditLog.tableHeaders.deviceInfo2'),
          sortable: false,
        },
        {
          text: this.$t('apiAuditLog.tableHeaders.methodName'),
          value: 'methodName',
          sortable: false,
        },
        {
          text: this.$t('apiAuditLog.tableHeaders.significantData'),
          value: 'significantData',
          sortable: false,
        },
        {
          text: this.$t('apiAuditLog.tableHeaders.status'),
          value: 'status',
          sortable: false,
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
};
</script>
<style lang="scss">
table thead tr th {
  z-index: 0 !important;
}
</style>
