<template>
  <div class="hospital-incidents">
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{itemsPerPageOptions: [10, 25, 50]}"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :height="tableHeight"
        show-expand
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap"
    >
      <template v-slot:item.sequenceNumber="{ item }">
        <router-link :to="{ name: routes.INCIDENT_VIEW, params: { id: item.id }}">
          <b>{{ item.sequenceNumber }}</b>
        </router-link>
      </template>
      <template v-slot:item.uuid="{ item }">
        <v-btn
            small
            plain
            class="view-uuid-button"
            :disabled="item.uuid === null"
            @click="showUUID(item)"
        >View UUID</v-btn>
      </template>
      <template v-slot:item.incidentDateTime="{ item }">
        {{ item.incidentDateTime | datetime }}
      </template>
      <template v-slot:item.atHospitalDateTime="{ item }">
        {{ item.atHospitalDateTime | datetime }}
      </template>
      <template v-slot:item.age="{ item }">
        {{ item.age }}<span v-if="item.ageUnit !== null"> {{ item.ageUnit }}</span>
      </template>
      <template v-slot:item.complaints="{ item }">
        <span v-if="item.complaints.length !== 0"> {{ item.complaints.join(', ') }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
            :colspan="headers.length - 1"
            :class="['incident-files']"
        >
          <hospital-incident-files
              :items="item.urls"
              :incident="item"
              @click="showFile"
          />
        </td>
        <td
            :colspan="1"
            :class="['tr-download']"
        >
          <v-btn
              small
              color="primary"
              :loading="item.isDownloadingPdf"
              @click="downloadGeneralPdf(item)"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <view-incident-popup
        ref="viewIncidentPopup"
        :showFlagBtn="false"
    />
    <hospital-incident-uuid-popup ref="hospitalIncidentUUIDPopup"/>
  </div>
</template>

<script>
import _ from 'lodash';
import HospitalIncidentFiles from '@/components/Hospitals/HospitalIncidentFiles';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';
import HospitalIncidentUuidPopup from '@/components/Hospitals/HospitalIncidentUuidPopup';
import ROUTES from '@/enums/routes';
import fixedHeader from '@/mixins/fixedHeader';
import downloadPdf from '@/mixins/downloadPdf';

export default {
  name: 'HospitalIncidentsTable',
  components: {
    HospitalIncidentFiles,
    ViewIncidentPopup,
    HospitalIncidentUuidPopup,
  },
  mixins: [fixedHeader, downloadPdf],
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      singleExpand: false,
      expanded: [],
      additionalIndent: 0,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        totalItems: 0,
      },
      routes: ROUTES,
      headers: [
        {
          text: 'Incident Date',
          value: 'incidentDateTime',
          sortable: false,
        },
        {
          text: 'At Hospital Date',
          value: 'atHospitalDateTime',
          sortable: false,
        },
        {
          text: 'Sequence Number',
          value: 'sequenceNumber',
          sortable: false,
        },
        {
          text: 'UUID',
          value: 'uuid',
          sortable: false,
        },
        {
          text: 'Transport Agency',
          value: 'agency',
          sortable: false,
        },
        {
          text: 'Transport Unit',
          value: 'unit',
          sortable: false,
        },
        {
          text: 'Pt. Last Name',
          value: 'ptLastName',
          sortable: false,
        },
        {
          text: 'Age',
          value: 'age',
          sortable: false,
        },
        {
          text: 'M/F',
          value: 'sex',
          sortable: false,
        },
        {
          text: 'Chief Complaint(s)',
          value: 'complaints',
          sortable: false,
        },
      ],
    };
  },

  watch: {
    /**
     * Watch pagination options and update pagination props.
     *
     * @param {Object} newData - New data for pagination
     * @param {Object} oldData - Old data for pagination
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.$emit('updatePagination', newData);
        this.expanded = [];
      }
    },
  },

  methods: {
    setPage(pageNum) {
      this.pagination.page = pageNum;
    },

    /**
     * Show files of incident.
     *
     * @param {Number} id - Index of selected file to show
     * @param {Object} incident - Object with data of incident
     */
    showFile(id, incident) {
      this.$refs.viewIncidentPopup.show(incident, false, true, false, id, null, null, true);
    },

    /**
     * Show incident UUID popup.
     *
     * @param {Object} incident - Incident data
     */
    showUUID(incident) {
      this.$refs.hospitalIncidentUUIDPopup.show(incident);
    },

    /**
     * Download general PDF file.
     *
     * @param {Object} incident - Object with data of incident
     */
    async downloadGeneralPdf(incident) {
      const inc = incident;
      inc.isDownloadingPdf = true;
      await this.downloadPdf(incident.id, 'general', null);
    },

    /**
     * Fresh expanded rows.
     */
    freshExpanded() {
      this.expanded = [];
    },
  },
};
</script>

<style lang="scss">
  .hospital-incidents table thead tr th {
    z-index: 1 !important;
  }
  tr.v-data-table__expanded__content {
    height: 150px;
  }
  .tr-download {
    text-align: right;
  }
  .incident-files {
    max-width: 920px;
  }
  .view-uuid-button {
    padding: 0!important;
  }
</style>
