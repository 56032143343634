<template>
  <v-dialog
    v-if="faxItem"
    v-model="showResendPopup"
    persistent
    max-width="400"
    @close="$emit('close')"
  >
    <v-card class="pa-4">
      <v-card-title class="headline pt-0">Fax info</v-card-title>
      <v-card-text :class="$style.column">
        <span>Client:
          {{ faxItem.client.name }}
        </span>
        <span>Incident Sequence number:
          <a @click="impersonateToIncident(faxItem.client.registryId, faxItem.incident.id)">
            {{ faxItem.incident.sequenceNumber }}
          </a>
        </span>
        <span>Phone:
          {{ faxItem.phone }}
        </span>
        <h4>{{ message }}</h4>
      </v-card-text>
      <v-card-actions class="pb-0 pt-0">
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click.native="cancel"
        >DISMISS</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import impersonateMixins from '@/mixins/impersonateMixins';

const { mapState } = createNamespacedHelpers('faxes');

export default {
  name: 'FaxResendPopup',
  mixins: [impersonateMixins],
  data() {
    return {
      /**
       * Flag for show/hide this popup.
       */
      showResendPopup: false,
      /**
       * Fax item for display it info.
       */
      faxItem: null,
    };
  },
  computed: {
    ...mapState({
      resendSuccessStatus: state => state.resendSuccessStatus,
      resendErrorMessage: state => state.resendErrorMessage,
    }),
    /**
     * Get success or error message after resending.
     */
    message() {
      if (this.resendSuccessStatus) {
        return 'Fax is successfully re-send. Status is Re-send';
      }

      if (this.resendErrorMessage) {
        return `Error: ${this.resendErrorMessage}`;
      }

      return '';
    },
  },
  methods: {
    /**
     * Show current popup and save fax data.
     * @param fax
     */
    async showPopup(fax) {
      this.showResendPopup = true;
      this.faxItem = fax;
    },
    /**
     * Cancel popup and emit table update.
     */
    cancel() {
      this.showResendPopup = false;
      this.$emit('tableUpdate');
    },
  },
};
</script>

<style lang="scss" module>
.column {
  display: flex;
  flex-direction: column;
}
</style>
