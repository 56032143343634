<template>
  <vue2-date-range-picker
    ref="picker"
    v-model="dateRange"
    :class="$style.host"
    :timePicker="false"
    control-container-class=""
    :ranges="ranges"
    :show-dropdowns="true"
    :opens="position"
    :disabled="disabled"
    @select="dateRange = $event"
    @update="applyDates"
    @toggle="toggle"
  >
    <div slot="header">
      <slot name="header"/>
    </div>

    <template #input>
      <v-text-field
        v-model="dateRangeText"
        :disabled="disabled"
        readonly
        prepend-inner-icon="event"
        style="min-width: 230px"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
        :solo="solo"
        :dense="dense"
        :label="label"
        :loading="loading"
      ></v-text-field>
    </template>

    <div
      slot="footer"
      slot-scope="data"
      :class="$style.footer"
    >
      <v-btn
          v-if="clearable"
          text
          color="primary"
          @click="clear(data)"
      >Clear</v-btn>
      <v-spacer />
      <v-btn
        text
        @click="cancel(data)"
      >Cancel</v-btn>
      <v-btn
        text
        color="primary"
        @click="apply(data)"
      >Apply</v-btn>
    </div>
  </vue2-date-range-picker>
</template>

<script>
import moment from 'moment';
import Vue2DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

/**
 * Display DateRangePicker.
 */
export default {
  name: 'DateRangePickerWithPeriods',

  components: {
    Vue2DateRangePicker,
  },
  props: {
    value: {
      type: Array,
    },
    position: {
      type: String,
      default: 'center',
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    periods: {
      type: Object,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Date Range',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    /**
     * Dates range.
     */
    dates: null,
    datesTmp: null,
    applied: false,
    cancelled: false,
  }),

  computed: {
    dateRange: {
      get() {
        if (this.dates) {
          return this.dates;
        }
        const dates = {
          startDate: null,
          endDate: null,
        };
        if (this.value && this.value.length) {
          dates.startDate = moment(this.value[0]).toDate();
          if (this.value.length === 2) {
            dates.endDate = moment(this.value[1]).toDate();
          }
        }
        return dates;
      },
      set(value) {
        this.dates = value;
      },
    },

    ranges() {
      if (this.periods !== undefined) {
        return this.periods;
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const yesterday = moment().subtract(1, 'days').toDate();

      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'Last 7 days': [moment().subtract(6, 'days').toDate(), today],
        'Month to date': [moment().startOf('month').toDate(), today],
        'Year to date': [moment().startOf('year').toDate(), today],
        'The previous Month': [
          moment().subtract(1, 'months').startOf('month').toDate(),
          moment().subtract(1, 'months').endOf('month').toDate(),
        ],
      };
    },

    /**
     * Date range input text.
     */
    dateRangeText() {
      const { startDate, endDate } = this.dateRange;

      if (!startDate) {
        return '';
      }

      const dateFilter = this.$options.filters.date;
      const startDateFiltered = dateFilter(startDate);
      const endDateFiltered = endDate && dateFilter(endDate);

      if (endDateFiltered) {
        return `${startDateFiltered} - ${endDateFiltered}`;
      }

      return `${startDateFiltered}`;
    },
  },

  methods: {
    /**
     * Cancel date time range picker.
     */
    clear(data) {
      this.dates = null;
      data.clickCancel();
      this.$emit('input', null);
    },
    /**
     * Cancel date time range picker.
     */
    cancel(data) {
      this.dates = null;
      this.cancelled = true;
      data.clickCancel();
    },
    /**
     * Emit dates values.
     */
    applyDates() {
      const dates = [];
      if (this.dates.startDate) {
        dates.push(moment.parseZone(this.dates.startDate).format('YYYY-MM-DD'));
        if (this.dates.endDate) {
          dates.push(moment.parseZone(this.dates.endDate).format('YYYY-MM-DD'));
        }
      }
      this.$emit('input', dates);
      this.$emit('change');
      this.dates = null;
    },
    apply(data) {
      this.applied = true;
      data.clickApply();
    },
    /**
     * Calls when datepicker opens/closes.
     */
    toggle(isOpened) {
      if (isOpened) {
        this.applied = false;
        this.cancelled = false;
      } else if (!this.applied && this.dates) {
        this.applyDates();
      }
      if (!isOpened) {
        this.$emit('closed', this.applied, this.cancelled);
      }
    },
  },
};
</script>

<style lang="scss" module>
.host {
  display: block !important;

  div[class="ranges"] {
    width: 120px;

    .rangeList {
      width: 100%;

      .active {
        background-color: $primary-red;
        color: $white;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    border-top: thin solid #eee;
    padding: 5px;
  }
}
</style>

<style lang="scss">
div.calendar-table th {
  background: none !important;
  box-shadow: none !important;
}

.vue-daterange-picker {
  .daterangepicker {
    top: auto;

    td {
      &.in-range {
        background-color: lighten($primary-red, 35%);
      }

      &.active {
        color: $white;
        background-color: $primary-red;
      }
    }

    &.single.show-ranges {
      flex-wrap: nowrap;
      min-width: 400px;
    }
  }

  .calendar-time {
    .form-control,
    .ampmselect {
      appearance: auto;
      border-radius: 5px;
      border: none;
      border-bottom: thin solid #eee;
    }
  }
}
</style>
