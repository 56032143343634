import api from './api';

export default {
  /**
   * Fetch list for table.
   *
   * @param {String} type - Type of export "billing" or "dhs"
   * @param {Number} limit - Return total of records
   *
   * @return Promise<Object>
   */
  fetchExportLog(type, limit) {
    return api()
      .get(`/client/export-log/${type}`, {
        params: {
          per_page: limit,
        },
      })
      .then(response => response.data);
  },
  /**
   * Fetch totals of incidents.
   *
   * @param {String} type - Type of export "billing" or "dhs"
   *
   * @return Promise<Object>
   */
  runManually(type) {
    return api()
      .post(`/client/export-log/${type}/run`)
      .then(response => response.data);
  },

  /**
   * Fetch totals of incidents.
   *
   * @param {String} type - Type of export "billing" or "dhs"
   *
   * @return Promise<Object>
   */
  fetchExportLogTotals(type) {
    return api()
      .get(`/client/export-log/${type}/totals`)
      .then(response => response.data);
  },

  /**
   * Fetch totals not sent incidents to billing.
   *
   * @return Promise<Object>
   */
  fetchBillingNotSentTotals() {
    return api()
      .get('/client/export-log/billing/totals-not-sent')
      .then(response => response.data);
  },

  /**
  * Fetch list of table.
  *
  * @param {Number} logId - Log identifier
  *
  * @return Promise<Object>
  */
  fetchExportLogData(logId) {
    return api()
      .get(`/client/export-log/${logId}`, {
        params: {
          include: 'exportIncidents',
        },
      })
      .then(response => response.data);
  },
};
