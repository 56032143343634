export default [
  { text: 'OFF', value: null },
  { text: '2 min', value: 2 },
  { text: '3 min', value: 3 },
  { text: '4 min', value: 4 },
  { text: '5 min', value: 5 },
  { text: '10 min', value: 10 },
  { text: '15 min', value: 15 },
  { text: '20 min', value: 20 },
  { text: '25 min', value: 25 },
  { text: '30 min', value: 30 },
  { text: '35 min', value: 35 },
  { text: '40 min', value: 40 },
  { text: '45 min', value: 45 },
  { text: '50 min', value: 50 },
  { text: '55 min', value: 55 },
  { text: '1 hour', value: 60 },
  { text: '2 hour', value: 120 },
  { text: '3 hour', value: 180 },
  { text: '6 hour', value: 360 },
  { text: '12 hour', value: 720 },
  { text: '24 hour', value: 1440 },
];
