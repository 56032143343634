<template>
  <ce-entry-card
    :loading="loading"
    :data-loaded="dataLoaded"
    :color-number="monthsLeft === 0 && !completed ? 3 : 2"
    :width="width"
  >
    <template>
      <v-row class="pl-4">
        <v-col class="pb-0">
          <span :class="[$style.cardTitle]">
            Licensing Period
          </span>
        </v-col>
      </v-row>
      <v-row class="pl-4 mt-0">
        <v-col class="pt-0">
          <span
            v-if="periodFrom && periodTo"
            :class="[$style.period]"
          >
            {{ periodFrom | dateFormat }} — {{ periodTo | dateFormat }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-0 pl-4">
        <v-col
            class="pt-0 pb-0"
        >
          <span
            v-if="monthsLeft !== '' && monthsLeft !== null"
            :class="[$style.monthsLeft]"
          >
            {{ monthsLeft }} months left
          </span>
        </v-col>
      </v-row>
    </template>
  </ce-entry-card>
</template>
<script>
import CeEntryCard from './CeEntryCard';

export default {
  name: 'LicensingPeriodCard',
  components: { CeEntryCard },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    periodFrom: {
      type: String,
      default: null,
    },
    periodTo: {
      type: String,
      default: null,
    },
    monthsLeft: {
      type: Number,
      default: null,
    },
    completed: {
      type: Boolean,
      default: null,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" module>
.cardTitle {
  font-weight: bolder;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 22px;
}
.period {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 18px;
}
.monthsLeft {
  font-weight: bold;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 18px;
}
</style>
