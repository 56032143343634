<template>
  <div>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>Set a New Password</v-toolbar-title>
    </v-toolbar>
    <div v-if="tokenIsValid">
      <v-card-text>
        <v-form
          v-if="!passwordIsChanged"
          @keyup.native.enter="changePassword"
        >
          <v-alert
            :value="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
          >
            {{ errorMessage }}
          </v-alert>
          <v-text-field
            ref="password"
            v-model="password"
            v-validate="{ required: true, regex: validatePasswordRegexp }"
            :error-messages="errors.collect('password')"
            prepend-icon="lock"
            name="password"
            label="Password"
            type="password"
          />
          <v-text-field
            v-model="confirmPassword"
            v-validate="'required|confirmed:password'"
            :error-messages="errors.collect('confirmPassword')"
            prepend-icon="lock"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
          />
          <i>{{ $t('general.passwordFormat') }}</i>
        </v-form>
        <span v-if="passwordIsChanged">Your password has been changed</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <router-link
          :to="'/login'"
          class="mr-3"
        >Login</router-link>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="changePassword"
        >
          Change Password
        </v-btn>
      </v-card-actions>
    </div>

    <div v-if="tokenIsValid === false">
      <v-layout
        align-center
        justify-center
        class="pt-5 pb-5"
      >
        Link is outdated or does not exist!
      </v-layout>
    </div>

    <div v-if="!tokenChecked">
      <v-layout
        align-center
        justify-center
        class="pt-5 pb-5"
      >
        <v-progress-circular
          :width="3"
          :size="50"
          indeterminate
          color="primary"
        />
      </v-layout>
    </div>
  </div>
</template>

<script>
import { checkToken, changePassword } from '@/api/password';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';

export default {
  name: 'Form',
  mixins: [errorAlertMixins],
  data() {
    return {
      tokenChecked: false,
      tokenIsValid: null,
      passwordIsChanged: false,
      loading: false,
      validatePasswordRegexp,
      password: null,
      confirmPassword: null,
      dictionary: {
        attributes: {
          password: 'Password',
          confirmPassword: 'Confirm Password',
        },
      },
    };
  },
  created() {
    this.checkTokenRequest();
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    changePassword() {
      this.errors.clear();
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        this.loading = true;
        this.hideError();
        changePassword(this.$route.params.token, this.password)
          .then(() => {
            this.passwordIsChanged = true;
            setTimeout(() => {
              this.$router.push('/login');
            }, 3000);
          })
          .catch(error => {
            this.loading = false;
            this.parseErrorResponse(error.response);
          });
      });
    },
    checkTokenRequest() {
      checkToken(this.$route.params.token)
        .then(() => {
          this.tokenChecked = true;
          this.tokenIsValid = true;
        })
        .catch(() => {
          this.tokenChecked = true;
          this.tokenIsValid = false;
        });
    },
  },
};
</script>
