<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="max-width: 300px"
  >
    <v-text-field
        v-for="license in settingsData"
        :key="'license_' + license.licenseTypeId"
        v-model="license.clientSkills.skills"
        :rules="numberRules"
        :min="1"
        :max="100"
        :label="license.licenseTypeName"
        type="number"
    />
  </v-form>
</template>
<script>
import _ from 'lodash';
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import ceHoursSettings from '@/api/ceHoursSettings';

export default {
  name: 'LicenseSkills',
  mixins: [componentMixins],
  data() {
    return {
      valid: true,
      settingsData: [],
      settingsDataOriginal: [],
      numberRules: [
        (value) => {
          if (value) {
            const v = parseFloat(value);
            return (Number.isInteger(v)) || 'The value in this field must be an integer.';
          }
          return true;
        },
        (value) => {
          if (value) {
            const v = parseFloat(value);
            return (v > 0 && v <= 100) || 'The value must be greater than 0 and less than 100.';
          }
          return true;
        },
      ],
    };
  },
  computed: {
    licenseSkillsById() {
      const licenseSkills = {};
      this.settingsData.forEach(skill => {
        licenseSkills[skill.licenseTypeId] = skill;
      });
      return licenseSkills;
    },
  },
  mounted() {
    this.loading = false;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        await this.loadSettingsData();
      } finally {
        this.loading = false;
      }
    },
    async loadSettingsData() {
      const licenseSkills = await this.fetchSkills();
      this.settingsDataOriginal = _.cloneDeep(licenseSkills);
      this.settingsData = licenseSkills;
    },
    async fetchSkills() {
      let licenseSkills = await ceHoursSettings.getSkillsPerLicense();
      licenseSkills = licenseSkills.map((item) => {
        const licenseSkillItem = item;
        if (licenseSkillItem.clientSkills) {
          licenseSkillItem.clientSkills.skills = licenseSkillItem.clientSkills.skills.toString();
        } else {
          licenseSkillItem.clientSkills = {
            skills: '',
          };
        }
        return licenseSkillItem;
      });
      return licenseSkills;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;
        await ceHoursSettings.saveSkillsPerLicense(this.settingsData);
        await this.loadSettingsData();
        this.$emit('licenseSkillsUpdated');
        this.edited = false;
      } finally {
        this.saving = false;
      }
    },
    /**
     * Triggers when License Hours updated when skills list already loaded and edited.
     * It needs to sync updated available licenses.
     */
    async updateLicenseHoursHandler() {
      try {
        this.loading = true;
        let licenseSkills = await this.fetchSkills();
        this.settingsDataOriginal = _.cloneDeep(licenseSkills);

        licenseSkills = licenseSkills.map(item => {
          const itemWithChanges = item;
          if (Object.prototype.hasOwnProperty.call(
            this.licenseSkillsById, itemWithChanges.licenseTypeId,
          )) {
            const previousLicense = this.licenseSkillsById[itemWithChanges.licenseTypeId];
            itemWithChanges.clientSkills.skills = previousLicense.clientSkills.skills;
          }
          return itemWithChanges;
        });
        this.settingsData = licenseSkills;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
