<template>
  <div>
    <a
      v-if="showTaskLink"
      :href="issue.link"
      target="_blank"
    >{{ issue.key }}</a>
    {{ (issue.release_notes || issue.summary) | removePrefix}}
  </div>
</template>

<script>
export default {
  name: 'ReleaseNotesIssue',
  filters: {
    removePrefix(summary) {
      let str = summary;
      const prefixes = [
        'Backend - ',
        'DevOps - ',
        'iOS - ',
        'Template - ',
        'Dashboard - ',
      ];
      prefixes.forEach(prefix => {
        if (str.indexOf(prefix) === 0) {
          str = str.slice(prefix.length);
        }
      });
      return str;
    },
  },
  props: {
    issue: {
      type: Object,
      required: true,
    },
    showTaskLink: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
