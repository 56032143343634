export default {
  header: 'Reference Materials',
  search: 'Search',
  tableHeaders: {
    name: 'Name',
    updated_at: 'Last Modified',
    category: 'Category',
    created_at: 'Created',
  },
  buttons: {
    edit: 'edit',
    delete: 'delete',
    close: 'Close',
    save: 'Save',
    cancel: 'cancel',
    export: 'export',
  },
  tabs: {
    documents: 'Documents',
    countyParamedic: 'La County Paramedic',
    categories: 'Categories',
  },
  editPopup: {
    new: 'Add Document',
    edit: 'Edit Document',
    name: 'Name',
    subtitle: 'Sub Title',
    comment: 'Comment',
    category: 'Category',
    cancel: 'Cancel',
    save: 'Save',
  },
  deletePopup: {
    title: 'Delete {type}',
    body: 'Do you really want to delete {type}: {name}?',
  },
  categories: {
    selectPlaceholder: 'Category...',
    editPopup: {
      new: 'Add Category',
      edit: 'Edit Category',
      name: 'Name',
      cancel: 'Cancel',
      save: 'Save',
    },
  },
  errors: {
    categoryId: 'Need to choose category.',
    file: 'Need to upload file',
  },
};
