<template>
  <div class="categories">
    <v-expansion-panels
      :class="$style.categoriesTab"
      flat
    >
      <v-expansion-panel
        v-for="(item, index) in items"
        :key="index"
        :readonly="!hasChildren(item)"
      >
        <v-expansion-panel-header :class="{[$style.withoutChildren]: !hasChildren(item)}">
          <div :class="$style.rowContent">
            {{ item.name }}
            <div :class="$style.icons">
              <v-icon
                :class="$style.icon"
                color="grey darken-2"
                @click.stop="$emit('edit', item)"
              >
                edit
              </v-icon>
              <v-icon
                :class="$style.icon"
                color="grey darken-2"
                @click.stop="$emit('delete', item)"
              >
                delete
              </v-icon>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <categories-tab
            v-if="hasChildren(item)"
            :items="item.children"
            @edit="$emit('edit', $event)"
            @delete="$emit('delete', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'CategoriesTab',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /**
     * Has current item the subcategories.
     * @param item
     * @returns {*}
     */
    hasChildren(item) {
      return item.children && item.children.length;
    },
  },
};
</script>

<style lang="scss">
.categories {
  div.v-expansion-panel.v-item--active {
    background-color: #f3f3ee !important;
  }
  div.v-expansion-panel.v-item--active .v-expansion-panel {
    background-color: transparent !important;
  }
  div.v-expansion-panel-content__wrap {
    padding: 0;
    padding-left: 24px;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    margin: 0;
  }
  .v-expansion-panel-header:hover,
  .v-expansion-panel-header:hover * {
    background-color: rgb(224, 224, 224);
  }
  .v-icon {
    transition-property: transform;
  }
  .categories-tab {
    min-height: 650px;
  }
}
</style>

<style lang="scss" module>
.rowContent {
  display: flex;
  justify-content: space-between;
  padding-left: 34px;
  align-items: center;
}

.icons {
  flex: none;
}

.icon {
  margin-left: 15px;
  opacity: 0.6;
  transition-property: transform, opacity;
}

.icon:hover {
  opacity: 1;
}

.withoutChildren div span {
  display: none !important;
}

.categoriesTab {
  box-shadow: none;

  li {
    border-top: none !important;

    & > div:last-of-type {
      padding-left: 24px !important;
    }
  }
}
</style>
