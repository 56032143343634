import api from './api';

export default {
  /**
   * Get client impersonate url.
   *
   * @param {number} clientID - Identifier of client
   *
   * @return Promise<Object>
   */
  getImpersonateUrl(clientID) {
    return api()
      .get(`/admin/clients/${clientID}/impersonate`)
      .then(response => response.data);
  },

  /**
   * Returns all clients list.
   *
   * @param {Object} params - List of params for request.
   *
   * @return Promise<Array>
   */
  fetchList(params) {
    return api()
      .get('/admin/clients', { params })
      .then(response => response.data);
  },

  /**
   * Returns client's active devices.
   *
   * @param {int} clientId - Identifier of client.
   *
   * @return Promise<Array>
   */
  fetchClientDevices(clientId) {
    return api()
      .get(`/admin/clients/${clientId}/active-devices`)
      .then(response => response.data);
  },

  /**
   * Delete client.
   *
   * @param {number} clientId - Identifier of client.
   */
  deleteClient(clientId) {
    return api()
      .delete(`/admin/clients/${clientId}`)
      .then(response => response.data);
  },

  /**
   * Restore client.
   *
   * @param {number} clientId - Identifier of client.
   */
  restoreClient(clientId) {
    return api()
      .post(`/admin/clients/${clientId}/restore`)
      .then(response => response.data);
  },

  /**
   * Create new client.
   *
   * @param {Object} client - Client object
   */
  createClient(client) {
    return api()
      .post('/admin/clients', client)
      .then(response => response.data);
  },

  /**
   * Update client.
   *
   * @param {Object} client - Client object to save
   */
  saveClient(client) {
    return api()
      .put(`/admin/clients/${client.registryId}`, client)
      .then(response => response.data);
  },
};
