<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span>Export error explanation</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          {{ explanation }}
          <br />
          <br />
          <template v-if="response">
            <b>Response:</b>
            <br />
            <pre style="white-space: pre-line">{{ response }}</pre>
          </template>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'ErrorExplanationPopup',
  data() {
    return {
      dialog: false,
      explanation: null,
      response: null,
    };
  },

  methods: {
    /**
     * Show explanation popup.
     */
    async show(explanation, response) {
      this.explanation = explanation;
      this.response = response;
      this.dialog = true;
    },
  },
};
</script>
