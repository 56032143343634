<template>
  <v-dialog
    v-model="showConfirmPopup"
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">Update Entries?</v-card-title>
      <v-card-text>
        The database contains records with provider number "<b>{{ oldNumber }}</b>",
        do you want to update their value to "<b>{{ newNumber }}</b>"?
      </v-card-text>
      <slot/>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click.native="showConfirmPopup=false;"
        >{{ $t('buttons.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn
          :disabled="dontUpdating"
          :loading="updating"
          color="primary"
          text
          @click.native="updateEntries()"
        >Update Entries</v-btn>
        <v-btn
            :disabled="updating"
            :loading="dontUpdating"
            color="primary"
            text
            @click.native="dontUpdateEntries()"
        >Don't Update Entries</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateEntriesConfirmation',
  props: {
    oldNumber: {
      type: String,
      required: false,
      default: '',
    },
    newNumber: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      updating: false,
      dontUpdating: false,
      showConfirmPopup: false,
      updateCallback: null,
      dontUpdateCallback: null,
    };
  },
  methods: {
    /**
     * Show confirmation popup
     *
     * @param {function} updateCallback - Callback when clicked Update button.
     * @param {function} dontUpdateCallback - Callback when clicked Don't update button.
     */
    showConfirm(updateCallback, dontUpdateCallback) {
      this.showConfirmPopup = true;
      this.updateCallback = updateCallback;
      this.dontUpdateCallback = dontUpdateCallback;
    },

    /**
     * Save with update entries.
     */
    async updateEntries() {
      this.updating = true;
      try {
        await this.updateCallback();
        this.showConfirmPopup = false;
      } finally {
        this.updating = false;
      }
    },

    /**
     * Save with don't update entries
     */
    async dontUpdateEntries() {
      this.dontUpdating = true;
      try {
        await this.dontUpdateCallback();
        this.showConfirmPopup = false;
      } finally {
        this.dontUpdating = false;
      }
    },
  },
};
</script>
