<template>
  <div>
    <v-text-field
        v-model="timeValue"
        prepend-inner-icon="mdi-clock-time-four-outline"
        label="Time"
        readonly
        clearable
        hide-details
        outlined
        dense
        @click="openTimePicker"
    />
    <datetime
        ref="time"
        v-model="timeForPicker"
        type="time"
        input-class="d-none"
        class="theme-red"
        use12-hour
    />
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime';
import moment from 'moment';

export default {
  name: 'Time',
  components: {
    Datetime,
  },

  props: {
    value: {
      type: String,
    },
  },

  data() {
    return {
      menu: false,
      date: null,
      time: null,
    };
  },

  computed: {
    /**
     * Split time from datetime value.
     *
     * @return {string}
     */
    splitTime() {
      if (!this.value) {
        return null;
      }
      const parts = this.value.split('T');
      if (parts.length === 2) {
        return parts[1];
      }
      return this.value.includes(':') ? this.value : null;
    },

    timeForPicker: {
      get() {
        return this.splitTime;
      },
      set(value) {
        let time = null;

        if (value) {
          time = moment(value).format('HH:mm:ss');
        }

        this.$emit('input', time);
      },
    },
    timeValue: {
      get() {
        return this.splitTime
          ? moment.parseZone(`${moment().format('YYYY-MM-DD')}T${this.splitTime}`).format('hh:mm A')
          : null;
      },
      set(value) {
        if (!value) {
          this.timeForPicker = null;
        }
      },
    },
  },
  methods: {
    /**
     * Open timepicker
     *
     * @param {Object} event
     */
    openTimePicker(event) {
      this.$refs.time.open(event);
    },
  },
};
</script>
