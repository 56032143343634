import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    pagination: {
      sortDesc: [true],
      page: 1,
      sortBy: ['firstName'],
      itemsPerPage: 25,
      totalItems: 0,
    },
    totalItems: 0,
    filters: {
      search: null,
      status: 'active',
    },
    licenseTypes: [],
  },
  actions,
  mutations,
  getters: {},
};
