import api from './api';

export default {
  /**
   * Fetch list of monitors.
   *
   * @return Promise<Object>
   */
  getMonitors() {
    return api()
      .get('/monitors')
      .then(response => response.data);
  },

  /**
   * Create new monitor.
   *
   * @param {Object} monitor - Object of monitor to create
   *
   * @return Promise<Object>
   */
  create(monitor) {
    return api()
      .post('/monitors', monitor)
      .then(response => response.data);
  },

  /**
   * Save exising monitor.
   *
   * @param {Number} id - Monitor identifier
   * @param {Object} monitor - Object of monitor to create
   *
   * @return Promise<Object>
   */
  save(id, monitor) {
    return api()
      .put(`/monitors/${id}`, monitor)
      .then(response => response.data);
  },

  /**
   * Delete exising monitor.
   *
   * @param {Number} id - Monitor identifier
   *
   * @return Promise<Object>
   */
  delete(id) {
    return api()
      .delete(`/monitors/${id}`)
      .then(response => response.data);
  },
};
