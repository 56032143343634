const USER_ROLES = {
  // Physical roles:
  SUPER_ADMIN: 'su',
  CLIENT_ADMIN: 'ca',
  CLIENT_CAPTAIN_USER: 'ccu',
  CLIENT_FIREFIGHTER_USER: 'cfu',
  CLIENT_QA_USER: 'cqa',
  CLIENT_CE_USER: 'ceu',
  HOSPITAL_ADMIN: 'ha',
  HOSPITAL_USER: 'hu',

  // Computed roles:
  CLIENT_ADMIN_IMPERSONATED: 'ca_impersonated',
  HOSPITAL_ADMIN_IMPERSONATED: 'ha_impersonated',
  PUBLIC: 'public',
};

export default USER_ROLES;
