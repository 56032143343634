import api from './api';

export default {
  /**
   * Get XSD Versions
   */
  fetchXSDVersions() {
    return api()
      .get('/xsd/versions')
      .then(response => response.data);
  },
};
