<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    fixed-header
    class="header-nowrap"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <NoDataAvailable
          :headers="headers"
          :items="items"
        />
        <v-hover
          v-for="item in items"
          :key="item.id"
        >
          <tr :class="[(noMatchedIncident(item) ? $style.deleted : null)]">
            <td>
              <a
                 v-if="item.incident"
                 @click="goToIncident(item)"
              >
                {{ item.sequenceNumber }}
              </a>
              <span v-else>{{ item.sequenceNumber }}</span>
            </td>
            <td>{{ item.incidentNumber }}</td>
            <td>{{ item.incidentDateTime | datetimeToClientTimezone }}</td>
            <td>{{ item.status }}</td>
            <td>
              {{ item.exportedAt | datetimeToClientTimezone }}
            </td>
            <td>
              <span v-if="noMatchedIncident(item)">
                {{ $t('externalServiceExports.noMatchedIncident') }}
                (<a
                  href="https://odmap.hidta.org"
                  target="_blank"
                >https://odmap.hidta.org</a>,
                use CaseNumber: {{ item.incidentNumber }})
              </span>
              <span v-else>{{ item.error }}</span>
            </td>
          </tr>
        </v-hover>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import impersonateMixins from '@/mixins/impersonateMixins';
import fixedHeader from '@/mixins/fixedHeader';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'OdExportTable',

  components: {
    NoDataAvailable,
  },

  mixins: [impersonateMixins, fixedHeader],

  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useImpersonate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('externalServiceExports.tableHeaders.sequenceNumber'),
          value: 'sequenceNumber',
        },
        {
          text: this.$t('externalServiceExports.tableHeaders.incidentNumber'),
          value: 'incidentNumber',
        },
        {
          text: this.$t('externalServiceExports.tableHeaders.incidentDateTime'),
          value: 'incidentDateTime',
        },
        {
          text: this.$t('externalServiceExports.tableHeaders.status'),
          value: 'status',
        },
        {
          text: this.$t('externalServiceExports.tableHeaders.exportedAt'),
          value: 'exportedAt',
        },
        {
          text: this.$t('externalServiceExports.tableHeaders.error'),
          value: 'error',
        },
      ],
    };
  },

  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    /**
     * Check if incident already is not exists or not match for export criteria.
     *
     * @param {Object} odMapExport - Od Map Export object.
     *
     * @return {Boolean}
     */
    noMatchedIncident(odMapExport) {
      return (
        !odMapExport.incident || odMapExport.incident.PA_DrugsSuspected !== 'Y'
      );
    },

    /**
     * Impersonate user to client and go to incident view page.
     *
     * @param {Object} odMapExport - Od Map Export object.
     */
    goToIncident(odMapExport) {
      if (this.useImpersonate) {
        this.impersonateToIncident(
          odMapExport.clientId,
          odMapExport.incident.id,
        );
      } else {
        const url = `/incident/view?ID=${odMapExport.incident.id}`;
        const win = window.open(url, '_blank');
        win.focus();
      }
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}

.deleted {
  background-color: $red-light;
}
</style>
