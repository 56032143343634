<template>
  <v-card
    elevation="3"
    class="ma-4 flagged-users"
  >
    <clients-toolbar
      :local-filters.sync="localFilters"
      :filters="filters"
      @filtersUpdate="filtersUpdate"
      @addClient="showAddClientPopup"
    ></clients-toolbar>
    <clients-table
      :items="items"
      :loading="loading"
      @clickEdit="showEditPopup"
      @clickDelete="showDelete"
      @clickRestore="showRestore"
    ></clients-table>
    <clients-edit-popup
      ref="editPopup"
      @clickDelete="showDelete"
      @saved="reloadItems"
    ></clients-edit-popup>
    <confirmation
      ref="confirmationPopup"
      @confirmed="reloadItems"
    ></confirmation>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import apiClients from '@/api/clients';
import { SET_TITLE } from '@/store/mutations';
import { LOAD_CLIENTS } from '@/store/Clients/actions';
import { SET_CLIENTS_FILTERS } from '@/store/Clients/mutations';
import Confirmation from '@/components/Confirmation';
import ClientsTable from '@/components/Clients/ClientsTable';
import ClientsToolbar from '@/components/Clients/ClientsToolbar';
import ClientsEditPopup from '@/components/Clients/ClientsEditPopup';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('clients');

export default {
  components: {
    ClientsTable,
    ClientsToolbar,
    ClientsEditPopup,
    Confirmation,
  },

  data() {
    return {
      loading: false,
      localFilters: {
        search: '',
        type: null,
        mapTypeId: null,
      },
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),

    /**
     * Filtered clients.
     *
     * @return {Array}
     */
    items() {
      const { localFilters } = this;
      let filteredItems = this.entity.items;

      if (localFilters.search) {
        filteredItems = _.pickBy(
          filteredItems,
          item => item.name
            .toLowerCase()
            .indexOf(localFilters.search.toLowerCase()) !== -1
            || localFilters.search.toLowerCase() === item.domain.toLowerCase()
            || item.registryId === parseInt(localFilters.search, 10),
        );
      }

      if (localFilters.type) {
        filteredItems = _.pickBy(
          filteredItems,
          item => item.type === localFilters.type,
        );
      }

      if (localFilters.mapTypeId) {
        filteredItems = _.pickBy(
          filteredItems,
          item => item.mapTypeId === localFilters.mapTypeId,
        );
      }
      return _.values(filteredItems);
    },

    filters() {
      return this.entity.filters;
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('clients.header'));
    this.reloadItems();
  },

  methods: {
    ...mapMutations({
      setFilters: SET_CLIENTS_FILTERS,
    }),
    ...mapActions({
      loadItems: LOAD_CLIENTS,
    }),

    /**
     * Handler when filter is updated.
     *
     * @param {Object} filters - Filters object.
     */
    filtersUpdate(filters) {
      this.setFilters(filters);
      this.reloadItems();
    },

    /**
     * Reloads clients list for current filter.
     */
    async reloadItems() {
      this.loading = true;

      try {
        await this.loadItems();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Show edit client popup.
     *
     * @param {Object} client - Client object.
     */
    showEditPopup(client) {
      this.$refs.editPopup.showEdit(_.clone(client));
    },

    /**
     * Show add client popup.
     */
    showAddClientPopup() {
      this.$refs.editPopup.showNew();
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} client - Client object.
     */
    showDelete(client) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('clients.deleteConfirmationTitle'),
        this.$t('clients.deleteConfirmationText', client),
        async () => {
          await apiClients.deleteClient(client.registryId);
          this.$refs.editPopup.hidePopup();
          this.reloadItems();
        },
      );
    },

    /**
     * Show restore confirmation popup.
     *
     * @param {Object} client - Client object.
     */
    showRestore(client) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('clients.restoreConfirmationTitle'),
        this.$t('clients.restoreConfirmationText', client),
        async () => {
          await apiClients.restoreClient(client.registryId);
          this.reloadItems();
        },
      );
    },
  },
};
</script>
