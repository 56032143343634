export const SET_ITEMS = 'SET_ITEMS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';

export default {
  /**
   * Save devices data.
   *
   * @param {Object} state - Devices state.
   * @param {{ items: Array, totalItems: number }} data - Devices data to set
   */
  [SET_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.totalItems = totalItems;
  },

  /**
   * Save devices pagination.
   *
   * @param {Object} state - Devices state.
   * @param {Object} pagination - Devices pagination.
   */
  [SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters of devices.
   *
   * @param {Object} state - Devices state.
   * @param {Object} filters - Filters to set.
   */
  [SET_FILTERS]: (state, filters) => {
    state.filters = filters;
  },
};
