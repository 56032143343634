import api from './api';

export default {
  /**
  * Fetch user data
  *
  * @return Promise<Object>
  */
  fetchUser() {
    return api()
      .get('/profile')
      .then(response => response.data);
  },

  /**
   * Update user profile.
   *
   * @param {Object} user - User object to save
   */
  saveProfile(user) {
    return api()
      .put('/profile', user)
      .then(response => response.data);
  },

  /**
   * Set new phone request.
   *
   * @param {Object} data - Data to update phone request
   */
  newPhoneRequest(data) {
    return api()
      .post('/profile/phone', data)
      .then(response => response.data);
  },

  /**
   * Verify sms code and update phone in profile.
   *
   * @param {Object} data - Data to update phone request
   */
  verifySmsCode(data) {
    return api()
      .post('/profile/phone/verify', data)
      .then(response => response.data);
  },

  /**
   * Send verification code.
   *
   * @param {String} token - Token to resend sms code
   */
  sendCode(token) {
    return api()
      .post('/profile/phone/send-code', { token })
      .then(response => response.data);
  },

  /**
   * Set new email request.
   *
   * @param {Object} data - Data to update phone request
   */
  newEmailRequest(data) {
    return api()
      .post('/profile/email', data)
      .then(response => response.data);
  },

  /**
   * Verify sms code and update phone in profile.
   *
   * @param {Object} data - Data to update phone request
   */
  verifyEmailCode(data) {
    return api()
      .post('/profile/email/verify', data)
      .then(response => response.data);
  },
};
