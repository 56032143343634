import api from './api';

export default {
  /**
  * Fetch reconciliation data.
  *
  * @param {String} type - Type of reconciliation data
  * @param {Object} params - Request params
  *
  * @return Promise<Object>
  */
  fetchData(type, params) {
    return api()
      .get(`/reconciliation/${type}`, { params })
      .then(response => response.data);
  },

  /**
   * Get reconciliation file.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  getFile(params) {
    return api()
      .get('/reconciliation/file', { params, responseType: 'blob' });
  },

  /**
   * Get reconciliation file.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  send(params) {
    return api()
      .post('/reconciliation/send', params);
  },

  /**
   * Get list of available data types.
   *
   * @return Promise<Object>
   */
  getAvailableDataTypes() {
    return api()
      .get('/reconciliation/data-types')
      .then(response => response.data);
  },

  /**
   * Fetch Reconciliation export settings.
   *
   * @return Promise<Object>
   */
  getSettings() {
    return api()
      .get('/reconciliation/settings')
      .then(response => response.data);
  },

  /**
   * Save Reconciliation export settings.
   *
   * @param {Object} settings - Settings data
   *
   * @return Promise<Object>
   */
  saveSettings(settings) {
    return api()
      .post('/reconciliation/settings', settings)
      .then(response => response.data);
  },

  /**
   * Delete Reconciliation export settings.
   *
   * @return Promise<Object>
   */
  deleteSettings() {
    return api()
      .delete('/reconciliation/settings')
      .then(response => response.data);
  },

  /**
   * Fetch Reconciliation export status.
   *
   * @return Promise<Object>
   */
  getStatus() {
    return api()
      .get('/reconciliation/status')
      .then(response => response.data);
  },

  /**
   * Retry failed export.
   *
   * @return Promise<Object>
   */
  retryExport() {
    return api()
      .post('/reconciliation/retry')
      .then(response => response.data);
  },
};
