<template>
  <div>
    <v-row>
      <v-col md="3">
        <total-card
            :color-number="1"
            :total="totalData ? totalData.total : 0"
            :data="totalData ? totalData.ranges : []"
            :loading="totalLoading"
            title="Incidents total"
        />
      </v-col>
      <v-col md="3">
        <total-card
            :color-number="2"
            :total="thisMonthData ? thisMonthData.total : 0"
            :data="thisMonthData ? thisMonthData.ranges : []"
            :loading="thisMonthLoading"
            title="This month"
        />
      </v-col>
      <v-col md="3">
        <total-card
            :color-number="3"
            :total="lastMonthData ? lastMonthData.total : 0"
            :data="lastMonthData ? lastMonthData.ranges : []"
            :loading="lastMonthLoading"
            title="Last month"
        />
      </v-col>
      <v-col md="3">
        <total-card
            :color-number="4"
            :total="yearToDateData ? yearToDateData.total : 0"
            :data="yearToDateData ? yearToDateData.ranges : []"
            :loading="yearToDateLoading"
            title="Year to date"
        />
      </v-col>
    </v-row>

    <toolbar
        :selected-metric.sync="selectedMetric"
        :date-range="dateRange"
        :other-metrics.sync="otherMetrics"
        :transported-filter.sync="transportedFilter"
        @updateDateRange="updateDateRange"
    />
    <div v-if="otherMetrics">
      <v-container>
        <v-row>
          <v-col>
            <div v-if="Object.prototype.hasOwnProperty.call(metricComponentMap, selectedMetric)">
              <component
                  v-bind:is="metricComponentMap[selectedMetric]"
                  :title="metricNames[selectedMetric]"
                  :data="filteredOtherMetricsData"
                  :loading="otherMetricLoading"
                  :height="metricHeight"
                  :date-range="dateRange"
                  :metric-type="selectedMetric"
                  :measurement="Object.prototype.hasOwnProperty.call(measurementMap, selectedMetric)
                    ? measurementMap[selectedMetric]
                    : null
                  "
              ></component>
            </div>
            <div v-else>
              is not implemented yet.
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-else>
      <v-row>
        <v-col md="6">
          <simple-pie
              title="Transport Via"
              :data="transportViaData || []"
              :loading="transportViaLoading"
          />
        </v-col>
        <v-col md="6">
          <simple-donut
              title="Transport Reason"
              :data="transportReasonData || []"
              :loading="transportReasonLoading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <stacked-columns
              title="Average Age of Patient"
              :data="averageAgeData || {dates: [], series: []}"
              :loading="averageAgeLoading"
          />
        </v-col>
        <v-col md="6">
          <column-with-data-labels
              title="Total Patients by Provider Impression"
              :data="providerImpressionData || []"
              :loading="providerImpressionLoading"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import METRICS from '@/enums/metrics';
import METRIC_NAMES from '@/enums/metricNames';
import TotalCard from '@/components/ClientDashboard/TotalCard';
import metrics from '@/api/metrics';
import StackedColumns from '@/components/ClientDashboard/Metrics/StackedColumns';
import Toolbar from '@/components/ClientDashboard/Toolbar';
import ColumnWithDataLabels from '@/components/ClientDashboard/Metrics/ColumnWithDataLabels';
import SimplePie from '@/components/ClientDashboard/Metrics/SimplePie';
import SimpleDonut from '@/components/ClientDashboard/Metrics/SimpleDonut';
import DistributedColumns from '@/components/ClientDashboard/Metrics/DistributedColumns';
import MultiDimensionalTreemap from '@/components/ClientDashboard/Metrics/MultiDimensionalTreemap';
import GoogleMap from '@/components/ClientDashboard/Metrics/GoogleMap';
import ApotFirst from '@/components/ClientDashboard/Metrics/ApotFirst';
import ApotSecond from '@/components/ClientDashboard/Metrics/ApotSecond';
import ApotSecondByUnits from '@/components/ClientDashboard/Metrics/ApotSecondByUnits';
import ApotDelayExceeding from '@/components/ClientDashboard/Metrics/ApotDelayExceeding';

export default {
  name: 'DashboardClient',

  components: {
    DistributedColumns,
    SimpleDonut,
    SimplePie,
    ColumnWithDataLabels,
    Toolbar,
    StackedColumns,
    TotalCard,
    GoogleMap,
    MultiDimensionalTreemap,
    ApotFirst,
    ApotSecond,
    ApotSecondByUnits,
    ApotDelayExceeding,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      indentHeight: 330,

      transportedFilter: {
        transported: true,
        notTransported: true,
      },

      metricNames: METRIC_NAMES,

      dateRange: null,
      otherMetrics: false,
      selectedMetric: METRICS.RECEIVING_FACILITY,

      totalData: null,
      thisMonthData: null,
      lastMonthData: null,
      yearToDateData: null,
      averageAgeData: null,
      transportViaData: null,
      transportReasonData: null,
      providerImpressionData: null,
      otherMetricData: null,

      totalLoading: true,
      thisMonthLoading: true,
      lastMonthLoading: true,
      yearToDateLoading: true,
      transportViaLoading: true,
      transportReasonLoading: true,
      averageAgeLoading: true,
      providerImpressionLoading: true,
      otherMetricLoading: true,

      metricComponentMap: this.getMetricComponentMap(),
      measurementMap: this.getMeasurementMap(),
    };
  },
  computed: {
    /**
     * Calculates height of metrics.
     *
     * @return {Number}
     */
    metricHeight() {
      const height = this.windowHeight - this.indentHeight;
      return height >= 400 ? height : 400;
    },

    filteredOtherMetricsData() {
      let data = null;
      if (this.metricComponentMap[this.selectedMetric] === 'StackedColumns') {
        data = this.otherMetricData || { dates: [], series: [] };
      } else {
        data = this.otherMetricData || [];
      }

      if (this.selectedMetric === METRICS.COVID_INCIDENTS_BY_LOCATION) {
        data = data.filter(item => (this.transportedFilter.transported && item.TI_ReceivingFacility)
              || (this.transportedFilter.notTransported && !item.TI_ReceivingFacility));
      }

      if (this.selectedMetric === METRICS.APOT_2) {
        data = this.otherMetricData || { total: 0, series: [] };
      }

      if (this.metricComponentMap[this.selectedMetric] === 'ApotDelayExceeding') {
        data = this.otherMetricData || { total: 0, series: [], minutes: 0 };
      }
      return data;
    },
  },
  watch: {
    otherMetrics(val) {
      if (val) {
        this.otherMetricLoadData();
      } else {
        this.updateMetrics();
      }
    },
    selectedMetric() {
      if (this.otherMetrics) {
        this.otherMetricLoadData();
      }
    },
  },
  created() {
    window.addEventListener('resize', this.recalculateHeights);
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateHeights);
  },
  mounted() {
    this.dateRange = [
      moment().subtract(7, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ];
    this.updateTotals();
    this.updateMetrics();
  },
  methods: {
    /**
     * Recalculate height of window, handled by change page size.
     */
    recalculateHeights() {
      this.windowHeight = window.innerHeight;
    },

    getMetricComponentMap() {
      const map = {};
      map[METRICS.INCIDENTS_BY_LOCATION] = 'GoogleMap';
      map[METRICS.COVID_INCIDENTS_BY_LOCATION] = 'GoogleMap';
      map[METRICS.AVG_TIME_RESPONSE] = 'ColumnWithDataLabels';
      map[METRICS.AVG_TIME_RESPONSE_BY_DISPATCH_ID] = 'StackedColumns';
      map[METRICS.AVG_TIME_RESPONSE_BY_VIA] = 'StackedColumns';
      map[METRICS.AVG_TIME_ON_SCENE] = 'ColumnWithDataLabels';
      map[METRICS.AVG_TIME_BY_RECEIVING_FACILITY] = 'DistributedColumns';
      map[METRICS.AVG_TIME_TOTAL] = 'ColumnWithDataLabels';
      map[METRICS.AVG_TIME_BY_VIA] = 'StackedColumns';
      map[METRICS.AVG_TIME_BY_DISPATCH_ID] = 'StackedColumns';
      map[METRICS.TOTAL_BY_RUN_TYPE] = 'StackedColumns';
      map[METRICS.RECEIVING_FACILITY] = 'ColumnWithDataLabels';
      map[METRICS.AMA] = 'ColumnWithDataLabels';
      map[METRICS.TRANSPORT_VIA] = 'SimplePie';
      map[METRICS.TRANSPORT_REASON] = 'SimpleDonut';
      map[METRICS.TRANSPORTED_TO] = 'SimplePie';
      map[METRICS.PROTOCOL] = 'ColumnWithDataLabels';
      map[METRICS.CONTACT] = 'ColumnWithDataLabels';
      map[METRICS.SEX] = 'SimplePie';
      map[METRICS.AVERAGE_AGE] = 'StackedColumns';
      map[METRICS.AVERAGE_WEIGHT] = 'ColumnWithDataLabels';
      map[METRICS.DISTRESS_LEVEL] = 'ColumnWithDataLabels';
      map[METRICS.COMPLAINT] = 'ColumnWithDataLabels';
      map[METRICS.MECHANISM_OF_INJURY] = 'ColumnWithDataLabels';
      map[METRICS.PROVIDER_IMPRESSION] = 'ColumnWithDataLabels';
      map[METRICS.FALLOUT_CODE] = 'ColumnWithDataLabels';
      map[METRICS.PERCENTAGE_FALLOUT_CODE] = 'ColumnWithDataLabels';
      map[METRICS.SPECIAL_CIRCUMSTANCES] = 'DistributedColumns';
      map[METRICS.PROVIDER_IMPRESSIONS_BY_VIA] = 'MultiDimensionalTreemap';
      map[METRICS.APOT_1] = 'ApotFirst';
      map[METRICS.APOT_1_BY_UNITS] = 'ApotFirst';
      map[METRICS.APOT_2] = 'ApotSecond';
      map[METRICS.APOT_2_BY_UNITS] = 'ApotSecondByUnits';
      map[METRICS.APOT_DELAY_EXCEEDING_60] = 'ApotDelayExceeding';
      map[METRICS.APOT_DELAY_EXCEEDING_30] = 'ApotDelayExceeding';
      map[METRICS.APOT_DELAY_EXCEEDING_20] = 'ApotDelayExceeding';
      map[METRICS.APOT_DELAY_EXCEEDING_10] = 'ApotDelayExceeding';
      return map;
    },
    getMeasurementMap() {
      const map = {};
      map[METRICS.AVG_TIME_BY_VIA] = 'Minutes';
      map[METRICS.AVG_TIME_BY_DISPATCH_ID] = 'Minutes';
      map[METRICS.AVG_TIME_TOTAL] = 'Minutes';
      map[METRICS.AVG_TIME_BY_RECEIVING_FACILITY] = 'Minutes';
      map[METRICS.AVG_TIME_ON_SCENE] = 'Minutes';
      map[METRICS.AVG_TIME_RESPONSE_BY_VIA] = 'Minutes';
      map[METRICS.AVG_TIME_RESPONSE_BY_DISPATCH_ID] = 'Minutes';
      map[METRICS.AVG_TIME_RESPONSE] = 'Minutes';
      return map;
    },

    updateDateRange(dateRange) {
      this.dateRange = dateRange;
      if (this.otherMetrics) {
        this.otherMetricLoadData();
      } else {
        this.updateMetrics();
      }
    },

    updateTotals() {
      this.totalLoadData();
      this.thisMonthLoadData();
      this.lastMonthLoadData();
      this.yearToDateLoadData();
    },

    updateMetrics() {
      this.transportVia();
      this.transportReason();
      this.averageAgeOfPatient();
      this.providerImpression();
    },

    async otherMetricLoadData() {
      this.otherMetricData = null;
      try {
        this.otherMetricLoading = true;
        this.otherMetricData = await metrics.getMetrics(this.getParams(this.selectedMetric));
      } finally {
        this.otherMetricLoading = false;
      }
    },

    async totalLoadData() {
      try {
        this.totalLoading = true;
        this.totalData = await metrics.getTotal();
      } finally {
        this.totalLoading = false;
      }
    },
    async thisMonthLoadData() {
      try {
        this.thisMonthLoading = true;
        this.thisMonthData = await metrics.getThisMonth();
      } finally {
        this.thisMonthLoading = false;
      }
    },
    async lastMonthLoadData() {
      try {
        this.lastMonthLoading = true;
        this.lastMonthData = await metrics.getLastMonth();
      } finally {
        this.lastMonthLoading = false;
      }
    },
    async yearToDateLoadData() {
      try {
        this.yearToDateLoading = true;
        this.yearToDateData = await metrics.getYearToDate();
      } finally {
        this.yearToDateLoading = false;
      }
    },
    async averageAgeOfPatient() {
      try {
        this.averageAgeLoading = true;
        this.averageAgeData = await metrics.getMetrics(this.getParams('averageAge'));
      } finally {
        this.averageAgeLoading = false;
      }
    },
    async transportVia() {
      try {
        this.transportViaLoading = true;
        this.transportViaData = await metrics.getMetrics(this.getParams('transportVia'));
      } finally {
        this.transportViaLoading = false;
      }
    },
    async transportReason() {
      try {
        this.transportReasonLoading = true;
        this.transportReasonData = await metrics.getMetrics(this.getParams('transportReason'));
      } finally {
        this.transportReasonLoading = false;
      }
    },
    async providerImpression() {
      try {
        this.providerImpressionLoading = true;
        this.providerImpressionData = await metrics.getMetrics(this.getParams('providerImpression'));
      } finally {
        this.providerImpressionLoading = false;
      }
    },

    getParams(metric) {
      const params = {
        metric,
        from: null,
        to: null,
      };

      if (this.dateRange) {
        // eslint-disable-next-line prefer-destructuring
        params.from = this.dateRange[0];
        if (this.dateRange.length === 2) {
          // eslint-disable-next-line prefer-destructuring
          params.to = this.dateRange[1];
        }
      }
      return params;
    },
  },
};
</script>
<style lang="scss">
.apexcharts-toolbar {
  z-index: 1 !important;
}
</style>
