<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
      <v-card>
        <v-card-title>Warning</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          It is suggested that you first validate the incident to
          prevent any issues when the data is sent to DHS.
          You can still bypass this validation and continue
          to save the data if you desire.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$emit('startValidation'); hide()"
          >
            Start Validation
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$emit('save'); hide()"
          >
            Save Anyway
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'PreSaveValidationPopup',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
  },
};
</script>
