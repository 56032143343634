<template>
  <v-dialog
      v-model="dialog"
      width="600"
  >
    <v-card>
      <template v-if="type === tabs.ALS">
        <v-card-title class="text-h5 grey">
          ALS based on R units
        </v-card-title>

        <v-card-text>
          This Reconciliation report will show reports, between your selected date range,<br />
          the patient was transported ('<b>LeftSceneDate IS NOT NULL</b>'),<br />
          the Unit starts with an '<b>R</b>' ('<b>LIKE 'R%'</b>'),<br />
          and show unique reports by SequenceNumber.
        </v-card-text>
        <v-card-text>
          <b>SQL view:</b><br />
          <i>
            ImportIncidents.IncidentDate &gt;= '{DateRangeFrom}'<br />
            AND ImportIncidents.IncidentDate &lt;= '{DateRangeTo}'<br />
            AND ImportIncidentUnits.LeftSceneDate <b>IS NOT NULL</b><br />
            AND ImportIncidentUnits.Unit LIKE '<b>R%</b>'
          </i>
        </v-card-text>
      </template>

      <template v-if="type === tabs.ALS_BASED_ON_S">
        <v-card-title class="text-h5 grey">
          ALS based on S units
        </v-card-title>

        <v-card-text>
          This Reconciliation report will show reports, between your selected date range,<br />
          the patient was transported ('<b>LeftSceneDate IS NOT NULL</b>'),<br />
          the Unit starts with an '<b>S</b>' ('<b>LIKE 'S%'</b>'),<br />
          and show unique reports by SequenceNumber.
        </v-card-text>
        <v-card-text>
          <b>SQL view:</b><br />
          <i>
            ImportIncidents.IncidentDate &gt;= '{DateRangeFrom}'<br />
            AND ImportIncidents.IncidentDate &lt;= '{DateRangeTo}'<br />
            AND ImportIncidentUnits.LeftSceneDate <b>IS NOT NULL</b><br />
            AND ImportIncidentUnits.Unit LIKE '<b>S%</b>'
          </i>
        </v-card-text>
      </template>

      <template v-if="type === tabs.BLS">
        <v-card-title class="text-h5 grey">
          Analysis based on BLS units
        </v-card-title>

        <v-card-text>
          This Reconciliation report will show reports, between your selected date range,<br />
          the patient was transported ('<b>LeftSceneDate IS NOT NULL</b>'),<br />
          the Unit starts with an '<b>BLS</b>' ('<b>LIKE 'BLS%'</b>'),<br />
          and show unique reports by SequenceNumber.
        </v-card-text>
        <v-card-text>
          <b>SQL view:</b><br />
          <i>
            ImportIncidents.IncidentDate &gt;= '{DateRangeFrom}'<br />
            AND ImportIncidents.IncidentDate &lt;= '{DateRangeTo}'<br />
            AND ImportIncidentUnits.LeftSceneDate <b>IS NOT NULL</b><br />
            AND ImportIncidentUnits.Unit LIKE '<b>BLS%</b>'
          </i>
        </v-card-text>
      </template>

      <template v-if="type === tabs.FIRST_RESPONDER">
        <v-card-title class="text-h5 grey">
          First Responder
        </v-card-title>

        <v-card-text>
          This Reconciliation report will show reports, between your selected date range,<br />
          the patient wasn't transported ('<b>LeftSceneDate IS NULL</b>'),<br />
          the report has filled patient info (<b>PI_LastName IS NOT NULL</b>),<br />
          and show unique reports by SequenceNumber.
        </v-card-text>
        <v-card-text>
          <b>SQL view:</b><br />
          <i>
            ImportIncidents.IncidentDate &gt;= '{DateRangeFrom}'<br />
            AND ImportIncidents.IncidentDate &lt;= '{DateRangeTo}'<br />
            AND ImportIncidentUnits.LeftSceneDate <b>IS NULL</b><br />
            AND Reports.PI_LastName <b>IS NOT NULL</b><br />
          </i>
        </v-card-text>
      </template>

      <template v-if="type === tabs.ALL_INCLUSIVE">
        <v-card-title class="text-h5 grey">
          All Inclusive
        </v-card-title>

        <v-card-text>
          This Reconciliation report is a common report. <br />
          It will show reports between your selected date range,<br />
          the patient was transported ('<b>LeftSceneDate IS NOT NULL</b>'),<br />
          the Unit starts with <b>R, S or BLS</b>
          ('<b>LIKE 'R%' or LIKE 'S%' or LIKE 'BLS%'</b>').<br /><br />
          And also it will include reports according to First Responder conditions:<br />
          the patient wasn't transported ('<b>LeftSceneDate IS NULL</b>'),<br />
          the report has filled patient info (<b>PI_LastName IS NOT NULL</b>),<br />
          and show unique reports by SequenceNumber.
        </v-card-text>
        <v-card-text>
          <b>SQL view:</b><br />
          <i>
            ImportIncidents.IncidentDate &gt;= '{DateRangeFrom}'<br />
            AND ImportIncidents.IncidentDate &lt;= '{DateRangeTo}'<br />
            (<br />
            <span class="ml-3">ImportIncidentUnits.LeftSceneDate <b>IS NOT NULL</b></span><br />
            <span class="ml-3">AND ImportIncidentUnits.Unit REGEXP '^(<b>R|S|BLS</b>)'</span><br />
            ) OR (<br />
            <span class="ml-3">ImportIncidentUnits.LeftSceneDate <b>IS NULL</b></span><br />
            <span class="ml-3">AND Reports.PI_LastName <b>IS NOT NULL</b></span><br />
            )<br />

          </i>
        </v-card-text>
      </template>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Got it
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RECONCILIATION_TABS from '@/enums/reconciliationTabs';

export default {
  name: 'HelpDialog',

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      tabs: RECONCILIATION_TABS,
    };
  },

  methods: {
    show() {
      this.dialog = true;
    },
  },
};
</script>
