<template>
  <v-item-group>
    <v-row>
      <v-col
          v-for="(item, id) in items"
          :key="id"
          :cols="1"
          :class="['incident-file']"
      >
        <v-item>
          <v-card @click="$emit('click', id, incident)">
            <v-img :src="item.thumbnail_url">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="gray"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
export default {
  name: 'HospitalIncidentFiles',
  props: {
    items: {
      type: Array,
      required: true,
    },
    incident: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.incident-file {
  max-width: 120px;
}
</style>
