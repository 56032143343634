import defaultState from './defaultState';

export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_TABLE_FIELDS = 'SET_TABLE_FIELDS';
export const SET_EXPORT_FIELDS = 'SET_EXPORT_FIELDS';
export const SET_SEARCH_FIELDS = 'SET_SEARCH_FIELDS';
export const SET_BASIC_SEARCH_FILTERS = 'SET_BASIC_SEARCH_FILTERS';
export const SET_ADVANCED_SEARCH_FILTERS = 'SET_ADVANCED_SEARCH_FILTERS';
export const SET_SELECTED_FIELDS = 'SET_SELECTED_FIELDS';
export const SET_SEARCH_PRESET = 'SET_SEARCH_PRESET';
export const SET_COLUMN_PRESET = 'SET_COLUMN_PRESET';
export const SET_EXPORT_PRESET = 'SET_EXPORT_PRESET';
export const SET_CURRENT_COLUMN_PRESET = 'SET_CURRENT_COLUMN_PRESET';
export const SET_SHOW_ADVANCED = 'SET_SHOW_ADVANCED';
export const SET_ADV_SEARCH_GROUPS = 'SET_ADV_SEARCH_GROUPS';
export const SET_ADV_SEARCH_PRESET = 'SET_ADV_SEARCH_PRESET';
export const SET_MONITOR = 'SET_MONITOR';
export const SET_MONITOR_TMP = 'SET_MONITOR_TMP';

export default {
  /**
   * Clear state.
   *
   * @param {Object} state - Entity state.
   */
  [CLEAR_STATE]: (state) => {
    Object.assign(state, defaultState());
  },

  /**
   * Save entity data.
   *
   * @param {Object} state - Entity state.
   * @param {String} value - ID of session.
   */
  [SET_SESSION_ID]: (state, value) => {
    state.sessionId = value;
  },

  /**
   * Save entity data.
   *
   * @param {Object} state - Entity state.
   * @param {{ type: string, items: Array, totalItems: number }} data - Entity data to set
   */
  [SET_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.totalItems = totalItems;
  },

  /**
   * Save entity pagination.
   *
   * @param {Object} state - Entities state.
   * @param {Object} pagination - Entity pagination.
   */
  [SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves list of table fields.
   *
   * @param {Object} state - State.
   * @param {array} data - Data of table fields.
   */
  [SET_TABLE_FIELDS]: (state, data) => {
    state.tableFields = data;
  },

  /**
   * Saves list of export fields.
   *
   * @param {Object} state - State.
   * @param {array} data - Data of table fields.
   */
  [SET_EXPORT_FIELDS]: (state, data) => {
    state.exportFields = data;
  },

  /**
   * Saves list of search fields.
   *
   * @param {Object} state - State.
   * @param {array} data - Data of table fields.
   */
  [SET_SEARCH_FIELDS]: (state, data) => {
    state.searchFields = data;
  },

  /**
   * Saves list of search presets.
   *
   * @param {Object} state - State.
   * @param {array} data - List of presets.
   */
  [SET_SEARCH_PRESET]: (state, data) => {
    state.searchPresets = data;
  },

  /**
   * Saves list of column presets.
   *
   * @param {Object} state - State.
   * @param {array} data - List of presets.
   */
  [SET_COLUMN_PRESET]: (state, data) => {
    state.columnPresets = data;
  },

  /**
   * Saves list of export presets.
   *
   * @param {Object} state - State.
   * @param {array} data - List of presets.
   */
  [SET_EXPORT_PRESET]: (state, data) => {
    state.exportPresets = data;
  },

  /**
   * Save basic filters.
   *
   * @param {Object} state - Entities state.
   * @param {{ string: type , Object: filters }} data - filters data.
   */
  [SET_BASIC_SEARCH_FILTERS]: (state, { type, filters }) => {
    state.basicSearchFilters[type] = filters;
  },

  /**
   * Save advanced search filters.
   *
   * @param {Object} state - Entities state.
   * @param {Object} filters - filters data.
   */
  [SET_ADVANCED_SEARCH_FILTERS]: (state, filters) => {
    state.advancedSearchFilters = filters;
  },

  /**
   * Save selected fields.
   *
   * @param {Object} state - Entities state.
   * @param {Array} fields - fields list.
   */
  [SET_SELECTED_FIELDS]: (state, fields) => {
    state.selectedFields = fields;
  },

  /**
   * Save current column preset
   *
   * @param {Object} state - Entities state.
   * @param {{ value: Number  }} data
   */
  [SET_CURRENT_COLUMN_PRESET]: (state, { value }) => {
    state.currentColumnPreset = value;
  },

  /**
   * Save show advanced
   *
   * @param {Object} state - Entities state.
   * @param {Boolean} value - Value of flag.
   */
  [SET_SHOW_ADVANCED]: (state, value) => {
    state.showAdvanced = value;
  },

  /**
   * Save advanced search groups
   *
   * @param {Object} state - Entities state.
   * @param {Array} value - data
   */
  [SET_ADV_SEARCH_GROUPS]: (state, value) => {
    state.advSearchGroups = value;
  },

  /**
   * Save advanced search groups
   *
   * @param {Object} state - Entities state.
   * @param {Array} value - data
   */
  [SET_ADV_SEARCH_PRESET]: (state, value) => {
    state.advSearchPreset = value;
  },

  /**
   * Set monitor entity.
   *
   * @param {Object} state - Entities state.
   * @param {Object} monitor - data
   */
  [SET_MONITOR]: (state, monitor) => {
    state.monitor = monitor;
  },

  /**
   * Set temporary monitor entity.
   *
   * @param {Object} state - Entities state.
   * @param {Object} monitor - data
   */
  [SET_MONITOR_TMP]: (state, monitor) => {
    state.monitorTmp = monitor;
  },
};
