<template>
  <v-card
    :loading="loading"
    :class="printView ? 'no-transition' : ''"
    tile
    flat
    >
    <v-expansion-panels
        v-if="periodsList && periodsList.length"
        v-model="panel"
        focusable
        multiple
        tile
        flat
        class="periods-panel mt-1"
    >
      <v-expansion-panel
          v-for="(period, i) in periodsList"
          :key="i"
      >
        <v-expansion-panel-header
            :hide-actions="printView"
        >
          <v-row no-gutters>
            <v-col :cols="printView ? 5 : 3">
              <template v-if="period.id">
                <b>Period:</b> {{ period.dateFrom | dateFormat }} — {{ period.dateTo | dateFormat }}
                <span
                  v-if="period.isCurrentPeriod"
                  class="current-period"
                >(Current)</span>
                <span
                    v-if="!period.isCurrentPeriod && lastPeriodId === period.id"
                    class="latest-period"
                >(Latest)</span>
              </template>
              <template v-else>
                <b>Period:</b> Non-Defined
              </template>
            </v-col>
            <v-col cols="2.5">
              <b>Total Hours:</b> {{ convertNumberToTime(period.hours) }}
            </v-col>
            <v-col cols="2.5">
              <template v-if="period.id">
                <b>Remaining:</b> {{ period.remaining > 0
                  ? convertNumberToTime(period.remaining)
                  : '00:00' }} <span v-if="!printView && period.remaining < 0">
                (extra {{ convertNumberToTime(period.remaining * -1) }})
              </span>
              </template>
            </v-col>
            <v-col cols="2">
              <b>Skills:</b>
              <template v-if="period.skillsGoal">
                {{ period.skills }} / {{ period.skillsGoal }}
              </template>
              <template v-else>
                {{ period.skills }}
              </template>
            </v-col>
            <v-col
                v-if="!printView && showActions && period.id"
                cols="2"
            >
              <v-btn
                  x-small
                  class="edit-btn float-right mr-5"
                  color="primary"
                  @click.stop="deletePeriod(period)"
              >delete</v-btn>
              <v-btn
                  x-small
                  class="edit-btn float-right mr-5"
                  @click.stop="$emit('editPeriodClick', period)"
              >Edit</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
              :headers="hoursHeaders"
              :items="period.entries"
              hide-default-footer
              disable-pagination
              dense
              class="hours-table mb-7"
          >
            <template v-slot:item.date="{ item }">
              {{ item.date | dateFormat }}
            </template>
            <template v-slot:item.hours="{ item }">
              {{ convertNumberToTime(item.hours) }}
            </template>
            <template v-slot:item.courseTitle="{ item }">
              <span v-if="printView">{{ item.courseTitle }}</span>
              <a
                v-else
                @click="$emit('viewEntry', item)"
              >{{ item.courseTitle }}</a>
            </template>
            <template v-slot:item.skills="{ item }">
              <div class="pt-1">
                <v-chip
                    v-for="itemSkill in item.skills"
                    v-bind:key="'skill_' + itemSkill.id"
                    color="dark-gray"
                    outlined
                    label
                    small
                    class="skill-chip mr-1 mb-1"
                >
                  {{ itemSkill.skill }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.certificate="{ item }">
              <v-btn
                  v-if="item.certificateIsAvailable
                  && Object.prototype.hasOwnProperty.call(downloadingCertificate, item.id)"
                  :loading="downloadingCertificate[item.id]"
                  small
                  icon
                  title="Download Certificate"
                  @click="downloadCertificate(item)"
              >
                <v-icon
                    small
                    class="mr-1"
                    color="red"
                >
                  mdi-certificate-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text
      v-else
      class="text-center"
    >
      <span v-if="loading">Loading...</span>
      <span v-else>No registered entries.</span>
    </v-card-text>
    <confirmation ref="confirmationPopup" />
  </v-card>
</template>
<script>
import { convertNumberToTime } from '@/utils/convertNumberToTime';
import Confirmation from '@/components/Confirmation';
import ceHours from '@/api/ceHours';
import fileDownload from 'js-file-download';

export default {
  name: 'PeriodsExpansionPanels',
  components: { Confirmation },
  filters: {
    skillsCommaSeparated(skills) {
      if (!skills) {
        return '';
      }
      const skillList = [];
      skills.forEach(item => {
        skillList.push(item.skill);
      });
      return skillList.join(', ');
    },
    skillsNewLinesSeparated(skills) {
      if (!skills) {
        return '';
      }
      const skillList = [];
      skills.forEach(item => {
        skillList.push(item.skill);
      });
      return skillList.join('\n');
    },
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: null,
      required: false,
    },
    periods: {
      type: Array,
      default: null,
    },
    lastPeriodId: {
      type: Number,
      default: null,
    },
    printView: {
      type: Boolean,
      default: false,
    },
    currentPeriod: {
      type: Object,
      required: false,
    },
    priorPeriod: {
      type: Object,
      required: false,
    },
    printPeriod: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      convertNumberToTime,
      expandedPanels: [],
      downloadingCertificate: {},
    };
  },
  computed: {
    hoursHeaders() {
      const hoursHeaders = [
        {
          text: 'Course Date',
          sortable: false,
          value: 'date',
          width: '120',
          cellClass: 'pr-2 pl-2',
          align: 'center',
        },
        {
          text: 'Course Title',
          sortable: false,
          value: 'courseTitle',
          cellClass: 'pr-2 pl-2',
          align: 'center',
        },
        {
          text: 'Approved CE Provider Name',
          sortable: false,
          value: 'providerName',
          cellClass: 'pr-2 pl-2',
          align: 'center',
        },
        {
          text: 'Approved Pre-hospital CE Provider Number',
          sortable: false,
          value: 'provider',
          align: 'center',
          cellClass: 'min-width-130 pr-2 pl-2',
          width: '15%',
        },
        {
          text: 'Earned CE Hours',
          sortable: false,
          value: 'hours',
          align: 'center',
          cellClass: 'pr-2 pl-2',
          width: '10%',
        },
        {
          text: 'Skills',
          sortable: false,
          value: 'skills',
          align: 'center',
          cellClass: 'pr-2 pl-2',
          width: '15%',
        },
      ];
      if (!this.printView && this.userData && this.userData.id) {
        hoursHeaders.push({
          text: '',
          sortable: false,
          value: 'certificate',
          align: 'center',
          cellClass: 'pr-2 pl-2',
          width: '30',
        });
      }

      return hoursHeaders;
    },
    periodsList() {
      if (!this.printView) {
        return this.periods;
      }
      if (this.printPeriod === 'all') {
        return this.periods;
      }
      const periodsToPrint = [];
      if (this.printPeriod === 'current' && this.currentPeriod) {
        periodsToPrint.push(this.currentPeriod);
      }
      if (this.printPeriod === 'prior' && this.priorPeriod) {
        periodsToPrint.push(this.priorPeriod);
      }
      return periodsToPrint;
    },
    panel: {
      get() {
        if (this.printView) {
          const items = [];
          this.periodsList.forEach((item, index) => {
            items.push(index);
          });
          return items;
        }
        return this.expandedPanels;
      },
      set(value) {
        this.expandedPanels = value;
      },
    },
  },
  watch: {
    periods: {
      handler() {
        this.fillDownloadingCertificate();
      },
      deep: true,
    },
  },
  mounted() {
    this.fillDownloadingCertificate();
  },
  methods: {
    fillDownloadingCertificate() {
      const downloadingCertificate = {};
      this.periods.forEach(period => {
        period.entries.forEach(entry => {
          downloadingCertificate[entry.id] = false;
        });
      });
      this.downloadingCertificate = downloadingCertificate;
    },
    deletePeriod(period) {
      this.$refs.confirmationPopup.showConfirm(
        'Delete Education period',
        `Do you really want to delete education period
         ${this.$options.filters.dateFormat(period.dateFrom)} - ${this.$options.filters.dateFormat(period.dateTo)}?`,
        async () => {
          await ceHours.deleteCePeriod(period.id);
          this.$emit('deletedPeriod');
        },
      );
    },
    async downloadCertificate(item) {
      this.downloadingCertificate[item.id] = true;
      const response = await ceHours.downloadCertificate(item.id, this.userData.id);
      this.downloadingCertificate[item.id] = false;
      fileDownload(response, `certificate_${item.id}.pdf`);
    },
  },
};
</script>
<style lang="scss">
.periods-panel {
  .min-width-130 {
    min-width: 130px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 0 16px 0;
  }

  .hours-table {
    thead {
      background-color: #f3f3f3;
    }
  }
  .current-period {
    color: #0c750f;
    font-weight: bold;
  }
  .latest-period {
    color: #2f58de;
    font-weight: bold;
  }
  .edit-btn {
    display: none;
  }
  .v-item--active {
    margin: 10px 0;
    .edit-btn {
      display: block;
    }
  }
  .skill-chip {
    max-width: 120px;
  }
  .skill-chip .v-chip__content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.no-transition {
  .v-item-group.v-expansion-panels {
    &,
    & .v-expansion-panel,
    & .v-expansion-panel-header,
    & .v-expansion-panel-content {
      transition: none !important;
    }
  }
}
</style>
