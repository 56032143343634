import api from './api';

export default {
  /**
   * Fetch list of od map exports for admin role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminFetchList(params) {
    return api()
      .get('admin/external-service-exports', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of od map exports for client role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientFetchList(params) {
    return api()
      .get('client/external-service-exports', { params })
      .then(response => response.data);
  },

  /**
   * Retry failed export.
   *
   * @param {Number} exportId - Export identifier.
   *
   * @return Promise<Object>
   */
  retry(exportId) {
    return api()
      .post(`admin/external-service-exports/${exportId}/retry`)
      .then(response => response.data);
  },
};
