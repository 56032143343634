export default [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Zip',
    value: 'zip',
  },
  {
    text: 'Phone',
    value: 'phone',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    sortable: false,
  },
];
