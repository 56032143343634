<template>
  <v-card class="elevation-20">
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title class="font-weight-medium">Login</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form @keyup.native.enter="loginRequest">
        <v-alert
          :value="errorAlert"
          type="error"
          outlined
          transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>
        <v-alert
          :value="expiredPasswordAlert"
          type="error"
          outlined
          transition="scale-transition"
        >
          Your password is expired. Please use a
          <router-link :to="'forgot'">Forgot Password</router-link> to set a new password
        </v-alert>
        <div v-if="ipNotAllowedAlert">
          <v-alert
              :value="ipNotAllowedAlert"
              type="error"
              outlined
              transition="scale-transition"
          >
            <span class="ml-3">{{ ipNotAllowedMessage }}</span>
            <br v-if="requestToAdminToken"/>
            <v-btn
                v-if="requestToAdminToken"
                :loading="sendingRequestToAdmin"
                :disabled="requestToAdminSent"
                text
                color="primary"
                small
                @click="sendRequestToAdmin"
            >Send request to add IP</v-btn>
          </v-alert>
        </div>

        <v-text-field
          ref="sitecode"
          v-model="sitecode"
          v-validate="'required'"
          :error-messages="errors.collect('sitecode')"
          prepend-icon="domain"
          name="sitecode"
          label="Site Code"
          type="text"
        />
        <v-text-field
          ref="login"
          v-model="login"
          v-validate="'required'"
          :error-messages="errors.collect('login')"
          prepend-icon="person"
          name="login"
          label="User Name"
          type="text"
        />
        <v-text-field
          v-model="password"
          v-validate="'required'"
          :error-messages="errors.collect('password')"
          prepend-icon="lock"
          name="password"
          label="Password"
          type="password"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <trust-browser-checkbox
        v-if="showTrustBrowserCheckbox"
        v-model="trustBrowserModel"
      />
      <v-spacer/>
      <router-link
        :to="'forgot'"
        class="mr-3"
      >Forgot Password</router-link>
      <v-btn
        :loading="loading"
        :disabled="loading"
        width="88"
        class="login__button"
        color="primary"
        @click="loginRequest"
      >
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import auth from '@/api/auth';
import { SET_LOGIN } from '@/store/Login/mutations';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import loginMixins from '@/mixins/loginMixins';
import TrustBrowserCheckbox from '@/components/Login/TrustBrowserCheckbox';

export default {
  name: 'LoginForm',
  components: { TrustBrowserCheckbox },
  mixins: [errorAlertMixins, loginMixins],
  props: {
    trustBrowser: {
      type: Boolean,
      default: true,
      required: true,
    },
    token: {
      type: String,
      default: null,
      required: false,
    },
    hasVerifiedPhone: {
      type: Boolean,
      required: true,
    },
    hasVerifiedEmail: {
      type: Boolean,
      required: true,
    },
    currentIp: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      expiredPasswordAlert: false,
      password: null,
      loading: false,
      ipNotAllowedAlert: false,
      ipNotAllowedMessage: null,
      requestToAdminSent: false,
      requestToAdminToken: null,
      sendingRequestToAdmin: false,
      trustBrowserModel: this.trustBrowser,
      showTrustBrowserCheckbox: 0,
      dictionary: {
        attributes: {
          sitecode: 'Site Code',
          login: 'User Name',
          password: 'Password',
        },
      },
    };
  },
  computed: {
    login: {
      get() {
        return this.$store.state.login.login;
      },
      set(value) {
        this.$store.commit(`login/${SET_LOGIN}`, value);
      },
    },
  },
  mounted() {
    this.showTrustBrowserCheckbox = process.env.VUE_APP_SHOW_TRUST_BROWSER_CHECKBOX === 'true';
    if (this.sitecode) {
      this.$refs.login.focus();
    } else {
      this.$refs.sitecode.focus();
    }
    this.$validator.localize('en', this.dictionary);
  },
  watch: {
    trustBrowserModel() {
      this.$emit('update:trustBrowser', this.trustBrowserModel);
    },
  },
  methods: {
    setUserName(userName) {
      this.login = userName;
    },
    async sendRequestToAdmin() {
      this.sendingRequestToAdmin = true;
      try {
        await auth.requestToAdmin(this.requestToAdminToken);
        this.requestToAdminSent = true;
      } finally {
        this.sendingRequestToAdmin = false;
      }
    },

    async loginRequest() {
      this.ipNotAllowedMessage = null;
      this.ipNotAllowedAlert = false;
      this.requestToAdminSent = false;
      this.sendingRequestToAdmin = false;
      this.expiredPasswordAlert = false;
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.loading = true;
      this.hideError();
      try {
        const user = await auth.login(this.login, this.password, this.trustBrowserModel);
        this.afterLogin(user);
      } catch (error) {
        if (error.response.data.errorCode === 'PASSWORD_IS_EXPIRED') {
          this.expiredPasswordAlert = true;
        } else if (error.response.data.errorCode === 'IP_IS_NOT_ALLOWED') {
          this.ipNotAllowedAlert = true;
          this.ipNotAllowedMessage = error.response.data.message;
          this.requestToAdminToken = error.response.data.payload.token;
        } else if (error.response.data.errorCode === 'SUGGESTION_TO_SAVE_IP') {
          this.emitStandardEvents(error.response.data.payload);
          this.$emit('update:currentIp', error.response.data.payload.ip);
          this.$emit('suggestSaveIp');
        } else if (error.response.data.errorCode === 'VERIFICATION_SMS_CODE_SENT') {
          this.emitStandardEvents(error.response.data.payload);
          this.$emit('smsCodeSent');
        } else if (error.response.data.errorCode === 'VERIFICATION_EMAIL_CODE_SENT') {
          this.emitStandardEvents(error.response.data.payload);
          this.$emit('emailCodeSent');
        } else if (error.response.data.errorCode === 'AUTH_BY_HOSPITAL_CREDENTIALS') {
          this.$emit('update:token', error.response.data.payload.token);
          this.$emit('hospitalAuthRestricted');
        } else if (error.response.data.errorCode === 'AUTH_INCOMPLETE_USER') {
          this.$emit('update:token', error.response.data.payload.token);
          this.$emit('completeClientUser');
        } else {
          this.showError(error.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    /**
     * Emit standard events after typed exception.
     *
     * @param {Object} payload - Payload of response
     */
    emitStandardEvents(payload) {
      this.$emit('update:token', payload.token);
      this.$emit('update:hasVerifiedPhone', payload.hasVerifiedPhone);
      this.$emit('update:hasVerifiedEmail', payload.hasVerifiedEmail);
    },
  },
};
</script>
