import api from './api';

export default {
  /**
   * Fetch list for table.
   *
   * @param {Object} params - Search params
   *
   * @return Promise<Object>
   */
  fetchResult(params) {
    return api()
      .get('/client/advanced-query', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of advanced query emails.
   *
   * @return Promise<Object>
   */
  getEmails() {
    return api()
      .get('/client/advanced-query/emails')
      .then(response => response.data);
  },

  /**
   * Save advanced query emails.
   *
   * @param {Array} emails - List of emails to save
   *
   * @return Promise<Object>
   */
  saveEmails(emails) {
    return api()
      .post('/client/advanced-query/emails', {
        emails,
      });
  },

  /**
   * Export results to XLSX.
   *
   * @param {Object} params - Search params
   *
   * @return Promise<Object>
   */
  export(params) {
    return api()
      .get('/client/advanced-query/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Export results to email.
   *
   * @param {Object} params - Search params
   *
   * @return Promise<Object>
   */
  exportToEmail(params) {
    return api()
      .get('/client/advanced-query/export-to-email', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list for table.
   *
   * @param {Number} incidentId - Incident identifier.
   *
   * @return Promise<Object>
   */
  schedule(incidentId) {
    return api()
      .post(`/client/advanced-query/queue/${incidentId}`)
      .then(response => response.data);
  },

  /**
   * Fetch list for table.
   *
   * @param {Number} incidentId - Incident identifier.
   *
   * @return Promise<Object>
   */
  override(incidentId) {
    return api()
      .delete(`/client/advanced-query/queue/${incidentId}`)
      .then(response => response.data);
  },
};
