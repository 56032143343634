<template>
  <v-card class="white elevation-3 ma-4 fixed-header">
    <templates-toolbar />
    <templates-list ref="templateList"/>
    <template-edit-popup @saved="updateList()"/>
  </v-card>
</template>

<script>
import TemplatesList from '@/components/Templates/TemplatesList';
import TemplatesToolbar from '@/components/Templates/TemplatesToolbar';
import TemplateEditPopup from '@/components/Templates/TemplateEditPopup';
import siteCodeMixins from '@/mixins/siteCodeMixins';
import { SET_TITLE } from '@/store/mutations';

export default {
  components: {
    TemplatesList,
    TemplatesToolbar,
    TemplateEditPopup,
  },
  mixins: [siteCodeMixins],
  created() {
    this.$store.commit(SET_TITLE, this.$store.state.templates.title);
  },
  methods: {
    updateList() {
      this.$refs.templateList.fetchData();
    },
  },
};
</script>
