<template>
  <v-layout
    v-if="!user"
    align-center
    justify-center
  >
    <v-flex
      xs12
      sm8
      md4
    >
      <MainLogo />
      <v-card class="elevation-20">
        <Form />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Form from '@/components/Forgot/Form';
import MainLogo from '@/components/MainLogo';
import authenticatedMixins from '@/mixins/authenticatedMixins';

export default {
  components: {
    Form,
    MainLogo,
  },
  mixins: [authenticatedMixins],
  data() {
    return {};
  },
};
</script>
