<template>
  <v-list-item>
    <item-content
        :item="item"
        :pointer="true"
        @click="routeToCeEntryPage"
    />
    <approve-or-reject-ce-entry
        v-if="!isActiveDelete"
        :item="item"
        @updateNotificationList="$emit('updateNotificationList', item)"
    />
    <delete-item-action
        :item="item"
        @removeItem="$emit('removeItem', item)"
        @isActiveOrInactiveDelete="setIsActiveDelete"
    />
  </v-list-item>
</template>

<script>

import ItemContent from '@/components/Notifications/NotificationTypes/ItemComponents/ItemContent';
import DeleteItemAction from '@/components/Notifications/NotificationTypes/ItemComponents/DeleteItemAction';
import ApproveOrRejectCeEntry from '@/components/Notifications/NotificationTypes/ItemComponents/ApproveOrRejectCeEntry';
import ROUTES from '@/enums/routes';

export default {
  name: 'CreateCeEntryNotification',

  components: {
    ItemContent,
    DeleteItemAction,
    ApproveOrRejectCeEntry,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActiveDelete: false,
    };
  },

  methods: {
    setIsActiveDelete(isActive) {
      this.isActiveDelete = isActive;
    },

    routeToCeEntryPage() {
      if (this.$route.name !== ROUTES.CE_ENTRY_ENTRIES) {
        this.$router.push({ name: ROUTES.CE_ENTRY_ENTRIES });
      } else {
        const link = this.$router.resolve({ name: ROUTES.CE_ENTRY_ENTRIES }).href;
        window.location.replace(link);
      }
    },
  },
};
</script>

<style scoped>

</style>
