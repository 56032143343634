import api from './api';

/**
 * Fetch list of hospital types
 * @return {Array} Hospital types list
 */
export function fetchHospitalsTypes() {
  return api('v4')
    .get('/hospitals/types')
    .then(response => response.data);
}

/**
 * Update data of hospitals type by ID
 * @param {number} hospitalsTypeID - Identifier of hospitals type
 * @param {Array} data - Data of hospitals type for update
 */
export function updateHospitalsType(hospitalsTypeID, data) {
  return api()
    .put(`/hospitals/types/${hospitalsTypeID}`, data)
    .then(response => response.data);
}

/**
 * Create new hospitals type
 * @param {Array} data - Data of hospitals type for create
 */
export function createHospitalsType(data) {
  return api()
    .post('/hospitals/types', data)
    .then(response => response.data);
}

/**
 * Delete hospitals type by ID
 * @param {number} hospitalsTypeID - Identifier of hospitals type
 */
export function deleteHospitalsType(hospitalsTypeID) {
  return api().delete(`/hospitals/types/${hospitalsTypeID}`);
}
