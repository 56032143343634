import Vue from 'vue';
import { AclCreate, AclInstaller, AclRule } from 'vue-acl';
import { INIT } from '@/store/actions';
import { SET_ACL_INITIALIZED } from '@/store/mutations';
import ACL from './enums/aclGlobalRules';
import USER_ROLES from './enums/userRoles';

Vue.use(AclInstaller);

export default function createAcl(router, store) {
  const globalRules = {};
  globalRules[ACL.SUPER_ADMIN] = new AclRule(USER_ROLES.SUPER_ADMIN).generate();
  globalRules[ACL.CLIENT_USER] = new AclRule(USER_ROLES.CLIENT_ADMIN)
    .or(USER_ROLES.CLIENT_ADMIN_IMPERSONATED)
    .or(USER_ROLES.CLIENT_CAPTAIN_USER)
    .or(USER_ROLES.CLIENT_FIREFIGHTER_USER)
    .or(USER_ROLES.CLIENT_QA_USER)
    .or(USER_ROLES.CLIENT_CE_USER)
    .generate();
  globalRules[ACL.HOSPITAL_USER] = new AclRule(USER_ROLES.HOSPITAL_ADMIN)
    .or(USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED)
    .or(USER_ROLES.HOSPITAL_USER)
    .generate();
  globalRules[ACL.PUBLIC] = new AclRule(ACL.PUBLIC)
    .or(USER_ROLES.SUPER_ADMIN)
    .or(USER_ROLES.CLIENT_ADMIN)
    .or(USER_ROLES.CLIENT_ADMIN_IMPERSONATED)
    .or(USER_ROLES.CLIENT_CAPTAIN_USER)
    .or(USER_ROLES.CLIENT_FIREFIGHTER_USER)
    .or(USER_ROLES.CLIENT_QA_USER)
    .or(USER_ROLES.CLIENT_CE_USER)
    .or(USER_ROLES.HOSPITAL_ADMIN)
    .or(USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED)
    .or(USER_ROLES.HOSPITAL_USER)
    .generate();

  return new AclCreate({
    initial: 'public',
    notfound: {
      path: `/login?redirect=${encodeURIComponent(window.location.href)}`,
      forwardQueryParams: true,
    },
    router,
    acceptLocalRules: true,
    globalRules,
    middleware: async acl => {
      if (!store.state.user || !store.state.siteInfo) {
        await store.dispatch(INIT);
      }
      if (store.state.user) {
        if (store.state.user.userTypeId === USER_ROLES.CLIENT_ADMIN
            && store.getters.isImpersonated
        ) {
          acl.change([store.state.siteInfo.clientSiteType, USER_ROLES.CLIENT_ADMIN_IMPERSONATED]);
        } else if (store.state.user.userTypeId === USER_ROLES.HOSPITAL_ADMIN
            && store.getters.isImpersonated
        ) {
          acl.change(USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED);
        } else if (store.state.siteInfo.role === 'client') {
          acl.change([store.state.siteInfo.clientSiteType, store.state.user.userTypeId]);
        } else {
          acl.change(store.state.user.userTypeId);
        }
      } else {
        acl.change(ACL.PUBLIC);
      }
      await store.commit(SET_ACL_INITIALIZED, true);
    },
  });
}
