/**
 * Check value is not number.
 *
 * @param {mixed} value - Value to check
 *
 * @return {Boolean}
 */
export default function isFloat(value) {
  const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(value)) {
    return false;
  }
  const val = parseFloat(value);
  return !Number.isNaN(val);
}
