<template>
  <v-card class="white elevation-3 ma-4">
    <hospitals-tabs />
    <hospitals-search-tools />
    <hospitals-search-table/>
    <hospitals-edit-popup />
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { SET_TITLE } from '@/store/mutations';
import HospitalsSearchTools from '@/components/Hospitals/HospitalsSearchTools';
import HospitalsSearchTable from '@/components/Hospitals/HospitalsSearchTable';
import HospitalsEditPopup from '@/components/Hospitals/HospitalsEditPopup';
import HospitalsTabs from '@/components/Hospitals/HospitalsTabs';

export default {
  components: {
    HospitalsSearchTools,
    HospitalsSearchTable,
    HospitalsEditPopup,
    HospitalsTabs,
  },
  mounted() {
    this[SET_TITLE](this.$t('hospitals.header'));
  },
  methods: {
    ...mapMutations([SET_TITLE]),
  },
};
</script>

<style lang="scss">
.long-beach-tabs div[class='datatable__actions'] {
  float: right;
}
</style>
