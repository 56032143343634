<template>
  <div class="mt-12">
    <v-alert
      :value="!!exportError || !!uploadError"
      type="error"
      outlined
      dismissible
      transition="scale-transition"
      class="mb-4"
    >
      {{ exportError||uploadError }}
    </v-alert>
    <v-expansion-panels
      v-if="exportProcessIsStarted && incidents.length"
      v-model="panel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-progress-linear
            :value="exportProgress"
            :color="!incidentsFailedList.length ? 'green' : 'error'"
            class="progress-text-center"
            height="20"
          >
            <strong>{{ exportProgress }}%</strong>
            <strong v-if="incidentsFailedList.length">(Failed: {{ incidentsFailedList.length }} /
            Processed: {{ incidentsProcessedList.length }})</strong>
          </v-progress-linear>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row no-gutters>
              <v-col>
                <v-treeview
                  :items="resultMenu"
                  :open="openedTree"
                  :active.sync="treeValue"
                  activatable
                  open-on-click
                  transition
                ></v-treeview>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col :class="[$results.scrollable]">
                <v-list>
                  <v-list-item-group>
                    <template v-for="incident in incidentsSeletedList">
                      <v-list-item
                        :key="incident.sequenceNumber"
                        @click="goToIncident(incident)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ incident.sequenceNumber }} | Incident Number:
                            {{ incident.incidentNumber }} | Incident Date:
                            {{ incident.incidentDate | date }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="incident.status === 'failed'">
                            {{ incident.error }}
                          </v-list-item-subtitle>
                          <v-list-item-title v-if="incident.status === 'pdfQueued'">
                            {{ $t('utilitiesExport.messages.pdfQueued') }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="incident.status === 'pdfProcessing'">
                            {{ $t('utilitiesExport.messages.pdfProcessing') }}
                          </v-list-item-subtitle>
                          <v-list-item-title v-if="incident.status === 'pdfProcessed'">
                            {{ $t('utilitiesExport.messages.pdfProcessed') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <progress-bar
      :visible="exportIsStarting"
    >
      {{ $t('utilitiesExport.messages.startingExport') }}
    </progress-bar>

    <progress-bar
      :visible="showPleaseWait"
    >
      {{ $t('utilitiesExport.messages.pleaseWait') }}
    </progress-bar>

    <div v-if="!incidents.length && exportIsComplete">
      {{ $t('utilitiesExport.messages.noIncidentsFound') }}
    </div>
    <template v-if="incidents.length && exportIsComplete">
      <div :class="$results.container">
        <div
          v-if="fileUrl"
          :class="[$results.controls, 'mr-5']"
        >
          <v-btn
            :href="fileUrl"
            color="primary"
          >
            <v-icon :class="$style.icon">get_app</v-icon>
            {{ $t('utilitiesExport.download.buttonCaption') }}
          </v-btn>
        </div>
        <div
          v-if="fileUrl && !uploadIsComplete"
          :class="$results.controls"
        >
          <v-btn
            :loading="exportIsUploading"
            color="primary"
            @click="startUpload"
          >
            <v-icon :class="$style.icon">cloud_upload</v-icon>
            {{ $t('utilitiesExport.upload.buttonCaption') }}
          </v-btn>
        </div>
        <div v-if="uploadIsComplete">
          <v-icon :class="[$style.icon, $results.notificon]">cloud_done</v-icon>
          {{ $t('utilitiesExport.messages.uploadComplete') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import impersonateMixins from '@/mixins/impersonateMixins';
import {
  UTILITIES_EXPORT,
  GET_EXPORT_INFO,
  GET_EXPORT_PROGRESS,
  DHS,
  BILLING,
  EXPORT_TYPE,
  TEMPLATE,
  FORMAT,
  FORMAT_MAP,
  SEQUENCE_NUMBER,
  INCIDENTS,
  EXPORT_STATUS,
  EXPORT_PROGRESS,
  PROCESSING,
  PROCESSED,
  QUEUED,
  FAILED,
  PDF_QUEUED,
  PDF_PROCESSING,
  PDF_PROCESSED,
  START_UPLOAD,
  GET_UPLOAD_INFO,
  FILE_URL,
  UPLOAD_STATUS,
  UPLOAD_PROGRESS,
  EXPORT_ERROR,
  UPLOAD_ERROR,
  CLIENT,
} from '@/store/UtilitiesExport/constants';

import ProgressBar from './ProgressBar';

export default {
  components: {
    ProgressBar,
  },

  mixins: [impersonateMixins],

  data() {
    return {
      DHS,
      BILLING,
      openedTree: ['incidents'],
      treeValue: [],
      panel: null,
      alreadyExpanded: false,
    };
  },
  computed: {
    ...mapState(UTILITIES_EXPORT, {
      exportType: EXPORT_TYPE,
      template: TEMPLATE,
      sequenceNumbers: SEQUENCE_NUMBER,
      incidentsData: INCIDENTS,
      exportError: EXPORT_ERROR,
      uploadError: UPLOAD_ERROR,
      fileUrl: FILE_URL,
      clientId: CLIENT,
    }),
    ...mapGetters(UTILITIES_EXPORT, {
      incidents: 'incidents',
    }),
    data() {
      return this.$store.state[UTILITIES_EXPORT];
    },
    format() {
      return FORMAT_MAP[this.data[FORMAT]];
    },

    /**
     * Get list of incidents depends on choiced type in menu.
     *
     * @return {Array}
     */
    incidentsSeletedList() {
      switch (true) {
        case this.treeValue.includes(FAILED):
          return this.incidentsFailedList;
        case this.treeValue.includes(PROCESSED):
          return this.incidentsProcessedList;
        case this.treeValue.includes(PROCESSING):
          return this.incidentsProcessingList;
        default:
          return [];
      }
    },

    /**
     * Filters only processing status incidents list from common list.
     *
     * @return {Array}
     */
    incidentsProcessingList() {
      const filteredItems = _.pickBy(
        this.incidentsData.items,
        incident => incident.status === PROCESSING
          || incident.status === PDF_QUEUED
          || incident.status === PDF_PROCESSING
          || incident.status === PDF_PROCESSED
          || incident.status === QUEUED,
      );
      return _.values(filteredItems);
    },

    /**
     * Filters only processed incidents list from common list.
     *
     * @return {Array}
     */
    incidentsProcessedList() {
      const filteredItems = _.pickBy(
        this.incidentsData.items,
        incident => incident.status === PROCESSED,
      );
      return _.values(filteredItems);
    },

    /**
     * Filters only failed incidents list from common list.
     *
     * @return {Array}
     */
    incidentsFailedList() {
      const filteredItems = _.pickBy(
        this.incidentsData.items,
        incident => incident.status === FAILED,
      );
      return _.values(filteredItems);
    },

    /**
     * Wait starting process of export flag.
     *
     * @return {Boolean}
     */
    exportIsStarting() {
      const status = this.data[EXPORT_STATUS];

      return status === QUEUED;
    },

    /**
     * Export process is started flag.
     *
     * @return {Boolean}
     */
    exportProcessIsStarted() {
      const status = this.data[EXPORT_STATUS];

      return status !== QUEUED;
    },

    /**
     * Show please wait loading bar.
     *
     * @return {Boolean}
     */
    showPleaseWait() {
      const status = this.data[EXPORT_STATUS];

      return status === PROCESSING && this.exportProgress === 100;
    },

    /**
     * Export is complete flag.
     *
     * @return {Boolean}
     */
    exportIsComplete() {
      const status = this.data[EXPORT_STATUS];

      return status === PROCESSED;
    },

    /**
     * Export progress.
     *
     * @return {Number}
     */
    exportProgress() {
      return Number(this.data[EXPORT_PROGRESS]);
    },

    /**
     * Export file is uploading flag.
     *
     * @return {Boolean}
     */
    exportIsUploading() {
      const status = this.data[UPLOAD_STATUS];

      return status === QUEUED || status === PROCESSING;
    },

    /**
     * Upload file is complete flag.
     *
     * @return {Boolean}
     */
    uploadIsComplete() {
      const status = this.data[UPLOAD_STATUS];

      return status === PROCESSED;
    },

    /**
     * Upload progress.
     *
     * @return {Boolean}
     */
    uploadProgress() {
      return Number(this.data[UPLOAD_PROGRESS]);
    },

    /**
     * Get list of result menu items
     *
     * @return {Array}
     */
    resultMenu() {
      return [
        {
          id: 'incidents',
          name: 'Incidents',
          children: [
            {
              id: FAILED,
              name: `Failed (${this.incidentsFailedList.length})`,
            },
            {
              id: PROCESSED,
              name: `Processed (${this.incidentsProcessedList.length})`,
            },
            {
              id: PROCESSING,
              name: `Processing (${this.incidentsProcessingList.length})`,
            },
          ],
        },
      ];
    },
  },

  watch: {
    incidentsFailedList(newVal) {
      if (newVal.length && !this.alreadyExpanded) {
        this.expandPanel();
        this.alreadyExpanded = true;
      }
    },
  },

  methods: {
    ...mapActions(UTILITIES_EXPORT, {
      getExportInfo: GET_EXPORT_INFO,
      getExportProgress: GET_EXPORT_PROGRESS,
      startUpload: START_UPLOAD,
      getUploadInfo: GET_UPLOAD_INFO,
    }),

    /**
     * Open expanded panel and open Failed incidents list.
     */
    expandPanel() {
      this.panel = 0;
      this.openedTree = ['incidents', FAILED];
      this.treeValue = [FAILED];
    },

    goToIncident(incident) {
      this.impersonateToIncident(this.clientId, incident.id);
    },
  },
};
</script>
<style lang="scss">
.progress-text-center .v-progress-linear__content {
  text-align: center;
}
</style>
<style lang="scss" src="./stylesheets/style.scss" module />
<style lang="scss" src="./stylesheets/section.scss" module="$section" />
<style lang="scss" src="./stylesheets/results.scss" module="$results" />
