import reconciliation from '@/api/reconciliation';
import fileDownload from 'js-file-download';
import RECONCILIATION_TABS from '@/enums/reconciliationTabs';

export default {
  data() {
    return {
      clientId: null,
      downloading: false,
      tabIndex: 0,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      tabs: RECONCILIATION_TABS,
      searchFilters: {
        from: null,
        to: null,
        incidentNumber: null,
      },
    };
  },

  computed: {
    tab() {
      return this.tabMap[this.tabIndex];
    },
  },

  methods: {
    async downloadFile(fileType, period) {
      this.downloading = true;
      try {
        const response = await reconciliation.getFile({
          clientId: this.clientId,
          year: this.year,
          month: this.month,
          dataType: this.tab,
          fileType,
          period,
        });
        const disposition = response.request.getResponseHeader('Content-Disposition');
        const filename = disposition.split('filename=')[1].split(';')[0];
        fileDownload(response.data, filename);
      } finally {
        this.downloading = false;
      }
    },
  },
};
