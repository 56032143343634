import api from './api';

export default {
  /**
   * Fetch list of templates.
   *
   * @param {Object} search - Params for search template.
   * @param {int} page - Page number.
   * @param {int} perPage - Count of row on page.
   * @param {string} sortBy - Sorting param.
   * @param {string} descending - Desc or asc.
   * @param {Array} include - List of includes.
   */
  fetchTemplatesList(search, page, perPage, sortBy, descending, include) {
    const params = {
      ...search,
      page,
      per_page: perPage,
      order_by: sortBy,
      sort_order: descending ? 'desc' : 'asc',
      include: include.join(',', include),
    };

    return api()
      .get('/templates', { params })
      .then(response => response.data);
  },

  /**
   * Get all templates with trashed.
   *
   * @param {string} status - Deletion status.
   * @param {string} sortBy - Sorting param.
   * @param {string} descending - Desc or asc.
   */
  getList(status, sortBy, descending) {
    const params = {
      status,
      order_by: sortBy,
      sort_order: descending ? 'desc' : 'asc',
    };

    return api()
      .get('/templates/list', { params })
      .then(response => response.data);
  },

  /**
   * Update template.
   *
   * @param {Object} template - Template data.
   */
  saveTemplate(template) {
    return api()
      .put(`/templates/${template.templateId}`, template)
      .then(response => response.data);
  },

  /**
   * Create new template.
   *
   * @param {Object} template - Template data.
   */
  createTemplate(template) {
    return api()
      .post('/templates', template)
      .then(response => response.data);
  },

  /**
   * Delete template.
   *
   * @param {number} id - Identifier of template.
   */
  deleteTemplate(id) {
    return api()
      .delete(`/templates/${id}`)
      .then(response => response.data);
  },

  /**
   * Restore template.
   *
   * @param {number} id - Identifier of template.
   */
  restoreTemplate(id) {
    return api()
      .post(`/templates/${id}/restore`)
      .then(response => response.data);
  },
};
