<template>
  <div>
    <v-text-field
        v-model="condition.value"
        hide-details
        outlined
        dense
    />
  </div>
</template>
<script>

export default {
  name: 'LikeCriterion',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  watch: {
    condition: {
      handler() {
        this.$emit('update:condition', this.condition);
      },
      deep: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
};
</script>
