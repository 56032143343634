import api from './api';

/*
 * Starts process of generating DHS/Billing export file
 */
export const startExport = (data, type) => api('v4')
  .post(`/export/${type}`, data)
  .then(response => response.data);

/*
 * Checks export info by export ID
 */
export const getExportInfo = id => api('v4')
  .get(`/export/${id}`)
  .then(response => response.data);

/**
 * Get export progress status
 */
export const getExportProgress = id => api('v4')
  .get(`/export/${id}/progress`)
  .then(response => response.data);

/**
 * Start upload process
 * @param id - previously saved export id
 * @returns {*}
 */

export const startUpload = id => api('v4')
  .post('/export/upload', { exportId: id })
  .then(response => response.data);

/**
 * Checks upload status
 * @param id - upload id
 * @returns {*}
 */

export const getUploadInfo = id => api('v4')
  .get(`/export/upload/${id}`)
  .then(response => response.data);

export default {
  getExportInfo,
  getExportProgress,
  getUploadInfo,
  startExport,
  startUpload,
};
