export default [
  {
    text: 'Client',
    value: 'client',
    sortable: false,
  },
  {
    text: 'Incident',
    value: 'incident',
    sortable: false,
  },
  {
    text: 'Phone',
    value: 'phone',
  },
  {
    text: 'Additional text',
    value: 'additionalText',
  },
  {
    text: 'Created at',
    value: 'created',
  },
  {
    text: 'Sent at',
    value: 'sent',
  },
  {
    text: 'Page 2',
    value: 'page2',
    sortable: false,
  },
  {
    text: 'Status',
    value: 'status',
  },
  {
    sortable: false,
  },
];
