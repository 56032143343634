<template>
  <v-tooltip
      :disabled="tooltipDisabled"
      right
  >
    <template v-slot:activator="{ on }">
      <v-list-item
          :to="item.route ? { name: item.route } : undefined"
          :href="item.link ? item.link : undefined"
          :target="item.link ? '_blank' : undefined"
          :ripple="false"
          :exact="exact"
          color="primary"
          link
          v-on="on"
      >
        <v-list-item-icon class="single-item-icon">
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <span v-text="item.title"></span>
  </v-tooltip>

</template>

<script>
export default {
  name: 'NavigationItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
    tooltipDisabled: {
      type: Boolean,
      required: true,
    },
    exact: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss">
.single-item-icon {
  margin-right: 15px !important;
}
</style>
