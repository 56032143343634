// Notification settings.
export const SET_NOTIFICATION_EMAILS = 'SET_NOTIFICATION_EMAILS';
export const SET_NOTIFICATION_TIME = 'SET_NOTIFICATION_TIME';
export const SET_NOTIFICATION_RULES = 'SET_NOTIFICATION_RULES';
export const ADD_NOTIFICATION_RULE = 'ADD_NOTIFICATION_RULE';
export const UPDATE_NOTIFICATION_RULE = 'UPDATE_NOTIFICATION_RULE';
export const SET_CE_ADMIN_NOTIFICATION = 'SET_CE_ADMIN_NOTIFICATION';

// Export settings.
export const SET_BILLING_EXPORT_SETTINGS = 'SET_BILLING_EXPORT_SETTINGS';
export const SET_BILLING_EXPORT_UPLOAD_TYPES = 'SET_BILLING_EXPORT_UPLOAD_TYPES';
export const SET_DHS_EXPORT_SETTINGS = 'SET_DHS_EXPORT_SETTINGS';
export const SET_MINDBASE_EXPORT_SETTINGS = 'SET_MINDBASE_EXPORT_SETTINGS';
export const SET_DHS_EXPORT_UPLOAD_TYPES = 'SET_DHS_EXPORT_UPLOAD_TYPES';
export const SET_UNFILTERED_EXPORT_SETTINGS = 'SET_UNFILTERED_EXPORT_SETTINGS';

// Security settings.
export const SET_SECURITY_SETTINGS_API_KEY = 'SET_SECURITY_SETTINGS_API_KEY';
export const SET_SECURITY_SETTINGS_ACCESS_RESTRICTION = 'SET_SECURITY_SETTINGS_ACCESS_RESTRICTION';
export const UPDATE_ACCESS_RESTRICTION = 'UPDATE_ACCESS_RESTRICTION';

// Deleted unique forms notification
export const SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS = 'SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS';
export const SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY = 'SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY';
export const UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION = 'UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION';

export default {
  /**
   * Set report notification emails.
   * @param state
   * @param emails
   */
  [SET_NOTIFICATION_EMAILS]: (state, emails) => {
    state.reportNotification.emails = emails;
  },
  /**
   * Set report notification time.
   * @param state
   * @param newEmail
   */
  [SET_NOTIFICATION_TIME]: (state, { module, time }) => {
    state[module].time = time;
  },
  /**
   * Set notification rules.
   * @param state
   * @param time
   */
  [SET_NOTIFICATION_RULES]: (state, { rules, module }) => {
    state[module].rules = rules;
  },
  /**
   * Add notification rule to array.
   * @param state
   * @param rule
   * @param module
   */
  [ADD_NOTIFICATION_RULE]: (state, { rule, module }) => {
    state[module].rules.push(rule);
  },
  /**
   * Update notification rule by rule id.
   * @param state
   * @param rule
   * @param module
   */
  [UPDATE_NOTIFICATION_RULE]: (state, { rule, module }) => {
    const { rules } = state[module];
    const ruleIndex = rules
      .findIndex(createdRule => createdRule.ruleId === rule.ruleId);

    if (ruleIndex !== -1) {
      state[module].rules = [...rules.slice(0, ruleIndex), rule, ...rules.slice(ruleIndex + 1)];
    }
  },

  /**
   * Set CE Admin notifications.
   * @param state
   * @param {object} data List of admins.
   */
  [SET_CE_ADMIN_NOTIFICATION]: (state, data) => {
    state.ceAdminNotifications = data;
  },

  /**
   * Set Billing Export settings.
   * @param state
   * @param {object} data Billing Export settings.
   */
  [SET_BILLING_EXPORT_SETTINGS]: (state, data) => {
    state.billingExportSettings = data;
  },

  /**
   * Set Unfiltered Export settings.
   * @param state
   * @param {object} data Billing Export settings.
   */
  [SET_UNFILTERED_EXPORT_SETTINGS]: (state, data) => {
    state.unfilteredExportSettings = data;
  },

  /**
   * Set Unfiltered Export settings.
   * @param state
   * @param {object} data Billing Export settings.
   */
  [SET_MINDBASE_EXPORT_SETTINGS]: (state, data) => {
    state.mindBaseExportSettings = data;
  },

  /**
   * Set DHS Export upload types.
   * @param {object} data Upload types.
   */
  [SET_DHS_EXPORT_UPLOAD_TYPES]: (state, data) => {
    state.dhsExportUploadTypes = data;
  },

  /**
   * Set Billing Export upload types.
   * @param {object} data Upload types.
   */
  [SET_BILLING_EXPORT_UPLOAD_TYPES]: (state, data) => {
    state.billingExportUploadTypes = data;
  },

  /**
   * Set DHS Export settings.
   * @param {object} data DHS Export settings.
   */
  [SET_DHS_EXPORT_SETTINGS]: (state, data) => {
    state.dhsExportSettings = data;
  },

  /**
   * Set Security Settings API key information.
   * @param {object} data API Key security settings.
   */
  [SET_SECURITY_SETTINGS_API_KEY]: (state, data) => {
    state.securitySettings.apiKeySettings = data;
  },

  /**
   * Set Security Settings access restriction information.
   * @param {object} data Access restrictions security settings.
   */
  [SET_SECURITY_SETTINGS_ACCESS_RESTRICTION]: (state, data) => {
    state.securitySettings.accessRestrictions = data;
  },

  /**
   * Set deleted unique forms emails.
   *
   * @param state
   * @param emails
   */
  [SET_DELETED_UNIQUE_FORMS_NOTIFICATION_EMAILS]: (state, emails) => {
    state.deletedUniqueForms.emails = emails;
  },

  /**
   * Set deleted unique forms frequency.
   *
   * @param state
   * @param frequency
   */
  [SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY]: (state, frequency) => {
    state.deletedUniqueForms.frequency = frequency;
  },
};
