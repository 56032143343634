<template>
  <v-data-table
    ref="dataTable"
    :headers="header"
    :items="clients"
    :loading="loading"
    :class="[$style.table]"
    :options.sync="pagination"
    :height="tableHeight"
    hide-default-header
    hide-default-footer
    fixed-header
  >
    <template v-slot:header="{ props: { headers } }">
      <thead class="v-data-table-header">
        <tr>
          <th
            v-for="header in headers"
            :key="header.text"
            :class="[
              header.sortable ? 'sortable' : '',
              header.value === pagination.sortBy[0] ? 'active' : '',
              header.align === 'left' ? 'text-left' : '',
              pagination.sortDesc[0] ? 'desc': 'asc',
            ]"
            class="text-left"
            @click="changeSort(header.value)"
          >
            <span v-html="header.text"></span>
            <v-icon
                v-if="header.sortable"
                class="v-data-table-header__icon"
                small
            >mdi-arrow-up</v-icon>
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item.registryId"
        >
          <td>
            <a @click="impersonate(item.clientId)">{{ item.clientName }}</a>
          </td>
          <td>{{ item.template }} ({{ item.releaseDate | formatDate }})</td>
          <td>{{ item.incidentsTotal }}</td>
          <td>{{ item.incidentsCurrentMonth }}</td>
          <td>{{ item.incidentsPreviousMonth }}</td>
          <td>{{ item.incidentsCurrentYear }}</td>
          <td>{{ item.activeUsers }}</td>
          <td>{{ item.totalDevices }}</td>
          <td>{{ item.totalFaxesPreviousYear }}</td>
          <td>{{ item.totalFaxesCurrentYear }}</td>
        </tr>
      </tbody>

      <tfoot>
        <tr :class="[$style.footer]">
          <td></td>
          <td>TOTALS:</td>
          <td>{{ totals.incidentsTotal }}</td>
          <td>{{ totals.incidentsCurrentMonth }}</td>
          <td>{{ totals.incidentsPreviousMonth }}</td>
          <td>{{ totals.incidentsCurrentYear }}</td>
          <td>{{ totals.activeUsers }}</td>
          <td>{{ totals.totalDevices }}</td>
          <td>{{ totals.totalFaxesPreviousYear }}</td>
          <td>{{ totals.totalFaxesCurrentYear }}</td>
        </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { SET_TITLE } from '@/store/mutations';
import { FETCH_DATA } from '@/store/AdminDashboard/actions';
import clientsApi from '@/api/clients';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'AdminPage',
  filters: {
    /**
     * Format date time.
     *
     * @param {string} date - Datetime for format.
     */
    formatDate(date) {
      return date ? `${moment.parseZone(date).format('MM/DD/YYYY')}` : 'None';
    },
  },
  mixins: [fixedHeader],
  data() {
    return {
      loading: true,
      pagination: {
        itemsPerPage: -1,
      },
      header: [
        {
          text: 'Client Name',
          value: 'clientName',
          sortable: true,
        },
        {
          text: 'Assigned Template',
          value: 'template',
          sortable: true,
          sort: (a, b) => {
            const versionA = a ? a.split('.') : [];
            const versionB = b ? b.split('.') : [];

            const firstResult = this.compareVersion(versionA[0], versionB[0]);

            if (firstResult !== 0) {
              return firstResult;
            }

            const secondResult = this.compareVersion(versionA[1], versionB[1]);

            if (secondResult !== 0) {
              return secondResult;
            }

            return this.compareVersion(versionA[2], versionB[2]);
          },
        },
        {
          text: 'Incidents',
          value: 'incidentsTotal',
          sortable: true,
        },
        {
          text: 'Incidents<br>This Month',
          value: 'incidentsCurrentMonth',
          sortable: true,
        },
        {
          text: 'Incidents<br>Last Month',
          value: 'incidentsPreviousMonth',
          sortable: true,
        },
        {
          text: 'Incidents<br>Year to Date',
          value: 'incidentsCurrentYear',
          sortable: true,
        },
        {
          text: 'Active<br>Users',
          value: 'activeUsers',
          sortable: true,
        },
        {
          text: 'Devices',
          value: 'totalDevices',
          sortable: true,
        },
        {
          text: `Faxes<br>${new Date().getFullYear() - 1}`,
          value: 'totalFaxesPreviousYear',
          sortable: true,
        },
        {
          text: `Faxes<br>${new Date().getFullYear()}`,
          value: 'totalFaxesCurrentYear',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('adminDashboard', ['clients', 'totals']),
  },
  created() {
    this.$store.commit(SET_TITLE, this.$store.state.adminDashboard.title);
    this.getData();
  },
  methods: {
    ...mapActions({
      FETCH_DATA: `adminDashboard/${FETCH_DATA}`,
    }),
    changeSort(column) {
      if (this.pagination.sortBy[0] === column) {
        this.pagination.sortDesc = [!this.pagination.sortDesc[0]];
      } else {
        this.pagination.sortBy = [column];
        this.pagination.sortDesc = [false];
      }
    },

    /**
     * Get all data for admin dashboard page.
     */
    getData() {
      this.FETCH_DATA().then(() => {
        this.loading = false;
      });
    },

    /**
     * Get url for impersonate client.
     *
     * @param {number} clientId - Identifier of client
     */
    impersonate(clientId) {
      clientsApi.getImpersonateUrl(clientId).then(data => {
        const win = window.open(data.url, '_blank');
        win.focus();
      });
    },

    /**
     * Sort function for version.
     *
     * @param {String} a - Argimetn for sorting
     * @param {String} b - Argimetn for sorting
     * @return {number}
     */
    compareVersion(a, b) {
      if (Number(a) < Number(b)) {
        return -1;
      }

      if (Number(a) > Number(b)) {
        return 1;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" module>
.table {
  td,
  th {
    padding: 0 5px !important;
  }

  tr td {
    height: 25px !important;
  }

  tr td:first-child {
    white-space: nowrap;
    padding-left: 20px !important;
  }

  tr th:first-child {
    padding-left: 20px !important;
  }
}

.footer {
  td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>
