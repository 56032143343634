<template>
  <div class="reassign-report">
    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
        persistent
        :transition="false"
    >
      <v-card v-if="loading">
        <v-card-text class="mt-3">
          Loading...
          <v-progress-linear
              indeterminate
              color="primary"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>Reassign To Unit</v-card-title>
        <v-divider></v-divider>
        <v-card-text
          v-if="dialog"
          class="pt-2"
        >
          <template v-if="cadUnits.length">
            <b>Cad Allowed:</b>
            <div>
              <v-chip
                  v-for="item in cadUnits"
                  v-bind:key="'cadunit_' + item.unit"
                  class="ma-2"
              >
                {{ item.unit }}
              </v-chip>
            </div>
          </template>

          <b>Admin Allowed:</b>
          <v-radio-group
              v-model="type"
              row
          >
            <v-radio
                label="All your units"
                :value="types.YOUR"
            ></v-radio>
            <v-radio
                label="Custom units"
                :value="types.CUSTOM"
            ></v-radio>
          </v-radio-group>

          <template v-if="type === types.CUSTOM">
            Select units below to reassignment:
            <div>
              <v-chip
                  v-for="(item, index) in assignedUnits"
                  v-bind:key="'assigned_unit_' + index"
                  class="ma-2"
                  close
                  @click:close="assignedUnits.splice(index, 1)"
              >
                {{ item.unit }}
              </v-chip>
            </div>

            <v-card
              color="#f5f5f6"
              flat
              class="pa-3"
            >
              <v-autocomplete
                  v-model="selectedClient"
                  :items="entity.clients"
                  item-text="name"
                  item-value="registryId"
                  :loading="loadingDevices"
                  @change="getClientDevicesList"
              />
              <v-card
                flat
                tile
                class="devices-list pl-5"
              >
                <v-row v-if="client">
                  <v-col
                      v-for="(chunk, index) in devicesChunks"
                      v-bind:key="'chunk_' + index"
                      sm="3"
                  >
                    <v-checkbox
                        v-for="(device, index) in chunk"
                        v-bind:key="'device_' + index"
                        v-model="devicesModels[client.registryId + '-' + device.dispatchId]"
                        :label="client.mapTypeId + ' ' + device.dispatchId"
                        hide-details
                        @change="clickDeviceHandler(client.registryId, device.dispatchId)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </template>

        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import transmittedReports from '@/api/transmittedReports';
import { LOAD_CLIENTS } from '@/store/TransmittedReports/actions';
import TRANSMITTED_REASSIGN_TYPES from '@/enums/transmittedReassingTypes';

const { mapState, mapActions } = createNamespacedHelpers('transmittedReports');

export default {
  name: 'ReassignTransmittedReportPopup',
  data() {
    return {
      dialog: false,
      saving: false,
      loading: false,
      loadingDevices: false,
      item: null,
      cadUnits: [],
      assignedUnits: [],
      clientDevices: [],
      selectedClient: null,
      type: 'your',
      types: TRANSMITTED_REASSIGN_TYPES,
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),

    clientsById() {
      const clients = {};
      if (!this.entity.clients) {
        return {};
      }
      this.entity.clients.forEach(client => {
        clients[client.registryId] = client;
      });
      return clients;
    },

    client() {
      return this.clientsById[this.selectedClient];
    },

    assignedUnitIds() {
      return this.assignedUnits.map(item => `${item.clientId}-${item.dispatchId}`);
    },

    devicesChunks() {
      const countChunks = 4;
      const chunks = [];
      let devices = _.cloneDeep(this.clientDevices);
      let restChunks = countChunks;
      for (let i = 1; i <= countChunks; i++) {
        let chunksSize = devices.length / restChunks;
        if (chunksSize > parseInt(chunksSize, 10)) {
          chunksSize = parseInt(chunksSize, 10) + 1;
        }
        chunks.push(devices.slice(0, chunksSize));
        devices = devices.slice(chunksSize);
        restChunks--;
      }
      return chunks;
    },
    devicesModels: {
      get() {
        const models = {};
        this.clientDevices.forEach(device => {
          const id = `${this.client.registryId}-${device.dispatchId}`;
          models[id] = this.assignedUnitIds.includes(id);
        });
        return models;
      },
    },
  },
  methods: {
    ...mapActions({
      loadClients: LOAD_CLIENTS,
    }),
    clickDeviceHandler(clientId, dispatchId) {
      const id = `${clientId}-${dispatchId}`;
      const index = this.assignedUnitIds.indexOf(id);

      if (index !== -1) {
        this.assignedUnits.splice(index, 1);
      } else {
        this.assignedUnits.push({
          unit: `${this.clientsById[clientId].mapTypeId} ${dispatchId}`,
          clientId,
          dispatchId,
        });
      }
    },
    async show(item) {
      const currentClientId = this.$store.state.user.clientId;
      this.selectedClient = currentClientId;
      this.dialog = true;
      this.item = item;
      this.loading = true;
      this.type = item.assignedToAll ? this.types.YOUR : this.types.CUSTOM;
      try {
        const promises = [];
        if (this.entity.clients === null) {
          promises.push(this.loadClients());
        }
        promises.push(this.getUnits(item.id));
        promises.push(this.getAssignedUnits(item.id));
        promises.push(this.getClientDevicesList(currentClientId));
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },
    async getUnits(id) {
      this.cadUnits = await transmittedReports.getUnits(id);
    },

    async getClientDevicesList(clientId) {
      try {
        this.loadingDevices = true;
        this.clientDevices = [];
        this.clientDevices = await transmittedReports.getClientDevicesList(clientId);
      } finally {
        this.loadingDevices = false;
      }
    },

    async getAssignedUnits(id) {
      this.assignedUnits = await transmittedReports.getAssignedUnits(id);
    },

    async save() {
      try {
        this.saving = true;
        await transmittedReports.saveUnits(this.item.id, {
          type: this.type,
          list: this.type === this.types.CUSTOM ? this.assignedUnits : null,
        });
        this.dialog = false;
        this.$emit('saved');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss">
.devices-list {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: transparent !important;
}
</style>
