<template>
  <v-toolbar
    flat
    class="view-incident-toolbar"
  >
    <v-subheader class="subheader pl-0">
      <v-btn
          v-if="showBackBtn"
          icon
          class="mr-3"
          @click="goBack()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn> Sequence Number: <span class="sequence-number">{{ incident.sequenceNumber }}</span>
    </v-subheader>
    <v-spacer />
    <template v-if="editMode">
      <v-btn
          v-if="validationSupports"
          :color="validated ? 'success' : 'primary'"
          :loading="validating"
          small
          class="mr-5"
          @click="$emit('validate')"
      >
        <v-icon
            left
            dark
        >
          mdi-check-circle-outline
        </v-icon>
        Verify (validation) check
      </v-btn>
      <v-btn
          small
          text
          color="primary"
          class="mr-5"
          @click="$emit('cancelEdit')"
      >
        Cancel
      </v-btn>
      <v-btn
          small
          color="primary"
          :loading="saving"
          :disabled="saveDisabled"
          @click="$emit('save')"
      >
        <v-icon
            left
            dark
        >
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
    </template>
    <template v-else>
      <v-btn
          v-if="showTechnicalInfo"
          text
          color="blue"
          @click="$emit('clickTechnicalInfo')"
      >Technical Info</v-btn>
      <v-btn
          v-if="showFlagBtn"
          text
          color="blue"
          @click="$emit('clickFlag')"
      >
        <v-icon
            left
            dark
        >
          mdi-flag
        </v-icon>
        Flag for review
      </v-btn>

      <v-btn
          small
          color="blue"
          dark
          class="mr-5"
          :loading="downloading"
          @click="$emit('download')"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>

      <v-btn
          v-if="showEditBtn"
          small
          color="blue"
          dark
          @click="edit()"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
        Edit
      </v-btn>
    </template>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
    incident: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    validating: {
      type: Boolean,
      required: true,
    },
    validationSupports: {
      type: Boolean,
      required: true,
    },
    validated: {
      type: Boolean,
      required: true,
    },
    downloading: {
      type: Boolean,
      required: true,
    },
    showFlagBtn: {
      type: Boolean,
      required: true,
    },
    showEditBtn: {
      type: Boolean,
      required: true,
    },
    showTechnicalInfo: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    saveDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    showBackBtn() {
      return window.history.state && window.history.state.key;
    },
  },
  methods: {
    edit() {
      this.$emit('update:editMode', true);
      this.$emit('clickEdit');
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss">
.view-incident-toolbar {
  .subheader {
    font-size: 18px;
    .sequence-number {
      font-weight: bold;
      margin-left: 8px;
    }
  }
}
</style>
