export default {
  header: 'Export',
  formLabels: {
    type: 'Type',
    clients: 'Clients',
    xmlTemplate: 'XML Template',
    date: 'Date',
    dateFrom: 'From',
    dateTo: 'To (including)',
    format: 'Format',
    sequenceNumber: 'Sequence Numbers',
    dhsExport: 'DHS Export',
    billingExport: 'Billing Export',
    selectClient: 'Select Client',
    selectTemplate: 'Select Template',
    commaSeparated: 'Comma Separated',
    includeUpdated: 'Include Updated',
    useClientExportSettings: 'Use Client Export Settings',
  },
  messages: {
    incorrectSequenceNumber: 'Incorrect Sequence Number',
    startingExport: 'Export process in queue, please wait...',
    pleaseWait: 'Please wait a bit...',
    noIncidentsFound: 'No Incidents Found',
    uploading: 'Uploading file',
    uploadComplete: 'Upload complete',
    pdfQueued: 'Process of PDF rendering in queue...',
    pdfProcessing: 'PDF rendering...',
    pdfProcessed: 'PDF rendering is done',
  },
  download: {
    buttonCaption: 'Download Zip',
  },
  upload: {
    buttonCaption: 'Upload To Export Server',
  },
  backButton: 'Back',
};
