<template>
  <v-toolbar>
    <v-autocomplete
      v-model="clientId"
      :items="clients"
      :loading="loading"
      item-text="name"
      item-value="registryId"
      hide-details
      style="max-width: 400px"
      placeholder="Select Client"
      clearable
      @change="$emit('changeClientId', clientId ? clientsById[clientId] : null)"
    />
    <v-spacer />
    <v-btn
      :disabled="!clientId"
      color="primary"
      @click="$emit('clickAddNew')"
    >+ Generate New</v-btn>
  </v-toolbar>
</template>
<script>
import clients from '@/api/clients';

export default {
  name: 'Toolbar',

  data() {
    return {
      clientId: null,
      clients: [],
      loading: false,
    };
  },

  computed: {
    clientsById() {
      const clientsById = {};
      this.clients.forEach(client => {
        clientsById[client.registryId] = client;
      });
      return clientsById;
    },
  },

  mounted() {
    this.fetchClients();
  },

  methods: {
    setClient(clientId) {
      this.clientId = clientId;
      this.$emit('changeClientId', this.clientsById[clientId]);
    },

    async fetchClients() {
      try {
        this.loading = true;
        const response = await clients.fetchList({});
        this.clients = response.results;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
