<template>
  <div
      :class="['tabs-container', (height < minHeight ? 'scroll' : '')]"
      v-bind:style="{ height: height + 'px' }"
  >
    <v-tabs
        v-model="tab"
        v-ripple="false"
        vertical
        align-with-title
        :height="height < minHeight ? minHeight : height"
        :slider-size="5"
        center-active
        :fixed-tabs="false"
        background-color="#f5f5f6"
    >
      <template v-for="(item, index) in categories">
        <v-tab :key="'item' + index">
          {{ item }}
        </v-tab>
      </template>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  props: {
    value: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      minHeight: 700,
    };
  },
  computed: {
    tab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>

<style lang="scss">
.tabs-container {
  background-color: #f5f5f6;
  padding-left: 10px;
  border-right: 1px solid #e1e1e3;
  .v-tab {
    justify-content: left;
    text-transform: none;
    margin-left: 0px !important;
    max-width: none;
  }
}
.tabs-container.scroll {
  overflow-y: scroll;
}
</style>
