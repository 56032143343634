import clientsApi from '@/api/clients';
import lookupApi from '@/api/lookup';
import templatesApi from '@/api/templates';
import DELETION_STATUSES from '@/enums/deletionStatuses';
import {
  SET_CLIENTS_ITEMS, SET_PROVIDERS, SET_TEMPLATES, SET_NEMSIS_AGENCIES,
} from './mutations';

export const LOAD_CLIENTS = 'LOAD_CLIENTS';
export const LOAD_PROVIDERS = 'LOAD_PROVIDERS';
export const LOAD_TEMPLATES = 'LOAD_TEMPLATES';
export const LOAD_NEMSIS_AGENCIES = 'LOAD_NEMSIS_AGENCIES';

export const INCLUDES = {
  REGISTRY: 'registry',
  COUNT_DEVICES: 'countDevices',
  LOGO_URL: 'logoUrl',
  BILLING_FTP_USER: 'billingFtpUser',
  TEMPLATE: 'template',
};

export default {
  /**
   * Load clients list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Clients state.
   */
  [LOAD_CLIENTS]: async ({ commit, state }) => {
    const { filters } = state;
    const include = [
      INCLUDES.REGISTRY,
      INCLUDES.COUNT_DEVICES,
      INCLUDES.LOGO_URL,
      INCLUDES.BILLING_FTP_USER,
      INCLUDES.TEMPLATE,
    ].join(',');

    const results = await clientsApi.fetchList({ ...filters, include });
    commit(SET_CLIENTS_ITEMS, results.results);
  },

  /**
   * Load lookup providers list if not loaded yet.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Clients state.
   */
  [LOAD_PROVIDERS]: async ({ commit, state }) => {
    const { providers } = state;
    if (!providers.length) {
      const result = await lookupApi.fetchProviders();
      commit(SET_PROVIDERS, result.results);
    }
  },

  /**
   * Fetch list of active templates.
   *
   * @param commit - State commit.
   * @param state - Clients state.
   */
  [LOAD_TEMPLATES]: async ({ commit, state }) => {
    const result = await templatesApi.getList(
      DELETION_STATUSES.ACTIVE,
      state.templatesSortBy,
      true,
    );
    commit(SET_TEMPLATES, result.results);
  },

  /**
   * Load lookup nemsis agencies list if not loaded yet.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Clients state.
   */
  [LOAD_NEMSIS_AGENCIES]: async ({ commit, state }) => {
    const { nemsisAgencies } = state;
    if (!nemsisAgencies.length) {
      const result = await lookupApi.fetchNemsisAgencies();
      commit(SET_NEMSIS_AGENCIES, result.results);
    }
  },
};
