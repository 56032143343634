export const SET_TEMPLATES_LOADING = 'SET_TEMPLATES_LOADING';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_TEMPLATES_PAGINATION = 'SET_TEMPLATES_PAGINATION';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_SHOW_EDIT_DIALOG = 'SET_SHOW_EDIT_DIALOG';
export const SET_ALL_TEMPLATES = 'SET_ALL_TEMPLATES';
export const SET_TEMPLATES_SEARCH_PARAMS = 'SET_TEMPLATES_SEARCH_PARAMS';

export default {
  /**
   * Set flag then data for templates table are loading.
   *
   * @param state - Template's state.
   * @param {boolean} data - True if data are loading, else false.
   */
  [SET_TEMPLATES_LOADING]: (state, data) => {
    state.loading = data;
  },

  /**
   * Save list of templates.
   *
   * @param state - Template's state.
   * @param {Array} data - List of templates from api.
   */
  [SET_TEMPLATES]: (state, data) => {
    state.templates = [...data.results];
    state.paginationInfo = { ...data.pagination };
  },

  /**
   * Save list of templates.
   *
   * @param state - Template's state.
   * @param {Array} data - List of templates from api.
   */
  [SET_ALL_TEMPLATES]: (state, data) => {
    state.allTemplates = [...data.results];
  },

  /**
   * Save information about pagination on template's page.
   *
   * @param state - Template's state.
   * @param {Object} data - Pagination info.
   */
  [SET_TEMPLATES_PAGINATION]: (state, data) => {
    state.pagination = data;
  },

  /**
   * Save selected template.
   *
   * @param state - Template's state.
   * @param {Object} data - Selected template.
   */
  [SET_SELECTED_TEMPLATE]: (state, data) => {
    state.selectedTemplate = data;
  },

  /**
   * Set flag for open edit template popup.
   *
   * @param state - Template's state.
   * @param {boolean} data - True for open popup, false for close.
   */
  [SET_SHOW_EDIT_DIALOG]: (state, data) => {
    state.showEditPopup = data;
  },

  /**
   * Save params for template's search.
   *
   * @param state - Template's state.
   * @param {Object} data - Search params.
   */
  [SET_TEMPLATES_SEARCH_PARAMS]: (state, data) => {
    state.search = data;
  },
};
