import api from './api';

export default {
  /**
   * Get faxes list from API according to filters and pagination/sorting params.
   * @param filters Filter by clientId, sequenceNumber, status, dateFrom, dateTo
   * @param tableParams Pagination and sorting params for table
   * @returns {*}
   */
  getFaxesList(filters, tableParams) {
    return api()
      .get('faxes', {
        params: {
          ...filters,
          page: tableParams.page,
          per_page: tableParams.itemsPerPage,
          order_by: tableParams.sortBy[0],
          sort_order: tableParams.sortDesc[0] ? 'desc' : 'asc',
        },
      })
      .then(response => response.data);
  },

  /**
   * Get statuses list for fax.
   * @returns {*}
   */
  getFaxStatuses() {
    return api()
      .get('faxes/statuses')
      .then(response => response.data);
  },

  /**
   * Resend fax.
   * @param {object} fax
   * @param {number} fax.sendId Fax send ID.
   * @returns {Promise}
   */
  resendFax(fax) {
    return api().post(`faxes/${fax.sendId}/resend`);
  },
};
