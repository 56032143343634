import faxesApi from '@/api/faxes';
import { SET_FAXES, SET_STATUSES, SET_RESEND_SUCCESS_STATUS } from './mutations';

export const GET_FAXES = 'GET_FAXES';
export const RESEND_FAX = 'RESEND_FAX';
export const GET_STATUSES = 'GET_STATUSES';

export default {
  /**
   * Get fax list and pagination from API.
   */
  [GET_FAXES]: async ({ state, commit }) => {
    const { filters, tableParams } = state;
    const results = await faxesApi.getFaxesList(filters, tableParams);

    commit(SET_FAXES, results);

    return results;
  },

  /**
   * Get list of statuses for fax.
   */
  [GET_STATUSES]: async ({ commit }) => {
    const results = await faxesApi.getFaxStatuses();

    commit(SET_STATUSES, results);

    return results;
  },

  /**
   * Resend fax if status error.
   */
  [RESEND_FAX]: async ({ commit }, fax) => {
    let results;
    try {
      results = await faxesApi.resendFax(fax);

      commit(SET_RESEND_SUCCESS_STATUS, { success: true });
    } catch (error) {
      // For display error message in popup.
      if (error.response.data && error.response.data.message) {
        commit(SET_RESEND_SUCCESS_STATUS, { success: false, message: error.response.data.message });
      } else {
        throw error;
      }
    }

    return results;
  },
};
