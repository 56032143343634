<template>
  <v-layout
    v-if="!user"
    align-center
    justify-center
  >
    <div style="max-width: 650px; width: 100%">
      <main-logo></main-logo>
      <login-form
          v-if="currentForm === 'login'"
          ref="loginForm"
          :trust-browser.sync="trustBrowser"
          :token.sync="token"
          :has-verified-phone.sync="hasVerifiedPhone"
          :has-verified-email.sync="hasVerifiedEmail"
          :current-ip.sync="currentIp"
          @smsCodeSent="showCheckSmsCodeForm"
          @emailCodeSent="showCheckEmailCodeForm"
          @suggestSaveIp="showSuggestionSaveIpForm"
          @hospitalAuthRestricted="showCreateHospitalUserForm"
          @completeClientUser="showCompleteClientUserForm"
      />
      <suggest-to-save-ip-form
          v-if="currentForm === 'saveIp'"
          :token.sync="token"
          :current-ip="currentIp"
          :confirm-ip.sync="confirmIp"
          :has-verified-phone="hasVerifiedPhone"
          :has-verified-email="hasVerifiedEmail"
          @backToLogin="backToLogin"
          @smsCodeSent="showCheckSmsCodeForm"
          @emailCodeSent="showCheckEmailCodeForm"
      />
      <verification-sms-code-form
          v-if="currentForm === 'verifySms'"
          :trust-browser.sync="trustBrowser"
          :token.sync="token"
          :has-verified-email="hasVerifiedEmail"
          :confirm-ip="confirmIp"
          @backToLogin="backToLogin"
          @emailCodeSent="showCheckEmailCodeForm"
      />
      <verification-email-code-form
          v-if="currentForm === 'verifyEmail'"
          :trust-browser.sync="trustBrowser"
          :token.sync="token"
          :confirm-ip="confirmIp"
          @backToLogin="backToLogin"
      />
      <create-hospital-user-form
          v-if="currentForm === 'createHospitalUser'"
          :token.sync="token"
          @backToLogin="backToLogin"
      />
      <complete-client-user-form
          v-if="currentForm === 'completeClientUser'"
          :token.sync="token"
          @backToLogin="backToLogin"
      />
    </div>
  </v-layout>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm';
import MainLogo from '@/components/MainLogo';
import authenticatedMixins from '@/mixins/authenticatedMixins';
import SuggestToSaveIpForm from '@/components/Login/SuggestToSaveIpForm';
import { SET_TITLE } from '@/store/mutations';
import VerificationSmsCodeForm from '@/components/Login/VerificationSmsCodeForm';
import VerificationEmailCodeForm from '@/components/Login/VerificationEmailCodeForm';
import CreateHospitalUserForm from '@/components/Login/CreateHospitalUserForm';
import CompleteClientUserForm from '@/components/Login/CompleteClientUserForm';

export default {
  components: {
    CompleteClientUserForm,
    CreateHospitalUserForm,
    VerificationEmailCodeForm,
    VerificationSmsCodeForm,
    SuggestToSaveIpForm,
    LoginForm,
    MainLogo,
  },
  mixins: [authenticatedMixins],
  data() {
    return {
      trustBrowser: true,
      token: null,
      hasVerifiedPhone: false,
      hasVerifiedEmail: false,
      currentIp: null,
      currentForm: 'login',
      confirmIp: null,
    };
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'Medic-Clipboard Login');
  },
  created() {
    const trustBrowser = localStorage.getItem('trustBrowser');
    if (trustBrowser !== null) {
      this.trustBrowser = trustBrowser === '1';
    }
  },
  watch: {
    trustBrowser() {
      const value = this.trustBrowser ? '1' : '0';
      localStorage.setItem('trustBrowser', value);
    },
  },
  methods: {
    showCheckSmsCodeForm() {
      this.currentForm = 'verifySms';
    },
    showCheckEmailCodeForm() {
      this.currentForm = 'verifyEmail';
    },
    showSuggestionSaveIpForm() {
      this.currentForm = 'saveIp';
    },
    showCreateHospitalUserForm() {
      this.currentForm = 'createHospitalUser';
    },
    showCompleteClientUserForm() {
      this.currentForm = 'completeClientUser';
    },
    backToLogin(userName = null) {
      this.currentForm = 'login';
      this.token = null;
      this.currentIp = null;
      this.confirmIp = null;
      this.hasVerifiedPhone = false;
      this.hasVerifiedEmail = false;
      if (userName) {
        this.$nextTick(async () => {
          this.$refs.loginForm.setUserName(userName);
        });
      }
    },
  },
};
</script>
