import _ from 'lodash';

export default {
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    pagination: {
      get() {
        return this.entity.pagination;
      },
      set(value) {
        if (!_.isEqual(value, this.entity.pagination)) {
          this.setPagination(value);
          this.reloadItems();
        }
      },
    },
    filters() {
      return this.entity.filters;
    },
  },

  mounted() {
    this.reloadItems();
  },

  methods: {
    /**
     * Handler of filters update event.
     *
     * @param {Object} filters - Filtering params object
     */
    filtersUpdate(filters) {
      this.pagination.page = 1;
      this.setFilters(filters);
      this.reloadItems();
    },

    /**
     * Reloads paginated list for current filter.
     */
    async reloadItems() {
      this.loading = true;

      try {
        await this.loadItems();
      } finally {
        this.loading = false;
      }
    },
  },
};
