<template>
  <span>
    {{ field.name || condition.field }} {{ eqCharacter }} "<b>{{ getValue() }}</b>"
  </span>
</template>
<script>
import moment from 'moment';
import SEARCH_FIELD_TYPES from '@/enums/searchFieldTypes';

export default {
  name: 'Eq',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
    eqCharacter: {
      type: String,
      default: '=',
    },
  },
  data() {
    return {
      fieldTypes: SEARCH_FIELD_TYPES,
    };
  },

  methods: {
    getValue() {
      if (this.field.type === this.fieldTypes.BOOLEAN) {
        return this.condition.value ? 'Yes' : 'No';
      }
      if (this.field.type === this.fieldTypes.DATE) {
        return moment.parseZone(this.condition.value).format('MM/DD/YYYY');
      }
      if (this.field.type === this.fieldTypes.DATETIME) {
        return moment.parseZone(this.condition.value).format('MM/DD/YYYY h:mm A');
      }
      if (this.field.type === this.fieldTypes.TIME) {
        return moment.parseZone(`${moment().format('YYYY-MM-DD')}T${this.condition.value}`).format('h:mm A');
      }
      return this.condition.value;
    },
  },
};
</script>
