import api from './api';

export default {
  /**
   * Fetch list of devices.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  fetchList(params) {
    return api()
      .get('/devices', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of client's devices.
   *
   * @return Promise<Object>
   */
  fetchClientDevices() {
    return api()
      .get('/client/devices')
      .then(response => response.data);
  },

  /**
   * Export devices to Excel.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  export(params) {
    return api()
      .get('/devices/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Get team members of unfinished forms.
   *
   * @param {number} deviceId - Identifier of device.
   */
  getTeamMembers(deviceId) {
    return api()
      .get(`/devices/${deviceId}/unfinished-team-members`)
      .then(response => response.data.results);
  },

  /**
   * Hard delete device.
   *
   * @param {number} deviceId - Identifier of device.
   */
  forceDeleteDevice(deviceId) {
    return api().delete(`/devices/${deviceId}/force`);
  },

  /**
   * Deactivate device (soft delete)
   *
   * @param {number} deviceId - Identifier of device.
   */
  deactivateDevice(deviceId) {
    return api().delete(`/devices/${deviceId}`);
  },

  /**
   * Restore device.
   *
   * @param {number} deviceId - Identifier of device.
   */
  restoreDevice(deviceId) {
    return api().post(`/devices/${deviceId}/restore`);
  },

  /**
   * Create new device.
   *
   * @param {Object} device - Device object
   */
  createDevice(device) {
    return api()
      .post('/devices', device)
      .then(response => response.data);
  },

  /**
   * Update device.
   *
   * @param {Object} device - Device object to save
   */
  saveDevice(device) {
    return api()
      .put(`/devices/${device.registryId}`, device)
      .then(response => response.data);
  },
};
