<template>
  <div>
    <v-card class="white elevation-3 ma-4">
      <toolbar
        :loading="loading"
        @search="search"
      />
    </v-card>

    <v-card
        class="white elevation-3 ma-4"
        :loading="loading"
    >
      <v-tabs v-model="tabIndex">
        <v-tab
            v-for="(item, index) in tabItems"
            :key="'tab-' + index"
            :disabled="item.disabled"
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-divider class="mb-5"/>

      <v-tabs-items v-model="tabIndex">
        <v-tab-item>
          <incidents-table
            :items="incidents"
            :total-items="incidentsTotal"
            :loading="!loading && loadingIncidents"
            :show-location-column="true"
            typeData="incidents"
            location-prefix="/incidents/"
            @updatePagination="searchIncidents"
            @goToIncident="goToIncident"
          />
        </v-tab-item>
        <v-tab-item>
          <incidents-table
              :items="duplicates"
              :total-items="duplicatesTotal"
              :loading="!loading && loadingDuplicates"
              :show-location-column="false"
              typeData="duplicates"
              @updatePagination="searchDuplicates"
              @goToIncident="goToDuplicate"
          />
        </v-tab-item>
        <v-tab-item>
          <incidents-table
            :items="transmitted"
            :total-items="transmittedTotal"
            :loading="!loading && loadingTransmitted"
            :show-location-column="false"
            :show-first-name-column="false"
            typeData="transmitted"
            @updatePagination="searchTransmitted"
            @goToIncident="goToIncident"
          />
        </v-tab-item>
        <v-tab-item>
          <incidents-table
              :items="airdropped"
              :total-items="airdroppedTotal"
              :loading="!loading && loadingAirDropped"
              :show-location-column="false"
              :show-first-name-column="false"
              typeData="airdropped"
              @updatePagination="searchAirdropped"
              @goToIncident="goToIncident"
          />
        </v-tab-item>
        <v-tab-item>
          <api-logs-table
              :items="apiLogs"
              :total-items="apiLogsTotal"
              :loading="!loading && loadingApiLogs"
              @updatePagination="searchApiLogs"
              @goToIncident="goToApiLogs"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import entireSearch from '@/api/entireSearch';
import ApiLogsTable from '@/components/IncidentsSystemSearch/ApiLogsTable';
import IncidentsTable from '@/components/IncidentsSystemSearch/IncidentsTable';
import Toolbar from '@/components/IncidentsSystemSearch/Toolbar';
import impersonateMixins from '@/mixins/impersonateMixins';
import { SET_TITLE } from '@/store/mutations';

export default {
  components: {
    Toolbar,
    ApiLogsTable,
    IncidentsTable,
  },

  mixins: [impersonateMixins],

  data() {
    return {
      tabIndex: 0,
      filters: {},
      loading: false,
      loadingIncidents: false,
      loadingDuplicates: false,
      loadingTransmitted: false,
      loadingAirDropped: false,
      loadingApiLogs: false,
      incidentsTotal: 0,
      incidents: [],
      duplicatesTotal: 0,
      duplicates: [],
      transmittedTotal: 0,
      transmitted: [],
      airdroppedTotal: 0,
      airdropped: [],
      apiLogsTotal: 0,
      apiLogs: [],
    };
  },

  computed: {
    tabItems() {
      const items = [];
      items.push({ name: `Incidents (${this.incidentsTotal})`, disabled: this.incidentsTotal === 0 });
      items.push({ name: `Duplicates (${this.duplicatesTotal})`, disabled: this.duplicatesTotal === 0 });
      items.push({ name: `Transmitted (${this.transmittedTotal})`, disabled: this.transmittedTotal === 0 });
      items.push({ name: `AirDropped (${this.airdroppedTotal})`, disabled: this.airdroppedTotal === 0 });
      items.push({ name: `API Logs (${this.apiLogsTotal})`, disabled: this.apiLogsTotal === 0 });
      return items;
    },
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'Incident System Search');
  },

  methods: {
    /**
     * Search all entities.
     *
     * @param {Object} filters - Filters to search.
     */
    async search(filters) {
      this.filters = filters;
      try {
        this.loading = true;
        const promises = [
          this.searchIncidents(),
          this.searchDuplicates(),
          this.searchTransmitted(),
          this.searchAirdropped(),
          this.searchApiLogs(),
        ];

        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Search regular/deleted/unfinished incidents.
     *
     * @param {Object} pagination - Pagination data.
     */
    async searchIncidents(pagination = {}) {
      try {
        this.loadingIncidents = true;
        const params = this.prepareParams(pagination);
        const response = await entireSearch.searchIncidents(params);
        this.incidents = this.setDownloadingProperty(response.results);
        this.incidentsTotal = response.pagination.total;
      } finally {
        this.loadingIncidents = false;
      }
    },

    /**
     * Search duplicated incidents.
     *
     * @param {Object} pagination - Pagination data.
     */
    async searchDuplicates(pagination = {}) {
      try {
        this.loadingDuplicates = true;
        const params = this.prepareParams(pagination);
        const response = await entireSearch.searchDuplicates(params);
        this.duplicates = this.setDownloadingProperty(response.results);
        this.duplicatesTotal = response.pagination.total;
      } finally {
        this.loadingDuplicates = false;
      }
    },

    /**
     * Search transmitted.
     *
     * @param {Object} pagination - Pagination data
     */
    async searchTransmitted(pagination = {}) {
      try {
        this.loadingTransmitted = true;
        const params = this.prepareParams(pagination);
        const response = await entireSearch.searchTransmitted(params);
        this.transmitted = this.setDownloadingProperty(response.results);
        this.transmittedTotal = response.pagination.total;
      } finally {
        this.loadingTransmitted = false;
      }
    },

    /**
     * Search transmitted.
     *
     * @param {Object} pagination - Pagination data
     */
    async searchAirdropped(pagination = {}) {
      try {
        this.loadingAirDropped = true;
        const params = this.prepareParams(pagination);
        const response = await entireSearch.searchAirdropped(params);
        this.airdropped = this.setDownloadingProperty(response.results);
        this.airdroppedTotal = response.pagination.total;
      } finally {
        this.loadingAirDropped = false;
      }
    },

    /**
     * Set downloading property for items.
     *
     * @param {Array} items - List of items
     *
     * @returns {Array}
     */
    setDownloadingProperty(items) {
      const list = [];

      items.forEach(item => {
        if (item instanceof Object) {
          const i = _.clone(item);
          i.isDownloading = false;
          list.push(i);
        }
      });

      return list;
    },

    /**
     * Search transmitted.
     *
     * @param {Object} pagination - Pagination data
     */
    async searchApiLogs(pagination = {}) {
      try {
        this.loadingApiLogs = true;
        const params = this.prepareParams(pagination);
        const response = await entireSearch.searchApiLogs(params);
        this.apiLogs = response.results;
        this.apiLogsTotal = response.pagination.total;
      } finally {
        this.loadingApiLogs = false;
      }
    },

    /**
     * Prepare params for searching.
     *
     * @param {Object} pagination - Pagination data.
     *
     * @returns {*}
     */
    prepareParams(pagination = {}) {
      const params = _.cloneDeep(this.filters);
      params.page = pagination ? pagination.page : 1;
      params.per_page = pagination ? pagination.itemsPerPage : 25;

      return params;
    },

    /**
     * Impersonate and navigate to search incidents page.
     *
     * @param {Object} incident - Incident to search.
     */
    goToIncident(incident) {
      const route = `incidents_${incident.searchType}`;
      this.impersonateToRoute(
        incident.clientId,
        route,
        {
          sequenceNumber: incident.II_SequenceNumber,
        },
      );
    },

    /**
     * Go to duplicates page and search incident.
     *
     * @param {Object} incident - Incident to search.
     */
    goToDuplicate(incident) {
      const props = this.$router.resolve({
        name: 'duplicateIncidents',
      });
      const url = `${props.href}?clientId=${incident.clientId}&sequenceNumber=${incident.II_SequenceNumber}`;
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    },

    /**
     * Go to api audit page.
     *
     * @param {Object} log - Log to search
     */
    goToApiLogs(log) {
      const props = this.$router.resolve({ name: 'apiAuditLog' });
      const url = `${props.href}?method=${log.methodName}&significantData=${log.significantData}`;
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    },
  },
};
</script>
