<template>
  <div>
    <v-text-field
        v-model="timeValue"
        prepend-inner-icon="mdi-clock-time-four-outline"
        label="Time"
        readonly
        clearable
        @click="openTimePicker"
    />
    <datetime
        ref="time"
        v-model="timeForPicker"
        type="time"
        input-class="d-none"
        class="theme-red"
        use12-hour
    />
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime';
import moment from 'moment';

export default {
  name: 'Time',
  components: {
    Datetime,
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    timeForPicker: {
      get() {
        const currentDate = moment().format('YYYY-MM-DD');
        return `${currentDate}T${this.value}`;
      },
      set(value) {
        let newValue = null;
        if (value) {
          newValue = moment(value).format('HH:mm:ss');
        }
        this.$emit('input', newValue);
      },
    },
    timeValue: {
      get() {
        const currentDate = moment().format('YYYY-MM-DD');
        return this.value
          ? moment(`${currentDate} ${this.value}`).format('hh:mm A')
          : null;
      },
      set(value) {
        let newValue = null;
        if (value) {
          newValue = moment(value).format('HH:mm:ss');
        }
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    /**
     * Handler of click by schedule date input.
     */
    openTimePicker(event) {
      this.$refs.time.open(event);
    },
  },
};
</script>
