<template>
  <view-resolver>
    <last-imported-incidents-admin slot="adminView"></last-imported-incidents-admin>
    <last-imported-incidents-client slot="clientView"></last-imported-incidents-client>
  </view-resolver>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import LastImportedIncidentsAdmin from './LastImportedIncidentsAdmin';
import LastImportedIncidentsClient from './LastImportedIncidentsClient';

export default {
  components: {
    ViewResolver,
    LastImportedIncidentsAdmin,
    LastImportedIncidentsClient,
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('importedIncidents.header'));
  },
};
</script>

<style>
.incidents .v-tabs-bar {
  display: none;
}
</style>
