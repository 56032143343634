import api from './api';

export default {
  /**
   * Create new invoice.
   *
   * @param {Object} data - Data of invoice
   *
   * @return Promise<Object>
   */
  create(data) {
    return api()
      .post('/invoices', data)
      .then(response => response.data);
  },

  /**
   * Update invoice.
   *
   * @param {Number} invoiceId - Invoice identifier
   * @param {Object} data - Data of invoice
   *
   * @return Promise<Object>
   */
  update(invoiceId, data) {
    return api()
      .put(`/invoices/${invoiceId}`, data)
      .then(response => response.data);
  },

  /**
   * Get list of client's invoices.
   *
   * @return Promise<Object>
   */
  getInvoices(clientId) {
    return api()
      .get('/invoices', { params: { clientId, include: 'client' } })
      .then(response => response.data);
  },

  /**
   * Get list of last invoices separated by clients.
   *
   * @return Promise<Object>
   */
  getLastInvoices() {
    return api()
      .get('/invoices/latest', { params: { include: 'client' } })
      .then(response => response.data);
  },

  /**
   * Get list of last invoices separated by clients.
   *
   * @param {Number} clientId - Client identifier
   * @param {String} from - Fiscal date from
   * @param {String} to - Fiscal date to
   *
   * @return Promise<Object>
   */
  getByFiscalRange(clientId, from, to) {
    return api()
      .get('/invoices/by-fiscal-range', { params: { clientId, from, to } })
      .then(response => response.data);
  },

  /**
   * Fetch invoice header data.
   *
   * @return Promise<Object>
   */
  getHeader() {
    return api()
      .get('/invoices/header')
      .then(response => response.data);
  },

  /**
   * Get AVG PDF generation time.
   *
   * @return Promise<Object>
   */
  getAvgGenerationTime() {
    return api()
      .get('/invoices/avg-generation-time')
      .then(response => response.data);
  },

  /**
   * Save invoice header data.
   *
   * @return Promise<Object>
   */
  saveHeader(data) {
    return api()
      .put('/invoices/header', data)
      .then(response => response.data);
  },

  /**
   * Get projected incidents for period.
   *
   * @return Promise<Object>
   */
  getProjections(clientId, from, to) {
    return api()
      .get('/invoices/projections', {
        params: {
          clientId,
          from,
          to,
        },
      })
      .then(response => response.data);
  },

  /**
   * Get count incidents for period.
   *
   * @return Promise<Object>
   */
  countIncidentsByPeriod(clientId, from, to) {
    return api()
      .get('/invoices/count-incidents-by-period', {
        params: {
          clientId,
          from,
          to,
        },
      })
      .then(response => response.data);
  },
};
