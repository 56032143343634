const USER_ROLE_NAMES = {
  SUPER_ADMIN: 'Super User',
  CLIENT_ADMIN: 'Admin',
  CLIENT_CAPTAIN_USER: 'Captain User',
  CLIENT_FIREFIGHTER_USER: 'Firefighter User',
  CLIENT_QA_USER: 'QA/QI',
  CLIENT_CE_USER: 'User',
  HOSPITAL_USER: 'User',
  HOSPITAL_ADMIN: 'Admin',
};

export default USER_ROLE_NAMES;
