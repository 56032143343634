<template>
  <span>
    {{ field.name || condition.field }} like "<b>{{ condition.value }}</b>"
  </span>
</template>
<script>

export default {
  name: 'Like',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
};
</script>
