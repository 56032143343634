export default [
  { text: '5 sec', value: 5 },
  { text: '10 sec', value: 10 },
  { text: '15 sec', value: 15 },
  { text: '20 sec', value: 20 },
  { text: '25 sec', value: 25 },
  { text: '30 sec', value: 30 },
  { text: '35 sec', value: 35 },
  { text: '40 sec', value: 40 },
  { text: '45 sec', value: 45 },
  { text: '50 sec', value: 50 },
  { text: '55 sec', value: 55 },
  { text: '60 sec', value: 60 },
];
