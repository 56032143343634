import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#E04337',
        secondary: '#FFCCBC',
        accent: '#E64A19',
        error: '#f44336',
        warning: '#ffeb3b',
        info: '#146F8C',
        success: '#008000',
        grey: '#e6e6e6',
        white: '#FFFFFF',
        red: '#d0021b',
        blue: '#4a90e2',
        gray: '#d8d8d8',
        green: '#4caf50',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
