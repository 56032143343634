<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
      <v-card>
        <v-card-title>Save new preset</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="name"
            v-validate="{ required: true }"
            name="name"
            :error-messages="errors.collect('name')"
            label="Preset Name"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';
import presetManager from '@/api/presetManager';

export default {
  name: 'SavePresetPopup',
  mixins: [errorAlertMixins],
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      name: null,
      saving: false,
      dictionary: {
        attributes: {
          name: 'Preset Name',
        },
      },
    };
  },
  methods: {
    /**
     * Show save preset dialog
     */
    show() {
      this.dialog = true;
      this.name = null;
    },

    /**
     * Save preset
     */
    async save() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        const result = await presetManager.create({
          name: this.name,
          data: this.data,
          type: this.type,
        });
        this.$emit('saved', result);
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
