<template>
  <v-card class="elevation-20">
    <v-toolbar
        dark
        color="primary"
    >
      <v-toolbar-title class="font-weight-medium">Create your own account</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!isRegistered">
      <v-form @keyup.native.enter="register">
        <div class="text-center">A generic username and password can not longer used
          due to HIPAA regulations. Please create a username and password
          that is unique to you.</div>

        <div class="text-center">Additional users can create their own account by first
          using the generic username and password, after which they too will also be
          prompted to create their own account.</div>

        <v-alert
            :value="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>

        <v-layout
            wrap
        >
          <v-flex
            xs12
            sm12
            md12
            lg12
          >
            <v-text-field
                ref="userName"
                v-model="user.userName"
                v-validate="'required'"
                :error-messages="errors.collect('userName')"
                prepend-inner-icon="person"
                name="userName"
                placeholder="User Name"
                type="text"
            />
          </v-flex>

          <v-flex
              xs12
              sm5
              md5
              lg5
          >
            <vue-tel-input-vuetify
                v-model="phoneModel"
                label=""
                placeholder="Phone Number"
                :preferred-countries="['US']"
                :disabled-fetching-country="true"
                :error-messages="phoneNumberErrorMessages"
                name="phone"
                outer="mdi-send"
                mode="international"
                @input="updatePhone"
            />
          </v-flex>
          <v-flex
              xs12
              sm6
              md6
              lg6
              offset-sm1
              offset-md1
              offset-lg1
          >
            <v-text-field
                v-model="user.email"
                v-validate="{ required: true, email: true }"
                :error-messages="errors.collect('email')"
                prepend-inner-icon="mdi-at"
                name="email"
                placeholder="Email"
                type="text"
                maxlength="40"
            />
          </v-flex>

          <v-flex
            xs12
            sm5
            md5
            lg5
          >
            <v-text-field
                ref="firstName"
                v-model="user.firstName"
                v-validate="'required'"
                :error-messages="errors.collect('firstName')"
                name="firstName"
                placeholder="First Name"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md6
            lg6
            offset-sm1
            offset-md1
            offset-lg1
          >
            <v-text-field
                ref="lastName"
                v-model="user.lastname"
                v-validate="'required'"
                :error-messages="errors.collect('lastName')"
                name="lastName"
                placeholder="Last Name"
            />
          </v-flex>

          <v-flex
              xs12
              sm5
              md5
              lg5
          >
            <v-text-field
                ref="password"
                v-model="user.password"
                v-validate="{ required: true, regex: passwordRegexp }"
                :error-messages="errors.collect('password')"
                prepend-inner-icon="lock"
                name="password"
                placeholder="Password"
                type="password"
            />
          </v-flex>
          <v-flex
              xs12
              sm6
              md6
              lg6
              offset-sm1
              offset-md1
              offset-lg1
          >
            <v-text-field
                ref="passwordConfirm"
                v-model="passwordConfirm"
                v-validate="'required|confirmed:password'"
                :error-messages="errors.collect('passwordConfirm')"
                prepend-inner-icon="lock"
                name="passwordConfirm"
                placeholder="Retype Password"
                type="password"
            />
          </v-flex>
          <v-flex class="mt-3">
            <i>{{ $t('general.passwordFormat') }}</i>
          </v-flex>
        </v-layout>

      </v-form>
    </v-card-text>
    <v-card-text v-if="isRegistered">
      Your account is registered. Please, use your
      User Name "<b>{{ registeredUser.userName }}</b>" to authenticate to the system.
    </v-card-text>
    <v-card-actions>
      <a
          v-if="!isRegistered"
          class="ml-3"
          @click="$emit('backToLogin')"
      >Back to Login</a>

      <v-spacer/>

      <v-btn
          v-if="!isRegistered"
          :loading="saving"
          class="login__button"
          color="primary"
          @click="register"
      >
        Create New user
      </v-btn>
      <v-btn
          v-if="isRegistered"
          class="login__button"
          color="primary"
          @click="$emit('backToLogin', registeredUser.userName)"
      >
        Sign in
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import hospitals from '@/api/hospitals';

export default {
  mixins: [errorAlertMixins],
  name: 'CreateHospitalUserForm',
  props: {
    token: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      saving: false,
      user: {
        userName: null,
        email: null,
        phone: null,
        firstName: null,
        lastname: null,
        password: null,
      },
      passwordConfirm: null,
      phoneModel: null,
      phoneIsValid: true,
      dictionary: {
        attributes: {
          userName: 'User Name',
          email: 'Email',
          firstName: 'First Name',
          lastName: 'Last Name',
          passwordConfirm: 'Retype Password',
        },
      },
      passwordRegexp: validatePasswordRegexp,
      isRegistered: false,
      registeredUser: null,
    };
  },
  computed: {
    phoneNumberErrorMessages() {
      const messages = this.errors.collect('phone');
      if (!this.phoneIsValid) {
        messages.push('The Phone field is invalid');
      }
      return messages;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Update phone.
     *
     * @param {String} number - Phone number
     * @param {Object} data - Phone data
     */
    updatePhone(number, data) {
      this.phoneIsValid = data.isValid;
      if (data.isValid === true) {
        this.user.phone = data.number.e164;
      } else {
        this.user.phone = '';
      }
    },

    /**
     * Validates phone.
     *
     * @return {Boolean}
     */
    validatePhone() {
      if (!this.user.phone) {
        this.phoneIsValid = false;
      }
      return this.phoneIsValid;
    },

    async register() {
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
      this.validatePhone();
      if (!await this.$validator.validateAll() || !this.phoneIsValid) {
        return;
      }
      this.saving = true;
      try {
        const params = _.cloneDeep(this.user);
        params.token = this.token;
        this.registeredUser = await hospitals.createUser(params);
        this.isRegistered = true;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
