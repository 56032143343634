<template>
  <v-row>
    <v-col
      xl="6"
      lg="12"
    >
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <h3>
          {{ $t('profileSettings.notifications.modificationHeader') }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-information-outline</v-icon>
            </template>
            <span style="text-align: center">
              This will allow a department administrator to be<br />
              notified when a report, that has previously been submitted<br />
              to the server, has been modified on the website by a user.
            </span>
          </v-tooltip>
        </h3>

        <v-menu
          ref="notificationTimePickerVisible"
          v-model="notificationTimePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="reportNotificationTime"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportNotificationTime"
              :label="$t('profileSettings.timePickerLabel')"
              :class="$style.textField"
              prepend-icon="access_time"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="notificationTimePickerVisible"
            v-model="reportNotificationTime"
          >
            <v-spacer/>
            <v-btn
              text
              color="primary"
              @click="saveReportNotificationTime"
            >
              {{ $t('buttons.ok') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="notificationTimePickerVisible = false"
            >
              {{ $t('buttons.cancel') }}
            </v-btn>
          </v-time-picker>
        </v-menu>
        <v-divider></v-divider>
        <v-form
          :class="$style.emailInputContainer"
          @submit.prevent="addEmailNotification"
        >
          <v-text-field
            v-model="newEmail"
            v-validate="{ email: true }"
            autocomplete="email"
            color="blue darken-2"
            :label="$t('profileSettings.newEmailBtn')"
            :class="$style.textField"
            :error-messages="errors.collect('newEmail')"
            name="newEmail"
          ></v-text-field>
          <v-btn
            color="error"
            type="submit"
            :disabled="!!errors.collect('newEmail').length"
            :class="$style.emailAddButton"
          >
            {{ $t('buttons.add') }}
          </v-btn>
        </v-form>
        <div :class="$style.emailsContainer">
          <v-chip
            v-for="email of notificationEmails"
            :key="email"
            :class="$style.emailChip"
            close
            @click:close="showDeleteEmailPopup(email, 'notificationEmails')"
          >
            {{ email }}
          </v-chip>
        </div>
      </v-card>
    </v-col>
    <v-col
        xl="6"
        lg="12"
    >
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <h3>
          {{ $t('profileSettings.notifications.deletedUniqueFormsHeader') }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  small
                  v-on="on"
              >mdi-information-outline</v-icon>
            </template>
            <span style="text-align: center">
              This will allow a department administrator to be notified<br />
              about removed unique forms for chosen frequency.
            </span>
          </v-tooltip>
        </h3>
        <v-select
            v-model="frequencyDeletedUniqueForms"
            :items="frequencies"
            :hint="'Note: time is 0600 (PST) for all options. Query period: ' + getPeriod()"
            label="Notification Frequency"
            persistent-hint
            item-value="value"
            item-text="text"
            single-line
        />
        <v-divider/>
        <v-form
            :class="$style.emailInputContainer"
            @submit.prevent="addEmailDeletedUniqueForms"
        >
          <v-text-field
              v-model="newEmailDeletedUniqueForms"
              v-validate="{ email: true }"
              autocomplete="email"
              color="blue darken-2"
              :label="$t('profileSettings.newEmailBtn')"
              :class="$style.textField"
              :error-messages="errors.collect('newEmailDeletedUniqueForms')"
              name="newEmailDeletedUniqueForms"
          ></v-text-field>
          <v-btn
              color="error"
              type="submit"
              :disabled="!!errors.collect('newEmailDeletedUniqueForms').length"
              :class="$style.emailAddButton"
          >
            {{ $t('buttons.add') }}
          </v-btn>
        </v-form>
        <div :class="$style.emailsContainer">
          <v-chip
              v-for="email of deletedUniqueFormsEmails"
              :key="email"
              :class="$style.emailChip"
              close
              @click:close="showDeleteEmailPopup(email, 'deletedUniqueFormsEmails')"
          >
            {{ email }}
          </v-chip>
        </div>
      </v-card>
    </v-col>

    <v-col
      xl="6"
      lg="12"
    >
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <h3>
          {{ $t('profileSettings.notifications.reportsOnDeviceHeader') }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  small
                  v-on="on"
              >mdi-information-outline</v-icon>
            </template>
            <span style="text-align: center">
              This will allow a department administrator to be notified<br />
              when an incomplete has been left on the iPad.
            </span>
          </v-tooltip>
        </h3>
        <v-menu
          ref="deviceTimePickerVisible"
          v-model="deviceTimePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="reportOnDeviceTime"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportOnDeviceTime"
              :label="$t('profileSettings.timePickerLabel')"
              :class="$style.textField"
              prepend-icon="access_time"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="deviceTimePickerVisible"
            v-model="reportOnDeviceTime"
          >
            <v-spacer/>
            <v-btn
              text
              color="primary"
              @click="saveDeviceNotificationTime"
            >
              {{ $t('buttons.ok') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="deviceTimePickerVisible = false"
            >
              {{ $t('buttons.cancel') }}
            </v-btn>
          </v-time-picker>
        </v-menu>
        <v-divider></v-divider>
        <v-data-table
          :headers="$t('profileSettings.notifications.tableHeader')"
          :items="modifiedRules(deviceNotificationRules)"
          :items-per-page="5"
        >
          <template v-slot:top>
            <div :class="$style.addBtnContainer">
              <v-btn
                color="error"
                @click="$emit('showEditDeviceNotificationPopup')"
              >{{ $t('buttons.add') }}</v-btn>
            </div>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon @click="$emit('showEditDeviceNotificationPopup', item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.remove="{ item }">
            <v-icon @click="showDeleteRowPopup(item, false)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col
      xl="6"
      lg="12"
    >
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <h3>
          {{ $t('profileSettings.notifications.unfinishedReportsHeader') }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  small
                  v-on="on"
              >mdi-information-outline</v-icon>
            </template>
            <span style="text-align: center">
              This will allow a department administrator to be notified<br />
              when a report, that was transmitted to another unit via<br />
              the 'Remote Handoff' feature, has not been received and<br />
              has not been completed.
            </span>
          </v-tooltip>
        </h3>
        <v-menu
          ref="transmittedTimePickerVisible"
          v-model="transmittedTimePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="transmittedReportTime"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="transmittedReportTime"
              :label="$t('profileSettings.timePickerLabel')"
              :class="$style.textField"
              prepend-icon="access_time"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="transmittedTimePickerVisible"
            v-model="transmittedReportTime"
          >
            <v-spacer/>
            <v-btn
              text
              color="primary"
              @click="saveTransmittedNotificationTime"
            >
              {{ $t('buttons.ok') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="transmittedTimePickerVisible = false"
            >
              {{ $t('buttons.cancel') }}
            </v-btn>
          </v-time-picker>
        </v-menu>
        <v-divider></v-divider>
        <v-data-table
          :headers="$t('profileSettings.notifications.tableHeader')"
          :items="modifiedRules(transmittedNotificationRules)"
          :items-per-page="5"
        >
          <template v-slot:top>
            <div :class="$style.addBtnContainer">
              <v-btn
                color="error"
                @click="$emit('showEditTransmittedNotificationPopup')"
              >{{ $t('buttons.add') }}</v-btn>
            </div>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon @click="$emit('showEditTransmittedNotificationPopup', item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.remove="{ item }">
            <v-icon @click="showDeleteRowPopup(item, true)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col
        xl="6"
        lg="12"
    >
      <v-card
          elevation="3"
          class="mt-4 mx-4"
          :class="$style.card"
      >
        <h3>
          {{ $t('profileSettings.notifications.adminCeNotificationsHeader') }}
        </h3>
        <v-select
            v-model="ceAdminsNotifiable"
            :items="ceAdminNotifications"
            :return-object="false"
            label="Notifiable admins"
            item-text="fullName"
            item-value="registryID"
            multiple
            chips
            deletable-chips
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!ceAdminsNotifiableModel"
            :loading="ceAdminsNotifiableSaving"
            color="primary"
            @click="saveCeAdminNotifiable"
          >
          Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <confirmation ref="confirmationPopup"/>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Confirmation from '@/components/Confirmation';
import {
  UPDATE_REPORT_NOTIFICATION,
  GET_DEVICE_NOTIFICATION,
  DELETE_NOTIFICATION_RULE,
  UPDATE_DEVICE_NOTIFICATION_TIME,
  GET_TRANSMITTED_NOTIFICATION,
  UPDATE_TRANSMITTED_NOTIFICATION_TIME,
  UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION,
  UPDATE_CE_ADMINS_NOTIFIABLE,
} from '@/store/ProfileSettings/actions';
import {
  SET_NOTIFICATION_TIME,
  SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY,
} from '@/store/ProfileSettings/mutations';
import FREQUENCIES from '@/enums/frequencies';
import moment from 'moment';

export default {
  name: 'NotificationsSettings',
  components: {
    Confirmation,
  },
  data() {
    return {
      /**
       * Notification timePicker visible flag.
       */
      notificationTimePickerVisible: false,
      /**
       * Notification on device timePicker visible flag.
       */
      deviceTimePickerVisible: false,
      /**
       * Notification transmitted timePicker visible flag.
       */
      transmittedTimePickerVisible: false,
      /**
       * New email to add to array.
       */
      newEmail: '',

      newEmailDeletedUniqueForms: '',
      frequencies: [
        { value: FREQUENCIES.DAILY, text: 'Every Day' },
        { value: FREQUENCIES.WEEKLY, text: 'Every Monday' },
        { value: FREQUENCIES.MONTHLY, text: 'First of Every Month' },
      ],
      dictionary: {
        attributes: {
          newEmailDeletedUniqueForms: 'New email',
        },
      },

      ceAdminsNotifiableModel: null,
      ceAdminsNotifiableSaving: false,
    };
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  computed: {
    ...mapState('profileSettings', {
      notificationEmails: state => state.reportNotification.emails,
      notificationTime: state => state.reportNotification.time,
      deviceNotificationTime: state => state.deviceNotification.time,
      deviceNotificationRules: state => state.deviceNotification.rules,
      transmittedNotificationTime: state => state.transmittedNotification.time,
      transmittedNotificationRules: state => state.transmittedNotification.rules,
      deletedUniqueFormsEmails: state => state.deletedUniqueForms.emails,
      deletedUniqueFormsFrequency: state => state.deletedUniqueForms.frequency,
      ceAdminNotifications: state => state.ceAdminNotifications
        .sort((a, b) => a.fullName.localeCompare(b.fullName)),
    }),

    /**
     * Frequency deleted unique forms notification.
     */
    frequencyDeletedUniqueForms: {
      set(newValue) {
        this.setFrequency(newValue);
        this.updateDeletedUniqueFormsNotification(
          { frequency: newValue, emails: this.deletedUniqueFormsEmails },
        );
      },
      get() {
        return this.deletedUniqueFormsFrequency;
      },
    },

    /**
     * Notification time.
     */
    reportNotificationTime: {
      set(newValue) {
        this.setTime({ time: newValue, module: 'reportNotification' });
      },
      get() {
        return this.notificationTime;
      },
    },
    /**
     * REport on device notification time.
     */
    reportOnDeviceTime: {
      set(newValue) {
        this.setTime({ time: newValue, module: 'deviceNotification' });
      },
      get() {
        return this.deviceNotificationTime;
      },
    },
    /**
     * Transmitted notification time.
     */
    transmittedReportTime: {
      set(newValue) {
        this.setTime({ time: newValue, module: 'transmittedNotification' });
      },
      get() {
        return this.transmittedNotificationTime;
      },
    },

    ceAdminNotificationsEnabled() {
      const enabled = [];
      this.ceAdminNotifications.forEach((item) => {
        if (item.ceAdminNotifications === true) {
          enabled.push(item.registryID);
        }
      });
      return enabled;
    },

    ceAdminsNotifiable: {
      set(newValue) {
        this.ceAdminsNotifiableModel = newValue;
      },
      get() {
        return this.ceAdminsNotifiableModel
          ? this.ceAdminsNotifiableModel
          : this.ceAdminNotificationsEnabled;
      },
    },
  },

  methods: {
    ...mapMutations('profileSettings', {
      setTime: SET_NOTIFICATION_TIME,
      setFrequency: SET_DELETED_UNIQUE_FORMS_NOTIFICATION_FREQUENCY,
    }),
    ...mapActions('profileSettings', {
      updateNotification: UPDATE_REPORT_NOTIFICATION,
      loadDeviceNotification: GET_DEVICE_NOTIFICATION,
      loadTransmittedNotification: GET_TRANSMITTED_NOTIFICATION,
      deleteNotificationRule: DELETE_NOTIFICATION_RULE,
      updateDeviceNotificationTime: UPDATE_DEVICE_NOTIFICATION_TIME,
      updateTransmittedNotificationTime: UPDATE_TRANSMITTED_NOTIFICATION_TIME,
      updateDeletedUniqueFormsNotification: UPDATE_DELETED_UNIQUE_FORMS_NOTIFICATION,
      updateCeAdminsNotifiable: UPDATE_CE_ADMINS_NOTIFIABLE,
    }),
    /**
     * Add to API rules data rule (text to display) and personnel - joined emails.
     * @param rules
     * @returns {*}
     */
    modifiedRules(rules) {
      return rules.map(rule => ({
        rule: `${this.getRuleTitle(rule.type)} ≥ ${rule.daysToWaitBeforeSending}`,
        type: rule.type,
        daysToWaitBeforeSending: rule.daysToWaitBeforeSending,
        emails: rule.emails,
        personnel: rule.emails.join(', '),
        ruleId: rule.ruleId,
      }));
    },
    /**
     * Save report notification time to server.
     */
    saveReportNotificationTime() {
      this.$refs.notificationTimePickerVisible.save(this.reportNotificationTime);
      this.updateNotification({
        time: this.reportNotificationTime,
        emails: this.notificationEmails,
      });
    },
    /**
     * Save device notification time to server.
     */
    saveDeviceNotificationTime() {
      this.$refs.deviceTimePickerVisible.save(this.reportOnDeviceTime);
      this.updateDeviceNotificationTime(this.reportOnDeviceTime);
    },
    /**
     * Save transmitted notification time to server.
     */
    saveTransmittedNotificationTime() {
      this.$refs.transmittedTimePickerVisible.save(this.transmittedReportTime);
      this.updateTransmittedNotificationTime(this.transmittedReportTime);
    },
    /**
     * Add email to array and send API request.
     */
    async addEmailNotification() {
      if (this.newEmail === '') {
        return;
      }
      const updatedEmails = [...this.notificationEmails, this.newEmail];
      await this.updateNotification({ time: this.notificationTime, emails: updatedEmails });
      this.newEmail = '';
    },
    /**
     * Delete email from array and send API request.
     */
    deleteEmailNotification(value, emailList) {
      const updatedEmails = [
        ...this[emailList]
          .filter(email => email !== value),
      ];

      if (emailList === 'notificationEmails') {
        this.updateNotification({ time: this.notificationTime, emails: updatedEmails });
      }

      if (emailList === 'deletedUniqueFormsEmails') {
        this.updateDeletedUniqueFormsNotification(
          { frequency: this.deletedUniqueFormsFrequency, emails: updatedEmails },
        );
      }
    },
    /**
     * Delete notification rule from table and send API request.
     */
    async deleteDeviceNotificationRule(rule, isTransmitted) {
      await this.deleteNotificationRule(rule.ruleId);
      if (isTransmitted) {
        this.loadTransmittedNotification();
      } else {
        this.loadDeviceNotification();
      }
    },
    /**
     * Get title for type to display.
     * @param type
     * @returns {string}
     */
    getRuleTitle(type) {
      switch (type) {
        case 'sinceIncidentDate':
          return 'Incident Date';
        case 'sinceLastModified':
          return 'Last Modified Date';
        case 'sinceTransmitted':
          return 'Transmitted Date';
        default:
          return '';
      }
    },
    /**
     * Show confirmation for deleting email.
     *
     * @param email
     * @param emailList
     */
    showDeleteEmailPopup(email, emailList) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.deleteEmailTitle'),
        `Do you want to delete ${email} from list?`,
        () => {
          this.deleteEmailNotification(email, emailList);
        },
      );
    },
    /**
     * Show confirmation for deleting notification row.
     * @param item Notification object
     * @param isTransmitted
     */
    showDeleteRowPopup(item, isTransmitted) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.deleteNotificationTitle'),
        `Do you really want to delete notification: ${item.rule} for ${item.personnel}?`,
        () => {
          this.deleteDeviceNotificationRule(item, isTransmitted);
        },
      );
    },

    /**
     * Add email to array deleted unique forms emails and send API request.
     */
    async addEmailDeletedUniqueForms() {
      if (this.newEmailDeletedUniqueForms === '') {
        return;
      }
      const updatedEmails = [...this.deletedUniqueFormsEmails, this.newEmailDeletedUniqueForms];
      await this.updateDeletedUniqueFormsNotification({
        frequency: this.deletedUniqueFormsFrequency,
        emails: updatedEmails,
      });
      this.newEmailDeletedUniqueForms = '';
    },

    /**
     * Get period by frequency.
     *
     * @returns {String}
     */
    getPeriod() {
      if (this.frequencyDeletedUniqueForms === FREQUENCIES.DAILY) {
        return moment().format('MM/DD/YYYY');
      }
      if (this.frequencyDeletedUniqueForms === FREQUENCIES.WEEKLY) {
        return `${moment().startOf('week').add(1, 'days').format('MM/DD/YYYY')} - ${
          moment().endOf('week').add(1, 'days').format('MM/DD/YYYY')}`;
      }
      if (this.frequencyDeletedUniqueForms === FREQUENCIES.MONTHLY) {
        return `${moment().startOf('month').format('MM/DD/YYYY')} - ${
          moment().endOf('month').format('MM/DD/YYYY')}`;
      }
      return 'none';
    },

    async saveCeAdminNotifiable() {
      this.ceAdminsNotifiableSaving = true;
      try {
        await this.updateCeAdminsNotifiable(this.ceAdminsNotifiableModel);
      } finally {
        this.ceAdminsNotifiableSaving = false;
      }
      this.ceAdminsNotifiableModel = null;
    },
  },
};
</script>

<style lang="scss" module>
.textField {
  max-width: 300px;
}

.card {
  padding: 14px;
  height: 100%;
}

.emailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.emailInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.emailAddButton {
  margin-left: 10px;
}

.addBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.emailChip {
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
