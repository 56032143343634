<template>
  <v-container fluid>
    <v-toolbar
        id="systemSearchToolbar"
        flat
        dense
        height="auto"
        :class="[$style['search-toolbar']]"
    >
      <v-layout
        row
        wrap
      >
        <v-toolbar-items class="mb-2 mt-2">
          <date-range-picker-with-periods
              v-model="filters.dateRange"
              :label="this.filters.rangeBy === 'incidentDate' ? 'Incident Date' : 'Upload Date'"
              position="right"
              class="mr-5"
              @closed="datePickerCloseHandler"
          >
            <div
                slot="header"
                :class="[$style['date-header']]"
            >
              <v-radio-group
                  v-model="dateRangeFieldTmp"
                  row
              >
                <v-radio
                    label="Incident Date"
                    value="incidentDate"
                />
                <v-radio
                    label="Date PCR Uploaded"
                    value="uploadedDate"
                />
              </v-radio-group>
            </div>
          </date-range-picker-with-periods>
        </v-toolbar-items>

        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.sequenceNumbers"
              label="Sequence Numbers"
              hide-details
              class="mr-5"
              style="min-width: 230px; max-width: 800px"
          ></v-text-field>
        </v-toolbar-items>

        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.incidentNumbers"
              label="Incident Numbers"
              hide-details
              class="mr-5"
              style="min-width: 230px; max-width: 800px"
          ></v-text-field>
        </v-toolbar-items>

        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.firstName"
              label="First Name"
              hide-details
              class="mr-5"
              style="min-width: 230px; max-width: 800px"
          ></v-text-field>
        </v-toolbar-items>

        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.lastName"
              label="Last Name"
              hide-details
              class="mr-5"
              style="min-width: 230px; max-width: 800px"
          ></v-text-field>
        </v-toolbar-items>
      </v-layout>

      <v-spacer/>

      <v-btn
          class="float-right mt-5"
          color="primary"
          :loading="loading"
          @click="search"
      >
        <v-icon>search</v-icon>
        Search
      </v-btn>
    </v-toolbar>

  </v-container>
</template>
<script>
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'Toolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        dateRange: [],
        rangeBy: 'incidentDate',
        sequenceNumbers: null,
        incidentNumbers: null,
        firstName: null,
        lastName: null,
      },
      dateRangeFieldTmp: 'incidentDate',
      rows: 1,
    };
  },

  created() {
    window.addEventListener('resize', this.recalculateRows);
    this.recalculateRows();
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateRows);
  },

  methods: {
    recalculateRows() {
      const toolbar = document.getElementById('systemSearchToolbar');
      const width = toolbar ? toolbar.offsetWidth : 0;
      this.rows = width < 1385 ? 2 : 1;
    },

    /**
     * Run search process.
     */
    search() {
      const filters = {
        firstName: this.filters.firstName,
        lastName: this.filters.lastName,
      };
      if (this.filters.dateRange && this.filters.dateRange.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        filters.dateRangeFrom = this.filters.dateRange[0];
        // eslint-disable-next-line prefer-destructuring
        filters.dateRangeTo = this.filters.dateRange[1];
        filters.rangeBy = this.filters.rangeBy;
      }

      if (this.filters.sequenceNumbers) {
        filters.sequenceNumbers = this.splitStr(this.filters.sequenceNumbers);
      }

      if (this.filters.incidentNumbers) {
        filters.incidentNumbers = this.splitStr(this.filters.incidentNumbers);
      }

      this.$emit('search', filters);
    },

    /**
     * Split str by comma.
     *
     * @param {String} str - String to split.
     *
     * @return {Array}
     */
    splitStr(str) {
      const list = str.split(',');
      const trimmedList = [];
      list.forEach((item) => {
        const itemTrimmed = item.trim();
        if (itemTrimmed) {
          trimmedList.push(itemTrimmed);
        }
      });
      return trimmedList;
    },

    /**
     * Handler of datetime range picker close action.
     *
     * @param {Boolean} applied - If closed by applied button
     * @param {Boolean} cancelled - If closed by cancel button
     */
    datePickerCloseHandler(applied, cancelled) {
      if (this.filters.rangeBy !== this.dateRangeFieldTmp && !cancelled) {
        this.filters.rangeBy = this.dateRangeFieldTmp;
      }
      if (this.filters.rangeBy !== this.dateRangeFieldTmp && cancelled) {
        this.dateRangeFieldTmp = this.filters.rangeBy;
      }
    },
  },
};
</script>
<style lang="scss" module>
.date-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: thin solid #eee;
  padding-top: 15px;
  padding-left: 15px;
}
.search-toolbar {
  z-index: 10;
}
</style>
