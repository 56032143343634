<template>
  <div
    v-bind:style="{width: width + 'px', height: (height * scale) + 'px'}"
    class=" mb-2"
  >
    <div
        class="ce-entry-card-container"
        v-bind:style="{ transform: 'scale(' + scale + ')' }"
    >
      <v-responsive
          :aspect-ratio="21/8"
          class="ml-1 mr-1"
          :max-height="height + 'px'"
      >
        <v-card
            :loading="loading"
            :class="['ce-entry-box', 'fill-height', ('color' + colorNumber)]"
            rounded="lg"
            dark
            style="height: 100%; width: 100%"
        >
          <v-card-text
              v-if="loading && !dataLoaded"
              class="mt-0 pt-3 text-center"
          >
            Loading...
          </v-card-text>
          <slot v-else/>
        </v-card>
      </v-responsive>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CeEntryCard',
  props: {
    colorNumber: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      height: 117,
    };
  },
  computed: {
    scale() {
      return this.width / 317;
    },
  },
};
</script>
<style lang="scss">
.ce-entry-box.color1 {
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);
}
.ce-entry-box.color2 {
  background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
}
.ce-entry-box.color3 {
  background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);
}
.ce-entry-box.color4 {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%);
}
.ce-entry-box.color5 {
  background-image: linear-gradient( 135deg, #a6ff9c 10%, #038f7f 100%);
}
.ce-entry-card-container {
  width: 317px;
  transform-origin: left top;
}
</style>
