<template>
  <v-toolbar
    class="nemsis-export-toolbar-validate"
    flat
  >
    <v-autocomplete
        v-if="showClientsFilter"
        v-model="clientId"
        :items="clients"
        label="Choose client"
        clearable
        hide-details
        style="max-width: 300px"
        class="mr-5"
    />
    <v-text-field
      v-model="sequenceNumber"
      hide-details
      class="mr-5"
      label="Sequence Number"
      style="max-width: 300px"
    />

    <v-spacer />
    <v-btn
        class="mr-3"
        color="primary"
        :disabled="validateDisabled"
        :loading="validating"
        @click="runValidation"
    >
      Validate
    </v-btn>
  </v-toolbar>
</template>
<script>

export default {
  name: 'ToolbarValidate',
  props: {
    validating: {
      default: false,
      type: Boolean,
    },
    showClientsFilter: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      clientId: null,
      sequenceNumber: null,
    };
  },

  computed: {
    clients() {
      const { clientsList } = this.$store.state;
      if (!clientsList || !clientsList.length) {
        return [];
      }
      return clientsList.map(client => ({
        text: client.name,
        value: client.registryId,
      }));
    },
    validateDisabled() {
      if (this.showClientsFilter && !this.clientId) {
        return true;
      }
      if (!this.sequenceNumber) {
        return true;
      }
      return false;
    },
  },

  methods: {
    validate(clientId, sequenceNumber) {
      this.clientId = clientId;
      this.sequenceNumber = sequenceNumber;
      this.runValidation();
    },
    runValidation() {
      const filters = {
        clientId: this.clientId,
        sequenceNumber: this.sequenceNumber,
      };
      this.$emit('validate', filters);
    },
  },
};
</script>

<style lang="scss">
.nemsis-export-toolbar {
  z-index: 10;
}
</style>
