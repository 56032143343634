<template>
  <div>
    <v-btn
        v-if="!isPrinting && showBackButton"
        class="mt-4 ml-4"
        rounded
        text
        @click="$emit('goBack')"
    >
      <v-icon left>chevron_left</v-icon>
      Users Overview
    </v-btn>
    <v-card-title
        v-if="isPrinting"
        class="justify-center pb-0"
    >
      {{ siteInfo.roleDescription }}
    </v-card-title>
    <v-card-title
        v-if="isPrinting"
        class="justify-center pa-0"
    >
      Individual Report
    </v-card-title>
    <widgets-row
      :key="userDataToWidgets.key"
      :user-data="userDataToWidgets"
      :data-loaded="dataLoaded"
      :loading="loadingUser"
    />
    <v-toolbar
        v-if="!isPrinting"
        flat
    >
      <v-spacer/>
      <v-btn
          v-if="showActionButtons"
          color="primary"
          class="mr-4"
          :disabled="loadingUser && !dataLoaded"
          @click="$refs.editUserLicensePopup.show(userData)"
      >
        Edit User License
      </v-btn>
      <v-btn
          v-if="showActionButtons"
          color="primary"
          class="mr-4"
          :disabled="loadingUser && !dataLoaded"
          @click="$emit('addPeriodClick', {
            userId: userData.id,
            licenseTypeId: userData.licenseTypeId,
            period: null
          })"
      >
        + Add Period
      </v-btn>

      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :disabled="loadingUser || loadingPeriods"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon left>mdi-printer-outline</v-icon>
            Print
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              :disabled="!currentPeriod"
              @click="printHandler('current')"
          >
            <v-list-item-title>Current Period</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="!priorPeriod"
            @click="printHandler('prior')"
          >
            <v-list-item-title>Prior Period</v-list-item-title>
          </v-list-item>
          <v-list-item @click="printHandler('all')">
            <v-list-item-title>All Periods</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-divider />
    <periods-expansion-panels
        v-show="!isPrinting"
        :user-data="userData"
        :periods="periods"
        :loading="loadingPeriods"
        :show-actions="showActionButtons"
        :last-period-id="userData.periodId"
        :current-period="currentPeriod"
        :prior-period="priorPeriod"
        :print-view="false"
        @viewEntry="viewEntry"
        @editPeriodClick="editPeriodHandler"
        @deletedPeriod="updateData"
    />
    <periods-expansion-panels
        v-show="isPrinting"
        :user-data="userData"
        :periods="periods"
        :loading="false"
        :show-actions="false"
        :last-period-id="userData.periodId"
        :current-period="currentPeriod"
        :prior-period="priorPeriod"
        :print-view="true"
        :print-period="printPeriod"
    />
    <edit-user-license-popup
      ref="editUserLicensePopup"
      @saved="loadUserData(userData.id)"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import PeriodsExpansionPanels from '@/components/CeEntry/PeriodsExpansionPanels';
import WidgetsRow from '@/components/CeEntry/WidgetsRow';
import ceHours from '@/api/ceHours';
import EditUserLicensePopup from '@/components/CeEntry/EditUserLicensePopup';
import { SET_IS_PRINTING } from '@/store/mutations';
import { mapState } from 'vuex';

export default {
  name: 'ViewUser',
  components: {
    EditUserLicensePopup,
    WidgetsRow,
    PeriodsExpansionPanels,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingUser: false,
      loadingPeriods: false,
      dataLoaded: false,
      userData: {},
      periods: [],
      printPeriod: null,
    };
  },
  computed: {
    ...mapState(['siteInfo']),
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    currentPeriod() {
      let currentPeriod = null;
      this.periods.forEach(item => {
        if (item.isCurrentPeriod) {
          currentPeriod = item;
        }
      });
      return currentPeriod;
    },
    priorPeriod() {
      let priorPeriod = null;
      if (this.currentPeriod) {
        const currentPeriodIndex = this.periodIdIndexMap[this.currentPeriod.id];
        const priorPeriodIndex = currentPeriodIndex + 1;
        if (this.periods[priorPeriodIndex] && this.periods[priorPeriodIndex].id) {
          priorPeriod = this.periods[priorPeriodIndex];
        }
      }
      if (!priorPeriod) {
        this.periods.forEach(item => {
          if (item.id === this.userData.periodId && !item.isCurrentPeriod) {
            priorPeriod = item;
          }
        });
      }
      return priorPeriod;
    },
    periodIdIndexMap() {
      const map = {};
      this.periods.forEach((item, index) => {
        map[item.id] = index;
      });
      return map;
    },
    userDataToWidgets() {
      const userData = _.cloneDeep(this.userData);
      userData.key = 'current_period';
      if (this.printPeriod === 'prior') {
        userData.goal = this.priorPeriod.goal;
        userData.hours = this.priorPeriod.hours;
        userData.isCurrentPeriod = false;
        userData.isShortOfHours = false;
        userData.monthsLeft = 0;
        userData.periodId = this.priorPeriod.id;
        userData.periodFrom = this.priorPeriod.dateFrom;
        userData.periodTo = this.priorPeriod.dateTo;
        userData.skills = this.priorPeriod.skills;
        userData.skillsGoal = this.priorPeriod.skillsGoal;
        userData.key = 'prior_period';
      }
      return userData;
    },
  },
  methods: {
    async printHandler(type) {
      this.printPeriod = type;
      this.$store.commit(SET_IS_PRINTING, true);
      await this.$nextTick();
      await window.print();
      this.printPeriod = null;
      this.$store.commit(SET_IS_PRINTING, false);
    },
    loadUsingUserData(userData) {
      this.userData = userData;
      this.dataLoaded = true;
      this.periods = [];
      this.loadingUser = false;
      this.loadPeriods(userData.id);
    },
    loadUsingUserId(userId) {
      this.dataLoaded = false;
      this.loadUserData(userId);
      this.loadPeriods(userId);
    },
    updateData() {
      this.loadUserData(this.userData.id);
      this.loadPeriods(this.userData.id);
    },
    async loadUserData(userId) {
      this.loadingUser = true;
      try {
        this.userData = await ceHours.getUser(userId);
        this.dataLoaded = true;
      } finally {
        this.loadingUser = false;
      }
    },
    async loadPeriods(userId) {
      this.loadingPeriods = true;
      try {
        this.periods = await ceHours.getUserPeriods(userId);
      } finally {
        this.loadingPeriods = false;
      }
    },
    viewEntry(entry) {
      this.$emit('viewEntry', entry);
    },
    editPeriodHandler(period) {
      this.$emit('addPeriodClick', {
        userId: this.userData.id,
        licenseTypeId: this.userData.licenseTypeId,
        period,
      });
    },
  },
};
</script>
