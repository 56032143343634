import api from './api';

/**
 * Fetch list of admins
 * @return {Array} Admins list
 */
export function fetchAdminLists() {
  return api('v4')
    .get('/admin/admins')
    .then(response => response.data.items);
}

/**
 * Update data of admin by ID
 * @param {number} adminID - Identifier of admin
 * @param {Array} data - Data of admin for update
 */
export function updateAdmin(adminID, data) {
  return api()
    .put(`/admin/admins/${adminID}`, data)
    .then(response => response.data);
}

/**
 * Create new admin
 * @param {Array} data - Data of admin for create
 */
export function createAdmin(data) {
  return api()
    .post('/admin/admins', data)
    .then(response => response.data);
}

/**
 * Delete admin by ID
 * @param {number} adminID - Identifier of admin
 */
export function deleteAdmin(adminID) {
  return api().delete(`/admin/admins/${adminID}`);
}

/**
 * Restore admin by ID
 * @param {number} adminID - Identifier of admin
 */
export function restoreAdmin(adminID) {
  return api().post(`/admin/admins/${adminID}/restore`);
}

/**
 * Check is given username is available
 * @param {Array} data - Data of admin for check
 */
export function checkUserName(data) {
  return api()
    .post('/admin/admins/check-name', data)
    .then(response => response.data.isValid);
}
