<template>
  <div>
    <push-notifications-toolbar
      :filters="filters"
      :is-failed-tab="true"
      @filtersUpdate="filtersUpdate"
    ></push-notifications-toolbar>

    <push-notifications-table
      :items="pushNotifications.itemsFailed"
      :pagination="pagination"
      :total-items="pagination.totalItems"
      :loading="loading"
      :is-failed-table="true"
      @deleted="reloadItems"
      @editNotification="editNotification"
      @update:pagination="updatePagination"
    ></push-notifications-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { LOAD_PUSH_NOTIFICATIONS_FAILED } from '@/store/PushNotifications/actions';
import {
  SET_PUSH_NOTIFICATIONS_FILTERS_FAILED,
  SET_PUSH_NOTIFICATIONS_PAGINATION_FAILED,
} from '@/store/PushNotifications/mutations';
import PushNotificationsTable from './PushNotificationsTable';
import PushNotificationsToolbar from './PushNotificationsToolbar';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('pushNotifications');

export default {
  name: 'PushNotificationsTabFailed',

  components: {
    PushNotificationsToolbar,
    PushNotificationsTable,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState({
      pushNotifications: state => state,
    }),

    pagination: {
      get() {
        return this.pushNotifications.paginationFailed;
      },
      set(value) {
        if (!_.isEqual(value, this.pushNotifications.paginationFailed)) {
          this.setPagination(value);
          this.reloadItems();
        }
      },
    },
    filters() {
      return this.pushNotifications.filtersFailed;
    },
  },

  mounted() {
    this.reloadItems();
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PUSH_NOTIFICATIONS_PAGINATION_FAILED,
      setFilters: SET_PUSH_NOTIFICATIONS_FILTERS_FAILED,
    }),
    ...mapActions({
      loadItems: LOAD_PUSH_NOTIFICATIONS_FAILED,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Reloads push notifications paginated list for current filter
     */
    async reloadItems() {
      this.loading = true;
      try {
        await this.loadItems();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Handler of filters update event.
     *
     * @param {Object} filters - Filtering params object
     */
    filtersUpdate(filters) {
      this.pagination.page = 1;
      this.setFilters(filters);
      this.reloadItems();
    },

    /**
     * Handler of edit notification event.
     *
     * @param {Object} notification - Notification object to edit
     */
    editNotification(notification) {
      this.$emit('editNotification', notification);
    },
  },
};
</script>
