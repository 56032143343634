import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import Confirmation from '@/components/Confirmation';

export default {
  components: {
    VueCronEditorBuefy,
    Confirmation,
  },
  data() {
    return {
      /**
       * Flag for show/hide this popup.
       */
      showEditPopup: false,
      /**
       * Value of new password.
       */
      newPassword: '',
      /**
       * Flag for is password changed.
       */
      isPasswordChanged: false,
      /**
       * Flag for mode of password display/edit.
       */
      isPasswordEditMode: false,
      /**
       * Flag for cron edit block show/hide.
       */
      isCronEditMode: false,
      /**
       * Flag for first setup of export settings.
       */
      isFirstSetupMode: false,
    };
  },
  methods: {
    /**
     * Set initial data.
     */
    initPopupData() {
      this.isPasswordEditMode = false;
      this.isPasswordChanged = false;
      this.newPassword = '';
      this.newEmail = '';
      this.showEditPopup = true;
      this.isCronEditMode = false;
    },
    /**
     * Validate and save export settings data.
     * If password field is changed then send new value to API.
     */
    async save() {
      if (!await this.$validator.validateAll()) {
        return;
      }

      if (this.isPasswordChanged) {
        this.exportSettings.password = this.newPassword;
      }

      this.saveSettings(this.exportSettings);
      this.showEditPopup = false;
    },
    /**
     * Open confirmation dialog and show input field for new password if needed.
     */
    changePassword() {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.changePasswordTitle'),
        this.$t('profileSettings.changePasswordText'),
        () => {
          this.isPasswordChanged = true;
          this.isPasswordEditMode = true;
          this.newPassword = null;
        },
      );
    },
  },
};
