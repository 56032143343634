<template>
  <v-card-actions>
    <v-text-field
        v-model="filters.search"
        :label="$t('users.filters.search')"
        clearable
        class="mr-3"
        style="max-width: 300px;"
    />

    <v-select
        v-model="filters.status"
        :items="statuses"
        :label="$t('users.filters.status')"
        item-value="status"
        item-text="name"
        single-line
        style="max-width: 300px;"
    />

    <v-spacer />
    <v-btn
        :loading="exportLoading"
        color="primary"
        class="mr-3"
        @click.native="$emit('exportToExcel')"
    >
      {{ $t('buttons.export') }}
    </v-btn>

    <v-btn
        color="primary"
        @click="$emit('addUser')"
    >
      {{ $t('buttons.add') }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import DELETION_STATUSES from '@/enums/deletionStatuses';

export default {
  name: 'UsersToolbar',
  props: {
    siteRole: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    statuses() {
      const statuses = [
        {
          status: DELETION_STATUSES.ALL,
          name: this.$t('users.statuses.all'),
        },
        {
          status: DELETION_STATUSES.ACTIVE,
          name: this.$t('users.statuses.active'),
        },
      ];

      if (this.siteRole === 'client') {
        statuses.push({
          status: DELETION_STATUSES.DEACTIVATED,
          name: this.$t('users.statuses.deactivated'),
        });
      }
      statuses.push({
        status: DELETION_STATUSES.DELETED,
        name: this.$t('users.statuses.deleted'),
      });
      return statuses;
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },
};
</script>
