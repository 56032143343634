<template>
  <div>
    <v-card
        :loading="loading"
        class="files"
    >
      <v-toolbar flat>
        <v-icon
            dense
            class="mr-2"
        >mdi-file-document-multiple</v-icon>
        Files
        <v-spacer />
        <v-btn
          v-if="!loading && files.length < maxFileCount"
          icon
          @click="uploadFile()"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider/>
      <v-card-text v-if="!loading && !files.length">No uploaded files</v-card-text>
      <v-card-text v-if="files.length">
        <v-list>
          <v-list-item
              v-for="file in files"
              :key="file.id"
          >
            <v-list-item-icon class="mr-1"><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      :href="file.url"
                      target="_blank"
                      v-on="on"
                    >{{ makeFileName(file) }}</a>
                  </template>
                  <span style="text-align: center">
                    <template v-if="file.createdByUser">
                      Created by: {{ makeFullUserName(file) }}<br/>
                    </template>
                    Created at: {{ file.createdAt | datetimeToClientTimezone }}
                  </span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="showActionMenu(file)">
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon
                      class="float-right"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="editFile(file)">
                    <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item @click="deleteFile(file)" >
                    <v-list-item-icon><v-icon>mdi-trash-can-outline</v-icon></v-list-item-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <confirmation ref="confirmationPopup"/>
    <files-popup
      ref="filesPopup"
      @saved="loadFiles"
    />
  </div>
</template>

<script>
import FilesPopup from '@/components/IncidentView/FilesPopup';
import Confirmation from '@/components/Confirmation';
import incidents from '@/api/incidents';
import { SHOW_SNACKBAR } from '@/store/actions';
import { mapActions } from 'vuex';

export default {
  name: 'Files',
  components: {
    Confirmation,
    FilesPopup,
  },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      maxFileCount: 3,
      loading: true,
      files: [],
    };
  },
  async mounted() {
    await this.loadFiles();
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),
    /**
     * Load files data.
     *
     * @return {Promise<void>}
     */
    async loadFiles() {
      try {
        this.loading = true;
        this.files = await incidents.fetchFiles(this.incidentId);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Upload new incident file.
     *
     * @return {Promise<void>}
     */
    uploadFile() {
      this.$refs.filesPopup.open(this.incidentId);
    },

    /**
     * Edit incident file.
     *
     * @param {Object} file - File of incident
     *
     * @return {Promise<void>}
     */
    editFile(file) {
      this.$refs.filesPopup.open(this.incidentId, file);
    },

    /**
     * Delete incident file.
     *
     * @param {Object} file - File of incident
     */
    deleteFile(file) {
      const fileName = `${file.title}.${file.fileType}`;

      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        `Do you really want to delete the file "${fileName}"?`,
        async () => {
          try {
            await incidents.removeFile(this.incidentId, file);
            await this.loadFiles();
          } catch (error) {
            this.$refs.confirmationPopup.reject();

            if (error.response.status === 500) {
              this.showSnackbar({ title: error.response.statusText, color: 'red' });
            } else {
              this.showSnackbar({ title: error.response.data.message, color: 'red' });
            }
          }
        },
      );
    },

    /**
     * Download incident file.
     *
     * @param {Object} file - File of incident
     */
    downloadFile(file) {
      const win = window.open(file.url);
      win.focus();
    },

    /**
     * Make file name.
     *
     * @param {Object} file - File of incident
     *
     * @returns {string}
     */
    makeFileName(file) {
      return `${file.title}.${file.fileType}`;
    },

    /**
     * Make full username.
     *
     * @param {Object} file - File of incident
     *
     * @returns {string}
     */
    makeFullUserName(file) {
      return `${file.createdByUser.firstName} ${file.createdByUser.lastname}`;
    },

    /**
     * Check showing action menu.
     *
     * @param {Object} file - File of incident
     *
     * @return {boolean}
     */
    showActionMenu(file) {
      return this.$can('showMenu', 'incidentFile')
        || (file.createdBy && this.$store.state.user.registryId === file.createdBy);
    },
  },
};
</script>

<style lang="scss">
.files {
  .v-card__text {
    color: black !important;
  }
}
</style>
