<template>
  <div>
    <v-dialog
        v-model="verifyContactDataDialog"
        persistent
        max-width="600"
        @close="$emit('close')"
    >
      <v-card>
        <v-container>
          <v-card-title class="headline pt-0">
            Verify contact details
          </v-card-title>
          <v-card-text class="pt-0 text-center">
            Your contact details are not confirmed. <br />
            Please review and confirm your contact details to protect your account. <br /><br />
            <i>Why do we need this?: We will soon be implementing 2FA (Two-Factor Authentication)
            which will require you to have verified contact
            details that are unique to you alone.</i> <br />
            <div class="text-center mt-5">
              <v-btn
                  v-if="!user.phoneVerified"
                  text
                  class="mr-5"
                  @click="showVerifyPhoneDialog()"
              >
                Confirm your phone
              </v-btn>
              <v-chip
                  v-if="user.phoneVerified"
                  color="green"
                  label
                  dark
                  class="mr-5"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                PHONE VERIFIED
              </v-chip>

              <v-chip
                  v-if="user.emailVerified"
                  color="green"
                  label
                  dark
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                EMAIL VERIFIED
              </v-chip>

              <v-btn
                  v-if="!user.emailVerified"
                  text
                  @click="showVerifyEmailDialog()"
              >
                Confirm your email
              </v-btn>
            </div>

          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
                v-if="!user.phoneVerified || !user.emailVerified"
                color="primary"
                text
                @click="verifyContactDataDialog = false"
            >Verify Later</v-btn>
            <v-btn
                v-if="user.phoneVerified && user.emailVerified"
                color="primary"
                text
                @click="verifyContactDataDialog = false"
            >Close</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <verify-phone-dialog ref="verifyPhoneDialog" />
    <verify-email-dialog ref="verifyEmailDialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SET_VERIFY_CONTACT_DATA_DIALOG } from '@/store/mutations';
import VerifyPhoneDialog from '@/components/Profile/VerifyPhoneDialog';
import VerifyEmailDialog from '@/components/Profile/VerifyEmailDialog';

export default {
  name: 'VerifyContactDataDialog',
  components: { VerifyEmailDialog, VerifyPhoneDialog },
  mixins: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapState(['user']),

    verifyContactDataDialog: {
      get() {
        return this.$store.state.verifyContactDataDialog;
      },
      set(value) {
        this.$store.commit(SET_VERIFY_CONTACT_DATA_DIALOG, value);
      },
    },
  },
  methods: {
    showVerifyPhoneDialog() {
      this.$refs.verifyPhoneDialog.show();
    },

    showVerifyEmailDialog() {
      this.$refs.verifyEmailDialog.show();
    },

    close() {
      this.verifyContactDataDialog = false;
    },
  },
};
</script>
