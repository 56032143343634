import fileDownload from 'js-file-download';
import devicesApi from '@/api/devices';

import { SET_ITEMS } from './mutations';

export const LOAD_DEVICES = 'LOAD_DEVICES';
export const EXPORT_DEVICES = 'EXPORT_DEVICES';

export const INCLUDES = {
  REGISTRY: 'registry',
};

export default {
  /**
   * Export devices list.
   *
   * @param {Object} state - Devices state.
   *
   * @return {Promise}
   */
  [EXPORT_DEVICES]: ({ state }) => {
    const { pagination, filters } = state;
    const params = {
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const result = devicesApi.export(params);

    return result.then(response => fileDownload(response.data, 'devices.xlsx'));
  },

  /**
   * Load devices list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Devices state.
   *
   * @return {Promise}
   */
  [LOAD_DEVICES]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      include: [INCLUDES.REGISTRY].join(','),
      ...filters,
    };

    const results = await devicesApi.fetchList(params);
    commit(SET_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },
};
