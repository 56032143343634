import api from './api';

/**
 * Check existance provider in current SiteCode,
 * which assigned in Headers of Axios api object.
 */
export function checkProviderExists() {
  return api()
    .get('/public/cad-monitoring/check-provider-existence')
    .then(response => response.data);
}

/**
 * Fetch list of providers for admin site.
 */
export function adminFetchProviders() {
  return api()
    .get('/admin/cad-monitoring/providers')
    .then(response => response.data);
}

/**
 * Fetch list of providers for public site.
 */
export function publicFetchProviders(params) {
  return api()
    .get('/public/cad-monitoring/providers', { params })
    .then(response => response.data);
}

/**
 * Fetch list of providers
 * @param {number} providerID - Identifier of provider
 */
export function fetchProvider(providerID) {
  return api()
    .get(`/admin/cad-monitoring/providers/${providerID}`)
    .then(response => response.data);
}

/**
 * Update data of provider by ID
 * @param {number} providerID - Identifier of provider
 * @param {Array} data - Data of provider for update
 */
export function updateProvider(providerID, data) {
  return api()
    .put(`/admin/cad-monitoring/providers/${providerID}`, data)
    .then(response => response.data);
}

/**
 * Delete file from unprocessed filelist
 * @param {number} providerID - Identifier of provider
 * @param {string} file - Path to file, which needs to delete
 */
export function deleteFile(providerID, file) {
  return api()
    .post(`/admin/cad-monitoring/providers/${providerID}/delete-file`, {
      file,
    })
    .then(response => response.data);
}

/**
 * Retry to process file from unprocessed filelist
 * @param {number} providerID - Identifier of provider
 * @param {string} file - Path to file, which needs to delete
 */
export function retryFile(providerID, file) {
  return api()
    .post(`/admin/cad-monitoring/providers/${providerID}/retry-file`, {
      file,
    })
    .then(response => response.data);
}

/**
 * Download file from unprocessed filelist
 * @param {number} providerID - Identifier of provider
 * @param {string} file - Path to file, which needs to delete
 */
export function downloadFile(providerID, file) {
  return api()
    .get(`/admin/cad-monitoring/providers/${providerID}/get-file?file=${file}`)
    .then(response => response.data);
}

/**
 * Get information about sftp status.
 */
export function fetchSftpStatus() {
  return api()
    .get('/public/cad-monitoring/sftp-status')
    .then(response => response.data);
}
