<template>
  <v-card
    class="flag-info"
    :loading="loading"
  >
    <v-toolbar flat>
      <template v-if="inReview">
        <v-icon
            dense
            class="mr-2"
            color="yellow accent-4"
        >mdi-alert-circle</v-icon>
        Incident is Still in Review
      </template>
      <template v-else>
        <v-icon
            dense
            class="mr-2"
            color="green"
        >mdi-check-circle</v-icon>
        This incident has been reviewed
      </template>
      <v-spacer />

      <v-btn
          v-if="canEdit"
          icon
          @click="$emit('clickEdit')"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider/>
    <v-card-text>
      <v-toolbar
        class="name-toolbar"
        flat
        dense
      >
        <span class="name">
          {{ flag.createdByUser.firstName }} {{ flag.createdByUser.lastname }}
        </span>
        <v-spacer />
        <span>{{ flag.createdAt | date }}</span>
      </v-toolbar>

      <span class="name">Reason for Flagging:</span><br />
      <div class="mb-3">{{ flag.message }}</div>
      <template v-if="flag.falloutCode">
        <span class="name">Fallout code:</span><br />
        <div class="mb-3">{{ flag.falloutCode }}</div>
      </template>
      <template v-if="showComments">
        <span
          v-if="flag.comments.length"
          class="name"
        >Comments:</span>
        <div
          v-if="flag.comments.length"
          class=comments-wrapper
        >
          <div
              v-for="(comment, index) in flag.comments"
              v-bind:key="'comment_' + index"
          >
            <v-toolbar
                flat
                class="comment-toolbar"
            >
              <div>
                <span>
                  <b>{{ comment.createdByUser.firstName }} {{ comment.createdByUser.lastname }}</b>
                </span>
                  <br/>
                  <span class="comment-datetime">
                  {{ comment.createdAt | datetimeToClientTimezone }}
                </span>
              </div>
              <v-spacer/>
              <v-btn
                  v-if="showDeleteCommentButton(comment)"
                  icon
                  class="comment-delete-btn"
                  @click="$emit('deleteComment', comment)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <div class="comment-text">
              {{ comment.comment }}
            </div>
            <v-divider v-if="index < flag.comments.length - 1"/>
          </div>
        </div>
        <div class="comments-add-btn">
          <v-btn
              text
              @click="$emit('addComment')"
          >
            + Add Comment
          </v-btn>
        </div>
      </template>

      <template v-if="showSentTo && flag.alerts.length">
        <span class="name">Sent to:</span><br />
        <v-card
            v-for="(alert, index) in flag.alerts"
            v-bind:key="'alert_' + index"
            elevation="0"
            color="#f5f5f6"
            class="mb-2 pl-3 pr-3"
            tile
        >
          <v-row>
            <v-col
                sm="1"
                class="text-right pr-0"
            >
              <v-icon
                  dense
                  :color="getStatusColor(alert.status)"
              >{{ getStatusIcon(alert.status) }}</v-icon>
            </v-col>
            <v-col sm="7">
              <span
                v-if="!alert.user.isDeleted"
                class="name"
              >
                {{ alert.user.firstName}} {{ alert.user.lastname}}
              </span>
              <span
                v-else
                class="name name-deleted"
              >
                User Removed ({{ alert.user.firstName}} {{ alert.user.lastname}})
              </span>
            </v-col>
            <v-col
                sm="4"
                class="text-right pl-0 overflow-hidden no-wrap"
            >
              <v-btn
                  v-if="alert.status === flagStatuses.PENDING && isFlaggedByCurrentUser(incident)"
                  text
                  color="primary"
                  small
                  :loading="resendingAlerts.includes(alert.alertId)"
                  @click="resendFlag(alert)"
              >
                Resend
              </v-btn>
              <span v-if="alert.status !== flagStatuses.PENDING">{{ alert.responded | date }}</span>
              <v-btn
                  v-if="canEdit && alert.status === flagStatuses.PENDING"
                  icon
                  small
                  class="ml-3"
                  :disabled="deletedAlerts.includes(alert.alertId)"
                  @click="deleteAlert(alert)"
              >
                <v-icon small>mdi-account-remove-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="alert.status !== flagStatuses.PENDING">
            <v-col
                sm="1"
                class="pt-0"
            />
            <v-col
                sm="9"
                class="pt-0"
            >
              <span
                  :class="
                alert.status === flagStatuses.UNDERSTOOD ? 'green--text' : 'red--text lighten-1'
                "
              >
                {{ alert.status | statusText }}
              </span>
              <div v-if="alert.message">
                <span class="comment-name">Comment:</span>
                <div>{{ alert.message }}</div>
              </div>
            </v-col>
            <v-col
              sm="2"
              class="pt-0 text-right"
            >
              <v-btn
                  v-if="canEdit"
                  icon
                  small
                  class="ml-3"
                  :disabled="deletedAlerts.includes(alert.alertId)"
                  @click="deleteAlert(alert)"
              >
                <v-icon small>mdi-account-remove-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>

      <div
        v-if="getMinStatus(flag.alerts) === flagStatuses.PENDING"
        class="text-center"
      >
        <v-btn
          v-if="isFlaggedByCurrentUser(incident)"
          text
          color="primary"
          small
          :loading="resending"
          @click="resendAllPending()"
        >
          Resend All Pending
        </v-btn>
        <v-btn
          v-if="showClosePendingFlagButton"
          text
          color="primary"
          small
          @click="$emit('closePendingFlag')"
        >
          Close Pending Flag
        </v-btn>
      </div>
      <v-card-actions v-if="participantAlert && participantAlert.status === flagStatuses.PENDING">
        <v-btn
            color="primary"
            @click="$emit('clickUnderstand')"
        >
          I UNDERSTAND
        </v-btn>
        <v-spacer />
        <v-btn @click="$emit('clickDidNotUnderstand')">
          I DO NOT UNDERSTAND
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import flaggedUsers from '@/api/flaggedUsers';
import FLAG_STATUSES from '@/enums/flagStatuses';
import incidentFlagMixins from '@/mixins/incidentFlagMixins';
import { SHOW_SNACKBAR } from '@/store/actions';

export default {
  name: 'FlagInfo',
  mixins: [incidentFlagMixins],
  props: {
    flag: {
      type: Object,
      required: true,
    },
    incident: {
      type: Object,
      required: true,
    },
    canSeeParticipants: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    statusText(status) {
      const map = {
        0: 'Pending',
        1: 'Understood',
        2: 'Did Not Understand',
      };
      return map[status];
    },
  },
  data() {
    return {
      flagStatuses: FLAG_STATUSES,
      resending: false,
      resendingAlerts: [],
      deletedAlerts: [],
    };
  },
  mounted() {
    const addViewLog = this.isParticipant && this.participantAlert.status === FLAG_STATUSES.PENDING;
    if (addViewLog) {
      flaggedUsers.viewLog(this.incident.id);
    }
  },
  computed: {
    inReview() {
      const statuses = this.flag.alerts.map(alert => alert.status);
      return statuses.includes(FLAG_STATUSES.PENDING);
    },
    isParticipant() {
      const users = this.flag.alerts.map(alert => alert.userId);
      return users.includes(this.$store.state.user.registryId);
    },
    participantAlert() {
      const alertsByUserId = {};
      this.flag.alerts.forEach(alert => {
        alertsByUserId[alert.userId] = alert;
      });
      return this.isParticipant ? alertsByUserId[this.$store.state.user.registryId] : null;
    },

    showSentTo() {
      return this.isFlaggedByCurrentUser(this.incident)
          || this.isParticipant || this.canSeeParticipants;
    },

    /**
     * Check user permission for viewing.
     *
     * @returns {boolean}
     */
    showComments() {
      return this.incident
        && this.incident.flag
        && (
          (
            this.$can('showCommentFlag', 'incident')
            && this.$store.state.user.registryId === this.incident.flag.createdBy
          )
          || this.$can('showForeignCommentFlag', 'incident')
        );
    },

    /**
     * Check usr permission for viewing close pending flag button.
     *
     * @returns {boolean}
     */
    showClosePendingFlagButton() {
      return this.incident
        && this.incident.flag
        && (
          this.$can('showForeignClosePendingFlagButton', 'incident')
          || (
            this.$can('showClosePendingFlagButton', 'incident')
            && this.$store.state.user.registryId === this.incident.flag.createdBy
          )
        );
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Check user permission for showing delete comment button.
     *
     * @param {Object} comment - Object of comment
     *
     * @returns {boolean}
     */
    showDeleteCommentButton(comment) {
      return (
        this.$can('showCommentFlag', 'incident') && this.$store.state.user.registryId === comment.createdBy
      )
      || this.$can('showForeignCommentFlag', 'incident');
    },

    /**
     * Delete alert logic.
     *
     * @param alert - Flag alert object
     */
    deleteAlert(alert) {
      if (this.flag.alerts.length > 1) {
        this.$emit('deleteAlert', alert);
      } else {
        this.$emit('deleteLastAlert', alert);
      }
    },

    /**
     * Add alert to deleted list.
     *
     * @param id - Alert identifier
     */
    alertDeleted(id) {
      this.deletedAlerts.push(id);
    },

    async resendAllPending() {
      try {
        this.resending = true;
        await flaggedUsers.resendPendingAlerts(this.incident.id);
        this.showSnackbar('Alerts was successfully re-sent.');
      } finally {
        this.resending = false;
      }
    },
    async resendFlag(alert) {
      try {
        this.resendingAlerts.push(alert.alertId);
        await flaggedUsers.resendFlag(alert.alertId);
        this.showSnackbar('Alert was successfully re-sent.');
      } finally {
        const index = this.resendingAlerts.indexOf(alert.alertId);
        this.resendingAlerts.splice(index, 1);
      }
    },
    getStatusColor(status) {
      const map = {
        0: 'yellow accent-4',
        1: 'green',
        2: 'red lighten-1',
      };
      return map[status];
    },
    getStatusIcon(status) {
      const map = {
        0: 'mdi-clock-time-four',
        1: 'mdi-check-circle',
        2: 'mdi-close-circle',
      };
      return map[status];
    },
  },
};
</script>

<style lang="scss">
.flag-info {
  .v-card__text {
    color: black !important;
  }
  .name {
    font-weight: 500;
  }
  .name-deleted {
    color: #e04337;
  }
  .comment-name {
    color: darkgrey;
  }
  .name-toolbar {
    .v-toolbar__content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .comments-wrapper {
    padding: 0 10px;
    background-color: rgb(245, 245, 246);
    .comment-toolbar {
      background-color: rgb(245, 245, 246);
      height: 50px !important;
      padding-top: 10px;
      .v-toolbar__content {
        height: 50px !important;
        padding: 5px 0px;
      }
      .comment-datetime {
        font-size: 12px;
      }
      .comment-delete-btn {
        margin: 0 !important;
      }
    }
    .comment-text {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
  .comments-add-btn {
    margin-top: 15px;
    text-align: center;
  }
}

</style>
