import api from './api';

export default {

  /**
     * Fetch list of transaction logs.
     *
     * @param {Object} params
     *
     * @return Promise<Object>
     */
  fetchTransmittedReports(params) {
    return api()
      .get('/client/incidents/transmitted', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of clients to reassign.
   *
   * @return Promise<Object>
   */
  fetchClientsList() {
    return api()
      .get('/client/incidents/transmitted/clients')
      .then(response => response.data);
  },

  /**
   * Fetch list of clients to reassign.
   *
   * @return Promise<Object>
   */
  getClientDevicesList(clientId) {
    return api()
      .get(`/client/incidents/transmitted/clients/${clientId}/devices`)
      .then(response => response.data);
  },

  /**
   * Get CAD allowed units.
   *
   * @param {Number} id
   *
   * @return Promise<Object>
   */
  getUnits(id) {
    return api()
      .get(`/client/incidents/transmitted/${id}/units`)
      .then(response => response.data);
  },

  /**
   * Get Assigned units.
   *
   * @param {Number} id
   *
   * @return Promise<Object>
   */
  getAssignedUnits(id) {
    return api()
      .get(`/client/incidents/transmitted/${id}/assigned-units`)
      .then(response => response.data);
  },

  /**
   * Mark transmitted reports and constantly.
   *
   * @param {Number} id
   *
   * @return Promise<Object>
   */
  markAsConstantly(id) {
    return api()
      .put(`/client/incidents/transmitted/${id}/mark-as-constantly`)
      .then(response => response.data);
  },

  /**
   * UnMark transmitted reports and constantly.
   *
   * @param {Number} id
   *
   * @return Promise<Object>
   */
  unmarkAsConstantly(id) {
    return api()
      .put(`/client/incidents/transmitted/${id}/unmark-as-constantly`)
      .then(response => response.data);
  },

  /**
   * Save assigned units to transmitted incident.
   *
   * @param {Number} id Id of transmitted incident
   * @param {Object} data Data to save
   *
   * @return Promise<Object>
   */
  saveUnits(id, data) {
    return api()
      .post(`/client/incidents/transmitted/${id}/units`, data)
      .then(response => response.data);
  },
};
