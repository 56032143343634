<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="pl-4 pr-4"
  >
    <div v-if="settingsData">
      <v-card-title class="ce-certificate-section-title">Certificate Text:</v-card-title>
      <div class="mb-5">
        <v-alert
            border="bottom"
            color="orange"
            colored-border
            type="info"
        >
          <b>Available tags:</b><br />
          {{tags.hours}} - course hours <br />
          {{tags.state}} - full state name<br />
          {{tags.providerNumber}} - course provider number<br />
          {{tags.instructorOrNonInstructor}} - instructor based type<br />
          {{tags.recordId}} - record identifier<br />
          {{tags.clientName}} - agency name<br />
          {{tags.clientAddress}} - agency address<br />
          <br />
          <i>Leave the field blank to use the default text.</i>
        </v-alert>
        <v-textarea
            v-model="settingsData.text"
            label="Text"
            :placeholder="'Default text: ' + defaultText"
            counter="1000"
            maxlength="1000"
            outlined
        />
        <v-row class="justify-end">
          <a
              v-if="!settingsData.text"
              @click="editDefaultText"
          >
            Edit default text
          </a>
        </v-row>

        <v-text-field
            v-model="settingsData.clientName"
            label="Agency Name"
            :placeholder="'Default name: ' + settingsData.defaultClientName"
            maxlength="255"
        />
        <v-text-field
            v-model="settingsData.clientAddress"
            label="Agency Address"
            maxlength="255"
        />
      </div>

      <v-card-title class="ce-certificate-section-title mb-5">Certificate Signature:</v-card-title>
      <div class="mb-5">
        <input
            ref="signatureFileInput"
            type="file"
            style="display: none;"
            accept="image/*"
            @change="handleSignatureFileChange"
        />
        <v-row
            no-gutters
            class="mb-2"
        >
          <v-btn
              color="primary"
              class="mr-2"
              @click="$refs.signatureFileInput.click()"
          >
            Choose signature
          </v-btn>
          <v-btn
              v-if="settingsData.signatureUrl"
              @click="deleteSignature"
          >
            Delete signature
          </v-btn>
        </v-row>
        Max size: 690px / 120px:
        <div class="ce-signature-container d-flex justify-center align-center mb-3">
          <v-img
              v-if="settingsData.signatureUrl"
              :src="settingsData.signatureUrl"
              max-height="118"
              contain
          />
          <span v-else>No uploaded signature</span>
        </div>

        <v-text-field
            v-model="settingsData.signerName"
            label="Signer Name"
            counter="55"
            maxlength="55"
        />
        <v-text-field
            v-model="settingsData.signerTitle"
            label="Signer Title"
            counter="55"
            maxlength="55"
        />
      </div>

      <v-card-title class="ce-certificate-section-title mb-5">
        About / Address / Contacts:
      </v-card-title>
      <div class="mb-10">
        <v-textarea
            v-model="settingsData.about"
            :rules="aboutRules"
            label="Text"
            outlined
            style="max-width: 500px"
        />
      </div>

      <v-card-title class="ce-certificate-section-title">Logo:</v-card-title>
      <div class="mb-5">
        <v-checkbox
            v-model="settingsData.useClientLogo"
            label="Use Agency Logo"
        />
        <input
            ref="logoFileInput"
            type="file"
            style="display: none;"
            accept="image/*"
            @change="handleLogoFileChange"
        />
        <v-row
            v-if="!settingsData.useClientLogo"
            no-gutters
            class="mb-2"
        >
          <v-btn
              color="primary"
              class="mr-2"
              @click="$refs.logoFileInput.click()"
          >
            Choose logo
          </v-btn>
          <v-btn
              v-if="settingsData.logoUrl"
              @click="deleteLogo"
          >
            Delete logo
          </v-btn>
        </v-row>
        Max size: 650px / 250px:
        <div class="ce-logo-container d-flex justify-center align-center">
          <template v-if="settingsData.useClientLogo">
            <v-img
                v-if="settingsData.clientLogoUrl"
                :src="settingsData.clientLogoUrl"
                max-height="248"
                contain
            />
            <span v-else>No agency logo</span>
          </template>
          <template v-else>
            <v-img
                v-if="settingsData.logoUrl"
                :src="settingsData.logoUrl"
                max-height="248"
                contain
            />
            <span v-else>No uploaded logo</span>
          </template>
        </div>
      </div>
    </div>
  </v-form>
</template>
<script>
import _ from 'lodash';
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import ceHoursSettings from '@/api/ceHoursSettings';
import { getPresignedData, upload } from '@/api/uploader';

export default {
  name: 'Certificates',
  mixins: [componentMixins],
  data() {
    return {
      valid: true,
      settingsData: null,
      settingsDataOriginal: null,
      defaultText: null,
      logoFileToUpload: null,
      signatureFileToUpload: null,
      tags: {
        hours: '{{Hours}}',
        state: '{{State}}',
        providerNumber: '{{ProviderNumber}}',
        instructorOrNonInstructor: '{{InstructorOrNonInstructor}}',
        recordId: '{{RecordID}}',
        clientName: '{{ClientName}}',
        clientAddress: '{{ClientAddress}}',
      },
      aboutRules: [
        (value) => {
          if (value) {
            const lines = value.split('\n');
            return lines.length <= 7 || 'Maximum 7 lines available';
          }
          return true;
        },
        (value) => {
          if (value) {
            const lines = value.split('\n');
            let hasLongLine = false;
            lines.forEach(line => {
              if (line.length > 50) {
                hasLongLine = true;
              }
            });
            return !hasLongLine || 'Maximum line length 50 characters';
          }
          return true;
        },
      ],
    };
  },
  mounted() {
    this.loading = false;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const certificateSettings = await ceHoursSettings.getCertificateSettings();
        this.defaultText = certificateSettings.defaultText;
        delete certificateSettings.defaultText;
        this.settingsDataOriginal = _.cloneDeep(certificateSettings);
        this.settingsData = certificateSettings;
      } finally {
        this.loading = false;
      }
    },

    async save() {
      if (!this.$refs.form || !this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;
        const settingsData = _.cloneDeep(this.settingsData);
        delete settingsData.signatureUrl;
        delete settingsData.logoUrl;
        delete settingsData.clientLogoUrl;
        if (!this.settingsData.useClientLogo && this.logoFileToUpload) {
          const logoPresignedData = await getPresignedData(this.logoFileToUpload.name);
          await upload(logoPresignedData, this.logoFileToUpload);
          settingsData.logoTmp = logoPresignedData.tmpFilename;
        }
        if (this.signatureFileToUpload) {
          const signaturePresignedData = await getPresignedData(this.signatureFileToUpload.name);
          await upload(signaturePresignedData, this.signatureFileToUpload);
          settingsData.signatureTmp = signaturePresignedData.tmpFilename;
        }
        await ceHoursSettings.saveCertificateSettings(settingsData);
        await this.fetchData();
        this.edited = false;
      } finally {
        this.saving = false;
      }
    },

    editDefaultText() {
      this.settingsData.text = this.defaultText;
    },

    handleLogoFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.logoFileToUpload = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.settingsData.logoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    deleteLogo() {
      this.logoFileToUpload = null;
      this.settingsData.logoUrl = null;
      this.settingsData.logo = null;
    },

    handleSignatureFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.signatureFileToUpload = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.settingsData.signatureUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    deleteSignature() {
      this.signatureFileToUpload = null;
      this.settingsData.signatureUrl = null;
      this.settingsData.signature = null;
    },
  },
};
</script>
<style>
.ce-certificate-section-title {
  background-color: #f5f5f6;
}
.ce-logo-container {
  border: 1px solid #646464;
  height: 250px;
  width: 650px;
}
.ce-signature-container {
  border: 1px solid #646464;
  height: 120px;
  width: 690px;
}
</style>
