<template>
  <v-card>
    <v-toolbar flat>
      <v-icon
          dense
          class="mr-2"
      >{{ icon }}</v-icon>
      {{ title }}
    </v-toolbar>
    <v-divider/>
    <webp-supports-alert
      v-if="checkWebp"
      class="mr-5 ml-5 mt-5"
    />
    <v-slide-group
        class="pa-4"
        active-class="success"
        show-arrows
    >
      <v-slide-item
          v-for="(item, index) in items"
          :key="'item_' + index"
      >
        <v-card
            class="ma-4"
            width="120"
            @click="$emit('click', item.id)"
        >
          <v-img :src="item.src">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="gray"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-card>
</template>

<script>
import WebpSupportsAlert from '@/components/WebpSupportsAlert';

export default {
  name: 'Gallery',
  components: { WebpSupportsAlert },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    checkWebp: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">

</style>
