import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    itemsFailed: [],
    pagination: {
      sortDesc: [true],
      page: 1,
      sortBy: ['deliveryDate'],
      itemsPerPage: 25,
      totalItems: 0,
    },
    paginationFailed: {
      sortDesc: [true],
      page: 1,
      sortBy: ['deliveryDate'],
      itemsPerPage: 25,
      totalItems: 0,
    },
    filters: {
      clientId: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      statuses: ['scheduled', 'queued', 'sent'],
    },
    filtersFailed: {
      clientId: null,
      statuses: ['failed'],
    },
  },
  actions,
  mutations,
  getters: {},
};
