<template>
  <v-simple-table
    fixed-header
    :class="['evaluation-attendees-table', isPrinting ? 'print-table' : '']"
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Name
        </th>
        <th class="text-left">
          Type
        </th>
        <th class="text-left">
          Evaluated At
        </th>
        <th class="text-left">
          Status
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="attendee in attendees"
          :key="'attendee_' + attendee.id"
      >
        <td>{{ attendee.name }}</td>
        <td>{{ attendee.licenseType }}</td>
        <td>
          <span v-if="attendee.evaluatedAt">
            {{ attendee.evaluatedAt | datetimeToClientTimezone }}
          </span>
          <span v-else>
            —
          </span>
        </td>
        <td>
          <span v-if="attendee.isEvaluated && isPrinting">
            Evaluated
          </span>
          <a
              v-if="attendee.isEvaluated && !isPrinting"
              @click="showEvaluatedForm(attendee)"
          >
            Evaluated
          </a>
          <span v-if="!attendee.isEvaluated">
            Awaiting evaluation
          </span>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'EvaluationAttendeesTable',
  mixins: [errorAlertMixins],
  props: {
    attendees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },

  computed: {
    isPrinting() {
      return this.$store.state.isPrinting;
    },
  },

  methods: {
    showEvaluatedForm(attendee) {
      this.$emit('evaluatedClick', attendee);
    },
  },
};
</script>
<style>
.evaluation-attendees-table {
  max-height: calc(100vh - 300px);
  overflow: auto;

  table {
    table-layout: fixed;
  }

  thead {
    position: sticky;
    z-index: 2;
  }
}
.evaluation-attendees-table.print-table {
  max-height: 100% !important;

  tr:hover td{
    background-color: #FFFFFF;
  }
}
</style>
