import fileDownload from 'js-file-download';
import duplicateIncidents from '@/api/duplicateIncidents';
import incidents from '@/api/incidents';

import {
  SET_DUPLICATE_INCIDENTS_ITEMS,
  SET_DUPLICATE_INCIDENT_DATA,
  SET_ORIGINAL_INCIDENT_DATA,
} from './mutations';

export const LOAD_DUPLICATE_INCIDENTS = 'LOAD_DUPLICATE_INCIDENTS';
export const LOAD_INCIDENTS_DATA = 'LOAD_INCIDENTS_DATA';
export const OVERWRITE = 'OVERWRITE';
export const KEEP_ORIGINAL = 'KEEP_ORIGINAL';
export const COMMIT = 'COMMIT';
export const DOWNLOAD_ORIGINAL_JSON = 'DOWNLOAD_ORIGINAL_JSON';
export const DOWNLOAD_DUPLICATE_JSON = 'DOWNLOAD_DUPLICATE_JSON';

export default {
  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Duplicate Incidents state.
   *
   * @return {Promise}
   */
  [LOAD_DUPLICATE_INCIDENTS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      include: 'originalIncident',
      ...filters,
    };

    const results = await duplicateIncidents.fetchList(params);
    commit(SET_DUPLICATE_INCIDENTS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Load original and duplicate incidents full data.
   *
   * @param {Function} commit - State commit.
   * @param {Object} identifiers - Identifiers of original and duplicate incidents.
   */
  [LOAD_INCIDENTS_DATA]: async ({ commit }, identifiers) => {
    const duplicateResult = await duplicateIncidents.fetchIncidentData(
      identifiers.duplicateId,
    );
    commit(SET_DUPLICATE_INCIDENT_DATA, duplicateResult);

    const originalResult = await incidents.fetchIncidentData(
      identifiers.originalId,
    );
    commit(SET_ORIGINAL_INCIDENT_DATA, originalResult);
  },

  /**
   * Overwrite original incident to duplicate.
   *
   * @param {Object} incident - Duplicate incident to overwrite.
   *
   * @return Promise
   */
  // eslint-disable-next-line no-empty-pattern
  [OVERWRITE]: ({}, incident) => duplicateIncidents.overwrite(incident.id),

  /**
   * Keep original and remove duplicate.
   *
   * @param {Object} incident - Duplicate incident to overwrite.
   *
   * @return Promise
   */
  // eslint-disable-next-line no-empty-pattern
  [KEEP_ORIGINAL]: ({}, incident) => duplicateIncidents.keepOriginal(incident.id),

  /**
   * Commit duplicated incident as new sequence number.
   *
   * @param {Object} data - Data to commit.
   *
   * @return Promise
   */
  // eslint-disable-next-line no-empty-pattern
  [COMMIT]: ({}, data) => duplicateIncidents.commit(data.incident.id, data.sequenceNumber),

  /**
   * Download incident json file.
   *
   * @param {Object} incident - Original incident object
   *
   * @return Promise
   */
  // eslint-disable-next-line no-empty-pattern
  [DOWNLOAD_ORIGINAL_JSON]: ({}, incident) => {
    const result = incidents.fetchJson(incident.id);

    return result.then(response => fileDownload(response.data, `${incident.sequenceNumber}_orig.json`));
  },

  /**
   * Download duplicate incident json file.
   *
   * @param {Object} incident - Original incident object
   *
   * @return Promise
   */
  // eslint-disable-next-line no-empty-pattern
  [DOWNLOAD_DUPLICATE_JSON]: ({}, incident) => {
    const result = duplicateIncidents.fetchJson(incident.id);

    return result.then(response => fileDownload(
      response.data,
      `${incident.sequenceNumber}_duplicate.json`,
    ));
  },
};
