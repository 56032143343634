import { render, staticRenderFns } from "./SharePresetFieldsNotification.vue?vue&type=template&id=0a4c9dcd&scoped=true&"
import script from "./SharePresetFieldsNotification.vue?vue&type=script&lang=js&"
export * from "./SharePresetFieldsNotification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4c9dcd",
  null
  
)

export default component.exports