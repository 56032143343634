import axios from 'axios';
import api from './api';

/**
 * Fetch presigned data for upload to s3
 *
 * @param {string} filename - Name of file which need to upload.
 *
 * @return {Promise}
 */
export function getPresignedData(filename) {
  const params = {
    filename,
  };

  return api()
    .get('/upload-data', { params })
    .then(response => response.data);
}

/**
 * Upload file to s3
 *
 * @param {Object} presignedData - presigned request data.
 * @param {File} file - File to upload
 * @param {Function} progressCallBack - Callback of set progress
 *
 * @return {Promise}
 */
export function upload(presignedData, file, progressCallBack = null) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  if (presignedData.serverSideEncryption) {
    headers['x-amz-server-side-encryption'] = presignedData.serverSideEncryption;
  }

  const config = {
    headers,
  };
  if (progressCallBack) {
    config.onUploadProgress = progressEvent => {
      if (progressEvent.lengthComputable) {
        const progressPercent = (progressEvent.loaded * 100) / progressEvent.total;
        progressCallBack(parseInt(progressPercent, 10));
      }
    };
  }
  return axios.put(presignedData.uploadUrl, file, config);
}
