<template>
  <v-list>
    <v-list-item-group>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <b>Incident Number:</b> {{ incident.IncidentNumber }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <b>Date</b>: {{ incident.IncidentDate | date }} {{ incident.IncidentTime }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><b>Address</b>: {{ address }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'IncidentInfo',
  props: {
    incident: {
      type: Object,
      required: true,
    },
  },
  computed: {
    address() {
      const addressFields = [
        'IncidentStreetNumber',
        'IncidentStreetDir',
        'IncidentStreetName',
        'IncidentStreetType',
        'IncidentAptNumber',
      ];

      const addressChunks = [];
      addressFields.forEach(field => {
        if (this.incident[field]) {
          addressChunks.push(this.incident[field]);
        }
      });
      return addressChunks.join(' ');
    },
  },
};
</script>
