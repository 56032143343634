var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"column-manager",attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('b',[_vm._v("Selective Export")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search fields...","append-icon":"search","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('preset-manager',{ref:"presetManager",attrs:{"title":"Export Preset","type":_vm.presetTypes.EXPORT,"presets":_vm.entity.exportPresets,"data":_vm.presetData,"blank-data":{
        selected: [],
        groupBy: _vm.fieldsGroupBy.VALUES,
        showHistoricalFields: this.showHistoricalFields,
      }},on:{"selected":_vm.setPreset,"deleted":_vm.deletePresetHandler,"updated":_vm.updatePresetHandler,"added":_vm.addPresetHandler}}),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"400px"}},[_c('fields-selector',{ref:"fieldsSelector",attrs:{"all-fields":_vm.allFields,"search":_vm.search,"show-historical-fields":_vm.showHistoricalFields,"group-by":_vm.groupBy,"locked":[
            _vm.incidentFields.SEQUENCE_NUMBER,
            _vm.incidentFields.INCIDENT_NUMBER,
            _vm.incidentFields.INCIDENT_DATE
        ],"selected":_vm.selected},on:{"update:selected":function($event){_vm.selected=$event}}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-checkbox',{attrs:{"label":"Show historical fields"},model:{value:(_vm.showHistoricalFields),callback:function ($$v) {_vm.showHistoricalFields=$$v},expression:"showHistoricalFields"}}),_c('span',{staticClass:"ml-5 mr-2"},[_vm._v("Group fields by:")]),_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"items":[_vm.fieldsGroupBy.NONE, _vm.fieldsGroupBy.VALUES],"dense":"","flat":""},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.exporting,"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('export', _vm.selected)}}},[_vm._v(" Export ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }