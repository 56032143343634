<template>
  <v-tooltip
      bottom
      content-class="primary tooltip-bottom"
  >
    <template v-slot:activator="{ on }">
      <v-checkbox
          v-model="valueModel"
          hide-details
          class="ml-2 mt-0 mb-1 mr-5"
          v-on="on"
      >
      <span
        slot="label"
        v-on="on"
      >Trust this browser</span>
      </v-checkbox>
    </template>
    <div class="text-center">
      If you choose to trust this browser, you will not be asked
      <br />
      for a verification code the next time you sign in.
    </div>
  </v-tooltip>
</template>
<script>
export default {
  name: 'TrustBrowserCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      valueModel: this.value,
    };
  },
  watch: {
    valueModel() {
      this.$emit('input', this.valueModel);
    },
  },
};
</script>

<style>
@import '../../styles/tooltip.css';
</style>
