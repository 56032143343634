import _ from 'lodash';

// Be careful if the default rows styles change.
const DATA_TABLE_REGULAR_ROW_HEIGHT = 48;

export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      headerTopPos: 0,
      additionalIndent: 40,
    };
  },

  computed: {
    tableHeight() {
      return (
        this.windowHeight
        - this.headerTopPos
        - (this.$refs.dataTable && this.$refs.dataTable.hideDefaultFooter
          ? -20
          : this.additionalIndent)
      );
    },
  },

  mounted() {
    this.recalculateHeights();
  },
  updated() {
    this.recalculateHeights();
  },
  created() {
    window.addEventListener('resize', this.recalculateHeights);
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateHeights);
  },
  methods: {
    recalculateHeights() {
      const headers = document.getElementsByClassName('v-data-table-header');
      const tableWrappers = document.getElementsByClassName('v-data-table__wrapper');

      if (headers.length) {
        let headerPosition = null;
        let tableScrollTop = null;

        _.forEach(headers, header => {
          if (header.offsetParent && headerPosition === null) {
            headerPosition = header.getBoundingClientRect();
          }
        });

        // We need to maintain the scroll position of the table when we expand rows
        // or add new rows to the table.
        _.forEach(tableWrappers, tableWrapper => {
          if (tableWrapper.offsetParent && tableScrollTop === null) {
            tableScrollTop = tableWrapper.scrollTop + DATA_TABLE_REGULAR_ROW_HEIGHT;
          }
        });

        if (headerPosition != null && tableScrollTop != null) {
          this.headerTopPos = headerPosition.top + headerPosition.height + tableScrollTop;
        }

        this.windowHeight = window.innerHeight;
      }
    },
  },
};
