export default {
  notifications: [
    {
      text: 'Rule', align: 'start', sortable: false, value: 'rule', width: '200px',
    },
    { text: 'Personnel', value: 'personnel', sortable: false },
    { text: '', value: 'edit', sortable: false },
    { text: '', value: 'remove', sortable: false },
  ],
  security: [
    {
      text: 'Name', align: 'start', sortable: false, value: 'name', width: '200px',
    },
    { text: 'IP - address', value: 'net', sortable: false },
    { text: '', value: 'edit', sortable: false },
    { text: '', value: 'remove', sortable: false },
  ],
};
