import api from './api';

export default {
  /**
   * Fetch list of push notifications.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise
   */
  fetchList(params) {
    return api()
      .get('/push-notifications', { params })
      .then(response => response.data);
  },

  /**
   * Delete notification.
   *
   * @param {int} notificationId - Identifier of notification
   *
   * @return Promise
   */
  deleteNotification(notificationId) {
    return api().delete(`/push-notifications/${notificationId}`);
  },

  /**
   * Create new notification.
   *
   * @param {Object} notification - Notification object
   */
  createPushNotification(notification) {
    return api()
      .post('/push-notifications', notification)
      .then(response => response.data);
  },

  /**
   * Update notification.
   *
   * @param {Object} notification - Notification object to save
   */
  savePushNotification(notification) {
    return api()
      .put(`/push-notifications/${notification.id}`, notification)
      .then(response => response.data);
  },
};
