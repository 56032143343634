<template>
  <div>
    <v-toolbar
      flat
      :class="[$style['toolbar'], 'mb-3']"
    >
      <v-select
          v-model="filters.type"
          :items="viewTypes"
          label="View Type"
          item-text="name"
          item-value="value"
          hide-details
          style="max-width: 250px"
          class="mr-3"
      />
      <date-range-picker-with-periods
          v-model="dateRange"
          position="right"
          :label="rangeLabel"
          class="mr-3"
          @closed="datePickerCloseHandler"
      >
        <div
          slot="header"
          :class="[$style['date-header']]"
        >
          <v-radio-group
              v-model="dateRangeFieldTmp"
              row
          >
            <v-radio
                label="Incident Date"
                value="incidentDateTime"
            />
            <v-radio
                label="Date PCR Uploaded"
                value="datePCRUploaded"
            />
            <v-radio
                label="Sent To Billing"
                value="posted"
            />
          </v-radio-group>
        </div>
      </date-range-picker-with-periods>
      <v-text-field
          v-model="filters.sequenceNumber"
          label="Sequence Number"
          hide-details
          class="mr-3"
      />
      <v-text-field
          v-model="filters.incidentNumber"
          label="Incident Number"
          hide-details
          class="mr-3"
      />
      <v-spacer />
      <v-btn
          :disabled="loading"
          color="primary"
          class="ma-2"
          @click="$refs.settingsPopup.show()"
      >
        <v-icon
            left
            dark
        >
          mdi-cog-outline
        </v-icon>
        Export Settings
      </v-btn>

      <v-btn
          class="float-right"
          color="primary"
          :disabled="!pagination.totalItems"
          :loading="downloading"
          @click="exportXlsx"
      >
        Export XLSX
      </v-btn>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="pagination.totalItems"
        :loading="loadingTable"
        :footer-props="{
            itemsPerPageOptions: [10,25,50]
          }"
        :height="tableHeight"
        fixed-header
    >
      <template v-slot:item.incidentDateTime="{ item }">
        {{ item.incidentDateTime | date }}
      </template>
      <template v-slot:item.datePCRUploaded="{ item }">
        {{ item.datePCRUploaded | datetimeToClientTimezone }}
      </template>
      <template v-slot:item.sequenceNumber="{ item }">
        <a @click="viewIncident(item)">{{ item.sequenceNumber }}</a>
      </template>
      <template v-slot:item.incidentNumber="{ item }">
        <a @click="viewIncident(item)">{{ item.incidentNumber }}</a>
      </template>
      <template v-slot:item.posted="{ item }">
          <span v-if="item.status === exportStatuses.SUCCESS">
            {{ item.posted | date }}
          </span>
      </template>
      <template v-slot:item.status="{ item }">
          <span
              v-if="item.status === exportStatuses.PENDING"
              :class="$style['status-pending']"
          >Processing</span>
        <span
            v-else-if="item.status === exportStatuses.SUCCESS"
            :class="[$style['status-success']]"
        >Sent</span>

        <v-tooltip
            v-else-if="item.status === exportStatuses.ERROR"
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
              <span
                  :class="[$style['status-error']]"
                  v-bind="attrs"
                  v-on="on"
              >Error</span>
          </template>
          <span>{{ item.comment || 'No Comment' }}</span>
        </v-tooltip>

        <span v-else>Not Sent</span>
      </template>
      <template v-slot:item.scheduledOn="{ item }">
        {{ item.scheduledOn | date }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
            v-if="item.inQueue"
            :loading="loadingSchedule.indexOf(item.id) !== -1"
            small
            @click="override(item.id)"
        >
          Override
        </v-btn>

        <v-btn
            v-if="!item.inQueue
            && (item.status === null || item.status === exportStatuses.ERROR)
            && !item.scheduledOn"
            :loading="loadingSchedule.indexOf(item.id) !== -1"
            small
            color="primary"
            @click="schedule(item.id)"
        >
          Schedule
        </v-btn>
      </template>

    </v-data-table>

    <confirmation ref="confirmationPopup"/>

    <view-incident-popup
        ref="viewIncidentPopup"
        :show-flag-btn="false"
        @view-incident="viewIncident"
    />

    <settings-popup ref="settingsPopup"/>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'js-file-download';
import advancedQuery from '@/api/advancedQuery';
import EXPORT_STATUSES from '@/enums/exportStatuses';
import ROUTES from '@/enums/routes';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import Confirmation from '@/components/Confirmation';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';
import SettingsPopup from '@/components/ExportLog/SettingsPopup';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'AdvancedQuery',
  mixins: [fixedHeader],
  components: {
    SettingsPopup, ViewIncidentPopup, Confirmation, DateRangePickerWithPeriods,
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      loadingSchedule: [],
      downloading: false,
      items: [],
      debounce: null,
      exportStatuses: EXPORT_STATUSES,
      filters: {
        type: 'all',
        sequenceNumber: null,
        incidentNumber: null,
      },
      directDownloadLimit: 10000,
      dateRange: [],
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['incidentDateTime'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
      additionalIndent: -10,
      routes: ROUTES,
      viewTypes: [
        { name: 'All', value: 'all' },
        { name: 'All Records Sent', value: 'sent' },
        { name: 'All Records NOT Sent', value: 'not_sent' },
      ],
      headers: [
        {
          text: this.$t('advancedQuery.tableHeaders.incidentDate'),
          value: 'incidentDateTime',
        },
        {
          text: this.$t('advancedQuery.tableHeaders.datePCRUploaded'),
          value: 'datePCRUploaded',
        },
        {
          text: this.$t('advancedQuery.tableHeaders.sentToBilling'),
          value: 'posted',
        },
        {
          text: this.$t('advancedQuery.tableHeaders.sequenceNumber'),
          value: 'sequenceNumber',
        },
        {
          text: this.$t('advancedQuery.tableHeaders.incidentNumber'),
          value: 'incidentNumber',
        },
        {
          text: this.$t('advancedQuery.tableHeaders.lastName'),
          value: 'lastName',
          sortable: false,
        },
        {
          text: this.$t('advancedQuery.tableHeaders.mode'),
          value: 'mode',
          sortable: false,
        },
        {
          text: this.$t('advancedQuery.tableHeaders.status'),
          value: 'status',
        },
        {
          text: this.$t('advancedQuery.tableHeaders.scheduledOn'),
          value: 'scheduledOn',
          sortable: false,
        },
        {
          sortable: false,
          value: 'action',
          align: 'end',
        },
      ],
      dateRangeField: 'incidentDateTime',
      dateRangeFieldTmp: 'incidentDateTime',
    };
  },
  computed: {
    rangeLabel() {
      const map = {
        incidentDateTime: 'Incident Date Range',
        datePCRUploaded: 'Date PCR Uploaded Range',
        posted: 'Sent To Billing Range',
      };
      return map[this.dateRangeField];
    },
  },
  watch: {
    pagination: {
      handler(newValue, oldValue) {
        if (!this.loading && !_.isEqual(newValue, oldValue)) {
          this.loadTableData();
        }
      },
      deep: true,
    },
    dateRangeField() {
      if (this.dateRange && this.dateRange.length) {
        this.pagination.page = 1;
        this.loadTableDataDebounce();
      }
    },
    filters: {
      handler() {
        this.pagination.page = 1;
        this.loadTableDataDebounce();
      },
      deep: true,
    },
    dateRange() {
      this.pagination.page = 1;
      if (!this.loading) {
        this.loadTableDataDebounce();
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async exportXlsx() {
      if (this.pagination.totalItems > this.directDownloadLimit) {
        this.$refs.confirmationPopup.showConfirm(
          'Warning',
          'Because this export contains > 10k rows of data, this report will take ~1 minute to produce. '
            + 'Once the export has been completed, you will receive an email with a link to download the file.',
          async () => {
            await advancedQuery.exportToEmail(this.getParams());
          },
        );
      } else {
        try {
          this.downloading = true;
          const response = await advancedQuery.export(this.getParams());
          fileDownload(response.data, 'billingExportDetailed.xlsx');
        } finally {
          this.downloading = false;
        }
      }
    },

    /**
     * Load full data for advanced query page.
     */
    async fetchData() {
      this.loading = true;
      try {
        await Promise.all([
          this.loadTableData(),
        ]);
      } finally {
        this.loading = false;
      }
    },

    loadTableDataDebounce() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.loadTableData();
        }, 300);
      }
      if (!this.loading) {
        this.debounce();
      }
    },

    /**
     * Load data for advanced query table.
     */
    async loadTableData() {
      this.loadingTable = true;
      try {
        const result = await advancedQuery.fetchResult(this.getParams());
        this.items = result.results;
        this.pagination.totalItems = result.pagination.total;
      } finally {
        this.loadingTable = false;
      }
    },

    getParams() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        order_by: this.pagination.sortBy[0] || 'incidentDateTime',
        sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        timeOffset: null,
      };
      const date = new Date();
      params.timeOffset = date.getTimezoneOffset() * -1;

      let rangeFilters = {};
      if (this.dateRange && this.dateRange.length) {
        const fromDate = moment(this.dateRange[0]).startOf('day');
        const toDate = moment(this.dateRange[1]).endOf('day');
        rangeFilters = {
          rangeField: this.dateRangeField,
          rangeFrom: fromDate.utc().format('YYYY-MM-DD HH:mm:ss') || null,
          rangeTo: toDate.utc().format('YYYY-MM-DD HH:mm:ss') || null,
        };
      }
      return {
        ...params,
        ...this.filters,
        ...rangeFilters,
      };
    },

    async schedule(incidentId) {
      this.loadingSchedule.push(incidentId);
      try {
        await advancedQuery.schedule(incidentId);
        await this.fetchData();
      } finally {
        this.loadingSchedule.splice(this.loadingSchedule.indexOf(incidentId), 1);
      }
    },

    async override(incidentId) {
      this.loadingSchedule.push(incidentId);
      try {
        await advancedQuery.override(incidentId);
        await this.fetchData();
      } finally {
        this.loadingSchedule.splice(this.loadingSchedule.indexOf(incidentId), 1);
      }
    },

    viewIncident(incident) {
      let previousIncident = null;
      let nextIncident = null;
      this.items.forEach((item, index) => {
        if (item.id === incident.id) {
          if (index > 0) {
            previousIncident = this.items[index - 1];
          }
          if (index < this.items.length - 1) {
            nextIncident = this.items[index + 1];
          }
        }
      });
      this.$refs.viewIncidentPopup.show(
        { id: incident.id, II_SequenceNumber: incident.sequenceNumber },
        true,
        true,
        false,
        null,
        previousIncident,
        nextIncident,
      );
    },

    /**
     * Handler of datetime range picker close action.
     *
     * @param {Boolean} applied - If closed by applied button
     * @param {Boolean} cancelled - If closed by cancel button
     */
    datePickerCloseHandler(applied, cancelled) {
      if (this.dateRangeField !== this.dateRangeFieldTmp && !cancelled) {
        this.dateRangeField = this.dateRangeFieldTmp;
      }
      if (this.dateRangeField !== this.dateRangeFieldTmp && cancelled) {
        this.dateRangeFieldTmp = this.dateRangeField;
      }
    },
  },
};
</script>

<style lang="scss" module>
.toolbar {
  z-index: 10;
}

.date-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: thin solid #eee;
  padding-top: 15px;
  padding-left: 15px;
}

.popup-title {
  font-size: 22px;
}
.status-success {
  color: green;
  font-weight: bold;
}
.status-error {
  color: red;
  font-weight: bold;
}
.status-pending {
  color: blue;
  font-weight: bold;
}
</style>
