<template>
  <div>
    <v-text-field
        v-model="search"
        label="Search Provider"
        class="mb-2"
        hide-details
        style="max-width: 300px"
    />

    <v-data-table
        :headers="headers"
        :items="filteredProviders"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        fixed-header
        class="providers-settings-table"
    >
      <template v-slot:item.isDefault="{ item }">
        {{ item.isDefault ? 'General' : 'Custom' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="table-actions">
          <v-btn
              v-if="!item.isDefault"
              icon
              class="mr-1"
              title="Edit"
              @click="editProvider(item)"
          >
            <v-icon color="grey darken-2">edit</v-icon>
          </v-btn>

          <v-btn
              icon
              title="Delete"
              @click="deleteProvider(item)"
          >
            <v-icon color="grey darken-2">delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <confirmation ref="confirmationPopup" />
    <edit-provider-popup
        ref="providerDialog"
        @saved="savedProviderHandler"
    />
  </div>
</template>
<script>
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import ceHoursSettings from '@/api/ceHoursSettings';
import Confirmation from '@/components/Confirmation';
import EditProviderPopup from '@/components/CeEntry/Settings/EditProviderPopup';

export default {
  name: 'ProviderList',
  mixins: [componentMixins],
  components: {
    EditProviderPopup,
    Confirmation,
  },
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Number',
          sortable: true,
          value: 'numberId',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Type',
          sortable: true,
          value: 'isDefault',
        },
        {
          text: 'Actions',
          sortable: false,
          width: 150,
          value: 'actions',
        },
      ],
      providers: [],
      sortBy: 'numberId',
      sortDesc: false,
    };
  },
  computed: {
    filteredProviders() {
      return this.providers.filter(item => {
        let addToResult = false;
        if (!this.search) {
          addToResult = true;
        }
        if (this.search
            && ((item.name && item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                || item.numberId.indexOf(this.search.toLowerCase()) !== -1
            )
        ) {
          addToResult = true;
        }
        return addToResult;
      });
    },
  },
  mounted() {
    this.fetchProviders();
  },
  methods: {
    async fetchProviders() {
      try {
        this.loading = true;
        this.providers = await ceHoursSettings.getProviders();
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$refs.providerDialog.show();
    },
    editProvider(item) {
      this.$refs.providerDialog.show(item);
    },
    deleteProvider(item) {
      const name = item.name || item.numberId;
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        `Do you really want to delete the provider "${name}"?`,
        async () => {
          await ceHoursSettings.deleteProvider(item.id);
          this.fetchProviders();
        },
      );
    },
    savedProviderHandler() {
      this.fetchProviders();
    },
  },
};
</script>
<style lang="scss">
.providers-settings-table {
  .v-data-table__wrapper {
    height: calc(100vh - 380px);
  }
  thead {
    th {
      z-index: 1 !important;
    }
  }
  .table-actions {
    visibility: hidden;
  }
  tr:hover .table-actions {
    visibility: visible;
  }
}
</style>
