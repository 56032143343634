import api from './api';

export default {
  /**
   * Get list notifications.
   *
   * @param {Object} params - Params for request
   *
   * @returns {Promise}
   */
  getNotifications(params) {
    return api()
      .get('/notifications', { params })
      .then(response => response.data);
  },

  /**
   * Get total unread notifications.
   *
   * @returns {Promise}
   */
  getTotalUnreadNotifications() {
    return api()
      .get('notifications/total-unread')
      .then(response => response.data);
  },

  /**
   * Remove notification by ID.
   *
   * @param {String} id - Notification ID
   *
   * @returns {Promise}
   */
  deleteNotification(id) {
    return api()
      .delete(`notifications/${id}`)
      .then(response => response.data);
  },
};
