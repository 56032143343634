export default {
  profile: {
    header: 'My Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    username: 'Username',
    email: 'Email',
    phone: 'Phone',
    password: 'Current Password',
    newPassword: 'New Password',
    retypePassword: 'Retype New Password',
  },
};
