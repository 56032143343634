<template>
  <div>
    <template v-if="groupOperator === 'not'">
      NOT
    </template>
    <template v-if="totalGroups > 1 || groupOperator === 'not'">( </template>
    <slot />
    <template v-if="totalGroups > 1 || groupOperator === 'not'"> )</template>
  </div>
</template>
<script>

export default {
  name: 'ConditionsWrapper',
  props: {
    totalGroups: {
      type: Number,
      required: true,
    },
    groupOperator: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>
