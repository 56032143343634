import {
  fetchAdminDashboardClients,
  fetchAdminDashboardTotals,
} from '@/api/dashboard';

import { SET_CLIENTS, SET_TOTALS } from './mutations';

export const FETCH_DATA = 'FETCH_DATA';

export default {
  [FETCH_DATA]: ({ commit }) => {
    fetchAdminDashboardTotals().then(data => commit(SET_TOTALS, data));
    return fetchAdminDashboardClients().then(data => commit(SET_CLIENTS, data));
  },
};
