import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    statuses: [],
    filters: {
      clientId: null,
      sequenceNumber: null,
      status: null,
      dateFrom: null,
      dateTo: null,
    },
    serverPaginationInfo: {},
    tableParams: {
      sortDesc: [true],
      page: 1,
      sortBy: ['created'],
      itemsPerPage: 25,
    },
    resendSuccessStatus: false,
    resendErrorMessage: '',
  },
  actions,
  mutations,
};
