<template>
  <div>
    <v-row>
      <v-col :sm="colSize">
        <date-range-picker-with-periods
            v-model="filters.dateRange"
            :disabled="disabled"
            position="right"
        />
      </v-col>
      <v-col :sm="colSize">
        <v-text-field
            v-model="filters.sequenceNumbers"
            :disabled="disabled"
            label="Sequence Numbers"
            hint="Comma separated"
        ></v-text-field>
      </v-col>
      <v-col :sm="colSize">
        <v-text-field
            v-model="filters.incidentNumbers"
            :disabled="disabled"
            label="Incident Numbers"
            hint="Comma separated"
        ></v-text-field>
      </v-col>
      <v-col
          v-if="showStatus"
          sm="3"
      >
        <v-select
            v-model="filters.status"
            :disabled="disabled"
            :items="flaggedStatuses"
            :label="$t('incidents.filters.status')"
            item-value="id"
            item-text="name"
            single-line
        ></v-select>
      </v-col>
      <v-col
          v-if="showDevice"
          sm="3"
      >
        <v-autocomplete
            v-model="filters.deviceId"
            :disabled="disabled"
            :items="deviceItems"
            :label="$t('incidents.filters.device')"
            :loading="loadingDevices"
            item-value="id"
            item-text="name"
            single-line
            clearable
        ></v-autocomplete>
      </v-col>
      <v-col
          v-if="showDateRemoved"
          sm="3"
      >
        <v-menu
            ref="menu"
            v-model="dateRemovedMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.dateRemoved"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="filters.dateRemoved"
                :disabled="disabled"
                label="Date Removed"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="filters.dateRemoved"
              no-title
              scrollable
          >
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(null)"
            >
              Clear
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="dateRemovedMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(filters.dateRemoved)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="showDevice">
      <v-col
          sm="3"
          class="pt-0 pb-0"
      >
        <v-checkbox
            v-model="filters.uploaded"
            label="Uploaded"
            hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import devices from '@/api/devices';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'SearchBasic',
  components: { DateRangePickerWithPeriods },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    showDateRemoved: {
      type: Boolean,
      default: false,
    },
    showDevice: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateRemovedMenu: null,
      flaggedStatuses: [
        { id: null, name: 'All Statuses' },
        { id: 0, name: 'Pending' },
        { id: 1, name: 'Understood' },
        { id: 2, name: 'Did not understand' },
      ],
      devices: null,
      loadingDevices: false,
      updateData: true,
    };
  },
  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters, this.updateData);
        this.updateData = true;
      },
      deep: true,
    },
  },
  computed: {
    colSize() {
      return this.showStatus || this.showDateRemoved || this.showDevice ? 3 : 4;
    },
    deviceItems() {
      if (this.devices === null) {
        return [];
      }
      return this.devices.map((item) => ({
        id: item.registryId,
        name: `${item.dispatchId} (Inventory #${item.inventoryNumber})`,
      }));
    },
  },
  mounted() {
    if (this.showDevice && this.devices === null) {
      this.loadDevices();
    }
  },
  methods: {
    /**
     * Load list of client's devices.
     */
    async loadDevices() {
      this.loadingDevices = true;
      try {
        this.devices = await devices.fetchClientDevices();
      } finally {
        this.loadingDevices = false;
      }
    },

    /**
     * Set filters.
     *
     * @param {Object} filters - List of filters to set.
     * @param {Boolean} updateData - Is there a need to update data after set filter.
     */
    setFilters(filters, updateData) {
      this.updateData = updateData;
      _.forEach(filters, (value, name) => {
        this.filters[name] = value;
      });
    },

    /**
     * Reset filters.
     */
    reset() {
      const filters = {};
      _.forEach(this.filters, (value, name) => {
        filters[name] = null;
      });
      this.$emit('filtersUpdate', filters);
    },
  },
};
</script>

<style lang="scss" module>

</style>
