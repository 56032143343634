import auditLog from '@/api/auditLog';
import { SET_ITEMS } from '@/store/TransactionLogs/mutations';

export const LOAD_ITEMS = 'LOAD_ITEMS';

export default {
  /**
     * Load transaction logs.
     *
     * @param {Function} commit - State commit.
     * @param {Object} state - state.
     */
  [LOAD_ITEMS]: async ({ commit, state }) => {
    const { pagination, filters } = state;
    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await auditLog.fetchIncidentMainAuditLogs(params);
    commit(SET_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });
  },

};
