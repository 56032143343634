import Confirmation from '@/components/Confirmation';

export default {
  components: {
    Confirmation,
  },
  data() {
    return {
      /**
       * Flag for display popup.
       */
      showEditPopup: false,
      /**
       * Is rule new (it will be created or already exists)
       */
      isNew: false,
      /**
       * New email to add to array.
       */
      newEmail: '',
      /**
       * Notification emails array.
       */
      emails: [],
      /**
       * Days to wait before sending notification.
       */
      daysToWaitBeforeSending: '',
      /**
       * Type of notification rule.
       */
      type: '',
      /**
       * Rule id if rule already exists.
       */
      ruleId: null,
    };
  },
  methods: {
    /**
     * Show and initialize popup.
     * @param item Rule data
     * @returns {Promise<void>}
     */
    showPopup(item) {
      this.$validator.reset();
      this.isNew = !item;
      this.showEditPopup = true;
      this.newEmail = '';
      if (this.isNew) {
        this.daysToWaitBeforeSending = '';
        this.type = '';
        this.emails = [];
        this.ruleId = null;
      } else {
        this.type = item.type;
        this.emails = item.emails;
        this.ruleId = item.ruleId;
        this.daysToWaitBeforeSending = item.daysToWaitBeforeSending;
      }
    },
    /**
     * Add new email to array.
     */
    async addEmail() {
      const isNewEmailValid = await this.$validator.validate('email');
      if (this.newEmail === '' || !isNewEmailValid) {
        return;
      }

      this.emails.push(this.newEmail);
      this.newEmail = '';
    },
    /**
     * Delete selected email from array.
     * @param emailToDelete
     */
    deleteEmail(emailToDelete) {
      this.emails = this.emails.filter(email => email !== emailToDelete);
    },
    /**
     * Save rule data and send API request.
     * @returns {Promise<void>}
     */
    async saveRule() {
      if (!await this.$validator.validateAll()) {
        return;
      }

      const rule = {
        type: this.type,
        emails: this.emails,
        daysToWaitBeforeSending: this.daysToWaitBeforeSending,
      };

      this.saveNotificationRule({ rule, ruleId: this.ruleId });
      this.showEditPopup = false;
    },
    /**
     * Show confirmation for deleting email.
     * @param email
     */
    showConfirmation(email) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.deleteEmailTitle'),
        `Do you want to delete ${email} from list?`,
        () => {
          this.deleteEmail(email);
        },
      );
    },
  },
};
