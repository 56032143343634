<template>
  <v-card
      class="elevation-20"
  >
    <v-toolbar
        dark
        color="primary"
    >
      <v-toolbar-title class="font-weight-medium">Complete your account</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!isCompleted">
      <v-form
          :disabled="loading"
          @keyup.native.enter="register"
      >
        <div class="text-center">
          Please complete the registration by filling in your account details below.
          Set your own new password to access the account.
        </div>

        <v-alert
            :value="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>

        <v-layout
            wrap
        >
          <v-row class="mb-0">
            <v-col
                cols="12"
                class="py-1"
            >
              <v-text-field
                  ref="userName"
                  v-model="user.userName"
                  v-validate="'required'"
                  :error-messages="errors.collect('userName')"
                  disabled
                  prepend-inner-icon="person"
                  name="userName"
                  label="User Name"
                  type="text"
              />
            </v-col>

            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="firstName"
                  v-model="user.firstName"
                  v-validate="'required'"
                  :error-messages="errors.collect('firstName')"
                  name="firstName"
                  label="First Name"
              />
            </v-col>

            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="lastName"
                  v-model="user.lastname"
                  v-validate="'required'"
                  :error-messages="errors.collect('lastName')"
                  name="lastName"
                  label="Last Name"
              />
            </v-col>

            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="localLicense"
                  v-model="user.EMT"
                  :error-messages="errors.collect('localLicense')"
                  name="localLicense"
                  label="Local License #"
              />
            </v-col>

            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="stateLicense"
                  v-model="user.stateLicense"
                  :error-messages="errors.collect('stateLicense')"
                  name="stateLicense"
                  label="State License #"
              />
            </v-col>

            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="nationalLicense"
                  v-model="user.nationalLicense"
                  :error-messages="errors.collect('nationalLicense')"
                  name="nationalLicense"
                  label="National License #"
              />
            </v-col>
          </v-row>
          <v-row class="mb-0">
            <v-col
                cols="6"
                class="py-1"
            >
              <vue-tel-input-vuetify
                  v-model="phoneModel"
                  label="Phone Number"
                  :preferred-countries="['US']"
                  :disabled-fetching-country="true"
                  :error-messages="phoneNumberErrorMessages"
                  name="phone"
                  outer="mdi-send"
                  mode="international"
                  @input="updatePhone"
              />
            </v-col>
            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  v-model="user.email"
                  v-validate="{ required: true, email: true }"
                  :error-messages="errors.collect('email')"
                  prepend-inner-icon="mdi-at"
                  name="email"
                  label="Email"
                  type="text"
                  maxlength="40"
              />
            </v-col>
          </v-row>
          <v-row class="mb-0">
            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="password"
                  v-model="user.password"
                  v-validate="{ required: true, regex: passwordRegexp }"
                  :error-messages="errors.collect('password')"
                  prepend-inner-icon="lock"
                  name="password"
                  label="New Password"
                  type="password"
              />
            </v-col>
            <v-col
                cols="6"
                class="py-1"
            >
              <v-text-field
                  ref="passwordConfirm"
                  v-model="passwordConfirm"
                  v-validate="'required|confirmed:password'"
                  :error-messages="errors.collect('passwordConfirm')"
                  prepend-inner-icon="lock"
                  name="passwordConfirm"
                  label="Retype Password"
                  type="password"
              />
            </v-col>
            <v-col
                cols="12"
                class="py-1"
            >
              <i>{{ $t('general.passwordFormat') }}</i>
            </v-col>
          </v-row>
        </v-layout>

      </v-form>
    </v-card-text>
    <v-card-text
      v-if="isCompleted"
      class="text-center"
    >
      Your account has been completed. Please use your username "<b>{{ user.userName }}</b>"
      and the new password to authenticate in the system.
    </v-card-text>
    <v-card-actions>
      <a
          v-if="!isCompleted"
          class="ml-3"
          @click="$emit('backToLogin')"
      >Back to Login</a>

      <v-spacer/>

      <v-btn
          v-if="!isCompleted"
          :loading="saving || loading"
          class="login__button"
          color="primary"
          @click="submitUserData"
      >
        Complete account
      </v-btn>
      <v-btn
          v-if="isCompleted"
          class="login__button"
          color="primary"
          @click="$emit('backToLogin', user.userName)"
      >
        Sign in
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import usersApi from '@/api/users';

export default {
  mixins: [errorAlertMixins],
  name: 'CompleteClientUserForm',
  props: {
    token: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      saving: false,
      loading: true,
      user: {
        userName: null,
        firstName: null,
        lastname: null,
        email: null,
        phone: null,
        EMT: null,
        stateLicense: null,
        nationalLicense: null,
        password: null,
      },
      passwordConfirm: null,
      phoneModel: null,
      phoneIsValid: true,
      dictionary: {
        attributes: {
          userName: 'User Name',
          email: 'Email',
          firstName: 'First Name',
          lastName: 'Last Name',
          password: 'New Password',
          passwordConfirm: 'Retype Password',
        },
      },
      passwordRegexp: validatePasswordRegexp,
      isCompleted: false,
    };
  },
  computed: {
    phoneNumberErrorMessages() {
      const messages = this.errors.collect('phone');
      if (!this.phoneIsValid) {
        messages.push('The Phone field is invalid');
      }
      return messages;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
    this.fetchUncompletedUser();
  },

  methods: {
    /**
     * Fetch uncompleted user.
     *
     */
    async fetchUncompletedUser() {
      this.loading = true;
      try {
        const userData = await usersApi.getUncompletedUser(this.token);
        this.user.userName = userData.userName;
        this.user.firstName = userData.firstName;
        this.user.lastname = userData.lastname;
        this.user.email = userData.email;
        this.user.phone = userData.phone;
        this.user.EMT = userData.EMT;
        this.user.stateLicense = userData.stateLicense;
        this.user.nationalLicense = userData.nationalLicense;
      } finally {
        this.loading = false;
      }
    },

    /**
     * Update phone.
     *
     * @param {String} number - Phone number
     * @param {Object} data - Phone data
     */
    updatePhone(number, data) {
      this.phoneIsValid = data.isValid;
      if (data.isValid === true) {
        this.user.phone = data.number.e164;
      } else {
        this.user.phone = '';
      }
    },

    /**
     * Validates phone.
     *
     * @return {Boolean}
     */
    validatePhone() {
      if (!this.user.phone) {
        this.phoneIsValid = false;
      }
      return this.phoneIsValid;
    },

    async submitUserData() {
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
      this.validatePhone();
      if (!await this.$validator.validateAll() || !this.phoneIsValid) {
        return;
      }
      this.saving = true;
      try {
        const submitData = _.cloneDeep(this.user);
        submitData.token = this.token;
        await usersApi.completeUser(submitData);
        this.isCompleted = true;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
