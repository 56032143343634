<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="900px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text class="pt-3">
          Loading...
          <v-progress-linear
              indeterminate
              color="primary"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-toolbar flat>
          <span class="invoice-popup-title">
            {{ isNew ? 'Invoice Creation' : 'Edit Invoice' }}: {{ client ? client.name : '' }}
          </span>
          <v-spacer />
          <v-text-field
            v-model="invoice.invoiceNumber"
            v-validate="{ integer: true, min_value: 0, max_value: 99999 }"
            placeholder="Custom Invoice Number"
            name="invoiceNumber"
            :error-messages="errors.collect('invoiceNumber')"
            prefix="#"
            maxlength="5"
            style="max-width: 190px"
          />
        </v-toolbar>
        <v-divider class="mb-5" />
        <v-card-text>

          <v-row>
            <v-col
                md="6"
                class="pt-0 pb-0"
            >
              <date-range-picker-with-periods
                v-model="projectionRange"
                :loading="loadingProjections"
                :disabled="generating"
                :periods="periods"
                :clearable="false"
                label="Projections Based On"
                position="right"
                @change="changeProjectionHandler"
              />
              <div class="float-right mt-4">
                <span style="color: red;"><b>{{ invoice.projectedIncidents }}</b></span> Incidents
              </div>
            </v-col>
            <v-col
                md="6"
                class="pt-0 pb-0"
            >
              <date-range-picker-with-periods
                  v-model="fiscalDateRange"
                  v-validate="{ required: true }"
                  name="fiscalDateRange"
                  :error-messages="errors.collect('fiscalDateRange')"
                  :disabled="!isNew || generating"
                  :hide-details="false"
                  :periods="periods"
                  :clearable="false"
                  label="Fiscal Date Range"
                  position="left"
              />

              <v-text-field
                  key="increase"
                  v-model="invoice.increase"
                  v-validate="{ required: true, integer: true, max_value: 1000, min_value: -100}"
                  name="increase"
                  :error-messages="errors.collect('increase')"
                  :disabled="generating"
                  label="% Increase"
                  suffix="%"
                  maxlength="4"
              />

              <div class="float-right">
                <span style="color: red;"><b>{{ estimatedIncidents }}</b></span> Incidents
              </div>
              <div class="clearfix"></div>

              <v-text-field
                  key="previousInvoiceIncidents"
                  v-model="invoice.previousInvoiceIncidents"
                  v-validate="{ integer: true, min_value: 0 }"
                  name="previousInvoiceIncidents"
                  :error-messages="errors.collect('previousInvoiceIncidents')"
                  :disabled="generating"
                  label="Amt. PPU Paid In Previous Invoice"
                  hint="Leave empty if NA"
                  maxlength="6"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              md="6"
              class="pt-0 pb-0"
            >
              <v-select
                  v-model="invoice.type"
                  :items="types"
                  :disabled="generating"
                  style="max-width: 300px"
                  label="Billing Type"
              />

              <v-select
                  v-model="invoice.typeFaxing"
                  :items="types"
                  :disabled="generating"
                  style="max-width: 300px"
                  label="Faxing Billing Type"
              />
              <template v-for="(charge, index) in invoice.miscCharges">
                <v-text-field
                  :key="'charge_description_' + index"
                  v-model="charge.description"
                  v-validate="{ required: true }"
                  :error-messages="errors.collect('MiscChargeDescription #' + (index+1))"
                  :disabled="generating"
                  :name="'MiscChargeDescription #' + (index+1)"
                  label="Misc Charge"
                  maxlength="1000"
                />
              </template>
              <v-btn
                  :disabled="generating"
                  small
                  text
                  color="blue"
                  @click="addMiscCharge"
              >+ Add Miscellaneous Charge</v-btn>
            </v-col>
            <v-col
              md="6"
              class="pt-0 pb-0"
            >
              <v-text-field
                  key="price"
                  v-model="invoice.price"
                  v-validate="{ required: true, decimal: 2, min_value: 0 }"
                  :error-messages="errors.collect('price')"
                  :label="invoice.type === 'ppu' ? 'PPU Price' : 'Fixed Price'"
                  :disabled="generating"
                  name="price"
                  prefix="$"
                  maxlength="9"
                  @keyup="invoice.price = fixDecimal(invoice.price)"
              />

              <v-text-field
                  key="priceFaxing"
                  v-model="invoice.priceFaxing"
                  v-validate="{ decimal: 2, min_value: 0 }"
                  :error-messages="errors.collect('priceFaxing')"
                  :label="invoice.typeFaxing ==='ppu'
                  ? 'PPU Cost Of Faxing'
                  : 'Fixed Cost Of Faxing'"
                  :disabled="generating"
                  name="priceFaxing"
                  prefix="$"
                  hint="Leave empty if NA"
                  maxlength="9"
                  @keyup="invoice.priceFaxing = fixDecimal(invoice.priceFaxing)"
              />

              <template v-for="(charge, index) in invoice.miscCharges">
                <v-text-field
                    :key="'charge_price_' + index"
                    v-model="charge.price"
                    v-validate="{ required: true, decimal: 2 }"
                    :error-messages="errors.collect('MiscChargePrice #' + (index+1))"
                    :name="'MiscChargePrice #' + (index+1)"
                    :disabled="generating"
                    label="Charge Price"
                    append-icon="mdi-close"
                    prefix="$"
                    maxlength="9"
                    @click:append="deleteMiscCharge(index)"
                    @keyup="charge.price = fixDecimal(charge.price)"
                />
              </template>
            </v-col>
          </v-row>

          <div class="separator overview">
            Invoice Overview
          </div>

          <div class="invoice-overview">
            <div class="mb-2">
              Projections Based On:
              <span style="color: red;">{{ projectionRange | dateRange }}</span> :
              <span style="float: right">
                <span style="color: red;">{{ invoice.projectedIncidents }}</span> incidents
              </span>
            </div>
            <v-divider class="mb-2"/>
            <div class="mb-2">
              For Fiscal {{ isStandardFiscalYear ? 'Year' : 'Date Range' }}:
              <span style="color: red;">{{ fiscalDateRange | dateRange }}</span> :
              <span style="float: right">
                <span style="color: red;">{{ estimatedIncidents }}</span> incidents
              </span>
            </div>
            <div
                v-if="invoice.previousInvoiceIncidents
                && !isNaN(invoice.previousInvoiceIncidents)"
                class="mb-2"
            >
              Amt. PPU Paid In Previous Invoice:
              <span style="float: right">
                <span style="color: red;">
                  {{ this.invoice.previousInvoiceIncidents }}
                </span> incidents
              </span>
            </div>
            <div
                v-if="invoice.previousInvoiceIncidents"
                class="mb-2"
            >
              <span v-if="credit >= 0">- PPU Credit From Previous Invoice</span>
              <span v-if="credit < 0">+ PPU Overage From Previous Invoice</span>
              <span style="float: right">
                <span
                  style="color: red;"
                >
                  {{ credit | abs }}
                </span> incidents
              </span>
            </div>
            <v-divider class="mb-2"/>
            <div class="mb-2">
              Total PPU For This Invoice:
              <span style="float: right">
                <b><span style="color: red;">
                {{ totalPPUForInvoice }}</span> incidents</b>
              </span>
            </div>
            <div
                v-if="invoice.type === 'ppu'"
                class="mb-2"
            >
              <span style="display: inline-block; width: 169px;">
                <span style="float: right">@ ${{ invoice.price | price }}:</span>
              </span>
              <span style="color: red; float: right">${{ amountPPU | price }}</span>
            </div>
            <div
                v-if="invoice.type === 'fixed'"
                class="mb-2"
            >
              Fixed Price:
              <span style="color: red; float: right">${{ amountPPU | price }}</span>
            </div>

            <div
                v-if="invoice.typeFaxing === 'ppu'"
                class="mb-2"
            >
              Total Cost of Faxing @ ${{ invoice.priceFaxing | price }}:
              <span style="color: red; float: right">${{ amountFaxing | price }}</span>
            </div>
            <div
                v-if="invoice.typeFaxing === 'fixed'"
                class="mb-2"
            >
              Fixed Price for Faxing:
              <span style="color: red; float: right">${{ amountFaxing | price }}</span>
            </div>

            <template v-for="(item, index) in invoice.miscCharges">
              <div
                  v-if="item.description"
                  :key="'overview_misc_charge_' + index"
                  class="mb-2"
              >
                <span style="max-width: 400px; display: inline-block;">
                  {{ item.description }}:
                </span>
                <span style="color: red; float: right">${{ item.price | price }}</span>
              </div>
            </template>

            <div class="mb-2">
              <b>
                Total For This Invoice:
                <span style="color: red; float: right">${{ total | price }}</span>
              </b>
            </div>
            <v-alert
                :value="errorAlert"
                type="error"
                outlined
                dense
                transition="scale-transition"
                dismissible
            >
              {{ errorMessage }}
            </v-alert>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-progress-linear
              v-if="generating"
              v-model="generatingProgress"
              height="25"
              class="mr-5"
          >
            <strong>{{ progressBarLabel }}</strong>
          </v-progress-linear>

          <v-spacer />
          <v-btn
              :disabled="generating && !pdfWaiting"
              text
              color="blue darken-1"
              @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="generating"
              @click="generateInvoiceHandler"
          >
            Generate Invoice
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"></confirmation>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import invoices from '@/api/invoices';
import Confirmation from '@/components/Confirmation';
import isFloat from '@/utils/isFloat';

export default {
  name: 'InvoicePopup',
  components: { Confirmation, DateRangePickerWithPeriods },
  mixins: [errorAlertMixins],
  filters: {
    dateRange(range) {
      const from = moment(range[0]);
      const to = moment(range[1]);
      return `${from.format('ll')} - ${to.format('ll')}`;
    },
    abs(value) {
      return Math.abs(value);
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingProjections: false,
      saving: false,
      pdfWaiting: false,
      generating: false,
      generatingProgress: 0,
      avgWaitingPdfTime: 45,
      timer: null,
      client: null,
      isNew: true,
      projectionRange: [],
      fiscalDateRange: [],
      invoice: {},
      existenceInvoice: null,
      previousInvoice: {},
      echoChannel: null,
      types: [
        { text: 'PPU (Price Per Incident Uploaded)', value: 'ppu' },
        { text: 'Fixed Billing', value: 'fixed' },
      ],
      dictionary: {
        attributes: {
          increase: '% Increase',
          previousInvoiceIncidents: 'Amt. PPU Paid In Previous Invoice',
          price: 'Price',
          priceFaxing: 'Cost Of Faxing',
          invoiceNumber: 'Custom Invoice Number',
          fiscalDateRange: 'Fiscal Date Range',
        },
      },
    };
  },

  watch: {
    fiscalDateRange(currentValue, previousValue) {
      if (!currentValue.length || !previousValue.length) {
        this.changeProjectionHandler();
      }
      if (this.checkIsStandardFiscalYear(currentValue[0], currentValue[1])
          !== this.checkIsStandardFiscalYear(previousValue[0], previousValue[1])
      ) {
        this.changeProjectionHandler();
      }
    },
  },

  computed: {
    /**
     * Is current fiscal date range is standard Fiscal year 1 Jul - 30 Jun.
     *
     * @return {String}
     */
    isStandardFiscalYear() {
      return this.checkIsStandardFiscalYear(this.fiscalDateRange[0], this.fiscalDateRange[1]);
    },

    /**
     * Label of progress bar.
     *
     * @return {String}
     */
    progressBarLabel() {
      if (!this.generating) {
        return '';
      }
      if (this.saving) {
        return 'Saving data...';
      }
      if (this.pdfWaiting && this.generatingProgress < 100) {
        return `Generating PDF: ${this.generatingProgress}%`;
      }
      if (this.pdfWaiting && this.generatingProgress === 100) {
        return 'Please wait a bit...';
      }
      return '';
    },

    /**
     * Estimated incidents for the fiscal year.
     *
     * @return {Number}
     */
    estimatedIncidents() {
      const increaseCount = this.invoice.increase && this.invoice.projectedIncidents
        ? (this.invoice.projectedIncidents * this.invoice.increase) / 100
        : 0;
      const estimatedIncidents = this.invoice.projectedIncidents
        ? Math.ceil(this.invoice.projectedIncidents + increaseCount)
        : 0;
      return !this.isNaN(estimatedIncidents) ? estimatedIncidents : 0;
    },

    /**
     * Calculate total ppu for the invoice.
     *
     * @return {Number}
     */
    totalPPUForInvoice() {
      return this.estimatedIncidents - this.credit;
    },

    /**
     * Calculates amount PPU.
     *
     * @return {Number}
     */
    amountPPU() {
      if (this.invoice.type === 'ppu') {
        return this.totalPPUForInvoice * this.invoice.price;
      }
      return Number(this.invoice.price);
    },

    /**
     * Calculates amount faxing.
     *
     * @return {Number}
     */
    amountFaxing() {
      if (this.invoice.typeFaxing === 'ppu') {
        return this.totalPPUForInvoice * this.invoice.priceFaxing;
      }
      return Number(this.invoice.priceFaxing);
    },

    /**
     * Calculates total for this invoice.
     *
     * @return {Number}
     */
    total() {
      let miscCharges = 0;
      if (this.invoice && this.invoice.miscCharges) {
        this.invoice.miscCharges.forEach(item => {
          if (item.description && item.price && !this.isNaN(item.price)) {
            miscCharges += Number(item.price);
          }
        });
      }
      const amountPPU = !this.isNaN(this.amountPPU) ? this.amountPPU : 0;
      const amountFaxing = !this.isNaN(this.amountFaxing) ? this.amountFaxing : 0;
      return amountPPU + amountFaxing + miscCharges;
    },

    /**
     * Total credits remained from previous invoice.
     *
     * @return {Number}
     */
    credit() {
      if (this.invoice.previousInvoiceIncidents
          && !this.isNaN(this.invoice.previousInvoiceIncidents)
      ) {
        const credit = this.invoice.previousInvoiceIncidents
            - this.estimatedIncidents;
        return !this.isNaN(credit) ? credit : 0;
      }
      return 0;
    },

    /**
     * Get fiscal years periods.
     *
     * @return {Object}
     */
    periods() {
      let startYear = this.getCurrentFiscalYear();

      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      if (currentMonth === 6) {
        startYear++;
      }
      const result = {};
      for (let i = 0; i <= 7; i++) {
        const year = startYear - i;
        const name = `Jul, ${year} - Jun, ${year + 1}`;
        const start = new Date(year, 6, 1);
        const end = new Date(year + 1, 5, 30);
        result[name] = [start, end];
      }
      return result;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Is current fiscal date range is standard Fiscal year 1 Jul - 30 Jun.
     *
     * @return {String}
     */
    checkIsStandardFiscalYear(from, to) {
      const start = moment(from);
      const end = moment(to);
      return start.month() === 6
          && start.date() === 1
          && end.month() === 5
          && end.date() === 30
          && end.year() - start.year() === 1;
    },

    /**
     * Cancel button click handler.
     */
    cancel() {
      this.dialog = false;
      if (this.generating && this.pdfWaiting) {
        this.$echo.leave(this.echoChannel);
        this.generating = false;
        this.$emit('saved');
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    },

    /**
     * Show invoice dialog.
     *
     * @param {Object} client - Client data
     * @param {Object|null} invoice - Invoice data
     *
     * @return {Number}
     */
    async show(client, invoice = null) {
      this.hideError();
      await this.$validator.reset();
      this.$validator.pause();

      this.client = client;
      this.dialog = true;
      this.loading = true;
      this.pdfWaiting = false;
      this.saving = false;
      this.existenceInvoice = null;
      this.generating = false;
      this.generatingProgress = 0;
      this.timer = null;
      this.echoChannel = null;
      const promise = [
        this.fetchAvgWaitingPdfTime(),
      ];

      if (invoice) {
        this.invoice = _.cloneDeep(invoice);
        this.isNew = false;
        this.projectionRange = [
          invoice.projectionFrom,
          invoice.projectionTo,
        ];
        this.fiscalDateRange = [
          invoice.fiscalDateFrom,
          invoice.fiscalDateTo,
        ];
      } else {
        const currentFiscalYear = this.getCurrentFiscalYear();
        this.projectionRange = this.getPreviousFiscalYearRange();
        this.isNew = true;
        this.fiscalDateRange = [
          `${currentFiscalYear}-07-01`,
          `${currentFiscalYear + 1}-06-30`,
        ];
        this.invoice = {
          invoiceNumber: null,
          projectionFrom: null,
          projectionTo: null,
          estimatedIncidents: null,
          type: 'ppu',
          typeFaxing: 'ppu',
          increase: 0,
          previousInvoiceIncidents: null,
          price: null,
          priceFaxing: null,
          amount: null,
          fiscalDateFrom: null,
          fiscalDateTo: null,
          miscCharges: [],
        };
        promise.push(this.changeProjectionHandler());
      }
      await Promise.all(promise);
      this.invoice = _.cloneDeep(this.invoice);
      this.$validator.resume();
      this.loading = false;
    },

    /**
     * Fetch AVG generation time.
     */
    async fetchAvgWaitingPdfTime() {
      const response = await invoices.getAvgGenerationTime();
      this.avgWaitingPdfTime = response.time;
    },

    /**
     * Handler for change projection date range.
     *
     * @return {Number}
     */
    async changeProjectionHandler() {
      if (this.isStandardFiscalYear) {
        this.invoice.projectedIncidents = await this.getProjectedIncidents();
      } else {
        this.invoice.projectedIncidents = await this.getCountIncidentsByProjectionPeriod();
      }
    },

    /**
     * Get count of projected incidents by selected date range.
     *
     * @return {Number}
     */
    async getProjectedIncidents() {
      let projectedIncidents = 0;
      try {
        this.loadingProjections = true;
        const response = await invoices.getProjections(
          this.client.registryId,
          this.projectionRange[0],
          this.projectionRange[1],
        );
        projectedIncidents = response.projections;
      } finally {
        this.loadingProjections = false;
      }
      return projectedIncidents;
    },

    /**
     * Get count of projected incidents by selected date range.
     *
     * @return {Number}
     */
    async getCountIncidentsByProjectionPeriod() {
      let count = 0;
      try {
        this.loadingProjections = true;
        const response = await invoices.countIncidentsByPeriod(
          this.client.registryId,
          this.projectionRange[0],
          this.projectionRange[1],
        );
        count = response.count;
      } finally {
        this.loadingProjections = false;
      }
      return count;
    },

    /**
     * Get previous fiscal year range.
     *
     * @return {Number}
     */
    getPreviousFiscalYearRange() {
      const currentFiscalYear = this.getCurrentFiscalYear();
      return [
        `${currentFiscalYear - 1}-07-01`,
        `${currentFiscalYear}-06-30`,
      ];
    },

    /**
     * Calculate Current fiscal year.
     *
     * @return {Number}
     */
    getCurrentFiscalYear() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;

      if (currentMonth < 7) {
        return currentYear - 1;
      }
      return currentYear;
    },

    /**
     * Add new misc charge item.
     */
    addMiscCharge() {
      this.invoice.miscCharges.push({
        description: '',
        price: null,
      });
    },

    /**
     * Delete a item of misc charge.
     *
     * @param {Number} index - Index of item in array
     */
    deleteMiscCharge(index) {
      this.invoice.miscCharges.splice(index, 1);
    },

    /**
     * Start progress calculation.
     */
    startProgress() {
      this.seconds = 0;
      this.timer = setInterval(() => {
        this.seconds++;
        this.generatingProgress = parseInt((this.seconds * 100) / this.avgWaitingPdfTime, 10);
      }, 1000);
      setTimeout(() => {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.generatingProgress = 100;
      }, this.avgWaitingPdfTime * 1000);
    },

    /**
     * Find already existed invoice by fiscal year.
     *
     * @return {Number}
     */
    async findInvoiceByFiscalRange() {
      let invoice = null;
      try {
        invoice = await invoices.getByFiscalRange(
          this.client.registryId,
          this.fiscalDateRange[0],
          this.fiscalDateRange[1],
        );
      } catch (error) {
        if (error.response && error.response.status !== 404) {
          throw error;
        }
      }
      return invoice;
    },

    /**
     * Generate invoice click button handler.
     */
    async generateInvoiceHandler() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      if (this.total < 0) {
        this.showError('The total for the Invoice can not be negative');
        return;
      }
      if (this.total > 9999999.99) {
        this.showError('The total for the Invoice can not be more than $9,999,999.99');
        return;
      }

      this.generating = true;
      const invoiceId = this.isNew ? null : this.invoice.id;
      if (invoiceId) {
        this.continueGenerating();
        return;
      }
      const existenceInvoice = await this.findInvoiceByFiscalRange();
      if (existenceInvoice) {
        this.$refs.confirmationPopup.showConfirm(
          'Invoice already exists',
          'There is an invoice with the same fiscal year, do you want to update existence invoice?',
          async () => {
            this.existenceInvoice = existenceInvoice;
            this.continueGenerating();
          },
          () => {
            this.generating = false;
          },
        );
      } else {
        this.continueGenerating();
      }
    },

    /**
     * Continue generating process, saves data and waits rendering result.
     */
    async continueGenerating() {
      const newOrExistenceId = this.existenceInvoice ? this.existenceInvoice.id : null;
      const invoiceId = this.isNew
        ? newOrExistenceId
        : this.invoice.id;
      const savedInvoice = await this.saveInvoice(invoiceId);
      if (!savedInvoice) {
        this.generating = false;
        return;
      }
      this.startProgress();

      this.pdfWaiting = true;
      this.echoChannel = `invoice.${savedInvoice.id}`;
      this.$echo.private(this.echoChannel).listen('.InvoiceRendered', () => {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.pdfWaiting = false;
        window.open(savedInvoice.pdfUrl, '_blank');
        this.$echo.leave(this.echoChannel);
        this.generating = false;
        this.dialog = false;
        this.$emit('saved');
      });
    },

    /**
     * Continue generating process, saves data and waits rendering result.
     *
     * @param {Number|null} id - Invoice identifier to save
     *
     * @return {Object}
     */
    async saveInvoice(id = null) {
      this.saving = true;
      let response = null;
      try {
        const params = _.cloneDeep(this.invoice);
        const [projectionFrom, projectionTo] = this.projectionRange;
        const [fiscalDateFrom, fiscalDateTo] = this.fiscalDateRange;
        params.clientId = this.client.registryId;
        params.projectionFrom = projectionFrom;
        params.projectionTo = projectionTo;
        params.estimatedIncidents = this.estimatedIncidents;
        params.credit = this.credit;
        params.amount = this.total;
        params.fiscalDateFrom = fiscalDateFrom;
        params.fiscalDateTo = fiscalDateTo;

        if (id) {
          response = await invoices.update(id, params);
        } else {
          response = await invoices.create(params);
        }
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
      return response;
    },

    /**
     * Check value is not number.
     *
     * @param {mixed} value - Value to check
     *
     * @return {Boolean}
     */
    isNaN(value) {
      return !isFloat(value);
    },

    /**
     * Change comma character to dot in decimal value.
     *
     * @param {mixed} value - Value to fix
     *
     * @return {String}
     */
    fixDecimal(value) {
      if (value && typeof value === 'string' && value.includes(',')) {
        return value.replace(/,/g, '.');
      }
      return value;
    },
  },
};
</script>

<style lang="scss">
.invoice-popup-title {
  font-size: 20px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #a4a4a4;
}

.separator:not(:empty)::before {
  margin-right: .5em;
}

.separator:not(:empty)::after {
  margin-left: .5em;
}

.separator.overview {
  font-weight: bold;
}

.separator.overview::before,
.separator.overview::after {
  border-bottom: 2px solid #ff7a7a;
}

.invoice-overview {
  max-width: 500px;
  margin: 0 auto;
}
</style>
