<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      max-width="700"
    >
      <v-card :class="$style.card">
        <v-card-title class="headline pt-0">
          {{ $t('profileSettings.unfilteredExport.header') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form
            :id="formId"
            :class="$style.column"
            @submit.prevent="validateAndSave"
          >
            <h4>{{ $t('profileSettings.unfilteredExport.ftpSettingsTitle') }}</h4>
            <v-select
              v-model="exportSettings.exportTypeId"
              v-validate="{ required: true }"
              :items="billingExportTypes"
              :label="$t('profileSettings.billingExport.type')"
              item-value="exportTypeId"
              item-text="name"
              single-line
              name="exportType"
              :error-messages="errors.collect('exportType')"
            />
            <div v-if="!isMedicClipboardSFTP">
              <v-text-field
                v-model="exportSettings.server"
                v-validate="{ required: true, url: true }"
                name="server"
                maxlength="255"
                :label="$t('profileSettings.unfilteredExport.server')"
                :error-messages="errors.collect('server')"
              />
              <v-text-field
                v-model="exportSettings.username"
                v-validate="{ required: true }"
                name="username"
                maxlength="100"
                :error-messages="errors.collect('username')"
                :label="$t('profileSettings.unfilteredExport.user')"
              />
              <v-text-field
                v-if="isPasswordEditMode"
                v-model="newPassword"
                v-validate="{ required: true }"
                :label="$t('profileSettings.unfilteredExport.currentPassword')"
                type="password"
                append-icon="lock"
                persistent-hint
                maxlength="100"
                :error-messages="errors.collect('newPassword')"
                name="newPassword"
                autocomplete="new-password"
                @change="isPasswordChanged = true"
              />
              <v-text-field
                v-if="!isPasswordEditMode"
                value="******"
                :label="$t('profileSettings.unfilteredExport.currentPassword')"
                readonly
                append-icon="edit"
                @click:append="changePassword"
              />
            </div>
            <v-text-field
                v-model="exportSettings.uploadDirectory"
                v-validate="{ required: true }"
                name="uploadDirectory"
                maxlength="255"
                :error-messages="errors.collect('uploadDirectory')"
                :label="$t('profileSettings.unfilteredExport.uploadDirectory')"
            />
            <h4>{{ $t('profileSettings.billingExport.exportSettingsTitle') }}</h4>
            <v-flex :class="$style.row">
              <v-checkbox
                  v-model="exportSettings.includeUpdatedIncidents"
                  :class="$style.checkboxField"
                  :label="$t('profileSettings.billingExport.updatedIncidents')"
                  name="includeUpdatedIncidents"
              />

              <v-radio-group
                v-model="exportSettings.format"
                v-validate="{ required: true }"
                :label="$t('profileSettings.billingExport.format')"
                :class="$style.exportFormat"
                name="format"
                :error-messages="errors.collect('format')"
              >
                <v-radio
                  v-for="format in formats"
                  :key="format.value"
                  :label="`PDF's with ${format.name} file`"
                  :value="format.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-text-field
              v-validate="{ required: !exportSettings.cron }"
              :value="exportSettings.cron"
              :label="$t('profileSettings.timeForExport')"
              readonly
              name="cron"
              :error-messages="errors.collect('cron')"
              append-icon="edit"
              @click:append="isCronEditMode = !isCronEditMode"
            />
            <VueCronEditorBuefy
              v-if="isCronEditMode"
              v-model="exportSettings.cron"
              :visibleTabs="['minutes', 'hourly', 'daily', 'weekly', 'monthly', 'advanced']"
              :preserveStateOnSwitchToAdvanced="true"
            />
            <v-checkbox
              v-model="exportSettings.useCompression"
              :label="$t('profileSettings.billingExport.useCompression')"
              name="useCompression"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            color="primary"
            text
            :form="formId"
            type="submit"
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UPDATE_UNFILTERED_EXPORT_SETTINGS } from '@/store/ProfileSettings/actions';
import editExportPopupMixin from './editExportPopupMixin';

export default {
  name: 'EditUnfilteredExportPopup',
  mixins: [editExportPopupMixin],
  data() {
    return {
      /**
       * Form id for submit by button.
       */
      formId: 'billingExportForm',
      /**
       * Formats types list.
       */
      formats: [
        { value: 'xml', name: 'XML' },
        { value: 'tab', name: 'TAB' },
        { value: 'tripToken', name: 'Trip Token' },
      ],
      /**
       * Export settings model.
       */
      exportSettings: {
        exportTypeId: null,
        server: '',
        username: '',
        uploadDirectory: '',
        includeUpdatedIncidents: false,
        format: null,
        cron: '',
        useCompression: false,
      },
    };
  },
  computed: {
    ...mapState('profileSettings', {
      unfilteredExportSettings: state => state.unfilteredExportSettings,
      billingExportTypes: state => state.billingExportUploadTypes,
    }),
    /**
     * Check export type id is the id of medic clipboard sftp type.
     */
    isMedicClipboardSFTP() {
      const MEDIC_CLIPBOARD_SFTP_TYPE_ID = 5;

      return this.exportSettings.exportTypeId === MEDIC_CLIPBOARD_SFTP_TYPE_ID;
    },
  },
  methods: {
    ...mapActions('profileSettings', {
      // Using in editExportPopupMixin
      saveSettings: UPDATE_UNFILTERED_EXPORT_SETTINGS,
    }),
    /**
     * Open popup for edit.
     */
    async showPopup() {
      this.initPopupData();
      if (this.unfilteredExportSettings) {
        this.exportSettings = {
          exportTypeId: this.unfilteredExportSettings.exportTypeId,
          server: this.unfilteredExportSettings.server,
          username: this.unfilteredExportSettings.username,
          uploadDirectory: this.unfilteredExportSettings.uploadDirectory,
          includeUpdatedIncidents: this.unfilteredExportSettings.includeUpdatedIncidents,
          format: this.unfilteredExportSettings.format,
          cron: this.unfilteredExportSettings.cron,
          useCompression: this.unfilteredExportSettings.useCompression,
        };
      }
    },
    /**
     * Validate and save in success case.
     */
    async validateAndSave() {
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.save();
    },
  },
};
</script>

<style lang="scss" module>
.card {
  padding: 14px;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--v-error-base);
  margin-bottom: 10px;
}

.checkboxField {
  margin-top: 0;
}

.exportFormat {
  margin: 10px 0 0 26px;
}
</style>
