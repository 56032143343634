<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="600"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          {{ isNew ? $t('documents.editPopup.new') : $t('documents.editPopup.edit') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div
            v-if="loading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              color="grey darken"
              center
              indeterminate
            />
          </div>
          <div v-else>
            <v-alert
              v-model="errorAlert"
              type="error"
              outlined
              dismissible
              transition="scale-transition"
            >
              <v-flex
                v-for="(error, index) in errors.all()"
                :key="index"
                xs12
              >
                <span>
                  {{ error }}
                </span>
              </v-flex>
            </v-alert>
            <v-layout
              v-if="localDocument"
              wrap
            >
              <v-flex xs7>
                <v-text-field
                  v-model="localDocument.name"
                  v-validate="'required'"
                  :label="$t('documents.editPopup.name')"
                  maxlength="255"
                  name="name"
                />
              </v-flex>
              <v-flex xs5>
                <uploader
                  ref="uploader"
                  v-model="file"
                  :allowed-mime-types="mimeTypes"
                  :title="isNew ? $t('general.uploadFile') : $t('general.updateFile')"
                  class="uploader"
                  fixed-width="100%"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="localDocument.subtitle"
                  :label="$t('documents.editPopup.subtitle')"
                  maxlength="255"
                  name="subtitle"
                />
              </v-flex>
              <v-flex xs12>
                <tree-select
                  v-model="localDocument.categoryId"
                  :multiple="false"
                  :options="categories"
                  :append-to-body="true"
                  :default-expand-level="1"
                  :disable-branch-nodes="true"
                  :placeholder="$t('documents.categories.selectPlaceholder')"
                  :searchable="false"
                  :class="$style.treeselect"
                >
                  <div
                    slot="option-label"
                    slot-scope="{ node }"
                  >
                    {{ node.raw.name }}
                  </div>
                  <div
                    slot="value-label"
                    slot-scope="{ node }"
                  >
                    {{ transformLabel(node) }}
                  </div>
                </tree-select>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="localDocument.comment"
                  :label="$t('documents.editPopup.comment')"
                  maxlength="255"
                  name="comment"
                />
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-btn
            color="blue darken-1"
            text
            @click.native="close(false)"
          >
            {{ $t('documents.editPopup.cancel') }}
          </v-btn>
          <v-btn
            :loading="saving"
            :disabled="saving"
            color="primary"
            text
            @click.native="save"
          >
            {{ $t('documents.editPopup.save') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { SAVE_DOCUMENT, CREATE_DOCUMENT } from '@/store/Documents/actions';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import Uploader from '@/components/Uploader';
import TreeSelect from '@riophae/vue-treeselect';

const { mapActions } = createNamespacedHelpers('documents');

export default {
  name: 'DocumentEditPopup',
  components: { Uploader, TreeSelect },
  mixins: [errorAlertMixins],
  props: {
    document: {
      type: Object,
      default: null,
    },
    categories: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mimeTypes: ['application/pdf'],
      localDocument: {
        registryId: null,
        name: null,
        subtitle: null,
        categoryId: null,
      },
      file: null,
      saving: false,
      loading: false,
    };
  },
  computed: {
    isNew() {
      return !((this.document && this.document.registryId) || false);
    },
  },
  watch: {
    document: {
      handler(newVal) {
        if (newVal) {
          this.localDocument = { ...newVal };
          this.localDocument.categoryId = newVal.category.categoryId;
        } else {
          this.localDocument = {
            registryId: null,
            name: null,
            subtitle: null,
            categoryId: null,
          };
        }
      },
      deep: true,
    },
    visible: {
      handler(newVal) {
        if (!newVal) {
          this.localDocument = {
            registryId: null,
            name: null,
            subtitle: null,
            categoryId: null,
          };
          this.errors.clear();
          this.$validator.reset();
        }
      },
    },
    file: {
      handler(newVal) {
        if (newVal && this.errors.has('file')) {
          this.errors.remove('file');
        }
      },
      deep: true,
    },
    /* eslint func-names:0 */
    'localDocument.categoryId': function (newVal) {
      if (newVal) {
        this.errors.remove('categoryId');
      } else {
        this.errors.add({
          field: 'categoryId',
          msg: 'categoryId',
        });
      }
    },
    errors: {
      handler(errors) {
        if (!errors.count()) {
          this.hideError();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      saveDocument: SAVE_DOCUMENT,
      createDocument: CREATE_DOCUMENT,
    }),

    /**
     * Transform category label into appropriate view.
     *
     * @param {Object} node - Category to transform.
     *
     * @return {Object}
     */
    transformLabel(node) {
      let label = null;

      if (node.parentNode) {
        label = this.transformLabel(node.parentNode);
      }

      return label ? `${label} / ${node.raw.name}` : node.raw.name;
    },

    /**
     * Validates document and saves in case of success.
     */
    async save() {
      this.errors.clear();
      if (this.isNew && !this.file) {
        this.errors.add({
          field: 'file',
          msg: this.$t('documents.errors.file'),
        });
      }
      if (!this.localDocument.categoryId) {
        this.errors.add({
          field: 'categoryId',
          msg: this.$t('documents.errors.categoryId'),
        });
      }

      await this.$validator.validateAll();
      if (this.errors.count()) {
        this.errorAlert = true;
        return;
      }
      this.saving = true;
      try {
        if (this.file) {
          const preSignedData = await this.$refs.uploader.upload();
          this.localDocument.file = preSignedData.tmpFilename;
        }

        if (this.isNew) {
          await this.createDocument(this.localDocument);
        } else {
          await this.saveDocument(this.localDocument);
        }
        this.close(true);
      } catch (e) {
        this.parseErrorResponse(e.response);
        this.hideError();
      } finally {
        this.saving = false;
      }
    },

    /**
     * Emits closes popup event and clears all information.
     *
     * @param {Boolean} success - Shows whether was successful saving or not
     */
    close(success = false) {
      this.$emit('close', success);
      this.hideError();
      this.file = null;
      this.$refs.uploader.clear();
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss" module>
.treeselect {
  margin-top: 24px;
  margin-bottom: 24px;
}

.uploader .upload-btn {
  padding-right: 7px !important;
}
</style>
