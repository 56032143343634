export const SET_FLAGGED_USERS_ITEMS = 'SET_FLAGGED_USERS_ITEMS';
export const SET_FLAGGED_USERS_PAGINATION = 'SET_FLAGGED_USERS_PAGINATION';
export const SET_FLAGGED_USERS_FILTERS = 'SET_FLAGGED_USERS_FILTERS';

export default {
  /**
   * Save flagged users data.
   *
   * @param {Object} state - Flagged users state.
   * @param {{ items: Array, totalItems: number }} data - Flagged users data to set
   */
  [SET_FLAGGED_USERS_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.totalItems = totalItems;
  },

  /**
   * Save flagged users pagination.
   *
   * @param {Object} state - Flagged users state.
   * @param {Object} pagination - Flagged users pagination.
   */
  [SET_FLAGGED_USERS_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters of flagged users.
   *
   * @param {Object} state - Flagged users state.
   * @param {Object} filters - Filters to set.
   */
  [SET_FLAGGED_USERS_FILTERS]: (state, filters) => {
    state.filters = filters;
  },
};
