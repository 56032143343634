<template>
  <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :height="tableHeight"
      class="export-log-table"
      fixed-header
      hide-default-footer
  >
    <template v-slot:item.posted="{ item }">
      {{ item.posted | datetimeToClientTimezone }}
    </template>
    <template v-slot:item.records="{ item }">
      {{ item.records }} <template v-if="item.countFailed">
        / <span
          class="status-error"
      >{{ item.countFailed }}</span>
      </template>
    </template>

    <template v-slot:item.dateRange="{ item }">
      {{ item.startDate | date }} - {{ item.endDate | date }}
    </template>
    <template v-slot:item.status="{ item }">
      <span :class="getStatusClass(item.status)">
        {{ item.status | status }}
      </span>
      <template v-if="item.status === 0 && item.countFailed">
        ( <span class="status-error">partial</span> )
      </template>
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="table-actions">
        <v-btn
          v-if="item.downloadUrl"
          class="float-right mr-4"
          icon
          :href="item.downloadUrl"
          target="_blank"
        >
          <v-icon color="grey darken-2">download</v-icon>
        </v-btn>

        <v-btn
            v-if="item.downloadUrl"
            class="mr-3 float-right"
            icon
            target="_blank"
            @click="$emit('showDetails', item.logId)"
        >
          <v-icon color="grey darken-2">mdi-view-list-outline</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';
import EXPORT_STATUSES from '@/enums/exportStatuses';

export default {
  name: 'ExportLogTable',

  mixins: [fixedHeader],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    status(status) {
      const map = {};
      map[EXPORT_STATUSES.SUCCESS] = 'Success';
      map[EXPORT_STATUSES.ERROR] = 'Error';
      map[EXPORT_STATUSES.PENDING] = 'Pending';
      return map[status];
    },
  },

  data() {
    return {
      additionalIndent: -70,
      headers: [
        {
          text: this.$t('exportLog.tableHeaders.dateTime'),
          value: 'posted',
          sortable: false,
        },
        {
          text: this.$t('exportLog.tableHeaders.records'),
          value: 'records',
          sortable: false,
        },
        {
          text: this.$t('exportLog.tableHeaders.dateRange'),
          value: 'dateRange',
          sortable: false,
        },
        {
          text: this.$t('exportLog.tableHeaders.status'),
          value: 'status',
          sortable: false,
        },
        {
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    getStatusClass(status) {
      const map = {};
      map[EXPORT_STATUSES.SUCCESS] = 'status-success';
      map[EXPORT_STATUSES.ERROR] = 'status-error';
      map[EXPORT_STATUSES.PENDING] = 'status-pending';
      return map[status];
    },
  },
};
</script>

<style lang="scss">
  .export-log-table {
    .status-error {
      color: red;
    }
    .status-success {
      color: green;
    }
    .status-pending {
      color: blue;
    }
    .table-actions {
      visibility: hidden;
    }
    tr:hover .table-actions {
      visibility: visible;
    }
  }
</style>
