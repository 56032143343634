<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    :height="tableHeight"
    fixed-header
    class="header-nowrap"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <NoDataAvailable
          :headers="headers"
          :items="items"
        />
        <v-hover
          v-for="(item, index) in items"
          :key="index"
        >
          <tr slot-scope="{ hover }">
            <td><a @click="goToIncident(item)">{{ item.sequenceNumber }}</a></td>
            <td>{{ item.flagCreated | datetime }}</td>
            <td>{{ item.responded | datetime }}</td>
            <td v-if="!item.userIsDeleted">{{ item.fullName }}</td>
            <td
                v-else
                :class="[$style.deletedUser]"
            >User Removed <br/>({{ item.fullName }})</td>
            <td>{{ item.lastLogin | datetime }}</td>
            <td>{{ item.flagMessage }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.status | status }}</td>
            <td>{{ item.sentBy }}</td>
            <td :class="[$style.actions, 'text-right', 'no-wrap']">
              <v-icon
                v-if="isPendingStatus(item.status)"
                v-show="hover"
                class="mr-3"
                color="grey darken-2"
                @click="$emit('resendFlag', item)"
              >
                send
              </v-icon>
            </td>
          </tr>
        </v-hover>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import clientsApi from '@/api/clients';
import fixedHeader from '@/mixins/fixedHeader';
import FLAG_STATUSES from '@/enums/flagStatuses';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'FlaggedUsersTable',

  filters: {
    status(status) {
      const statuses = {
        0: 'Pending',
        1: 'Understood',
        2: 'Did not understand',
      };
      return statuses[status];
    },
  },
  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader],
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useImpersonate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('flaggedUsers.tableHeaders.sequenceNumber'),
          value: 'sequenceNumber',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.flagCreated'),
          value: 'flagCreated',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.responded'),
          value: 'responded',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.userFlagged'),
          value: 'fullName',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.lastLogin'),
          value: 'lastLogin',
          sortable: false,
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.flagMessage'),
          value: 'flagMessage',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.message'),
          value: 'message',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.status'),
          value: 'status',
        },
        {
          text: this.$t('flaggedUsers.tableHeaders.sentBy'),
          value: 'sentBy',
        },
        {
          sortable: false,
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    /**
     * Check status is pending.
     *
     * @param {int} status - Status of flagged user.
     */
    isPendingStatus(status) {
      return status === FLAG_STATUSES.PENDING;
    },

    /**
     * Impersonate user to client and go to incident view page.
     *
     * @param {Object} flaggedUser - Flagged user object.
     */
    async goToIncident(flaggedUser) {
      let url = `/incidents/${flaggedUser.incidentId}`;
      if (this.useImpersonate) {
        const data = await clientsApi.getImpersonateUrl(flaggedUser.clientId);
        url = `${data.url}?incidentId=${flaggedUser.incidentId}`;
      }
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}
.deletedUser {
  color: #e04337;
}
</style>
