<template>
  <div>
    <v-card
      elevation="3"
      class="ma-4"
    >
      <v-tabs
        v-model="activeTab"
        :show-arrows="true"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          exact
          active-class="primary--text"
          class="subheading text-capitalize tab-item"
        >
          {{ tab }}
        </v-tab>

        <v-tabs-items :value="activeTab">
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 0"
              :log-stream="cloudwatchStreams.PRIVATE_AMBULANCE"
              module-name="privateAmbulanceExport"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 1"
              :log-stream="cloudwatchStreams.BILLING_EXPORT"
              module-name="billingExportLog"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 2"
              :log-stream="cloudwatchStreams.BACKGROUND_WORKER"
              module-name="backgroundWorkerExceptions"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 3"
              :log-stream="cloudwatchStreams.TEMPLATE"
              module-name="templateErrors"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 4"
              :log-stream="cloudwatchStreams.CAD"
              module-name="cadErrors"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 5"
              :log-stream="cloudwatchStreams.EXPORT"
              module-name="exportErrors"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
              :is-active-tab="activeTab === 6"
              :log-stream="cloudwatchStreams.PUT_INCIDENT"
              module-name="putIncidentErrors"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
                :is-active-tab="activeTab === 7"
                :log-stream="cloudwatchStreams.EXTERNAL_SERVICE_ERROR"
                module-name="externalServiceError"
            ></errors-utility-table>
          </v-tab-item>
          <v-tab-item>
            <errors-utility-table
                :is-active-tab="activeTab === 8"
                :log-stream="cloudwatchStreams.SENT_EMAILS"
                module-name="externalServiceError"
            ></errors-utility-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import cloudwatchStreams from '@/enums/cloudwatchStreams';
import { SET_TITLE } from '@/store/mutations';
import ErrorsUtilityTable from '@/components/ErrorsUtility/ErrorsUtilityTable';

export default {
  name: 'ErrorsUtility',

  components: {
    ErrorsUtilityTable,
  },

  data: () => ({
    /**
     * Number of active tab.
     */
    activeTab: null,

    /**
     * Errors utility tabs.
     */
    tabs: [
      'Private Ambulance export',
      'Billing Export log',
      'Background worker exceptions',
      'Template Errors',
      'CAD Errors',
      'DHS/Billing Export errors',
      'Put Incident Errors',
      'External Service errors',
      'Sent Emails',
    ],

    /**
     * Cloudwatch streams.
     */
    cloudwatchStreams,
  }),

  created() {
    this.$store.commit(SET_TITLE, 'Errors Utility');
  },
};
</script>
