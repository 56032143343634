<template>
  <v-row class="pl-4 pr-4">
    <v-col
        md="4"
        class="text-center"
    >
      <b>Total Records in System:</b><br />
      {{ data.total || '-' }}
    </v-col>
    <v-col
        md="4"
        class="text-center"
    >
      <b>Total Records Sent:</b><br />
      {{ data.sent || '-' }}
    </v-col>
    <v-col
        md="4"
        class="text-center"
    >
      <b>Total NOT Sent:</b><br />
      <span v-if="!notSentClickable || !data.notSent">{{ data.notSent || '-' }}</span>
      <a
          v-if="notSentClickable && data.notSent"
          @click="$emit('notSentClick')"
      >{{ data.notSent || '-' }}</a>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'TotalCounters',

  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    notSentClickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
