<template>
  <v-dialog
    v-model="showConfirmPopup"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">{{ title || $t('general.confirmation') }}</v-card-title>
      <v-card-text v-if="!textIsHtml">{{ text }}</v-card-text>
      <v-card-text
          v-else
          v-html="text"
      />
      <slot/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click.native="reject()"
        >{{ $t('buttons.cancel') }}</v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          text
          @click.native="confirm()"
        >{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirmation',
  data() {
    return {
      loading: false,
      showConfirmPopup: false,
      title: null,
      text: null,
      confirmCallback: null,
      rejectCallback: null,
      textIsHtml: false,
    };
  },
  methods: {
    /**
     * Show confirmation popup
     *
     * @param {string} title - Title text of dialog.
     * @param {string} text - Message in confirmation popup.
     * @param {function} confirmCallback - Callback when clicked Ok button.
     * @param {function|null} rejectCallback - Callback when clicked Cancel button.
     * @param {boolean} textIsHtml - If text will be html
     */
    showConfirm(title, text, confirmCallback, rejectCallback = null, textIsHtml = false) {
      this.showConfirmPopup = true;
      this.title = title;
      this.text = text;
      this.confirmCallback = confirmCallback;
      this.rejectCallback = rejectCallback;
      this.textIsHtml = textIsHtml;
    },

    /**
     * Confirm action.
     */
    async confirm() {
      this.loading = true;
      try {
        await this.confirmCallback();
        this.showConfirmPopup = false;
        this.$emit('confirmed');
      } finally {
        this.loading = false;
      }
    },

    /**
     * Reject action.
     */
    async reject() {
      this.showConfirmPopup = false;
      if (this.rejectCallback) {
        await this.rejectCallback();
      }
    },
  },
};
</script>
