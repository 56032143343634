<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-toolbar flat>
      <h2>{{ title }}</h2>
      <v-spacer />
    </v-toolbar>

    <v-card-text
      v-if="data.minutes"
      class="text-center"
    >
      The graph shows the count of offload times that were more than {{ data.minutes }} minutes
      as well as the percentage of all offload times that were more than {{ data.minutes }} minutes.
    </v-card-text>

    <vue-apex-charts
        v-if="!loading && series[0].data.length"
        type="bar"
        :height="chartHeight"
        :options="options"
        :series="series || []"
    />
    <div
        v-if="!loading && !series[0].data.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';
import dateRangeFormat from './dateRangeFormat';

export default {
  name: 'ApotDelayExceeding',
  components: {
    VueApexCharts,
  },
  mixins: [dateRangeFormat],
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    dateRange: {
      type: Array,
      required: false,
      default: null,
    },
    metricType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            tools: {
              download: true,
            },
          },
        },
        title: {
          text: '',
          align: 'center',
        },
        subtitle: {
          text: '',
          align: 'center',
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -40,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
          formatter: (val, opts) => {
            const item = this.data.series[opts.dataPointIndex];
            return item ? [
              item.count,
              `${val}%`,
            ] : '';
          },
        },
        annotations: {
          yaxis: [],
        },
        xaxis: {
          categories: [],
          position: 'bottom',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => `${val.toFixed(2)}%`,
          },
        },
      },
    };
  },
  computed: {
    series() {
      const series = [];
      const data = this.data.series.map((item) => parseFloat(item.percentage));
      series.push({
        name: 'Percent',
        data,
      });
      return series;
    },

    chartHeight() {
      return this.height - 200;
    },

    averagePercentage() {
      let sum = 0;
      this.data.series.forEach((item) => {
        sum += parseFloat(item.percentage);
      });
      return this.data.series.length ? sum / this.data.series.length : 0;
    },

    options() {
      const options = _.cloneDeep(this.chartOptions);
      options.title.text = this.data.minutes
        ? `Percent and Count of Transports With An Offload Delay Exceeding ${this.data.minutes} Minutes`
          + `${this.dateRange ? ` ${this.dateRangeFormat(this.dateRange)}` : ''}`
        : '';
      options.subtitle.text = `(total transports = ${this.formatNumber(this.data.total)})`;
      options.xaxis.categories = this.data.series.map((item) => item.key);
      options.annotations.yaxis = [{
        y: this.averagePercentage,
        borderColor: '#E04337',
        label: {
          borderColor: '#E04337',
          style: {
            color: '#fff',
            background: '#E04337',
          },
          text: `Average ${this.averagePercentage.toFixed(2)}%`,
        },
      }];
      options.chart.toolbar.export = {
        csv: {
          filename: this.metricType,
          headerCategory: 'Group By',
        },
        svg: {
          filename: this.metricType,
        },
        png: {
          filename: this.metricType,
        },
      };

      return options;
    },
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>
