<template>
  <v-toolbar
    flat
    class="nfirs-toolbar"
  >
    <date-range-picker-with-periods
        v-model="dateRangeValue"
        :solo="false"
        :dense="false"
        :clearable="false"
        position="right"
        class="mr-4"
    />

    <v-spacer />

    <template v-if="showExportStatus">
      <span
          v-if="!status || !status.lastExportAt"
          class="mr-2"
        >No Status ( <a
        v-if="!loadingStatus"
        @click="$emit('refreshStatus')"
        >refresh</a>
        <v-progress-circular
            v-if="loadingStatus"
            indeterminate
            size="15"
            width="2"
            color="primary"
        /> )</span>
      <v-tooltip
        v-else-if="status.exportError"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              class="mr-2 nfirs-export-error"
              v-bind="attrs"
              v-on="on"
          >
            Export Error at {{ status.lastExportAt|datetimeToClientTimezone }}
            ( <a
                v-if="!loadingRetry"
                @click="$emit('retry')"
              >retry</a>
              <v-progress-circular
                v-if="loadingRetry"
                indeterminate
                size="15"
                width="2"
                color="primary"
              /> )
        </span>
        </template>
        <span>{{ status.exportError }}</span>
      </v-tooltip>
      <span
        v-else
        class="mr-2 nfirs-export-success"
      >Last Export at {{ status.lastExportAt|datetimeToClientTimezone }}</span>
    </template>

    <v-btn
        :disabled="disabledSettintsBtn"
        color="primary"
        class="ma-2"
        @click="$emit('showSettings')"
    >
      <v-icon
          left
          dark
      >
        mdi-cog-outline
      </v-icon>
      Export Settings
    </v-btn>

    <v-btn
        :loading="downloading"
        :disabled="downloading || disabledDownloadBtn"
        color="primary"
        class="ma-2"
        @click="$emit('download')"
    >
      Download NFIRS
      <span slot="loader">Downloading...</span>
    </v-btn>
  </v-toolbar>
</template>
<script>

import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'Toolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    downloading: {
      type: Boolean,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    status: {
      type: Object,
      required: false,
    },
    loadingRetry: {
      type: Boolean,
      required: true,
    },
    loadingStatus: {
      type: Boolean,
      required: true,
    },
    showExportStatus: {
      type: Boolean,
      required: true,
    },
    disabledSettintsBtn: {
      type: Boolean,
      required: true,
    },
    disabledDownloadBtn: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dateRangeValue: [],
    };
  },

  mounted() {
    this.dateRangeValue = this.dateRange;
  },

  watch: {
    /**
     * Watch date range
     */
    dateRangeValue() {
      this.$emit('update:dateRange', this.dateRangeValue);
    },
  },
};

</script>

<style lang="scss">
.nfirs-toolbar {
  z-index: 10;
}
.nfirs-export-success {
  color: green;
}
.nfirs-export-error {
  color: red;
}
</style>
