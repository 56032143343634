<template>
  <div>
    <slot name="filters"/>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="paginationOptions"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :height="tableHeight"
      fixed-header
    >
      <template v-slot:body="{ items }">
        <tbody>
          <v-hover
            v-for="item in items"
            :key="item.registryId"
          >
            <tr slot-scope="{ hover }">
              <td>{{ item.name }}</td>
              <td>{{ item.registry.updated | date }}</td>
              <td>{{ transformCategory(item.category) }}</td>
              <td>{{ item.registry.created | date }}</td>
              <td class="actions text-right no-wrap">
                <v-icon
                  v-if="showEditDelete"
                  v-show="hover"
                  color="grey darken-2"
                  class="mr-3"
                  @click="$emit('edit', item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="showEditDelete"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('delete', item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('download', item)"
                >
                  cloud_download
                </v-icon>
              </td>
            </tr>
          </v-hover>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'DocumentsTab',
  mixins: [fixedHeader],
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    showEditDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('documents.tableHeaders.name'),
          align: 'left',
          value: 'name',
          sortable: true,
        },
        {
          text: this.$t('documents.tableHeaders.updated_at'),
          value: 'updated_at',
          sortable: false,
        },
        {
          text: this.$t('documents.tableHeaders.category'),
          value: 'category',
          sortable: false,
        },
        {
          text: this.$t('documents.tableHeaders.created_at'),
          value: 'created_at',
          sortable: false,
        },
        {
          value: 'action',
          sortable: false,
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    /**
     * Transform category label into appropriate view.
     *
     * @param {Object} category - Category to transform.
     *
     * @return {string}
     */
    transformCategory(category) {
      return category.parent
        ? `${category.parent.name} / ${category.name}`
        : category.name;
    },
  },
};
</script>

<style lang="scss">
.actions {
  width: 25%;
}
</style>
