<template>
  <div>
    <div class="mb-4">
      We're excited to hear what you think! Your feedback is important to us.
      Please take a moment to complete. A Course Evaluation submission
      is required in order to receive CE credit.
    </div>
    <div :class="$style.chooseAnswersTitle">
      Choose the best answer for the following:
    </div>

    <v-form
        ref="evaluationForm"
        disabled
    >
      <v-simple-table
          class="mb-4"
          dense
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
            </th>
            <th
                class="text-center pl-1 pr-1"
                style="width: 70px"
            >
              Strongly Disagree
            </th>
            <th
                class="text-center pl-1 pr-1"
                style="width: 70px"
            >
              Disagree
            </th>
            <th
                class="text-center pl-1 pr-1"
                style="width: 70px"
            >
              Neutral
            </th>
            <th
                class="text-center pl-1 pr-1"
                style="width: 70px"
            >
              Agree
            </th>
            <th
                class="text-center pl-1 pr-1"
                style="width: 70px"
            >
              Strongly Agree
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="question in rateQuestions"
              v-bind:key="'question_' + question.id"
          >
            <td>
              <span>{{ question.question }}</span>
            </td>
            <td class="pl-1 pr-1">
              <v-radio-group
                  v-model="question.rate"
                  dense
                  hide-details
                  class="mt-0 pt-0 ml-4"
              >
                <v-radio
                    :value="1"
                    :ripple="false"
                />
              </v-radio-group>
            </td>
            <td class="pl-1 pr-1">
              <v-radio-group
                  v-model="question.rate"
                  dense
                  hide-details
                  class="mt-0 pt-0 ml-4"
              >
                <v-radio
                    :value="2"
                    :ripple="false"
                />
              </v-radio-group>
            </td>
            <td class="pl-1 pr-1">
              <v-radio-group
                  v-model="question.rate"
                  dense
                  hide-details
                  class="mt-0 pt-0 ml-4"
              >
                <v-radio
                    :value="3"
                    :ripple="false"
                />
              </v-radio-group>
            </td>
            <td class="pl-1 pr-1">
              <v-radio-group
                  v-model="question.rate"
                  dense
                  hide-details
                  class="mt-0 pt-0 ml-4"
              >
                <v-radio
                    :value="4"
                    :ripple="false"
                />
              </v-radio-group>
            </td>
            <td class="pl-1 pr-1">
              <v-radio-group
                  v-model="question.rate"
                  dense
                  hide-details
                  class="mt-0 pt-0 ml-4"
              >
                <v-radio
                    :value="5"
                    :ripple="false"
                />
              </v-radio-group>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-layout
          v-for="question in textQuestions"
          v-bind:key="'question_' + question.id"
          wrap
      >
        <v-flex xs12>
          <span :class="$style.textQuestion">{{ question.question }}</span>
        </v-flex>
        <span class="mt-2 mb-8">{{ question.answer || 'No answer' }}</span>
      </v-layout>
      <v-layout wrap>
        <v-flex
          xs12
          class="mb-2"
        >
          Please write your First and Last names to acknowledge you have
          completed the evaluation:
        </v-flex>
        <v-text-field
            v-model="firstName"
            label="First Name"
            outlined
            class="mr-2"
        />
        <v-text-field
            v-model="lastName"
            label="Last Name"
            outlined
        />
      </v-layout>
    </v-form>
  </div>
</template>
<script>

export default {
  name: 'EvaluationAnswersForm',
  props: {
    questions: {
      type: Array,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    rateQuestions() {
      return this.questions.filter(question => question.type === 'rate');
    },
    textQuestions() {
      return this.questions.filter(question => question.type === 'answer');
    },
  },
};
</script>
<style lang="scss" module>
.title {
  word-break: normal;
}
.chooseAnswersTitle {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.textQuestion {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
.asterisk {
  color: red;
}
</style>
