<template>
  <div v-if="providers[clientId].processedFiles.length">
    <b>Last {{ providers[clientId].processedFiles.length }} files processed:</b>
    <ul>
      <li
        v-for="(item, index) in providers[clientId].processedFiles"
        :key="item.file"
        :class="[$style.processedfile]"
      >
        <b>{{ index+1 }})</b>
        {{ item.file }} : {{ item.processedAt | formatDate }} {{ siteInfo.timeZone }},
        {{ item.size | humanFileSize }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'ProcessedFiles',
  filters: {
    formatDate(date) {
      return `${moment(date).format('MM/DD/YYYY @ HH:mm:ss')}`;
    },
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      providers: state => state.cadMonitoring.providers,
      siteInfo: state => state.siteInfo,
    }),
  },
};
</script>

<style lang="scss" module>
.processedfile {
  list-style-type: none;
}
</style>
