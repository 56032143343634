<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      max-width="700"
    >
      <v-card :class="$style.card">
        <v-form @submit.prevent="save">
          <v-card-title class="headline pt-0">
            {{ $t('profileSettings.dhsExport.header') }}
          </v-card-title>
          <v-card-text class="pt-0">
            <div :class="$style.column">
              <h4>{{ $t('profileSettings.dhsExport.exportDestinationTitle') }}</h4>
              <v-select
                v-model="exportSettings.exportTypeId"
                v-validate="{ required: true }"
                :items="dhsExportTypes"
                :label="$t('profileSettings.dhsExport.type')"
                item-value="exportTypeId"
                item-text="name"
                single-line
                name="exportType"
                :error-messages="errors.collect('exportType')"
              />
              <v-text-field
                v-model="exportSettings.server"
                v-validate="{ required: true, url: true }"
                name="server"
                maxlength="255"
                :label="$t('profileSettings.dhsExport.url')"
                :error-messages="errors.collect('server')"
              />
              <v-text-field
                v-model="exportSettings.username"
                v-validate="{ required: true }"
                name="username"
                maxlength="100"
                :label="$t('profileSettings.dhsExport.username')"
                :error-messages="errors.collect('username')"
              />
              <v-text-field
                v-if="isPasswordEditMode || isFirstSetupMode"
                v-model="newPassword"
                v-validate="{ required: isFirstSetupMode }"
                :label="$t('profileSettings.dhsExport.currentPassword')"
                type="password"
                append-icon="lock"
                persistent-hint
                maxlength="100"
                name="newPassword"
                :error-messages="errors.collect('newPassword')"
                autocomplete="new-password"
                @change="isPasswordChanged = true"
              />
              <v-text-field
                v-if="!isPasswordEditMode && !isFirstSetupMode"
                value="******"
                :label="$t('profileSettings.dhsExport.currentPassword')"
                readonly
                append-icon="edit"
                @click:append="changePassword"
              />
              <v-text-field
                v-model="exportSettings.uploadDirectory"
                v-validate="{ required: true }"
                name="uploadDirectory"
                maxlength="255"
                :error-messages="errors.collect('uploadDirectory')"
                :label="$t('profileSettings.dhsExport.uploadDirectory')"
              />
              <h4>{{ $t('profileSettings.dhsExport.exportSettingsTitle') }}</h4>
              <v-checkbox
                v-model="exportSettings.includeUpdatedIncidents"
                :label="$t('profileSettings.dhsExport.updatedIncidents')"
                name="includeUpdatedIncidents"
              />
              <v-text-field
                v-validate="{ required: !exportSettings.cron }"
                :value="exportSettings.cron"
                :label="$t('profileSettings.timeForExport')"
                readonly
                append-icon="edit"
                name="cron"
                :error-messages="errors.collect('cron')"
                @click:append="isCronEditMode = !isCronEditMode"
              />
              <VueCronEditorBuefy
                v-if="isCronEditMode || isFirstSetupMode"
                v-model="exportSettings.cron"
                :visibleTabs="['minutes', 'hourly', 'daily', 'weekly', 'monthly', 'advanced']"
                :preserveStateOnSwitchToAdvanced="true"
              />
              <v-checkbox
                  v-model="exportSettings.useCompression"
                  :label="$t('profileSettings.dhsExport.useCompression')"
                  name="useCompression"
              />
              <v-checkbox
                  v-model="exportSettings.mergeXmlFiles"
                  :label="$t('profileSettings.dhsExport.mergeXmlFiles')"
                  name="mergeXmlFiles"
              />
            </div>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              color="blue darken-1"
              text
              @click.native="showEditPopup = false"
            >{{ $t('buttons.cancel') }}</v-btn>
            <v-btn
              color="primary"
              text
              type="submit"
            >{{ $t('buttons.save') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UPDATE_DHS_EXPORT_SETTINGS } from '@/store/ProfileSettings/actions';
import editExportPopupMixin from './editExportPopupMixin';

export default {
  name: 'EditDhsExportPopup',
  mixins: [editExportPopupMixin],
  data() {
    return {
      exportSettings: {
        exportTypeId: null,
        server: '',
        username: '',
        uploadDirectory: '',
        includeUpdatedIncidents: false,
        cron: '',
        useCompression: false,
        mergeXmlFiles: false,
      },
    };
  },
  computed: {
    ...mapState('profileSettings', {
      dhsExportSettings: state => state.dhsExportSettings,
      dhsExportTypes: state => state.dhsExportUploadTypes,
    }),
  },
  methods: {
    ...mapActions('profileSettings', {
      // Using in editExportPopupMixin
      saveSettings: UPDATE_DHS_EXPORT_SETTINGS,
    }),
    /**
     * Open popup for edit.
     */
    async showPopup() {
      this.initPopupData();
      if (this.dhsExportSettings) {
        this.isFirstSetupMode = false;
        this.exportSettings = {
          exportTypeId: this.dhsExportSettings.exportTypeId,
          server: this.dhsExportSettings.server,
          username: this.dhsExportSettings.username,
          uploadDirectory: this.dhsExportSettings.uploadDirectory,
          includeUpdatedIncidents: this.dhsExportSettings.includeUpdatedIncidents,
          cron: this.dhsExportSettings.cron,
          useCompression: this.dhsExportSettings.useCompression,
          mergeXmlFiles: this.dhsExportSettings.mergeXmlFiles,
        };
      } else {
        this.isFirstSetupMode = true;
        this.exportSettings = {
          exportTypeId: null,
          server: '',
          username: '',
          uploadDirectory: '',
          includeUpdatedIncidents: false,
          cron: '',
          useCompression: false,
          mergeXmlFiles: false,
        };
      }
    },
  },
};
</script>

<style lang="scss" module>
.card {
  padding: 14px;
}

.column {
  display: flex;
  flex-direction: column;
}
</style>
