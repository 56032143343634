<template>
  <v-layout
    align-center
    justify-center
  >
    <img
      :src="require('@/assets/img/MobileMedic_logo1_blackred.png')"
      alt=""
      class="mb-12 mt-12"
      width="400"
    >
  </v-layout>
</template>

<script>
export default {
  name: 'MainLogo',
};
</script>
