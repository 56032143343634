<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
      <v-card>
        <v-card-title>Add Comment</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
              :value="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>
          <v-textarea
              v-model="message"
              v-validate="{ required: true, min: 11 }"
              name="message"
              :error-messages="errors.collect('message')"
              label="Comment"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="hide"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="sending"
              @click="send"
          >
            Close Pending Flag
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';
import flaggedUsers from '@/api/flaggedUsers';

export default {
  name: 'ClosePendingFlagPopup',
  mixins: [errorAlertMixins],
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  data() {
    return {
      dialog: false,
      message: null,
      sending: false,
      dictionary: {
        attributes: {
          message: 'comment',
        },
      },
    };
  },
  methods: {
    /**
     * Show add comment popup.
     */
    show() {
      this.dialog = true;
      this.message = null;
    },

    /**
     * Hide popup.
     */
    hide() {
      this.dialog = false;
    },

    /**
     * Send request for closing pending flag.
     */
    async send() {
      this.hideError();
      await this.$validator.reset();

      if (!await this.$validator.validateAll()) {
        return;
      }

      this.sending = true;

      try {
        await flaggedUsers.closePendingFlag(this.incidentId, { message: this.message });
        this.dialog = false;
        this.$emit('closedPendingFlag');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
