<template>
  <v-list-item-content
      :class="[pointer ? $style.pointer : '']"
      @click="$emit('click')"
  >
    <v-list-item-title>
      <strong v-if="item.readAt === null">{{ item.title }}</strong>
      <span v-else>{{ item.title }}</span>
    </v-list-item-title>
    <v-list-item-subtitle>
      <strong>{{ makeUserName(item) }}</strong> | {{ item.createdAt | datetime }}
    </v-list-item-subtitle>
    <p class="mt-3">{{ item.text }}</p>
  </v-list-item-content>
</template>

<script>
export default {
  name: 'ItemContent',

  props: {
    item: {
      type: Object,
      required: true,
    },
    pointer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    makeUserName(item) {
      if (item.createdUser && typeof item.createdUser === 'object') {
        if (item.createdUser.firstName && item.createdUser.lastname) {
          return `${item.createdUser.firstName} ${item.createdUser.lastname}`;
        }

        if (item.createdUser.firstName) {
          return item.createdUser.firstName;
        }
      }

      return 'System';
    },
  },
};
</script>

<style lang="scss" module>
.pointer:hover{
  cursor: pointer;
}
</style>
