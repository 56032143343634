<template>
  <div>
    <div
      v-for="(group, index) in conditions"
      :key="'group_' + index"
    >
      <conditions-wrapper
          :total-groups="conditions.length"
          :group-operator="group.operator"
      >
        <template
          v-for="(condition, condIndex) in group.conditions"
        >
          <component
              v-bind:is="condition.type"
              v-if="componentExists(condition.type)"
              :key="'cond_' + index + '_' + condIndex"
              :field="allFields[condition.field] || {}"
              :condition="condition"
          />
          <span
              v-else
              :key="'cond_' + index + '_' + condIndex + '_not_implemented'"
          >
          "{{ condition.type }}" is not implemented yet
          </span>

          <span
              v-if="condIndex < group.conditions.length-1"
              :key="'cond_' + index + '_' + condIndex + 'operator'"
          > {{ group.operator | operator }} </span>
        </template>
      </conditions-wrapper>

      <div
        v-if="index < conditions.length-1"
        :key="'group_' + index + '_operator'"
      ><b>AND</b></div>
    </div>
  </div>
</template>
<script>
import ConditionsWrapper from '@/components/Monitors/ConditionsWrapper';
import Eq from '@/components/Monitors/Conditions/Eq';
import NotEq from '@/components/Monitors/Conditions/NotEq';
import Like from '@/components/Monitors/Conditions/Like';
import IsNull from '@/components/Monitors/Conditions/IsNull';
import Range from '@/components/Monitors/Conditions/Range';
import AdvancedAirwayUsed from '@/components/Monitors/Conditions/AdvancedAirwayUsed';
import BmvUsed from '@/components/Monitors/Conditions/BmvUsed';
import ImageAttached from '@/components/Monitors/Conditions/ImageAttached';
import HasSignature from '@/components/Monitors/Conditions/HasSignature';
import Age from '@/components/Monitors/Conditions/Age';
import Weight from '@/components/Monitors/Conditions/Weight';

export default {
  name: 'ConditionsToText',

  /* eslint-disable vue/no-unused-components */
  components: {
    ConditionsWrapper,
    eq: Eq,
    not_eq: NotEq,
    like: Like,
    is_null: IsNull,
    range: Range,
    advanced_airway_used: AdvancedAirwayUsed,
    bmv_used: BmvUsed,
    image_attached: ImageAttached,
    has_signature: HasSignature,
    age: Age,
    weight: Weight,
  },

  filters: {
    operator(type) {
      const map = {
        and: 'AND',
        not: 'AND',
        or: 'OR',
      };
      return map[type];
    },
  },

  props: {
    conditions: {
      type: Array,
      required: true,
    },
    allFields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    componentExists(type) {
      return Object.prototype.hasOwnProperty.call(
        this.$options.components,
        type,
      );
    },
  },
};
</script>
