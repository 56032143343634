import apiv3 from './apiv3';
import apiv4 from './apiv4';

/**
 * Exception object, used when tried to get undefined api version.
 */
function UndefinedApiException() {}

/**
 * Get api object by version, default "v4"
 * @param {string} [version=v4] - Version of api.
 */
export default function api(version = 'v4') {
  switch (version) {
    case 'v3':
      return apiv3;
    case 'v4':
      return apiv4;
    default:
      throw new UndefinedApiException('Undefined API version.');
  }
}
