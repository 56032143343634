import api from './api';

export default {
  /**
     * Get list of zoll devices.
     *
     * @return Promise<Object>
     */
  getDevices() {
    return api()
      .get('/zoll-devices')
      .then(response => response.data);
  },

  /**
   * Create new ZOLL device.
   *
   * @param {Object} device - Device object
   */
  createDevice(device) {
    return api()
      .post('/zoll-devices', device)
      .then(response => response.data);
  },

  /**
   * Update ZOLL device.
   *
   * @param {Number} deviceId - Device identifier
   * @param {Object} device - Device object to save
   */
  saveDevice(deviceId, device) {
    return api()
      .put(`/zoll-devices/${deviceId}`, device)
      .then(response => response.data);
  },

  /**
   * Get list of zoll devices.
   *
   * @return Promise<Object>
   */
  deleteDevice(id) {
    return api()
      .delete(`/zoll-devices/${id}`)
      .then(response => response.data);
  },
};
