import api from './api';

export default {
  /**
   * Fetch insight data.
   *
   * @return Promise<Object>
   */
  getInsightData(params) {
    return api()
      .get('/insights', { params })
      .then(response => response.data);
  },

  /**
   * Get export XLSX file
   *
   * @return Promise<Object>
   */
  getInsightExportXlsx(params) {
    return api()
      .get('/insights/export/xlsx', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Fetch list of users.
   *
   * @return Promise<Object>
   */
  getUsers() {
    return api()
      .get('/insights/users')
      .then(response => response.data);
  },

  /**
   * Fetch list of provider impressions.
   *
   * @return Promise<Object>
   */
  getProviderImpressions() {
    return api()
      .get('/insights/provider-impressions')
      .then(response => response.data);
  },

  /**
   * Fetch list focuses by category.
   *
   * @return Promise<Object>
   */
  getFocuses(category) {
    return api()
      .get('/insights/focuses', {
        params: {
          category,
        },
      })
      .then(response => response.data);
  },
};
