import _ from 'lodash';

/* eslint func-names:0 */
export default {
  data() {
    return {
      loading: false,
      edited: false,
      saving: false,
      settingsData: null,
      settingsDataOriginal: null,
    };
  },
  watch: {
    settingsData: {
      handler() {
        this.edited = !this.isEqual(this.settingsData, this.settingsDataOriginal);
      },
      deep: true,
    },
    loading() {
      this.$emit('loading', this.loading, this.$options.name);
    },
    edited() {
      this.$emit('edited', this.edited, this.$options.name);
    },
    saving() {
      this.$emit('saving', this.saving, this.$options.name);
    },
  },
  methods: {
    cancel() {
      this.settingsData = _.cloneDeep(this.settingsDataOriginal);
    },
    updateLicenseHoursHandler() {
      // No default action.
    },

    isEqual(object1, object2) {
      const sortedObject1 = this.deepSortObject(_.cloneDeep(object1));
      const sortedObject2 = this.deepSortObject(_.cloneDeep(object2));
      return _.isEqual(sortedObject1, sortedObject2);
    },

    deepSortObject(obj) {
      if (Array.isArray(obj)) {
        return obj.map(this.deepSortObject)
          .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
      } if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).sort().reduce((acc, key) => {
          acc[key] = this.deepSortObject(obj[key]);
          return acc;
        }, {});
      }
      return obj;
    },
  },
};
