<template>
  <div>
    <toolbar
      :users="users"
      :provider-impressions="providerImpressions"
      :focuses="focusItems"
      :loading="loadingToolbar"
      :loading-focus="loadingFocus"
      :user-id.sync="filters.userId"
      :provider-impression.sync="filters.providerImpression"
      :category.sync="filters.category"
      :focus.sync="filters.focus"
    />
    <v-card class="white elevation-3 ma-4">
      <table-toolbar
          :title="title"
          :period.sync="filters.period"
          :date-from.sync="filters.dateFrom"
          :date-to.sync="filters.dateTo"
          :exporting="exporting"
          @export="exportXlsx"
      />
      <insight-table
        :filters="filters"
        :group-by-name="groupByName"
        :loading="loading"
        :items="insightsData"
        :users="users"
        :focuses="focusItems"
        :provider-impressions="providerImpressions"
        @selectFocus="selectFocus"
        @selectUser="selectUser"
        @selectProviderImpression="selectProviderImpression"
      />
    </v-card>
  </div>

</template>

<script>
import _ from 'lodash';
import fileDownload from 'js-file-download';
import { SET_TITLE } from '@/store/mutations';
import Toolbar from '@/components/Insights/Toolbar';
import insights from '@/api/insights';
import InsightTable from '@/components/Insights/InsightsTable';
import INSIGHT_CATEGORIES from '@/enums/insightCategories';
import INSIGHT_CATEGORY_NAME from '@/enums/insightCategoryNames';
import TableToolbar from '@/components/Insights/TableToolbar';

export default {
  name: 'Insights',
  components: { TableToolbar, InsightTable, Toolbar },
  data() {
    return {
      loading: false,
      exporting: false,
      loadingToolbar: false,
      loadingFocus: false,
      groupByName: 'Therapy/Skill',
      filters: {
        userId: null,
        providerImpression: null,
        category: 'therapy',
        focus: null,
        period: 'lastYears',
        dateFrom: null,
        dateTo: null,
      },
      users: [],
      providerImpressions: [],
      focuses: {},
      insightsData: [],
      debounceFunc: null,
    };
  },
  computed: {
    usersMap() {
      const focuses = {};
      this.users.forEach(item => {
        focuses[item.registryId] = `${item.firstName} ${item.lastname}`;
      });
      return focuses;
    },
    providerImpressionsMap() {
      const focuses = {};
      this.providerImpressions.forEach(item => {
        focuses[item.code] = item.name;
      });
      return focuses;
    },
    title() {
      let insight = null;
      switch (this.filters.category) {
        case INSIGHT_CATEGORIES.TRANSPORT_BY_PROVIDER_IMPRESSIONS:
          insight = this.filters.providerImpression
            ? this.providerImpressionsMap[this.filters.providerImpression]
            : 'the entire agency';
          break;
        default:
          insight = this.filters.userId ? this.usersMap[this.filters.userId] : 'the entire agency';
          break;
      }
      return `${INSIGHT_CATEGORY_NAME[this.filters.category]} insights for ${insight}`;
    },
    focusItems() {
      if (Object.prototype.hasOwnProperty.call(this.focuses, this.filters.category)) {
        return this.focuses[this.filters.category];
      }
      return [];
    },
  },
  watch: {
    filters: {
      handler(filters) {
        if (!this.debounceFunc) {
          const that = this;
          this.debounceFunc = _.debounce(() => {
            that.fetchData();
          }, 100);
        }
        this.debounceFunc();
        if (!Object.prototype.hasOwnProperty.call(this.focuses, filters.category)) {
          this.fetchFocuses(filters.category);
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, 'QA/QI Insights');
    this.fetchToolbarData();
    this.fetchData();
  },

  methods: {
    selectFocus(focus) {
      this.filters.focus = focus;
    },

    selectUser(userId) {
      this.filters.userId = userId;
    },

    selectProviderImpression(code) {
      this.filters.providerImpression = code;
    },

    async fetchUsers() {
      const users = await insights.getUsers();
      this.users = users.results;
    },

    async fetchProviderImpressions() {
      const response = await insights.getProviderImpressions();
      this.providerImpressions = response.results;
    },

    async fetchToolbarData() {
      try {
        this.loadingToolbar = true;
        await Promise.all([
          this.fetchUsers(),
          this.fetchProviderImpressions(),
          this.fetchFocuses(this.filters.category),
        ]);
      } finally {
        this.loadingToolbar = false;
      }
    },
    async fetchFocuses(category) {
      try {
        this.loadingFocus = true;
        const response = await insights.getFocuses(category);
        const focuses = _.cloneDeep(this.focuses);
        focuses[category] = response.results;
        this.focuses = focuses;
      } finally {
        this.loadingFocus = false;
      }
    },

    async exportXlsx() {
      try {
        this.exporting = true;
        const response = await insights.getInsightExportXlsx(this.filters);
        fileDownload(response.data, 'insights.xlsx');
      } finally {
        this.exporting = false;
      }
    },

    async fetchData() {
      try {
        this.loading = true;
        const response = await insights.getInsightData(this.filters);
        this.insightsData = response.results;
        this.updateGroupByName();
      } finally {
        this.loading = false;
      }
    },
    updateGroupByName() {
      if (this.filters.focus) {
        switch (this.filters.category) {
          case INSIGHT_CATEGORIES.TRANSPORT_BY_PROVIDER_IMPRESSIONS:
            this.groupByName = 'Provider Impression';
            break;
          default:
            this.groupByName = 'Crew Member';
            break;
        }
      } else {
        switch (this.filters.category) {
          case INSIGHT_CATEGORIES.THERAPY:
            this.groupByName = 'Therapy/Skill';
            break;
          case INSIGHT_CATEGORIES.PROVIDER_IMPRESSION:
            this.groupByName = 'Provider Impression';
            break;
          case INSIGHT_CATEGORIES.ADVANCED_AIRWAYS:
            this.groupByName = 'Device';
            break;
          case INSIGHT_CATEGORIES.MEDS:
            this.groupByName = 'Medication';
            break;
          case INSIGHT_CATEGORIES.TRANSPORT:
          case INSIGHT_CATEGORIES.TRANSPORT_BY_PROVIDER_IMPRESSIONS:
            this.groupByName = 'Transport';
            break;
          default:
            this.groupByName = 'Procedure name';
            break;
        }
      }
    },
  },
};

</script>
