import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    showEditPopup: false,
    selectedHospital: null,
    loading: true,
    search: {
      search: '',
      status: 'all',
    },
    hospitals: [],
    pagination: {
      itemsPerPage: 25,
    },
    paginationInfo: {},
    loadingHospital: false,
    hospital: {},
  },
  actions,
  mutations,
  getters,
};
