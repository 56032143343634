<template>
  <div class="tabs-div">
    <v-tabs
      color="white"
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        :to="tab.path"
        exact
        active-class="primary--text"
        class="subheading text-capitalize tab-item"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-divider/>
  </div>
</template>

<script>
export default {
  name: 'HospitalsTabs',
  data() {
    return {
      tabs: [
        {
          path: '/hospitals',
          name: this.$t('hospitals.tabs.list'),
        },
        {
          path: '/hospitals/types',
          name: this.$t('hospitals.tabs.types'),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.tabs-div {
  padding-bottom: 20px;
}

.tab-item {
  min-width: 130px;
}
</style>
