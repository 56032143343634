<template>
  <v-card class="white elevation-3 ma-4 od-map-exports">
    <exports-toolbar
      :filters="filters"
      :show-clients-filter="false"
      @filtersUpdate="filtersUpdate"
    />
    <od-map-exports-table
      :items="entity.items"
      :pagination="pagination"
      :total-items="pagination.totalItems"
      :loading="loading"
      :use-impersonate="false"
      @update:pagination="updatePagination"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import OdMapExportsTable from '@/components/OdMapExports/OdMapExportsTable';
import ExportsToolbar from '@/components/ExternalServiceExports/ExportsToolbar';
import { CLIENT_LOAD_OD_MAP_EXPORTS } from '@/store/OdMapExports/actions';
import {
  SET_OD_MAP_EXPORTS_FILTERS,
  SET_OD_MAP_EXPORTS_PAGINATION,
} from '@/store/OdMapExports/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';

const { mapState, mapMutations } = createNamespacedHelpers('odMapExports');

export default {
  components: {
    OdMapExportsTable,
    ExportsToolbar,
  },
  mixins: [filtersAndTablesGeneralMixins],

  data() {
    return {
      exportLoading: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },

  methods: {
    ...mapMutations({
      setPagination: SET_OD_MAP_EXPORTS_PAGINATION,
      setFilters: SET_OD_MAP_EXPORTS_FILTERS,
    }),
    ...mapActions({
      loadItems: `odMapExports/${CLIENT_LOAD_OD_MAP_EXPORTS}`,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },
  },
};
</script>
