export const SET_FAXES = 'SET_FAXES';
export const SET_STATUSES = 'SET_STATUSES';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_TABLE_PARAMS = 'SET_TABLE_PARAMS';
export const SET_RESEND_SUCCESS_STATUS = 'SET_RESEND_SUCCESS_STATUS';

export default {
  /**
   * Set results from API response
   * @param state
   * @param results Faxes list and pagination
   */
  [SET_FAXES]: (state, results) => {
    state.items = results.results;
    state.serverPaginationInfo = results.pagination;
  },
  /**
   * Set statuses list for fax.
   * @param state
   * @param data List of statuses
   */
  [SET_STATUSES]: (state, data) => {
    state.statuses = data;
  },
  /**
   * Set table params.
   * @param state
   * @param data Pagination and sorting params.
   */
  [SET_TABLE_PARAMS]: (state, data) => {
    state.tableParams = data;
  },
  /**
   * Set filter values
   * @param state
   * @param data Filter object with clientId,
   * sequenceNumber, dateFrom, dateTo, status
   */
  [SET_FILTERS]: (state, data) => {
    state.filters = data;
  },

  /**
   * Set resend status of resend fax and error message.
   * @param state
   * @param data
   */
  [SET_RESEND_SUCCESS_STATUS]: (state, data) => {
    state.resendSuccessStatus = data.success;
    state.resendErrorMessage = data.message;
  },
};
