export default function getBaseDomain() {
  let domain = null;
  if (window.location.host.indexOf('dev.medic-clipboard.com') !== -1) {
    domain = 'dev.medic-clipboard.com';
  } else if (window.location.host.indexOf('medic-clipboard.com') !== -1) {
    domain = 'medic-clipboard.com';
  } else if (window.location.host.indexOf('localhost:8') === 0) {
    domain = process.env.VUE_APP_API_DOMAIN || 'service.docker';
  } else {
    const hostpartials = window.location.host.split('.');
    const zone = hostpartials.pop();
    const twoLevelDomain = hostpartials.pop();
    domain = `${twoLevelDomain}.${zone}`;
  }
  return domain;
}
