<template>
  <v-card
      elevation="3"
      class="ma-4"
      :loading="loading || loadingSettings"
      style="min-height: 50px;"
  >
    <v-tabs
      v-if="!loading"
      v-model="tabIndex"
    >
      <v-tab
          v-for="item in tabItems"
          :key="item.name"
          exact
          active-class="primary--text"
          class="subheading text-capitalize tab-item"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-divider class="mb-5"/>

    <toolbar
        v-if="!loading && tab !== tabs.SEARCH"
        :downloading="downloading"
        :download-name="downloadName"
        :sending="sending"
        :year.sync="year"
        :month.sync="month"
        :send-disabled="!settings || settings.exportType !== exportTypesEnum.EMAIL"
        :show-export-status="showExportStatus"
        :status="status || {}"
        :loading-status="loadingStatus"
        :loading-retry="loadingRetry"
        @download="downloadFile"
        @send="send"
        @showSettings="showSettings"
        @refreshStatus="loadExportStatus"
        @retry="retryExport"
        @showHelp="$refs.helpDialog.show()"
    />
    <search-toolbar
        v-if="!loading && tab === tabs.SEARCH"
        :filters.sync="searchFilters"
    />

    <v-tabs-items
      v-if="!loading"
      :value="tabIndex"
    >
      <v-tab-item
        v-for="(tabValue, index) in availableTabs"
        v-bind:key="tabValue"
      >
        <reconciliation-table
          :type="tabValue"
          :year="year"
          :month="month"
          :show-response-type-columns="showResponseTypeColumns"
          :show-data-type-column="tabValue === 'allInclusive'"
          :show-case-number-column="showCaseNumberColumn"
          :is-active="index === tabIndex"
        />
      </v-tab-item>
      <v-tab-item>
        <search-table
          v-if="availableTabs.length"
          :filters="searchFilters"
          :show-response-type-columns="showResponseTypeColumns"
          :is-active="availableTabs.length === tabIndex"
        />
      </v-tab-item>
    </v-tabs-items>
    <settings-popup
      ref="settingsPopup"
      :settings="settings || null"
      @saved="loadSettings"
    />
    <help-dialog
      ref="helpDialog"
      :type="tab"
    />
  </v-card>
</template>

<script>
import _ from 'lodash';
import { SET_TITLE } from '@/store/mutations';
import { mapActions } from 'vuex';
import ReconciliationTable from '@/components/Reconciliation/ReconciliationTable';
import Toolbar from '@/components/Reconciliation/Toolbar';
import SearchTable from '@/components/Reconciliation/SearchTable';
import SearchToolbar from '@/components/Reconciliation/SearchToolbar';
import reconciliationMixins from '@/mixins/reconciliationMixins';
import SettingsPopup from '@/components/Reconciliation/SettingsPopup';
import reconciliation from '@/api/reconciliation';
import { SHOW_SNACKBAR } from '@/store/actions';
import CLIENT_IDENTIFIERS from '@/enums/clientIdentifiers';
import RECONCILIATION_UPLOAD_TYPES from '@/enums/reconciliationUploadTypes';
import HelpDialog from '@/components/Reconciliation/HelpDialog';

export default {
  name: 'Reconciliation',
  mixins: [reconciliationMixins],

  components: {
    HelpDialog,
    SettingsPopup,
    SearchToolbar,
    SearchTable,
    Toolbar,
    ReconciliationTable,
  },

  data() {
    return {
      sending: false,
      loading: false,
      loadingSettings: false,
      loadingStatus: false,
      loadingRetry: false,
      availableTabs: [],
      settings: null,
      status: null,
      exportTypesEnum: RECONCILIATION_UPLOAD_TYPES,
    };
  },

  computed: {
    showExportStatus() {
      if (!this.settings) {
        return false;
      }
      return this.settings.exportType === this.exportTypesEnum.SFTP;
    },

    tabItems() {
      const tabs = [];
      const map = {};
      map[this.tabs.ALS] = '"ALS" Analysis (Based on "R" unit)';
      map[this.tabs.ALS_BASED_ON_S] = '"ALS" Analysis (Based on "S" unit)';
      map[this.tabs.BLS] = '"BLS" Analysis (Based on "BLS" unit)';
      map[this.tabs.FIRST_RESPONDER] = 'First Responder';
      map[this.tabs.ALL_INCLUSIVE] = 'All Inclusive';

      _.forEach(map, (name, value) => {
        if (this.availableTabs.includes(value)) {
          tabs.push({ name });
        }
      });

      tabs.push({ name: 'Search RAW CAD Data' });
      return tabs;
    },

    tabMap() {
      const tabsMap = {};
      const allTabs = [
        this.tabs.ALS,
        this.tabs.ALS_BASED_ON_S,
        this.tabs.BLS,
        this.tabs.FIRST_RESPONDER,
        this.tabs.ALL_INCLUSIVE,
      ];
      let counter = 0;
      allTabs.forEach(value => {
        if (this.availableTabs.includes(value)) {
          tabsMap[counter] = value;
          counter++;
        }
      });
      tabsMap[counter] = this.tabs.SEARCH;
      return tabsMap;
    },

    downloadName() {
      const names = {
        als: 'Download ALS Based On "R"',
        alsBasedOnS: 'Download ALS Based On "S"',
        bls: 'Download BLS',
        firstResponder: 'Download 1st Responder',
        allInclusive: 'Download All Inclusive',
      };
      return names[this.tab];
    },

    showResponseTypeColumns() {
      return this.$store.state.user.clientId === CLIENT_IDENTIFIERS.LONG_BEACH;
    },

    showCaseNumberColumn() {
      return this.$store.state.siteInfo && this.$store.state.siteInfo.hasCaseNumber;
    },
  },

  created() {
    this.$store.commit(SET_TITLE, 'Reconciliation');
  },

  mounted() {
    this.loadSettings();
    this.fetchData();
    this.loadExportStatus();
  },

  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Load Reconciliation export settings.
     */
    async loadSettings() {
      this.loadingSettings = true;
      try {
        const value = await reconciliation.getSettings();
        this.settings = value !== '' ? value : null;
      } finally {
        this.loadingSettings = false;
      }
    },

    /**
     * Fetch data for render reconciliation page.
     */
    async fetchData() {
      this.loading = true;
      try {
        await Promise.all([
          this.loadSettings(),
          this.loadAvailableTabs(),
        ]);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Load list of available tabs.
     */
    async loadAvailableTabs() {
      const data = await reconciliation.getAvailableDataTypes();
      this.availableTabs = data.dataTypes;
    },

    /**
     * Load Reconciliation export status.
     */
    async loadExportStatus() {
      this.loadingStatus = true;
      try {
        this.status = await reconciliation.getStatus();
      } finally {
        this.loadingStatus = false;
      }
    },

    /**
     * Retry export.
     */
    async retryExport() {
      this.loadingRetry = true;
      try {
        await reconciliation.retryExport();
        this.status = null;
      } finally {
        this.loadingRetry = false;
      }
    },

    /**
     * Show reconciliation settings.
     */
    showSettings() {
      this.$refs.settingsPopup.show();
    },

    /**
     * Send reconciliation reports to configured emails.
     *
     * @param {String} fileType - File type
     * @param {String} period - Period of report
     */
    async send(fileType, period) {
      this.sending = true;
      try {
        await reconciliation.send({
          clientId: this.clientId,
          year: this.year,
          month: this.month,
          fileType,
          period,
        });
        this.showSnackbar('Sending reconciliation report was started.');
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
