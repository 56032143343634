<template>
  <Layout>
    <router-view :key="$route.fullPath"></router-view>
  </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_IS_PRINTING } from './store/mutations';
import Layout from './components/Layout';
import { INIT, FETCH_CLIENTS } from './store/actions';

export default {
  name: 'App',
  components: {
    Layout,
  },
  data() {
    return {
      mediaQuery: null,
    };
  },
  computed: {
    isPrinting: {
      get() {
        return this.$store.state.isPrinting;
      },
      set(value) {
        this.$store.commit(SET_IS_PRINTING, value);
      },
    },
  },
  async created() {
    await this.INIT();
    if (this.$can('getAll', 'clients')) {
      this.FETCH_CLIENTS();
    }
  },
  mounted() {
    this.mediaQuery = window.matchMedia('print');
    this.mediaQuery.addEventListener('change', this.handlePrintChange);
  },
  beforeDestroy() {
    this.mediaQuery.removeEventListener('change', this.handlePrintChange);
  },
  methods: {
    ...mapActions([INIT, FETCH_CLIENTS]),
    handlePrintChange(e) {
      this.isPrinting = e.matches;
    },
  },
};
</script>

<style lang="scss">
.header-nowrap .v-data-table-header th {
  white-space: nowrap;
}

.no-wrap {
  white-space: nowrap;
}
</style>
