import api from './api';

export default {
  /**
  * Login request.
  *
  * @param {String} userLogin - Login to authenticate
  * @param {String} password - Password
  * @param {Boolean} trustBrowser - Trust this browser
  *
  * @return Promise<Object>
  */
  login(userLogin, password, trustBrowser) {
    return api()
      .post('/auth', {
        login: userLogin,
        password,
        trustBrowser,
      })
      .then(response => response.data);
  },

  /**
   * Verify Sms code.
   *
   * @param {String} token - Token to authenticate
   * @param {String} code - Password
   * @param {Boolean} confirmIp - Password
   * @param {Boolean} trustBrowser - Trust this browser
   *
   * @return Promise<Object>
   */
  verifySms(token, code, confirmIp, trustBrowser) {
    return api()
      .post('/auth/verify-sms', {
        token,
        code,
        confirmIp,
        trustBrowser,
      })
      .then(response => response.data);
  },

  /**
   * Verify Email code.
   *
   * @param {String} token - Token to authenticate
   * @param {String} code - Password
   * @param {Boolean} confirmIp - Password
   * @param {Boolean} trustBrowser - Trust this browser
   *
   * @return Promise<Object>
   */
  verifyEmail(token, code, confirmIp, trustBrowser) {
    return api()
      .post('/auth/verify-email', {
        token,
        code,
        confirmIp,
        trustBrowser,
      })
      .then(response => response.data);
  },

  /**
  * Send SMS verification code.
  *
  * @param {String} token - Token to authenticate
  *
  * @return Promise<Object>
  */
  sendSmsCode(token) {
    return api()
      .post('/auth/send-sms-code', {
        token,
      })
      .then(response => response.data);
  },

  /**
   * Send SMS verification code.
   *
   * @param {String} token - Token to authenticate
   *
   * @return Promise<Object>
   */
  sendEmailCode(token) {
    return api()
      .post('/auth/send-email-code', {
        token,
      })
      .then(response => response.data);
  },

  /**
   * Send verification code.
   *
   * @param {String} token - Token to authenticate
   *
   * @return Promise<Object>
   */
  requestToAdmin(token) {
    return api()
      .post('/auth/request-to-admin', {
        token,
      })
      .then(response => response.data);
  },

  /**
   * Logout
   *
   * @return Promise<Object>
   */
  logout() {
    return api()
      .delete('auth')
      .then(response => response.data);
  },
};
