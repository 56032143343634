<template>
  <view-resolver>
    <template v-slot:adminView>
      <base-nemsis-export
          :type="type"
          :show-settings-page="true"
          :show-clients-filter="true"
          :use-impersonate="true"
      />
    </template>
    <template v-slot:clientView>
      <base-nemsis-export
          :type="type"
          :show-settings-page="false"
          :show-clients-filter="false"
          :use-impersonate="false"
      />
    </template>
  </view-resolver>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import BaseNemsisExport from './BaseNemsisExport';

export default {

  components: {
    BaseNemsisExport,
    ViewResolver,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  mounted() {
    const titles = {
      et3: 'ET3 Export',
      dhs: 'NEMSIS/DHS Export',
    };
    this.$store.commit(SET_TITLE, titles[this.type]);
  },
};
</script>
