const INSIGHT_CATEGORY_NAME = {
  therapy: 'Therapies/Skills/Procedures',
  providerImpression: 'Provider Impressions',
  advancedAirways: 'Airways',
  meds: 'Medications',
  vitals: 'Vital Signs',
  transport: 'Transport',
  transportByProviderImpressions: 'Transport By Provider Impressions',
};

export default INSIGHT_CATEGORY_NAME;
