import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { abilitiesPlugin } from '@casl/vue';
import 'vue-datetime/dist/vue-datetime.css';
import VeeValidate, { Validator } from 'vee-validate';
import VueClipboard from 'vue-clipboard2';
import VueEcho from 'vue-echo-laravel';
import VueHtmlToPaper from 'vue-html-to-paper';
import { VueMaskDirective } from 'v-mask';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

import { SET_SHOW_LOGOUT_HOSPITAL_POPUP } from '@/store/mutations';

// eslint-disable-next-line
import Pusher from 'pusher-js';

import profile from '@/api/profile';
import baseUrl from './baseUrl';
import App from './App';
import apiv3 from './api/apiv3';
import apiv4 from './api/apiv4';
import i18n from './lang/i18n';
import validators from './validators';
import './utils/filters';
import { getAbilitiesForRole } from './abilities';

import createRouter from './router';
import noAuthRoutes from './router/noAuthRoutes';
import createStore from './store';
import createAcl from './acl';
import USER_ROLES from './enums/userRoles';
import ROUTES from './enums/routes';

import vuetify from './plugins/vuetify';

import './styles/app.scss';

Vue.config.productionTip = false;

Validator.extend('validate-hospital-unique', validators.validateHospitalUnique);
Validator.extend('validate-password', validators.validatePassword);
Validator.extend('integer-number', {
  getMessage: field => `The ${field} value is not integer.`,
  validate: value => /^\d+$/.test(value),
});

const store = createStore();
const router = createRouter(store);
const acl = createAcl(router, store);

apiv3.defaults.headers.common = {
  SiteCode: store.state.sitecode,
};
apiv4.defaults.headers.common = {
  SiteCode: store.state.sitecode,
};

/**
 * When error status of api request is 401,
 * and if url required authentication, then redirect to login page.
 * @param {Object} err - error object from axios
 */
const error = (err) => {
  if (
    err.response
    && err.response.status === 401
    && router.currentRoute.name
    && !noAuthRoutes[router.currentRoute.name]
  ) {
    let dontShow = localStorage.getItem('dontShowLogoutHospitalPopup');
    if (dontShow !== null) {
      dontShow = dontShow === '1';
    }
    if (store.state.sitecode === 'hospital' && !dontShow && store.state.initialized) {
      store.commit(`${SET_SHOW_LOGOUT_HOSPITAL_POPUP}`, true);
    } else {
      router.push({ name: ROUTES.LOGIN });
    }
  }
  return Promise.reject(err);
};

apiv3.interceptors.response.use(response => response, error);
apiv4.interceptors.response.use(response => response, error);

window.addEventListener(
  'visibilitychange',
  () => {
    if (document.hidden === true) return;
    if (store.state.sitecode === 'hospital') {
      // When user come back to the browser
      profile.fetchUser();
    }
  },
  false,
);

Vue.use(VeeValidate);
Vue.use(VueClipboard);
Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  authEndpoint: `${baseUrl(store.state.sitecode)}/API/v4/broadcasting/auth`,
  auth: {
    headers: {
      SiteCode: store.state.sitecode,
    },
    withCredentials: true,
  },
});
Vue.use(VueHtmlToPaper, {
  styles: [
    '/css/print.css',
  ],
});
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.directive('mask', VueMaskDirective);

const { ability } = store.getters;
ability.update(getAbilitiesForRole(USER_ROLES.PUBLIC));
Vue.use(abilitiesPlugin, store.getters.ability);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  acl,
  render: (h) => h(App),
}).$mount('#app');
