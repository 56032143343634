import api from './api';

export default {
  /**
   * Delete ftp user.
   *
   * @param {number} clientId - Identifier of client.
   *
   * @return {Promise}
   */
  deleteFtpUser(clientId) {
    return api().delete(`/admin/billing-ftp-users/${clientId}`);
  },

  /**
   * Create new ftp user.
   *
   * @param {Object} ftpUser - Ftp user object.
   *
   * @return {Promise}
   */
  createFtpUser(ftpUser) {
    return api().post('/admin/billing-ftp-users', ftpUser);
  },

  /**
   * Update ftp user.
   *
   * @param {Object} ftpUser - Ftp user object to update.
   *
   * @return {Promise}
   */
  saveFtpUser(ftpUser) {
    return api().put(`/admin/billing-ftp-users/${ftpUser.clientId}`, ftpUser);
  },
};
