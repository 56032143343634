<template>
  <v-container fluid>
    <v-toolbar
        id="hospitalIncidentsToolbar"
        flat
        dense
        height="auto"
        :class="[$style['search-toolbar']]"
    >
      <v-layout
        row
        wrap
      >
        <v-toolbar-items class="mb-2 mt-2">
          <date-range-picker-with-periods
              v-model="dateRange"
              :label="this.filters.rangeBy === 'incidentDate'
              ? 'Incident Date'
              : 'At Hospital Date'"
              position="right"
              class="mr-5"
              @closed="datePickerCloseHandler"
          >
            <div
                slot="header"
                :class="[$style['date-header']]"
            >
              <v-radio-group
                  v-model="dateRangeFieldTmp"
                  row
              >
                <v-radio
                    label="Incident Date"
                    value="incidentDate"
                />
                <v-radio
                    label="At Hospital Date"
                    value="atHospitalDate"
                />
              </v-radio-group>
            </div>
          </date-range-picker-with-periods>
        </v-toolbar-items>
        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.sequenceNumber"
              label="Sequence Number"
              hide-details
              class="mr-5"
          />
        </v-toolbar-items>
        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.agency"
              label="Transport Agency"
              hide-details
              class="mr-5"
          />
        </v-toolbar-items>
        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.unit"
              label="Transport Unit"
              hide-details
              class="mr-5"
          />
        </v-toolbar-items>
        <v-toolbar-items class="mb-2 mt-2">
          <v-text-field
              v-model="filters.lastName"
              label="Pt. Last Name"
              hide-details
              class="mr-5"
          />
        </v-toolbar-items>
      </v-layout>
      <v-btn
          class="float-right mt-2"
          color="primary"
          :loading="loading"
          @click="search"
      >
        <v-icon>search</v-icon>
        Search
      </v-btn>
    </v-toolbar>
  </v-container>

</template>

<script>
import _ from 'lodash';
import _debounce from 'lodash/debounce';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'HospitalIncidentsToolbar',

  components: {
    DateRangePickerWithPeriods,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateRange: null,
      filters: {
        dateRangeFrom: null,
        dateRangeTo: null,
        rangeBy: 'incidentDate',
        sequenceNumber: null,
        lastName: null,
        agency: null,
        unit: null,
      },
      dateRangeFieldTmp: 'incidentDate',
      debounceFunc: null,
    };
  },

  watch: {
    dateRange: {
      handler() {
        const filters = _.cloneDeep(this.filters);
        if (this.dateRange && this.dateRange.length === 2) {
          const [dateFrom, dateTo] = this.dateRange;
          filters.dateRangeFrom = dateFrom;
          filters.dateRangeTo = dateTo;
        }
        if (!this.dateRange || this.dateRange.length !== 2) {
          filters.dateRangeFrom = null;
          filters.dateRangeTo = null;
        }
        this.filters = filters;
      },
      deep: true,
    },
    filters: {
      handler() {
        if (!this.debounceFunc) {
          const that = this;
          this.debounceFunc = _debounce(async () => {
            that.search();
          }, 300);
        }
        this.debounceFunc();
      },
      deep: true,
    },
  },

  methods: {
    /**
     * Run search process.
     */
    search() {
      this.$emit('search', this.filters);
    },

    /**
     * Handler of datetime range picker close action.
     *
     * @param {Boolean} applied - If closed by applied button
     * @param {Boolean} cancelled - If closed by cancel button
     */
    datePickerCloseHandler(applied, cancelled) {
      if (this.filters.rangeBy !== this.dateRangeFieldTmp && !cancelled) {
        this.filters.rangeBy = this.dateRangeFieldTmp;
      }
      if (this.filters.rangeBy !== this.dateRangeFieldTmp && cancelled) {
        this.dateRangeFieldTmp = this.filters.rangeBy;
      }
    },
  },
};
</script>

<style lang="scss" module>
.date-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: thin solid #eee;
  padding-top: 15px;
  padding-left: 15px;
}
.search-toolbar {
  z-index: 10;
}
</style>
