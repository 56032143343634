<template>
  <v-container
    grid-list-xl
    fluid
  >
    <v-layout
      row
      wrap
    >
      <v-flex xl9>
        <v-layout
          row
          wrap
        >
          <v-flex
            :class="[$section.limitedWidth]"
            xl4
          >
            <v-btn-toggle
              v-model="exportType"
              mandatory
            >
              <v-btn
                :class="[$section.tab, $section.tabLarge]"
                :disabled="disabled"
                text
                value="dhs"
              >
                {{ $t('utilitiesExport.formLabels.dhsExport') }}
              </v-btn>
              <v-btn
                :class="[$section.tab, $section.tabLarge]"
                :disabled="disabled"
                text
                value="billing"
              >
                {{ $t('utilitiesExport.formLabels.billingExport') }}
              </v-btn>
            </v-btn-toggle>
          </v-flex>

          <v-flex
            v-if="exportType === DHS"
            :class="[$section.limitedWidth]"
            xl4
          >
            <v-select
              :items="templatesList"
              :value="template"
              :label="$t('utilitiesExport.formLabels.selectTemplate')"
              :disabled="disabled"
              solo
              @change="setTemplate"
            ></v-select>
          </v-flex>

          <v-flex
            v-if="exportType === BILLING"
            :class="[$section.limitedWidth]"
            xl4
          >
            <v-btn-toggle
              v-model="format"
              mandatory
            >
              <v-btn
                :class="[$section.tab, $section.tabSmall]"
                :disabled="disabled || useClientExportSettings"
                text
                value="tab"
              >
                Tab
              </v-btn>
              <v-btn
                :class="[$section.tab, $section.tabSmall]"
                :disabled="disabled || useClientExportSettings"
                text
                value="xml"
              >
                XML
              </v-btn>
              <v-btn
                :class="[$section.tab, $section.tabSmall]"
                :disabled="disabled || useClientExportSettings"
                text
                value="tripToken"
              >
                TripToken
              </v-btn>
            </v-btn-toggle>
          </v-flex>
          <v-flex xl4>
            <v-autocomplete
              :items="clientsList"
              :value="client"
              :label="$t('utilitiesExport.formLabels.selectClient')"
              :disabled="disabled"
              solo
              @change="setClient"
            ></v-autocomplete>
          </v-flex>
          <v-flex xl6>
            <v-layout
              row
              wrap
            >
              <v-flex
                xl6
              >
                <date-picker
                  v-model="dateFrom"
                  :label="$t('utilitiesExport.formLabels.dateFrom')"
                  :disabled="disabled"
                ></date-picker>
              </v-flex>
              <v-flex xl6>
                <date-picker
                  v-model="dateTo"
                  :label="$t('utilitiesExport.formLabels.dateTo')"
                  :disabled="disabled"
                ></date-picker>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xl3>
            <v-text-field
              v-model="sequenceNumber"
              :rules="[checkSequenceNumber]"
              :label="$t('utilitiesExport.formLabels.sequenceNumber')"
              :hint="$t('utilitiesExport.formLabels.commaSeparated')"
              :disabled="disabled || (exportType === BILLING && useClientExportSettings)"
            ></v-text-field>
          </v-flex>
          <v-flex xl3>
            <v-switch
              v-model="includeUpdated"
              :label="$t('utilitiesExport.formLabels.includeUpdated')"
              :disabled="disabled || (exportType === BILLING && useClientExportSettings)"
            ></v-switch>
          </v-flex>
          <v-flex xl3>
            <v-switch
                v-if="exportType === BILLING"
                v-model="useClientExportSettings"
                :label="$t('utilitiesExport.formLabels.useClientExportSettings')"
                :disabled="disabled"
            ></v-switch>
          </v-flex>
          <v-flex xs12>
            <v-btn
              v-if="!disabled"
              :class="$style.button"
              :disabled="formDisabled"
              color="primary"
              @click="sendData"
            >
              Export
            </v-btn>

            <v-btn
              v-if="disabled"
              :class="$style.button"
              @click="backToForm"
            >
              <v-icon :class="$style.icon">arrow_back</v-icon>
              Back
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { FETCH_CLIENTS } from '@/store/actions';
import {
  UTILITIES_EXPORT,
  CLIENTS_LIST,
  SET_VALUE,
  CLIENT,
  TEMPLATE,
  FETCH_TEMPLATES,
  TEMPLATES_LIST,
  SEQUENCE_NUMBER,
  SEQUENCE_NUMBER_VALID,
  SET_SEQUENCE_NUMBER,
  EXPORT_TYPE,
  FORMAT,
  INCLUDE_UPDATED,
  USE_CLIENT_EXPORT_SETTINGS,
  DATES,
  SET_DATE,
  FORM_DISABLED,
  SEND_DATA,
  DHS,
  BILLING,
  FORMAT_MAP,
  FORMAT_VALUES,
  SHOW_RESULTS,
  RESET_EXPORT_DATA,
  RESET_UPLOAD_DATA,
} from '@/store/UtilitiesExport/constants';
import DatePicker from '../DatePicker';

export default {
  components: {
    DatePicker,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      DHS,
      BILLING,
      FORMAT_MAP,
      FORMAT_VALUES,
    };
  },
  computed: {
    ...mapState(UTILITIES_EXPORT, {
      client: CLIENT,
      template: TEMPLATE,
    }),
    ...mapGetters(UTILITIES_EXPORT, {
      clientsList: CLIENTS_LIST,
      templatesList: TEMPLATES_LIST,
      formDisabled: FORM_DISABLED,
    }),
    data() {
      return this.$store.state[UTILITIES_EXPORT];
    },
    dateFrom: {
      get() {
        return this.data[DATES].from;
      },
      set(value) {
        this.$store.commit(`${UTILITIES_EXPORT}/${SET_DATE}`, {
          type: 'from',
          value,
        });
      },
    },
    dateTo: {
      get() {
        return this.data[DATES].to;
      },
      set(value) {
        this.$store.commit(`${UTILITIES_EXPORT}/${SET_DATE}`, {
          type: 'to',
          value,
        });
      },
    },
    exportType: {
      get() {
        return this.data[EXPORT_TYPE];
      },
      set(value) {
        this.$store.commit(`${UTILITIES_EXPORT}/${SET_VALUE}`, {
          type: EXPORT_TYPE,
          value,
        });
      },
    },
    format: {
      get() {
        return this.data[FORMAT];
      },
      set(value) {
        this.$store.commit(`${UTILITIES_EXPORT}/${SET_VALUE}`, {
          type: FORMAT,
          value,
        });
      },
    },
    includeUpdated: {
      get() {
        return this.data[INCLUDE_UPDATED];
      },
      set(value) {
        this.$store.commit(`${UTILITIES_EXPORT}/${SET_VALUE}`, {
          type: INCLUDE_UPDATED,
          value,
        });
      },
    },
    useClientExportSettings: {
      get() {
        return this.data[USE_CLIENT_EXPORT_SETTINGS];
      },
      set(value) {
        this.$store.commit(`${UTILITIES_EXPORT}/${SET_VALUE}`, {
          type: USE_CLIENT_EXPORT_SETTINGS,
          value,
        });
      },
    },
    sequenceNumber: {
      get() {
        return this.data[SEQUENCE_NUMBER].join(', ');
      },
      set(value) {
        const data = value
          .toUpperCase()
          .split(',')
          .map(val => val.trim())
          .filter(val => val !== '');

        this.$store.commit(`${UTILITIES_EXPORT}/${SET_SEQUENCE_NUMBER}`, data);
      },
    },
    radioGroup: {
      get() {
        return this.exportType;
      },
      set(value) {
        this.exportType = value;
      },
    },
  },
  created() {
    if (!this.clientsList.length) {
      this.$store.dispatch(FETCH_CLIENTS);
    }

    this.$store.dispatch(`${UTILITIES_EXPORT}/${FETCH_TEMPLATES}`);
  },
  methods: {
    setClient(value) {
      this.$store.commit(`${UTILITIES_EXPORT}/${SET_VALUE}`, {
        type: CLIENT,
        value,
      });
    },
    setTemplate(value) {
      this.$store.commit(`${UTILITIES_EXPORT}/${SET_VALUE}`, {
        type: TEMPLATE,
        value,
      });
    },
    sendData() {
      // Parse sequence number
      this.prepareSequenceNumber();
      // Send data to backend
      this.$store.dispatch(`${UTILITIES_EXPORT}/${SEND_DATA}`);
    },
    prepareSequenceNumber() {
      const data = this.sequenceNumber
        .toUpperCase()
        .split(',')
        .map(value => value.trim())
        .filter(value => value !== '');

      this.$store.commit(`${UTILITIES_EXPORT}/${SET_SEQUENCE_NUMBER}`, data);
    },
    checkSequenceNumber() {
      return (
        this.$store.getters[`${UTILITIES_EXPORT}/${SEQUENCE_NUMBER_VALID}`]
        || this.$t('utilitiesExport.messages.incorrectSequenceNumber')
      );
    },

    backToForm() {
      // 1. Switch pages
      this.$store.commit(`${UTILITIES_EXPORT}/${SHOW_RESULTS}`, false);
      // 2. Set export & upload data to defaults
      this.$store.dispatch(`${UTILITIES_EXPORT}/${RESET_EXPORT_DATA}`);
      this.$store.dispatch(`${UTILITIES_EXPORT}/${RESET_UPLOAD_DATA}`);
    },
  },
};
</script>

<style lang="scss" src="./stylesheets/style.scss" module />
<style lang="scss" src="./stylesheets/section.scss" module="$section" />
