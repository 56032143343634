<template>
  <div>
    <div :class="[$style.hint, 'text-center']">
      <span :class="[$style.feature]">{{ $t('releaseNotes.feature') }}</span>
      <span :class="[$style.bug]">{{ $t('releaseNotes.bug') }}</span>
    </div>
    <ul :class="[$style.releases]">
      <li
        v-for="(item, index) in releases"
        :key="index"
      >
        <h2 :class="[$style.name]">{{ item.info.name }}<sup
          v-if="!item.info.released"
          :class="[$style['not-released']]"
        >{{ $t('releaseNotes.notReleasedYet') }}</sup></h2>
        <h4 v-if="item.info.description">{{ item.info.description }}</h4>
        <h5 v-if="item.info.releaseDate">
          {{ $t('releaseNotes.releaseDate') }}: {{ item.info.releaseDate | releaseDate }}
        </h5>
        <release-notes-components
          v-if="item.components"
          :components="item.components"
          :show-task-link="showTaskLink"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';
import ReleaseNotesComponents from './ReleaseNotesComponents';

export default {
  name: 'ReleaseNotesReleases',

  filters: {
    /**
     * Filter of release date.
     *
     * @param {Object} date - Date of release.
     *
     * @return {String}
     */
    releaseDate(date) {
      return moment.parseZone(date).format('MM/DD/YYYY');
    },
  },

  components: {
    ReleaseNotesComponents,
  },

  props: {
    releases: {
      type: Array,
      required: true,
    },
    showTaskLink: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.releases {
  padding-bottom: 40px;
  list-style-type: none;

  .name {
    margin-top: 40px;
  }

  .not-released {
    color: #d0021b;
    font-size: small;
  }
}

.hint {
  span {
    padding: 5px;
  }

  .feature {
    background-color: $blue-light;
    margin-right: 10px;
  }

  .bug {
    background-color: $red-light;
  }
}
</style>
