<template>
  <v-text-field
      v-model="textValue"
      :rules="[rules.zipcode]"
      :label="label"
      :maxlength="5"
      @keypress="validateKey"
  />
</template>

<script>
export default {
  name: 'Zipcode',

  props: {
    value: {
      type: [Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      rules: {
        zipcode: value => {
          const pattern = /^\d{5}$/;
          return pattern.test(value) || value === '';
        },
      },
    };
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    validateKey($event) {
      if (this.textValue.toString().length === this.maxlength) {
        $event.preventDefault();
      }
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
  },
};
</script>
