import { AclRule } from 'vue-acl';

export function getConditionObject(type, conditions) {
  return {
    type,
    conditions,
  };
}

export function createAclRule(conditions) {
  let aclRule = null;

  conditions.forEach(condition => {
    if (typeof condition === 'string') {
      if (aclRule === null) {
        aclRule = new AclRule(condition);
      } else {
        aclRule.or(condition);
      }
    } else if (typeof condition === 'object' && !Array.isArray(condition)) {
      const { type } = condition;
      condition.conditions.forEach(value => {
        if (aclRule === null) {
          aclRule = new AclRule(value);
        } else {
          aclRule.or(value);
        }
        aclRule.and(type);
      });
    } else if (Array.isArray(condition)) {
      condition.forEach((value, index) => {
        if (index === 0) {
          if (aclRule === null) {
            aclRule = new AclRule(value);
          } else {
            aclRule.or(value);
          }
        } else {
          aclRule.and(value);
        }
      });
    }
  });

  return aclRule.generate();
}
