import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    title: 'CAD Monitoring',
    sftpStatus: {},
    providers: {},
    forceShowSiteCode: false,
  },
  actions,
  mutations,
  getters,
};
