<template>
  <v-card
    elevation="3"
    class="ma-4 push-notifications"
  >
    <v-flex xs12>
      <v-tabs v-model="activeTab">
        <v-tab>Notifications</v-tab>
        <v-tab>
          Failed Notifications
          <span v-if="pushNotifications.itemsFailed.length">
            ( {{ pushNotifications.itemsFailed.length }} )
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <push-notifications-tab
            ref="pushNotificationsTab"
            @editNotification="editNotification"
            @addNotification="$refs.editPopup.showNew()"
          ></push-notifications-tab>
        </v-tab-item>

        <v-tab-item>
          <push-notifications-tab-failed
            ref="pushNotificationsTabFailed"
            @editNotification="editNotification"
          ></push-notifications-tab-failed>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>

    <push-notifications-edit-popup
      ref="editPopup"
      @saved="updateData"
    ></push-notifications-edit-popup>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { SET_TITLE } from '@/store/mutations';
import PushNotificationsEditPopup from '@/components/PushNotifications/PushNotificationsEditPopup';
import PushNotificationsTab from '@/components/PushNotifications/PushNotificationsTab';
import PushNotificationsTabFailed from '@/components/PushNotifications/PushNotificationsTabFailed';

const { mapState } = createNamespacedHelpers('pushNotifications');

export default {
  components: {
    PushNotificationsTab,
    PushNotificationsTabFailed,
    PushNotificationsEditPopup,
  },

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    ...mapState({
      pushNotifications: state => state,
    }),
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('pushNotifications.header'));
  },

  methods: {
    /**
     * Opens edit notification popup.
     *
     * @param {Object} notification - Notification to edit
     */
    editNotification(notification) {
      this.$refs.editPopup.showEdit(_.clone(notification));
    },

    /**
     * Update data in notification tables.
     */
    updateData() {
      this.$refs.pushNotificationsTab.reloadItems();
      if (this.$refs.pushNotificationsTabFailed) {
        this.$refs.pushNotificationsTabFailed.reloadItems();
      }
    },
  },
};
</script>
