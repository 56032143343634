export const SET_PUSH_NOTIFICATIONS_ITEMS = 'SET_PUSH_NOTIFICATIONS_ITEMS';
export const SET_PUSH_NOTIFICATIONS_ITEMS_FAILED = 'SET_PUSH_NOTIFICATIONS_ITEMS_FAILED';
export const SET_PUSH_NOTIFICATIONS_PAGINATION = 'SET_PUSH_NOTIFICATIONS_PAGINATION';
export const SET_PUSH_NOTIFICATIONS_PAGINATION_FAILED = 'SET_PUSH_NOTIFICATIONS_PAGINATION_FAILED';
export const SET_PUSH_NOTIFICATIONS_FILTERS = 'SET_PUSH_NOTIFICATIONS_FILTERS';
export const SET_PUSH_NOTIFICATIONS_FILTERS_FAILED = 'SET_PUSH_NOTIFICATIONS_FILTERS_FAILED';

export default {
  /**
   * Save push notifications data.
   *
   * @param {Object} state - Push notifications state.
   * @param {{ items: Array, totalItems: number }} data - Push notifications data to set
   */
  [SET_PUSH_NOTIFICATIONS_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.pagination.totalItems = totalItems;
  },

  /**
   * Save failed push notifications data.
   *
   * @param {Object} state - Push notifications state.
   * @param {{ items: Array, totalItems: number }} data - Push notifications data to set
   */
  [SET_PUSH_NOTIFICATIONS_ITEMS_FAILED]: (state, { items, totalItems }) => {
    state.itemsFailed = items;
    state.paginationFailed.totalItems = totalItems;
  },

  /**
   * Save push notifications pagination.
   *
   * @param {Object} state - Push notifications state.
   * @param {Object} pagination - Push notifications pagination.
   */
  [SET_PUSH_NOTIFICATIONS_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Save failed push notifications pagination.
   *
   * @param {Object} state - Push notifications state.
   * @param {Object} pagination - Push notifications pagination.
   */
  [SET_PUSH_NOTIFICATIONS_PAGINATION_FAILED]: (state, pagination) => {
    state.paginationFailed = pagination;
  },

  /**
   * Saves filters of push notifications.
   *
   * @param {Object} state - Push notifications state.
   * @param {Object} filters - Filters to set.
   */
  [SET_PUSH_NOTIFICATIONS_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  /**
   * Saves filters of failed push notifications.
   *
   * @param {Object} state - Push notifications state.
   * @param {Object} filters - Filters to set.
   */
  [SET_PUSH_NOTIFICATIONS_FILTERS_FAILED]: (state, filters) => {
    state.filtersFailed = filters;
  },
};
