// Module name
export const UTILITIES_EXPORT = 'utilitiesExport';

// State fields
export const CLIENT = 'client';
export const TEMPLATE = 'template';
export const TEMPLATES = 'templates';
export const SEQUENCE_NUMBER = 'sequenceNumber';
export const EXPORT_ID = 'exportID';
export const UPLOAD_ID = 'uploadID';
export const INCLUDE_UPDATED = 'includeUpdated';
export const USE_CLIENT_EXPORT_SETTINGS = 'useClientExportSettings';
export const DATES = 'dates';
export const FILE_URL = 'fileUrl';
export const UPLOAD_STATUS = 'uploadStatus';
export const UPLOAD_PROGRESS = 'uploadProgress';
export const FORMAT = 'format';
export const EXPORT_ERROR = 'exportError';
export const UPLOAD_ERROR = 'uploadError';
export const EXPORT_STATUS = 'exportStatus';
export const EXPORT_PROGRESS = 'exportProgress';
export const EXPORT_TYPE = 'exportType';
export const INCIDENTS = 'incidents';
export const TOTAL = 'total';
export const PROCESSED = 'processed';
export const IS_STARTED_PROCESSING = 'isStartedProcessing';

// Mutations
export const SET_VALUE = 'SET_VALUE';
export const SET_SEQUENCE_NUMBER = 'SET_SEQUENCE_NUMBER';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_DATE = 'SET_DATE';
export const SET_EXPORT_ID = 'setExportID';
export const SET_INCIDENTS_DATA = 'setIncidentsData';
export const SET_INCIDENTS_ITEMS = 'setIncidentsItems';
export const SET_PARAM = 'setParam';
export const SET_STATUS = 'setStatus';
export const SHOW_RESULTS = 'showResults';
export const SET_IS_STARTED_PROCESSING = 'setIsStartedProcessing';

// Actions
export const START_LOADING = 'startLoading';
export const STOP_LOADING = 'stopLoading';
export const SET_INCIDENTS = 'setIncidents';
export const RESET_INCIDENTS = 'resetIncidents';
export const RESET_EXPORT_DATA = 'resetExportData';
export const RESET_UPLOAD_DATA = 'resetUploadData';
export const SEND_DATA = 'SEND_DATA';
export const GET_EXPORT_INFO = 'getExportInfo';
export const GET_EXPORT_PROGRESS = 'getExportProgress';
export const START_UPLOAD = 'startUpload';
export const GET_UPLOAD_INFO = 'getUploadInfo';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';

// Getters
export const CLIENTS_LIST = 'clientsList';
export const SELECTED_CLIENT = 'selectedClient';
export const TEMPLATES_LIST = 'TEMPLATES_LIST';
export const BILLING_TYPE = 'billingType';
export const SEQUENCE_NUMBER_VALID = 'sequenceNumberValid';
export const FORM_DISABLED = 'formDisabled';

// Data values
export const FORMAT_MAP = {
  tab: 'Tab',
  xml: 'XML',
  tripToken: 'TripToken',
};
export const FORMAT_VALUES = Object.keys(FORMAT_MAP);
export const DHS = 'dhs';
export const BILLING = 'billing';
export const TYPE = 'type';

// API calls statuses
export const IDLE = 'idle';
export const STABLE = 'stable';
export const LOADING = 'loading';
export const PROCESSING = 'processing';
export const QUEUED = 'queued';
export const SUCCESS = 'success';
export const FAILED = 'failed';
export const STATUS = 'status';
export const PDF_QUEUED = 'pdfQueued';
export const PDF_PROCESSING = 'pdfProcessing';
export const PDF_PROCESSED = 'pdfProcessed';
