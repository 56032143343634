<template>
  <v-card class="elevation-3 ma-4">
    <v-card-text class="ml-2 text--primary">
      <div
        v-if="loading"
        class="text-center"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-if="!loading"
        class="text-left"
      >
        <v-chip
          :color="sftpStatus.lastStatus | statusColor"
          class="ma-1"
          text-color="white"
          small
          label
        >{{ sftpStatus.lastStatus | statusTitle }}</v-chip>:
        {{ $t('cadMonitoring.sftpStatus') }} |
        {{ $t('cadMonitoring.checkInterval') }}: <b>5 min</b> |
        {{ $t('cadMonitoring.lastCheck') }} @:
        <b>{{ sftpStatus.lastCheck | formatDate }} {{ siteInfo.timeZone }}</b> |
        {{ $t('cadMonitoring.lastSuccessfullStatus') }} @:
        <b>{{ sftpStatus.lastSuccess | formatDate }} {{ siteInfo.timeZone }}</b>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import i18n from '@/lang/i18n';
import { FETCH_SFTP_STATUS } from '@/store/CadMonitoring/actions';

export default {
  name: 'SftpStatus',
  filters: {
    statusColor(status) {
      return status === 'OK' ? 'green' : 'warning';
    },
    statusTitle(status) {
      return status === 'OK'
        ? i18n.t('cadMonitoring.statusOk')
        : i18n.t('cadMonitoring.statusWarning');
    },
    formatDate(date) {
      return date
        ? `${moment.parseZone(date).format('MM/DD/YYYY - HH:mm:ss')}`
        : 'None';
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      sftpStatus: state => state.cadMonitoring.sftpStatus,
      siteInfo: state => state.siteInfo,
    }),
  },
  created() {
    this.FETCH_SFTP_STATUS().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions({
      FETCH_SFTP_STATUS: `cadMonitoring/${FETCH_SFTP_STATUS}`,
    }),
  },
};
</script>
