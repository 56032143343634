<template>
  <span>
    Used BMV
  </span>
</template>
<script>

export default {
  name: 'BmvUsed',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
};
</script>
