const defaultState = () => ({
  items: null,
  total: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true],
    page: 1,
    sortBy: ['created_at'],
    itemsPerPage: 25,
    totalItems: 0,
  },
  clients: null,
});

export default defaultState;
