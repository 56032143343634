const RECONCILIATION_TABS = {
  ALS: 'als',
  ALS_BASED_ON_S: 'alsBasedOnS',
  BLS: 'bls',
  FIRST_RESPONDER: 'firstResponder',
  ALL_INCLUSIVE: 'allInclusive',
  SEARCH: 'search',
};

export default RECONCILIATION_TABS;
