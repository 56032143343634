<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="max-width: 350px"
      class="pl-2"
  >
    <v-row
        v-for="license in settingsData"
        :key="'license_' + license.licenseTypeId"
        no-gutters
    >
      <v-simple-checkbox v-model="license.enabled"/>
      <v-text-field
          v-model="license.clientHours.hours"
          :rules="license.enabled ? numberRules : []"
          :min="1"
          :max="100"
          :label="license.licenseTypeName"
          :disabled="!license.enabled"
          type="number"
      />
    </v-row>
  </v-form>
</template>
<script>
import _ from 'lodash';
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import ceHoursSettings from '@/api/ceHoursSettings';

export default {
  name: 'LicenseHours',
  mixins: [componentMixins],
  components: {
  },
  data() {
    return {
      valid: true,
      settingsData: [],
      settingsDataOriginal: [],
      numberRules: [
        (value) => !!value || 'The Hours value is required',
        (value) => {
          if (value) {
            const v = parseFloat(value);
            return (Number.isInteger(v)) || 'The value in this field must be an integer.';
          }
          return true;
        },
        (value) => {
          if (value) {
            const v = parseFloat(value);
            return (v > 0 && v <= 100) || 'The value must be greater than 0 and less than 100.';
          }
          return true;
        },
      ],
    };
  },
  mounted() {
    this.loading = false;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        await this.loadSettingsData();
      } finally {
        this.loading = false;
      }
    },
    async loadSettingsData() {
      let licenseHours = await ceHoursSettings.getLicenseHours();
      licenseHours = licenseHours.map((item) => {
        const licenseHourItem = item;
        if (licenseHourItem.clientHours) {
          licenseHourItem.clientHours.hours = licenseHourItem.clientHours.hours.toString();
          licenseHourItem.enabled = true;
        } else {
          licenseHourItem.clientHours = {
            hours: '',
          };
          licenseHourItem.enabled = false;
        }
        return licenseHourItem;
      });
      this.settingsDataOriginal = _.cloneDeep(licenseHours);
      this.settingsData = licenseHours;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;
        let dataToSave = _.cloneDeep(this.settingsData);
        dataToSave = dataToSave.map(item => {
          const license = item;
          if (!license.enabled) {
            license.clientHours.hours = '';
          }
          delete license.enabled;
          return license;
        });
        await ceHoursSettings.saveLicenseHours(dataToSave);
        await this.loadSettingsData();
        this.edited = false;
        this.$emit('licenseHoursUpdated');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
