export default {
  sequenceNumber: 'Sequence Number',
  client: 'Client',
  selectClient: 'Select Client',
  file: 'File',
  confirmation: 'Confirmation',
  uploadFile: 'Upload File',
  updateFile: 'Update File',
  datePicker: {
    buttons: {
      ok: 'Ok',
      cancel: 'Cancel',
    },
    label: 'Date',
  },
  passwordFormat:
    '* Password must be 12 or more characters long (with at least 1 capital letter and 1 lowercase letter, least 1 '
    + 'digit, 1 special character)',
};
