<template>
  <vue2-date-range-picker
    ref="picker"
    v-model="dates"
    :class="$style.host"
    :maxDate="maxDate"
    timePicker
    control-container-class=""
    :ranges="false"
    :timePicker24Hour="false"
    :time-picker-increment="1"
    @select="dates = $event"
    @update="applyDates"
  >
    <template #input>
      <v-text-field
        v-model="dateRangeText"
        readonly
        prepend-icon="event"
        label="Date range"
      ></v-text-field>
    </template>

    <div
      slot="footer"
      slot-scope="data"
      :class="$style.footer"
    >
      <v-btn
        text
        @click="cancel(data)"
      >Cancel</v-btn>
      <v-btn
        text
        color="primary"
        @click="data.clickApply"
      >Apply</v-btn>
    </div>
  </vue2-date-range-picker>
</template>

<script>
import Vue2DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

/**
 * Display DateRangePicker.
 */
export default {
  name: 'DateRangePicker',

  components: {
    Vue2DateRangePicker,
  },
  props: {
    value: {
      type: [Object, null],
    },
    maxDate: {
      type: [Date],
    },
  },
  data: () => ({
    /**
     * Dates range.
     */
    dates: {
      startDate: null,
      endDate: null,
    },
  }),

  computed: {
    /**
     * Date range input text.
     */
    dateRangeText() {
      const { startDate, endDate } = this.dates;

      if (!startDate) {
        return '';
      }

      const dateTimeFilter = this.$options.filters.datetime;
      const startDateTime = dateTimeFilter(startDate);
      const endDateTime = endDate && dateTimeFilter(endDate);

      if (endDateTime) {
        return `${startDateTime} ~ ${endDateTime}`;
      }

      return `${startDateTime}`;
    },
  },

  watch: {
    /**
     * Set dates variable according to value from parent component.
     */
    value(pickerValue) {
      if (!pickerValue) {
        this.dates = {
          startDate: null,
          endDate: null,
        };
      } else {
        this.dates = {
          startDate: pickerValue.dateFrom,
          endDate: pickerValue.dateTo,
        };
      }
    },
  },

  methods: {
    /**
     * Cancel date time range picker.
     */
    cancel(data) {
      data.clickCancel();
      this.dates = this.value;
    },
    /**
     * Emit dates values.
     */
    applyDates() {
      this.$emit('input', {
        dateFrom: this.dates.startDate,
        dateTo: this.dates.endDate,
      });
    },
  },
};
</script>

<style lang="scss" module>
.host {
  display: block !important;

  div[class="ranges"] {
    width: 120px;

    .rangeList {
      width: 100%;

      .active {
        background-color: $primary-red;
        color: $white;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    border-top: thin solid #eee;
    padding: 5px;
  }
}
</style>

<style lang="scss">
.vue-daterange-picker {
  .daterangepicker {
    top: auto;

    td {
      &.in-range {
        background-color: lighten($primary-red, 35%);
      }

      &.active {
        color: $white;
        background-color: $primary-red;
      }
    }

    &.single.show-ranges {
      flex-wrap: nowrap;
      min-width: 400px;
    }
  }

  .calendar-time {
    .form-control,
    .ampmselect {
      appearance: auto;
      border-radius: 5px;
      border: none;
      border-bottom: thin solid #eee;
    }
  }
}
</style>
