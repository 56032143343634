<template>
  <v-list-item-action>
    <v-btn
      text
      color="primary"
      class="text-capitalize"
      @click="viewFlag(item)"
    >View
    </v-btn>
  </v-list-item-action>
</template>

<script>
import ROUTES from '@/enums/routes';

export default {
  name: 'ViewFlagItemAction',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    viewFlag(item) {
      this.$router
        .push({ name: ROUTES.INCIDENT_VIEW, params: { id: item.payload.incidentId } })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
