<template>
  <v-alert
      v-if="!isWebpSupports"
      dense
      outlined
      type="error"
  >
    Your browser does not support the <strong>WebP</strong> format of images.
    To see pictures as correctly, please update your browser according
    to the <a
      href="https://caniuse.com/webp"
      target="_blank"
  >table</a>.
  </v-alert>
</template>
<script>
import checkWebpFeature from '@/utils/checkWebpFeature';

export default {
  data() {
    return {
      isWebpSupports: true,
    };
  },
  mounted() {
    checkWebpFeature(isSupport => {
      this.isWebpSupports = isSupport;
    });
  },
};
</script>
