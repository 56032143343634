<template>
  <v-dialog
    v-model="showEditPopup"
    persistent
    class="template-edit"
    max-width="600"
    @close="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          {{ isNew ? $t('templates.editPopup.headerNew') : $t('templates.editPopup.headerEdit') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div
            v-show="loading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              color="grey darken"
              center
              indeterminate
            />
          </div>
          <div v-show="!loading">
            <v-alert
              v-model="errorAlert"
              type="error"
              outlined
              dismissible
              transition="scale-transition"
            >
              <div v-html="errorMessage" />
            </v-alert>
            <v-form>
              <v-layout wrap>
                <v-flex xs7>
                  <v-text-field
                    v-model="template.name"
                    v-validate="{ required: true, regex: templateVersionRegexp }"
                    :label="$t('templates.editPopup.version')"
                    :error-messages="errors.collect('templateVersion')"
                    :disabled="!isNew"
                    name="templateVersion"
                  />
                </v-flex>
                <v-flex xs5>
                  <uploader
                    ref="uploader"
                    v-model="file"
                    :allowed-mime-types="mimeTypes"
                    :title="isNew ? $t('general.uploadFile') : $t('general.updateFile')"
                    class="uploader"
                    fixed-width="100%"
                    @file-update="fileUpdated"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="template.sourceCodeVersion"
                    :label="$t('templates.editPopup.sourceCodeVersion')"
                    :disabled="true"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    ref="datePicker"
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="releaseDateFormatted"
                        :label="$t('templates.editPopup.releaseDate')"
                        readonly
                        persistent-hint
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="template.releaseDate"
                      no-title
                      @input="datePicker = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="template.renderTemplateId"
                    :items="templatesSelectOptions"
                    :label="$t('templates.editPopup.renderBy')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="template.description"
                    :label="$t('templates.editPopup.description')"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            :disabled="savingTemplate"
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            :loading="savingTemplate"
            color="primary"
            text
            @click.native="validateAndSave()"
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import JSZip from 'jszip';
import moment from 'moment';
import apiTemplates from '@/api/templates';
import Uploader from '@/components/Uploader';
import {
  SET_SHOW_EDIT_DIALOG,
  SET_SELECTED_TEMPLATE,
} from '@/store/Templates/mutations';
import { TEMPLATES_SELECT_OPTIONS } from '@/store/Templates/getters';
import { FETCH_ALL_TEMPLATES } from '@/store/Templates/actions';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import i18n from '@/lang/i18n';

export const TEMPLATE_NAME_REGEXP = new RegExp(
  '^template_(([0-9]+).([0-9]+).([0-9]+)).zip$',
);

export default {
  name: 'TemplateEditPopup',

  components: {
    Uploader,
  },
  mixins: [errorAlertMixins],

  data() {
    return {
      loading: false,
      file: null,
      datePicker: false,
      savingTemplate: false,
      mimeTypes: [
        'application/zip',
        'application/x-zip',
        'application/x-zip-compressed',
      ],
      templateVersionRegexp: new RegExp('^([0-9]+)\\.([0-9]+)\\.([0-9]+)$'),
      dictionary: {
        attributes: {
          templateVersion: 'Template Version',
        },
        custom: {
          templateVersion: {
            regex: () => i18n.t('templates.editPopup.invalidVersionMessage'),
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      templatesOptions: `templates/${TEMPLATES_SELECT_OPTIONS}`,
    }),

    /**
     * Select template options.
     *
     * @return {Array}
     */
    templatesSelectOptions() {
      return [
        {
          value: null,
          text: i18n.t('templates.defaultTemplate'),
        },
        ...this.templatesOptions,
      ];
    },

    /**
     * Show edit popup computed property.
     */
    showEditPopup: {
      get() {
        return this.$store.state.templates.showEditPopup;
      },
      set(value) {
        this.$store.commit(`templates/${SET_SHOW_EDIT_DIALOG}`, value);
      },
    },

    /**
     * Template object.
     */
    template: {
      get() {
        return this.$store.state.templates.selectedTemplate;
      },
      set(value) {
        this.$store.commit(`templates/${SET_SELECTED_TEMPLATE}`, value);
      },
    },

    /**
     * True if add new template.
     *
     * @return {Boolean}
     */
    isNew() {
      return !this.template.templateId;
    },

    /**
     * Format release date to MM/DD/YYYY format.
     *
     * @return {Boolean}
     */
    releaseDateFormatted() {
      return this.template.releaseDate
        ? moment.parseZone(this.template.releaseDate).format('MM/DD/YYYY')
        : null;
    },
  },
  watch: {
    /**
     * Watch param of show edit popup.
     */
    async showEditPopup(value) {
      this.$validator.reset();
      this.hideError();
      if (value) {
        await this.loadPopup();
      }
      this.$refs.uploader.clear();
    },
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapActions({
      fetchAllTemplates: `templates/${FETCH_ALL_TEMPLATES}`,
    }),
    /**
     * Load data for render popup.
     */
    async loadPopup() {
      this.loading = true;
      await this.fetchAllTemplates();
      this.loading = false;
    },

    /**
     * Handler wnen file is selected.
     *
     * @param {Object} file - Selected file object
     */
    fileUpdated(file) {
      if (file) {
        try {
          if (TEMPLATE_NAME_REGEXP.test(file.name)) {
            const matches = file.name.match(TEMPLATE_NAME_REGEXP);
            if (this.isNew) {
              // eslint-disable-next-line prefer-destructuring
              this.template.name = matches[1];
            } else if (this.template.name !== matches[1]) {
              throw new Error(i18n.t('templates.editPopup.mismatchVersions'));
            }
            this.loadSourceCodeVersionFromFile(file);
            this.hideError();
          } else {
            throw new Error(i18n.t('templates.editPopup.invalidTemplateFile'));
          }
        } catch (e) {
          this.$refs.uploader.clear();
          this.showError(e.message);
        }
      }
    },

    /**
     * Unpack archive and get source code version.
     *
     * @param {File} file - zip file of template
     */
    loadSourceCodeVersionFromFile(file) {
      JSZip.loadAsync(file)
        .then(content => {
          if ('VERSION' in content.files) {
            return content.files.VERSION.async('text');
          }
          return null;
        })
        .then(version => {
          this.template.sourceCodeVersion = version;
        });
    },

    /**
     * Save or create template.
     */
    saveTemplate() {
      if (this.template.templateId) {
        return apiTemplates.saveTemplate(this.template);
      }
      return apiTemplates.createTemplate(this.template);
    },

    /**
     * Validate template values and save if it's ok.
     */
    async validateAndSave() {
      this.hideError();
      let isValid = await this.$validator.validateAll();
      const errors = [];

      if (this.isNew && !this.file) {
        isValid = false;
        errors.push(i18n.t('templates.editPopup.fileRequired'));
      }

      if (!this.template.releaseDate) {
        isValid = false;
        errors.push(i18n.t('templates.editPopup.releaseDateRequired'));
      }
      if (!isValid && errors.length) {
        this.showError(errors.join('<br/>'));
      }

      if (isValid && !this.savingTemplate) {
        this.savingTemplate = true;
        try {
          if (this.file) {
            const presignedData = await this.$refs.uploader.upload();
            this.template.tmpFilename = presignedData.tmpFilename;
            this.template.filename = this.file.name;
          }
          await this.saveTemplate(this.template);
          this.showEditPopup = false;
          this.$emit('saved');
          this.savingTemplate = false;
        } catch (e) {
          this.savingTemplate = false;
          this.parseErrorResponse(e.response);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.uploader .upload-btn {
  padding-right: 7px !important;
}
</style>
