<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span>Export Settings</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            v-if="dialog"
            class="pb-0"
        >
          <v-alert
              v-if="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          <v-row>
            <v-col sm="12">
              <v-autocomplete
                  v-if="this.isNew"
                  v-model="clientId"
                  :items="clients"
                  label="Client"
                  clearable
              />

              <v-text-field
                  v-model="settingsData.wsdl"
                  v-validate="{ required: isRequired}"
                  label="WSDL Url"
                  maxlength="255"
                  name="wsdl"
                  :error-messages="errors.collect('wsdl')"
              ></v-text-field>

              <v-text-field
                  v-model="settingsData.endpoint"
                  v-validate="{ required: isRequired}"
                  label="Endpoint Location"
                  maxlength="255"
                  name="endpoint"
                  :error-messages="errors.collect('endpoint')"
              ></v-text-field>

              <v-text-field
                  v-model="settingsData.username"
                  v-validate="{ required: isRequired}"
                  maxlength="255"
                  label="Username"
                  name="username"
                  :error-messages="errors.collect('username')"
              ></v-text-field>

              <v-text-field
                  v-model="settingsData.password"
                  v-validate="{ required: isRequired}"
                  maxlength="255"
                  label="API Key/Password"
                  name="password"
                  type="password"
                  :error-messages="errors.collect('password')"
              ></v-text-field>

              <v-text-field
                  v-model="settingsData.organization"
                  v-validate="{ required: isRequired}"
                  maxlength="255"
                  label="Organization"
                  name="organization"
                  :error-messages="errors.collect('organization')"
              ></v-text-field>

              <v-select
                  v-model="settingsData.interval"
                  :items="intervals"
                  :hint="settingsData.interval
                  ? 'Note: time is 0600 (PST) for all options. Query period: ' + getPeriod()
                  : null"
                  persistent-hint
                  label="Export interval"
              />
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              :disabled="this.isNew && !clientId"
              @click="validateAndSave"
          >
            <template>Save</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import nemsisExport from '@/api/nemsisExport';
import EXPORT_INTERVALS from '@/enums/exportIntervals';
import moment from 'moment';

export default {
  name: 'SettingsPopup',
  mixins: [errorAlertMixins],
  props: {
    type: {
      type: String,
      required: true,
    },
    allSettings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      isNew: false,
      clientId: null,
      settingId: null,
      settingsData: {
        wsdl: null,
        endpoint: null,
        username: null,
        password: null,
        organization: null,
        interval: null,
        devMode: false,
      },
      saving: false,
      dictionary: {
        attributes: {
          wsdl: 'WSDL Url',
          endpoint: 'Endpoint Location',
          username: 'Username',
          password: 'API Key/Password',
          organization: 'Organization',
          interval: 'Interval',
        },
      },
      intervals: [
        { value: null, text: 'No Scheduled Export' },
        { value: EXPORT_INTERVALS.DAILY, text: 'Every Day' },
        { value: EXPORT_INTERVALS.WEEKLY, text: 'Every Monday' },
        { value: EXPORT_INTERVALS.MONTHLY, text: 'First of Every Month' },
      ],
    };
  },

  computed: {
    clients() {
      const { clientsList } = this.$store.state;
      if (!clientsList || !clientsList.length) {
        return [];
      }
      const ids = [];
      this.allSettings.forEach(item => {
        ids.push(item.clientId);
      });
      return clientsList.map(client => ({
        text: client.name,
        value: client.registryId,
      })).filter(item => !ids.includes(item.value));
    },
    isRequired() {
      return !!(this.settingsData.wsdl
          || this.settingsData.endpoint
          || this.settingsData.username
          || this.settingsData.password
          || this.settingsData.organization
          || this.settingsData.interval
      );
    },
    isClear() {
      return !this.settingsData.wsdl
          && !this.settingsData.endpoint
          && !this.settingsData.username
          && !this.settingsData.password
          && !this.settingsData.organization
          && !this.settingsData.interval;
    },
  },

  watch: {
    settingsData: {
      handler() {
        if (!this.isRequired) {
          this.$validator.reset();
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Show Export Settings dialog.
     */
    async show(settings) {
      this.hideError();
      this.$validator.reset();
      this.settingId = settings ? settings.id : null;
      this.clientId = settings ? settings.clientId : null;
      if (settings) {
        this.settingsData = _.cloneDeep(settings);
        this.isNew = false;
      } else {
        this.settingsData = {
          wsdl: null,
          endpoint: null,
          username: null,
          password: null,
          organization: null,
          interval: null,
          devMode: false,
        };
        this.isNew = true;
      }
      this.dialog = true;
    },

    /**
     * Validate form and save if is valid.
     */
    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.saving = true;
      try {
        const settingsData = _.cloneDeep(this.settingsData);
        settingsData.clientId = this.clientId;
        settingsData.type = this.type;
        if (this.isClear) {
          await nemsisExport.deleteSettings(this.settingId);
        } else if (this.settingId) {
          await nemsisExport.saveSettings(this.settingId, settingsData);
        } else if (!this.settingId) {
          await nemsisExport.createSettings(settingsData);
        }
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.saving = false;
      }
      this.$emit('saved');
    },

    /**
     * Get example of export period.
     *
     * @return {String}
     */
    getPeriod() {
      if (this.settingsData.interval === EXPORT_INTERVALS.DAILY) {
        return moment().format('MM/DD/YYYY');
      }
      if (this.settingsData.interval === EXPORT_INTERVALS.WEEKLY) {
        return `${moment().startOf('week').add(1, 'days').format('MM/DD/YYYY')} - ${
          moment().endOf('week').add(1, 'days').format('MM/DD/YYYY')}`;
      }
      if (this.settingsData.interval === EXPORT_INTERVALS.MONTHLY) {
        return `${moment().startOf('month').format('MM/DD/YYYY')} - ${
          moment().endOf('month').format('MM/DD/YYYY')}`;
      }
      return 'none';
    },
  },
};
</script>
<style lang="scss">
.nfirs-right-container {
  border-left: 1px solid #e1e1e3;
}
</style>
