<template>
  <materials
    :show-export-xls="true"
    :show-client-selection="false"
    :need-client-id="true"
    :show-county-paramedic-tab="true"
    @loadCategories="loadClientCategories"
    @loadDocuments="loadClientDocuments"
    @exportDocuments="exportClientDocuments"
/>
</template>

<script>
import { mapActions } from 'vuex';
import Materials from '../Materials';
import {
  CLIENT_EXPORT_DOCUMENTS,
  CLIENT_LOAD_CATEGORIES,
  CLIENT_LOAD_DOCUMENTS,
} from '../../store/Documents/actions';

export default {
  components: {
    Materials,
  },
  methods: {
    ...mapActions({
      loadClientDocuments: `documents/${CLIENT_LOAD_DOCUMENTS}`,
      exportClientDocuments: `documents/${CLIENT_EXPORT_DOCUMENTS}`,
      loadClientCategories: `documents/${CLIENT_LOAD_CATEGORIES}`,
    }),
  },
};
</script>
