<template>
  <div class="mb-2">
    <v-toolbar flat>
      <span class="mr-5">{{ title }}</span>
      <v-autocomplete
          v-model="preset"
          :items="presetsItems"
          hide-details
          class="mr-10"
          style="max-width: 300px"
          item-value="id"
          item-text="name"
          :loading="loading"
          :disabled="loading"
      />
      <v-btn
        class="mr-5"
        :disabled="saveIsDisabled || !isChanged"
        @click="savePreset()"
      >Save Preset</v-btn>
      <manage-popup
        v-if="presets.length"
        :presets="presets"
        @deleted="deleteHandler"
        @updated="updateHandler"
      />
    </v-toolbar>
    <confirmation ref="confirmationPopup"/>
    <save-preset-popup
      ref="savePresetPopup"
      :type="type"
      :data="data"
      @saved="addedNew"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import Confirmation from '@/components/Confirmation';
import ManagePopup from '@/components/Incidents/Presets/ManagePopup';
import SavePresetPopup from '@/components/Incidents/Presets/SavePresetPopup';
import presetManager from '@/api/presetManager';

export default {
  name: 'PresetManager',
  components: { SavePresetPopup, ManagePopup, Confirmation },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    presets: {
      type: Array,
      required: true,
    },
    data: {
      type: [Object, Array],
      required: true,
    },
    blankData: {
      type: [Object, Array],
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    preSelected: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      preset: null,
      ignoreWatch: false,
    };
  },
  computed: {
    /**
     * Get object where index is preset identifier, for fast search.
     *
     * @return {Object}
     */
    presetById() {
      const items = {};
      this.presets.forEach(preset => {
        items[preset.id] = preset;
      });
      return items;
    },

    /**
     * Get object where index is preset identifier and value is index in preset list.
     *
     * @return {Object}
     */
    presetIndexes() {
      const items = {};
      this.presets.forEach((preset, index) => {
        items[preset.id] = index;
      });
      return items;
    },

    /**
     * Get selected preset.
     *
     * @return {Object}
     */
    selectedPreset() {
      return this.preset ? this.presetById[this.preset] : null;
    },

    /**
     * Preset is changed.
     *
     * @return {Boolean}
     */
    isChanged() {
      if (!this.selectedPreset && !_.isEqual(this.data, this.blankData)) {
        return true;
      }
      return this.selectedPreset && !_.isEqual(this.data, this.selectedPreset.data);
    },

    /**
     * Get list of preset items for select control.
     *
     * @return {Array}
     */
    presetsItems() {
      const items = [{
        id: null,
        name: `New Preset${!this.selectedPreset && this.isChanged ? ' *' : ''}`,
      }];
      this.presets.forEach(presetItem => {
        const preset = _.clone(presetItem);
        if (this.selectedPreset && preset.id === this.selectedPreset.id && this.isChanged) {
          preset.name += ' *';
        }
        if (preset.isCollaborate) {
          preset.name = `${preset.name} (Collaborated)`;
        }
        items.push(preset);
      });
      return items;
    },

    /**
     * Determines save button is disabled.
     *
     * @return {Boolean}
     */
    saveIsDisabled() {
      return _.isEqual(this.data, this.blankData);
    },
  },
  mounted() {
    if (this.preSelected !== undefined) {
      this.set(this.preSelected);
    }
  },
  watch: {
    preset(newValue, oldValue) {
      if (this.ignoreWatch) {
        this.ignoreWatch = false;
        return;
      }
      const newData = this.selectedPreset ? this.selectedPreset.data : this.blankData;
      const oldPreset = oldValue ? this.presetById[oldValue] : null;
      const oldPresetIsChanged = oldPreset && !_.isEqual(oldPreset.data, this.data);

      if (oldValue === null && !_.isEqual(this.data, this.blankData)) {
        this.$refs.confirmationPopup.showConfirm(
          'Warning',
          'Are you sure that you want to set this preset? Your current values may be lost.',
          () => {
            this.$emit('selected', newData);
          },
          () => {
            this.ignoreWatch = true;
            this.preset = oldValue;
          },
        );
      } else if (oldValue && oldPresetIsChanged) {
        this.$refs.confirmationPopup.showConfirm(
          'Warning',
          'Are you sure that you want to set this preset? Your changes of preset may be lost.',
          () => {
            this.$emit('selected', newData);
          },
          () => {
            this.ignoreWatch = true;
            this.preset = oldValue;
          },
        );
      } else {
        this.$emit('selected', newData);
      }
    },
  },
  methods: {
    /**
     * Set preset as current
     *
     * @param {Number} presetId - Preset identifier
     */
    set(presetId) {
      if (this.preset !== presetId) {
        this.ignoreWatch = true;
      }
      this.preset = presetId;
    },

    /**
     * Handler for add new preset event.
     *
     * @param {Object} preset - Preset object
     */
    addedNew(preset) {
      this.$emit('added', preset);
      this.ignoreWatch = true;
      this.preset = preset.id;
    },

    /**
     * Save preset logic for save existing preset or new.
     */
    savePreset() {
      if (!this.preset) {
        this.$refs.savePresetPopup.show();
      } else {
        this.$refs.confirmationPopup.showConfirm(
          'Confirmation',
          `Are you sure that you want to update your "${this.selectedPreset.name}" preset?`,
          async () => {
            const presetData = _.cloneDeep(this.selectedPreset);
            presetData.data = this.data;
            await presetManager.update(presetData);
            this.$emit('updated', this.presetIndexes[presetData.id], presetData);
          },
        );
      }
    },

    /**
     * Reset state.
     */
    reset() {
      this.ignoreWatch = true;
      this.preset = null;
    },

    /**
     * Handler for delete preset event.
     *
     * @param {Number} presetIndex - Preset index
     */
    deleteHandler(presetIndex) {
      if (this.presets[presetIndex].id === this.preset) {
        this.ignoreWatch = true;
        this.preset = null;
        this.$emit('selected', null);
      }
      this.$emit('deleted', presetIndex);
    },

    /**
     * Handler for delete preset event.
     *
     * @param {Number} index - Preset index
     * @param {Object} preset - Preset object
     */
    updateHandler(index, preset) {
      this.$emit('updated', index, preset);
    },
  },
};
</script>
