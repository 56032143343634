import fetchAllStates from '@/api/states';
import { SET_STATES } from './mutations';

export const LOAD_STATES = 'LOAD_STATES';

export default {
  /**
   * Load and save lookup states.
   *
   * @param commit - Vuex commit.
   */
  [LOAD_STATES]: async ({ commit }) => {
    const result = await fetchAllStates();
    commit(SET_STATES, result.results);
  },
};
