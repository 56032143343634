<template>
  <div v-if="providers[clientId].countFailedFiles">
    <b>Not processed files:</b>
    <v-list
      two-line
      :class="$style.actions"
    >
      <v-list-item
        v-for="(item, index) in providers[clientId].failedFiles"
        :key="item.file"
        >
        <v-list-item-action>
          <failed-files-actions
            v-if="item.storage == 's3'"
            :client-id="clientId"
            :file="item.file"
          ></failed-files-actions>
        </v-list-item-action>
        <v-list-item-content :key="index">
          <v-list-item-title>{{ item.file }} (<b>{{ item.storage }}</b>)</v-list-item-title>
          <v-list-item-subtitle>{{ item.reason | reason }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FailedFilesActions from '@/components/CadMonitoring/FailedFilesActions';

export default {
  name: 'FailedFiles',
  components: {
    FailedFilesActions,
  },
  filters: {
    reason(reason) {
      return reason || 'Unknown reason';
    },
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('cadMonitoring', ['providers']),
  },
};
</script>

<style lang="scss" module>
.actions button::before {
  opacity: .5;
}

.actions button:hover::before {
  opacity: .9 !important;
}
</style>
