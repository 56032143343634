<template>
  <div>
    <v-tooltip
        v-model="showVerifyContactTooltip"
        bottom
        content-class="primary tooltip-bottom"
    >
      <template v-slot:activator="{ attrs }">
        <v-btn
            v-bind="attrs"
            icon
            @click="showVerifyContactDataDialog()"
        >
          <v-icon size="25">mdi-account-alert</v-icon>
        </v-btn>
      </template>
      <span>Please confirm your contact details</span>
    </v-tooltip>
  </div>
</template>

<script>
import { SET_VERIFY_CONTACT_DATA_DIALOG } from '@/store/mutations';

export default {
  name: 'VerifyContactDataButton',
  data() {
    return {
      showVerifyContactTooltip: false,
    };
  },

  created() {
    setTimeout(() => {
      this.showVerifyContactTooltip = true;
      this.closeTooltipAfter(10000);
    }, 5000);
  },

  methods: {
    showVerifyContactDataDialog() {
      this.$store.commit(SET_VERIFY_CONTACT_DATA_DIALOG, true);
    },

    closeTooltipAfter(milliseconds) {
      setTimeout(() => {
        this.showVerifyContactTooltip = false;
      }, milliseconds);
    },
  },
};
</script>

<style>
@import '../../styles/tooltip.css';
</style>
