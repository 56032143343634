<template>
  <v-card class="white elevation-3 ma-4 fixed-header">
    <v-toolbar
      flat
      color="white"
    >
      <v-flex xs4>
        <v-text-field
          v-model="search.text"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-flex>
      <v-flex xs2>
        <v-select
          v-model="search.status"
          :items="statuses"
          :label="$t('admins.status')"
          single-line
          hide-details
          class="ml-4"
        />
      </v-flex>
      <v-spacer/>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="editItem()"
      >+ ADD
      </v-btn>
    </v-toolbar>
    <v-data-table
      ref="dataTable"
      :headers="header"
      :items="filteredItems"
      :loading="loading"
      :class="[ $style.admintable ]"
      :height="tableHeight"
      fixed-header
    >
      <template v-slot:body="{ items }">
        <tbody>
          <v-hover
            v-for="item in items"
            :key="item.id"
>
            <tr
              slot-scope="{ hover }"
              :class="{ [ $style.deleted ]: item.deleted }"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.fullName }}</td>
              <td>{{ item.lastLoginAt | date }}</td>
              <td>{{ item.createdAt | date }}</td>
              <td>
                <span
                    v-if="item.phoneVerified"
                    :class="[$style.verified]"
                >{{ item.phone }}</span>
                <v-tooltip
                    v-else
                    bottom
                    content-class="primary tooltip-bottom"
                >
                  <template v-slot:activator="{ on }">
                  <span
                      :class="[$style.not_verified]"
                      v-on="on"
                  >{{ item.phone }}</span>
                  </template>
                  <div class="text-center">
                    The phone is not verified
                  </div>
                </v-tooltip>
              </td>
              <td>
                <span
                    v-if="item.emailVerified"
                    :class="[$style.verified]"
                >{{ item.email }}</span>
                <v-tooltip
                    v-else
                    bottom
                    content-class="primary tooltip-bottom"
                >
                  <template v-slot:activator="{ on }">
                  <span
                      :class="[$style.not_verified]"
                      v-on="on"
                  >{{ item.email }}</span>
                  </template>
                  <div class="text-center">
                    The email is not verified
                  </div>
                </v-tooltip>
              </td>
              <td
                :class="[ $style.actions ]"
                class="text-right no-wrap"
              >
                <v-icon
                  v-show="hover"
                  color="grey darken-2"
                  class="mr-3"
                  @click="editItem(item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="!item.deleted"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showDelete(item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-else
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showRestore(item)"
                >
                  restore
                </v-icon>
              </td>
            </tr>
          </v-hover>
        </tbody>
      </template>
    </v-data-table>
    <admin-edit-popup
      @update="update()"
      @close="close()"
    ></admin-edit-popup>
    <confirmation
      ref="confirmationPopup"
      @confirmed="getData()"
    ></confirmation>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SET_TITLE } from '@/store/mutations';
import { FETCH_DATA, UPDATE_DIALOG, EDITED_ITEM } from '@/store/Admin/actions';
import { deleteAdmin, restoreAdmin } from '@/api/admin';
import AdminEditPopup from '@/components/Admin/AdminEditPopup';
import Confirmation from '@/components/Confirmation';
import i18n from '@/lang/i18n';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'AdminList',
  components: {
    Confirmation,
    AdminEditPopup,
  },
  mixins: [fixedHeader],
  data() {
    return {
      dialog: false,
      loading: true,
      search: {
        text: '',
        status: '',
      },
      header: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'fullName',
        },
        {
          text: 'Last Login',
          value: 'lastLoginAt',
        },
        {
          text: 'Created',
          value: 'createdAt',
        },
        {
          text: 'Phone',
          value: 'phone',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('adminList', ['admins', 'editedItem']),
    /**
     * Admin statuses.
     */
    statuses() {
      return this.$t('admins.statuses').map(status => ({
        text: status,
        value: status.toLowerCase(),
      }));
    },

    filteredItems() {
      let filteredItems = this.admins;
      if (this.search.text) {
        const patt = new RegExp(this.search.text);
        filteredItems = filteredItems.filter(item => patt.test(item.fullName));
      }
      if (this.search.status) {
        switch (this.search.status) {
          case 'deleted':
            filteredItems = filteredItems.filter(item => item.deleted);
            break;
          case 'active':
            filteredItems = filteredItems.filter(item => !item.deleted);
            break;
          case 'all':
          default:
            break;
        }
      }
      return filteredItems;
    },
  },
  created() {
    this.$store.commit(SET_TITLE, this.$store.state.adminList.title);
    this.getData();
  },
  mounted() {
    this.search.status = 'active';
  },
  methods: {
    ...mapActions({
      FETCH_DATA: `adminList/${FETCH_DATA}`,
      UPDATE_DIALOG: `adminList/${UPDATE_DIALOG}`,
      EDITED_ITEM: `adminList/${EDITED_ITEM}`,
    }),
    getData() {
      this.FETCH_DATA().then(() => {
        this.loading = false;
      });
    },

    /**
     * Open dialog for edit or add admin.
     *
     * @param {Object|null} item - Admin object for edit, empty for add.
     */
    editItem(item) {
      this.EDITED_ITEM(item || {});
      this.UPDATE_DIALOG(true);
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} admin - Admin object.
     */
    showDelete(admin) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('admins.deleteConfirmationTitle'),
        i18n.t('admins.deleteConfirmationText', admin),
        async () => {
          await deleteAdmin(admin.id);
        },
      );
    },
    /**
     * Show restore confirmation popup.
     *
     * @param {Object} admin - Admin object.
     */
    showRestore(admin) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('admins.restoreConfirmationTitle'),
        i18n.t('admins.restoreConfirmationText', admin),
        async () => {
          await restoreAdmin(admin.id);
        },
      );
    },
    update() {
      this.close();
      this.getData();
    },
    close() {
      this.UPDATE_DIALOG(false);
      this.loading = false;
    },
  },
};
</script>

<style>
@import '../../styles/tooltip.css';
</style>

<style lang="scss" module>
.admintable {
  .deleted {
    background-color: $red-light;
  }

  .actions {
    width: 15%;
  }

  .not_verified {
    color: red;
  }
  .verified {
    color: green;
  }
}
</style>
