import { render, staticRenderFns } from "./AddFlagCommentPopup.vue?vue&type=template&id=31113c29&scoped=true&"
import script from "./AddFlagCommentPopup.vue?vue&type=script&lang=js&"
export * from "./AddFlagCommentPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31113c29",
  null
  
)

export default component.exports