<template>
  <v-list-item-action>
    <span>
      <v-btn
        icon
        :loading="isLoading && isDisabledReject"
        :disabled="isDisabledAccept"
        @click="acceptPreset(item)"
      >
        <v-icon color="red lighten-4">mdi-check</v-icon>
      </v-btn>
      <v-btn
        icon
        :loading="isLoading && isDisabledAccept"
        :disabled="isDisabledReject"
        @click="rejectPreset(item)"
      >
        <v-icon color="red lighten-4">mdi-close</v-icon>
      </v-btn>
    </span>
  </v-list-item-action>
</template>

<script>
import presetManager from '@/api/presetManager';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { LOAD_COLUMN_PRESETS, LOAD_SEARCH_PRESETS } from '@/store/Incidents/actions';
import { mapActions } from 'vuex';

export default {
  name: 'AcceptOrRejectPresetAction',
  mixins: [errorAlertMixins],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isDisabledAccept: false,
      isDisabledReject: false,
    };
  },

  methods: {
    ...mapActions({
      loadColumnPresets: `incidents/${LOAD_COLUMN_PRESETS}`,
      loadSearchPresets: `incidents/${LOAD_SEARCH_PRESETS}`,
    }),

    async acceptPreset(item) {
      this.isLoading = true;
      this.isDisabledReject = true;

      try {
        await presetManager.accept(item.payload.presetFieldsId, { notificationId: item.id });
        this.$emit('updateNotificationList', item);
        const promises = [];
        promises.push(this.loadColumnPresets());
        promises.push(this.loadSearchPresets());
        await Promise.all(promises);
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.isLoading = false;
        this.isDisabledReject = false;
      }
    },

    async rejectPreset(item) {
      this.isLoading = true;
      this.isDisabledAccept = true;

      try {
        await presetManager.reject(item.payload.presetFieldsId, { notificationId: item.id });
        this.$emit('updateNotificationList', item);
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.isLoading = false;
        this.isDisabledAccept = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
