export default {
  TEMPLATE: 'ERROR_Template',
  CAD: 'ERROR_CAD',
  EXPORT: 'ERROR_Export',
  PUT_INCIDENT: 'ERROR_PutIncident',
  PRIVATE_AMBULANCE: 'EXPORT-Private-Ambulance',
  BILLING_EXPORT: 'EXPORT-billing_export',
  BACKGROUND_WORKER: 'APP-worker_exceptions',
  EXTERNAL_SERVICE_ERROR: 'ERROR_ExternalErrors',
  SENT_EMAILS: 'Sent_emails',
};
