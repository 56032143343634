<template>
  <div>
    <v-menu
      ref="menu"
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="localDate"
      :disabled="disabled"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="localDate"
          :label="label"
          :disabled="disabled"
          prepend-inner-icon="event"
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="localDate"
        :max="max"
        :min="min"
        no-title
        scrollable
      >
        <v-spacer/>
        <v-btn
          text
          color="primary"
          @click="clear"
        >
          Clear
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'DatePicker',
  props: {
    label: {
      type: String,
      default: 'Select Date',
    },
    value: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      open: false,
      localDate: null,
    };
  },
  watch: {
    value(newVal) {
      this.localDate = newVal;
    },
    localDate() {
      this.save();
    },
  },
  mounted() {
    this.localDate = this.value;
  },
  methods: {
    /**
     * Update local date.
     */
    update() {
      this.localDate = this.value;
    },

    /**
     * Save input date and close picker.
     */
    save() {
      this.$refs.menu.save(this.localDate);
      this.$emit('input', this.localDate);
      this.open = false;
    },

    /**
     * Clear picker value.
     */
    clear() {
      this.localDate = null;
      this.$emit('input', this.localDate);
    },
  },
};
</script>
