<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text>
          <div class="pt-3">
            Loading...
            <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <span>Export Settings</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            v-if="dialog"
            class="pb-0"
        >
          <v-alert
              v-if="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          <v-row>
            <v-col
                sm="12"
            >
              <v-radio-group
                  v-model="settingsData.exportType"
                  row
              >
                <v-radio
                    label="Export To Emails"
                    value="email"
                ></v-radio>
                <v-radio
                    label="SFTP"
                    value="sftp"
                ></v-radio>
              </v-radio-group>
              <div v-if="settingsData.exportType === exportTypesEnum.EMAIL">
                <v-combobox
                    ref="emailsList"
                    v-model="settingsData.emails"
                    v-validate="{ 'check-emails': true }"
                    :error-messages="errors.collect('emails')"
                    name="emails"
                    hide-selected
                    label="Notification Emails"
                    placeholder="Add Emails"
                    multiple
                    small-chips
                    deletable-chips
                    append-icon=""
                    @change="emailsChanged"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        :key="data.item"
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        :color="validateEmail(data.item) ? 'gray' : 'primary'"
                        @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                      <v-icon
                          class="ml-2"
                          small
                          @click="data.parent.selectItem(data.item)"
                      >
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

              <div v-if="settingsData.exportType === exportTypesEnum.SFTP">
                <v-select
                    key="interval"
                    v-model="settingsData.interval"
                    v-validate="{ required: settingsData.exportType === exportTypesEnum.SFTP }"
                    :items="intervals"
                    :hint="settingsData.interval
                  ? 'Note: time is 0600 (PST) for all options. Query period: ' + getPeriod()
                  : null"
                    :error-messages="errors.collect('interval')"
                    persistent-hint
                    label="Export interval"
                    name="interval"
                />

                <v-text-field
                    key="ftpServer"
                    v-model="settingsData.ftpServer"
                    v-validate="{ required: settingsData.exportType === exportTypesEnum.SFTP }"
                    :error-messages="errors.collect('ftpServer')"
                    label="Server"
                    placeholder="server:port"
                    maxlength="255"
                    name="ftpServer"
                ></v-text-field>

                <v-text-field
                    key="ftpUser"
                    v-model="settingsData.ftpUser"
                    v-validate="{ required: settingsData.exportType === exportTypesEnum.SFTP }"
                    :error-messages="errors.collect('ftpUser')"
                    label="Username"
                    maxlength="100"
                    name="ftpUser"
                ></v-text-field>

                <v-text-field
                    key="ftpPassword"
                    v-model="settingsData.ftpPassword"
                    v-validate="{ required: settingsData.exportType === exportTypesEnum.SFTP }"
                    :error-messages="errors.collect('ftpPassword')"
                    label="Password"
                    maxlength="100"
                    name="ftpPassword"
                    type="password"
                ></v-text-field>

                <v-text-field
                    key="uploadDirectory"
                    v-model="settingsData.uploadDirectory"
                    label="Upload Directory"
                    maxlength="255"
                    name="uploadDirectory"
                ></v-text-field>
              </div>

            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="validateAndSave"
          >
            <template>Save</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import reconciliation from '@/api/reconciliation';
import EXPORT_INTERVALS from '@/enums/exportIntervals';
import RECONCILIATION_UPLOAD_TYPES from '@/enums/reconciliationUploadTypes';
import moment from 'moment';

export default {
  name: 'SettingsPopup',
  mixins: [errorAlertMixins],
  props: {
    settings: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      retryAfterChange: false,
      exportTypesEnum: RECONCILIATION_UPLOAD_TYPES,
      settingsDataBlank: {
        exportType: 'email',
        emails: [],
        interval: EXPORT_INTERVALS.DAILY,
        ftpServer: null,
        ftpUser: null,
        ftpPassword: null,
        uploadDirectory: null,
      },
      settingsData: {},
      saving: false,
      dictionary: {
        attributes: {
          interval: 'Export Interval',
          emails: 'Emails',
          ftpServer: 'Server',
          ftpUser: 'Username',
          ftpPassword: 'Password',
          uploadDirectory: 'Upload Directory',
        },
      },
      intervals: [
        { value: EXPORT_INTERVALS.DAILY, text: 'Every Day' },
        { value: EXPORT_INTERVALS.WEEKLY, text: 'Every Monday' },
        { value: EXPORT_INTERVALS.MONTHLY, text: 'First of Every Month' },
      ],
    };
  },

  created() {
    const that = this;
    this.$validator.extend('check-emails', {
      getMessage() {
        return 'Has an invalid email address';
      },
      validate(value) {
        let result = true;
        value.forEach(email => {
          if (!that.validateEmail(email)) {
            result = false;
          }
        });
        return result;
      },
    });
  },

  computed: {
    isClear() {
      return (this.settingsData.exportType === this.exportTypesEnum.EMAIL
              && (!this.settingsData.emails || !this.settingsData.emails.length)
      ) || (
        this.settingsData.exportType === this.exportTypesEnum.SFTP
              && !this.settingsData.ftpServer
              && !this.settingsData.ftpUser
              && !this.settingsData.ftpPassword
              && !this.settingsData.uploadDirectory
      );
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    emailsChanged() {
      this.normalizeEmails();
      if (this.retryAfterChange) {
        this.validateAndSave();
      }
    },

    normalizeEmails() {
      this.settingsData.emails.forEach((email, index) => {
        const emailTrimmed = email.trim();
        if (emailTrimmed) {
          if (emailTrimmed.indexOf(',') !== -1) {
            const emails = emailTrimmed.split(',');
            let counter = 0;
            emails.forEach((subEmail) => {
              const subEmailTrimmed = subEmail.trim();
              if (subEmailTrimmed) {
                counter++;
                if (counter === 1) {
                  this.settingsData.emails[index] = subEmailTrimmed;
                } else {
                  this.settingsData.emails.push(subEmailTrimmed);
                }
              }
            });
          } else {
            this.settingsData.emails[index] = emailTrimmed;
          }
        } else {
          this.settingsData.emails.slice(index, 1);
        }
      });
    },

    validateEmail(email) {
      if (email.length > 254) {
        return false;
      }
      return !!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    },

    /**
     * Show Export Settings dialog.
     */
    async show() {
      this.hideError();
      this.$validator.reset();
      this.settingsData = this.settings
        ? _.cloneDeep(this.settings)
        : _.cloneDeep(this.settingsDataBlank);
      this.dialog = true;
      this.loading = false;
    },

    /**
     * Validate form and save if is valid.
     */
    async validateAndSave() {
      if (this.settingsData.exportType === this.exportTypesEnum.EMAIL
          && this.$refs.emailsList.lazySearch
          && !this.retryAfterChange
      ) {
        this.retryAfterChange = true;
        return;
      }
      this.retryAfterChange = false;
      this.hideError();

      if (this.isClear) {
        this.saving = true;
        try {
          await reconciliation.deleteSettings();
          this.dialog = false;
          this.$emit('saved');
          return;
        } finally {
          this.saving = false;
        }
      }

      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.saving = true;
      try {
        const data = this.getDataToSave();
        await reconciliation.saveSettings(data);
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.saving = false;
      }
      this.$emit('saved');
    },

    /**
     * Prepare data to save.
     *
     * @return {Object}
     */
    getDataToSave() {
      const data = _.cloneDeep(this.settingsData);

      if (data.exportType === this.exportTypesEnum.EMAIL) {
        data.interval = null;
        data.ftpServer = null;
        data.ftpUser = null;
        data.ftpPassword = null;
        data.uploadDirectory = null;
      } else if (data.exportType === this.exportTypesEnum.SFTP) {
        data.emails = null;
      }
      return data;
    },

    /**
     * Get example of export period.
     *
     * @return {String}
     */
    getPeriod() {
      if (this.settingsData.interval === EXPORT_INTERVALS.DAILY) {
        return moment().format('MM/DD/YYYY');
      }
      if (this.settingsData.interval === EXPORT_INTERVALS.WEEKLY) {
        return `${moment().startOf('week').add(1, 'days').format('MM/DD/YYYY')} - ${
          moment().endOf('week').add(1, 'days').format('MM/DD/YYYY')}`;
      }
      if (this.settingsData.interval === EXPORT_INTERVALS.MONTHLY) {
        return `${moment().startOf('month').format('MM/DD/YYYY')} - ${
          moment().endOf('month').format('MM/DD/YYYY')}`;
      }
      return 'none';
    },
  },
};
</script>
