<template>
  <v-dialog
      v-model="showPopup"
      persistent
      max-width="600"
      @close="$emit('close')"
  >
    <v-card class="text-center">
      <v-container>
        <v-card-title class="headline pt-0">
          Still there?
        </v-card-title>
        <v-card-text class="pt-0">
          The system will now log you out due to inactivity.<br /><br />
          If you would like the session to remain open with no logout timeframe, you
          can enable alerts that will remove the session timeout limit.
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-checkbox
              v-model="dontShowAgain"
              hide-details
              class="ml-2 mt-0 mb-1 mr-5"
              label="Don't show again"
          />
          <v-spacer/>
          <v-btn
              color="primary"
              text
              :loading="logouting"
              @click.native="logout"
          >Got It</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ROUTES from '@/enums/routes';

export default {
  name: 'LogoutHospitalPopup',

  data() {
    return {
      logouting: false,
      dontShowAgain: false,
    };
  },

  computed: {
    /**
     * Show edit popup property.
     */
    showPopup() {
      return this.$store.state.showLogoutHospitalPopup;
    },
  },

  methods: {
    /**
     * Validate hospital values and save if it's ok.
     */
    async logout() {
      if (this.dontShowAgain) {
        localStorage.setItem('dontShowLogoutHospitalPopup', '1');
      }
      this.$router.push({ name: ROUTES.LOGIN });
      this.logouting = true;
    },
  },
};
</script>
