<template>
  <ul :class="[$style.components]">
    <li
      v-for="(component, componentName) in components"
      :key="componentName"
    >
      <h4 :class="$style.name">{{ componentName }}</h4>
      <release-notes-issues-list
        :issues="component.tasks"
        :show-task-link="showTaskLink"
      />
      <release-notes-issues-list
        :issues="component.bugs"
        :is-bugs="true"
        :show-task-link="showTaskLink"
      />
    </li>
  </ul>
</template>

<script>
import ReleaseNotesIssuesList from './ReleaseNotesIssuesList';

export default {
  name: 'ReleaseNotesComponents',

  components: {
    ReleaseNotesIssuesList,
  },

  props: {
    components: {
      type: Object,
      required: true,
    },
    showTaskLink: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.components {
  padding-bottom: 40px;
  list-style-type: none;
}

.name {
  margin-top: 25px;
}
</style>
