<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      class="device-edit"
      max-width="700"
      @close="$emit('close')"
    >
      <v-card>
        <v-container>
          <v-card-title class="headline pt-0">
            {{ isNew ? $t('devices.editPopup.headerNew') : $t('devices.editPopup.headerEdit') }}
          </v-card-title>
          <v-card-text class="pt-0">
            <div
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                color="grey darken"
                center
                indeterminate
              />
            </div>
            <div v-else>
              <v-alert
                v-model="errorAlert"
                type="error"
                outlined
                dismissible
                transition="scale-transition"
                class="mb-4"
              >
                <div v-html="errorMessage" />
              </v-alert>
              <div>
                <div v-if="!isNew">
                  <b>Profile Information:</b>
                  <div class="clearfix">
                    <b>DateInstalled:</b>
                    <span>{{ device.bundle_dateInstalled |datetimeToClientTimezone }}</span>
                  </div>
                  <div class="clearfix">
                    <b>DateofExpiration:</b>
                    <span>{{ device.bundle_dateOfExpiration |datetimeToClientTimezone }}</span>
                  </div>
                  <div class="clearfix">
                    <b>Bundle-identifier:</b> <span>{{ device.bundle_identifier }}</span>
                  </div>
                  <div class="clearfix">
                    <b>Title:</b> <span>{{ device.bundle_title }}</span>
                  </div>
                  <div class="mb-4"/>
                </div>

                <v-form>
                  <v-layout wrap>
                    <v-flex
                      v-if="showClientsSelect"
                      xs12
                    >
                      <v-autocomplete
                        v-model="device.clientId"
                        v-validate="{ required: showClientsSelect }"
                        :items="clients"
                        :label="$t('devices.editPopup.selectClient')"
                        :error-messages="errors.collect('clientId')"
                        item-value="registryId"
                        item-text="name"
                        name="clientId"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="device.UDID"
                        v-validate="{ required: true }"
                        :label="$t('devices.editPopup.udid')"
                        :error-messages="errors.collect('UDID')"
                        maxlength="40"
                        name="UDID"
                      />
                    </v-flex>
                    <v-flex
                      xs5
                    >
                      <v-text-field
                        v-model="device.dispatchId"
                        v-validate="{ required: true }"
                        :label="$t('devices.editPopup.dispatchId')"
                        :error-messages="errors.collect('dispatchId')"
                        maxlength="10"
                        name="dispatchId"
                      />
                    </v-flex>
                    <v-flex
                      v-if="!isNew"
                      xs6
                      offset-xs1
                    >
                      <v-text-field
                        v-model="device.inventoryNumber"
                        v-validate="{ required: true }"
                        :label="$t('devices.editPopup.inventoryNumber')"
                        :error-messages="errors.collect('inventoryNumber')"
                        maxlength="3"
                        name="inventoryNumber"
                      />
                    </v-flex>
                    <v-flex xs12/>
                    <v-flex xs5>
                      <v-select
                        v-model="device.unitType"
                        v-validate="{ required: true }"
                        :items="unitTypes"
                        :label="$t('devices.editPopup.unitType')"
                        :error-messages="errors.collect('unitType')"
                        name="unitType"
                      />
                    </v-flex>
                    <v-flex
                      v-if="showBlsAllowed && device.unitType === 'B'"
                      xs6
                      offset-xs1
                    >
                      <v-checkbox
                        v-model="device.BLSAllowed"
                        :label="$t('devices.editPopup.blsAllowed')"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                    >
                      <v-textarea
                        v-model="device.description"
                        :label="$t('devices.editPopup.description')"
                      />
                    </v-flex>

                  </v-layout>
                </v-form>
              </div>
          </div></v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              v-if="!isNew"
              :disabled="savingDevice"
              color="blue darken-1"
              text
              @click.native="$emit('clickDeactivate', device)"
            >{{ $t('buttons.deactivate') }}</v-btn>
            <v-btn
              v-if="!isNew"
              :disabled="savingDevice"
              color="blue darken-1"
              text
              @click.native="$emit('clickDelete', device)"
            >{{ $t('buttons.delete') }}</v-btn>
            <v-btn
              :disabled="savingDevice"
              color="blue darken-1"
              text
              @click.native="hidePopup"
            >{{ $t('buttons.cancel') }}</v-btn>
            <v-btn
              :loading="savingDevice"
              color="primary"
              text
              @click.native="validateAndSave"
            >{{ $t('buttons.save') }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import apiDevices from '@/api/devices';
import DEVICE_TYPES from '@/enums/deviceTypes';
import DEVICE_TYPE_NAMES from '@/enums/deviceTypeNames';

const { mapState } = createNamespacedHelpers('devices');

export default {
  name: 'DeviceEditPopup',

  mixins: [errorAlertMixins],

  props: {
    showBlsAllowed: {
      type: Boolean,
      default: false,
    },
    showClientsSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isNew: false,
      clientId: null,
      device: {},
      showEditPopup: false,
      loading: false,
      savingDevice: false,
      validatePasswordRegexp,
      dictionary: {
        attributes: {
          UDID: this.$t('devices.editPopup.udid'),
          dispatchId: this.$t('devices.editPopup.dispatchId'),
          unitType: this.$t('devices.editPopup.unitType'),
          inventoryNumber: this.$t('devices.editPopup.inventoryNumber'),
          clientId: this.$t('devices.editPopup.selectClient'),
        },
      },
      unitTypes: [
        {
          text: DEVICE_TYPE_NAMES.ALS,
          value: DEVICE_TYPES.ALS,
        },
        {
          text: DEVICE_TYPE_NAMES.BLS,
          value: DEVICE_TYPES.BLS,
        },
        {
          text: DEVICE_TYPE_NAMES.HELI,
          value: DEVICE_TYPES.HELI,
        },
      ],
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),

    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      return this.$store.state.clientsList;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Save or create device.
     *
     * @return {Promise}
     */
    saveDevice() {
      if (this.device.registryId) {
        return apiDevices.saveDevice(this.device);
      }
      if (!this.showClientsSelect) {
        this.device.clientId = this.$store.state.user.clientId;
      }
      return apiDevices.createDevice(this.device);
    },

    /**
     * Validate and save device.
     */
    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.savingDevice = true;
      try {
        await this.saveDevice();
        this.showEditPopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.savingDevice = false;
      }
    },

    /**
     * Show new edit popup.
     *
     * @param {Boolean} isNew - Flag determines device is new or edit existence
     * @param {Object} device - Device object
     */
    showPopup(isNew, device) {
      this.$validator.reset();
      this.errors.clear();
      this.hideError();
      this.isNew = isNew;
      this.device = device;
      this.showEditPopup = true;
    },

    /**
     * Hide edit device popup.
     */
    hidePopup() {
      this.showEditPopup = false;
    },

    /**
     * Show new device popup.
     *
     * @var {Object} params - Predefined params for new device.
     */
    showNew(params) {
      const blankParams = {
        clientId: null,
        UDID: null,
        dispatchId: null,
        unitType: DEVICE_TYPES.ALS,
        inventoryNumber: null,
        description: null,
        BLSAllowed: false,
      };

      const mergedParams = Object.assign(blankParams, params);
      this.showPopup(true, mergedParams);
    },

    /**
     * Show edit device popup.
     *
     * @param {Object} device - Device object.
     */
    showEdit(device) {
      this.showPopup(false, device);
    },
  },
};
</script>

<style lang="scss">
.clearfix {
  clear: both;
}
</style>
