import { fetchAdminLists } from '@/api/admin';
import { SET_ADMINS, SET_DIALOG, SET_EDITED_ITEM } from './mutations';

export const FETCH_DATA = 'FETCH_DATA';
export const UPDATE_DIALOG = 'UPDATE_DIALOG';
export const EDITED_ITEM = 'EDITED_ITEM';

export default {
  [FETCH_DATA]: ({ commit }) => fetchAdminLists().then(data => commit(SET_ADMINS, data)),
  [UPDATE_DIALOG]: ({ commit }, payload) => commit(SET_DIALOG, payload),
  [EDITED_ITEM]: ({ commit }, payload) => commit(SET_EDITED_ITEM, payload),
};
