<template>
  <v-text-field
    v-model="textValue"
    :label="label"
    :maxlength="maxlength"
    type="number"
    @keypress="validate"
  />
</template>
<script>
export default {
  name: 'NumValue',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    maxlength() {
      if (this.field.restrictions && this.field.restrictions.maxlen) {
        return this.field.restrictions.maxlen;
      }
      if (this.field.restrictions && this.field.restrictions.max) {
        return this.field.restrictions.max.toString().length;
      }
      return 255;
    },
  },
  methods: {
    validate($event) {
      if (this.textValue.toString().length === this.maxlength) {
        $event.preventDefault();
      }
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
</script>
