import ROUTES from '../../enums/routes';

// eslint-disable-next-line no-unused-vars
export default function getCeEntryClientNavigation(store) {
  return [
    {
      icon: 'mdi-home-outline',
      title: 'Home',
      route: ROUTES.CE_HOME,
    },
    {
      icon: 'people',
      title: 'Users',
      route: ROUTES.USERS,
    },
    {
      icon: 'mdi-card-account-details-outline',
      title: 'My License',
      route: ROUTES.CE_ENTRY,
      isAvailable() {
        return store.state.user && store.state.user.ceHoursEnabled;
      },
    },
    {
      icon: 'mdi-account-search-outline',
      title: 'Agency Overview',
      route: ROUTES.CE_ENTRY_AGENCY_OVERVIEW,
      isAvailable() {
        return store.state.user && store.state.user.ceHoursEnabled;
      },
    },
    {
      icon: 'mdi-account-search-outline',
      title: 'Skills Overview',
      route: ROUTES.CE_ENTRY_SKILLS_OVERVIEW,
      isAvailable() {
        return store.state.user && store.state.user.ceHoursEnabled;
      },
    },
    {
      icon: 'mdi-book-education-outline',
      title: 'CE Entry',
      route: ROUTES.CE_ENTRY_ENTRIES,
      isAvailable() {
        return store.state.user && store.state.user.ceHoursEnabled;
      },
    },
    {
      icon: 'settings',
      title: 'Settings',
      route: ROUTES.CE_SETTINGS,
      isAvailable() {
        return store.state.user && store.state.user.ceHoursEnabled;
      },
    },
  ];
}
