<template>
  <v-app
    id="inspire"
    :class="['white', isPrinting ? 'app-background' : 'gradient-background']"
  >
    <Navigation v-if="!initializing && aclInitialized"/>
    <Header v-if="!initializing && !isPrinting"></Header>
    <verify-contact-data-dialog v-if="$store.state.user"/>
    <logout-hospital-popup />

    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="snackbarColor"
      top
      tile
    >
      <span class="pt-3">{{ snackbarTitle }}</span>

      <v-btn
          icon
          x-small
          class="ml-5 float-right"
          @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-main>
      <v-container
        :fill-height="initializing"
        fluid
        class="main-container"
      >
        <v-layout
          v-if="initializing"
          align-center
          justify-center
        >
          <v-progress-circular
            :width="3"
            :size="100"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
        <slot v-if="!initializing"></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { SET_SNACKBAR } from '@/store/mutations';
import VerifyContactDataDialog from '@/components/Profile/VerifyContactDataDialog';
import LogoutHospitalPopup from '@/components/Login/LogoutHospitalPopup';
import Navigation from './Navigation';
import Header from './Header';

export default {
  name: 'Layout',
  components: {
    LogoutHospitalPopup,
    VerifyContactDataDialog,
    Navigation,
    Header,
  },
  computed: {
    snackbarTitle() {
      return this.$store.state.snackbarTitle;
    },

    isPrinting() {
      return this.$store.state.isPrinting;
    },

    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set(value) {
        this.$store.commit(SET_SNACKBAR, value);
      },
    },
    snackbarColor() {
      return this.$store.state.snackbarColor;
    },

    initializing() {
      return (
        this.$store.state.initializingUser
        || this.$store.state.initializingSiteInfo
        || this.$store.state.initializing
      );
    },
    aclInitialized() {
      return this.$store.state.aclInitialized;
    },
  },
};
</script>

<style lang="scss">
.theme--light.application.grey-background {
  background-color: $main-background !important;
}

.app-background .v-application--wrap {
  background-color: #f1f1f1;
}

.gradient-background .v-application--wrap {
  background: url('~@/assets/img/fon_main.jpg') no-repeat scroll center 0 #edccb6;
}

.main-container {
  overflow-y: auto;
  height: calc(100vh - 48px);
  padding: 18px 24px;
}
</style>
