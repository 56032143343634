<template>
  <div>
    <v-dialog
      v-model="showUnfinishedForms"
      persistent
      max-width="1200"
      @close="$emit('close')"
    >
      <v-card>
        <v-container>
          <v-card-title class="headline pt-0">
            {{ $t('devices.unfinishedFormsPopup.header') }}
          </v-card-title>
          <v-card-text class="pt-0">
            <div
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                color="grey darken"
                center
                indeterminate
              />
            </div>
            <div v-else>
              <b>Total number of reports left on device:</b>
              <span>{{ device.unfinishedReportsData.length }}</span>
              <div class="clearfix" />
              <b>Date/time of update: </b>
              <span>{{ device.lastUnfinishedReportsDataUpdate | datetimeToClientTimezone }}</span>
              <div class="clearfix" />
              <v-data-table
                :headers="headers"
                :items="device.unfinishedReportsData"
                :options.sync="pagination"
                :footer-props="{
                  itemsPerPageOptions: [10,25,50]
                }"
              >
                <template v-slot:body="{ items }">
                  <NoDataAvailable
                    :headers="headers"
                    :items="items"
                  />
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.Number"
                    >
                      <td>{{ item.Number }}</td>
                      <td>{{ item.SequenceNumber }}</td>
                      <td>{{ item.IncidentDate | date }}</td>
                      <td>{{ item.PatientLastName }}</td>
                      <td>{{ item.PatientAge }}</td>
                      <td>{{ item.DateLastModified | date }}</td>
                      <td>
                        <div
                          v-if="item.TM_Numbers"
                          :title="prepareTmNumbersTitle(item.TM_Numbers)"
                          class="tm-numbers"
                        >
                          {{ prepareTmNumbersStr(item.TM_Numbers || {}) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              color="blue darken-1"
              text
              @click.native="hidePopup()"
            >{{ $t('buttons.close') }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import devicesApi from '@/api/devices';
import NoDataAvailable from '../NoDataAvailable';

const { mapState } = createNamespacedHelpers('devices');

export default {
  name: 'DeviceUnfinishedForms',

  components: {
    NoDataAvailable,
  },

  mixins: [errorAlertMixins],

  data() {
    return {
      device: {
        unfinishedReportsData: [],
        lastUnfinishedReportsDataUpdate: null,
      },
      teamMembers: [],
      showUnfinishedForms: false,
      loading: false,
      pagination: {
        sortDesc: [false],
        page: 1,
        sortBy: ['Number'],
        itemsPerPage: 10,
      },
      headers: [
        {
          text: '#',
          value: 'Number',
        },
        {
          text: this.$t(
            'devices.unfinishedFormsPopup.tableHeaders.sequenceNumber',
          ),
          value: 'SequenceNumber',
        },
        {
          text: this.$t(
            'devices.unfinishedFormsPopup.tableHeaders.incidentDate',
          ),
          value: 'IncidentDate',
        },
        {
          text: this.$t('devices.unfinishedFormsPopup.tableHeaders.lastName'),
          value: 'PatientLastName',
        },
        {
          text: this.$t('devices.unfinishedFormsPopup.tableHeaders.age'),
          value: 'PatientAge',
        },
        {
          text: this.$t(
            'devices.unfinishedFormsPopup.tableHeaders.lastModified',
          ),
          value: 'DateLastModified',
        },
        {
          text: this.$t('devices.unfinishedFormsPopup.tableHeaders.tms'),
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),

    /**
     * Generate team members map by EMT field.
     *
     * @return object
     */
    teamMembersNames() {
      const byNames = {};
      this.teamMembers.forEach(user => {
        byNames[user.EMT] = `${user.firstName} ${user.lastname}`;
      });
      return byNames;
    },
  },

  methods: {
    /**
     * Prepare title of dif for Tm members.
     *
     * @param {Object} tmNumbers - List of tm numbers
     *
     * @return string
     */
    prepareTmNumbersTitle(tmNumbers) {
      const numbers = [];
      _.forEach(tmNumbers, (tmnumber, key) => {
        const numStr = `#${this.getNumberFromTmNumberKey(key)}:`;
        const nameStr = this.getNameByEmtNumber(tmnumber);

        numbers.push(`${numStr} ${nameStr}`);
      });
      return numbers.join('\n');
    },

    /**
     * Prepare cut value of tmNumbers.
     *
     * @param {Object} tmNumbers - List of tm numbers
     *
     * @return string
     */
    prepareTmNumbersStr(tmNumbers) {
      const firstKey = Object.keys(tmNumbers)[0];
      const countTmNumbers = Object.keys(tmNumbers).length;
      if (!firstKey) {
        return '';
      }

      const numStr = `#${this.getNumberFromTmNumberKey(firstKey)}:`;
      const nameStr = this.getNameByEmtNumber(tmNumbers[firstKey]);
      const countStr = countTmNumbers - 1 ? `+${countTmNumbers - 1}` : '';

      return `${numStr} ${nameStr} ${countStr}`;
    },

    /**
     * Get num from tm Numbers key.
     *
     * @param {string} key - Key of tm Number
     */
    getNumberFromTmNumberKey(key) {
      return key.replace(/TM_TeamMemberID/gi, '');
    },

    /**
     * Get name of team member.
     *
     * @param {string} emtNumber - EMT number
     */
    getNameByEmtNumber(emtNumber) {
      return emtNumber in this.teamMembersNames
        ? this.teamMembersNames[emtNumber]
        : emtNumber;
    },

    /**
     * Show unfinished forms popup.
     *
     * @param {Object} device - Device object
     */
    async showPopup(device) {
      this.loading = true;
      this.showUnfinishedForms = true;
      this.teamMembers = await devicesApi.getTeamMembers(device.registryId);
      this.device = device;
      this.loading = false;
    },

    /**
     * Hide unfinished forms popup.
     */
    hidePopup() {
      this.showUnfinishedForms = false;
    },
  },
};
</script>

<style lang="scss">
.clearfix {
  clear: both;
}

.tm-numbers {
  white-space: nowrap;
  border-bottom: 1px dashed #505050;
  overflow: hidden;
  text-overflow: ellipsis;
  width: min-content;
  max-width: 150px;
  cursor: pointer;
}
</style>
