import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    providers: [],
    nemsisAgencies: [],
    clients: [],
    templates: [],
    templatesSortBy: 'name',
    filters: {
      status: 'active',
    },
  },
  actions,
  mutations,
  getters: {
    sortedProviders: state => {
      const providersCopy = state.providers.slice();

      return providersCopy
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(provider => ({
          value: provider.code,
          text: `${provider.name} (${provider.code})`,
        }));
    },
    nemsisAgencyItems: state => {
      const agenciesCopy = state.nemsisAgencies.slice();
      return agenciesCopy
        .map(agency => ({
          value: agency.code,
          text: `${agency.name} (${agency.code})`,
        }));
    },
  },
};
