<template>
  <v-dialog
    v-model="showEditPopup"
    persistent
    max-width="478"
  >
    <v-card :class="$style.card">
      <v-form @submit.prevent="saveRestriction">
        <v-card-title class="headline pt-0">
          {{ $t('profileSettings.security.header') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div :class="$style.column">
            <v-text-field
              v-model="name"
              v-validate="{ required: true }"
              name="name"
              single-line
              :label="$t('profileSettings.security.name')"
              :error-messages="errors.collect('name')"
            />
            <v-text-field
              v-model="net"
              v-validate="{ required: true }"
              name="net"
              single-line
              :label="$t('profileSettings.security.ipAddress')"
              :error-messages="errors.collect('net')"
            />
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            type="submit"
            color="primary"
            text
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import allowedAccessNets from '@/api/allowedAccessNets';
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'EditSecurityPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      /**
       * Flag for show/hide current popup.
       */
      showEditPopup: false,
      /**
       * Name of the net.
       */
      name: '',
      /**
       * Net ip address.
       */
      net: '',
      /**
       * Net id.
       */
      netId: null,
      /**
       * Is popup for create or update net.
       */
      isNew: false,
    };
  },
  methods: {
    /**
     * Initialize and open popup.
     * @param item
     * @returns {Promise<void>}
     */
    async showPopup(item) {
      this.showEditPopup = true;
      this.$validator.reset();
      this.isNew = item == null;
      if (this.isNew) {
        this.name = '';
        this.net = '';
        this.netId = null;
      } else {
        this.name = item.name;
        this.net = item.net;
        this.netId = item.netId;
      }
    },
    /**
     * Save net restriction to API.
     * @returns {Promise<void>}
     */
    async saveRestriction() {
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      const params = { net: this.net, name: this.name };

      try {
        if (this.netId === null) {
          await allowedAccessNets.createAccessRestriction(params);
        } else {
          params.netId = this.netId;
          await allowedAccessNets.updateAccessRestriction(params);
        }

        this.showEditPopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      }
    },
  },
};
</script>

<style lang="scss" module>
.card {
  padding: 14px;
}

.column {
  display: flex;
  flex-direction: column;
}
</style>
