<template>
  <div class="templates-table">
    <v-data-table
      :headers="$t('templates.tableHeaders')"
      :items="templates"
      :loading="loading"
      :options.sync="pagination"
      :server-items-length="paginationInfo.total"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :height="tableHeight"
      fixed-header
    >
      <template v-slot:body="{ items }">
        <tbody>
          <v-hover
            v-for="item in items"
            :key="item.name"
          >
            <tr
              slot-scope="{ hover }"
              :class="item.registry.deleted ? $style.deleted : null"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.releaseDate | formatDate }}</td>
              <td><div
                v-if="item.lastFile"
                :class="$style.nowrap"
              >{{ item.lastFile.createdAt | formatDateTime }}</div></td>
              <td>
                <div :class="$style.nowrap">
                  {{ item.renderTemplate
                    ? item.renderTemplate.name
                    : $t('templates.defaultTemplate')
                  }}
                </div>
              </td>
              <td class='description-word-break'>{{ item.description }}</td>
              <td>
                <span
                  v-for="(item, index) in item.clients"
                  :key="index"
                  :class="isEven(index) ? $style.bold : null"
                >
                  {{ item.name }}
                  <span v-if="item.clients && !isLastElement(index, item.clients)">, </span>
                </span>
              </td>
              <td :class="[$style.actions, 'text-right', 'no-wrap']">
                <v-icon
                  v-show="hover"
                  color="grey darken-2"
                  class="mr-3"
                  @click="editTemplate(item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="!item.registry.deleted"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showDelete(item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-else
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showRestore(item)"
                >
                  restore
                </v-icon>
              </td>
            </tr>
          </v-hover>
        </tbody>
      </template>
    </v-data-table>
    <confirmation
      ref="confirmationPopup"
      @confirmed="fetchData()"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { FETCH_DATA, OPEN_TEMPLATE_EDIT } from '@/store/Templates/actions';
import { SET_TEMPLATES_PAGINATION } from '@/store/Templates/mutations';
import Confirmation from '@/components/Confirmation';
import apiTemplates from '@/api/templates';
import i18n from '@/lang/i18n';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'TemplatesList',
  components: {
    Confirmation,
  },
  filters: {
    /**
     * Fomat date time.
     *
     * @param {string} date - Datetime for format.
     */
    formatDate(date) {
      return date ? `${moment.parseZone(date).format('MM/DD/YYYY')}` : 'None';
    },
    /**
     * Fomat date time.
     *
     * @param {string} date - Datetime for format.
     */
    formatDateTime(date) {
      return date
        ? `${moment.parseZone(date).format('MM/DD/YYYY h:mm A')}`
        : 'None';
    },
  },
  mixins: [fixedHeader],
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('templates', ['templates', 'paginationInfo', 'search']),
    pagination: {
      get() {
        return this.$store.state.templates.pagination;
      },
      set(value) {
        this.$store.commit(`templates/${SET_TEMPLATES_PAGINATION}`, value);
      },
    },
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.updateTableDebounce();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchData: `templates/${FETCH_DATA}`,
      editTemplate: `templates/${OPEN_TEMPLATE_EDIT}`,
    }),

    /**
     * Debounced search hospitals by name.
     */
    /* eslint func-names:0 */
    updateTableDebounce: _.debounce(async function () {
      this.loading = true;
      await this.fetchData();
      this.loading = false;
    }, 500),

    /**
     * Check value is even.
     *
     * @param {number} value - Value for check.
     */
    isEven(value) {
      const n = Number(value);

      return n === 0 || !!(n && !(n % 2));
    },

    /**
     * Check if index is last in array.
     *
     * @param {number} index - Index in list.
     * @param {Array} list - List of values.
     */
    isLastElement(index, list) {
      return index === Object.keys(list).length - 1;
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} template - Template object.
     */
    showDelete(template) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('templates.deleteConfirmationTitle'),
        i18n.t('templates.deleteConfirmationText', template),
        async () => {
          await apiTemplates.deleteTemplate(template.templateId);
        },
      );
    },
    /**
     * Show restore confirmation popup.
     *
     * @param {Object} template - Template object.
     */
    showRestore(template) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('templates.restoreConfirmationTitle'),
        i18n.t('templates.restoreConfirmationText', template),
        async () => {
          await apiTemplates.restoreTemplate(template.templateId);
        },
      );
    },
  },
};
</script>
<style lang="scss" module>
.nowrap {
  white-space: nowrap;
}

.deleted {
  background-color: $red-light;
}

.actions {
  width: 20%;
}

.bold {
  font-weight: bold;
}
</style>

<style>
.templates-table td,
.templates-table th {
  padding: 0 5px !important;
}

.templates-table tr th:first-child,
.templates-table td:first-child {
  padding-left: 20px !important;
}
.description-word-break {
  word-break: break-all;
}
</style>
