<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span>Edit User License</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
          v-if="dialog"
          class="pb-0"
      >
        <v-alert
            v-if="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
            class="mt-2"
        >
          {{ errorMessage }}
        </v-alert>

        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model="userData.nationalLicense"
              v-validate="{ required: false}"
              label="National License"
              name="nationalLicense"
              maxlength="255"
              :error-messages="errors.collect('nationalLicense')"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
                v-model="userData.stateLicense"
                v-validate="{ required: false}"
                label="State License"
                name="stateLicense"
                maxlength="255"
                :error-messages="errors.collect('stateLicense')"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
                v-model="userData.localLicense"
                v-validate="{ required: false}"
                label="Local License"
                name="localLicense"
                maxlength="255"
                :error-messages="errors.collect('localLicense')"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
                v-model="userData.licenseTypeId"
                v-validate="{ required: true }"
                :items="userLicenseTypes"
                :error-messages="errors.collect('licenseTypeId')"
                label="License Type"
                name="licenseTypeId"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            color="blue darken-1"
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            :loading="saving"
            @click="validateAndSave"
        >
          <template>Save</template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import users from '@/api/users';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import ceHours from '@/api/ceHours';

export default {
  name: 'EditUserLicensePopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      saving: false,
      licenseTypes: [],
      userId: null,
      userData: {
        nationalLicense: null,
        stateLicense: null,
        localLicense: null,
        licenseTypeId: null,
      },
      dictionary: {
        attributes: {
          licenseTypeId: 'License Type',
        },
      },
    };
  },
  computed: {
    userLicenseTypes() {
      const userLicenseTypes = [];

      this.licenseTypes.forEach((item) => {
        userLicenseTypes.push({
          text: item.name,
          value: item.licenseTypeId,
        });
      });

      return userLicenseTypes;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Show dialog.
     */
    async show(userData) {
      this.dialog = true;
      await this.loadLicenseTypes();
      this.userId = userData.id;
      this.userData.nationalLicense = userData.nationalLicense;
      this.userData.stateLicense = userData.stateLicense;
      this.userData.localLicense = userData.localLicense;
      this.userData.licenseTypeId = userData.licenseTypeId;
    },

    async loadLicenseTypes() {
      this.loading = true;
      try {
        this.licenseTypes = await users.getLicenseTypes();
      } finally {
        this.loading = false;
      }
    },

    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        await ceHours.updateUserLicenses(this.userId, this.userData);
        this.dialog = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
