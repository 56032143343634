export default [
  { text: 'January', value: 1, disabled: false },
  { text: 'February', value: 2, disabled: false },
  { text: 'March', value: 3, disabled: false },
  { text: 'April', value: 4, disabled: false },
  { text: 'May', value: 5, disabled: false },
  { text: 'June', value: 6, disabled: false },
  { text: 'July', value: 7, disabled: false },
  { text: 'August', value: 8, disabled: false },
  { text: 'September', value: 9, disabled: false },
  { text: 'October', value: 10, disabled: false },
  { text: 'November', value: 11, disabled: false },
  { text: 'December', value: 12, disabled: false },
];
