import api from './api';

export default {
  /**
   * Export long beach billing files.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  exportAll(params) {
    return api()
      .post('/long-beach-billing/export', params);
  },
};
