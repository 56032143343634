<template>
  <view-resolver>
    <template v-slot:adminView>
      <dashboard-admin />
    </template>
    <template v-slot:clientView>
      <dashboard-client />
    </template>
  </view-resolver>
</template>
<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import DashboardAdmin from './DashboardAdmin';
import DashboardClient from './DashboardClient';

export default {
  components: {
    ViewResolver,
    DashboardAdmin,
    DashboardClient,
  },

  mounted() {
    this.$store.commit(SET_TITLE, 'Dashboard');
  },
};
</script>
