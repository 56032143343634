<template>
  <v-card
    :class="$style['utility-button']"
    color="#e04337"
    dark
    height="200"
    @click="handleClick()"
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        {{ icon }}
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      {{ description }}
    </v-card-text>

    <v-card-actions/>
  </v-card>
</template>

<script>
export default {
  name: 'UtilityButton',

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    /**
     * Handle click by button.
     */
    handleClick() {
      if (this.route) {
        this.$router.push({ name: this.route });
      } else if (this.link) {
        window.open(this.link, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" module>
.utility-button {
  cursor: pointer;
}
</style>
