<template>
  <span>Redirecting... Please wait.</span>
</template>

<script>
import impersonateMixins from '@/mixins/impersonateMixins';

export default {
  mixins: [impersonateMixins],

  created() {
    const currentUrl = new URL(window.location.href);
    const clientId = currentUrl.searchParams.get('clientId');
    const incidentId = currentUrl.searchParams.get('incidentId');

    if (incidentId) {
      this.impersonateToIncident(clientId, incidentId, true);
    }
  },
};
</script>
