<template>
  <v-list-group
      :value="item.expanded"
      :class="[!miniVariant ? 'nested-item' : 'nested-item-mini']"
      prepend-icon=""
      :sub-group="!miniVariant"
  >
      <template v-slot:activator>
        <v-tooltip
            :disabled="!miniVariant"
            right
        >
          <template v-slot:activator="{ on }">
            <v-list-item
                :to="item.route ? { name: item.route } : undefined"
                :ripple="false"
                class="nested-grouped-item"
                v-on="on"
            >
              <v-list-item-icon>
                <v-icon v-html="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>

      <template v-slot:appendIcon>
        <v-icon>mdi-chevron-down</v-icon>
      </template>

    <v-divider
        v-if="divided"
    />

    <template v-for="(nestedItem, index) in item.items">
      <grouped-item
        v-if="nestedItem.grouped"
        v-bind:key="'group_item_' + index"
        :mini-variant="miniVariant"
        :item="nestedItem"
        :divided="true"
      />
      <navigation-item
          v-if="!nestedItem.grouped"
          v-bind:key="'group_item_' + index"
          :item="nestedItem"
          :tooltip-disabled="!miniVariant"
          :exact="false"
      />
    </template>
    <v-divider
      v-if="divided"
    />
  </v-list-group>
</template>

<script>
import NavigationItem from '@/components/Navigation/NavigationItem';

export default {
  name: 'GroupedItem',
  components: { NavigationItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    miniVariant: {
      type: Boolean,
      required: true,
    },
    divided: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>

</style>
