<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
    >
      <v-card>
        <v-card-title>
          Edit Invoice Header
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
              v-model="header.company"
              v-validate="{ required: true }"
              :error-messages="errors.collect('company')"
              name="company"
              maxlength="255"
              label="Company"
          />
          <v-text-field
              v-model="header.address"
              v-validate="{ required: true }"
              :error-messages="errors.collect('address')"
              name="address"
              maxlength="255"
              label="Address"
          />
          <v-text-field
              v-model="header.phone"
              v-validate="{ required: true }"
              :error-messages="errors.collect('phone')"
              name="phone"
              maxlength="255"
              label="Phone"
          />
          <v-text-field
              v-model="header.url"
              v-validate="{ required: true }"
              :error-messages="errors.collect('url')"
              name="url"
              maxlength="255"
              label="URL"
          />
          <v-text-field
              v-model="header.email"
              v-validate="{ required: true }"
              :error-messages="errors.collect('email')"
              name="email"
              maxlength="255"
              label="Email"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="validateAndSend"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import invoices from '@/api/invoices';

export default {
  name: 'EditHeaderPopup',
  mixins: [errorAlertMixins],
  props: {
    headerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      header: {},
      dictionary: {
        attributes: {
          name: 'Company',
          address: 'Address',
          phone: 'Phone',
          url: 'URL',
          email: 'Email',
        },
      },
    };
  },

  methods: {
    async show() {
      this.hideError();
      this.$validator.reset();
      this.header = _.cloneDeep(this.headerData);
      this.dialog = true;
    },

    async validateAndSend() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        await invoices.saveHeader(this.header);
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
      this.$emit('update:headerData', this.header);
      this.dialog = false;
    },
  },
};
</script>
