<template>
  <v-navigation-drawer
    v-if="!isPrinting"
    v-model="drawer"
    :mini-variant="miniVariant"
    mini-variant-width="66"
    disable-resize-watcher
    mobile-breakpoint="230"
    width="275"
    fixed
    persistent
    class="grey-menu navigation-menu"
    app
  >
    <v-subheader :class="[$style.header, 'justify-center']">
      <div
        v-if="!miniVariant"
        :class="{ [$style.adminLogo]: !isClientSite }"
      >
        <v-img
          v-if="logoSrc && isClientSite"
          :class="$style.clientLogo"
          :src="logoSrc"
        ></v-img>
        <v-img
          v-else-if="!isClientSite"
          :src="require('@/assets/img/MobileMedic_logo1_blackred.png')"
        ></v-img>
        <div
          v-if="isClientSite"
          :class="[$style.clinetNameDefault, {[$style.clientName]: !logoSrc}]"
        >
          {{ siteInfo.roleDescription }}
        </div>
      </div>
      <v-btn
        icon
        :class="[$style.menuBtn, {[$style.miniVariantBtn]: miniVariant}]"
        @click.stop="miniVariant = !miniVariant"
      >
        <v-icon v-html="miniVariant ? 'menu' : 'menu_open'"/>
      </v-btn>
    </v-subheader>

    <v-divider></v-divider>

    <v-list
        subheader
        dense
        expand
    >
      <template v-for="(item,i) in menuItems">
        <v-divider
            v-if="item.divided"
            :key="'divider_' + i"
        />
        <grouped-item
          v-if="item.grouped"
          :key="'group_' + i"
          :item="item"
          :mini-variant="miniVariant"
        />
        <navigation-item
            v-if="!item.grouped"
            :key="'nav_item_' + i"
            :item="item"
            :tooltip-disabled="!miniVariant"
            :exact="navItemExactUrl"
        />
      </template>
    </v-list>
    <div :class="[$style.logoDivider]"></div>
    <v-img
      v-if="!miniVariant"
      :class="$style.greyLogo"
      :src="require('@/assets/img/MobileMedic-Logo-Grey.svg')"
    />
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import getNavigation from '@/data/Navigation';
import getCeEntryClientNavigation from '@/data/Navigation/ceEntryClient';
import { SET_DRAWER } from '@/store/mutations';
import navigationCheckAccessMixins from '@/mixins/navigationCheckAccessMixins';
import NavigationItem from '@/components/Navigation/NavigationItem';
import GroupedItem from '@/components/Navigation/GroupedItem';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

export default {
  name: 'Navigation',
  components: { GroupedItem, NavigationItem },
  mixins: [navigationCheckAccessMixins],
  data() {
    return {
      miniVariant: false,
      menuItems: [],
    };
  },
  computed: {
    ...mapState(['user', 'siteInfo']),
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    isClientSite() {
      return this.siteInfo && this.siteInfo.role === 'client';
    },
    logoSrc() {
      return this.isClientSite ? this.siteInfo.logo : null;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit(SET_DRAWER, value);
      },
    },
    currentRouteName() {
      return this.$route.name;
    },
    navItemExactUrl() {
      return this.$store.state.siteInfo.clientSiteType === CLIENT_SITE_TYPES.CE_ENTRY;
    },
  },
  watch: {
    siteInfo() {
      this.menuItems = this.buildMenuItems();
    },
    currentRouteName() {
      if (!this.menuItems.length && this.currentRouteName) {
        this.menuItems = this.buildMenuItems();
      }
    },
  },
  methods: {
    buildMenuItems() {
      if (!this.siteInfo) {
        return [];
      }
      const items = [];
      let navigation = [];
      if (this.$store.state.siteInfo.clientSiteType === CLIENT_SITE_TYPES.CE_ENTRY) {
        navigation = getCeEntryClientNavigation(this.$store);
      } else {
        navigation = getNavigation(this.$store);
      }

      navigation.forEach(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'items')) {
          const groupedItem = this.getGroupedItem(item);
          if (groupedItem) {
            items.push(groupedItem);
          }
        } else if (this.haveAccessAndAvailable(item)) {
          items.push(item);
        }
      });

      return items;
    },

    getGroupedItem(item) {
      const groupedItem = _.cloneDeep(item);
      let hasActiveItem = false;
      groupedItem.grouped = true;
      groupedItem.expanded = false;
      groupedItem.items = [];
      if (Object.prototype.hasOwnProperty.call(item, 'route')) {
        if (!this.haveAccessAndAvailable(item)) {
          groupedItem.route = null;
        }
      }
      item.items.forEach(nestedItem => {
        if (Object.prototype.hasOwnProperty.call(nestedItem, 'items')) {
          const subItems = this.getGroupedItem(nestedItem);
          if (subItems) {
            if (subItems.expanded) {
              hasActiveItem = true;
            }
            groupedItem.items.push(subItems);
          }
        } else if (this.haveAccessAndAvailable(nestedItem)) {
          if (this.currentRouteName === nestedItem.route) {
            hasActiveItem = true;
          }
          groupedItem.items.push(nestedItem);
        }
      });
      if (hasActiveItem) {
        groupedItem.expanded = true;
      }
      return groupedItem.items.length ? groupedItem : null;
    },

    /**
     * Check access and available to navigation item.
     *
     * @param {Object} item - Navigation item.
     *
     * @return {Boolean}
     */
    haveAccessAndAvailable(item) {
      const hasIsAvailable = Object.prototype.hasOwnProperty.call(item, 'isAvailable');
      return this.haveAccess(item) && (!hasIsAvailable || (hasIsAvailable && item.isAvailable()));
    },
  },
};
</script>

<style lang="scss" module>
.header {
  text-align: center;
  justify-content: center;
  overflow: hidden;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: auto !important;
  min-height: 48px;
  margin: 0;
  padding: 0 16px;
  position: relative;
  align-items: flex-start !important;
  flex-shrink: 0;
}

.logoDivider {
  flex-grow: 1;
}

.header .adminLogo {
  max-width: 150px;
  margin: auto 46px auto 0;
  display: flex;
  flex-direction: column;
  color: #000000;
}

.header .clientLogo {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  color: #000000;
  max-height: 200px;
}

.header .clientName {
  margin: 14px 20px;
}

.clinetNameDefault {
  font-size: 13px;
}

.greyLogo {
  width: 184px;
  margin: 10px auto 26px;
  flex: none !important;
}

.menuBtn {
  width: 36px;
  flex: none;
  position: fixed !important;
  top: 6px;
  right: 7px;
}

.miniVariantBtn {
  right: 15px;
}

.groupItem {
  padding: 0 !important;
}

.groupItem:hover::before {
  background: transparent !important;
}

div[class="v-navigation-drawer__content"] {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.nested-item {
  .v-list-group__header {
    padding-left: 0px !important;
    .v-list-item__icon:first-child {
      margin-right: 15px !important;
    }
  }
}
.nested-item-mini {
  .v-list-group__header {
    padding: 0px;
  }
  .nested-grouped-item {
    padding-left: 4px;
  }
}
</style>
