import api from './api';

export default {
  /**
   * Fetch Duplicated Incident data.
   *
   * @param {string} incidentId - Identifier of duplicated incident
   *
   * @return Promise
   */
  fetchIncidentData(incidentId) {
    return api()
      .get(`duplicated-incidents/${incidentId}`)
      .then(response => response.data);
  },

  /**
   * Fetch list of duplicate incidents.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise
   */
  fetchList(params) {
    return api()
      .get('duplicated-incidents', { params })
      .then(response => response.data);
  },

  /**
   * Overwrite original incident to duplicate.
   *
   * @param {string} incidentId - Identifier of duplicated incident
   *
   * @return Promise
   */
  overwrite(incidentId) {
    return api().post(`duplicated-incidents/${incidentId}/overwrite`);
  },

  /**
   * Keep original and remove duplicate.
   *
   * @param {string} incidentId - Identifier of duplicated incident
   *
   * @return Promise
   */
  keepOriginal(incidentId) {
    return api().post(`duplicated-incidents/${incidentId}/keep-original`);
  },

  /**
   * Commit duplicated incident to new sequence number.
   *
   * @param {string} incidentId - Identifier of duplicated incident
   * @param {string} sequenceNumber - New sequence number
   *
   * @return Promise
   */
  commit(incidentId, sequenceNumber) {
    return api().post(`duplicated-incidents/${incidentId}/commit`, {
      sequenceNumber,
    });
  },

  /**
   * Get duplicate incident json file.
   *
   * @param {string} incidentId - Identifier of duplicate incident
   *
   * @return Promise
   */
  fetchJson(incidentId) {
    return api()
      .get(`duplicated-incidents/${incidentId}/json`, { responseType: 'blob' })
      .then(response => response);
  },
};
