import defaultState from './defaultState';

export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_CLIENTS = 'SET_CLIENTS';

export default {
  /**
     * Clear state.
     *
     * @param {Object} state - Entity state.
     */
  [CLEAR_STATE]: (state) => {
    Object.assign(state, defaultState());
  },

  /**
     * Save list of transmitted reports
     *
     * @param {Object} state - Entity state.
     * @param {{ items: Array, totalItems: number }} data - Entity data to set
     */
  [SET_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.total = totalItems;
  },

  /**
   * Save list of clients.
   *
   * @param {Object} state - Entity state.
   * @param {Array} items - Entity data to set
   */
  [SET_CLIENTS]: (state, items) => {
    state.clients = items;
  },

  /**
     * Save entity pagination.
     *
     * @param {Object} state - Entities state.
     * @param {Object} pagination - Entities pagination.
     */
  [SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
     * Save entity pagination.
     *
     * @param {Object} state - Entities state.
     * @param {Object} filters - Filtering data.
     */
  [SET_FILTERS]: (state, filters) => {
    state.filters = filters;
  },
};
