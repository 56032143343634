<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-toolbar flat>
      <h2>{{ title }}</h2>
      <v-spacer />
      <v-radio-group
          v-model="mode"
          row
      >
        <v-radio
            label="90th Percentile"
            value="percentile"
        ></v-radio>
        <v-radio
            label="Average Time"
            value="avg"
        ></v-radio>
      </v-radio-group>

      <v-checkbox
          v-model="showStatewideGoal"
          label="Statewide Goal"
      ></v-checkbox>
    </v-toolbar>

    <v-card-text class="text-center">
      APOT-1 identifies the number of 911 transports to an emergency
      department and the {{ mode === 'percentile' ? '90th percentile' : 'average' }} time.
      This approach allows the opportunity to compare APOT times across multiple months
      and other periods. Also, the graph includes the count of
      hospitals represented.

      <span v-if="mode == 'percentile'">
        For APOT-1, the weighted 90th percentile is used so all transports hold the same value.
        This approach allows the opportunity to compare APOT times within a jurisdiction across
        multiple months and/or quarters.
      </span>
      <span v-if="metricType === metrics.APOT_1_BY_UNITS">
        Only ONE “transporting” unit (with “OnFacilityEquipmentDateTime”) is taken from each
        report where a patient was transported.
      </span>

    </v-card-text>

    <vue-apex-charts
        v-if="!loading && series[0].data.length"
        type="bar"
        :height="chartHeight"
        :options="options"
        :series="series || []"
    />
    <div
        v-if="!loading && !series[0].data.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';
import convertMinutesToTime from '@/utils/convertMinutesToTime';
import METRICS from '@/enums/metrics';
import dateRangeFormat from './dateRangeFormat';

export default {
  name: 'ApotFirst',
  components: {
    VueApexCharts,
  },
  mixins: [dateRangeFormat],
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    dateRange: {
      type: Array,
      required: false,
      default: null,
    },
    metricType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      mode: 'percentile',
      showStatewideGoal: true,
      metrics: METRICS,
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            tools: {
              download: true,
            },
            export: {
              svg: {
                filename: 'apot_1',
              },
              png: {
                filename: 'apot_1',
              },
            },
          },
        },
        title: {
          text: '',
          align: 'center',
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => convertMinutesToTime(val),
          },
          x: {
            show: true,
            formatter: (val, opts) => {
              const item = this.data[opts.dataPointIndex];
              return [val,
                '<br />',
                `Hospitals: ${item.hospitals}`,
                '<br />',
                `Incidents: ${item.incidents}`,
              ];
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
          formatter: (val, opts) => {
            const item = this.data[opts.dataPointIndex];
            if (this.data.length > 12) {
              return convertMinutesToTime(val);
            }
            return item ? [
              `Hospitals: ${item.hospitals}`,
              `Incidents: ${item.incidents}`,
              `Time: ${convertMinutesToTime(val)}`,
            ] : '';
          },
        },
        annotations: {
          yaxis: [],
        },
        xaxis: {
          categories: [],
          position: 'bottom',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => convertMinutesToTime(val),
          },
          title: {
            text: 'Time',
          },
        },
      },
    };
  },
  computed: {
    series() {
      const series = [];
      const data = this.data.map((item) => (this.mode === 'percentile' ? item.count : item.countAvg));
      series.push({
        name: 'Time',
        data,
      });
      return series;
    },

    chartHeight() {
      return this.height - 200;
    },

    options() {
      const options = _.cloneDeep(this.chartOptions);
      options.title.text = `APOT-1, using ${this.mode === 'percentile' ? 'percentile' : 'average time'}`
          + `${this.dateRange ? ` ${this.dateRangeFormat(this.dateRange)}` : ''}`;
      options.xaxis.categories = this.data.map((item) => item.key);
      options.annotations.yaxis = [];
      if (this.showStatewideGoal) {
        const goal = 20;

        let maxTime = 0;
        this.data.forEach((item) => {
          const time = this.mode === 'percentile' ? item.count : item.countAvg;
          if (maxTime < parseFloat(time)) {
            maxTime = parseFloat(time);
          }
        });
        if (maxTime < goal) {
          options.yaxis.max = goal + 3;
        }

        options.annotations.yaxis.push({
          y: goal,
          borderColor: '#E04337',
          label: {
            borderColor: '#E04337',
            style: {
              color: '#fff',
              background: '#E04337',
            },
            text: 'Statewide Goal',
          },
        });
      }
      if (this.data.length > 12) {
        options.dataLabels.offsetY = -20;
      }
      return options;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .apexcharts-menu-item.exportCSV {
  display: none;
}
</style>
