<template>
  <v-toolbar
    flat
    color="white"
  >
    <v-flex xs4>
      <v-text-field
        v-model="search.search"
        :label="$t('templates.search')"
        append-icon="search"
        single-line
        hide-details
      />
    </v-flex>
    <v-flex xs2>
      <v-select
        v-model="search.status"
        :items="statuses"
        :label="$t('templates.status')"
        class="ml-4"
        hide-details
        single-line
      />
    </v-flex>
    <v-spacer/>
    <v-btn
      color="primary"
      dark
      class="mb-2"
      @click="addTemplate()"
    >
      {{ $t('buttons.add') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_TEMPLATES_SEARCH_PARAMS } from '@/store/Templates/mutations';
import { OPEN_TEMPLATE_ADD } from '@/store/Templates/actions';

export default {
  name: 'TemplatesToolbar',
  computed: {
    /**
     * Template statuses.
     */
    statuses() {
      return this.$t('templates.statuses').map(status => ({
        text: status,
        value: status.toLowerCase(),
      }));
    },

    /**
     * Search text.
     */
    search: {
      get() {
        return this.$store.state.templates.search;
      },
      set(value) {
        this.$store.commit(`templates/${SET_TEMPLATES_SEARCH_PARAMS}`, value);
      },
    },
  },
  mounted() {
    this.search.status = 'active';
  },
  methods: {
    ...mapActions({
      addTemplate: `templates/${OPEN_TEMPLATE_ADD}`,
    }),
  },
};
</script>
