<template>
  <v-row dense>
    <v-col
      sm="2"
      class="pt-0 pb-0"
    >
      <v-select
        v-model="fromOperator"
        :items="fromItems"
        style="min-width: 75px;"
        outlined
        dense
        hide-details
      />
    </v-col>
    <v-col
      sm="3"
      class="pt-0 pb-0"
    >
      <v-text-field
          v-model="fromValue"
          type="number"
          dense
          outlined
          hide-details
      />
    </v-col>
    <v-col
      sm="2"
      class="text_center text-center pt-3 pb-0"
    >
      —
    </v-col>
    <v-col
      sm="2"
      class="pt-0 pb-0"
    >
      <v-select
          v-model="toOperator"
          :items="toItems"
          style="min-width: 75px;"
          outlined
          dense
          hide-details
      />
    </v-col>
    <v-col
      sm="3"
      class="pt-0 pb-0"
    >
      <v-text-field
          v-model="toValue"
          type="number"
          outlined
          dense
          hide-details
      />
    </v-col>
  </v-row>
</template>
<script>
import _ from 'lodash';
import RANGE_OPERATORS from '@/enums/rangeOperators';

export default {
  name: 'RangeCriterion',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  watch: {
    condition: {
      handler() {
        this.$emit('update:condition', this.condition);
      },
      deep: true,
    },
  },
  data() {
    return {
      fromOperatorTmp: RANGE_OPERATORS.GT,
      toOperatorTmp: RANGE_OPERATORS.LT,
      fromItems: [
        {
          text: '>',
          value: RANGE_OPERATORS.GT,
        },
        {
          text: '≥',
          value: RANGE_OPERATORS.GTE,
        },
      ],
      toItems: [
        {
          text: '<',
          value: RANGE_OPERATORS.LT,
        },
        {
          text: '≤',
          value: RANGE_OPERATORS.LTE,
        },
      ],
    };
  },
  computed: {
    fromOperator: {
      get() {
        let operator = this.fromOperatorTmp;
        if (this.condition[RANGE_OPERATORS.GT]) {
          operator = RANGE_OPERATORS.GT;
        }
        if (this.condition[RANGE_OPERATORS.GTE]) {
          operator = RANGE_OPERATORS.GTE;
        }
        return operator;
      },
      set(value) {
        this.fromOperatorTmp = value;
        this.setFrom(value, this.fromValue);
      },
    },
    fromValue: {
      get() {
        let value = null;
        if (this.condition[RANGE_OPERATORS.GT]) {
          value = this.condition[RANGE_OPERATORS.GT];
        }
        if (this.condition[RANGE_OPERATORS.GTE]) {
          value = this.condition[RANGE_OPERATORS.GTE];
        }
        return value;
      },
      set(value) {
        this.setFrom(this.fromOperator, value);
      },
    },

    toOperator: {
      get() {
        let operator = this.toOperatorTmp;
        if (this.condition[RANGE_OPERATORS.LT]) {
          operator = RANGE_OPERATORS.LT;
        }
        if (this.condition[RANGE_OPERATORS.LTE]) {
          operator = RANGE_OPERATORS.LTE;
        }
        return operator;
      },
      set(value) {
        this.toOperatorTmp = value;
        this.setTo(value, this.toValue);
      },
    },
    toValue: {
      get() {
        let value = null;
        if (this.condition[RANGE_OPERATORS.LT]) {
          value = this.condition[RANGE_OPERATORS.LT];
        }
        if (this.condition[RANGE_OPERATORS.LTE]) {
          value = this.condition[RANGE_OPERATORS.LTE];
        }
        return value;
      },
      set(value) {
        this.setTo(this.toOperator, value);
      },
    },
  },
  methods: {
    /**
     * Set range from
     *
     * @param {String} operator - Range operator
     * @param {Number} value - Range value
     */
    setFrom(operator, value) {
      const condition = _.cloneDeep(this.condition);
      delete condition[RANGE_OPERATORS.GT];
      delete condition[RANGE_OPERATORS.GTE];
      if (value) {
        condition[operator] = value;
      }
      this.$emit('update:condition', condition);
    },

    /**
     * Set range to
     *
     * @param {String} operator - Range operator
     * @param {Number} value - Range value
     */
    setTo(operator, value) {
      const condition = _.cloneDeep(this.condition);
      delete condition[RANGE_OPERATORS.LT];
      delete condition[RANGE_OPERATORS.LTE];
      if (value) {
        condition[operator] = value;
      }
      this.$emit('update:condition', condition);
    },
  },
};
</script>
