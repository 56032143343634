<template>
  <v-container
    grid-list-xl
    fluid
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        v-if="showClientsFilter"
        lg3
        xl3
      >
        <v-autocomplete
          v-model="filters.clientId"
          :items="clients"
          :label="$t('externalServiceExports.filters.selectClient')"
          item-value="registryId"
          item-text="name"
          single-line
        />
      </v-flex>
      <v-flex
        lg3
        xl2
      >
        <v-text-field
          v-model="filters.sequenceNumber"
          :label="$t('externalServiceExports.filters.sequenceNumber')"
          clearable
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ExportsToolbar',

  props: {
    filters: {
      type: Object,
      required: true,
    },
    showClientsFilter: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      return this.$store.state.clientsList;
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },
};
</script>
