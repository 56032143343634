<template>
  <v-list-item>
    <item-content :item="item"/>
    <accept-or-reject-preset-action
      :item="item"
      @updateNotificationList="$emit('updateNotificationList', item)"
    />
  </v-list-item>
</template>

<script>
import ItemContent from '@/components/Notifications/NotificationTypes/ItemComponents/ItemContent';
import AcceptOrRejectPresetAction from '@/components/Notifications/NotificationTypes/ItemComponents/AcceptOrRejectPresetAction';

export default {
  name: 'SharePresetFieldsNotification',

  components: {
    ItemContent,
    AcceptOrRejectPresetAction,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
