export default [
  {
    text: 'Version',
    value: 'name',
    width: '90px',
  },
  {
    text: 'Release Date',
    value: 'releaseDate',
    sortable: true,
    width: '105px',
  },
  {
    text: 'Last Uploaded',
    value: 'registry.updated',
    sortable: false,
    width: '150px',
  },
  {
    text: 'Render By',
    value: 'renderTemplate.name',
    sortable: false,
    width: '125px',
  },
  {
    text: 'Description',
    value: 'description',
    sortable: false,
  },
  {
    text: 'Currently Assign To',
    sortable: false,
  },
  {
    sortable: false,
  },
];
