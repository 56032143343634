<template>
  <v-toolbar
    flat
    class="search-toolbar"
  >
    <date-range-picker-with-periods
        v-model="dateRange"
        position="right"
        class="mr-4"
    />

    <v-text-field
      v-model="filtersData.incidentNumber"
      label="Incident Number"
      style="max-width: 200px"
      hide-details
    />

    <v-spacer />

  </v-toolbar>
</template>
<script>

import _ from 'lodash';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'SearchToolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filtersData: this.filters,
      dateRange: null,
    };
  },

  watch: {
    dateRange() {
      const filters = _.cloneDeep(this.filtersData);
      if (this.dateRange) {
        // eslint-disable-next-line prefer-destructuring
        filters.from = this.dateRange[0];
        // eslint-disable-next-line prefer-destructuring
        filters.to = this.dateRange[1];
      } else {
        filters.from = null;
        filters.to = null;
      }
      this.filtersData = filters;
    },
    filtersData: {
      handler() {
        this.$emit('update:filters', this.filtersData);
      },
      deep: true,
    },
  },
};

</script>

<style lang="scss">
.search-toolbar {
  z-index: 10;
}
</style>
