<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-card-title>{{ title }}</v-card-title>
    <vue-apex-charts
      v-if="!loading && series.length"
      type="donut"
      :height="chartHeight"
      :options="options"
      :series="series || []"
    />
    <div
        v-if="!loading && !series.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';

export default {
  name: 'SimpleDonut',
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },
    };
  },
  computed: {
    series() {
      return this.data.map((item) => item.count);
    },
    chartHeight() {
      return this.height - 100;
    },
    options() {
      const options = _.cloneDeep(this.chartOptions);
      options.labels = this.data.map((item) => item.key);
      return options;
    },
  },
};
</script>
