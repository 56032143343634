import { mapActions } from 'vuex';
import { CHANGE_SITECODE } from '@/store/actions';

export default {
  computed: {
    sitecode: {
      get() {
        return this.$store.state.sitecode;
      },
      set(value) {
        this.CHANGE_SITECODE(value);
      },
    },
    isAdminSiteCode() {
      return this.$store.state.sitecode === 'admin';
    },
    isHospitalSiteCode() {
      return this.$store.state.sitecode === 'hospital';
    },
  },
  methods: {
    ...mapActions([CHANGE_SITECODE]),
  },
};
