<template>
  <div>
    <SftpStatus/>
    <Providers
      :providers="entity.providers"
      :show-latest-cad-data-link="true"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { PUBLIC_FETCH_PROVIDERS } from '@/store/CadMonitoring/actions';
import Providers from '../../components/CadMonitoring/Providers';
import SftpStatus from '../../components/CadMonitoring/SftpStatus';

const { mapActions, mapState } = createNamespacedHelpers('cadMonitoring');

export default {
  components: {
    SftpStatus,
    Providers,
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },
  mounted() {
    this.loadItems({
      clientId: this.$store.state.user.clientId,
    });
  },
  methods: {
    ...mapActions({
      loadItems: PUBLIC_FETCH_PROVIDERS,
    }),
  },
};
</script>

<style lang="scss">
/* stylelint-disable-next-line */
.expansion-panel .card__text {
  padding: 12px 28px;
}
</style>
