<template>
  <v-card
    elevation="3"
    class="ma-4"
  >
    <v-layout
      class="px-9"
      wrap
      row
    >
      <v-flex xs4>
        <v-select
          v-model="dynamicFilters.clientId"
          :items="clients"
          label="Select Client"
          item-value="registryId"
          item-text="name"
          single-line
        ></v-select>
      </v-flex>
      <v-flex
        xs4
        offset-xs2
      >
        <v-select
          v-model="dynamicFilters.methodName"
          :items="methods"
          :loading="!methodsLoaded"
          :disabled="!methodsLoaded"
          label="Select Method"
          item-value="value"
          item-text="name"
          single-line
        ></v-select>
      </v-flex>
    </v-layout>

    <v-layout
      class="px-9 mb-1"
      wrap
      row
    >
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('apiAuditLog.additionalFilters') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template>
              <v-layout
                class="px-4"
                wrap
                row
              >
                <v-flex xs2>
                  <v-text-field
                    v-model="localFilters.deviceId"
                    :maxlength="8"
                    :label="$t('apiAuditLog.filters.deviceId')"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs2
                  ml-5
                >
                  <v-text-field
                    v-model="localFilters.UDID"
                    :maxlength="40"
                    :label="$t('apiAuditLog.filters.UDID')"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs2
                  ml-5
                >
                  <v-text-field
                    v-model="localFilters.dispatchId"
                    :maxlength="7"
                    :label="$t('apiAuditLog.filters.dispatchId')"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs2
                  ml-5
                >
                  <v-text-field
                    v-model="localFilters.inventoryNumber"
                    :maxlength="4"
                    :label="$t('apiAuditLog.filters.inventoryNumber')"
                    type="text"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="submitDisabled"
                  @click.prevent="applyFilters"
                >
                  Submit
                </v-btn>
              </v-layout>
              <v-layout
                class="px-4"
                wrap
                row
              >
                <v-flex
                  xs2
                >
                  <v-text-field
                    v-model="localFilters.significantData"
                    :maxlength="40"
                    :label="$t('apiAuditLog.filters.significantData')"
                    type="text"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs2
                  ml-5
                >
                  <v-select
                    v-model="localFilters.status"
                    :items="apiLogStatuses"
                    item-value="value"
                    item-text="name"
                    single-line
                  ></v-select>
                </v-flex>
                <v-flex
                  ml-5
                  xs4
                >
                  <date-range-picker-with-periods
                    v-model="localFilters.dateRange"
                    :clearable="true"
                    position="center"
                  />
                </v-flex>
              </v-layout>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-layout>

    <audit-log-table
      :items="auditLog.items"
      :pagination="pagination"
      :total-items="auditLog.totalItems"
      :loading="loading"
      :disabled="filtersChanged"
      @update:pagination="updatePagination"
    ></audit-log-table>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { SET_TITLE } from '@/store/mutations';
import {
  LOAD_AUDIT_LOG,
  LOAD_AUDIT_METHODS,
} from '@/store/ApiAuditLog/actions';
import {
  SET_AUDIT_LOG_PAGINATION,
  SET_AUDIT_LOG_FILTERS,
  SET_AUDIT_LOG_DYNAMIC_FILTERS,
} from '@/store/ApiAuditLog/mutations';
import AuditLogTable from '@/components/ApiAuditLog/AuditLogTable';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('apiAuditLog');

export default {
  components: {
    AuditLogTable,
    DateRangePickerWithPeriods,
  },
  data() {
    return {
      panel: null,
      loading: false,
      methodsLoaded: false,
      today: moment().toISOString(),
      localFilters: {},
      apiLogStatuses: [
        {
          name: 'All Statuses',
          value: null,
        },
        {
          name: 'Success',
          value: 1,
        },
        {
          name: 'Fail',
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      auditLog: state => state,
    }),

    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      const clients = this.$store.state.clientsList.slice(0);
      clients.unshift({
        registryId: null,
        name: 'All Clients',
      });

      return clients;
    },

    /**
     * Returns methods for filtering api logs that have ever been used.
     *
     * @return {Array}
     */
    methods() {
      const methods = this.auditLog.methods.map(method => ({
        name: method,
        value: method,
      }));
      methods.unshift({
        name: 'All Methods',
        value: null,
      });

      return methods;
    },
    pagination: {
      get() {
        return this.auditLog.pagination;
      },
      set(value) {
        if (!_.isEqual(value, this.auditLog.pagination)) {
          this.setPagination(value);
          this.reloadItems();
        }
      },
    },

    /**
     * Returns only filters which used for hot data reload.
     *
     * @return {Object}
     */
    dynamicFilters() {
      return this.auditLog.dynamicFilters;
    },

    /**
     * Shows whether submit filters buttons disabled is.
     *
     * @return {Boolean}
     */
    submitDisabled() {
      return this.loading || !this.filtersChanged;
    },

    /**
     * Shows whether filters changed is.
     *
     * @return {Boolean}
     */
    filtersChanged() {
      return !_.isEqual(this.localFilters, this.auditLog.filters);
    },
  },
  watch: {
    dynamicFilters: {
      handler(newVal) {
        this.setDynamicFilters(newVal);
        this.reloadItems(true);
      },
      deep: true,
    },
  },
  mounted() {
    const method = this.$route.query.method ?? null;
    const significantData = this.$route.query.significantData ?? null;

    this.loadMethods().finally(() => {
      this.methodsLoaded = true;
    });

    this.$store.commit(SET_TITLE, this.$t('apiAuditLog.header'));
    this.localFilters = _.clone(this.auditLog.filters);
    this.localFilters.significantData = significantData;
    this.auditLog.dynamicFilters.methodName = method;

    if (significantData !== null) {
      this.panel = 0;
    }

    this.applyFilters();
  },
  methods: {
    ...mapMutations({
      setPagination: SET_AUDIT_LOG_PAGINATION,
      setFilters: SET_AUDIT_LOG_FILTERS,
      setDynamicFilters: SET_AUDIT_LOG_DYNAMIC_FILTERS,
    }),
    ...mapActions({
      loadItems: LOAD_AUDIT_LOG,
      loadMethods: LOAD_AUDIT_METHODS,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Reloads documents paginated list for current client filter
     *
     * @param {Boolean} throwOffPagination - Shows whether pagination should be thrown off
     */
    async reloadItems(throwOffPagination = false) {
      this.loading = true;
      if (throwOffPagination) {
        this.pagination.page = 1;
      }
      try {
        await this.loadItems();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Apply chosen by user filters and reload page.
     */
    applyFilters() {
      this.setFilters(_.clone(this.localFilters));

      this.reloadItems(true);
    },
  },
};
</script>
