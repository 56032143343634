<template>
  <v-card>
    <v-card-title
      :style="{backgroundColor: BackgroundColor}"
      class="white--text"
    >{{ TitleName }}</v-card-title>

    <v-card-text class="pt-4 pb-1">
      <v-layout
        justify-center
        align-center
      >
        <v-layout
          align-center
          justify-center
          style="max-width: 100px"
        >
          <v-layout
            column
            align-center
          >
            <v-btn
                icon
                class="my-0"
                style="position: relative; bottom: 4px"
                @click="onAddHourClicked"
            >
              <v-icon large>keyboard_arrow_up</v-icon>
            </v-btn>
            <v-text-field
                v-model="hourModel"
                style="width: 40px"
                class="pt-0 my-0 no-underline centered-input"
                type="number"
                hide-spin-buttons
                :min="MinHour"
                :max="MaxHour"
                step="1"
                required
                hide-details
                @wheel="$event.target.blur()"
            ></v-text-field>
            <span>{{ LblHour }}</span>
            <v-btn
                icon
                class="my-0"
                style="position: relative; top: 8px"
                @click="onSubtractHourClicked"
            >
              <v-icon large>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-layout>

          <div
              class="grey--text"
              style="font-size: 18px; position: relative; bottom: 2px"
          >
            :
          </div>
          <v-layout align-center>
            <v-layout
              column
              align-center
            >
              <v-btn
                  icon
                  class="my-0"
                  style="position: relative; bottom: 4px"
                  @click="onAddMinuteClicked"
              >
                <v-icon large>keyboard_arrow_up</v-icon>
              </v-btn>
              <v-text-field
                  v-model="minuteModel"
                  style="width: 40px"
                  class="pt-0 my-0 no-underline centered-input"
                  type="number"
                  hide-spin-buttons
                  :min="minMinute"
                  :max="maxMinute"
                  step="1"
                  required
                  hide-details
              ></v-text-field>
              <span>{{ LblMinute }}</span>
              <v-btn
                  icon
                  class="my-0"
                  style="position: relative; top: 8px"
                  @click="onSubtractMinuteClicked"
              >
                <v-icon large>keyboard_arrow_down</v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
      <div class="d-flex justify-end pt-2">
        <v-btn
          v-if="!HideBtnClear"
          text
          @click="clearTime()"
          >{{BtnClear}}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save"
        >Ok</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'CustomTimePicker',
  data() {
    return {
      /* Time Picker */
      hour: 0,
      minute: 0,
      minMinute: 0,
      maxMinute: 59,

      TitleName: null,
      LblHour: null,
      LblMinute: null,
      BtnClear: null,
      BtnNow: null,
      HideBtnClear: false,
      BackgroundColor: null,
      MinHour: null,
      MaxHour: null,
      /* /Time Picker */
    };
  },
  props: [
    'value',
    'titleName',
    'lblHour',
    'lblMinute',
    'btnClear',
    'btnNow',
    'hideBtnClear',
    'hideBtnNow',
    'backgroundColor',
    'minHour',
    'maxHour',
  ],
  created() {
    this.TitleName = !this.titleName || this.titleName === '' ? 'Select Time' : this.titleName;
    this.LblHour = !this.lblHour || this.lblHour === '' ? 'HOUR' : this.lblHour;
    this.LblMinute = !this.lblMinute || this.lblMinute === '' ? 'MIN' : this.lblMinute;
    this.BtnClear = !this.btnClear || this.btnClear === '' ? 'CLEAR' : this.btnClear;
    this.BtnNow = !this.btnNow || this.btnNow === '' ? 'NOW' : this.btnNow;
    this.HideBtnClear = !this.hideBtnClear ? false : this.hideBtnClear;
    this.BackgroundColor = !this.backgroundColor || this.backgroundColor === '' ? '#e04337' : this.backgroundColor;
    this.MinHour = !this.minHour || this.minHour === '' ? 0 : this.minHour;
    this.MaxHour = !this.maxHour || this.maxHour === '' ? 23 : this.maxHour;
  },

  computed: {
    hourModel: {
      get() {
        return this.forceTwoDigits(this.hour);
      },
      set(v) {
        this.hour = Number(v);
      },
    },
    minuteModel: {
      get() {
        return this.forceTwoDigits(this.minute);
      },
      set(v) {
        this.minute = Number(v);
      },
    },
    fullTimeModel: {
      get() {
        return !this.hour && !this.minute
          ? null
          : `${this.hourModel}:${this.minuteModel}`;
      },
      set(v) {
        let h = 0;
        let m = 0;
        if (v) {
          [h, m] = v.split(':');
        }
        this.hourModel = h;
        this.minuteModel = m;
      },
    },
  },
  mounted() {
    this.fullTimeModel = this.value;
  },

  watch: {
    hour(hour) {
      this.$nextTick(() => {
        if (hour < this.MinHour) this.hour = this.MinHour;
        if (hour > this.MaxHour) this.hour = this.MaxHour;
      });
    },
    minute(minute) {
      this.$nextTick(() => {
        if (minute < this.minMinute) this.minute = this.minMinute;
        if (minute > this.maxMinute) this.minute = this.maxMinute;
      });
    },
  },

  methods: {
    forceTwoDigits(num) {
      return (num < 10 ? '0' : '') + num;
    },
    onAddHourClicked() {
      if (this.hour < this.MaxHour) {
        this.hour += 1;
      } else {
        this.hour = this.MinHour;
      }
    },
    onSubtractHourClicked() {
      if (this.hour > this.MinHour) {
        this.hour -= 1;
      } else {
        this.hour = this.MaxHour;
      }
    },
    onAddMinuteClicked() {
      if (this.minute < this.maxMinute) {
        this.minute += 1;
      } else {
        this.minute = this.minMinute;
      }
    },
    onSubtractMinuteClicked() {
      if (this.minute > this.minMinute) {
        this.minute -= 1;
      } else {
        this.minute = this.maxMinute;
      }
    },
    clearTime() {
      this.hour = 0;
      this.minute = 0;
      this.save();
    },
    save() {
      this.$emit('input', this.fullTimeModel);
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.v-text-field.align-right input {
  text-align: right !important;
}

.v-text-field.align-left input {
  text-align: left !important;
}

.v-text-field.align-center input {
  text-align: center !important;
}

.v-text-field.no-underline .v-input__slot::before {
  display: none !important;
}

.centered-input>>>input {
  text-align: center
}
</style>
