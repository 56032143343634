<template>
  <div>
    <v-data-table
      :headers="tableHeader"
      :items="items"
      :options.sync="paginationOptions"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :height="tableHeight"
      fixed-header
      calculate-widths
      class="header-nowrap push-notifications-table"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <no-data-available
            :headers="tableHeader"
            :items="items"
          ></no-data-available>
          <v-hover
            v-for="(item, index) in items"
            :key="index"
          >
            <tr
              slot-scope="{ hover }"
              :class="isScheduled(item) || isQueued(item) ? $style.scheduled : null"
            >
              <td>{{ item | deliveryDate }}</td>
              <td>{{ item.messageType| messageType }}</td>
              <td>{{ item.showType | showType }}</td>
              <td>{{ item.client | clientView }}</td>
              <td>{{ item.device | deviceView }}</td>
              <td>{{ item.views }}/{{ item.devices }}</td>
              <td>{{ item.message }}</td>
              <td v-if="isFailedTable">{{ item.error }}</td>
              <td :class="[$style.actions, 'text-right', 'no-wrap']">
                <v-icon
                  v-show="hover && (isScheduled(item) || isFailed(item))"
                  color="grey darken-2"
                  class="mr-3"
                  @click="$emit('editNotification', item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-show="hover && (isScheduled(item) || isFailed(item))"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showDelete(item)"
                >
                  delete
                </v-icon>
              </td>
            </tr>
          </v-hover>
        </tbody>
      </template>
    </v-data-table>
    <confirmation
      ref="confirmationPopup"
      @confirmed="fetchData()"
    ></confirmation>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import i18n from '@/lang/i18n';
import fixedHeader from '@/mixins/fixedHeader';
import apiPushNotifications from '@/api/pushNotifications';
import PUSH_NOTIFICATION_STATUSES from '@/enums/pushNotificationStatuses';
import NoDataAvailable from '../NoDataAvailable';
import Confirmation from '../Confirmation';

export default {
  name: 'PushNotificationsTable',
  components: {
    Confirmation,
    NoDataAvailable,
  },
  filters: {
    /**
     * Filter of device column.
     *
     * @param {Object} device - Device object
     *
     * @return {String}
     */
    deviceView(device) {
      return device
        ? `${device.dispatchId} (Inventory #${device.inventoryNumber})`
        : 'ALL';
    },

    /**
     * Filter of client column.
     *
     * @param {Object} client - Client object
     *
     * @return {String}
     */
    clientView(client) {
      return client ? client.name : 'ALL';
    },

    /**
     * Filter of show type column.
     *
     * @param {String} showType - Show type to filter
     *
     * @return {String}
     */
    showType(showType) {
      const showTypes = {
        dashboard: i18n.t('pushNotifications.onDashboard'),
        scheduled: i18n.t('pushNotifications.onDashboard'),
        immediate: i18n.t('pushNotifications.immediate'),
      };
      return showTypes[showType];
    },

    /**
     * Filter of message type column.
     *
     * @param {String} type - Message type to filter
     *
     * @return {String}
     */
    messageType(type) {
      const messageTypes = {
        newTemplate: i18n.t('pushNotifications.newTemplate'),
        newApp: i18n.t('pushNotifications.newAppVersion'),
        url: i18n.t('pushNotifications.pleaseVisitWebsite'),
        custom: i18n.t('pushNotifications.custom'),
      };
      return messageTypes[type];
    },

    /**
     * Filter of delivery date column.
     *
     * @param {Object} item - Notification object
     *
     * @return {String}
     */
    deliveryDate(item) {
      if (item.status === 'failed') {
        return i18n.t('pushNotifications.notDelivered');
      }
      const date = item.sent || item.scheduledDate;

      return date
        ? moment(date).format('MM/DD/YYYY h:mm A')
        : i18n.t('pushNotifications.processing');
    },
  },
  mixins: [fixedHeader],
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFailedTable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('pushNotifications.tableHeaders.deliveryDate'),
          value: 'deliveryDate',
          width: '180px',
        },
        {
          text: this.$t('pushNotifications.tableHeaders.messageType'),
          value: 'mesageType',
          width: '170px',
        },
        {
          text: this.$t('pushNotifications.tableHeaders.showType'),
          value: 'responded',
          width: '150px',
        },
        {
          text: this.$t('pushNotifications.tableHeaders.client'),
          value: 'client',
          width: '200px',
        },
        {
          text: this.$t('pushNotifications.tableHeaders.device'),
          value: 'device',
          width: '170px',
        },
        {
          text: this.$t('pushNotifications.tableHeaders.received'),
          value: 'views',
          width: '170px',
          sortable: false,
        },
        {
          text: this.$t('pushNotifications.tableHeaders.message'),
          value: 'message',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    /**
     * List of table header columns.
     *
     * @return {Array}
     */
    tableHeader() {
      const headers = _.clone(this.headers);
      if (this.isFailedTable) {
        headers.push({
          text: this.$t('pushNotifications.tableHeaders.error'),
          value: 'error',
          sortable: false,
        });
      }

      headers.push({
        text: '',
        sortable: false,
      });

      return headers;
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    /**
     * Check status is scheduled.
     *
     * @param {Object} item - Push notification object.
     */
    isScheduled(item) {
      return item.status === PUSH_NOTIFICATION_STATUSES.SCHEDULED;
    },

    /**
     * Check status is squeued.
     *
     * @param {Object} item - Push notification object.
     */
    isQueued(item) {
      return item.status === PUSH_NOTIFICATION_STATUSES.QUEUED;
    },

    /**
     * Check status is squeued.
     *
     * @param {Object} item - Push notification object.
     */
    isFailed(item) {
      return item.status === PUSH_NOTIFICATION_STATUSES.FAILED;
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} item - Push notification object.
     */
    showDelete(item) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('pushNotifications.deleteConfirmationTitle'),
        this.$t('pushNotifications.deleteConfirmationText'),
        async () => {
          await apiPushNotifications.deleteNotification(item.id);
          this.$emit('deleted');
        },
      );
    },
  },
};
</script>

<style lang="scss" module>
@import '../../styles/variables';

.scheduled {
  background-color: $yellow-light;
}

.actions {
  width: 20%;
}
</style>
