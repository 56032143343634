<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      max-width="700"
    >
      <v-card :class="$style.card">
        <v-form @submit.prevent="save">
          <v-card-title class="headline pt-0">
            {{ $t('profileSettings.mindBaseExport.header') }}
          </v-card-title>
          <v-card-text class="pt-0">
            <div :class="$style.column">
              <h4>{{ $t('profileSettings.mindBaseExport.exportDestinationTitle') }}</h4>
              <v-text-field
                v-model="exportSettings.ftpServer"
                v-validate="{ required: isRequired }"
                placeholder="server:port"
                name="server"
                maxlength="255"
                :label="$t('profileSettings.mindBaseExport.server')"
                :error-messages="errors.collect('server')"
              />
              <v-text-field
                v-model="exportSettings.ftpUser"
                v-validate="{ required: isRequired }"
                name="username"
                maxlength="100"
                :label="$t('profileSettings.dhsExport.username')"
                :error-messages="errors.collect('username')"
              />
              <v-text-field
                v-show="isPasswordEditMode || isFirstSetupMode"
                v-model="newPassword"
                v-validate="{ required: isRequired && isFirstSetupMode }"
                :label="$t('profileSettings.mindBaseExport.currentPassword')"
                type="password"
                append-icon="lock"
                persistent-hint
                maxlength="100"
                name="newPassword"
                :error-messages="errors.collect('newPassword')"
                autocomplete="new-password"
                @change="isPasswordChanged = true"
              />
              <v-text-field
                v-if="!isPasswordEditMode && !isFirstSetupMode"
                value="******"
                :label="$t('profileSettings.mindBaseExport.currentPassword')"
                readonly
                append-icon="edit"
                @click:append="changePassword"
              />
              <v-text-field
                v-model="exportSettings.uploadDirectory"
                v-validate="{ required: isRequired }"
                name="uploadDirectory"
                maxlength="255"
                :error-messages="errors.collect('uploadDirectory')"
                :label="$t('profileSettings.mindBaseExport.uploadDirectory')"
              />
            </div>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              color="blue darken-1"
              text
              @click.native="showEditPopup = false"
            >{{ $t('buttons.cancel') }}</v-btn>
            <v-btn
              color="primary"
              text
              type="submit"
            >{{ $t('buttons.save') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UPDATE_MINDBASE_EXPORT_SETTINGS } from '@/store/ProfileSettings/actions';
import editExportPopupMixin from './editExportPopupMixin';

export default {
  name: 'EditMindBaseExportPopup',
  mixins: [editExportPopupMixin],
  data() {
    return {
      exportSettings: {
        ftpServer: '',
        ftpUser: '',
        uploadDirectory: '',
      },
    };
  },
  computed: {
    ...mapState('profileSettings', {
      mindBaseExportSettings: state => state.mindBaseExportSettings,
    }),
    isRequired() {
      return !!(this.exportSettings.ftpServer
          || this.exportSettings.ftpUser
          || this.exportSettings.uploadDirectory);
    },
  },
  methods: {
    ...mapActions('profileSettings', {
      // Using in editExportPopupMixin
      saveSettings: UPDATE_MINDBASE_EXPORT_SETTINGS,
    }),
    /**
     * Open popup for edit.
     */
    async showPopup() {
      this.initPopupData();
      if (this.mindBaseExportSettings) {
        this.isFirstSetupMode = false;
        this.exportSettings = {
          ftpServer: this.mindBaseExportSettings.ftpServer,
          ftpUser: this.mindBaseExportSettings.ftpUser,
          uploadDirectory: this.mindBaseExportSettings.uploadDirectory,
        };
      } else {
        this.isFirstSetupMode = true;
        this.exportSettings = {
          ftpServer: '',
          ftpUser: '',
          uploadDirectory: '',
        };
      }
    },
  },
};
</script>

<style lang="scss" module>
.card {
  padding: 14px;
}

.column {
  display: flex;
  flex-direction: column;
}
</style>
