<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span v-if="isNew">Add ZOLL Device</span>
          <span v-else>Edit ZOLL Device</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            v-if="dialog"
            class="pb-0"
        >
          <v-alert
              v-if="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          <v-row>
            <v-col sm="12">
              <v-text-field
                  v-model="device.serialNumber"
                  v-validate="{ required: true}"
                  label="Serial Number"
                  maxlength="11"
                  name="serialNumber"
                  :error-messages="errors.collect('serialNumber')"
              ></v-text-field>

              <v-text-field
                  v-model="device.device"
                  v-validate="{ required: true}"
                  label="Friendly Name"
                  maxlength="255"
                  name="device"
                  :error-messages="errors.collect('device')"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              v-if="!forceSaveBtn"
              text
              color="primary"
              :loading="saving"
              @click="validateAndSave(false)"
          >
            <template>Save</template>
          </v-btn>
          <v-btn
              v-if="forceSaveBtn"
              text
              color="primary"
              :loading="saving"
              @click="validateAndSave(true)"
          >
            <template>Save Anyway</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import zollDevices from '@/api/zollDevices';

export default {
  name: 'ZollDeviceEditPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      isNew: false,
      deviceId: null,
      device: {
        serialNumber: null,
        device: null,
      },
      saving: false,
      forceSaveBtn: false,
      dictionary: {
        attributes: {
          serialNumber: 'Serial Number',
          device: 'Friendly Name',
        },
      },
    };
  },

  computed: {
  },

  watch: {
    device: {
      handler() {
        this.$validator.reset();
        this.forceSaveBtn = false;
      },
      deep: true,
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Show Export Settings dialog.
     */
    async show(device) {
      this.hideError();
      this.$validator.reset();
      this.deviceId = device ? device.id : null;
      this.forceSaveBtn = false;
      if (device) {
        this.device = _.cloneDeep(device);
        this.isNew = false;
      } else {
        this.device = {
          serialNumber: null,
          device: null,
        };
        this.isNew = true;
      }
      this.dialog = true;
    },

    /**
     * Validate form and save zoll device if is valid.
     */
    async validateAndSave(force = false) {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.saving = true;
      try {
        const device = _.cloneDeep(this.device);
        if (force) {
          device.forceSave = true;
        }
        if (this.isNew) {
          await zollDevices.createDevice(device);
        } else {
          await zollDevices.saveDevice(this.deviceId, device);
        }
        this.dialog = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response, false);
        if (e.response
            && e.response.data
            && e.response.data.errorCode === 'WRONG_ZOLL_DEVICE_SERIAL_NUMBER'
        ) {
          this.forceSaveBtn = true;
        }
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
