<template>
  <v-card
    flat
    :disabled="Object.keys(validationResult).length === 0"
  >
    <v-row no-gutters>
      <v-col md="3">
        <v-treeview
            :items="resultMenu"
            :open="openedTree"
            :active.sync="treeValue"
            activatable
            open-on-click
            transition
        ></v-treeview>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col>
        <div v-show="currentDataSet">
          <v-tabs v-model="tabIndex" >
            <v-tab
                v-for="(item, index) in tabItems"
                :key="index"
                :href="'#' + item.href"
                exact
                active-class="primary--text"
                class="subheading text-capitalize tab-item"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-divider class="mb-5"/>
          <div
            :class="[$style.scrollable]"
            v-bind:style="{ maxHeight: (windowHeight - 350) + 'px' }"
          >
            <v-tabs-items v-model="tabIndex">
              <v-tab-item value="tab-1">
                <error-list :errors-data="currentXsdErrors" />
              </v-tab-item>
              <v-tab-item value="tab-2">
                <error-list :errors-data="currentSchematronErrors" />
              </v-tab-item>
              <v-tab-item value="tab-3">
                <error-list :errors-data="currentSchematronWarnings" />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
        <div
          v-if="!currentDataSet"
          class="text-center mt-10"
        >
          Choose the dataset.
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import _ from 'lodash';
import ErrorList from '@/components/NemsisExport/ErrorList';

export default {
  name: 'ValidateResults',
  components: { ErrorList },
  props: {
    validationResult: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      tabIndex: 'tab-1',
      panel: null,
      openedTree: ['ems'],
      treeValue: ['ems'],
    };
  },
  watch: {
    validationResult() {
      this.tabIndex = 'tab-1';
    },
    treeValue() {
      this.tabIndex = 'tab-1';
    },
  },

  computed: {
    /**
     * Get data for selected dataset.
     *
     * @return {String}
     */
    currentDataSet() {
      switch (true) {
        case this.treeValue.includes('ems'):
          return 'EMS';
        case this.treeValue.includes('dem'):
          return 'DEM';
        case this.treeValue.includes('state'):
          return 'STATE';
        default:
          return null;
      }
    },

    /**
     * Get current file XSD errors.
     *
     * @return {Object}
     */
    currentXsdErrors() {
      return this.getErrorsAsObject(this.currentDataSet, 'xsd');
    },

    /**
     * Get current file Schematron errors.
     *
     * @return {Object}
     */
    currentSchematronErrors() {
      return this.getErrorsAsObject(this.currentDataSet, 'schematron', 'errors');
    },

    /**
     * Get current file Schematron warnings.
     *
     * @return {Object}
     */
    currentSchematronWarnings() {
      return this.getErrorsAsObject(this.currentDataSet, 'schematron', 'warnings');
    },

    /**
     * Count of EMS errors.
     *
     * @return {Number}
     */
    countEmsErrors() {
      return this.countDatasetErrors('EMS');
    },

    /**
     * Count of DEM errors.
     *
     * @return {Number}
     */
    countDemErrors() {
      return this.countDatasetErrors('DEM');
    },

    /**
     * Count of State errors.
     *
     * @return {Number}
     */
    countStateErrors() {
      return this.countDatasetErrors('STATE');
    },

    /**
     * Count of EMS Warnings.
     *
     * @return {Number}
     */
    countEmsWarnings() {
      return this.countDatasetWarnings('EMS');
    },

    /**
     * Count of DEM Warnings.
     *
     * @return {Number}
     */
    countDemWarnings() {
      return this.countDatasetWarnings('DEM');
    },

    /**
     * Count of State Warnings.
     *
     * @return {Number}
     */
    countStateWarnings() {
      return this.countDatasetWarnings('STATE');
    },

    /**
     * Get list of result menu items
     *
     * @return {Array}
     */
    resultMenu() {
      return [
        {
          id: 'ems',
          name: `EMS Dataset (err: ${this.countEmsErrors} / wrn: ${this.countEmsWarnings})`,
        },
        {
          id: 'dem',
          name: `DEM Dataset (err: ${this.countDemErrors} / wrn: ${this.countDemWarnings})`,
        },
        {
          id: 'state',
          name: `State Dataset (err: ${this.countStateErrors} / wrn: ${this.countStateWarnings})`,
        },
      ];
    },

    /**
     * List of tab items.
     *
     * @return {Array}
     */
    tabItems() {
      return [
        { name: `XSD (${this.countErrors(this.currentXsdErrors)})`, href: 'tab-1' },
        { name: `Schematron Errors (${this.countErrors(this.currentSchematronErrors)})`, href: 'tab-2' },
        { name: `Schematron Warnings (${this.countErrors(this.currentSchematronWarnings)})`, href: 'tab-3' },
      ];
    },
  },

  methods: {
    /**
     * Get object of errors data.
     *
     * @param {String} datasetType - Type of dataset
     * @param {String} validator - Validator
     * @param {String} schematronType - Type of schematron validation, errors or warnings
     *
     * @return {Object}
     */
    getErrorsAsObject(datasetType, validator, schematronType) {
      const dataset = this.validationResult[datasetType] || {};
      const schematron = dataset.schematron || {};
      let value = {};
      switch (validator) {
        case 'xsd':
          value = dataset.xsd || {};
          break;
        case 'schematron':
          value = schematron[schematronType] || {};
          break;
        default:
          value = {};
          break;
      }
      const isEmptyArray = value.length === 0;
      return isEmptyArray ? {} : value;
    },

    /**
     * Count errors.
     *
     * @return {Number}
     */
    countErrors(errorsBySections) {
      let count = 0;
      _.forEach(errorsBySections, (errors) => {
        count += errors.length;
      });
      return count;
    },

    /**
     * Count dataset errors.
     *
     * @return {Number}
     */
    countDatasetErrors(datasetType) {
      const xsdErrors = this.getErrorsAsObject(datasetType, 'xsd');
      const schematronErrors = this.getErrorsAsObject(datasetType, 'schematron', 'errors');
      return this.countErrors(xsdErrors) + this.countErrors(schematronErrors);
    },

    /**
     * Count dataset warnings.
     *
     * @return {Number}
     */
    countDatasetWarnings(datasetType) {
      const schematronWarnings = this.getErrorsAsObject(datasetType, 'schematron', 'warnings');
      return this.countErrors(schematronWarnings);
    },
  },
};
</script>
<style lang="scss" module>
.scrollable {
  overflow-y: scroll;
}
</style>
