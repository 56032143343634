import _ from 'lodash';
import apiTemplates from '@/api/templates';
import DELETION_STATUSES from '@/enums/deletionStatuses';
import {
  SET_TEMPLATES_LOADING,
  SET_TEMPLATES,
  SET_SELECTED_TEMPLATE,
  SET_SHOW_EDIT_DIALOG,
  SET_ALL_TEMPLATES,
} from './mutations';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_ALL_TEMPLATES = 'FETCH_ALL_TEMPLATES';
export const OPEN_TEMPLATE_EDIT = 'OPEN_TEMPLATE_EDIT';
export const OPEN_TEMPLATE_ADD = 'OPEN_TEMPLATE_ADD';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';

export const INCLUDES = {
  REGISTRY: 'registry',
  CLIENTS: 'clients',
  RENDER_TEMPLATE: 'renderTemplate',
  LAST_FILE: 'lastFile',
};

export default {
  /**
   * Get page of templates.
   *
   * @param commit - State commit.
   * @param state - Templates state.
   */
  [FETCH_DATA]: async ({ commit, state }) => {
    commit(SET_TEMPLATES_LOADING, true);
    const result = await apiTemplates.fetchTemplatesList(
      state.search,
      state.pagination.page,
      state.pagination.itemsPerPage,
      state.pagination.sortBy[0],
      state.pagination.sortDesc[0],
      [
        INCLUDES.REGISTRY,
        INCLUDES.CLIENTS,
        INCLUDES.RENDER_TEMPLATE,
        INCLUDES.LAST_FILE,
      ],
    );
    commit(SET_TEMPLATES, result);
    commit(SET_TEMPLATES_LOADING, false);
  },

  /**
   * Fetch list of all templates with trashed.
   *
   * @param commit - State commit.
   * @param state - Templates state.
   */
  [FETCH_ALL_TEMPLATES]: async ({ commit, state }) => {
    const result = await apiTemplates.getList(
      DELETION_STATUSES.ALL,
      state.allTemplatesSortBy,
      true,
    );
    commit(SET_ALL_TEMPLATES, result);
  },

  /**
   * Open edit selected template.
   *
   * @param commit - Vuex commit.
   * @param state - Root state.
   * @param template - Selected template for edit.
   */
  [OPEN_TEMPLATE_EDIT]: ({ commit }, template) => {
    commit(SET_SELECTED_TEMPLATE, _.clone(template));
    commit(SET_SHOW_EDIT_DIALOG, true);
  },

  /**
   * Open add new template.
   *
   * @param commit - State commit.
   */
  [OPEN_TEMPLATE_ADD]: ({ commit }) => {
    commit(SET_SELECTED_TEMPLATE, {
      name: null,
      description: null,
      releaseDate: null,
      renderTemplateId: null,
      sourceCodeVersion: null,
    });
    commit(SET_SHOW_EDIT_DIALOG, true);
  },
};
