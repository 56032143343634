<template>
  <v-data-table
      :headers="headers"
      :items="entries"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :loading="loading"
      :height="tableHeight"
      fixed-header
      class="entries-table"
  >
    <template v-slot:item="{ item }">
      <tr
          :class="[
          !item.approved && !item.rejected ? 'not-approved' : null,
          !item.approved && item.rejected ? 'entry-rejected' : null,
          item.approved && item.evaluationRequired
          && item.isEvaluated === false ? 'awaiting-evaluation' : null,
          ]"
      >
        <td>
          {{ item.date | dateFormat }}
        </td>
        <td>
          {{ item.provider }}
        </td>
        <td>
          <a @click="$emit('view', item)">{{ item.courseTitle }}</a>
        </td>
        <td>
          {{ convertNumberToTime(item.hours) }}
        </td>
        <td v-if="showAttendeesColumn">
          {{ item.attendeesNumber }}
        </td>
        <td v-if="showSkillsColumn">
          {{ item.skillsNumber }}
        </td>
        <td v-if="showEvaluationsColumn">
          <span v-if="item.evaluationRequired">
            <a @click="$emit('evaluationAttendeesClick', item)">
              {{ item.evaluatedNumber }}/{{ item.attendeesNumber }}
            </a>
          </span>
          <span v-else>
            —
          </span>
        </td>
        <td>
          <span v-if="item.createdByUser">
            {{ item.createdByUser.firstName }} {{ item.createdByUser.lastname }}
          </span>
        </td>
        <td v-if="showStatusColumn">
          <template v-if="item.approved">
            <span v-if="item.evaluationRequired && item.isEvaluated === false">
              Awaiting evaluation
            </span>
            <span v-else>
              Confirmed
            </span>
          </template>
          {{ !item.approved && !item.rejected ? 'Awaiting confirmation' : null }}
          <span v-if="!item.approved && item.rejected && !item.rejectedComment">
            Rejected
          </span>
          <v-tooltip
              v-if="!item.approved && item.rejected && item.rejectedComment"
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                  v-bind="attrs"
                  class="rejected-with-comment"
                  v-on="on"
              >
                Rejected
              </span>
            </template>
            <span>{{ item.rejectedComment }}</span>
          </v-tooltip>
        </td>
        <td>
          <div
              v-if="item.approved && item.evaluationRequired && item.isEvaluated === false"
              class="float-right text-no-wrap"
          >
            <v-btn
                small
                @click="$emit('evaluate', item)"
            >
              <v-icon
                  small
                  class="mr-1"
                  color="blue"
              >
                mdi-comment-question-outline
              </v-icon>
              Evaluate
            </v-btn>
          </div>
          <template v-if="showAdminTools">
            <div
                v-if="!item.approved && !item.rejected"
                class="float-right text-no-wrap"
            >
              <v-btn
                  small
                  class="mr-2"
                  @click="$emit('reject', item)"
              >
                <v-icon
                    small
                    class="mr-1"
                    color="red"
                >
                  mdi-close-octagon
                </v-icon>
                Reject
              </v-btn>
              <v-btn
                small
                @click="$emit('approve', item)"
              >
                <v-icon
                    small
                    class="mr-1"
                    color="green"
                >
                  mdi-check-decagram
                </v-icon>
                Approve
              </v-btn>
            </div>
            <div
                v-if="item.approved"
                class="table-actions float-right"
            >
              <v-btn
                  small
                  class="mr-3 float-right"
                  icon
                  @click="$emit('edit', item)"
              >
                <v-icon color="grey darken-2">edit</v-icon>
              </v-btn>
              <v-btn
                  small
                  class="mr-3 float-right"
                  icon
                  @click="$emit('delete', item)"
              >
                <v-icon color="grey darken-2">delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-else-if="item.isOwner">
            <div
                v-if="item.rejected"
                class="table-actions float-right"
            >
              <v-btn
                  class="mr-3 float-right"
                  small
                  icon
                  @click="$emit('delete', item)"
              >
                <v-icon color="grey darken-2">delete</v-icon>
              </v-btn>
            </div>
            <div
                v-if="!item.approved && !item.rejected"
                class="table-actions float-right"
            >
              <v-btn
                  class="mr-3 float-right"
                  small
                  icon
                  @click="$emit('edit', item)"
              >
                <v-icon color="grey darken-2">edit</v-icon>
              </v-btn>
              <v-btn
                  class="mr-3 float-right"
                  small
                  icon
                  @click="$emit('delete', item)"
              >
                <v-icon color="grey darken-2">delete</v-icon>
              </v-btn>
            </div>
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>

import _ from 'lodash';
import fixedHeader from '@/mixins/fixedHeader';
import ceHours from '@/api/ceHours';
import { convertNumberToTime } from '@/utils/convertNumberToTime';

export default {
  name: 'EntriesTable',
  mixins: [fixedHeader],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    showAttendeesColumn: {
      default: true,
      type: Boolean,
    },
    showSkillsColumn: {
      default: true,
      type: Boolean,
    },
    showEvaluationsColumn: {
      default: true,
      type: Boolean,
    },
    showStatusColumn: {
      default: false,
      type: Boolean,
    },
    showAdminTools: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      debounce: null,
      additionalIndent: 0,
      convertNumberToTime,
      pagination: {
        sortDesc: [true],
        page: 1,
        sortBy: ['date'],
        itemsPerPage: 25,
        totalItems: 0,
      },
      loading: false,
      totalItems: 0,
      entries: [],
    };
  },
  computed: {
    headers() {
      const headers = [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        { text: 'Provider', value: 'provider' },
        { text: 'Course Title', value: 'courseTitle' },
        { text: 'Hours', value: 'hours' },
      ];
      if (this.showAttendeesColumn) {
        headers.push({ text: 'Attendees', value: 'attendeesNumber', sortable: true });
      }
      if (this.showSkillsColumn) {
        headers.push({ text: 'Skills', value: 'skillsNumber', sortable: true });
      }
      if (this.showEvaluationsColumn) {
        headers.push({ text: 'Evaluation(s)', value: 'evaluatedNumber', sortable: false });
      }
      headers.push({ text: 'Created By', value: 'createdBy', sortable: false });
      if (this.showStatusColumn) {
        headers.push({ text: 'Status', value: 'status', sortable: false });
      }
      headers.push({ text: '', value: 'actions', sortable: false });
      return headers;
    },
  },
  mounted() {
    this.fetchDataDebounce();
    document.addEventListener('approveRejectEntry', () => {
      this.fetchDataDebounce();
    });
  },
  watch: {
    /**
     * Watch pagination options and update pagination
     */
    pagination: {
      // eslint-disable-next-line
      handler() {
        this.fetchDataDebounce();
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        order_by: this.pagination.sortBy[0],
        sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        include: 'createdByUser',
        ...this.filters,
      };
      try {
        this.loading = true;
        const response = await ceHours.getEntries(params);
        this.entries = response.results;
        this.totalItems = response.pagination.total;
      } finally {
        this.loading = false;
      }
    },

    fetchDataDebounce(resetPage = false) {
      if (resetPage) {
        this.pagination.page = 1;
      }
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.fetchData(resetPage);
        }, 300);
      }
      this.debounce();
    },
  },
};
</script>
<style lang="scss">
.entries-table {
  .rejected-with-comment {
    text-decoration: underline;
    cursor: pointer;
  }
  .table-actions {
    width: 100px;
    visibility: hidden;
  }
  tr:hover .table-actions {
    visibility: visible;
  }
  .not-approved {
    background-color: #c5e2ff;
  }
  .entry-rejected {
    background-color: #ffe7ec;
  }
  tr:hover.not-approved {
    background-color: #beddfd !important;
  }
  tr:hover.entry-rejected {
    background-color: #ffe2e8 !important;
  }
  .awaiting-evaluation {
    background-color: #c5e2ff;
  }
  tr:hover.awaiting-evaluation {
    background-color: #beddfd !important;
  }
  thead {
    th {
      z-index: 1 !important;
    }
  }
}
</style>
