<template>
  <div class="ce-home-container">
    <div class="content">
      <div class="title">CE Tracking provided by:</div>
      <img
          :src="require('@/assets/img/MobileMedic_logo1_blackred.png')"
          style="max-width: 400px"
      >
      <div class="text">
        Should you have any questions or concerns, <br />
        please contact your nurse educator.
      </div>
    </div>
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';

export default {
  data() {
    return {
    };
  },
  computed: {},
  mounted() {
    this.$store.commit(SET_TITLE, 'Continuing Education Tracking System');
  },
  methods: {
  },
};
</script>
<style>
  .ce-home-container {
    display: grid;
    place-items: center;
    height: calc(100vh - 300px);
    .content {
      padding: 20px;
      .title {
        font-size: 30px !important;
        text-align: center;
        margin-bottom: 30px;
      }
      img {
        margin-bottom: 20px;
      }
      .text {
        font-size: 22px !important;
        text-align: center;
      }
    }
  }
</style>
