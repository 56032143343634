<template>
  <v-dialog
      v-model="dialog"
      max-width="400"
      persistent
  >
    <v-card>
      <v-card-title>{{ isNew ? 'Upload File' : 'Edit File' }}</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-alert
            :value="uploadErrors.length || errorAlert ? true : false"
            type="error"
            outlined
            class="mb-4 mt-4"
        >
        <span
            v-for="(uploadError, i) in uploadErrors"
            :key="i"
        >
          {{ uploadError }} <br>
        </span>
          <span v-if="errorAlert" >
            {{ errorMessage }}
          </span>
        </v-alert>
        <v-text-field
            ref="title"
            v-model="title"
            v-validate="{ required: true, regex: /^[\sa-zA-Z0-9_-]+$/, min: 3, max: 100 }"
            label="Title"
            name="title"
            type="text"
            :error-messages="errors.collect('title')"
        />
        <uploader
            v-if="isNew"
            ref="uploader"
            v-model="uploadedFile"
            :allowed-mime-types="mimeTypes"
            :title="$t('general.uploadFile')"
            :disabled="saving"
            :show-uploading-progress="true"
            fixed-width="100%"
            class="mt-4"
            @file-update="fileSelected"
        />
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            color="blue darken-1"
            @click="close"
        >Cancel
        </v-btn>
        <v-btn
            text
            :loading="saving"
            color="primary"
            :disabled="isDisabled()"
            @click="save"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import Uploader from '@/components/Uploader';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import incidents from '@/api/incidents';

export default {
  name: 'FilesPopup',
  components: {
    Uploader,
  },
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      saving: false,
      isNew: true,
      file: null,
      incidentId: null,
      title: null,
      mimeTypes: [],
      uploadedFile: null,
      uploadErrors: [],
    };
  },
  methods: {
    /**
     * Open popup.
     *
     * @param {Number} incidentId - Incident identifier
     * @param {Object|null} file - File object
     *
     * @return {Promise<void>}
     */
    async open(incidentId, file = null) {
      if (file !== null) {
        this.isNew = false;
        this.file = file;
        this.title = file.title;
      }

      this.incidentId = incidentId;
      this.dialog = true;
    },

    /**
     * Check selected file.
     *
     * @param {Object} uploadedFile - Uploaded file
     */
    fileSelected(uploadedFile) {
      this.uploadErrors = [];
      if (!uploadedFile) {
        return;
      }

      const fileSize = 75 * 1024 * 1024;
      const fileTypes = ['application/pdf', 'application/zip', 'image/png'];

      if (uploadedFile.size > (fileSize)) {
        this.uploadErrors.push('Max file size is 75Mb');
      }

      if (
        !fileTypes.includes(uploadedFile.type) && uploadedFile.name.match(/^[\sa-zA-Z0-9_-]+(.zol)|(.ZOL)$/) === null
      ) {
        this.uploadErrors.push('Allowed only PDF, ZIP, PNG, ZOL');
      }

      if (this.uploadErrors.length) {
        this.uploadedFile = null;
      }
    },

    /**
     * Cancel method for dialog.
     */
    close() {
      if (this.isNew) {
        this.$refs.uploader.clear();
      }

      this.dialog = false;
      this.saving = false;
      this.file = null;
      this.incidentId = null;
      this.isNew = true;
      this.title = null;
      this.uploadErrors = [];
      this.uploadedFile = null;
    },

    /**
     * Save method for dialog.
     *
     * @return {Promise<void>}
     */
    async save() {
      this.errors.clear();
      this.hideError();

      if (!await this.$validator.validateAll() || this.uploadErrors.length) {
        return;
      }
      this.saving = true;
      try {
        if (this.isNew) {
          const presignedData = await this.$refs.uploader.upload();
          this.file = {
            fileName: presignedData.tmpFilename,
            title: this.title,
            fileType: this.uploadedFile.name.slice(-3).toLowerCase(),
          };
        } else {
          this.file.title = this.title;
        }
        if (this.isNew) {
          await incidents.createFile(this.incidentId, this.file);
        } else {
          await incidents.updateFile(this.incidentId, this.file);
        }
        this.$emit('saved');
        this.close();
      } catch (error) {
        this.parseErrorResponse(error.response);

        if (error.response.status === 500) {
          this.showError(error.response.statusText);
        }
      } finally {
        this.saving = false;
      }
    },

    /**
     * Check if the file can be uploaded.
     *
     * @return {boolean}
     */
    isDisabled() {
      if (this.isNew) {
        return this.title === null
          || this.uploadedFile === null
          || this.uploadErrors.length
          || this.$validator.errors.has('title');
      }
      return this.title === null;
    },
  },
};
</script>
