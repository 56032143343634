<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text>
          <div class="pt-3">
            Loading...
            <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-show="!loading">
        <v-card-title>Flag for review</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-autocomplete
            v-model="users"
            v-validate="{ required: true }"
            name="recipients"
            :items="recipientsList"
            label="Choose Recipients"
            placeholder="Choose Recipients"
            :error-messages="errors.collect('recipients')"
            chips
            multiple
            deletable-chips
            clearable
          />
          <v-textarea
              v-model="flag.message"
              v-validate="{ required: true }"
              :error-messages="errors.collect('message')"
              name="message"
              label="Reason For Flagging"
          />
          <v-text-field
            v-model="flag.falloutCode"
            maxlength="6"
            label="Fallout Code (optional)"
          />
          <span>Notify me via email about responses (⩾ 11 characters) for those:</span>
          <v-checkbox
            v-model="flag.notifyWhenDoNotUnderstand"
            label="That do not understand"
          />
          <v-checkbox
              v-model="flag.notifyWhenUnderstand"
              label="That understand"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="sending"
              :disabled="resending"
              @click="validateAndSend(false)"
          >
            {{ isNew ? 'Send' : 'Only Save' }}
          </v-btn>
          <v-btn
              v-if="!isNew"
              text
              color="primary"
              :loading="resending"
              :disabled="sending"
              @click="validateAndSend(true)"
          >
            Save/Resend to all users
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import flaggedUsers from '@/api/flaggedUsers';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import incidents from '@/api/incidents';

export default {
  name: 'AddFlagPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      sending: false,
      resending: false,
      recipients: [],
      loading: false,
      users: [],
      teamMembers: [],
      incident: null,
      flag: {},
      isNew: true,
      blankFlag: {
        message: null,
        falloutCode: null,
      },
      dictionary: {
        attributes: {
          recipients: 'Recipients',
          message: 'Message',
        },
      },
    };
  },
  computed: {
    recipientsList() {
      const addedIds = [];
      const recipients = [];
      this.teamMembers.forEach(item => {
        addedIds.push(item.id);

        recipients.push({
          text: `TM#${item.tmNumber}: ${item.fullName}`,
          value: item.id,
        });
      });

      this.recipients.forEach(item => {
        if (addedIds.includes(item.registryId)) {
          return;
        }
        recipients.push({
          text: `${item.firstName} ${item.lastname}`,
          value: item.registryId,
        });
      });
      return recipients;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },

    async show(incident) {
      this.hideError();
      this.$validator.reset();
      this.dialog = true;
      this.incident = _.cloneDeep(incident);
      this.flag = incident.flag ? _.cloneDeep(incident.flag) : _.clone(this.blankFlag);
      this.isNew = !incident.flag;
      this.users = [];
      this.loading = true;
      try {
        const promises = [this.loadRecipients(), this.loadTeamMembers()];
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
      if (incident.flag) {
        incident.flag.alerts.forEach(item => {
          this.users.push(item.userID || item.userId);
        });
      } else {
        this.teamMembers.forEach(item => {
          this.users.push(item.id);
        });
      }
    },

    /**
     * Load list of recipients.
     */
    async loadRecipients() {
      if (this.recipients.length) {
        return;
      }
      const response = await flaggedUsers.fetchRecipients();
      this.recipients = response.results;
    },

    /**
     * Load list of incident's team members.
     */
    async loadTeamMembers() {
      const response = await incidents.getTeamMembers(this.incident.id);
      this.teamMembers = response.results;
    },

    async validateAndSend(resendAll = false) {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }

      if (resendAll) {
        this.resending = true;
      } else {
        this.sending = true;
      }

      try {
        await flaggedUsers.sendFlag(this.incident.id, {
          incidentId: this.incident.id,
          message: this.flag.message,
          userIds: this.users,
          falloutCode: this.flag.falloutCode || null,
          notifyWhenDoNotUnderstand: this.flag.notifyWhenDoNotUnderstand || false,
          notifyWhenUnderstand: this.flag.notifyWhenUnderstand || false,
          isResendAll: resendAll,
        });
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.sending = false;
        this.resending = false;
      }
      this.$emit('sent');
      this.close();
    },
  },
};
</script>
