export default {
  header: 'Users',
  tableHeaders: {
    firstName: 'First Name',
    lastName: 'Last Name',
    group: 'Group',
    email: 'Email',
    active: 'Active',
    lastlogin: 'Last Login',
    phone: 'Phone',
    license: 'License #',
  },
  filters: {
    search: 'Search',
    status: 'Status',
  },
  statuses: {
    all: 'All',
    active: 'Active',
    deactivated: 'Deactivated',
    deleted: 'Deleted',
  },
  editPopup: {
    headerEdit: 'Edit User',
    headerNew: 'Add User',
    firstName: 'First Name',
    lastName: 'Last Name',
    userName: 'Username',
    email: 'Email',
    phone: 'Phone',
    group: 'Group',
    emt: 'Paramedic/EMT#',
    stateLicense: 'State License #',
    nationalLicense: 'National License #',
    password: 'Password',
    retypePassword: 'Retype Password',
    active: 'Active',
    licenseType: 'License Type',
  },
  deleteConfirmationTitle: 'Delete user',
  deleteConfirmationText: 'Do you really want to delete user: {userName}?',
  deleteConfirmationHasPendingFlagsText: 'Warning: Deleting this user ({firstName} {lastname}) will also removing the “Pending” status from this users flagged messages. This can not be undone.',
  restoreConfirmationTitle: 'Restore user',
  restoreConfirmationText: 'Do you want to restore user: {userName}?',
};
