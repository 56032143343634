export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_DOCUMENTS_PAGINATION = 'SET_DOCUMENTS_PAGINATION';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_COMMON_CATEGORIES = 'SET_COMMON_CATEGORIES';
export const SET_DOCUMENTS_FILTERS = 'SET_DOCUMENTS_FILTERS';
export const SET_COMMON_DOCUMENTS = 'SET_COMMON_DOCUMENTS';
export const SET_COMMON_DOCUMENTS_PAGINATION = 'SET_COMMON_DOCUMENTS_PAGINATION';
export const SET_COMMON_DOCUMENTS_FILTERS = 'SET_COMMON_DOCUMENTS_FILTERS';

export default {
  /**
   * Save list documents.
   *
   * @param {Object} state - Document's state.
   * @param {{ items: Array, totalItems: number }} data - Documents data to set
   */
  [SET_DOCUMENTS]: (state, { items, totalItems }) => {
    state.documents.items = items;
    state.documents.totalItems = totalItems;
  },

  /**
   * Save list common documents.
   *
   * @param {Object} state - Document's state.
   * @param {{ items: Array, totalItems: number }} data - Common documents data to set
   */
  [SET_COMMON_DOCUMENTS]: (state, { items, totalItems }) => {
    state.commonDocuments.items = items;
    state.commonDocuments.totalItems = totalItems;
  },

  /**
   * Save document's pagination.
   *
   * @param {Object} state - Document's state.
   * @param {Object} pagination - Documents pagination.
   */
  [SET_DOCUMENTS_PAGINATION]: (state, pagination) => {
    state.documents.pagination = pagination;
  },

  /**
   * Saves filters of documents.
   *
   * @param {Object} state - Document's state.
   * @param {Object} filters - Filters to set.
   */
  [SET_DOCUMENTS_FILTERS]: (state, filters) => {
    state.documents.filters = filters;
  },

  /**
   * Save common document's pagination.
   *
   * @param {Object} state - Document's state.
   * @param {Object} pagination - Documents pagination.
   */
  [SET_COMMON_DOCUMENTS_PAGINATION]: (state, pagination) => {
    state.commonDocuments.pagination = pagination;
  },

  /**
   * Saves filters of common documents.
   *
   * @param {Object} state - Document's state.
   * @param {Object} filters - Filters to set.
   */
  [SET_COMMON_DOCUMENTS_FILTERS]: (state, filters) => {
    state.commonDocuments.filters = filters;
  },

  /**
   * Saves categories tree.
   *
   * @param {Object} state - Document's state.
   * @param {Array} categories - Categories tree.
   */
  [SET_CATEGORIES]: (state, categories) => {
    state.categories = categories;
  },

  /**
   * Saves common categories tree.
   *
   * @param {Object} state - Document's state.
   * @param {Array} categories - Categories tree.
   */
  [SET_COMMON_CATEGORIES]: (state, categories) => {
    state.commonCategories = categories;
  },
};
