<template>
  <view-resolver>
    <incident-view-client slot="clientView"/>
    <incident-view-hospital slot="hospitalView"/>
  </view-resolver>

</template>

<script>
import ViewResolver from '@/components/ViewResolver';
import IncidentViewClient from './IncidentViewClient';
import IncidentViewHospital from './IncidentViewHospital';

export default {
  name: 'IncidentViewResolver',
  components: {
    ViewResolver,
    IncidentViewClient,
    IncidentViewHospital,
  },
};
</script>

<style scoped>

</style>
