<template>
  <div>
    <v-data-table
      :headers="header"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :height="tableHeight"
      :loading="loading"
      :footer-props="{
           itemsPerPageOptions: [10, 25, 50]
        }"
      no-data-text="No data available"
      no-results-text="No data available"
      fixed-header
      class="header-nowrap"
    >
      <template v-slot:item.datetime="{ item }">
        <a @click="$emit('goToIncident', item)">{{ item.datetime | datetime }}</a>
      </template>
      <template v-slot:item.deviceId="{ item }">
        Device ID: <strong>{{ item.deviceId }}</strong><br/>
        Inventory #: <strong>{{ item.inventoryNumber }}</strong>
      </template>
      <template v-slot:item.dispatchId="{ item }">
        Dispatch ID: <strong>{{ item.dispatchId }}</strong><br/>
        UDID: <strong>{{ item.UDID}}</strong>
      </template>
      <template v-slot:item.APIStatus="{ item }">
        <span
            v-if="item.APIStatus"
            class="green--text"
        >
          {{ $t('apiAuditLog.statuses.success') }}
        </span>
        <span
            v-else
            class="red--text"
        >
          {{ $t('apiAuditLog.statuses.fail') }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'ApiLogsTable',
  mixins: [fixedHeader],
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    header() {
      return [
        {
          text: 'Date/Time(by PDT)',
          value: 'datetime',
          sortable: false,
        },
        {
          text: 'Device info #1',
          value: 'deviceId',
          sortable: false,
        },
        {
          text: 'Device info #2',
          value: 'dispatchId',
          sortable: false,
        },
        {
          text: 'API methods',
          value: 'methodName',
          sortable: false,
        },
        {
          text: 'Details',
          value: 'significantData',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'APIStatus',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.$emit('updatePagination', newData);
      }
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 25,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
    };
  },
};
</script>
