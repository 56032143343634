import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import login from './Login';
import cadMonitoring from './CadMonitoring';
import adminDashboard from './AdminDashboard';
import sitecode from '../sitecode';
import hospitalsTypes from './HospitalsTypes';
import hospitals from './Hospitals';
import lookupStates from './LookupStates';
import adminList from './Admin';
import templates from './Templates';
import documents from './Documents';
import apiAuditLog from './ApiAuditLog';
import importedIncidents from './ImportedIncidents';
import incidents from './Incidents';
import incidentView from './IncidentView';
import transactionLogs from './TransactionLogs';
import transmittedReports from './TransmittedReports';
import flaggedUsers from './FlaggedUsers';
import duplicateIncidents from './DuplicateIncidents';
import pushNotifications from './PushNotifications';
import clients from './Clients';
import utilitiesExport from './UtilitiesExport';
import users from './Users';
import devices from './Devices';
import odMapExports from './OdMapExports';
import hdeExports from './HDEExports';
import firstRunDevices from './FirstRunDevices';
import profileSettings from './ProfileSettings';
import errorsUtility from './ErrorsUtility';
import faxes from './Faxes';

Vue.use(Vuex);

export default function createStore() {
  return new Vuex.Store({
    plugins: [
      createPersistedState({
        paths: ['user'],
      }),
    ],
    modules: {
      login,
      cadMonitoring,
      adminDashboard,
      hospitalsTypes,
      hospitals,
      templates,
      lookupStates,
      adminList,
      documents,
      apiAuditLog,
      importedIncidents,
      incidents,
      incidentView,
      transactionLogs,
      transmittedReports,
      flaggedUsers,
      duplicateIncidents,
      pushNotifications,
      clients,
      utilitiesExport,
      users,
      devices,
      odMapExports,
      hdeExports,
      firstRunDevices,
      profileSettings,
      errorsUtility,
      faxes,
    },
    state: {
      sitecode,
      user: null,
      siteInfo: null,
      title: null,
      drawer: false,
      displayHeader: false,
      initialized: false,
      initializing: false,
      aclInitialized: false,
      initializingUser: true,
      initializingSiteInfo: true,
      gradientBackground: false,
      clientsList: [],
      snackbar: false,
      snackbarTitle: null,
      snackbarColor: 'green',
      verifyContactDataDialog: false,
      showLogoutHospitalPopup: false,
      isPrinting: false,
    },
    actions,
    mutations,
    getters,
  });
}
