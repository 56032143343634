<template>
  <v-card
      elevation="3"
      class="ma-4"
  >
    <v-tabs v-model="tabIndex">
      <v-tab
          v-for="item in tabItems"
          :key="item.name"
          exact
          active-class="primary--text"
          class="subheading text-capitalize tab-item"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-divider class="mb-5"/>

    <toolbar
        v-if="tab !== tabs.SEARCH"
        :downloading="downloading"
        :exporting="exporting"
        :download-name="downloadName"
        :year.sync="year"
        :month.sync="month"
        @download="downloadFile"
        @export="exportAll"
    />
    <search-toolbar
        v-if="tab === tabs.SEARCH"
        :filters.sync="searchFilters"
    />

    <v-tabs-items :value="tabIndex">
      <v-tab-item :key="tabs.ALS">
        <reconciliation-table
            ref="alsTable"
            :client-id="clientId"
            :type="this.tabs.ALS"
            :year="year"
            :month="month"
            :show-response-type-columns="true"
            :is-active="tab === tabs.ALS"
        />
      </v-tab-item>
      <v-tab-item :key="tabs.BLS">
        <reconciliation-table
            ref="blsTable"
            :client-id="clientId"
            :type="this.tabs.BLS"
            :year="year"
            :month="month"
            :show-response-type-columns="true"
            :is-active="tab === tabs.BLS"
        />
      </v-tab-item>
      <v-tab-item>
        <reconciliation-table
            ref="firstResponderTable"
            :client-id="clientId"
            :type="this.tabs.FIRST_RESPONDER"
            :year="year"
            :month="month"
            :show-response-type-columns="true"
            :is-active="tab === tabs.FIRST_RESPONDER"
        />
      </v-tab-item>
      <v-tab-item>
        <search-table
            ref="searchTable"
            :client-id="clientId"
            :filters="searchFilters"
            :show-response-type-columns="true"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import ReconciliationTable from '@/components/Reconciliation/ReconciliationTable';
import Toolbar from '@/components/LongBeachBilling/Toolbar';
import SearchTable from '@/components/Reconciliation/SearchTable';
import SearchToolbar from '@/components/Reconciliation/SearchToolbar';
import reconciliationMixins from '@/mixins/reconciliationMixins';
import longBeachBilling from '@/api/longBeachBilling';
import CLIENT_IDENTIFIERS from '@/enums/clientIdentifiers';
import RECONCILIATION_TABS from '@/enums/reconciliationTabs';

export default {
  name: 'Reconciliation',
  mixins: [reconciliationMixins],

  components: {
    SearchToolbar,
    SearchTable,
    Toolbar,
    ReconciliationTable,
  },

  data() {
    return {
      tabItems: [
        { name: '"ALS" Analysis (Based on "R" unit)' },
        { name: '"BLS" Analysis (Based on "BLS" unit)' },
        { name: 'First Responder' },
        { name: 'Search RAW CAD Data' },
      ],
      tabMap: {
        0: RECONCILIATION_TABS.ALS,
        1: RECONCILIATION_TABS.BLS,
        2: RECONCILIATION_TABS.FIRST_RESPONDER,
        3: RECONCILIATION_TABS.SEARCH,
      },
      clientId: CLIENT_IDENTIFIERS.LONG_BEACH,
      exporting: false,
    };
  },

  computed: {
    downloadName() {
      const names = {
        als: 'Download ALS',
        bls: 'Download BLS',
        firstResponder: 'Download 1st Responder',
      };
      return names[this.tab];
    },
  },

  created() {
    this.$store.commit(SET_TITLE, 'Long Beach Billing');
  },

  methods: {
    async exportAll(fileType, period) {
      this.exporting = true;
      try {
        await longBeachBilling.exportAll({
          year: this.year,
          month: this.month,
          fileType,
          period,
        });
      } finally {
        this.exporting = false;
      }
    },
  },
};
</script>
