export const SET_HOSPITALS_SEARCH_RESULT = 'SET_HOSPITALS_SEARCH_RESULT';
export const SET_HOSPITALS_SEARCH_PARAMS = 'SET_HOSPITALS_SEARCH_PARAMS';
export const SET_HOSPITALS_PAGINATION = 'SET_HOSPITALS_PAGINATION';
export const SET_HOSPITALS_SEARCH_LOADING = 'SET_HOSPITALS_SEARCH_LOADING';
export const SET_SELECTED_HOSPITAL = 'SET_SELECTED_HOSPITAL';
export const SET_SHOW_EDIT_DIALOG = 'SET_SHOW_EDIT_DIALOG';
export const SET_LOADING_HOSPITAL = 'SET_LOADING_HOSPITAL';
export const SET_HOSPITAL = 'SET_HOSPITAL';

export default {
  /**
   * Save list of filtered hospitals.
   *
   * @param state - Hospital's state.
   * @param {Array} data - List of hospitals from api.
   */
  [SET_HOSPITALS_SEARCH_RESULT]: (state, data) => {
    state.hospitals = data.results;
    state.paginationInfo = data.pagination;
  },

  /**
   * Save params for hospital's search.
   *
   * @param state - Hospital's state.
   * @param {Object} data - Search params.
   */
  [SET_HOSPITALS_SEARCH_PARAMS]: (state, data) => {
    state.search = data;
  },

  /**
   * Save information about pagination on hospital's page.
   *
   * @param state - Hospital's state.
   * @param {Object} data - Pagination info.
   */
  [SET_HOSPITALS_PAGINATION]: (state, data) => {
    state.pagination = data;
  },

  /**
   * Set flag then data for hospital's table are loading.
   *
   * @param state - Hospital's state.
   * @param {boolean} data - True if data are loading, else false.
   */
  [SET_HOSPITALS_SEARCH_LOADING]: (state, data) => {
    state.loading = data;
  },

  /**
   * Save selected hospital.
   *
   * @param state - Hospital's state.
   * @param {Object} data - Selected hospital.
   */
  [SET_SELECTED_HOSPITAL]: (state, data) => {
    state.selectedHospital = data;
  },

  /**
   * Set flag for open edit hospital popup.
   *
   * @param state - Hospital's state.
   * @param {boolean} data - True for open popup, false for close.
   */
  [SET_SHOW_EDIT_DIALOG]: (state, data) => {
    state.showEditPopup = data;
  },

  /**
   * Set flag when loading selected hospital is loading.
   *
   * @param state - Hospital's state.
   * @param {boolean} data - True when loading.
   */
  [SET_LOADING_HOSPITAL]: (state, data) => {
    state.loadingHospital = data;
  },

  /**
   * Save hospital details info.
   *
   * @param state - Hospital's state.
   * @param {Object} data - Hospital info.
   */
  [SET_HOSPITAL]: (state, data) => {
    state.hospital = data;
  },
};
