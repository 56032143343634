<template>
  <v-card
      :class="['total-box', 'fill-height', ('color' + colorNumber)]"
      :loading="loading"
      rounded="lg"
      dark
  >
    <v-row class="pl-4 pr-4">
      <v-col md="6">
        <div class="card-count">{{ loading ? '-' : total }}</div>
        <div class="card-title">{{title}}</div>
      </v-col>
      <v-col md="6">
        <vue-apex-charts
            v-if="!loading && series.length"
            type="line"
            height="80"
            :options="options"
            :series="series || []"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ApexCharts from 'apexcharts';
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';

export default {
  name: 'TotalCard',
  components: {
    VueApexCharts,
  },
  props: {
    colorNumber: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          group: 'sparks',
          type: 'line',
          height: 80,
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            opacity: 0.2,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 1,
        },
        grid: {
          padding: {
            top: 20,
            bottom: 10,
          },
        },
        colors: ['#fff'],
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: null,
            },
          },
        },
      },

    };
  },
  computed: {
    series() {
      const data = this.data.map((item) => item.count);
      return [{ data }];
    },
    options() {
      const titles = this.data.map((item) => item.key);
      const chartOptions = _.cloneDeep(this.chartOptions);
      chartOptions.tooltip.y.title.formatter = function formatter(seriesNum, value) {
        return titles[value.dataPointIndex] ? `${titles[value.dataPointIndex]} -` : '';
      };
      return chartOptions;
    },
  },
  methods: {
    render() {
      const el = document.querySelector(`#${this.id}`);
      if (el && !this.loading) {
        this.chartOptions.series[0].data = this.counts;
        const that = this;
        this.chartOptions.tooltip.y.title.formatter = function formatter(seriesNum, value) {
          return that.titles[value.dataPointIndex] ? `${that.titles[value.dataPointIndex]} -` : '';
        };
        const chart = new ApexCharts(el, this.chartOptions);
        chart.render();
      }
    },
  },
};
</script>
<style lang="scss">
.total-box.color1 {
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);
}
.total-box.color2 {
  background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
}
.total-box.color3 {
  background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);
}
.total-box.color4 {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%);
}
.card-count {
  font-family: Montserrat, Arial, sans-serif;
  font-size: calc(10px + 0.8vw);
}
.card-title {
  font-size: calc(8px + 0.5vw);
  font-family: Montserrat, Arial, sans-serif;
  text-transform: uppercase;
  white-space: pre-wrap;
  word-break: keep-all;
}
</style>
