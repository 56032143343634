<template>
  <admin-list />
</template>

<script>
import AdminList from '@/components/Admin/AdminList';
import siteCodeMixins from '@/mixins/siteCodeMixins';

export default {
  components: {
    AdminList,
  },
  mixins: [siteCodeMixins],
};
</script>
