<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
      <v-card>
        <v-card-title>Reason for edit</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
              :value="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          Please provide a reason for your edits:
          <v-text-field
            v-model="reason"
            v-validate="{ required: true }"
            name="reason"
            :error-messages="errors.collect('reason')"
            label="Editing reason"
            maxlength="255"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="clickUpdateHandler"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'SaveReasonPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      reason: null,
      saving: false,
      callback: null,
      dictionary: {
        attributes: {
          reason: 'Editing reason',
        },
      },
    };
  },
  methods: {
    show(saveCallback) {
      this.dialog = true;
      this.reason = null;
      this.callback = saveCallback;
    },
    hide() {
      this.dialog = false;
    },
    async clickUpdateHandler() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        await this.callback(this.reason);
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
