import api from './api';

export default {
  /**
   * Fetch list of documents.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminFetchList(params) {
    return api()
      .get('admin/documents', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of client documents.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientFetchList(params) {
    return api()
      .get('client/documents', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of common documents.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  fetchCommonList(params) {
    return api()
      .get('documents/common', { params })
      .then(response => response.data);
  },

  /**
   * Export documents to Excel.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminExportDocuments(params) {
    return api()
      .get('admin/documents/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Export client documents to Excel.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientExportDocuments(params) {
    return api()
      .get('client/documents/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Export documents to Excel.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  commonExportDocuments(params) {
    return api()
      .get('documents/common/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Delete document.
   *
   * @param {number} id - Identifier of document.
   *
   * @return Promise<any>
   */
  delete(id) {
    return api().delete(`/documents/${id}`);
  },

  /**
   * Load document.
   *
   * @param {number} id - Identifier of Document.
   *
   * @return Promise<Object>
   */
  load(id) {
    return api()
      .get(`/documents/${id}`)
      .then(response => response.data);
  },

  /**
   * Update document.
   *
   * @param {Number} id - Document identifier to update
   * @param {Object} params - Document data to update.
   *
   * @return Promise<Object>
   */
  update(id, params) {
    return api()
      .put(`/documents/${id}`, params)
      .then(response => response.data);
  },

  /**
   * Create new document.
   *
   * @param {Object} params - Document data.
   *
   * @return Promise<Object>
   */
  create(params) {
    return api().post('documents', params);
  },

  /**
   * Returns categories tree.
   *
   * @return Promise<Object>
   */
  adminCategories(params) {
    return api()
      .get('/admin/document-categories/', { params })
      .then(response => response.data);
  },

  /**
   * Returns client categories tree.
   *
   * @return Promise<Object>
   */
  clientCategories() {
    return api()
      .get('/client/document-categories/')
      .then(response => response.data);
  },

  /**
   * Returns common categories tree.
   *
   * @return Promise<Object>
   */
  commonCategories() {
    return api()
      .get('/document-categories/common')
      .then(response => response.data);
  },

  /**
   * Delete category.
   *
   * @param {number} id - Identifier of category.
   *
   * @return Promise<any>
   */
  deleteCategory(id) {
    return api().delete(`/document-categories/${id}`);
  },

  /**
   * Update Category.
   *
   * @param {Number} id - Category identifier to update
   * @param {Object} params - Category data to update.
   *
   * @return Promise<Object>
   */
  updateCategory(id, params) {
    return api()
      .put(`/document-categories/${id}`, params)
      .then(response => response.data);
  },

  /**
   * Create new document.
   *
   * @param {Object} params - Category data.
   *
   * @return Promise<Object>
   */
  createCategory(params) {
    return api().post('document-categories', params);
  },
};
