<template>
  <v-card
      elevation="3"
      class="ma-4"
      :loading="loading"
  >
    <v-toolbar
        flat
        color="white"
    >
      <h3>Hospital Info</h3>
      <v-spacer />
      <v-btn
          v-if="$can('edit', 'hospitalProfile')"
          color="error"
          @click="$emit('edit')"
      >
        <v-icon
            left
            dark
        >
          mdi-pencil-outline
        </v-icon>
        Edit
      </v-btn>
    </v-toolbar>

    <v-card-text v-if="hospital">
      <v-row>
        <v-col sm="4">
          <v-row>
            <v-col sm="12"><b>Name:</b> {{ hospital.name }}
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12"><b>Address:</b><br />
              {{ hospital.address }}<br />
              {{ hospital.city }}, {{ hospital.stateID }} {{ hospital.zip }}
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <b>Phone:</b> {{ hospital.phone }}
            </v-col>
          </v-row>

          <v-row v-if="hospital.fax">
            <v-col sm="12">
              <b>Fax:</b> {{ hospital.fax }}
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <b>Email:</b> {{ hospital.email }}
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="8">
          <div
              ref="googleMap"
              class="google-map"
              v-bind:style="{ height: '400px' }"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

/* eslint-disable no-undef */
export default {
  name: 'HospitalInfo',

  props: {
    hospital: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      map: null,
      marker: null,
    };
  },

  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    hospital() {
      if (this.hospital) {
        this.$nextTick(async () => {
          this.initMap();
        });
      }
    },
  },

  methods: {
    initMap() {
      const latLng = new google.maps.LatLng(this.hospital.latitude, this.hospital.longitude);

      this.map = new google.maps.Map(this.$refs.googleMap, {
        zoom: 15,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        title: this.hospital.name,
      });
    },
  },
};
</script>
<style lang="scss">
.google-map {
  width: 100%;
}
</style>
