<template>
  <v-card
    :loading="loading"
    class="transmission-history"
  >
    <v-toolbar flat>
      <v-icon
          dense
          class="mr-2"
      >mdi-swap-horizontal</v-icon>
      Transmission History
    </v-toolbar>
    <v-divider/>
    <v-card-text v-if="!loading && !items.length">No data to show</v-card-text>
    <v-card-text v-if="!loading && items.length">
      <template v-for="(item, index) in items">
        <div
            v-if="index < showOnly || showAll"
            v-bind:key="'item_' + index"
        >
          <v-toolbar
              class="name-toolbar mb-2"
              flat
              dense
          >
            <span class="name">
              #{{ item.transmissionNumber }}
            </span>
            <v-spacer />
            <span>{{ item.transmissionTime | datetimeToClientTimezone }}</span>
          </v-toolbar>
          <div class="mb-3">
            Incident was transmitted from <b>{{ item.sourceDispatchId }}</b> to
            <b>{{ item.targetDispatchId }}</b>
            <template v-if="item.transmissionMethod">
              via <b>{{ item.transmissionMethod }}</b>
            </template>
          </div>
          <v-divider />
        </div>
      </template>
      <div
          v-if="items.length > showOnly"
          class="text-center mt-3"
      >
        <v-btn
            v-if="showAll === false"
            text
            color="primary"
            small
            @click="showAll = true"
        >
          {{ items.length - showOnly }} More
        </v-btn>
        <v-btn
            v-if="showAll === true"
            text
            color="primary"
            small
            @click="showAll = false"
        >
          Hide
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TransmissionHistory',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showOnly: 3,
      showAll: false,
    };
  },
};
</script>

<style lang="scss">
.transmission-history {
  .v-card__text {
    color: black !important;
  }
  .name {
    font-weight: 500;
  }
  .name-toolbar {
    .v-toolbar__content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

</style>
