import {
  CLIENT,
  SEQUENCE_NUMBER,
  TEMPLATES,
  TEMPLATE,
  EXPORT_TYPE,
  FORMAT,
  INCLUDE_UPDATED,
  USE_CLIENT_EXPORT_SETTINGS,
  DATES,
  DHS,
  EXPORT_ID,
  STATUS,
  IDLE,
  INCIDENTS,
  TOTAL,
  PROCESSED,
  EXPORT_STATUS,
  EXPORT_PROGRESS,
  UPLOAD_ID,
  FILE_URL,
  UPLOAD_STATUS,
  UPLOAD_PROGRESS,
  EXPORT_ERROR,
  UPLOAD_ERROR,
  IS_STARTED_PROCESSING,
} from './constants';

export default {
  title: 'DHS / Billing Export',
  [EXPORT_ID]: null,
  [CLIENT]: null,
  [SEQUENCE_NUMBER]: [],
  [TEMPLATES]: [],
  [TEMPLATE]: null,
  [EXPORT_TYPE]: DHS,
  [FORMAT]: 'tab',
  [INCLUDE_UPDATED]: true,
  [USE_CLIENT_EXPORT_SETTINGS]: false,
  [DATES]: {
    from: null,
    to: null,
  },
  [EXPORT_ERROR]: null,
  showResults: false,
  [STATUS]: IDLE,
  [EXPORT_STATUS]: IDLE,
  [EXPORT_PROGRESS]: 0,
  [FILE_URL]: null,
  [INCIDENTS]: {
    [TOTAL]: 0,
    [PROCESSED]: 0,
    items: [],
    page: 1,
    perPage: 10,
  },
  [UPLOAD_ID]: null,
  [UPLOAD_STATUS]: IDLE,
  [UPLOAD_PROGRESS]: 0,
  [UPLOAD_ERROR]: null,
  [IS_STARTED_PROCESSING]: false,
};
