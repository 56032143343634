/* eslint func-names:0 */
export default function mapFilters(filters) {
  return filters.reduce((result, filter) => {
    const res = result;
    res[filter] = function (...args) {
      return this.$options.filters[filter](...args);
    };
    return res;
  }, {});
}
