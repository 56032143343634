<template>
  <view-resolver>
    <od-map-exports-admin slot="adminView" />
    <od-map-exports-client slot="clientView" />
  </view-resolver>
</template>
<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import OdMapExportsAdmin from './OdMapExportsAdmin';
import OdMapExportsClient from './OdMapExportsClient';

export default {
  components: {
    ViewResolver,
    OdMapExportsAdmin,
    OdMapExportsClient,
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('externalServiceExports.odMapHeader'));
  },
};
</script>
