<template>
  <v-toolbar
      flat
      color="transparent"
      class="dashboard-toolbar pr-0"
  >
    <v-spacer />

    <div
      v-if="selectedMetric === metricsEnum.COVID_INCIDENTS_BY_LOCATION"
      class="transported-checkboxes"
    >
      <v-row>
        <v-col>
          <v-checkbox
              v-model="transFilter.transported"
              label="Transported"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
              v-model="transFilter.notTransported"
              label="Not Transported"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>

    <v-select
        v-if="otherMetricsEnabled"
        v-model="metric"
        :items="metrics"
        label="Select"
        item-text="name"
        item-value="value"
        solo
        hide-details
        dense
        style="max-width: 480px"
        class="mr-3"
    />

    <v-btn
      class="mr-3"
      @click="otherMetricsEnabled = !otherMetricsEnabled"
    >{{ otherMetricsEnabled ? 'Basic' : 'Other' }} Metrics</v-btn>
    <date-range-picker-with-periods
        v-model="dateRangeComputed"
        :solo="true"
        :dense="true"
        :clearable="false"
        position="left"
    />
  </v-toolbar>
</template>

<script>
import moment from 'moment';
import METRICS from '@/enums/metrics';
import METRIC_NAMES from '@/enums/metricNames';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'Toolbar',
  components: {
    DateRangePickerWithPeriods,
  },
  props: {
    dateRange: {
      type: Array,
      required: false,
    },
    selectedMetric: {
      type: String,
      required: true,
    },
    otherMetrics: {
      type: Boolean,
      required: false,
    },
    transportedFilter: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dateRangeTmp: null,
      metrics: [
        { header: 'Incidents Info' },
        { name: METRIC_NAMES.incidentsByLocation, value: METRICS.INCIDENTS_BY_LOCATION },
        { name: METRIC_NAMES.covidIncidentsByLocation, value: METRICS.COVID_INCIDENTS_BY_LOCATION },
        { name: METRIC_NAMES.avgTimeResponse, value: METRICS.AVG_TIME_RESPONSE },
        {
          name: METRIC_NAMES.avgTimeResponseByDispatchId,
          value: METRICS.AVG_TIME_RESPONSE_BY_DISPATCH_ID,
        },
        { name: METRIC_NAMES.avgTimeResponseByVia, value: METRICS.AVG_TIME_RESPONSE_BY_VIA },
        { name: METRIC_NAMES.avgTimeOnScene, value: METRICS.AVG_TIME_ON_SCENE },
        {
          name: METRIC_NAMES.avgTimeByReceivingFacility,
          value: METRICS.AVG_TIME_BY_RECEIVING_FACILITY,
        },
        { name: METRIC_NAMES.avgTimeTotal, value: METRICS.AVG_TIME_TOTAL },
        { name: METRIC_NAMES.avgTimeByVia, value: METRICS.AVG_TIME_BY_VIA },
        { name: METRIC_NAMES.avgTimeByDispatchId, value: METRICS.AVG_TIME_BY_DISPATCH_ID },
        { name: METRIC_NAMES.totalByRunType, value: METRICS.TOTAL_BY_RUN_TYPE },
        { divider: true },
        { header: 'Transport information' },
        { name: METRIC_NAMES.contact, value: METRICS.CONTACT },
        { name: METRIC_NAMES.protocol, value: METRICS.PROTOCOL },
        { name: METRIC_NAMES.receivingFacility, value: METRICS.RECEIVING_FACILITY },
        { name: METRIC_NAMES.ama, value: METRICS.AMA },
        { name: METRIC_NAMES.transportVia, value: METRICS.TRANSPORT_VIA },
        { name: METRIC_NAMES.transportedTo, value: METRICS.TRANSPORTED_TO },
        { name: METRIC_NAMES.transportReason, value: METRICS.TRANSPORT_REASON },
        { name: METRIC_NAMES.providerImpressionsByVia, value: METRICS.PROVIDER_IMPRESSIONS_BY_VIA },
        { divider: true },
        { header: 'Patient Assessment' },
        { name: METRIC_NAMES.sex, value: METRICS.SEX },
        { name: METRIC_NAMES.averageAge, value: METRICS.AVERAGE_AGE },
        { name: METRIC_NAMES.averageWeight, value: METRICS.AVERAGE_WEIGHT },
        { name: METRIC_NAMES.distressLevel, value: METRICS.DISTRESS_LEVEL },
        { name: METRIC_NAMES.complaint, value: METRICS.COMPLAINT },
        { name: METRIC_NAMES.mechanismOfInjury, value: METRICS.MECHANISM_OF_INJURY },
        { name: METRIC_NAMES.specialCircumstances, value: METRICS.SPECIAL_CIRCUMSTANCES },
        { name: METRIC_NAMES.providerImpression, value: METRICS.PROVIDER_IMPRESSION },
        { divider: true },
        { header: 'QI/QA' },
        { name: METRIC_NAMES.falloutCode, value: METRICS.FALLOUT_CODE },
        { name: METRIC_NAMES.percentageFalloutCode, value: METRICS.PERCENTAGE_FALLOUT_CODE },
        { divider: true },
        { header: 'APOT (Ambulance Patient Offload Time)' },
        { name: METRIC_NAMES.apot1, value: METRICS.APOT_1 },
        { name: METRIC_NAMES.apot2, value: METRICS.APOT_2 },
        { name: METRIC_NAMES.apot1ByUnits, value: METRICS.APOT_1_BY_UNITS },
        { name: METRIC_NAMES.apot2ByUnits, value: METRICS.APOT_2_BY_UNITS },
        { name: METRIC_NAMES.apotDelayExceeding60, value: METRICS.APOT_DELAY_EXCEEDING_60 },
        { name: METRIC_NAMES.apotDelayExceeding30, value: METRICS.APOT_DELAY_EXCEEDING_30 },
        { name: METRIC_NAMES.apotDelayExceeding20, value: METRICS.APOT_DELAY_EXCEEDING_20 },
        { name: METRIC_NAMES.apotDelayExceeding10, value: METRICS.APOT_DELAY_EXCEEDING_10 },
      ],
      metricsEnum: METRICS,
    };
  },

  computed: {
    otherMetricsEnabled: {
      get() {
        return this.otherMetrics;
      },
      set(value) {
        this.$emit('update:otherMetrics', value);
      },
    },
    metric: {
      get() {
        return this.selectedMetric;
      },
      set(value) {
        this.$emit('update:selectedMetric', value);
      },
    },
    transFilter: {
      get() {
        return this.transportedFilter;
      },
      set(value) {
        this.$emit('update:transportedFilter', value);
      },
    },
    dateRangeComputed: {
      get() {
        return this.dateRange;
      },
      set(value) {
        this.$emit('updateDateRange', value);
      },
    },
    dateRangeText() {
      if (!this.dateRange) {
        return null;
      }
      let date = moment.parseZone(this.dateRange[0]).format('MM/DD/YYYY');
      if (this.dateRange.length === 2) {
        const dateTo = moment.parseZone(this.dateRange[1]).format('MM/DD/YYYY');
        date = `${date} - ${dateTo}`;
      }
      return date;
    },
  },
  watch: {
    transFilter: {
      handler(newVal) {
        this.transFilter = newVal;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.dashboard-toolbar {
  z-index: 10;
}
.dashboard-toolbar .v-toolbar__content {
  padding-right: 0px;
}
.transported-checkboxes {
  width: 340px
}
</style>
