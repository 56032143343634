<template>
  <v-data-table
    :headers="headers"
    :items="filteredItems"
    :height="tableHeight"
    :loading="loading"
    sort-by="device"
    fixed-header
    class="header-nowrap zoll-devices-table"
    no-data-text="No data available"
    no-results-text="No data available"
  >
    <template v-slot:item.actions="{ item }">
      <div class="table-actions">
        <v-btn
            class="float-right mr-4"
            icon
            title="Delete"
            @click="$emit('delete', item)"
        >
          <v-icon color="grey darken-2">delete</v-icon>
        </v-btn>
        <v-btn
            class="float-right mr-4"
            icon
            title="Edit"
            @click="$emit('edit', item)"
        >
          <v-icon color="grey darken-2">edit</v-icon>
        </v-btn>
      </div>
    </template>

  </v-data-table>
</template>

<script>
import _ from 'lodash';
import fixedHeader from '@/mixins/fixedHeader';
import zollDevices from '@/api/zollDevices';

export default {
  name: 'ZollDevicesTable',

  mixins: [fixedHeader],

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      debounce: null,
      items: [],
      loading: false,
      additionalIndent: -20,
      headers: [
        {
          text: 'Device Serial Number',
          value: 'serialNumber',
        },
        {
          text: 'Friendly Name',
          value: 'device',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      let filteredItems = this.items;
      if (this.filters.search) {
        const patt = new RegExp(this.filters.search, 'i');
        filteredItems = filteredItems.filter(
          item => patt.test(item.serialNumber) || patt.test(item.device),
        );
      }
      return filteredItems;
    },
  },
  mounted() {
    this.fetchDataDebounce();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.items = await zollDevices.getDevices();
      } finally {
        this.loading = false;
      }
    },

    fetchDataDebounce() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.fetchData();
        }, 300);
      }
      this.debounce();
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}
</style>

<style lang="scss">
.zoll-devices-table {
  .table-actions {
    visibility: hidden;
  }
  tr:hover .table-actions {
    visibility: visible;
  }
}
</style>
