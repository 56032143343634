<template>
  <v-dialog
      v-model="showMessagePopup"
      max-width="400"
  >
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text v-if="!textIsHtml">{{ text }}</v-card-text>
      <v-card-text
          v-else
          v-html="text"
      />
      <slot/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="primary"
            text
            @click.native="close()"
        >{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ExportErrorMessage',
  data() {
    return {
      showMessagePopup: false,
      title: null,
      text: null,
      textIsHtml: false,
    };
  },
  methods: {
    /**
     * Show message popup.
     *
     * @param {string} title - Title text of dialog
     * @param {string} text - Message in  popup
     * @param {boolean} textIsHtml - If text will be html
     */
    show(title, text, textIsHtml = false) {
      this.showMessagePopup = true;
      this.title = title;
      this.text = text;
      this.textIsHtml = textIsHtml;
    },

    /**
     * Close message.
     */
    close() {
      this.showMessagePopup = false;
    },
  },
};
</script>
