import incidents from '@/api/incidents';
import presetManager from '@/api/presetManager';
import PRESET_TYPES from '@/enums/presetTypes';
import getSearchParams from '@/utils/prepareSearchParams';
import {
  SET_EXPORT_FIELDS, SET_ITEMS, SET_SEARCH_FIELDS, SET_TABLE_FIELDS,
  SET_SEARCH_PRESET, SET_COLUMN_PRESET, SET_EXPORT_PRESET,
} from '@/store/Incidents/mutations';
import fileDownload from 'js-file-download';

export const CREATE = 'CREATE';
export const LOAD_INCIDENTS = 'LOAD_INCIDENTS';
export const LOAD_TABLE_FIELDS = 'LOAD_TABLE_FIELDS';
export const LOAD_EXPORT_FIELDS = 'LOAD_EXPORT_FIELDS';
export const LOAD_SEARCH_FIELDS = 'LOAD_SEARCH_FIELDS';
export const LOAD_SEARCH_PRESETS = 'LOAD_SEARCH_PRESETS';
export const LOAD_COLUMN_PRESETS = 'LOAD_COLUMN_PRESETS';
export const LOAD_EXPORT_PRESETS = 'LOAD_EXPORT_PRESETS';
export const LOAD_TRANSACTION_LOGS = 'LOAD_TRANSACTION_LOGS';
export const EXPORT_INCIDENTS = 'EXPORT_INCIDENTS';
export const EXPORT_INCIDENTS_TO_EMAIL = 'EXPORT_INCIDENTS_TO_EMAIL';

export default {
  /**
   * Commit duplicated incident as new sequence number.
   *
   * @param {Object} data - Data to commit.
   *
   * @return Promise
   */
  // eslint-disable-next-line no-empty-pattern
  [CREATE]: ({}, params) => incidents.createByJson(params),

  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Entity state.
   * @param {string} type - Type of incidents to search
   *
   * @return {Promise}
   */
  [LOAD_INCIDENTS]: async ({ commit, state }, type) => {
    const {
      selectedFields,
    } = state;
    const params = getSearchParams(state, type, selectedFields);
    let results = {
      results: [],
      pagination: {
        total: 0,
      },
    };
    if (params.filters.length) {
      results = await incidents.fetchList(params);
    }

    commit(SET_ITEMS, {
      type,
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Export incidents
   *
   * @param {Object} state - Entity state.
   * @param {Object} payload - Payload for action
   *
   * @return {Promise}
   */
  [EXPORT_INCIDENTS]: async ({ state }, payload) => {
    const params = getSearchParams(state, payload.type, payload.selectedFields);
    const response = await incidents.exportSearchResults(params);
    fileDownload(response.data, 'selective_export.xlsx');
  },

  /**
   * Export incidents to email
   *
   * @param {Object} state - Entity state.
   * @param {Object} payload - Payload for action
   *
   * @return {Promise}
   */
  [EXPORT_INCIDENTS_TO_EMAIL]: async ({ state }, payload) => {
    const params = getSearchParams(state, payload.type, payload.selectedFields);
    await incidents.exportSearchResultsToEmail(params);
  },

  /**
   * Load incident fields list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_TABLE_FIELDS]: async ({ commit }) => {
    const results = await incidents.fetchTableFields();
    commit(SET_TABLE_FIELDS, results);
  },

  /**
   * Load incidents field list for export.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_EXPORT_FIELDS]: async ({ commit }) => {
    const results = await incidents.fetchExportFields();
    commit(SET_EXPORT_FIELDS, results);
  },

  /**
   * Load incidents field list for search.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_SEARCH_FIELDS]: async ({ commit }) => {
    const results = await incidents.fetchSearchFields();
    commit(SET_SEARCH_FIELDS, results);
  },

  /**
   * Load search presets.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_SEARCH_PRESETS]: async ({ commit }) => {
    const results = await presetManager.fetchList({
      type: PRESET_TYPES.SEARCH,
    });
    commit(SET_SEARCH_PRESET, results);
  },

  /**
   * Load column presets.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_COLUMN_PRESETS]: async ({ commit }) => {
    const results = await presetManager.fetchList({
      type: PRESET_TYPES.COLUMN,
    });
    commit(SET_COLUMN_PRESET, results);
  },

  /**
   * Load Export presets.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - state.
   */
  [LOAD_EXPORT_PRESETS]: async ({ commit }) => {
    const results = await presetManager.fetchList({
      type: PRESET_TYPES.EXPORT,
    });
    commit(SET_EXPORT_PRESET, results);
  },
};
