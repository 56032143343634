<template>
  <v-toolbar
    flat
    height="auto"
  >
    <v-text-field
        v-model="localFilters.search"
        :label="$t('clients.filters.search')"
        style="max-width: 230px;"
        class="mr-4"
        single-line
        clearable
    />
    <v-select
        v-model="localFilters.type"
        :items="typesItems"
        :label="$t('clients.filters.type')"
        style="max-width: 230px;"
        class="mr-4"
        item-value="value"
        item-text="text"
        single-line
        clearable
    />
    <v-select
        v-model="filters.status"
        :items="statuses"
        :label="$t('clients.filters.status')"
        style="max-width: 200px;"
        class="mr-4"
        item-value="status"
        item-text="name"
        single-line
    />
    <v-autocomplete
        v-model="localFilters.mapTypeId"
        :items="sortedProviders"
        :label="$t('clients.filters.mapToType')"
        :menu-props="{'minWidth': 465, 'maxWidth': 465}"
        class="mr-4"
        single-line
        clearable
    />
    <v-spacer/>
    <v-btn
      color="primary"
      width="88"
      class="mb-4"
      @click="$emit('addClient')"
    >
      {{ $t('buttons.add') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { LOAD_PROVIDERS } from '@/store/Clients/actions';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('clients');

export default {
  name: 'ClientsToolbar',

  props: {
    localFilters: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      typesItems: [
        {
          value: CLIENT_SITE_TYPES.DEFAULT,
          text: 'Default',
        },
        {
          value: CLIENT_SITE_TYPES.CE_ENTRY,
          text: 'Continuing Education',
        },
      ],
      statuses: [
        {
          status: 'all',
          name: this.$t('clients.filters.statuses.all'),
        },
        {
          status: 'active',
          name: this.$t('clients.filters.statuses.active'),
        },
        {
          status: 'deleted',
          name: this.$t('clients.filters.statuses.deleted'),
        },
      ],
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),
    ...mapGetters(['sortedProviders']),
  },

  watch: {
    localFilters: {
      handler() {
        this.$emit('localFiltersUpdate', this.localFilters);
      },
      deep: true,
    },
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },

  mounted() {
    this.loadProviders();
  },

  methods: {
    ...mapActions({
      loadProviders: LOAD_PROVIDERS,
    }),
  },
};
</script>
