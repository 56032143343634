/* eslint func-names:0 */
export default function checkWebpFeature(callback) {
  const lossyImg = 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
  const img = new Image();
  img.onload = function () {
    const result = (img.width > 0) && (img.height > 0);
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = `data:image/webp;base64,${lossyImg}`;
}
