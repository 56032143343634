<template>
  <v-list-item-action>
    <span>
      <v-btn
          icon
          text
          title="Reject CE Entry"
          :loading="isLoading && isDisabledApprove"
          :disabled="isDisabledReject"
          @click="rejectEntry(item)"
      >
        <v-icon color="red lighten-4">mdi-close-octagon</v-icon>
      </v-btn>
      <v-btn
          icon
          test
          title="Approve CE Entry"
          :loading="isLoading && isDisabledReject"
          :disabled="isDisabledApprove"
          @click="approveEntry(item)"
      >
        <v-icon color="red lighten-4">mdi-check-decagram</v-icon>
      </v-btn>
      <confirmation ref="confirmationPopup" />
      <confirmation ref="rejectConfirmationPopup">
        <v-card-text>
          <v-textarea
              v-model="rejectComment"
              label="Rejection reason"
              outlined
              rows="2"
              hide-details
          />
        </v-card-text>
      </confirmation>
    </span>
  </v-list-item-action>
</template>

<script>
import ceHours from '@/api/ceHours';
import Confirmation from '@/components/Confirmation';

export default {
  name: 'ApproveOrRejectCeEntry',
  components: { Confirmation },

  props: {
    item: {
      type: Object,
      requested: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isDisabledApprove: false,
      isDisabledReject: false,
      rejectComment: null,
    };
  },

  methods: {
    approveEntry(item) {
      this.isLoading = true;
      this.isDisabledReject = true;
      this.$refs.confirmationPopup.showConfirm(
        'Approvement',
        `Do you really want to approve the entry "${item.payload.ceCourseTitle}"?`,
        async () => {
          await ceHours.approveEntry(item.payload.ceId);
          this.$emit('updateNotificationList', item);
          this.throwGlobalEvent();
          this.isLoading = false;
          this.isDisabledReject = false;
        },
        () => {
          this.isLoading = false;
          this.isDisabledReject = false;
        },
      );
    },

    rejectEntry(item) {
      this.isLoading = true;
      this.isDisabledApprove = true;
      this.$refs.rejectConfirmationPopup.showConfirm(
        'Rejection',
        `Do you really want to reject the entry "${item.payload.ceCourseTitle}"?`,
        async () => {
          await ceHours.rejectEntry(item.payload.ceId, { comment: this.rejectComment });
          this.$emit('updateNotificationList', item);
          this.throwGlobalEvent();
          this.isLoading = false;
          this.isDisabledApprove = false;
        },
        () => {
          this.isLoading = false;
          this.isDisabledApprove = false;
        },
      );
    },

    throwGlobalEvent() {
      const event = new Event('approveRejectEntry');
      document.dispatchEvent(event);
    },
  },
};
</script>
