import axios from 'axios';
import apiUrl from './apiUrl';
import sitecode from '../sitecode';

const apiv4 = axios.create({
  baseURL: apiUrl(sitecode, 'v4'),
  withCredentials: true,
});

export default apiv4;
