<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
        scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
        >
          Manage Presets
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Manage Presets</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px;">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <v-hover
                    v-for="(preset, index) in presets"
                    :key="'preset_' + index"
                >
                  <tr slot-scope="{ hover }">
                    <td>
                      <div v-if="!editMode[preset.id]">
                        <span v-if="preset.isCollaborate">
                          {{ preset.name + ' (Collaborated)' }}
                        </span>
                        <span v-else>{{ preset.name }}</span>
                      </div>
                      <v-text-field
                        v-if="editMode[preset.id]"
                        v-model="editClone[preset.id].name"
                        dense
                        hide-details
                      />
                    </td>
                    <td style="width: 200px">
                      <div v-if="editMode[preset.id]">
                        <v-btn
                          icon
                          :disabled="!editClone[preset.id].name"
                          :loading="saving.indexOf(preset.id) !== -1"
                          @click="savePreset(preset.id, index)"
                        >
                          <v-icon color="green darken-1">mdi-check-outline</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon
                              color="red lighten-4"
                              @click.stop.prevent="disableEditMode(preset.id)"
                          >mdi-cancel</v-icon>
                        </v-btn>
                      </div>
                      <div v-if="hover && !editMode[preset.id]">
                        <v-btn
                          icon
                          @click="share(preset)"
                        >
                          <v-icon>mdi-share-variant</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            @click="edit(preset)"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            @click="deletePreset(preset, index)"
                        >
                          <v-icon color="red lighten-4">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </v-hover>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="primary"
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"/>
    <share-preset-popup ref="sharePreset"/>
  </div>
</template>
<script>
import _ from 'lodash';
import Confirmation from '@/components/Confirmation';
import presetManager from '@/api/presetManager';
import SharePresetPopup from '@/components/Incidents/Presets/SharePresetPopup';

export default {
  name: 'ManagePopup',
  components: {
    Confirmation,
    SharePresetPopup,
  },
  props: {
    presets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      editing: [],
      saving: [],
      editClone: {},
    };
  },
  computed: {
    /**
     * Get list of preset items in edit mode.
     *
     * @return {Object}
     */
    editMode() {
      const items = {};
      this.presets.forEach(preset => {
        items[preset.id] = this.editing.indexOf(preset.id) !== -1;
      });
      return items;
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.editing = [];
        this.saving = [];
        this.editClone = {};
      }
    },
  },
  methods: {
    /**
     * Delete preset
     *
     * @param {Object} preset - Preset object
     * @param {Number} index - Index of preset in list
     */
    deletePreset(preset, index) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        `Do you really want to delete preset "${preset.name}"?`,
        async () => {
          await presetManager.delete(preset.id);
          this.$emit('deleted', index);
        },
      );
    },

    /**
     * Enable edit preset mode.
     *
     * @param {Object} preset - Preset object
     */
    edit(preset) {
      this.editing.push(preset.id);
      this.editClone[preset.id] = _.cloneDeep(preset);
    },

    /**
     * Disable edit preset mode.
     *
     * @param {Number} presetId - Preset identifier
     */
    disableEditMode(presetId) {
      const index = this.editing.indexOf(presetId);
      if (index !== -1) {
        this.editing.splice(index, 1);
      }
    },

    /**
     * Save preset.
     *
     * @param {Number} presetId - Preset identifier
     * @param {Number} index - Index of preset in list
     */
    async savePreset(presetId, index) {
      const updatedPreset = this.editClone[presetId];
      this.saving.push(presetId);
      await presetManager.update(updatedPreset);
      this.disableEditMode(presetId);
      this.saving.splice(this.saving.indexOf(presetId), 1);
      this.$emit('updated', index, updatedPreset);
    },

    /**
     * Share preset.
     *
     * @param {Object} preset - Preset object
     */
    share(preset) {
      this.$refs.sharePreset.showPopup(preset.id);
    },
  },
};
</script>
