<template>
  <div>
    <v-expansion-panels
        v-model="panel"
        flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-progress-linear
              :value="exportProgress"
              :color="!incidentsFailedList.length ? 'green' : 'error'"
              class="progress-text-center"
              height="20"
          >
            <strong>{{ exportProgress }}%</strong>
            <strong
                v-if="incidentsFailedList.length"
                class="ml-1"
            >(Failed: {{ incidentsFailedList.length }} /
              Processed: {{ incidentsProcessedList.length }})</strong>
          </v-progress-linear>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row no-gutters>
              <v-col>
                <v-treeview
                    :items="resultMenu"
                    :open="openedTree"
                    :active.sync="treeValue"
                    activatable
                    open-on-click
                    transition
                ></v-treeview>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col
                :class="[$style.scrollable]"
                v-bind:style="{ maxHeight: (windowHeight - 350) + 'px' }"
              >
                <v-list>
                  <v-list-item-group>
                    <template v-for="incident in incidentsSelectedList">
                      <v-list-item :key="incident.sequenceNumber">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ incident.sequenceNumber }} | Incident Number:
                            {{ incident.incidentNumber }} | Incident Date:
                            {{ incident.incidentDateTime | date }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                              v-if="incident.status === 'invalid'"
                              :class="[$style['invalid-xml']]"
                          >
                            Did not pass validation
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                              v-if="incident.status === 'error'"
                              :class="[$style['export-error']]"
                          >
                            {{ incident.message }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="incident.status === 'invalid'">
                          <v-btn
                              icon
                              @click="validate(incident)"
                          >
                            <v-icon color="primary">mdi-information</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-if="urlToDownload">
      <div :class="$style.container">
        <div :class="[$style.controls, 'mr-5']">
          <v-btn
              :href="urlToDownload"
              target="_blank"
              color="primary"
          >
            <v-icon :class="$style.icon">get_app</v-icon>
            Download
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'ExportResults',

  props: {
    exportProgress: {
      type: Number,
      required: true,
    },
    incidentsFailedList: {
      type: Array,
      required: true,
    },
    incidentsProcessedList: {
      type: Array,
      required: true,
    },
    incidentsProcessingList: {
      type: Array,
      required: true,
    },
    urlToDownload: {
      type: String,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      panel: null,
      openedTree: ['processed'],
      treeValue: ['processed'],
    };
  },

  computed: {
    /**
     * Get list of result menu items
     *
     * @return {Array}
     */
    resultMenu() {
      return [
        {
          id: 'processing',
          name: `Processing (${this.incidentsProcessingList.length})`,
        },
        {
          id: 'processed',
          name: `Processed (${this.incidentsProcessedList.length})`,
        },
        {
          id: 'failed',
          name: `Failed (${this.incidentsFailedList.length})`,
        },
      ];
    },

    /**
     * Get list of incidents depends on chosen type in menu.
     *
     * @return {Array}
     */
    incidentsSelectedList() {
      switch (true) {
        case this.treeValue.includes('processing'):
          return this.incidentsProcessingList;
        case this.treeValue.includes('processed'):
          return this.incidentsProcessedList;
        case this.treeValue.includes('failed'):
          return this.incidentsFailedList;
        default:
          return [];
      }
    },
  },

  methods: {
    validate(incident) {
      this.$emit('validate', incident.sequenceNumber, incident.clientId);
    },
  },
};
</script>

<style lang="scss" module>
.scrollable {
  overflow-y: scroll;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 24px;
}
.controls {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
.invalid-xml {
  color: #FF8C00FF !important;
}
.export-error {
  color: red !important;
}
.icon {
  margin-right: 8px;
}
</style>
