export const TEMPLATES_SELECT_OPTIONS = 'TEMPLATES_SELECT_OPTIONS';

export default {
  /**
   * Get list of options for templates dropdown.
   *
   * @param state - Template's state.
   */
  [TEMPLATES_SELECT_OPTIONS]: state => {
    const options = [];

    state.allTemplates.forEach(template => {
      const option = {
        text: template.name,
        value: template.templateId,
      };

      options.push(option);
    });
    return options;
  },
};
