<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
      @close="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          Verify Email
        </v-card-title>
        <v-card-text
          class="pt-0"
          style="min-height: 122px"
        >
          <v-alert
              v-model="errorAlert"
              type="error"
              outlined
              dismissible
              transition="scale-transition"
              class="mb-4"
          >
            <div v-html="errorMessage" />
          </v-alert>
          <div v-show="step === 'setEmail'">
            <v-text-field
                v-model="email"
                v-validate="{ required: step === 'setEmail', email: true }"
                :error-messages="errors.collect('email')"
                prepend-icon="mdi-at"
                name="email"
                label="Your email"
                type="text"
                maxlength="40"
            />

            <div
                v-if="user.email && showSuggestion"
                class="mt-5 font-italic"
            >
              <b>{{ user.email }}</b> - Is this your email?
              <a @click="setCurrentEmail">Yes</a> / <a @click="hideSuggestion">No</a>
            </div>
          </div>
          <div v-show="step === 'verify'">
            <v-text-field
                v-model="code"
                v-validate="{ required: step === 'verify' }"
                :error-messages="errors.collect('code')"
                prepend-icon="lock"
                name="code"
                label="Email Verification Code"
                type="text"
                maxlength="6"
            />
            <i>Please enter the Email Verification code that was sent to your email.</i>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-btn
              text
              @click="close"
          >Cancel</v-btn>
          <v-spacer/>
          <v-btn
              v-if="step === 'setEmail'"
              :loading="loading"
              color="primary"
              text
              @click="sendEmailToVerification"
          >Confirm</v-btn>

          <v-btn
              v-if="step === 'verify'"
              :loading="loading"
              color="primary"
              text
              @click="verifyCode"
          >Verify</v-btn>

        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import profile from '@/api/profile';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { SET_USER } from '@/store/mutations';

export default {
  name: 'VerifyEmailDialog',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      email: null,
      showSuggestion: true,
      step: 'setEmail',
      code: null,
      token: null,
      dictionary: {
        attributes: {
          email: 'Email',
          code: 'Verification Code',
        },
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapMutations({
      setUser: SET_USER,
    }),
    show() {
      this.dialog = true;
      this.loading = false;
      this.email = null;
      this.showSuggestion = true;
      this.step = 'setEmail';
      this.token = null;
      this.code = null;
      this.errors.clear();
      this.$validator.reset();
    },

    /**
     * Close dialog
     */
    close() {
      this.dialog = false;
    },

    setCurrentEmail() {
      this.email = this.user.email;
      this.showSuggestion = false;
    },

    hideSuggestion() {
      this.showSuggestion = false;
    },

    /**
     * Sets email request and sends verification code.
     */
    async sendEmailToVerification() {
      this.hideError();
      if (!await this.$validator.validateAll()) {
        return;
      }
      try {
        this.loading = true;
        const response = await profile.newEmailRequest({
          email: this.email,
        });
        this.token = response.token;
        this.step = 'verify';
        this.$nextTick(async () => {
          await this.$validator.reset();
        });
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Verify code from SMS.
     */
    async verifyCode() {
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.loading = true;
      try {
        await profile.verifyEmailCode({
          token: this.token,
          code: this.code,
        });
        const user = await profile.fetchUser();
        this.setUser(user);
        this.$emit('emailUpdated', this.email);
        this.close();
      } catch (error) {
        this.parseErrorResponse(error.response);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
