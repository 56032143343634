import api from './api';

export default {
  /**
   * Fetch list of audit log.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  fetchList(params) {
    return api()
      .get('audit-log', { params })
      .then(response => response.data);
  },

  /**
   * Returns list of audit log registered methods.
   *
   * @return Promise<Object>
   */
  fetchMethods() {
    return api()
      .get('audit-log/endpoints')
      .then(response => response.data);
  },

  /**
   * Fetch list of incident transaction logs.
   *
   * @param {Object} params
   *
   * @return Promise<Object>
   */
  fetchIncidentMainAuditLogs(params) {
    return api()
      .get('/audit/incident-main-audit', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of user authorization logs.
   *
   * @param {Object} params
   *
   * @return Promise<Object>
   */
  fetchAuthorizationLogs(params) {
    return api()
      .get('/audit/authorization-logs', { params })
      .then(response => response.data);
  },
};
