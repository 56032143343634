<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text>
          <div class="pt-3">
            Loading...
            <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <span v-if="isNew">Create monitor</span>
          <span v-else>Edit monitor</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
              v-model="monitor.name"
              v-validate="{ required: true }"
              :error-messages="errors.collect('name')"
              name="name"
              maxlength="255"
              label="Monitor Name"
          />

          <v-autocomplete
              v-model="monitor.userIds"
              v-validate="{ required: true }"
              name="recipients"
              :items="recipientsList"
              :error-messages="errors.collect('recipients')"
              label="Choose Recipients"
              placeholder="Who shall be notified?"
              chips
              deletable-chips
              multiple
              clearable
          />
          <v-select
              v-model="monitor.frequency"
              :items="frequencies"
              :hint="'Note: time is 0600 (PST) for all options. Query period: ' + getPeriod()"
              label="Notification Frequency"
              persistent-hint
              item-value="value"
              item-text="text"
              single-line
          ></v-select>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
              v-if="!isNew && hasDeleteBtn"
              text
              color="primary"
              @click="deleteMonitor"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="validateAndSend"
          >
            <template v-if="isNew">Create</template>
            <template v-else>Save</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup" />
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { SHOW_SNACKBAR } from '@/store/actions';
import { mapActions } from 'vuex';
import users from '@/api/users';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import MONITOR_FREQUENCIES from '@/enums/monitorFrequencies';
import monitors from '@/api/monitors';
import Confirmation from '@/components/Confirmation';

export default {
  name: 'EditMonitorPopup',
  components: { Confirmation },
  mixins: [errorAlertMixins],
  props: {
    hasDeleteBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      isNew: true,
      loading: false,
      saving: false,
      recipients: [],
      monitor: {},
      blankMonitor: {
        name: null,
        frequency: MONITOR_FREQUENCIES.DAILY,
        conditions: [],
        userIds: [],
      },
      frequencies: [
        { value: MONITOR_FREQUENCIES.DAILY, text: 'Every Day' },
        { value: MONITOR_FREQUENCIES.WEEKLY, text: 'Every Monday' },
        { value: MONITOR_FREQUENCIES.MONTHLY, text: 'First of Every Month' },
        { value: MONITOR_FREQUENCIES.HALF_MONTH, text: '15th of Every Month' },
      ],
      dictionary: {
        attributes: {
          name: 'Monitor Name',
          frequency: 'Frequency',
        },
      },
    };
  },
  computed: {
    recipientsList() {
      return this.recipients.map(item => ({
        text: `${item.firstName} ${item.lastname}`,
        value: item.registryId,
      }));
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    async show() {
      this.hideError();
      this.$validator.reset();
      this.monitor = _.clone(this.blankMonitor);
      this.dialog = true;
      this.isNew = true;
      if (!this.recipients.length) {
        this.loading = true;
        try {
          const data = await users.getClientUsers();
          this.recipients = data.results;
        } finally {
          this.loading = false;
        }
      }
    },

    async create(conditions) {
      this.show();
      this.monitor.conditions = conditions;
    },

    async edit(monitor) {
      this.show();
      this.monitor = _.cloneDeep(monitor);
      this.isNew = false;
      delete this.monitor.users;
      this.monitor.userIds = [];
      if (monitor.users) {
        monitor.users.forEach(item => {
          this.monitor.userIds.push(item.registryId);
        });
      }
    },

    async validateAndSend() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        if (this.isNew) {
          await monitors.create(this.monitor);
          this.showSnackbar('Monitor was created successfully');
        } else {
          await monitors.save(this.monitor.id, this.monitor);
        }
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
      this.$emit('saved');
      this.dialog = false;
    },

    async deleteMonitor() {
      this.$refs.confirmationPopup.showConfirm(
        'Delete monitor?',
        'Are you sure that you want to delete this monitor?',
        async () => {
          await monitors.delete(this.monitor.id);
          this.$emit('deleted');
          this.dialog = false;
        },
      );
    },

    getPeriod() {
      if (this.monitor.frequency === MONITOR_FREQUENCIES.DAILY) {
        return moment().format('MM/DD/YYYY');
      }
      if (this.monitor.frequency === MONITOR_FREQUENCIES.WEEKLY) {
        return `${moment().startOf('week').add(1, 'days').format('MM/DD/YYYY')} - ${
          moment().endOf('week').add(1, 'days').format('MM/DD/YYYY')}`;
      }
      if (this.monitor.frequency === MONITOR_FREQUENCIES.MONTHLY) {
        return `${moment().startOf('month').format('MM/DD/YYYY')} - ${
          moment().endOf('month').format('MM/DD/YYYY')}`;
      }
      if (this.monitor.frequency === MONITOR_FREQUENCIES.HALF_MONTH) {
        return `${moment().startOf('month').format('MM/DD/YYYY')} - ${
          moment().startOf('month').add(13, 'days').format('MM/DD/YYYY')}`;
      }
      return 'none';
    },
  },
};
</script>
