<template>
  <div class="ml-5">
    <span
      v-if="!Object.keys(errorsData).length"
    >No errors</span>
    <ul v-else>
      <li
        v-for="(messages, section) in errorsData"
        v-bind:key="section"
        class="mb-5"
      >
        <b>{{ section || 'General' }}</b>
        <ul>
          <li
            v-for="(message, index) in messages"
            v-bind:key="index + 'message'"
            class="mb-3"
          >
            {{ message }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'ErrorList',

  props: {
    errorsData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>

</style>
