<template>
  <v-container fluid>
    <v-toolbar
      flat
      dense
    >
      <v-layout
        row
        wrap
      >
        <v-toolbar-items>
          <v-text-field
              v-model="seqNum"
              name="sequenceNumber"
              label="Sequence Numbers"
              hide-details
              class="mr-5"
              style="max-width: 800px"
          />
        </v-toolbar-items>
      </v-layout>
      <v-spacer/>
      <v-btn
          class="float-right mt-5"
          color="primary"
          :loading="loading"
          :disabled="loading"
          style="margin-top: 0 !important;"
          @click="search"
      >
        <v-icon>search</v-icon>
        Search
      </v-btn>
    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  name: 'TransmittedSearchForm',
  mounted() {
    this.seqNum = this.sequenceNumber;
  },
  data() {
    return {
      seqNum: null,
      loading: false,
    };
  },
  props: {
    sequenceNumber: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    /**
     * Search by sequence number.
     */
    search() {
      this.loading = true;
      this.$emit('search', this.seqNum);
    },

    /**
     * Stop loading for button.
     */
    stopLoading() {
      this.loading = false;
    },
  },
};
</script>
