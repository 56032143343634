export default {
  header: 'Flagged Users',
  tableHeaders: {
    sequenceNumber: 'Sequence Number',
    flagCreated: 'Flag Created',
    responded: 'Responded',
    userFlagged: 'User Flagged',
    lastLogin: 'Last Login',
    flagMessage: 'Flag Message',
    message: 'User Message',
    status: 'Status',
    sentBy: 'Flag Sent By',
  },
  filters: {
    selectClient: 'Select Client',
    flagCreatedDate: 'Flag Created Date',
    sequenceNumber: 'Sequence Number',
    status: 'Status',
  },
  statuses: {
    all: 'All',
    pending: 'Pending',
    understood: 'Understood',
    didNotUnderstand: 'Did not understand',
  },
  resendFlagConfirmationTitle: 'Resend Flag',
  resendFlagConfirmationText:
    'Are you sure you want to resend flag to user {fullName}?',

  resendFlagsConfirmationTitle: 'Resend Flags',
  resendFlagsConfirmationText:
    'Are you sure you want to resend all pending flags?',
  flagSent: 'Flag has been sent',
  allFlagsSent: 'Resend flags task has been added to queue',
};
