import Vue from 'vue';
import moment from 'moment';
import isFloat from '@/utils/isFloat';

Vue.filter('date', date => (date ? moment(date).format('MM/DD/YYYY') : null));
Vue.filter('dateFormat', date => (date ? moment.parseZone(date).format('MM/DD/YYYY') : null));
Vue.filter(
  'datetime',
  date => (date ? moment.parseZone(date).format('MM/DD/YYYY h:mm A') : null),
);
Vue.filter(
  'datetimeToClientTimezone',
  date => (date ? moment(date).format('MM/DD/YYYY h:mm A') : null),
);
Vue.filter(
  'time',
  time => (time ? moment(time, 'hh:mm:ss').format('hh:mm A') : null),
);

Vue.filter('humanFileSize', size => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return !size
    ? '0 B'
    : `${Number((size / 1024 ** i).toFixed(2))} ${
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }`;
});

Vue.filter('trim', value => (value ? value.trim() : ''));

Vue.filter('price', price => {
  if (!isFloat(price)) {
    return '0.00';
  }
  const dollarUSLocale = Intl.NumberFormat('en-US');
  let newPrice = dollarUSLocale.format(price);
  newPrice = newPrice.includes('.') ? newPrice : `${newPrice}.00`;
  const parts = newPrice.split('.');
  if (parts[1].length === 1) {
    newPrice += '0';
  }
  return newPrice;
});
