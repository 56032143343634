<template>
  <v-dialog
    v-model="dialog"
    max-width = "420px"
    @input="onDialog"
  >
    <v-card
        v-if="incident"
        class="text-center"
    >
      <v-card-title>
        <v-spacer/>
        Incident: {{incident.sequenceNumber}}
        <v-spacer/>
      </v-card-title>
        <v-card-text>UUID: <b>{{ incident.uuid }}</b>
            <span class="copy-uuid-button">
              <v-btn
                icon
                small
                fab
                @click="copy"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="blue darken-1"
              text
              @click="onDialog(false)"
          >Close</v-btn>
          <v-spacer/>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'HospitalIncidentUuidPopup',
  data() {
    return {
      dialog: false,
      incident: null,
    };
  },
  methods: {
    /**
     * Show hospital incident UUID.
     *
     * @param {Object} incident Incident data
     */
    show(incident) {
      if (incident.uuid === null) {
        return;
      }

      this.incident = incident;
      this.dialog = true;
    },

    /**
     * Copy incident UUID.
     */
    copy() {
      navigator.clipboard.writeText(this.incident.uuid);
    },

    /**
     * Handle of dialog.
     *
     * @param {Boolean} isOpen Dialog is open
     */
    onDialog(isOpen) {
      if (!isOpen) {
        this.dialog = false;
        this.incident = null;
      }
    },
  },
};
</script>

<style lang="scss">
.copy-uuid-button {
  margin: 0 0 10px 10px;
}
</style>
