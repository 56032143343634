import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    documents: {
      items: [],
      pagination: {
        page: 1,
        sortDesc: [],
        itemsPerPage: 25,
        sortBy: [],
      },
      totalItems: 0,
      filters: {
        search: null,
        category: null,
      },
    },
    commonDocuments: {
      items: [],
      pagination: {
        page: 1,
        sortDesc: [],
        itemsPerPage: 25,
        sortBy: [],
      },
      totalItems: 0,
      filters: {
        search: null,
        category: null,
      },
    },
    categories: [],
    commonCategories: [],
  },
  actions,
  mutations,
  getters: {},
};
