export const SET_INCIDENTS_ITEMS = 'SET_INCIDENTS_ITEMS';
export const SET_INCIDENTS_PAGINATION = 'SET_INCIDENTS_PAGINATION';
export const SET_INCIDENTS_FILTERS = 'SET_INCIDENTS_FILTERS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_UNITS = 'SET_UNITS';
export const SET_UNITS_PAGINATION = 'SET_UNITS_PAGINATION';
export const SET_UNITS_LOADING = 'SET_UNITS_LOADING';
export const SET_UNITS_FILTER = 'SET_UNITS_FILTER';
export const SET_CURRENT_INCIDENT = 'SET_CURRENT_INCIDENT';

export default {
  /**
   * Save incidents data.
   *
   * @param {Object} state - Incidents state.
   * @param {{ items: Array, totalItems: number }} data - Incidents data to set
   */
  [SET_INCIDENTS_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.pagination.totalItems = totalItems;
  },

  /**
   * Save incidents pagination.
   *
   * @param {Object} state - Incidents state.
   * @param {Object} pagination - Incidents pagination.
   */
  [SET_INCIDENTS_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Saves filters of incidents.
   *
   * @param {Object} state - Incidents state.
   * @param {Object} filters - Filters to set.
   */
  [SET_INCIDENTS_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  /**
   * Set active tab state.
   *
   * @param {Object} state - Incidents state.
   * @param {Object} value - Value of active tab.
   */
  [SET_ACTIVE_TAB]: (state, value) => {
    state.activeTab = value;
  },

  /**
   * Save incidents data.
   *
   * @param {Object} state - Incidents state.
   * @param {{ units: Array, totalItems: number }} data - Incident units data to set
   */
  [SET_UNITS]: (state, { units, totalItems }) => {
    state.units = units;
    state.paginationUnits.totalItems = totalItems;
  },

  /**
   * Save incidents pagination.
   *
   * @param {Object} state - Incidents state.
   * @param {Object} pagination - Incident units pagination.
   */
  [SET_UNITS_PAGINATION]: (state, pagination) => {
    state.paginationUnits = pagination;
  },

  /**
   * Save incidents pagination.
   *
   * @param {Object} state - Incidents state.
   * @param {Boolean} loading - Incident units pagination.
   */
  [SET_UNITS_LOADING]: (state, loading) => {
    state.unitsLoading = loading;
  },

  /**
   * Saves filters of incident units.
   *
   * @param {Object} state - Incidents state.
   * @param {Object} filters - Filters to set.
   */
  [SET_UNITS_FILTER]: (state, filters) => {
    state.unitsFilters = filters;
  },

  /**
   * Set data of incident.
   *
   * @param {Object} state - Incidents state.
   * @param {Object} incident - Incident data.
   */
  [SET_CURRENT_INCIDENT]: (state, incident) => {
    state.currentIncident = incident;
  },
};
