<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          {{ formTitle }}
        </v-card-title>

        <v-card-text class="pt-0">
          <div>
            <v-layout wrap>
              <v-flex md12>
                <v-text-field
                  v-model="admin.userName"
                  v-validate="{required:true, 'check-unique': [admin]}"
                  :data-vv-delay="500"
                  :error-messages="errors.collect('userName')"
                  label="User Name"
                  name="userName"
                  maxlength="255"
                  data-vv-as="User Name"
                />
              </v-flex>
              <v-flex md12>
                <v-text-field
                  v-model="admin.firstName"
                  v-validate="'required'"
                  :error-messages="errors.collect('firstName')"
                  label="First Name"
                  name="firstName"
                  maxlength="255"
                  data-vv-as="First name"
                />
              </v-flex>
              <v-flex md12>
                <v-text-field
                  v-model="admin.lastName"
                  v-validate="'required'"
                  :error-messages="errors.collect('lastName')"
                  label="Last Name"
                  name="lastName"
                  maxlength="255"
                  data-vv-as="Last Name"
                />
              </v-flex>
              <v-flex md12>
                <v-text-field
                  v-model="admin.email"
                  v-validate="'required|email'"
                  :error-messages="errors.collect('email')"
                  label="Email"
                  name="email"
                />
              </v-flex>
              <v-flex md12>
                <vue-tel-input-vuetify
                  v-if="dialog"
                  v-model="phone"
                  :preferred-countries="['US']"
                  :disabled-fetching-country="true"
                  :error-messages="phoneValid ? [] : ['Phone is invalid']"
                  label="Phone"
                  outer="mdi-send"
                  mode="international"
                  @input="updatePhone"
                />
              </v-flex>
              <v-flex md6>
                <v-text-field
                  ref="password"
                  v-model="admin.password"
                  v-validate="passwordRule"
                  :error-messages="errors.collect('password')"
                  name="password"
                  label="Admin Password"
                  type="password"
                  prepend-icon="lock"
                />
              </v-flex>
              <v-flex md6>
                <v-text-field
                  v-model="admin.retypePassword"
                  v-validate="passwordRetypeRule + 'confirmed:password'"
                  :error-messages="errors.collect('retypePassword')"
                  label="Retype Password"
                  type="password"
                  name="retypePassword"
                  data-vv-as="password"
                  prepend-icon="lock"
                />
              </v-flex>
              <v-flex>
                <i>{{ $t('general.passwordFormat') }}</i>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>

        <v-card-actions class="pb-0 pt-0">
          <v-flex
            v-if="errorMessage"
            xs12
            class="error--text text-center"
          >
            {{ errorMessage }}
          </v-flex>
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click.native="$emit('close')"
          >Cancel</v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click.native="save"
          >Save</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateAdmin, createAdmin, checkUserName } from '@/api/admin';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';
import { mapState } from 'vuex';

export default {
  name: 'AdminEditPopup',
  data() {
    return {
      admin: {},
      loading: false,
      phone: null,
      phoneValid: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('adminList', ['admins', 'editedItem']),
    dialog: {
      get() {
        return this.$store.state.adminList.dialog;
      },
      set() {
        this.$emit('close');
      },
    },
    formTitle() {
      return !this.editedItem.id ? 'Add Admin' : 'Edit Admin';
    },
    passwordRule() {
      return !this.editedItem.id
        ? { required: true, regex: validatePasswordRegexp }
        : { regex: validatePasswordRegexp };
    },
    passwordRetypeRule() {
      return !this.editedItem.id ? 'required|' : '';
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.loading = false;
          this.errorMessage = '';
          if (this.editedItem.id) {
            this.admin = { ...this.editedItem };
            this.phone = this.admin.phone;
            this.phoneValid = this.admin.phoneVerified;
          } else {
            this.admin = {};
            this.phone = null;
            this.phoneValid = true;
          }
          setTimeout(() => {
            this.errors.clear();
          }, 100);
        }
      },
      deep: true,
    },
  },
  created() {
    const checkUnique = {
      getMessage(field) {
        return `Such ${field} already exists.`;
      },
      validate(value, admin) {
        if (admin[0].userName) {
          return checkUserName(admin[0]);
        }
        return true;
      },
    };
    this.$validator.extend('check-unique', checkUnique);
  },
  methods: {
    save() {
      this.validatePhone();
      this.$validator.validateAll().then(isValid => {
        if (!isValid || !this.phoneValid) {
          return;
        }
        const emptyFields = ['phone', 'password', 'retypePassword'];
        emptyFields.forEach(field => {
          if (!this.admin[field]) {
            delete this.admin[field];
          }
        });
        this.loading = true;
        if (this.editedItem.id) {
          updateAdmin(this.editedItem.id, this.admin)
            .then(response => {
              this.admin = response.item;
              Object.assign(this.editedItem.id, this.admin);
              this.$emit('update');
            })
            .catch(error => {
              this.loading = false;
              this.errorMessage = error.response.data.message;
            });
        } else {
          createAdmin(this.admin)
            .then(response => {
              this.admin = response.item;
              this.admins.push(this.admin);
              this.$emit('update');
            })
            .catch(error => {
              this.loading = false;
              this.errorMessage = error.response.data.message;
            });
        }
      });
    },

    validatePhone() {
      if (!this.admin.phone) {
        this.phoneValid = false;
      }
      return this.phoneValid;
    },

    /**
     * Update phone.
     *
     * @param {String} number - Phone number
     * @param {Object} data - Phone data
     */
    updatePhone(number, data) {
      this.phoneValid = data.isValid;
      if (data.isValid === true) {
        this.admin.phone = data.number.e164;
      } else {
        this.admin.phone = '';
      }
    },
  },
};
</script>
