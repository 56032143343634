<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-card-title>{{ title }}</v-card-title>
    <vue-apex-charts
        v-if="!loading && series[0].data.length"
        type="bar"
        :height="chartHeight"
        :options="options"
        :series="series || []"
    />
    <div
        v-if="!loading && !series[0].data.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';

export default {
  name: 'ColumnWithDataLabels',
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    measurement: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },

        xaxis: {
          categories: [],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
    };
  },
  computed: {
    series() {
      const series = [];
      const data = this.data.map((item) => item.count);
      series.push({
        name: this.measurement || 'Count',
        data,
      });
      return series;
    },

    chartHeight() {
      return this.height - 100;
    },

    options() {
      const options = _.cloneDeep(this.chartOptions);
      options.xaxis.categories = this.data.map((item) => item.key);
      return options;
    },
  },
};
</script>
