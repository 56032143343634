<template>
  <v-toolbar
    class="nemsis-export-toolbar"
    flat
  >
    <v-autocomplete
        v-if="showClientsFilter"
        v-model="clientIdModel"
        :items="clients"
        :disabled="exporting || preparing"
        label="Choose client"
        clearable
        hide-details
        style="max-width: 300px"
        class="mr-5"
    />
    <date-range-picker-with-periods
        v-model="dateRange"
        :clearable="true"
        :disabled="exporting || preparing"
        position="right"
        class="mr-5"
    />
    <v-text-field
      v-model="sequenceNumbers"
      :disabled="exporting || preparing"
      hide-details
      class="mr-5"
      label="Sequence Numbers"
    />
    <v-checkbox
        v-model="useSchematron"
        :disabled="exporting || preparing"
        hide-details
        class="mr-5"
        label="Use Schematron"
    />
    <v-spacer />

    <v-btn
        v-if="showSubmitBtn"
        class="mr-3"
        color="primary"
        :disabled="exporting || preparing || exportDisabled"
        @click="submit"
    >
      Submit
    </v-btn>

    <v-btn
        v-if="!exporting"
        class="mr-3"
        color="primary"
        :disabled="exportDisabled"
        :loading="preparing"
        @click="runExport"
    >
      Download
    </v-btn>
    <v-btn
        v-if="exporting"
        class="mr-3"
        @click="$emit('cancel')"
    >
      Cancel
    </v-btn>
  </v-toolbar>
</template>
<script>
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'Toolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    clientId: {
      type: Number,
      default: null,
      required: false,
    },
    preparing: {
      default: false,
      type: Boolean,
    },
    exporting: {
      default: false,
      type: Boolean,
    },
    showClientsFilter: {
      default: true,
      type: Boolean,
    },
    showSubmitBtn: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      clientIdModel: this.clientId,
      dateRange: null,
      sequenceNumbers: null,
      useSchematron: false,
    };
  },

  watch: {
    clientIdModel(value) {
      this.$emit('update:clientId', value);
    },
  },

  computed: {
    clients() {
      const { clientsList } = this.$store.state;
      if (!clientsList || !clientsList.length) {
        return [];
      }
      return clientsList.map(client => ({
        text: client.name,
        value: client.registryId,
      }));
    },
    exportDisabled() {
      if (this.showClientsFilter && !this.clientIdModel) {
        return true;
      }
      return !this.dateRange && !this.sequenceNumbers;
    },
  },

  methods: {
    runExport() {
      const filters = this.getFilters();
      this.$emit('export', filters);
    },

    submit() {
      const filters = this.getFilters();
      this.$emit('submit', filters);
    },

    getFilters() {
      const filters = {
        clientId: this.clientIdModel,
        sequenceNumbers: null,
        dateFrom: null,
        dateTo: null,
        useSchematron: this.useSchematron,
      };
      if (this.sequenceNumbers) {
        filters.sequenceNumbers = this.sequenceNumbers.toUpperCase()
          .split(',')
          .map(val => val.trim())
          .filter(val => val !== '');
      } else if (this.dateRange) {
        // eslint-disable-next-line prefer-destructuring
        filters.dateFrom = this.dateRange[0];
        // eslint-disable-next-line prefer-destructuring
        filters.dateTo = this.dateRange[1];
      }
      return filters;
    },
  },
};
</script>

<style lang="scss">
.nemsis-export-toolbar {
  z-index: 10;
}
</style>
