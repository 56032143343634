export const SET_ITEMS = 'SET_ITEMS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_LICENSE_TYPES = 'SET_LICENSE_TYPES';

export default {
  /**
   * Save users data.
   *
   * @param {Object} state - Users state.
   * @param {{ items: Array, totalItems: number }} data - Users data to set
   */
  [SET_ITEMS]: (state, { items, totalItems }) => {
    state.items = items;
    state.totalItems = totalItems;
  },

  /**
   * Save users pagination.
   *
   * @param {Object} state - Users state.
   * @param {Object} pagination - Users pagination.
   */
  [SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination;
  },

  /**
   * Save filters of users.
   *
   * @param {Object} state - Users state.
   * @param {Object} filters - Filters to set.
   */
  [SET_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  /**
   * Save license types of users.
   *
   * @param {Object} state - Users state
   * @param {Object} $types - Types of license
   */
  [SET_LICENSE_TYPES]: (state, $types) => {
    state.licenseTypes = $types;
  },
};
