<template>
  <div style="display: none;">
    <div
      :id="uniqueId"
    >
      <div
        class="print-images"
      >
        <template v-for="(url, index) in images">
          <img
              v-bind:key="'img_' + index"
              :src="url"
              style="position: relative;
                page-break-inside: avoid;
                page-break-after: always;
                padding:0;
                top:0; left:0; right:0;bottom:0;  border:0;
                max-width:98% !important;
                margin:0 auto;"
              @load="imageLoaded(url)"
          />
          <div
            v-if="index < images.length-1"
            v-bind:key="'pagebreak_' + index"
            style="page-break-before: always;"
          />
        </template>
      </div>
    </div>

    <div
        :id="uniqueId + '_narcotic_request'"
    >
      <div
          class="print-images-narcotic-request"
      >
        <template v-for="(url, index) in images">
          <img
              v-bind:key="'img_' + index"
              :src="url"
              style="position: relative;
                page-break-inside: avoid;
                page-break-after: always;
                padding:0;
                top:0; left:0; right:0;bottom:0;  border:0;
                max-width:98% !important;
                margin:0 auto;"
              @load="imageLoaded(url)"
          />
          <div
              v-if="index < images.length-1"
              v-bind:key="'pagebreak_' + index"
              style="page-break-before: always;"
          />
        </template>
        <img
            src="/spa/images/watermarking.png"
            style="position: absolute !important;
              page-break-inside: avoid;
              page-break-after: always;
              padding-top: 220px;
              top:0; left:0; right:0;bottom:0;  border:0;
              z-index: 100;
              margin:0 auto;"
            @load="imageLoaded('watermark')"
        />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PrintImages',
  props: {
    uniqueId: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    imagesLoaded: {
      type: Boolean,
      required: true,
    },
    printLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      printArea: null,
      printing: false,
      narcRequest: false,
      watermarkLoaded: false,
      loaded: [],
    };
  },
  watch: {
    images() {
      this.loaded = [];
      this.$emit('update:imagesLoaded', false);
    },
  },
  methods: {
    async print(narcRequest = false) {
      const printAreas = document.getElementsByClassName('print-area');
      let printArea = null;
      if (printAreas.length === 0) {
        printArea = document.createElement('div');
        printArea.classList.add('print-area');
        document.body.append(printArea);
      } else {
        // eslint-disable-next-line prefer-destructuring
        printArea = printAreas[0];
        printArea.innerHTML = '';
      }
      this.printArea = printArea;

      this.narcRequest = narcRequest;
      this.printing = true;
      this.$emit('update:printLoading', true);
      this.printFunction();
    },
    async imageLoaded(url) {
      if (url === 'watermark') {
        this.watermarkLoaded = true;
      } else {
        this.loaded.push(url);
      }
      const allImages = this.images.length * 2;
      if (this.loaded.length === allImages && this.watermarkLoaded) {
        this.$emit('update:imagesLoaded', true);
      }
    },
    async printFunction() {
      const printImages = document.getElementsByClassName(this.narcRequest
        ? 'print-images-narcotic-request'
        : 'print-images');
      if (printImages.length) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
          const id = this.uniqueId + (this.narcRequest ? '_narcotic_request' : '');
          await this.$htmlToPaper(id, {}, () => {
            this.$emit('update:printLoading', false);
            this.printing = false;
            this.printArea.remove();
            this.printArea = null;
          });
        } else {
          this.printArea.append(printImages[0].cloneNode(true));
          document.getElementById('appContainer').classList.add('printing');
          await window.print();
          this.$emit('update:printLoading', false);
          this.printing = false;
          this.printArea.remove();
          this.printArea = null;
          document.getElementById('appContainer').classList.remove('printing');
        }
      }
    },
  },
};
</script>
<style lang="scss">
.print-area {
  display: none;
}
</style>
