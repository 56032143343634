import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    pagination: {
      page: 1,
      sortDesc: [true],
      itemsPerPage: 25,
      sortBy: ['incidentDataUpdatedAt'],
      totalItems: 0,
    },
    filters: {
      clientId: null,
      sequenceNumber: null,
    },
    duplicateIncidentData: {},
    originalIncidentData: {},
  },
  actions,
  mutations,
  getters: {},
};
