export const SET_SITECODE = 'SET_SITECODE';
export const SET_ACL_INITIALIZED = 'SET_ACL_INITIALIZED';
export const SET_TITLE = 'SET_TITLE';
export const SET_DRAWER = 'SET_DRAWER';
export const SET_USER = 'SET_USER';
export const SET_SITE_INFO = 'SET_SITE_INFO';
export const SET_DISPLAY_HEADER = 'SET_DISPLAY_HEADER';
export const SET_INITIALIZING = 'SET_INITIALIZING';
export const SET_INITIALIZED = 'SET_INITIALIZED';
export const SET_INITIALIZING_USER = 'SET_INITIALIZING_USER';
export const SET_INITIALIZING_SITE_INFO = 'SET_INITIALIZING_SITE_INFO';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_SNACKBAR_TITLE = 'SET_SNACKBAR_TITLE';
export const SET_SNACKBAR_COLOR = 'SET_SNACKBAR_COLOR';
export const SET_GRADIENT_BACKGROUND = 'SET_GRADIENT_BACKGROUND';
export const SET_USER_UNREAD_NOTIFICATIONS = 'SET_USER_UNREAD_NOTIFICATIONS';
export const SET_VERIFY_CONTACT_DATA_DIALOG = 'SET_VERIFY_CONTACT_DATA_DIALOG';
export const SET_IS_ALERT_ON = 'SET_IS_ALERT_ON';
export const SET_SHOW_LOGOUT_HOSPITAL_POPUP = 'SET_SHOW_LOGOUT_HOSPITAL_POPUP';
export const SET_IS_PRINTING = 'SET_IS_PRINTING';

export default {
  [SET_SITECODE]: (state, sitecode) => {
    state.sitecode = sitecode;
  },
  [SET_ACL_INITIALIZED]: (state, value) => {
    state.aclInitialized = value;
  },
  [SET_TITLE]: (state, title) => {
    state.title = title;
    document.title = title;
  },
  [SET_DRAWER]: (state, drawer) => {
    state.drawer = drawer;
  },
  [SET_USER]: (state, user) => {
    state.user = user;
  },
  [SET_SITE_INFO]: (state, siteInfo) => {
    state.siteInfo = siteInfo;
  },
  [SET_DISPLAY_HEADER]: (state, displayHeader) => {
    state.displayHeader = displayHeader;
  },
  [SET_INITIALIZING_USER]: (state, initializing) => {
    state.initializingUser = initializing;
  },
  [SET_INITIALIZING_SITE_INFO]: (state, initializing) => {
    state.initializingSiteInfo = initializing;
  },
  [SET_INITIALIZING]: (state, initializing) => {
    state.initializing = initializing;
  },
  [SET_INITIALIZED]: (state, initialized) => {
    state.initialized = initialized;
  },
  [SET_CLIENTS]: (state, clients) => {
    state.clientsList = clients;
  },
  [SET_SNACKBAR]: (state, snackbar) => {
    state.snackbar = snackbar;
  },
  [SET_SNACKBAR_TITLE]: (state, title) => {
    state.snackbarTitle = title;
  },
  [SET_SNACKBAR_COLOR]: (state, color) => {
    state.snackbarColor = color;
  },
  [SET_USER_UNREAD_NOTIFICATIONS]: (state, totalUnread) => {
    state.user.notifications = totalUnread.notifications;
  },
  [SET_VERIFY_CONTACT_DATA_DIALOG]: (state, value) => {
    state.verifyContactDataDialog = value;
  },
  [SET_IS_ALERT_ON]: (state, isAlertOn) => {
    state.user.isAlertOn = isAlertOn;
  },
  [SET_SHOW_LOGOUT_HOSPITAL_POPUP]: (state, value) => {
    state.showLogoutHospitalPopup = value;
  },
  [SET_IS_PRINTING]: (state, value) => {
    state.isPrinting = value;
  },
};
