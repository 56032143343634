<template>
  <v-toolbar>
    <v-text-field
        v-model="filtersData.search"
        label="Search"
        clearable
        hide-details
        style="max-width: 300px"
    />
    <v-spacer />
    <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="$emit('add')"
    >
      + Add
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'ZollDevicesToolbar',
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filtersData: this.filters,
    };
  },

  watch: {
    filtersData: {
      handler() {
        this.$emit('search', this.filtersData);
      },
      deep: true,
    },
  },
};
</script>
