<template>
  <view-user
      ref="viewUser"
      :show-back-button="false"
      :show-action-buttons="false"
      @viewEntry="viewEntry"
  />
</template>
<script>
import ViewUser from '@/components/CeEntry/ViewUser';

export default {
  name: 'MyLicense',
  components: {
    ViewUser,
  },
  data() {
    return {
    };
  },
  mounted() {
    if (this.$store.state.user && this.$store.state.user.registryId) {
      this.$refs.viewUser.loadUsingUserId(this.$store.state.user.registryId);
    }
  },
  methods: {
    updateData() {
      this.$refs.viewUser.updateData();
    },
    viewEntry(entry) {
      this.$emit('viewEntry', entry);
    },
  },
};
</script>
