import api from './api';

export function fetchAdminDashboardClients() {
  return api()
    .get('/admin/dashboard/clients')
    .then(response => response.data);
}

export function fetchAdminDashboardTotals() {
  return api()
    .get('/admin/dashboard/totals')
    .then(response => response.data);
}
