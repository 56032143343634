import api from './api';

export default {
  /**
   * Fetch CE Entries data.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  getEntries(params) {
    return api()
      .get('/ce-hours/entries', { params })
      .then(response => response.data);
  },

  /**
     * Create CE Entry.
     *
     * @param {Object} data - Data of CE Entry
     *
     * @return Promise<Object>
     */
  createEntry(data) {
    return api()
      .post('/ce-hours/entries', data)
      .then(response => response.data);
  },

  /**
     * Save CE Entry.
     *
     * @param {Number} entryId - CeEntry Identifier
     * @param {Object} data - Data of CE Entry
     *
     * @return Promise<Object>
     */
  saveEntry(entryId, data) {
    return api()
      .put(`/ce-hours/entries/${entryId}`, data)
      .then(response => response.data);
  },

  /**
   * Delete CE Entry.
   *
   * @param {Number} entryId - CeEntry Identifier
   *
   * @return Promise<Object>
   */
  deleteEntry(entryId) {
    return api()
      .delete(`/ce-hours/entries/${entryId}`)
      .then(response => response.data);
  },

  /**
   * Evaluate CE Entry.
   *
   * @param {Number} entryId - CeEntry Identifier
   * @param {Object} data - Data to submit
   *
   * @return Promise<Object>
   */
  evaluateEntry(entryId, data) {
    return api()
      .post(`/ce-hours/entries/${entryId}/evaluate`, data)
      .then(response => response.data);
  },

  /**
   * Approve CE Entry.
   *
   * @param {Number} entryId - CeEntry Identifier
   *
   * @return Promise<Object>
   */
  approveEntry(entryId) {
    return api()
      .post(`/ce-hours/entries/${entryId}/approve`, {})
      .then(response => response.data);
  },

  /**
   * Reject CE Entry.
   *
   * @param {Number} entryId - CeEntry Identifier
   * @param {Object} data - Data to reject
   *
   * @return Promise<Object>
   */
  rejectEntry(entryId, data) {
    return api()
      .post(`/ce-hours/entries/${entryId}/reject`, data)
      .then(response => response.data);
  },

  /**
   * Get CE Entry data.
   *
   * @return Promise<Object>
   */
  getEntry(entryId) {
    const params = { include: 'files,skills' };
    return api()
      .get(`/ce-hours/entries/${entryId}`, { params })
      .then(response => response.data);
  },

  /**
   * Get CE Entry attendees.
   *
   * @return Promise<Object>
   */
  getAttendees(entryId) {
    return api()
      .get(`/ce-hours/entries/${entryId}/attendees`)
      .then(response => response.data);
  },

  /**
   * Get CE Entry attendees export.
   *
   * @return Promise<Object>
   */
  exportAttendees(entryId) {
    return api()
      .get(`/ce-hours/entries/${entryId}/attendees/export`, { responseType: 'blob' })
      .then(response => response.data);
  },

  /**
   * Download certificate.
   *
   * @return Promise<Object>
   */
  downloadCertificate(entryId, userId) {
    return api()
      .get(`/ce-hours/entries/${entryId}/attendees/${userId}/certificate`, { responseType: 'blob' })
      .then(response => response.data);
  },

  /**
   * Get list of all attendees.
   *
   * @return Promise<Object>
   */
  getAllAttendees() {
    return api()
      .get('/ce-hours/all-attendees')
      .then(response => response.data);
  },

  /**
   * Get list of all providers.
   *
   * @return Promise<Object>
   */
  getAllProviders() {
    return api()
      .get('/ce-hours/providers')
      .then(response => response.data);
  },

  /**
   * Get list of all skills.
   *
   * @return Promise<Object>
   */
  getAllSkills() {
    return api()
      .get('/ce-hours/all-skills')
      .then(response => response.data);
  },

  /**
   * Get list of client users with periods and hours.
   *
   * @return Promise<Object>
   */
  getAllUsers() {
    return api()
      .get('/ce-hours/users')
      .then(response => response.data);
  },

  /**
   * Get CE user by identifier.
   *
   * @return Promise<Object>
   */
  getUser(userId) {
    return api()
      .get(`/ce-hours/users/${userId}`)
      .then(response => response.data);
  },

  /**
   * Get list of client users with their skills.
   *
   * @return Promise<Object>
   */
  getUsersSkillsOverview(params) {
    return api()
      .get('/ce-hours/users/skills-overview', { params })
      .then(response => response.data);
  },

  /**
   * Export list of client users with their skills as XLSX file.
   *
   * @return Promise<Object>
   */
  exportUsersSkillsOverview(params) {
    return api()
      .get('/ce-hours/users/skills-overview/export', { params, responseType: 'blob' })
      .then(response => response.data);
  },

  /**
   * Update user licenses.
   *
   * @param {Number} userId - User Identifier
   * @param {Object} data - Data to save
   *
   * @return Promise<Object>
   */
  updateUserLicenses(userId, data) {
    return api()
      .put(`/ce-hours/users/${userId}/licenses`, data)
      .then(response => response.data);
  },

  /**
   * Get CE user's periods with entries.
   *
   * @return Promise<Object>
   */
  getUserPeriods(userId) {
    return api()
      .get(`/ce-hours/users/${userId}/periods`)
      .then(response => response.data);
  },

  /**
   * Create user education period.
   *
   * @param {Number} userId - User Identifier
   * @param {Object} data - Data to save
   *
   * @return Promise<Object>
   */
  createCePeriod(userId, data) {
    return api()
      .post(`/ce-hours/users/${userId}/periods`, data)
      .then(response => response.data);
  },

  /**
   * Update user education period.
   *
   * @param {Number} periodId - Period Identifier
   * @param {Object} data - Data to save
   *
   * @return Promise<Object>
   */
  updateCePeriod(periodId, data) {
    return api()
      .put(`/ce-hours/periods/${periodId}`, data)
      .then(response => response.data);
  },

  /**
   * Delete user education period.
   *
   * @param {Number} periodId - Period Identifier
   *
   * @return Promise<Object>
   */
  deleteCePeriod(periodId) {
    return api()
      .delete(`/ce-hours/periods/${periodId}`)
      .then(response => response.data);
  },

  /**
   * Get CE Evaluation questions.
   *
   * @return Promise<Object>
   */
  getEvaluationQuestions() {
    return api()
      .get('/ce-hours/evaluation/questions')
      .then(response => response.data);
  },

  /**
   * Get Evaluation answers of CE Entry.
   *
   * @param {Number} entryId - CeEntry Identifier
   * @param {Number} userId - Attendee identifier
   *
   * @return Promise<Object>
   */
  getEvaluationAnswers(entryId, userId) {
    return api()
      .get(`/ce-hours/entries/${entryId}/attendees/${userId}/evaluation-answers`)
      .then(response => response.data);
  },
};
