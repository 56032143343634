<template>
  <v-dialog
    v-model="showEditPopup"
    persistent
    class="notification-edit"
    max-width="600"
    @close="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          {{ isNew
            ? $t('pushNotifications.editPopup.headerNew')
            : $t('pushNotifications.editPopup.headerEdit')
          }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div
            v-if="loading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              color="grey darken"
              center
              indeterminate
            />
          </div>
          <div v-else>
            <v-alert
              v-model="errorAlert"
              type="error"
              outlined
              dismissible
              transition="scale-transition"
            >
              <div v-html="errorMessage" />
            </v-alert>
            <v-form>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    v-model="notification.messageType"
                    v-validate="{ required: true }"
                    :items="messageTypes"
                    :label="$t('pushNotifications.editPopup.messageType')"
                    :error-messages="errors.collect('messageType')"
                    name="messageType"
                    @change="messageTypeChange"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="notification.showType"
                    v-validate="{ required: true }"
                    :items="showTypes"
                    :error-messages="errors.collect('showType')"
                    :label="$t('pushNotifications.editPopup.showType')"
                    name="showType"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="scheduledDate"
                    :label="$t('pushNotifications.editPopup.scheduledAt')"
                    :error-messages="errors.collect('scheduledDate')"
                    prepend-icon="event"
                    clearable
                    name="scheduledDate"
                    @click="openDatePicker"
                  />
                  <datetime
                    ref="dateTime"
                    v-model="notification.scheduledDate"
                    type="datetime"
                    input-class="d-none"
                    class="theme-red"
                    use12-hour
                  />
                </v-flex>

                <v-flex xs12>
                  <v-select
                    v-model="notification.clientId"
                    :items="clients"
                    :label="$t('pushNotifications.editPopup.toClient')"
                    item-value="registryId"
                    item-text="name"
                    @change="changeClient"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-select
                    v-model="notification.deviceId"
                    :items="devices"
                    :label="$t('pushNotifications.editPopup.toDevice')"
                    :loading="devicesLoading"
                    :disabled="!notification.clientId"
                  />
                </v-flex>
                <v-flex
                  v-if="notification.messageType === 'url'"
                  xs12
                >
                  <v-text-field
                    v-model="notification.url"
                    v-validate="{required: notification.messageType === 'url'}"
                    :error-messages="errors.collect('url')"
                    :label="$t('pushNotifications.editPopup.urlAddrress')"
                    name="url"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="notification.message"
                    v-validate="{required: notification.messageType === 'custom'}"
                    :error-messages="errors.collect('message')"
                    :label="$t('pushNotifications.editPopup.message')"
                    name="message"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            :disabled="savingNotification"
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            :loading="savingNotification"
            color="primary"
            text
            @click.native="validateAndSave()"
          >
            {{ notification.scheduledDate ?
              $t('pushNotifications.buttons.schedule') :
            $t('pushNotifications.buttons.sendNow') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import { Datetime } from 'vue-datetime';
import apiPushNotifications from '@/api/pushNotifications';
import apiClients from '@/api/clients';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import PUSH_NOTIFICATION_SHOW_TYPE from '@/enums/pushNotificationShowTypes';

const { mapState } = createNamespacedHelpers('pushNotifications');

export default {
  name: 'TemplateEditPopup',

  components: {
    Datetime,
  },
  mixins: [errorAlertMixins],

  data() {
    return {
      isNew: false,
      loading: false,
      devicesLoading: false,
      savingNotification: false,
      showEditPopup: false,
      notification: {},
      clientsWithDevices: [],
      clientDevices: [],
      messageTypesStatic: [
        {
          value: 'newTemplate',
          text: this.$t('pushNotifications.editPopup.newTemplate'),
        },
        {
          value: 'url',
          text: this.$t('pushNotifications.editPopup.url'),
        },
        {
          value: 'custom',
          text: this.$t('pushNotifications.editPopup.custom'),
        },
      ],
      showTypes: [
        {
          value: PUSH_NOTIFICATION_SHOW_TYPE.DASHBOARD,
          text: this.$t('pushNotifications.editPopup.showTypeDashboard'),
        },
        {
          value: PUSH_NOTIFICATION_SHOW_TYPE.IMMEDIATE,
          text: this.$t('pushNotifications.editPopup.showTypeImmediate'),
        },
      ],
      dictionary: {
        attributes: {
          messageType: 'Message Type',
          showType: 'Deliver and Show',
          scheduledDate: 'Scheduled At',
          url: 'URL',
          message: 'Message',
        },
      },
    };
  },
  computed: {
    ...mapState({
      pushNotifications: state => state,
    }),

    /**
     * Get message types list.
     *
     * @return {Array}
     */
    messageTypes() {
      return [...this.messageTypesStatic];
    },

    /**
     * Computed property for schedule date input model.
     */
    scheduledDate: {
      get() {
        return this.notification.scheduledDate
          ? moment(this.notification.scheduledDate).format('MM/DD/YYYY h:mm A')
          : null;
      },
      set(value) {
        this.notification.scheduledDate = value;
      },
    },

    /**
     * Returns clients list for select options.
     *
     * @return {Array}
     */
    clients() {
      const items = [];
      items.push({
        registryId: null,
        name: this.$t('pushNotifications.editPopup.allClients'),
      });
      return [...items, ...this.clientsWithDevices];
    },

    /**
     * Returns devices list for select options.
     *
     * @return {Array}
     */
    devices() {
      const items = [
        {
          value: null,
          text: `${this.$t('pushNotifications.editPopup.allDevices')}${
            this.notification.clientId ? `(${this.clientDevices.length})` : ''
          }`,
        },
      ];
      if (this.notification.clientId) {
        this.clientDevices.forEach(device => {
          items.push({
            value: device.registryId,
            text: `Device ${device.dispatchId} (Inventory #${
              device.inventoryNumber
            })`,
          });
        });
      }
      return items;
    },
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    /**
     * Handler of click by schedule date input.
     */
    openDatePicker(event) {
      this.$refs.dateTime.open(event);
    },

    /**
     * Show new notification popup.
     */
    async showNew() {
      this.isNew = true;
      this.notification = {
        messageType: null,
        showType: null,
        scheduledDate: null,
        clientId: null,
        deviceId: null,
        message: null,
        url: null,
      };
      this.showEditPopup = true;
      this.loading = true;
      try {
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Show edit popup.
     *
     * @param {Object} notification - Notification object.
     */
    async showEdit(notification) {
      this.isNew = false;
      this.notification = notification;
      this.showEditPopup = true;
      this.loading = true;
      try {
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Load data for edit popup.
     *
     * @return Promise
     */
    loadData() {
      const promises = [
        apiClients.fetchList({ onlyWithInstalledDevices: 1 }).then(data => {
          this.clientsWithDevices = data.results;
        }),
      ];
      if (this.notification.clientId) {
        promises.push(this.loadDevices(this.notification.clientId));
      }
      return Promise.all(promises);
    },

    /**
     * Load client devices.
     *
     * @return Promise
     */
    loadDevices(clientId) {
      return apiClients.fetchClientDevices(clientId).then(data => {
        this.clientDevices = data.results;
      });
    },

    /**
     * Handler of change message type event.
     */
    messageTypeChange() {
      if (
        this.notification.messageType !== 'custom'
        && this.notification.showType === 'immediate'
      ) {
        this.notification.showType = null;
      }
    },

    /**
     * Handler of change client event.
     */
    async changeClient() {
      this.notification.deviceId = null;
      if (this.notification.clientId) {
        this.devicesLoading = true;
        try {
          await this.loadDevices(this.notification.clientId);
        } finally {
          this.devicesLoading = false;
        }
      }
    },

    /**
     * Save or create notification.
     *
     * @return {Promise}
     */
    saveNotification() {
      if (this.notification.id) {
        return apiPushNotifications.savePushNotification(this.notification);
      }
      return apiPushNotifications.createPushNotification(this.notification);
    },

    /**
     * Save or create notification.
     */
    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      this.savingNotification = true;
      try {
        await this.saveNotification();
        this.showEditPopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.savingNotification = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables';

/* stylelint-disable selector-class-pattern */
.theme-red .vdatetime-popup__header,
.theme-red .vdatetime-calendar__month__day--selected > span > span,
.theme-red .vdatetime-calendar__month__day--selected:hover > span > span {
  background: $primary-red;
}

.theme-red .vdatetime-year-picker__item--selected,
.theme-red .vdatetime-time-picker__item--selected,
.theme-red .vdatetime-popup__actions__button {
  color: $primary-red;
}
</style>
