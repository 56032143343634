import _ from 'lodash';
import clientsApi from '@/api/clients';

export default {
  methods: {
    /**
     * Impersonate user to client
     *
     * @param {Int} clientId - Client identifier.
     */
    async impersonate(clientId) {
      const data = await clientsApi.getImpersonateUrl(clientId);
      const win = window.open(data.url, '_blank');
      win.focus();
    },

    /**
     * Impersonate user to client and go to incident view page.
     *
     * @param {Int} clientId - Client identifier.
     * @param {string} incidentId - Incident identifier.
     */
    async impersonateToIncident(clientId, incidentId, redirect = false) {
      const data = await clientsApi.getImpersonateUrl(clientId);
      const url = `${data.url}?incidentId=${incidentId}`;
      if (redirect) {
        window.location = url;
      } else {
        const win = window.open(url, '_blank');
        if (win) {
          win.focus();
        }
      }
    },

    /**
     * Impersonate user to client and go to incident view page.
     *
     * @param {Int} clientId - Client identifier.
     * @param {Object} params - List of get params.
     * @param {string} route - Route name.
     * @param {string} redirect - Incident identifier.
     */
    async impersonateToRoute(clientId, route, params = {}, redirect = false) {
      const data = await clientsApi.getImpersonateUrl(clientId);
      let url = `${data.url}?route=${route}`;
      _.forEach(params, (value, key) => {
        url += `&${key}=${value}`;
      });
      if (redirect) {
        window.location = url;
      } else {
        const win = window.open(url, '_blank');
        if (win) {
          win.focus();
        }
      }
    },
  },
};
