<template>
  <v-dialog
    v-model="showEditPopup"
    persistent
    max-width="576"
  >
    <v-card :class="$style.card">
      <v-card-title class="headline pt-0">
        Notification
      </v-card-title>
      <v-card-text class="pt-0">
        <v-form
          :id="formId"
          :class="$style.row"
          @submit.prevent="saveRule"
        >
          <label :class="$style.label">Notify when</label>
          <v-select
            v-model="type"
            v-validate="{ required: true }"
            :class="$style.textField"
            :items="transmittedReportsOptions"
            item-value="type"
            item-text="name"
            single-line
            name="type"
            :error-messages="errors.collect('type')"
          />
          <label :class="$style.label">is > then</label>
          <v-text-field
            v-model="daysToWaitBeforeSending"
            v-validate="{ required: true, 'integer-number': true }"
            :class="$style.numberField"
            single-line
            name="daysToWait"
            :error-messages="errors.collect('daysToWait')"
          />
        </v-form>
        <v-form
          :class="$style.emailInputContainer"
          @submit.prevent="addEmail"
        >
          <v-text-field
            v-model="newEmail"
            v-validate="{ email: true }"
            color="blue darken-2"
            :label="$t('profileSettings.newEmailBtn')"
            name="email"
            autocomplete="email"
            :error-messages="errors.collect('email')"
          ></v-text-field>
          <v-btn
            color="error"
            type="submit"
            :class="$style.emailAddButton"
          >
            {{ $t('buttons.add') }}
          </v-btn>
        </v-form>
        <div :class="$style.emailsContainer">
          <v-chip
            v-for="email in emails"
            :key="email"
            :class="$style.emailChip"
            close
            @click:close="showConfirmation(email)"
          >
            {{ email }}
          </v-chip>
        </div>
      </v-card-text>
      <v-card-actions class="pb-0 pt-0">
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click.native="showEditPopup = false"
        >{{ $t('buttons.cancel') }}</v-btn>
        <v-btn
          color="primary"
          type="submit"
          :form="formId"
          text
        >{{ $t('buttons.save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmationPopup"/>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { FETCH_TRANSMITTED_NOTIFICATION_RULE } from '@/store/ProfileSettings/actions';
import editNotificationPopupMixin from './editNotificationPopupMixin';

export default {
  name: 'EditTransmittedNotificationPopup',
  mixins: [editNotificationPopupMixin],
  data() {
    return {
      /**
       * Form id for submit by button.
       */
      formId: 'transmitted-notification-form',
      /**
       * Options for type selection on transmitted notification rule.
       */
      transmittedReportsOptions: [
        { type: 'sinceIncidentDate', name: 'Incident Date' },
        { type: 'sinceTransmitted', name: 'Transmitted Date' },
      ],
    };
  },
  methods: {
    ...mapActions('profileSettings', {
      // Using in editNotificationPopupMixin
      saveNotificationRule: FETCH_TRANSMITTED_NOTIFICATION_RULE,
    }),
  },
};
</script>

<style lang="scss" module>
.card {
  padding: 14px;
}

.textField {
  max-width: 190px;
  margin-right: 8px;
}

.label {
  margin-right: 8px;
}

.numberField {
  max-width: 130px;
}

.emailInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.emailAddButton {
  margin-left: 10px;
}

.row {
  display: flex;
  align-items: center;
}

.emailChip {
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

</style>
