<template>
  <div>
    <v-alert
      :value="providers[clientId].status == 1"
      type="warning"
      outlined
      color="orange"
      class="mb-4"
    >
      {{ providers[clientId].statusDescriptions[0] }}
    </v-alert>

    <v-alert
      :value="providers[clientId].status == 2"
      type="error"
      outlined
      class="mb-4"
    >
      <span
        v-for="(description, i) in providers[clientId].statusDescriptions"
        :key="i"
      >
        {{ description }} <br>
      </span>
    </v-alert>

    <div
      v-if="providers[clientId].heartBeatFileProcessedPeriod
        || providers[clientId].heartBeatFileReceivedPeriod"
    >
      <b>Heartbeats enabled:</b>
      <v-spacer class="mb-1"/>
      <div>
        <span :class="[$style.red]">"No File" heartbeat:</span>
        <span v-if="providers[clientId].heartBeatFileReceivedPeriod">
          Alert at <b>{{ providers[clientId].heartBeatFileReceivedPeriod }}</b> minutes
        </span>
        <span v-if="!providers[clientId].heartBeatFileReceivedPeriod"><b>OFF</b></span>
      </div>

      <div>
        <span :class="[$style.red]">"Zero Kb (0 kb) file" heartbeat:</span>
        <span v-if="providers[clientId].heartBeatFileProcessedPeriod">
          Alert at <b>{{ providers[clientId].heartBeatFileProcessedPeriod }}</b> minutes
        </span>
        <span v-if="!providers[clientId].heartBeatFileProcessedPeriod"><b>OFF</b></span>
      </div>
    </div>

    <v-spacer class="mb-3"/>

    <a
      v-if="showCloudWatchUrl"
      :href="providers[clientId].clowdWatchUrl"
      target="_blank"
    >View AWS ClowdWatch Logs for past 24 hours</a>
    <v-spacer class="mt-3"/>

    <a
      v-if="showLatestCadDataLink"
      @click="showLatestCadData"
    >View the latest CAD data that has been processed</a>
    <v-spacer class="mt-3"/>

    <template v-if="providers[clientId].type !== 'mark43'">
      <span>
        <b>Files processed in last hour</b>: {{ providers[clientId].processedInLastHour }}
      </span>
      ({{ $t('cadMonitoring.includingZeroKbFiles') }})
      <v-spacer class="mb-3"/>

      <processed-files :client-id="clientId"/>
      <v-spacer class="mb-3"/>

      <failed-files
          v-if="showFailedFiles"
          :client-id="clientId"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FailedFiles from '@/components/CadMonitoring/FailedFiles';
import ProcessedFiles from '@/components/CadMonitoring/ProcessedFiles';
import { SET_INCIDENTS_FILTERS } from '@/store/ImportedIncidents/mutations';
import ROUTES from '@/enums/routes';

export default {
  name: 'Provider',
  components: {
    FailedFiles,
    ProcessedFiles,
  },
  filters: {
    latestCadUrl(clientID) {
      return `/lastimporincidents.php?clientID=${clientID}`;
    },
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    showCloudWatchUrl: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLatestCadDataLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFailedFiles: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('cadMonitoring', ['providers']),
  },
  methods: {
    ...mapMutations('importedIncidents', [SET_INCIDENTS_FILTERS]),

    showLatestCadData() {
      const { filters } = this.$store.state.importedIncidents;
      filters.clientId = this.clientId;
      this.SET_INCIDENTS_FILTERS(filters);
      this.$router.push({ name: ROUTES.LAST_IMPORTED_INCIDENTS });
    },
  },
};
</script>

<style lang="scss" module>
.red {
  color: #d0021b;
}
</style>
