<template>
  <div class="bloodGlucoseControl">
    <v-text-field
        v-if="!hiLoEnabled"
        v-model="textValue"
        type="number"
        hide-details
        outlined
        dense
    >
      <template v-slot:append-outer>
        <v-checkbox
            v-model="hiLoEnabled"
            label="HI/LO"
            class="hiLoCheckbox mt-0"
            hide-details
            dense
        />
      </template>
    </v-text-field>

    <v-select
        v-if="hiLoEnabled"
        v-model="textValue"
        :items="hiLoValues"
        hide-details
        outlined
        dense
    >
      <template v-slot:append-outer>
        <v-checkbox
            v-model="hiLoEnabled"
            label="HI/LO"
            class="hiLoCheckbox mt-0"
            hide-details
            dense
        />
      </template>
    </v-select>
  </div>
</template>
<script>
export default {
  name: 'BloodGlucose',
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      hiLoValues: ['HI', 'LO'],
    };
  },

  computed: {
    hiLoEnabled: {
      get() {
        return this.value && this.hiLoValues.includes(this.value);
      },
      set(value) {
        if (value) {
          this.textValue = 'HI';
        } else {
          this.textValue = null;
        }
      },
    },
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
<style lang="scss">
.bloodGlucoseControl {
  .v-input__append-outer {
    margin-top: 5px !important;
  }
  .hiLoCheckbox label {
    top: 0px !important;
  }
}
</style>
