<template>
  <v-dialog
    v-model="showEditPopup"
    persistent
    class="hospital-edit"
    max-width="600"
    @close="$emit('close')"
  >
    <v-card v-if="hospital">
      <v-container>
        <v-card-title class="headline pt-0">
          Edit Hospital
        </v-card-title>
        <v-card-text class="pt-0">
          <div
            v-if="loading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              color="grey darken"
              center
              indeterminate
            />
          </div>
          <div v-else>
            <v-alert
                v-model="errorAlert"
                type="error"
                outlined
                dismissible
                transition="scale-transition"
                class="mb-4"
            >
              <div v-html="errorMessage" />
            </v-alert>

            <v-layout wrap>

              <v-flex xs12>
                <v-text-field
                  v-model="hospital.name"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.name')"
                  :error-messages="errors.collect('name')"
                  name="name"
                  maxlength="255"
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="hospital.address"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.address')"
                  :error-messages="errors.collect('address')"
                  name="address"
                  maxlength="255"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.city"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.city')"
                  :error-messages="errors.collect('city')"
                  name="city"
                  maxlength="255"
                />
              </v-flex>
              <v-flex
                xs2
                offset-xs2
              >
                <v-select
                  v-model="hospital.stateID"
                  v-validate="'required'"
                  :items="states"
                  :label="$t('hospitals.editPopup.state')"
                  :error-messages="errors.collect('state')"
                  name="state"
                />
              </v-flex>
              <v-flex
                xs2
                offset-xs1
              >
                <v-text-field
                  v-model="hospital.zip"
                  v-validate="'required'"
                  :label="$t('hospitals.editPopup.zip')"
                  :error-messages="errors.collect('zip')"
                  name="zip"
                  maxlength="5"
                />
              </v-flex>

              <v-flex xs12>
                <i>Click by map to set location:</i><br />
                <div
                    ref="googleMap"
                    class="google-map"
                    v-bind:style="{ height: '200px' }"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                    v-model="hospital.latitude"
                    v-validate="'required|decimal'"
                    :label="$t('hospitals.editPopup.latitude')"
                    :error-messages="errors.collect('latitude')"
                    name="latitude"
                    @change="updateMarker"
                />
              </v-flex>

              <v-flex
                  xs5
                  offset-xs2
              >
                <v-text-field
                    v-model="hospital.longitude"
                    v-validate="'required|decimal'"
                    :label="$t('hospitals.editPopup.longitude')"
                    :error-messages="errors.collect('longitude')"
                    name="longitude"
                    @change="updateMarker"
                />
              </v-flex>

              <v-flex
                xs12
              >
                <v-text-field
                  v-model="hospital.email"
                  v-validate="'required|email'"
                  :label="$t('hospitals.editPopup.email')"
                  :error-messages="errors.collect('email')"
                  name="email"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  v-model="hospital.phone"
                  v-validate="'required|max:40'"
                  :label="$t('hospitals.editPopup.phone')"
                  :error-messages="errors.collect('phone')"
                  name="phone"
                  counter="40"
                />
              </v-flex>

              <v-flex
                xs5
                offset-xs2
              >
                <v-text-field
                  v-model="hospital.fax"
                  v-validate="'max:40'"
                  :label="$t('hospitals.editPopup.fax')"
                  :error-messages="errors.collect('fax')"
                  name="fax"
                  counter="40"
                />
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            :loading="saving"
            color="primary"
            text
            @click.native="validateAndSave()"
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { LOAD_STATES } from '@/store/LookupStates/actions';
import hospitalsApi from '@/api/hospitals';
import errorAlertMixins from '@/mixins/errorAlertMixins';

/* eslint-disable no-undef */
export default {
  name: 'MyHospitalEditPopup',
  mixins: [errorAlertMixins],

  data() {
    return {
      showEditPopup: false,
      saving: false,
      loading: false,
      hospital: {},
      map: null,
      marker: null,
    };
  },

  computed: {
    /**
     * Lookup states.
     */
    states() {
      return this.$store.state.lookupStates.states.map(state => state.stateID);
    },
  },
  methods: {
    ...mapActions({
      loadStates: `lookupStates/${LOAD_STATES}`,
    }),

    async show(hospital) {
      this.hospital = _.cloneDeep(hospital);
      this.showEditPopup = true;
      this.map = null;
      await this.$validator.reset();
      this.fetchData();
    },

    async fetchData() {
      try {
        this.loading = true;
        await this.loadStates();
      } finally {
        this.loading = false;
        this.$nextTick(async () => {
          this.initMap();
        });
      }
    },

    initMap() {
      const latLng = new google.maps.LatLng(this.hospital.latitude, this.hospital.longitude);

      this.map = new google.maps.Map(this.$refs.googleMap, {
        zoom: 15,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        title: this.hospital.name,
      });

      google.maps.event.addListener(this.map, 'click', (event) => {
        this.hospital.latitude = Number((event.latLng.lat()).toFixed(6));
        this.hospital.longitude = Number((event.latLng.lng()).toFixed(6));
        this.marker.setMap(null);
        this.marker = new google.maps.Marker({
          position: event.latLng,
          map: this.map,
          title: this.hospital.name,
        });
      });
    },

    /**
     * Validate hospital values and save if it's ok.
     */
    async validateAndSave() {
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      try {
        this.saving = true;
        await hospitalsApi.saveHospitalProfile(this.hospital);
        this.showEditPopup = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },

    /**
     * Update marker position
     */
    updateMarker() {
      const latLng = new google.maps.LatLng(this.hospital.latitude, this.hospital.longitude);
      this.map.setCenter(latLng);
      this.marker.setMap(null);
      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        title: this.hospital.name,
      });
    },
  },
};
</script>
