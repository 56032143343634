<template>
  <div v-if="!loading">
    <v-simple-table
        id="skills-table"
        class="skills-table"
        dense
        fixed-header
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th
              class="text-left skill-column"
          >
            Skill
          </th>
          <th
              v-for="license in licenses"
              v-bind:key="'th_' + license.licenseId"
              :class="['text-center']"
          >
            {{ license|licenseNameFilter }}
          </th>
          <th />
        </tr>
        </thead>
        <tbody class="skills-table-tbody">
          <tr
              v-for="(item, index) in tableRows"
              v-bind:key="'tr_' + index"
          >
            <td class="skill-column">
              <span v-if="item.id && !item.edit">{{ item.skill }}</span>
              <v-text-field
                  v-else
                  v-model="item.skill"
                  :error="item.error"
                  :hide-details="!item.error"
                  :error-messages="item.error ? ['The name is required'] : []"
                  placeholder="Skill"
                  class="mt-0 mb-2 pt-0"
                  style="min-width: 200px"
                  @keyup="changeSkill(index, item.skill)"
              />
            </td>

            <td
                v-for="license in licenses"
                v-bind:key="'skill_license_td_' + license.licenseId"
                class="text-center"
            >
              <v-simple-checkbox
                  v-model="item[license.licenseId]"
                  :ripple="false"
                  @click="setSkillLicense(index, license)"
              />
            </td>

            <td>
              <div
                  class="table-actions float-right"
              >
                <v-btn
                    v-if="item.id && !item.edit"
                    class="mr-3 float-right"
                    icon
                    @click="editSkill(item.id)"
                >
                  <v-icon color="grey darken-2">edit</v-icon>
                </v-btn>
                <v-btn
                    v-if="item.id && item.edit"
                    class="mr-3 float-right"
                    icon
                    @click="applySkillChanges(item.id)"
                >
                  <v-icon color="grey darken-2">check</v-icon>
                </v-btn>
                <v-btn
                    class="mr-3 float-right"
                    icon
                    @click="deleteSkill(index)"
                >
                  <v-icon color="grey darken-2">delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider class="mb-2"/>
    <v-btn
        color="primary"
        text
        @click="addSkillRow"
    >+ Add Skill</v-btn>
    <confirmation ref="confirmationPopup"/>
  </div>
</template>
<script>
import _ from 'lodash';
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import ceHoursSettings from '@/api/ceHoursSettings';
import Confirmation from '@/components/Confirmation';

export default {
  name: 'SkillsList',
  mixins: [componentMixins],
  components: {
    Confirmation,
  },
  filters: {
    licenseNameFilter(license) {
      const map = {
        9925005: 'EMT',
      };
      if (map[license.licenseId]) {
        return map[license.licenseId];
      }
      return license.name;
    },
  },
  data() {
    return {
      licenses: [],
      settingsData: [],
      settingsDataOriginal: [],
      editMode: [],
      errorMode: [],
    };
  },
  computed: {
    tableRows() {
      const rows = [];
      this.settingsData.forEach((item, index) => {
        const row = {
          id: item.id,
          skill: item.skill,
          edit: (item.id && this.editMode.includes(item.id)) || !item.id,
          error: this.errorMode.includes(index),
        };

        const enabledLicenses = [];
        if (item.licenseTypes) {
          item.licenseTypes.forEach(licenseType => {
            enabledLicenses.push(parseInt(licenseType.licenseTypeId, 10));
          });
        }
        this.licenses.forEach(license => {
          row[license.licenseId] = enabledLicenses.includes(parseInt(license.licenseId, 10));
        });
        rows[index] = row;
      });
      return rows;
    },

    skillIdIndexMap() {
      const map = {};
      this.settingsData.forEach((item, index) => {
        if (item.id) {
          map[item.id] = index;
        }
      });
      return map;
    },
  },
  mounted() {
    this.loading = true;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        await this.fetchLicenses();
        await this.fetchSkills();
      } finally {
        this.loading = false;
      }
    },

    async fetchLicenses() {
      this.licenses = [];
      const licenseHours = await ceHoursSettings.getLicenseHours();
      const licenses = [];
      licenseHours.forEach(item => {
        if (item.clientHours) {
          licenses.push({
            licenseId: item.licenseTypeId,
            name: item.licenseTypeName,
          });
        }
      });
      this.licenses = licenses;
    },

    async fetchSkills() {
      const skills = await ceHoursSettings.getSkills();
      this.settingsData = _.cloneDeep(skills.results);
      this.settingsDataOriginal = _.cloneDeep(skills.results);
    },

    setSkillLicense(index, license) {
      const settingsData = _.cloneDeep(this.settingsData);
      const skillItem = settingsData[index];
      let licenseTypeIndex = null;
      if (skillItem.licenseTypes) {
        skillItem.licenseTypes.forEach((item, licenseTypeIndx) => {
          if (parseInt(license.licenseId, 10) === parseInt(item.licenseTypeId, 10)) {
            licenseTypeIndex = licenseTypeIndx;
          }
        });
      }
      if (licenseTypeIndex !== null) {
        skillItem.licenseTypes.splice(licenseTypeIndex, 1);
      } else {
        if (!skillItem.licenseTypes) {
          skillItem.licenseTypes = [];
        }
        skillItem.licenseTypes.push({
          licenseTypeId: parseInt(license.licenseId, 10),
          licenseTypeName: license.name,
        });
      }
      this.settingsData = settingsData;
    },

    deleteSkill(index) {
      const settingsData = _.cloneDeep(this.settingsData);
      const skillItem = settingsData[index];
      if (skillItem.id) {
        this.$refs.confirmationPopup.showConfirm(
          'Confirmation',
          `Do you really want to delete the skill${skillItem.skill ? ` "${skillItem.skill}"` : ''} ?`,
          async () => {
            settingsData.splice(index, 1);
          },
        );
      } else {
        settingsData.splice(index, 1);
      }
      this.errorMode = [];
      this.settingsData = settingsData;
    },

    changeSkill(index, skill) {
      const settingsData = _.cloneDeep(this.settingsData);
      const skillItem = settingsData[index];
      skillItem.skill = skill;
      this.settingsData = settingsData;

      const errorModeIndex = this.errorMode.indexOf(index);
      if (errorModeIndex !== -1) {
        this.errorMode.splice(errorModeIndex, 1);
      }
    },

    async addSkillRow() {
      const settingsData = _.cloneDeep(this.settingsData);
      settingsData.push({
        id: null,
        skill: '',
        licenseTypes: null,
      });
      this.settingsData = settingsData;
      await this.$nextTick();
      const table = document.getElementById('skills-table');
      table.scrollTop = table.scrollHeight;
    },

    editSkill(skillId) {
      this.editMode.push(skillId);
    },

    applySkillChanges(skillId) {
      const index = this.skillIdIndexMap[skillId];
      if (!this.settingsData[index].skill) {
        this.errorMode.push(index);
        return;
      }

      const editModeIndex = this.editMode.indexOf(skillId);
      if (editModeIndex !== -1) {
        this.editMode.splice(editModeIndex, 1);
      }
    },

    async save() {
      try {
        if (!this.validate()) {
          return;
        }
        this.saving = true;
        await ceHoursSettings.saveSkills(this.settingsData);
        this.$emit('skillsListUpdated');
        await this.fetchSkills();

        this.settingsDataOriginal = _.cloneDeep(this.settingsData);
        this.editMode = [];
        this.edited = false;
      } finally {
        this.saving = false;
      }
    },

    validate() {
      let isValid = true;
      this.settingsData.forEach((item, index) => {
        if (!item.skill) {
          if (!this.errorMode.includes(index)) {
            this.errorMode.push(index);
          }
          isValid = false;
        }
      });
      return isValid;
    },

    /**
     * Triggers when License Hours updated when skills list already loaded and edited.
     * It needs to sync updated available licenses.
     */
    async updateLicenseHoursHandler() {
      try {
        this.loading = true;
        const licenses = _.cloneDeep(this.licenses);
        const licensesIds = licenses.map(license => license.licenseId);

        await this.fetchLicenses();
        const newLicensesIds = this.licenses.map(license => license.licenseId);

        const deletedLicenseIds = _.difference(licensesIds, newLicensesIds);
        const addedLicenseIds = _.difference(newLicensesIds, licensesIds);

        if (deletedLicenseIds.length || addedLicenseIds.length) {
          const skills = await ceHoursSettings.getSkills();
          this.settingsDataOriginal = _.cloneDeep(skills.results);
        }

        if (deletedLicenseIds.length) {
          this.removeDeletedLicenses(deletedLicenseIds);
        }

        if (addedLicenseIds.length) {
          await this.addNewLicenses(addedLicenseIds);
        }
      } finally {
        this.loading = false;
      }
    },

    async addNewLicenses(addedLicenseIds) {
      const settingsData = _.cloneDeep(this.settingsData);
      const freshSettingsData = _.cloneDeep(this.settingsDataOriginal);

      const freshSettingsDataMap = {};
      freshSettingsData.forEach(item => {
        const skill = item;
        const licenseTypes = {};
        if (skill.licenseTypes) {
          skill.licenseTypes.forEach(licenseType => {
            licenseTypes[licenseType.licenseTypeId] = licenseType;
          });
        }
        skill.licenseTypes = licenseTypes;
        freshSettingsDataMap[skill.id] = skill;
      });

      settingsData.forEach(item => {
        const skill = item;
        const freshSkill = freshSettingsDataMap[item.id];
        if (!freshSkill) {
          return;
        }
        addedLicenseIds.forEach(licenseId => {
          const freshLicenseType = freshSkill.licenseTypes[licenseId];
          if (freshLicenseType) {
            if (!skill.licenseTypes) {
              skill.licenseTypes = [];
            }
            skill.licenseTypes.push(freshLicenseType);
          }
        });
      });
      this.settingsData = settingsData;
    },

    removeDeletedLicenses(deletedLicenseIds) {
      const settingsData = _.cloneDeep(this.settingsData);
      settingsData.forEach(item => {
        const skill = item;
        if (skill.licenseTypes) {
          deletedLicenseIds.forEach(licenseId => {
            skill.licenseTypes.forEach((licenseType, index) => {
              if (licenseType.licenseTypeId === licenseId) {
                skill.licenseTypes.splice(index, 1);
              }
            });
          });
          if (!skill.licenseTypes.length) {
            skill.licenseTypes = null;
          }
        }
      });
      this.settingsData = settingsData;
    },
  },
};
</script>
<style>
.skills-table {
  max-height: calc(100vh - 300px);
  overflow: auto;

  table {
    table-layout: fixed;
  }

  th {
    word-wrap: normal;
  }

  .v-data-table__wrapper {
    overflow: unset;
  }

  .table-actions {
    width: 100px;
    visibility: hidden;
  }
  tr:hover .table-actions {
    visibility: visible;
  }
  .skill-column {
    width: 200px;
  }

  thead {
    position: sticky;
    z-index: 2;
  }
}
</style>
