import { mapState } from 'vuex';
import baseUrl from '@/baseUrl';
import { SET_INITIALIZING } from '@/store/mutations';
import USER_ROLES from '@/enums/userRoles';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

export default {
  computed: {
    ...mapState(['initializingUser', 'user']),
  },
  watch: {
    initializingUser() {
      this.redirectAuthenticated();
    },
  },
  beforeCreate() {
    this.$store.commit(SET_INITIALIZING, true);
  },
  created() {
    this.redirectAuthenticated();
  },
  methods: {
    redirectAuthenticated() {
      if (!this.initializingUser) {
        if (this.$store.state.user) {
          let url = `${baseUrl(this.$store.state.sitecode)}`;

          if (
            this.$store.state.siteInfo
              && this.$store.state.siteInfo.clientSiteType === CLIENT_SITE_TYPES.CE_ENTRY
          ) {
            url += '/ce-home';
          } else {
            switch (this.$store.state.user.userTypeId) {
              case USER_ROLES.CLIENT_CAPTAIN_USER:
              case USER_ROLES.CLIENT_FIREFIGHTER_USER: {
                url += '/incidents/regular';
                break;
              }
              case USER_ROLES.HOSPITAL_USER:
              case USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED:
              case USER_ROLES.HOSPITAL_ADMIN:
                url += '/incidents';
                break;
              default: {
                url += '/dashboard';
              }
            }
          }
          window.location.replace(url);
        } else {
          this.$store.commit(SET_INITIALIZING, false);
        }
      }
    },
  },
};
