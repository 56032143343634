import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    units: [],
    unitsLoading: false,
    currentIncident: null,
    pagination: {
      page: 1,
      sortDesc: [true],
      itemsPerPage: 25,
      sortBy: ['IncidentDateTime'],
      totalItems: 0,
    },
    paginationUnits: {
      page: 1,
      sortDesc: [],
      itemsPerPage: 25,
      sortBy: [],
      totalItems: 0,
    },
    filters: {
      clientId: null,
      incidentDate: null,
      incidentNumber: null,
    },
    unitsFilters: {
      clientId: null,
      incidentNumber: null,
    },
    activeTab: 0,
  },
  actions,
  mutations,
  getters: {},
};
