<template>
  <div
      v-if="incident"
      class="fields-container"
      v-bind:style="{ height: height + 'px' }"
  >
    <template v-for="(field, index) in fieldList">
      <v-row v-bind:key="'field_' + index ">
        <v-col
          sm="6"
          class="text-right"
        >
          <span class="field-name">{{ field.name }}</span>
        </v-col>
        <v-col
          sm="6"
          class="text-left"
        >
          <span v-if="typeof field.field === 'string'">
            {{ incident.data[field.field] || '-' }}
          </span>
          <span v-if="typeof field.field === 'object' && field.type === 'datetime'">
            {{ incident.data[field.field[0]] && incident.data[field.field[1]]
              ? incident.data[field.field[0]] + ' ' + incident.data[field.field[1]]
              : '-' }}
          </span>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ViewCategoryFields',
  props: {
    height: {
      type: Number,
      required: true,
    },
    fieldList: {
      type: Array,
      required: true,
    },
    incident: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.fields-container {
  overflow-y: scroll;
  overflow-x: hidden;
  .field-name {
    color: #9a9a9a;
  }
}
</style>
