<template>
  <v-card class="white elevation-3 ma-4">
    <exports-toolbar
        :filters="filters"
        :show-clients-filter="true"
        @filtersUpdate="filtersUpdate"
    />
    <exports-table
        v-if="filters.clientId"
        :items="entity.items"
        :pagination="pagination"
        :total-items="pagination.totalItems"
        :loading="loading"
        :use-impersonate="true"
        @update:pagination="updatePagination"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import ExportsTable from '@/components/ExternalServiceExports/ExportsTable';
import ExportsToolbar from '@/components/ExternalServiceExports/ExportsToolbar';
import { ADMIN_LOAD_HDE_EXPORTS } from '@/store/HDEExports/actions';
import {
  SET_HDE_EXPORTS_FILTERS,
  SET_HDE_EXPORTS_PAGINATION,
} from '@/store/HDEExports/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';
import { SET_TITLE } from '@/store/mutations';

const { mapState, mapMutations } = createNamespacedHelpers('hdeExports');

export default {
  components: {
    ExportsTable,
    ExportsToolbar,
  },
  mixins: [filtersAndTablesGeneralMixins],

  data() {
    return {
      exportLoading: false,
      resendLoading: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('externalServiceExports.hdeHeader'));
  },

  methods: {
    ...mapMutations({
      setPagination: SET_HDE_EXPORTS_PAGINATION,
      setFilters: SET_HDE_EXPORTS_FILTERS,
    }),
    ...mapActions({
      loadItems: `hdeExports/${ADMIN_LOAD_HDE_EXPORTS}`,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Reloads paginated list for current filter.
     */
    async reloadItems() {
      if (!this.filters.clientId) {
        return;
      }
      this.loading = true;

      try {
        await this.loadItems();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
