export default {
  header: 'Release Notes',
  notReleasedYet: '[Not released yet]',
  releaseDate: 'Release Date',
  feature: 'Feature',
  bug: 'Bug',
  all: 'All',
  template: 'Template',
  backend: 'Backend',
  ios: 'iOS',
};
