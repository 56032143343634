<template>
  <v-card class="elevation-20">
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title class="font-weight-medium">SMS Verification</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form @keyup.native.enter="checkRequest">
        <v-alert
          :value="errorAlert"
          type="error"
          outlined
          transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>
        <v-text-field
          ref="code"
          v-model="code"
          v-validate="'required'"
          :error-messages="errors.collect('code')"
          prepend-icon="lock"
          name="code"
          label="Verification Code"
          type="text"
          maxlength="6"
        />

        <i>Please enter the SMS Verification code that was sent to your phone.
          If you did not receive a code, you can retry again after one minute
          <span v-if="hasVerifiedEmail"> or use <a
            v-if="!sentCodeToEmail"
            :class="sendingToEmail ? $style.loadingLink : ''"
            @click="useEmailVerification"
          >verification through email</a>
            <span v-if="sentCodeToEmail">verification through email</span>
          </span>
        </i>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <a
        class="ml-3"
        @click="$emit('backToLogin')"
      >Back to Login</a>

      <v-spacer/>

      <v-btn
          :loading="resending"
          :disabled="loading || resendTime > 0"
          class="login__button"
          color="primary"
          text
          @click="resendCode"
      >
        Resend Code<span
          v-if="resendTime > 0"
          class="ml-1"
        >({{ resendTime }})</span>
      </v-btn>

      <v-btn
        :loading="loading"
        :disabled="loading"
        class="login__button"
        color="primary"
        @click="checkRequest"
      >
        Check Code
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import auth from '@/api/auth';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import loginMixins from '@/mixins/loginMixins';

export default {
  name: 'VerificationSmsCodeForm',
  mixins: [errorAlertMixins, loginMixins],
  props: {
    trustBrowser: {
      type: Boolean,
      default: true,
      required: true,
    },
    token: {
      type: String,
      default: null,
      required: false,
    },
    hasVerifiedEmail: {
      type: Boolean,
      required: true,
    },
    confirmIp: {
      type: Boolean,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      code: null,
      loading: false,
      resending: false,
      sendingToEmail: false,
      resendTime: 60,
      timer: null,
      sentCodeToEmail: false,
      dictionary: {
        attributes: {
          code: 'Verification Code',
        },
      },
    };
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
    this.startTimer();
  },
  methods: {
    async checkRequest() {
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.hideError();
      this.loading = true;
      try {
        const user = await auth.verifySms(this.token, this.code, this.confirmIp, this.trustBrowser);
        this.afterLogin(user);
      } catch (error) {
        if (error.response.data.errorCode === 'VERIFICATION_CODE_RESEND') {
          this.showError(error.response.data.message);
          clearTimeout(this.timer);
          this.resendTime = 0;
        } else {
          this.parseErrorResponse(error.response);
        }
      } finally {
        this.loading = false;
      }
    },

    /**
     * Resend verification code.
     */
    async resendCode() {
      try {
        this.resending = true;
        await auth.sendSmsCode(this.token);
        this.hideError();
        this.startTimer();
      } finally {
        this.resending = false;
      }
    },

    /**
     * Start timer.
     */
    startTimer() {
      this.resendTime = 60;
      this.timer = setInterval(() => {
        if (this.resendTime > 0) {
          this.resendTime--;
        } else {
          clearTimeout(this.timer);
        }
      }, 1000);
    },

    /**
     * Sends code to email and shows email verification dialog.
     */
    async useEmailVerification() {
      if (this.sentCodeToEmail || this.sendingToEmail) {
        return;
      }
      try {
        this.sendingToEmail = true;
        await auth.sendEmailCode(this.token);
        this.sentCodeToEmail = true;
        this.$emit('emailCodeSent');
      } catch (error) {
        this.parseErrorResponse(error.response);
      } finally {
        this.sendingToEmail = false;
      }
    },
  },
};
</script>
<style lang="scss" module>
.loadingLink {
  cursor: wait !important;
  color: #545454 !important;
}
</style>
