import defaultState from '@/store/IncidentView/defaultState';

export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_INCIDENT_FIELDS = 'SET_INCIDENT_FIELDS';
export const SET_INCIDENT = 'SET_INCIDENT';
export const SET_TRANSMISSION_HISTORY = 'SET_TRANSMISSION_HISTORY';

export default {
  /**
   * Clear state.
   *
   * @param {Object} state - Entity state.
   */
  [CLEAR_STATE]: (state) => {
    Object.assign(state, defaultState());
  },

  /**
   * Saves list of incident fields.
   *
   * @param {Object} state - State.
   * @param {array} data - Data of table fields.
   */
  [SET_INCIDENT_FIELDS]: (state, data) => {
    state.incidentFields = data;
  },

  /**
   * Saves incident data.
   *
   * @param {Object} state - State.
   * @param {array} data - Data incident
   */
  [SET_INCIDENT]: (state, data) => {
    state.incident = data;
  },

  /**
   * Saves incident data.
   *
   * @param {Object} state - State.
   * @param {array} data - Data transmission history
   */
  [SET_TRANSMISSION_HISTORY]: (state, data) => {
    state.transmissionHistory = data;
  },
};
