<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span>{{ isNew ? 'Add Education Period' : 'Edit Education Period' }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
          v-if="dialog"
          class="pb-0"
      >
        <v-alert
            v-if="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
            class="mt-2"
        >
          {{ errorMessage }}
        </v-alert>

        <v-layout wrap>
          <v-flex xs5>
            <v-menu
                v-model="dateFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    ref="dateFrom"
                    v-model="dateFromFormatted"
                    v-validate="{ required: true, date_format: 'MM/dd/yyyy', before: 'dateTo' }"
                    :error-messages="errors.collect('dateFrom')"
                    name="dateFrom"
                    label="Start Date"
                    readonly
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="period.dateFrom"
                  no-title
                  @input="dateFromMenu = false;"
              />
            </v-menu>
          </v-flex>
          <v-flex
            xs6
            offset-xs1
          >
            <v-menu
                ref="datePicker"
                v-model="dateToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    ref="dateTo"
                    v-model="dateToFormatted"
                    v-validate="{ required: true, date_format: 'MM/dd/yyyy', after: 'dateFrom' }"
                    :error-messages="errors.collect('dateTo')"
                    name="dateTo"
                    label="End Date"
                    readonly
                    clearable
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="period.dateTo"
                  no-title
                  @input="dateToMenu = false;"
              />
            </v-menu>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="period.goal"
              v-validate="{ required: true, integer: true, min_value: 1 }"
              :error-messages="errors.collect('goal')"
              label="Hours Required"
              prepend-inner-icon="mdi-clock-time-two-outline"
              name="goal"
            />
          </v-flex>
          <v-flex xs12>
            <v-checkbox
              v-model="period.autoRenewal"
              label="Auto-Renewal"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            color="blue darken-1"
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            :loading="saving"
            @click="validateAndSave"
        >
          <template>Save</template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';
import moment from 'moment';
import ceHours from '@/api/ceHours';
import ceHoursSettings from '@/api/ceHoursSettings';

export default {
  name: 'EditPeriodPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      isNew: true,
      userId: null,
      dateFromMenu: false,
      dateToMenu: false,
      saving: false,
      periodId: null,
      period: {
        dateFrom: null,
        dateTo: null,
        goal: null,
        autoRenewal: true,
      },
      datePicker: false,
      dictionary: {
        attributes: {
          dateFrom: 'Start Date',
          dateTo: 'End Date',
          goal: 'Hours Required',
        },
      },
    };
  },
  computed: {
    /**
     * Format date from to MM/DD/YYYY format.
     *
     * @return {Boolean}
     */
    dateFromFormatted: {
      get() {
        return this.period.dateFrom
          ? moment.parseZone(this.period.dateFrom).format('MM/DD/YYYY')
          : null;
      },
      set(value) {
        if (value) {
          throw new Error('Set value is not supported for this control');
        } else {
          this.period.dateFrom = null;
        }
      },
    },

    /**
     * Format date from to MM/DD/YYYY format.
     *
     * @return {Boolean}
     */
    dateToFormatted: {
      get() {
        return this.period.dateTo
          ? moment.parseZone(this.period.dateTo).format('MM/DD/YYYY')
          : null;
      },
      set(value) {
        if (value) {
          throw new Error('Set value is not supported for this control');
        } else {
          this.period.dateTo = null;
        }
      },
    },
  },
  watch: {
    dateFromFormatted() {
      this.$nextTick(async () => {
        this.$validator.validate('dateTo');
      });
    },
    dateToFormatted() {
      this.$nextTick(async () => {
        this.$validator.validate('dateFrom');
      });
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Show dialog.
     */
    async show(userId, licenseTypeId = null, period = null) {
      this.loading = true;
      this.dialog = true;
      this.isNew = true;
      this.userId = userId;
      this.periodId = null;
      const periodData = {
        dateFrom: null,
        dateTo: null,
        goal: null,
        autoRenewal: true,
      };
      if (period) {
        this.isNew = false;
        this.periodId = period.id;
        periodData.dateFrom = period.dateFrom;
        periodData.dateTo = period.dateTo;
        periodData.goal = period.goal;
        periodData.autoRenewal = period.autoRenewal;
      } else {
        const goalLicenseTypeMap = await this.getGoalLicenseTypeMap();
        if (licenseTypeId
            && Object.prototype.hasOwnProperty.call(goalLicenseTypeMap, licenseTypeId)
        ) {
          periodData.goal = goalLicenseTypeMap[licenseTypeId];
        }
      }
      this.period = periodData;
      this.loading = false;
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
    },

    /**
     * Get map of license type to hours goal.
     */
    async getGoalLicenseTypeMap() {
      const goalLicenseTypeMap = [];
      const response = await ceHoursSettings.getLicenseHours();
      response.filter(el => el.clientHours !== null)
        .forEach(el => {
          goalLicenseTypeMap[el.licenseTypeId] = el.clientHours.hours;
        });
      return goalLicenseTypeMap;
    },

    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        if (this.periodId) {
          await ceHours.updateCePeriod(this.periodId, this.period);
        } else {
          await ceHours.createCePeriod(this.userId, this.period);
        }
        this.dialog = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response, false);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
