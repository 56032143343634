import api from './api';

export function forgot(userName) {
  return api('v3')
    .post('forgot', {
      userName,
    })
    .then(response => response.data);
}

export function checkToken(token) {
  return api('v3')
    .get(`forgot/check-token/${token}`)
    .then(response => response.data);
}

export function changePassword(token, password) {
  return api('v3')
    .post('forgot/change', {
      token,
      password,
    })
    .then(response => response.data);
}
