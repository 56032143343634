<template>
  <div>
    <v-card
        :elevation="isPrinting ? 0 : 3"
        :flat="isPrinting"
        :class="['ma-4', 'ce-entry-card']"
    >
      <v-tabs
          v-if="!isPrinting"
          v-model="tabIndex"
          :class="[hideTabs ? 'hide-tabs' : '']"
      >
        <v-tab
            v-for="(item, index) in tabItems"
            :key="item.name"
            :tab-value="index"
            exact
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
            replace
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-card-title
          v-if="hideTabs && !isPrinting"
      >
        {{ tabItems[tabIndex].name }}
      </v-card-title>
      <v-divider v-if="!isPrinting"/>

      <v-tabs-items :value="tabIndex">
        <v-tab-item>
          <my-license
            ref="myLicense"
            @viewEntry="showViewEntryPopup"
          />
        </v-tab-item>

        <v-tab-item v-if="$can('view', 'ceHoursAgencyOverview')">
          <agency-overview
            ref="agencyOverview"
            @viewEntry="showViewEntryPopup"
          />
        </v-tab-item>

        <v-tab-item v-if="$can('view', 'ceHoursSkillsOverview')">
          <skills-overview
              ref="skillsOverview"
          />
        </v-tab-item>

        <v-tab-item>
          <entries-toolbar
            :filters="entriesFilters"
            :show-attendee-filter="$can('seeAttendeesFilter', 'ceHoursEntriesToolbar')"
            @filtersUpdate="entriesFiltersUpdate"
            @clickAdd="$refs.editEntryPopup.show()"
          />
          <entries-table
            ref="entriesTable"
            :filters="entriesFilters"
            :show-attendees-column="$can('seeAttendeesColumn', 'ceHoursEntriesTable')"
            :show-skills-column="$can('seeSkillsColumn', 'ceHoursEntriesTable')"
            :show-evaluations-column="$can('seeEvaluationsColumn', 'ceHoursEntriesTable')"
            :show-admin-tools="$can('seeAdminTools', 'ceHoursEntriesTable')"
            :show-status-column="$can('seeStatusColumn', 'ceHoursEntriesTable')"
            @view="showViewEntryPopup"
            @edit="showEditEntryPopup"
            @approve="approveEntry"
            @reject="rejectEntry"
            @delete="deleteEntry"
            @evaluate="evaluateEntry"
            @evaluationAttendeesClick="showEvaluationAttendees"
          />
        </v-tab-item>

        <v-tab-item v-if="$can('view', 'ceSettings')">
          <settings
            @licenseHoursUpdated="setForceUpdate"
            @licenseSkillsUpdated="setForceUpdate"
            @skillsListUpdated="setForceUpdate"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <view-entry-popup
      ref="viewEntryPopup"
      :show-admin-tools="$can('seeAdminTools', 'ceHoursEntriesTable')"
      :show-attendees="$can('seeAttendees', 'ceHoursEntriesDialog')"
      :can-see-instructor-based-type="$can('editInstructorBasedType', 'ceHoursEntriesDialog')"
      @approve="approveEntry"
      @reject="rejectEntry"
    />
    <edit-entry-popup
        ref="editEntryPopup"
        :show-attendees="$can('addAttendees', 'ceHoursEntriesDialog')"
        :show-skills="$can('addSkills', 'ceHoursEntriesDialog')"
        :show-external-ce-entry-alert="$can('seeExternalCeEntryAlert', 'ceHoursEntriesDialog')"
        :can-edit-instructor-based-type="$can('editInstructorBasedType', 'ceHoursEntriesDialog')"
        :can-edit-evaluation-required="$can('editEvaluationRequired', 'ceHoursEntriesDialog')"
        @saved="savedEntryHandler"
    />
    <evaluate-entry-popup
        ref="evaluateEntryPopup"
        @evaluated="evaluatedEntryHandler"
    />
    <evaluation-attendees-popup
        ref="evaluationAttendeesPopup"
    />
    <confirmation ref="confirmationPopup" />
    <confirmation ref="rejectConfirmationPopup">
      <v-card-text>
        <v-textarea
          v-model="rejectComment"
          label="Rejection reason"
          outlined
          rows="2"
          hide-details
        />
      </v-card-text>
    </confirmation>
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import EntriesTable from '@/components/CeEntry/EntriesTable';
import EditEntryPopup from '@/components/CeEntry/EditEntryPopup';
import AgencyOverview from '@/components/CeEntry/AgencyOverview';
import ViewEntryPopup from '@/components/CeEntry/ViewEntryPopup';
import EntriesToolbar from '@/components/CeEntry/EntriesToolbar';
import Confirmation from '@/components/Confirmation';
import ceHours from '@/api/ceHours';
import ROUTES from '@/enums/routes';
import CE_ENTRY_TABS from '@/enums/ceEntryTabs';
import MyLicense from '@/components/CeEntry/MyLicense';
import Settings from '@/components/CeEntry/Settings/Settings';
import SkillsOverview from '@/components/CeEntry/SkillsOverview';
import EvaluateEntryPopup from '@/components/CeEntry/EvaluateEntryPopup';
import EvaluationAttendeesPopup from '@/components/CeEntry/EvaluationAttendeesPopup';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

export default {
  components: {
    EvaluationAttendeesPopup,
    EvaluateEntryPopup,
    SkillsOverview,
    Settings,
    MyLicense,
    Confirmation,
    EntriesToolbar,
    ViewEntryPopup,
    AgencyOverview,
    EditEntryPopup,
    EntriesTable,
  },
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 0,
      tabIndexInit: true,
      forceUpdateAgencyOverview: false,
      forceUpdateMyLicense: false,
      forceUpdateSkillsOverview: false,
      entriesFilters: {
        dateFrom: null,
        dateTo: null,
        search: null,
        attendeeId: null,
      },
      rejectComment: null,
    };
  },
  computed: {
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    tabItems() {
      const tabItems = [
        {
          id: CE_ENTRY_TABS.MY_LICENSE,
          name: 'My License',
        },
      ];
      if (this.$can('view', 'ceHoursAgencyOverview')) {
        tabItems.push({
          id: CE_ENTRY_TABS.AGENCY_OVERVIEW,
          name: 'Agency Overview',
        });
      }
      if (this.$can('view', 'ceHoursSkillsOverview')) {
        tabItems.push({
          id: CE_ENTRY_TABS.SKILLS_OVERVIEW,
          name: 'Skills Overview',
        });
      }
      tabItems.push({
        id: CE_ENTRY_TABS.ENTRIES,
        name: 'CE Entry',
      });
      if (this.$can('view', 'ceSettings')) {
        tabItems.push({
          id: CE_ENTRY_TABS.SETTINGS,
          name: 'Settings',
        });
      }
      return tabItems;
    },
    tabItemsIdMap() {
      const map = {};
      this.tabItems.forEach((item, index) => {
        map[item.id] = index;
      });
      return map;
    },
    hideTabs() {
      return this.$store.state.siteInfo.clientSiteType === CLIENT_SITE_TYPES.CE_ENTRY;
    },
  },
  watch: {
    tabIndex(value) {
      if (this.tabIndexInit) {
        this.tabIndexInit = false;
      } else {
        const tabId = this.tabItems[value].id;
        this.forceUpdateTab(tabId);
        this.changeUrl(this.tabItems[value].id);
      }
    },
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'CE Entry / Reports');
    if (this.tabIndex === this.tabItemsIdMap[this.tab]) {
      this.tabIndexInit = false;
    }
    this.tabIndex = this.tabItemsIdMap[this.tab];
  },
  methods: {
    changeUrl(tabId) {
      const routesMap = {};
      routesMap[CE_ENTRY_TABS.MY_LICENSE] = ROUTES.CE_ENTRY;
      routesMap[CE_ENTRY_TABS.AGENCY_OVERVIEW] = ROUTES.CE_ENTRY_AGENCY_OVERVIEW;
      routesMap[CE_ENTRY_TABS.SKILLS_OVERVIEW] = ROUTES.CE_ENTRY_SKILLS_OVERVIEW;
      routesMap[CE_ENTRY_TABS.ENTRIES] = ROUTES.CE_ENTRY_ENTRIES;
      routesMap[CE_ENTRY_TABS.SETTINGS] = ROUTES.CE_SETTINGS;
      const link = this.$router.resolve({ name: routesMap[tabId] }).href;
      window.history.replaceState({}, null, link);
      if (this.$refs.agencyOverview) {
        this.$refs.agencyOverview.resetView();
      }
    },
    entriesFiltersUpdate(filters) {
      this.entriesFilters = filters;
      this.$refs.entriesTable.fetchDataDebounce(true);
    },

    approveEntry(entry) {
      this.$refs.confirmationPopup.showConfirm(
        'Approvement',
        `Do you really want to approve the entry "${entry.courseTitle}"?`,
        async () => {
          await ceHours.approveEntry(entry.id);
          this.$refs.entriesTable.fetchDataDebounce();
          this.setForceUpdate();
        },
      );
    },

    rejectEntry(entry) {
      this.rejectComment = null;
      this.$refs.rejectConfirmationPopup.showConfirm(
        'Rejection',
        `Do you really want to reject the entry "${entry.courseTitle}"?`,
        async () => {
          await ceHours.rejectEntry(entry.id, {
            comment: this.rejectComment,
          });
          this.$refs.entriesTable.fetchDataDebounce();
        },
      );
    },

    deleteEntry(entry) {
      this.$refs.confirmationPopup.showConfirm(
        'Delete confirmation',
        `Do you really want to delete the entry "${entry.courseTitle}"?`,
        async () => {
          await ceHours.deleteEntry(entry.id);
          this.$refs.entriesTable.fetchDataDebounce();
          this.setForceUpdate();
        },
      );
    },

    evaluateEntry(entry) {
      this.$refs.evaluateEntryPopup.show(entry);
    },

    showEvaluationAttendees(entry) {
      this.$refs.evaluationAttendeesPopup.show(entry);
    },

    showViewEntryPopup(entry) {
      this.$refs.viewEntryPopup.show(entry.id);
    },

    showEditEntryPopup(entry) {
      this.$refs.editEntryPopup.show(entry.id);
    },

    setForceUpdate() {
      this.forceUpdateMyLicense = true;
      this.forceUpdateAgencyOverview = true;
      this.forceUpdateSkillsOverview = true;
    },

    forceUpdateTab(tab) {
      let update = false;
      if (tab === CE_ENTRY_TABS.MY_LICENSE && this.forceUpdateMyLicense) {
        update = true;
        this.forceUpdateMyLicense = false;
      }
      if (tab === CE_ENTRY_TABS.AGENCY_OVERVIEW && this.forceUpdateAgencyOverview) {
        update = true;
        this.forceUpdateAgencyOverview = false;
      }
      if (tab === CE_ENTRY_TABS.SKILLS_OVERVIEW && this.forceUpdateSkillsOverview) {
        update = true;
        this.forceUpdateSkillsOverview = false;
      }
      if (update) {
        if (this.$refs[tab]) {
          this.$refs[tab].updateData();
        }
      }
    },

    savedEntryHandler() {
      this.$refs.entriesTable.fetchDataDebounce(true);
      this.setForceUpdate();
    },

    evaluatedEntryHandler() {
      this.$refs.entriesTable.fetchDataDebounce(true);
      this.setForceUpdate();
    },
  },
};
</script>

<style>
.ce-entry-card .hide-tabs {
  display: none;
}
</style>
