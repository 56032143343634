<template>
  <v-card class="elevation-20">
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title class="font-weight-medium">Email Verification Code</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form @keyup.native.enter="checkRequest">
        <v-alert
          :value="errorAlert"
          type="error"
          outlined
          transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>
        <v-text-field
          ref="code"
          v-model="code"
          v-validate="'required'"
          :error-messages="errors.collect('code')"
          prepend-icon="lock"
          name="code"
          label="Verification Code"
          type="text"
          maxlength="6"
        />

        <i>Please enter the Verification code that was sent to your Email.</i>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <a
        class="ml-3"
        @click="$emit('backToLogin')"
      >Back to Login</a>

      <v-spacer/>

      <v-btn
        :loading="loading"
        :disabled="loading"
        class="login__button"
        color="primary"
        @click="checkRequest"
      >
        Check Code
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import auth from '@/api/auth';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import loginMixins from '@/mixins/loginMixins';

export default {
  name: 'VerificationEmailCodeForm',
  mixins: [errorAlertMixins, loginMixins],
  props: {
    trustBrowser: {
      type: Boolean,
      default: true,
      required: true,
    },
    token: {
      type: String,
      default: null,
      required: false,
    },
    confirmIp: {
      type: Boolean,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      code: null,
      loading: false,
      dictionary: {
        attributes: {
          code: 'Verification Code',
        },
      },
    };
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    async checkRequest() {
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.hideError();
      this.loading = true;
      try {
        const user = await auth.verifyEmail(
          this.token,
          this.code,
          this.confirmIp,
          this.trustBrowser,
        );
        this.afterLogin(user);
      } catch (error) {
        this.parseErrorResponse(error.response);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
