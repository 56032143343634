<template>
  <v-card
    elevation="3"
    class="ma-4"
  >
    <faxes-toolbar
      :filters="entity.filters"
      @tableUpdate="reloadItems"
    ></faxes-toolbar>
    <faxes-table
      :items="entity.items"
      :loading="loading"
      @tableUpdate="reloadItems"
      @showPopup="showResendPopup"
    ></faxes-table>

    <fax-resend-popup
      ref="resendPopup"
      @tableUpdate="reloadItems"
    ></fax-resend-popup>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import FaxesToolbar from '@/components/Faxes/FaxesToolbar';
import FaxesTable from '@/components/Faxes/FaxesTable';
import { GET_FAXES, GET_STATUSES } from '@/store/Faxes/actions';
import { SET_TITLE } from '@/store/mutations';
import FaxResendPopup from '@/components/Faxes/FaxResendPopup';

const { mapState, mapActions } = createNamespacedHelpers('faxes');

export default {
  name: 'Faxes',
  components: {
    FaxResendPopup,
    FaxesTable,
    FaxesToolbar,
  },
  async mounted() {
    this.$store.commit(SET_TITLE, 'Faxes');
    await this.getStatuses();
    this.reloadItems();
  },
  data() {
    return {
      /**
       * Flag for loading items.
       */
      loading: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },
  methods: {
    ...mapActions({
      getFaxesList: GET_FAXES,
      getStatuses: GET_STATUSES,
    }),

    /**
     * Reload list of faxes.
     * @returns {Promise<void>}
     */
    async reloadItems() {
      this.loading = true;

      try {
        await this.getFaxesList();
      } finally {
        this.loading = false;
      }
    },
    /**
     * Show resend popup.
     * @param fax
     */
    showResendPopup(fax) {
      this.$refs.resendPopup.showPopup(_.clone(fax));
    },
  },
};
</script>
