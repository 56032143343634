<template>
  <div>
    <sftp-status></sftp-status>
    <Providers
      :providers="entity.providers"
      :show-settings="true"
      :show-cloud-watch-url="true"
      :show-latest-cad-data-link="true"
      :show-failed-files="true"
    ></Providers>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { ADMIN_FETCH_PROVIDERS } from '@/store/CadMonitoring/actions';
import Providers from '@/components/CadMonitoring/Providers';
import SftpStatus from '@/components/CadMonitoring/SftpStatus';

const { mapActions, mapState } = createNamespacedHelpers('cadMonitoring');

export default {
  components: {
    SftpStatus,
    Providers,
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    ...mapActions({
      loadItems: ADMIN_FETCH_PROVIDERS,
    }),
  },
};
</script>

<style lang="scss">
.expansion-panel .card__text {
  padding: 12px 28px;
}
</style>
