import { render, staticRenderFns } from "./TransactionLogsTable.vue?vue&type=template&id=1b1b93e2&"
import script from "./TransactionLogsTable.vue?vue&type=script&lang=js&"
export * from "./TransactionLogsTable.vue?vue&type=script&lang=js&"
import style0 from "./TransactionLogsTable.vue?vue&type=style&index=0&id=1b1b93e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports