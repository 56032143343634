import api from './api';

export default {
  /**
   * Get report notification time and emails array.
   * @param params
   * @returns {Promise<T>}
   */
  getReportNotification(params) {
    return api()
      .get('/client/settings/report-modification-notification', params)
      .then(response => response.data);
  },

  /**
   * Update report notification data (time, emails).
   * @param params
   * @returns {Promise<T>}
   */
  updateReportNotification(params) {
    return api()
      .post('/client/settings/report-modification-notification', params)
      .then(response => response.data);
  },

  /**
   * Get reports on device notification rules and time.
   * @param params
   * @returns {Promise<T>}
   */
  getDeviceNotification(params) {
    return api()
      .get('/client/settings/reports-on-device-notification', params)
      .then(response => response.data);
  },

  /**
   * Update reports on device notification rule.
   * @param params
   * @returns {Promise<T>}
   */
  updateDeviceNotificationRule(params) {
    return api()
      .put(`/client/settings/reports-on-device-notification/rules/${params.ruleId}`, params)
      .then(response => response.data);
  },

  /**
   * Update reports on device notification time.
   * @param params
   * @returns {Promise<T>}
   */
  updateDeviceNotificationTime(params) {
    return api()
      .post('/client/settings/reports-on-device-notification/', params)
      .then(response => response.data);
  },

  /**
   * Create reports on device notification rule.
   * @param params
   * @returns {Promise<T>}
   */
  createDeviceNotificationRule(params) {
    return api()
      .post('/client/settings/reports-on-device-notification/rules', params)
      .then(response => response.data);
  },

  /**
   * Delete notification rule by id.
   * @returns {Promise<T>}
   * @param params
   */
  deleteNotificationRule(params) {
    return api()
      .delete(`/client/settings/notification-rules/${params.ruleId}`, params)
      .then(response => response.data);
  },

  /**
   * Get reports transmitted notification (rules and time).
   * @param params
   * @returns {Promise<T>}
   */
  getTransmittedNotification(params) {
    return api()
      .get('/client/settings/transmitted-reports-notification', params)
      .then(response => response.data);
  },

  /**
   * Update transmitted notification time.
   * @param params
   * @returns {Promise<T>}
   */
  updateTransmittedNotificationTime(params) {
    return api()
      .post('/client/settings/transmitted-reports-notification/', params)
      .then(response => response.data);
  },

  /**
   * Update transmitted notification rule by id.
   * @param params
   * @returns {Promise<T>}
   */
  updateTransmittedNotificationRule(params) {
    return api()
      .put(`/client/settings/transmitted-reports-notification/rules/${params.ruleId}`, params)
      .then(response => response.data);
  },

  /**
   * Creat transmitted notification rule.
   * @param params
   * @returns {Promise<T>}
   */
  createTransmittedNotificationRule(params) {
    return api()
      .post('/client/settings/transmitted-reports-notification/rules', params)
      .then(response => response.data);
  },

  /**
   * Get client billing export information.
   * @returns {Promise}
   */
  getBillingExportSettings() {
    return api()
      .get('/client/settings/billing-export')
      .then(response => response.data)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return null;
        }
        throw error;
      });
  },

  /**
   * Get unfiltered export information.
   * @returns {Promise}
   */
  getUnfilteredExportSettings() {
    return api()
      .get('/client/settings/unfiltered-export')
      .then(response => response.data)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return null;
        }
        throw error;
      });
  },

  /**
   * Get MindBase export information.
   * @returns {Promise}
   */
  getMindBaseExportSettings() {
    return api()
      .get('/client/settings/mindbase-export')
      .then(response => response.data)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return null;
        }
        throw error;
      });
  },

  /**
   * Get billing export upload types list.
   * @returns {Promise<Array<object>>}
   */
  getBillingExportUploadTypes() {
    return api()
      .get('/client/settings/billing-export/upload-types')
      .then(response => response.data);
  },

  /**
   * Update billing export for current client.
   * @param {object} params Billing export data.
   */
  updateBillingExportSettings(params) {
    return api()
      .post('/client/settings/billing-export', params)
      .then(response => response.data);
  },

  /**
   * Update unfiltered export for current client.
   * @param {object} params Unfiltered export data.
   */
  updateUnfilteredExportSettings(params) {
    return api()
      .post('/client/settings/unfiltered-export', params)
      .then(response => response.data);
  },

  /**
   * Get DHS Export settings/
   * @returns {Promise<object>}
   */
  getDhsExportSettings() {
    return api()
      .get('/client/settings/dhs-export')
      .then(response => response.data)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return null;
        }
        throw error;
      });
  },

  /**
   * Get DHS Export upload types list.
   * @returns {Promise<Array<object>>}
   */
  getDhsExportUploadTypes() {
    return api()
      .get('/client/settings/dhs-export/upload-types')
      .then(response => response.data);
  },

  /**
   * Update DHS Export settings for current client.
   * @param {object} params
   */
  updateDhsExportSettings(params) {
    return api()
      .post('/client/settings/dhs-export', params)
      .then(response => response.data);
  },

  /**
   * Update MindBase Export settings for current client.
   * @param {object} params
   */
  updateMindBaseExportSettings(params) {
    return api()
      .post('/client/settings/mindbase-export', params)
      .then(response => response.data);
  },

  /**
   * Get API key from security settings.
   */
  getSecuritySettingsApiKey() {
    return api()
      .get('/client/settings/api-key')
      .then(response => {
        const { apiKey, apiKeyChangedAt, changedBy } = response.data;
        return {
          apiKey,
          changedBy,
          apiKeyChangedAt: new Date(apiKeyChangedAt),
        };
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return null;
        }
        throw error;
      });
  },

  /**
   * Refresh API key.
   */
  updateSecuritySettingsApiKey() {
    return api()
      .put('/client/settings/api-key/refresh')
      .then(response => response.data);
  },

  /**
   * Get deleted unique form notification frequency and emails array.
   *
   * @param params
   *
   * @returns {Promise<T>}
   */
  getDeletedUniqueFormsNotification() {
    return api()
      .get('/client/settings/deleted-unique-forms-notification')
      .then(response => response.data);
  },

  /**
   * Update deleted unique form notification (frequency, emails).
   * @param params
   * @returns {Promise<T>}
   */
  updateDeletedUniqueFormsNotification(params) {
    return api()
      .post('/client/settings/deleted-unique-forms-notification', params)
      .then(response => response.data);
  },

  /**
   * Get list of client admins to configure ce admin notifications.
   *
   * @returns {Promise<T>}
   */
  getCeAdminNotifications() {
    return api()
      .get('/client/settings/ce-admin-notifications')
      .then(response => response.data);
  },

  /**
   * Update list of client admins to configure ce admin notifications.
   *
   * @returns {Promise<T>}
   */
  updateCeAdminNotifications(adminIds) {
    return api()
      .post('/client/settings/ce-admin-notifications', {
        ids: adminIds,
      })
      .then(response => response.data);
  },
};
