import moment from 'moment';

export default {
  methods: {
    dateRangeFormat(daterange) {
      return `(${moment(daterange[0], 'YYYY-MM-DD').format('MM/DD/YYYY')} — `
              + `${moment(daterange[1], 'YYYY-MM-DD').format('MM/DD/YYYY')})`;
    },
  },
};
