import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    items: [],
    pagination: {
      page: 1,
      sortDesc: [true],
      itemsPerPage: 25,
      sortBy: ['datetime'],
    },
    totalItems: 0,
    dynamicFilters: {
      clientId: null,
      methodName: null,
    },
    filters: {
      UDID: null,
      dispatchId: null,
      deviceId: null,
      inventoryNumber: null,
      dateRange: null,
      status: null,
      significantData: null,
    },
    methods: [],
  },
  actions,
  mutations,
  getters: {},
};
