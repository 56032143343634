<template>
  <v-card
      elevation="3"
      className="ma-4"
  >
    <admin-page></admin-page>
  </v-card>
</template>

<script>
import AdminPage from '@/components/AdminDashboard/AdminPage';

export default {
  components: {
    AdminPage,
  },
};
</script>
