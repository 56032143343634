import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    title: 'Admins',
    admins: [],
    dialog: false,
    editedItem: {},
  },
  actions,
  mutations,
  getters,
};
