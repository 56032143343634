<template>
  <v-list-item-action>
    <v-btn
        v-if="isTrash"
        icon
        title="Delete Notification"
        :disabled="isDisabledBtn"
        @click="setDeletionConfirmation"
    >
      <v-icon color="red lighten-4">mdi-trash-can-outline</v-icon>
    </v-btn>
    <span v-else>
        <v-btn
            icon
            title="Confirm Deleting"
            @click="confirmationDelete(item)"
        >
          <v-icon color="red lighten-4">mdi-check</v-icon>
        </v-btn>
        <v-btn
            icon
            title="Cancel Deleting"
            @click="cancelDeleting"
        >
          <v-icon color="red lighten-4">mdi-close</v-icon>
        </v-btn>
      </span>
  </v-list-item-action>
</template>

<script>
export default {
  name: 'DeleteItemAction',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isTrash: true,
      isDisabledBtn: false,
    };
  },

  methods: {
    setDeletionConfirmation() {
      this.isTrash = false;
      this.$emit('isActiveOrInactiveDelete', true);
    },

    cancelDeleting() {
      this.isTrash = true;
      this.$emit('isActiveOrInactiveDelete', false);
    },

    confirmationDelete(item) {
      try {
        this.isTrash = true;
        this.isDisabledBtn = true;
        this.$emit('removeItem', item);
      } finally {
        this.isDisabledBtn = false;
      }
    },
  },
};
</script>
