<template>
  <v-dialog
      v-model="dialog"
      max-width="1300px"
  >
    <v-card v-if="loadingShow">
      <v-card-text class="pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-toolbar flat>
        <b>Find duplicated patients by group of fields</b>
        <v-spacer />
        <v-btn
            icon
            @click="dialog = false;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-toolbar
        flat
        class="popup-toolbar"
      >
        <v-radio-group
            v-model="filters.groupBy"
            class="mt-5"
            row
        >
          <v-radio
              label="Find by Patient"
              value="patient"
          ></v-radio>
          <v-radio
              label="Find by Incident Location"
              value="location"
          ></v-radio>
        </v-radio-group>
        <v-spacer />
        <date-range-picker-with-periods
            v-model="filters.dateRange"
            :clearable="false"
            position="left"
        />
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <v-row v-if="filters.groupBy === 'patient'">
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByPatientFields"
                label="First Name"
                value="PI_FirstName"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByPatientFields"
                label="Last Name"
                value="PI_LastName"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByPatientFields"
                label="Date Of Birth"
                value="PI_DateOfBirth"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="filters.groupBy === 'location'">
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByLocationFields"
                label="Street Number"
                value="II_IncidentStreetNumber"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByLocationFields"
                label="Street Name"
                value="II_IncidentStreetName"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByLocationFields"
                label="Apt Number"
                value="II_IncidentAptNumber"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByLocationFields"
                label="City"
                value="II_IncidentCityCode"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="2">
            <v-checkbox
                v-model="filters.groupByLocationFields"
                label="Zip"
                value="II_IncidentZipcode"
                dense
                hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-data-table
        :headers="headers"
        :items="items"
        :height="400"
        :loading="loading"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        fixed-header
        no-data-text="Duplicated patients not found"
      >
        <template v-slot:item.docsCount="{ item }">
          <b>{{ item.docsCount }}</b>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            class="float-right"
            color="primary"
            text
            small
            @click="applyConditions(item.fields)"
          >Apply Conditions</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import incidents from '@/api/incidents';
import moment from 'moment';

export default {
  name: 'FindDuplicatedPatients',
  components: { DateRangePickerWithPeriods },
  data() {
    return {
      dialog: false,
      loading: false,
      groupId: null,
      loadingShow: false,
      tableData: [],
      filters: {
        dateRange: null,
        groupBy: 'patient',
        groupByPatientFields: [],
        groupByLocationFields: [],
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
      totalItems: 0,
    };
  },

  computed: {
    headers() {
      const header = [];
      if (this.tableData.length) {
        this.tableData[0].fields.forEach(item => {
          header.push({
            text: item.fieldName,
            value: item.field,
            sortable: false,
          });
        });
        header.push({
          text: 'Duplicates',
          value: 'docsCount',
          sortable: false,
          align: 'center',
        });
        header.push({
          text: '',
          value: 'action',
          sortable: false,
        });
      }
      return header;
    },
    items() {
      return this.tableData.map(item => {
        const itemData = {
          docsCount: item.docsCount,
          fields: item.fields,
        };
        item.fields.forEach(fieldItem => {
          itemData[fieldItem.field] = fieldItem.value;
        });
        return itemData;
      });
    },
  },

  watch: {
    filters: {
      handler() {
        this.loading = true;
        this.pagination.page = 1;
        this.fetchData();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (!this.loading && !_.isEqual(newValue, oldValue)) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },

  methods: {
    /**
     * Show the dialog.
     */
    show(groupId) {
      this.dialog = true;
      this.loadingShow = true;
      this.groupId = groupId;
      this.filters.groupBy = 'patient';
      this.filters.groupByPatientFields = [
        'PI_FirstName',
        'PI_LastName',
        'PI_DateOfBirth',
      ];
      this.filters.groupByLocationFields = [
        'II_IncidentStreetNumber',
        'II_IncidentStreetName',
        'II_IncidentAptNumber',
        'II_IncidentCityCode',
        'II_IncidentZipcode',
      ];
      this.filters.dateRange = [
        moment().subtract(6, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ];
      this.fetchData();
    },

    /**
     * Show the dialog.
     */
    getParams() {
      const fields = this.filters.groupBy === 'patient'
        ? this.filters.groupByPatientFields
        : this.filters.groupByLocationFields;
      return {
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        groupByFields: fields,
        dateFrom: this.filters.dateRange[0],
        dateTo: this.filters.dateRange[1],
      };
    },

    /**
     * Get data for table.
     */
    async fetchData() {
      this.loading = true;
      try {
        const params = this.getParams();
        if (params.groupByFields.length) {
          const response = await incidents.getDuplicates(params);
          this.tableData = response.results;
          this.totalItems = response.pagination.total;
        } else {
          this.tableData = [];
        }
      } finally {
        this.loading = false;
        this.loadingShow = false;
      }
    },

    /**
     * Apply conditions and close popup.
     *
     * @param {Array} fields - Fields to apply
     */
    applyConditions(fields) {
      const fieldValues = {};
      fields.forEach(item => {
        fieldValues[item.field] = item.value;
      });
      this.$emit('apply-conditions', fieldValues, this.groupId, this.filters.dateRange);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.popup-toolbar {
  z-index: 10;
}
</style>
