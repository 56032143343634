<template>
  <v-card class="white elevation-3 ma-4 devices">
    <v-tabs
      v-if="showZollDevices"
      v-model="tabIndex"
    >
      <v-tab>Devices</v-tab>
      <v-tab>Zoll Devices</v-tab>
    </v-tabs>
    <v-divider
      v-if="showZollDevices"
      class="mb-3"
    />
    <v-tabs-items
        :value="tabIndex"
    >
      <v-tab-item>
        <devices-toolbar
            :filters="filters"
            :export-loading="exportLoading"
            @filtersUpdate="filtersUpdate"
            @exportToExcel="exportToExcel"
            @addDevice="showAddPopup"
        />
        <devices-table
            :items="entity.items"
            :pagination="pagination"
            :total-items="entity.totalItems"
            :loading="loading"
            @clickEdit="showEditPopup"
            @clickDelete="showDelete"
            @clickRestore="showRestore"
            @clickUnfinishedForms="showUnfinishedForms"
            @update:pagination="updatePagination"
        />
        <devices-edit-popup
            ref="editPopup"
            :show-bls-allowed="$store.state.siteInfo.BLSAllowed"
            @clickDelete="showDelete"
            @clickDeactivate="showDeactivate"
            @saved="reloadItems"
        />
        <device-unfinished-forms ref="deviceUnfinishedForms" />
        <confirmation
            ref="confirmationPopup"
            @confirmed="reloadItems"
        />
      </v-tab-item>
      <v-tab-item>
        <zoll-devices-toolbar
          :filters="zollDevicesFilters"
          @add="showEditZollDevice"
        />
        <zoll-devices-table
          ref="zollDevicesTable"
          :filters="zollDevicesFilters"
          @edit="showEditZollDevice"
          @delete="showDeleteZollDevice"
        />
        <confirmation
            ref="zollConfirmationPopup"
            @confirmed="reloadZollItems"
        />
        <zoll-device-edit-popup
          ref="zollDeviceEditPopup"
          @saved="$refs.zollDevicesTable.fetchData()"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import _ from 'lodash';
import DevicesTable from '@/components/Devices/DevicesTable';
import DevicesToolbar from '@/components/Devices/DevicesToolbar';
import DevicesEditPopup from '@/components/Devices/DevicesEditPopup';
import DeviceUnfinishedForms from '@/components/Devices/DeviceUnfinishedForms';
import Confirmation from '@/components/Confirmation';
import { LOAD_DEVICES, EXPORT_DEVICES } from '@/store/Devices/actions';
import { SET_FILTERS, SET_PAGINATION } from '@/store/Devices/mutations';
import { SET_TITLE } from '@/store/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';
import apiDevices from '@/api/devices';
import ZollDevicesToolbar from '@/components/ZollDevices/ZollDevicesToolbar';
import ZollDevicesTable from '@/components/ZollDevices/ZollDevicesTable';
import zollDevices from '@/api/zollDevices';
import ZollDeviceEditPopup from '@/components/ZollDevices/ZollDeviceEditPopup';

const { mapState, mapMutations } = createNamespacedHelpers('devices');

export default {
  components: {
    ZollDeviceEditPopup,
    ZollDevicesTable,
    ZollDevicesToolbar,
    DevicesTable,
    DevicesToolbar,
    DevicesEditPopup,
    DeviceUnfinishedForms,
    Confirmation,
  },
  mixins: [filtersAndTablesGeneralMixins],

  data() {
    return {
      exportLoading: false,
      tabIndex: 0,
      zollDevicesFilters: {
        search: null,
      },
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
    showZollDevices() {
      const { siteInfo } = this.$store.state;
      return siteInfo && siteInfo.hasZollCloudCredentials;
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('devices.header'));
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PAGINATION,
      setFilters: SET_FILTERS,
    }),
    ...mapActions({
      loadItems: `devices/${LOAD_DEVICES}`,
      exportDevices: `devices/${EXPORT_DEVICES}`,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Export devices by current filter and sort order to excel file.
     */
    async exportToExcel() {
      this.exportLoading = true;
      try {
        await this.exportDevices();
      } finally {
        this.exportLoading = false;
      }
    },

    /**
     * Show edit device popup.
     *
     * @param {Object} device - Device object.
     */
    showEditPopup(device) {
      this.$refs.editPopup.showEdit(_.clone(device));
    },

    /**
     * Show add device popup.
     */
    showAddPopup() {
      this.$refs.editPopup.showNew({});
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} device - Device object.
     */
    showDelete(device) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('devices.deleteConfirmationTitle'),
        this.$t('devices.deleteConfirmationText', device),
        async () => {
          await apiDevices.forceDeleteDevice(device.registryId);
          this.$refs.editPopup.hidePopup();
          this.reloadItems();
        },
      );
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} device - Zoll Device object.
     */
    showDeleteZollDevice(device) {
      this.$refs.zollConfirmationPopup.showConfirm(
        'Delete ZOLL Device',
        `Do you really want to delete ZOLL device "${device.device}"?`,
        async () => {
          await zollDevices.deleteDevice(device.id);
        },
      );
    },

    /**
     * Reload data for zoll devices table.
     */
    reloadZollItems() {
      this.$refs.zollDevicesTable.fetchData();
    },

    /**
     * Reload data for zoll devices table.
     */
    showEditZollDevice(item) {
      this.$refs.zollDeviceEditPopup.show(item);
    },

    /**
     * Show deactivate confirmation popup.
     *
     * @param {Object} device - Device object.
     */
    showDeactivate(device) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('devices.deactivateConfirmationTitle'),
        this.$t('devices.deactivateConfirmationText', device),
        async () => {
          await apiDevices.deactivateDevice(device.registryId);
          this.$refs.editPopup.hidePopup();
          this.reloadItems();
        },
      );
    },

    /**
     * Show restore confirmation popup.
     *
     * @param {Object} device - Device object.
     */
    showRestore(device) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('devices.restoreConfirmationTitle'),
        this.$t('devices.restoreConfirmationText', device),
        async () => {
          await apiDevices.restoreDevice(device.registryId);
          this.reloadItems();
        },
      );
    },

    /**
     * Show unfinished forms popup.
     *
     * @param {Object} device - Device object.
     */
    showUnfinishedForms(device) {
      this.$refs.deviceUnfinishedForms.showPopup(device);
    },
  },
};
</script>
