<template>
  <div>
    <v-data-table
        :headers="header"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :height="tableHeight"
        :loading="loading"
        :footer-props="{
           itemsPerPageOptions: [10,25,50]
        }"
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap system-search-table"
    >
      <template v-slot:item.II_SequenceNumber="{ item }">
        <a @click="$emit('goToIncident', item)">{{ item.II_SequenceNumber }}</a>
      </template>
      <template v-slot:item.location="{ item }">
        {{locationPrefix}}{{ item.searchType }}
      </template>
      <template v-slot:item.II_IncidentDate="{ item }">
        {{ item.II_IncidentDate | date }}
      </template>
      <template v-slot:item.datePCRUploaded="{ item }">
        {{ item.datePCRUploaded | date }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            :loading="item.isDownloading"
            :disabled="item.isDownloading"
            text
            @click="downloadJson(item)"
        >
          <v-icon>mdi-download</v-icon>
          json
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import fileDownload from 'js-file-download';
import duplicateIncidentsApi from '@/api/duplicateIncidents';
import incidentsApi from '@/api/incidents';
import fixedHeader from '@/mixins/fixedHeader';
import { SHOW_SNACKBAR } from '@/store/actions';

export default {
  name: 'IncidentsTable',
  mixins: [fixedHeader],
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showLocationColumn: {
      type: Boolean,
      required: true,
    },
    locationPrefix: {
      type: String,
      required: false,
    },
    showFirstNameColumn: {
      type: Boolean,
      required: false,
      default: true,
    },
    typeData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['II_IncidentDate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
    };
  },
  computed: {
    header() {
      let header = [
        {
          text: 'Sequence Number',
          value: 'II_SequenceNumber',
          sortable: false,
        },
        {
          text: 'Incident Date',
          value: 'II_IncidentDate',
          sortable: false,
        },
        {
          text: 'PCR Uploaded',
          value: 'datePCRUploaded',
          sortable: false,
        },
        {
          text: 'Incident Number',
          value: 'II_IncidentNumber',
          sortable: false,
        },
        {
          text: 'First Name',
          value: 'PI_FirstName',
          sortable: false,
        },
        {
          text: 'Last Name',
          value: 'PI_LastName',
          sortable: false,
        },
        {
          text: 'Client',
          value: 'client',
          sortable: false,
        },
        {
          text: 'Location',
          value: 'location',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ];

      header = header.filter((item) => {
        if (!this.showFirstNameColumn && item.text === 'First Name') {
          return false;
        }

        if (!this.showLocationColumn && item.text === 'Location') {
          return false;
        }

        return true;
      });

      return header;
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.$emit('updatePagination', newData);
      }
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Download JSON incident data.
     *
     * @param {Object} item Incident data
     */
    async downloadJson(item) {
      let response;
      const id = this.getId(item);

      try {
        if (id !== null) {
          this.setDownloading(id, true);
        }
        if (this.typeData === 'incidents') {
          response = await incidentsApi.fetchJson(id);
        } else if (this.typeData === 'duplicates') {
          response = await duplicateIncidentsApi.fetchJson(id);
        } else if (this.typeData === 'transmitted' || this.typeData === 'airdropped') {
          response = await incidentsApi.fetchTransmittedJson(id);
        } else {
          response = null;
        }

        if (response !== null) {
          fileDownload(response.data, `${item.II_SequenceNumber}.json`);
        }
      } catch (e) {
        await this.showSnackbar({ title: `Unexpected error while downloading JSON for ${item.II_SequenceNumber}`, color: 'red' });
      } finally {
        if (id !== null) {
          this.setDownloading(id, false);
        }
      }
    },
    /**
     * Get item identifier.
     *
     * @param {Object} item - Incident object
     *
     * @returns {integer|null}
     */
    getId(item) {
      switch (this.typeData) {
        case 'incidents':
        case 'duplicates':
          return item.id;
        case 'transmitted':
        case 'airdropped':
          return item.ID;
        default:
          return null;
      }
    },

    /**
     * Set downloading property by item identifier.
     *
     * @param {integer} id - Identifier of the incident
     * @param {boolean} isDownloading - Value of isDownloading property
     */
    setDownloading(id, isDownloading) {
      let i = null;

      this.items.forEach((item, index) => {
        const identifier = this.getId(item);
        if (id === identifier) {
          i = index;
        }
      });

      if (i !== null) {
        this.items[i].isDownloading = isDownloading;
      }
    },
  },
};
</script>
<style lang="scss">
  .system-search-table thead.v-data-table-header tr th{
    z-index: 1 !important;
  }
</style>
