<template>
  <view-resolver>
    <flagged-users-admin slot="adminView"></flagged-users-admin>
    <flagged-users-client slot="clientView"></flagged-users-client>
  </view-resolver>
</template>
<script>
import { SET_TITLE } from '@/store/mutations';
import ViewResolver from '@/components/ViewResolver';
import FlaggedUsersAdmin from './FlaggedUsersAdmin';
import FlaggedUsersClient from './FlaggedUsersClient';

export default {
  components: {
    ViewResolver,
    FlaggedUsersAdmin,
    FlaggedUsersClient,
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('flaggedUsers.header'));
  },
};
</script>
