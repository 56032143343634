<template>
  <div>
    <v-textarea
      v-model="textValue"
      :label="label"
      rows="6"
      no-resize
    />
  </div>
</template>

<script>
export default {
  name: 'StringTextarea',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
