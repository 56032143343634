import api from './api';

export default {
  /**
   * Fetch list of incidents for admin site.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminFetchList(params) {
    return api()
      .get('admin/imported-incidents', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of incidents for client site.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientFetchList(params) {
    return api()
      .get('client/imported-incidents', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of incident units for admin site.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminFetchUnits(params) {
    return api()
      .get('admin/imported-units', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of incident units for client site.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientFetchUnits(params) {
    return api()
      .get('client/imported-units', { params })
      .then(response => response.data);
  },
};
