<template>
  <v-card class="white elevation-3 ma-4 devices">
    <first-run-devices-toolbar
      :filters="filters"
      @filtersUpdate="filtersUpdate"
    />
    <first-run-devices-table
      :items="entity.items"
      :pagination="pagination"
      :total-items="entity.totalItems"
      :loading="loading"
      @clickDelete="showDelete"
      @clickRegister="showRegister"
      @update:pagination="updatePagination"
    />
    <devices-edit-popup
      ref="addDevicePopup"
      :show-bls-allowed="true"
      :show-clients-select="true"
      @saved="reloadItems"
    />
    <confirmation
      ref="confirmationPopup"
      @confirmed="reloadItems"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import FirstRunDevicesTable from '@/components/FirstRunDevices/FirstRunDevicesTable';
import FirstRunDevicesToolbar from '@/components/FirstRunDevices/FirstRunDevicesToolbar';
import DevicesEditPopup from '@/components/Devices/DevicesEditPopup';
import Confirmation from '@/components/Confirmation';
import { LOAD_ITEMS } from '@/store/FirstRunDevices/actions';
import {
  SET_PAGINATION,
  SET_FILTERS,
} from '@/store/FirstRunDevices/mutations';
import { SET_TITLE } from '@/store/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';
import apiFirstRunDevices from '@/api/firstRunDevices';

const { mapState, mapMutations } = createNamespacedHelpers('firstRunDevices');

export default {
  components: {
    FirstRunDevicesTable,
    FirstRunDevicesToolbar,
    DevicesEditPopup,
    Confirmation,
  },
  mixins: [filtersAndTablesGeneralMixins],

  computed: {
    ...mapState({
      entity: state => state,
    }),
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t('firstRunDevices.header'));
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PAGINATION,
      setFilters: SET_FILTERS,
    }),
    ...mapActions({
      loadItems: `firstRunDevices/${LOAD_ITEMS}`,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} item - Object to delete.
     */
    showDelete(item) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('firstRunDevices.deleteConfirmationTitle'),
        this.$t('firstRunDevices.deleteConfirmationText', item),
        async () => {
          await apiFirstRunDevices.delete(item.id);
          this.reloadItems();
        },
      );
    },

    showRegister(item) {
      this.$refs.addDevicePopup.showNew({ UDID: item.UDID });
    },
  },
};
</script>
