<template>
  <div>
    <v-row class="pl-4 pr-4">
      <v-col md="3">
        <v-text-field
          v-model="search"
          label="Keyword"
          append-icon="search"
          single-line
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-select
          v-model="selectedInterval"
          :items="ranges"
          label="Predefined Interval"
          item-value="name"
          item-text="name"
          @change="selectInterval"
        />
      </v-col>
      <v-col md="4">
        <date-range-picker
          v-model="selectedPickerDates"
          :max-date="new Date()"
          @input="selectPickerDates"
        ></date-range-picker>
      </v-col>
      <v-col class="ml-auto d-flex justify-end align-center">
        <a
          :href="cloudwatchLink"
          target="_blank"
          rel="noreferrer noopener"
          class="v-btn v-size--default primary"
        >View in CloudWatch</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import DateRangePicker from '../DateRangePicker';

const MINUTE_IN_MS = 1 * 60 * 1000; // One minute * 60 seconds * 1000 milliseconds
const HOUR_IN_MS = 1 * 60 * MINUTE_IN_MS; // 1 hours * 60 minutes * milliseconds in one minute.
const DAY_IN_MS = 1 * 24 * HOUR_IN_MS; // 1 day * 24 hours * milliseconds in one hour.
const Intervals = {
  LastMinute: 'Last minute',
  Last30Minutes: 'Last 30 minutes',
  Last1Hour: 'Last 1 hour',
  Last12Hours: 'Last 12 hours',
  Last1Day: 'Last 1 day',
};

/**
 * Errors utility filters.
 *
 * @emits applyFilter
 */
export default {
  name: 'Filters',

  components: {
    DateRangePicker,
  },

  data: () => ({
    /**
     * AWS Cloud Watch home page link.
     */
    cloudwatchLink: process.env.VUE_APP_CLOUDWATCH_LINK,

    /**
     * Search value.
     */
    search: '',

    /**
     * Dates range.
     */
    dates: {},
    /**
     * Selected picker dates.
     */
    selectedPickerDates: null,
    /**
     * Selected predefined interval.
     */
    selectedInterval: Intervals.Last30Minutes,
  }),

  mounted() {
    // Init filter by setting 30 minutes interval.
    this.selectInterval(this.selectedInterval);
  },

  computed: {
    /**
     * Filter params.
     */
    filterParams() {
      return {
        search: this.search,
        ...this.dates,
      };
    },
    /**
     * Ranges list.
     */
    ranges() {
      const nowDate = new Date();

      return [
        {
          name: Intervals.LastMinute,
          value: {
            dateFrom: new Date(nowDate.getTime() - MINUTE_IN_MS),
            dateTo: nowDate,
          },
        },
        {
          name: Intervals.Last30Minutes,
          value: {
            dateFrom: new Date(nowDate.getTime() - (MINUTE_IN_MS * 30)),
            dateTo: nowDate,
          },
        },
        {
          name: Intervals.Last1Hour,
          value: {
            dateFrom: new Date(nowDate.getTime() - HOUR_IN_MS),
            dateTo: nowDate,
          },
        },
        {
          name: Intervals.Last12Hours,
          value: {
            dateFrom: new Date(nowDate.getTime() - (HOUR_IN_MS * 12)),
            dateTo: nowDate,
          },
        },
        {
          name: Intervals.Last1Day,
          value: {
            dateFrom: new Date(nowDate.getTime() - DAY_IN_MS),
            dateTo: nowDate,
          },
        },
      ];
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    search: _debounce(function () {
      this.applyFilter();
    }, 300),

    dates() {
      this.applyFilter();
    },
  },

  methods: {
    /**
     * Emit event `applyFilter` with filter params object.
     */
    applyFilter() {
      this.$emit('applyFilter', this.filterParams);
    },
    /**
     * Select predefined interval
     * @param name Interval name
     */
    selectInterval(name) {
      this.dates = this.ranges.find(range => range.name === name)?.value;
      this.selectedPickerDates = null;
    },
    /**
     * Select dates from picker.
     * @param data Dates
     */
    selectPickerDates(data) {
      this.dates = data;
      this.selectedInterval = null;
    },
  },
};
</script>
