import baseDomain from './baseDomain';

const firstSubdomain = baseDomain() === window.location.host || window.location.host.split('.')[0] === 'www'
  ? null
  : window.location.host.split('.')[0];

const sitecode = window.location.host.indexOf('localhost:8') === 0
  ? process.env.VUE_APP_SITECODE_LOCAL || 'admin'
  : firstSubdomain;

export default sitecode;
