<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="pagination"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    fixed-header
    class="header-nowrap submission-logs-table"
    no-data-text="No data available"
    no-results-text="No data available"
  >
    <template v-slot:item.sequenceNumber="{ item }">
      <a
          @click="goToIncident(item)"
      >
        {{ item.sequenceNumber }}
      </a>
    </template>
    <template v-slot:item.incidentDateTime="{ item }">
      {{ item.incidentDateTime | datetime }}
    </template>
    <template v-slot:item.exportedAt="{ item }">
      {{ item.exportedAt | datetimeToClientTimezone }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
          v-if="item.status === 'failed'"
          class="float-right mr-4"
          icon
          title="Retry"
          :loading="item.retrying"
          @click="retry(item)"
      >
        <v-icon color="grey darken-2">mdi-file-upload-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.statusCodeDescription="{ item }">
      {{ statusMap[item.statusCode] }}
    </template>
  </v-data-table>
</template>

<script>
import _ from 'lodash';
import impersonateMixins from '@/mixins/impersonateMixins';
import nemsisExport from '@/api/nemsisExport';

export default {
  name: 'SubmissionLogTable',

  mixins: [impersonateMixins],

  props: {
    type: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    usesClientsFilter: {
      default: true,
      type: Boolean,
    },
    useImpersonate: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      debounce: null,
      pagination: {
        sortDesc: [true],
        page: 1,
        sortBy: ['id'],
        itemsPerPage: 25,
        totalItems: 0,
      },
      items: [],
      totalItems: 0,
      loading: false,
      headers: [
        {
          text: 'Sequence Number',
          value: 'sequenceNumber',
        },
        {
          text: 'Incident Number',
          value: 'incidentNumber',
        },
        {
          text: 'Incident DateTime',
          value: 'incidentDateTime',
        },
        {
          text: 'Job Status',
          value: 'status',
        },
        {
          text: 'Submitted At',
          value: 'exportedAt',
        },
        {
          text: 'Error',
          value: 'error',
          sortable: false,
        },
        {
          text: 'Status Code',
          value: 'statusCode',
        },
        {
          text: 'Status Code Description',
          value: 'statusCodeDescription',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      statusMap: {
        '-1': 'Invalid username and/or password',
        '-2': 'Permission denied to the client for the operation',
        '-3': 'Permission denied to the client for that organization',
        '-4': 'Invalid parameter value',
        '-5': 'Invalid parameter combination',
        '-20': 'Generic server error',
        '-21': 'Server error, because of database connection/operation issue',
        '-22': 'Server error, because of file system/network/IO issue',
        '-11': 'Failed import of a file, because the same file is already on the server',
        '-12': 'Failed import of a file, because of failing XML validation',
        '-13': 'Failed import of a file, because of [FATAL] level Schematron rule violation',
        '-14': 'Failed Import of a file, because of [ERROR] level Schematron rule violation',
        '-15': 'Failed Import of a file, because of critical ETL rule violation',
        '-16': 'Failed import of a file, because of critical Business Intelligence rule violation',
        '-30': 'Failed import of a file, because the size of SOAP message exceeds the limit',
        '-40': 'Status for the requested requestHandle is not available: it could be expired, or '
            + 'not in the correct format, or never exist, or for any other whatever reason.',
        '-41': 'Status for the requested requestHandle is not available since it expires already',
        '-42': 'Invalid value of requestHandle (for example, not formatted properly)',
        '-43': 'Never-used value of requestHandle',
        '-50': 'Server is too busy. The client should query later',
        '-51': 'Failed operation of QueryLimit',
        1: 'Successful import of a file',
        2: 'Successful import of a file, with [ERROR] level Schematron rule violation reported',
        3: 'Successful import of a file, with [WARNING] level Schematron rule violation reported',
        4: 'Successful import of a file, with ETL rule warning',
        5: 'Successful import of a file, with Business Intelligence warning',
        6: 'Partially successful import of a file, with [ERROR] level Schematron rule violation reported',
        10: 'File has passed validation, processing is not yet complete',
        0: 'The expected data processing is not yet complete',
        51: 'Successful operation of QueryLimit',
      },
    };
  },

  watch: {
    /**
     * Watch pagination options and update pagination
     */
    pagination() {
      this.fetchDataDebounce();
    },
  },
  mounted() {
    this.fetchDataDebounce();
  },
  methods: {
    async fetchData() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        order_by: this.pagination.sortBy[0],
        sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        type: this.type,
        ...this.filters,
      };
      if (this.usesClientsFilter && !this.filters.clientId) {
        this.items = [];
        this.totalItems = 0;
        return;
      }
      try {
        this.loading = true;
        const response = await nemsisExport.getLogs(params);
        this.items = response.results;
        this.totalItems = response.pagination.total;
      } finally {
        this.loading = false;
      }
    },

    fetchDataDebounce() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.fetchData();
        }, 300);
      }
      this.debounce();
    },

    /**
     * Impersonate user to client and go to incident view page.
     *
     * @param {Object} exportItem - Export object.
     */
    goToIncident(exportItem) {
      if (this.useImpersonate) {
        this.impersonateToIncident(
          exportItem.clientId,
          exportItem.incidentId,
        );
      } else {
        const url = `/incidents/${exportItem.incidentId}`;
        const win = window.open(url, '_blank');
        win.focus();
      }
    },

    async retry(item) {
      const itemObj = item;
      try {
        itemObj.retrying = true;
        await nemsisExport.retryExport(item.id);
      } finally {
        itemObj.retrying = false;
        itemObj.status = 'scheduled';
        itemObj.statusCode = null;
        itemObj.error = null;
        itemObj.exportedAt = null;
      }
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}

.deleted {
  background-color: $red-light;
}
</style>
<style lang="scss">
.submission-logs-table {
  .v-data-table__wrapper {
    height: calc(100vh - 390px);
  }
}
</style>
