<template>
  <div>
    <div v-if="!notificationsEnabled">
      The current user is not included in the list of administrators receiving CE notifications.
      You can add your user in the
      <router-link :to="{ name: routes.PROFILE_SETTINGS}">settings page</router-link>.
    </div>
    <v-row>
      <v-col>
        <v-toolbar flat>
          <v-toolbar-title>Admin Notifications:</v-toolbar-title>
        </v-toolbar>
        <v-card
            v-if="adminEmailNotifications.length"
            flat
        >
          <v-card-title class="notification-title">Email Notifications</v-card-title>
          <v-card-text>
            <template v-for="notification in adminEmailNotifications">
              <v-checkbox
                  :key="'email_' + notification.id"
                  v-model="notification.isEmail"
                  :label="notification.name"
                  :disabled="!notificationsEnabled"
                  hide-details
                  dense
              />
            </template>
          </v-card-text>
        </v-card>

        <v-card
            v-if="adminOnSiteNotifications.length"
            flat
        >
          <v-card-title class="notification-title">On Site Notifications</v-card-title>
          <v-card-text>
            <template v-for="notification in adminOnSiteNotifications">
              <v-checkbox
                  :key="'onsite_' + notification.id"
                  v-model="notification.isOnSite"
                  :label="notification.name"
                  :disabled="!notificationsEnabled"
                  hide-details
                  dense
              />
            </template>
          </v-card-text>
        </v-card>

        <v-card
            v-if="adminSmsNotifications.length"
            flat
        >
          <v-card-title class="notification-title">SMS Notifications</v-card-title>
          <v-card-text>
            <template v-for="notification in adminSmsNotifications">
              <v-checkbox
                  :key="'sms_' + notification.id"
                  v-model="notification.isSms"
                  :label="notification.name"
                  :disabled="!notificationsEnabled"
                  hide-details
                  dense
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-toolbar flat>
          <v-toolbar-title>User Notifications:</v-toolbar-title>
        </v-toolbar>

        <v-card
            v-if="userEmailNotifications.length"
            flat
        >
          <v-card-title class="notification-title">Email Notifications</v-card-title>
          <v-card-text>
            <template v-for="notification in userEmailNotifications">
              <v-checkbox
                  :key="'email_' + notification.id"
                  v-model="notification.isEmail"
                  :label="notification.name"
                  :disabled="!notificationsEnabled"
                  hide-details
                  dense
              />
            </template>
          </v-card-text>
        </v-card>

        <v-card
            v-if="userOnSiteNotifications.length"
            flat
        >
          <v-card-title class="notification-title">On Site Notifications</v-card-title>
          <v-card-text>
            <template v-for="notification in userOnSiteNotifications">
              <v-checkbox
                  :key="'onsite_' + notification.id"
                  v-model="notification.isOnSite"
                  :label="notification.name"
                  :disabled="!notificationsEnabled"
                  hide-details
                  dense
              />
            </template>
          </v-card-text>
        </v-card>

        <v-card
            v-if="userSmsNotifications.length"
            flat
        >
          <v-card-title class="notification-title">SMS Notifications</v-card-title>
          <v-card-text>
            <template v-for="notification in userSmsNotifications">
              <v-checkbox
                  :key="'sms_' + notification.id"
                  v-model="notification.isSms"
                  :label="notification.name"
                  :disabled="!notificationsEnabled"
                  hide-details
                  dense
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _ from 'lodash';
import ceHoursSettings from '@/api/ceHoursSettings';
import componentMixins from '@/components/CeEntry/Settings/componentMixins';
import ROUTES from '@/enums/routes';

export default {
  name: 'NotificationSettings',
  mixins: [componentMixins],
  data() {
    return {
      loading: false,
      settingsData: [],
      settingsDataOriginal: [],
      notificationsEnabled: true,
      routes: ROUTES,
    };
  },
  computed: {
    adminEmailNotifications() {
      return this.settingsData.filter(setting => setting.hasEmail && setting.adminNotification);
    },
    adminOnSiteNotifications() {
      return this.settingsData.filter(setting => setting.hasOnSite && setting.adminNotification);
    },
    adminSmsNotifications() {
      return this.settingsData.filter(setting => setting.hasSms && setting.adminNotification);
    },
    userEmailNotifications() {
      return this.settingsData.filter(
        setting => setting.hasEmail && !setting.adminNotification,
      );
    },
    userOnSiteNotifications() {
      return this.settingsData.filter(
        setting => setting.hasOnSite && !setting.adminNotification,
      );
    },
    userSmsNotifications() {
      return this.settingsData.filter(
        setting => setting.hasSms && !setting.adminNotification,
      );
    },
  },
  mounted() {
    this.loading = false;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const notificationSettings = await ceHoursSettings.getNotificationSettings();
        this.settingsData = _.cloneDeep(notificationSettings.notificationTypes);
        this.settingsDataOriginal = _.cloneDeep(notificationSettings.notificationTypes);
        this.notificationsEnabled = notificationSettings.notificationsEnabled;
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.saving = true;
        await ceHoursSettings.saveNotificationsSettings(this.settingsData);
        this.settingsDataOriginal = _.cloneDeep(this.settingsData);
        this.edited = false;
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style>
.notification-title {
  word-break: normal;
  font-size: 15px;
}
</style>
