<template>
  <ce-entry-card
    :loading="loading"
    :data-loaded="dataLoaded"
    :color-number="4"
    :width="width"
  >
    <template>
      <v-row class="pl-4">
        <v-col class="pb-0">
          <span :class="[$style.hoursLine]">
            <span :class="[$style.hours, $style.applied]">
              {{ applied | hoursFilter }}
            </span> Hours Applied
          </span>
        </v-col>
      </v-row>
      <v-row class="pl-4 mt-0">
        <v-col class="pb-2 pt-2">
          <span :class="[$style.hoursLine]">
            <span :class="[$style.hours, $style.needed]">
              {{ needed | hoursFilter }}
            </span> Hours Needed
          </span>
        </v-col>
      </v-row>
      <v-row class="pl-4 mt-0">
        <v-col class="pb-0 pt-0">
          <span :class="[$style.hoursLine]">
            <span
              :class="[$style.hours]"
            >{{ required | hoursFilter }}</span> Hours Required
          </span>
        </v-col>
      </v-row>
    </template>
  </ce-entry-card>
</template>
<script>
import { convertNumberToTime } from '@/utils/convertNumberToTime';
import CeEntryCard from './CeEntryCard';

export default {
  name: 'HoursCard',
  components: { CeEntryCard },
  filters: {
    hoursFilter(value) {
      return value !== null ? convertNumberToTime(value) : '—';
    },
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Number,
      default: null,
    },
    applied: {
      type: Number,
      default: null,
    },
    needed: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" module>
.hoursLine {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 18px;
}
.hours {
  font-weight: bolder;
}
.applied {
  color: #1e49bd;
}
.needed {
  color: #cc2424;
}
</style>
