<template>
  <div class="insights-table">
    <v-data-table
        :options.sync="pagination"
        :headers="header"
        :items="tableItems"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [-1,10,25,50]
        }"
        :height="tableHeight"
        dense
        fixed-header
    >
      <template v-slot:header.overall="{ header }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <span>{{ header.text }}</span>
              <br />
              <span class="additional-text">{{ header.additionalText }}</span>
            </span>
          </template>
          <span style="text-align: center">Overall in the agency by all users, for convenient <br />
            comparing and calculating the percentage.</span>
        </v-tooltip>
      </template>
      <template v-slot:header.overallPercentage="{ header }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <span>{{ header.text }}</span>
            </span>
          </template>
          <span style="text-align: center">(Total In Agency * 100) / Overall In Agency</span>
        </v-tooltip>
      </template>
      <template v-slot:header.total="{ header }">
        <span>{{ header.text }}</span>
        <br />
        <span class="additional-text">{{ header.additionalText }}</span>
      </template>
      <template v-slot:header.totalSuccessPercentage="{ header }">
        <span>{{ header.text }}</span>
        <br />
        <span class="additional-text">{{ header.additionalText }}</span>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="[$style['first-column']]">
          <a
            v-if="item.type === 'focus' && focusExists(item.id)"
            :title="item.name"
            @click="$emit('selectFocus', item.id)"
          >{{ item.name }}</a>
          <a
              v-else-if="item.type === 'user' && userExists(item.id)"
              :title="item.name"
              @click="$emit('selectUser', item.id)"
          >{{ item.name }}</a>
          <a
              v-else-if="item.type === 'providerImpression' && providerImpressionExists(item.id)"
              :title="item.name"
              @click="$emit('selectProviderImpression', item.id)"
          >{{ item.name }}</a>
          <span
            v-else
            :title="item.name"
          >
            {{ item.name }}
          </span>
        </span>
      </template>
      <template v-slot:item.totalPeriod="{ item }">
        <b v-if="item.totalPeriod > 0">
          <a
            :href="getTotalPeriodIncidentsLink(item)"
            target="_blank"
          >{{ item.totalPeriod }}</a>
        </b>
        <b v-else>{{ item.totalPeriod }}</b>
      </template>
      <template v-slot:item.periodSuccessPercentage="{ item }">
        <b>{{ item.periodSuccessPercentage | percentage }}</b>
      </template>
      <template v-slot:item.total="{ item }">
        <a
           v-if="item.total > 0"
           :href="getTotalIncidentsLink(item)"
           target="_blank"
        >{{ item.total }}</a>
        <span v-else>{{ item.total }}</span>
      </template>
      <template v-slot:item.overall="{ item }">
        <a
            v-if="item.overall > 0"
            :href="getOverallIncidentsLink(item)"
            target="_blank"
        >{{ item.overall }}</a>
        <span v-else>{{ item.overall }}</span>
      </template>
      <template v-slot:item.overallPercentage="{ item }">
        {{ item.overallPercentage | percentage }}
      </template>
      <template v-slot:item.totalSuccessPercentage="{ item }">
        {{ item.totalSuccessPercentage | percentage }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import _ from 'lodash';
import fixedHeader from '@/mixins/fixedHeader';
import INSIGHT_CATEGORIES from '@/enums/insightCategories';

export default {
  name: 'InsightTable',
  mixins: [fixedHeader],

  props: {
    filters: {
      type: Object,
      required: true,
    },
    groupByName: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    focuses: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    providerImpressions: {
      type: Array,
      required: true,
    },
  },

  filters: {
    percentage(value) {
      const perc = (value).toFixed(1);
      if (value === 0) {
        return '0%';
      }
      return value < 0.1 ? '<0.1%' : `${perc}%`;
    },
  },

  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: [],
        page: 1,
        sortBy: [],
        itemsPerPage: -1,
        totalItems: 0,
      },
      additionalIndent: 20,
      insightCategories: INSIGHT_CATEGORIES,
    };
  },

  computed: {
    focusesMap() {
      const focuses = {};
      this.focuses.forEach(item => {
        focuses[item.code] = item.name;
      });
      return focuses;
    },
    usersMap() {
      const focuses = {};
      this.users.forEach(item => {
        focuses[item.registryId] = `${item.firstName} ${item.lastname}`;
      });
      return focuses;
    },
    providerImpressionsMap() {
      const list = {};
      this.providerImpressions.forEach(item => {
        list[item.code] = item.name;
      });
      return list;
    },
    header() {
      const header = [
        {
          text: this.groupByName,
          value: 'name',
          width: 250,
          class: 'highlighted',
        },
      ];

      if (this.items.length) {
        _.forEach(this.items[0].values, (value, name) => {
          header.push({
            text: name,
            value: this.getFieldByColumnName(name),
            align: 'center',
            class: 'highlighted',
          });
        });
        if (this.items[0].totalPeriod !== null) {
          header.push({
            text: 'Total By Time Frame',
            value: 'totalPeriod',
            align: 'center',
            class: 'highlighted',
          });
        }
        if (this.items[0].totalPeriodSuccess !== null) {
          header.push({
            text: 'Time Frame Success %',
            value: 'periodSuccessPercentage',
            align: 'center',
            class: 'highlighted',
          });
        }
        if (this.items[0].total !== null) {
          const additionalText = this.items[0].type === 'user' ? '(All Time By User)' : '(All Time)';
          header.push({
            text: 'Total In Agency',
            additionalText,
            value: 'total',
            align: 'center',
            class: 'highlighted',
          });
        }
        if (this.items[0].totalSuccess !== null) {
          const additionalText = this.items[0].type === 'user' ? '(All Time By User)' : '(All Time)';
          header.push({
            text: 'Agency Success %',
            additionalText,
            value: 'totalSuccessPercentage',
            align: 'center',
            class: 'highlighted',
          });
        }
        if (this.items[0].overall !== null) {
          header.push({
            text: 'Overall In Agency',
            additionalText: '(All Users)',
            value: 'overall',
            align: 'center',
            class: 'highlighted',
          });
          header.push({
            text: 'Overall %',
            value: 'overallPercentage',
            align: 'center',
            class: 'highlighted',
          });
        }
      }

      return header;
    },

    tableItems() {
      const items = [];
      this.items.forEach(item => {
        const newItem = {
          id: item.id,
          name: item.name,
          type: item.type,
          total: item.total,
          totalPeriod: item.totalPeriod,
          totalPeriodFrom: item.totalPeriodFrom,
          totalPeriodTo: item.totalPeriodTo,
          overall: item.overall,
          overallPercentage: null,
          periodSuccessPercentage: null,
          totalSuccessPercentage: null,
        };
        if (newItem.overall !== null) {
          newItem.overallPercentage = newItem.overall > 0
            ? (newItem.total * 100) / newItem.overall
            : 0;
        }
        if (newItem.totalSuccess !== null) {
          newItem.totalSuccessPercentage = item.totalSuccess > 0
            ? (item.totalSuccess * 100) / item.total
            : 0;
        }
        if (newItem.totalPeriodSuccess !== null) {
          newItem.periodSuccessPercentage = item.totalPeriodSuccess > 0
            ? (item.totalPeriodSuccess * 100) / item.totalPeriod
            : 0;
        }
        _.forEach(item.values, (value, name) => {
          newItem[this.getFieldByColumnName(name)] = value;
        });
        items.push(newItem);
      });
      return items;
    },
  },

  methods: {
    getBaseIncidentsLink() {
      return `/incidents/regular?filters=insights&category=${this.filters.category}`;
    },

    getBaseTotalIncidentsLink(item) {
      let link = this.getBaseIncidentsLink();

      if (item.type === 'focus') {
        link += `&focus=${item.id}`;
        const insight = this.filters.category
        === this.insightCategories.TRANSPORT_BY_PROVIDER_IMPRESSIONS
          ? this.filters.providerImpression
          : this.filters.userId;
        if (insight) {
          link += `&insight=${insight}`;
        }
      } else {
        link += `&focus=${this.filters.focus}`;
        link += `&insight=${item.id}`;
      }
      return link;
    },

    getTotalPeriodIncidentsLink(item) {
      let link = this.getBaseTotalIncidentsLink(item);
      link += `&dateFrom=${item.totalPeriodFrom}`;
      link += `&dateTo=${item.totalPeriodTo}`;
      return link;
    },

    getTotalIncidentsLink(item) {
      return this.getBaseTotalIncidentsLink(item);
    },

    getOverallIncidentsLink(item) {
      let link = `/incidents/regular?filters=insights&category=${this.filters.category}`;
      if (item.type === 'focus') {
        link += `&focus=${item.id}`;
      } else {
        link += `&focus=${this.filters.focus}`;
      }
      return link;
    },

    getFieldByColumnName(name) {
      return name;
    },
    focusExists(value) {
      return Object.prototype.hasOwnProperty.call(this.focusesMap, value);
    },
    userExists(value) {
      return Object.prototype.hasOwnProperty.call(this.usersMap, value);
    },
    providerImpressionExists(value) {
      return Object.prototype.hasOwnProperty.call(this.providerImpressionsMap, value);
    },
  },
};
</script>
<style lang="scss" module>
.first-column {
  white-space: nowrap;
}
</style>

<style lang="scss">
.insights-table {
  table > tbody > tr > td:nth-child(1) {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #f5f5f5 !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  .highlighted {
    background: #f5f5f5 !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  .additional-text {
    font-size: 9px;
  }
}
</style>
