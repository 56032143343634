<template>
  <v-card
    min-height="700"
    elevation="3"
    class="incidents ma-4"
  >
    <v-tabs v-model="entity.activeTab">
      <v-tab>incidents</v-tab>
      <v-tab>units</v-tab>
      <v-tab-item>
        <incidents-filter
          :filters="filters"
          :show-clients-filter="true"
          @filtersUpdate="filtersUpdate"
        ></incidents-filter>
        <incidents-table
          v-if="filters.clientId"
          :items="entity.items"
          :pagination="pagination"
          :total-items="entity.pagination.totalItems"
          :loading="loading"
          @showUnits="showUnits"
          @update:pagination="updatePagination"
        ></incidents-table>
      </v-tab-item>

      <v-tab-item>
        <v-btn
          class="mt-4 ml-4"
          rounded
          text
          @click="showIncidentsTab"
        >
          <v-icon left>chevron_left</v-icon>
          Incidents
        </v-btn>

        <incident-info
          v-if="entity.currentIncident"
          :incident="entity.currentIncident"
        ></incident-info>

        <units-table
          :items="entity.units"
          :pagination="paginationUnits"
          :total-items="entity.paginationUnits.totalItems"
          :loading="entity.unitsLoading"
          :show-preempt-column="filters.clientId === clientIdentifiers.LONG_BEACH"
          @update:pagination="updatePaginationUnits"
        ></units-table>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import IncidentsFilter from '@/components/ImportedIncidents/IncidentsFilter';
import IncidentsTable from '@/components/ImportedIncidents/IncidentsTable';
import UnitsTable from '@/components/ImportedIncidents/UnitsTable';
import IncidentInfo from '@/components/ImportedIncidents/IncidentInfo';

import {
  ADMIN_LOAD_INCIDENTS,
  ADMIN_LOAD_UNITS,
  SHOW_INCIDENTS_TAB,
  SHOW_UNITS_TAB,
} from '@/store/ImportedIncidents/actions';
import {
  SET_INCIDENTS_FILTERS,
  SET_INCIDENTS_PAGINATION,
  SET_UNITS_PAGINATION,
} from '@/store/ImportedIncidents/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';
import CLIENT_IDENTIFIERS from '@/enums/clientIdentifiers';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('importedIncidents');

export default {
  components: {
    IncidentsFilter,
    IncidentsTable,
    UnitsTable,
    IncidentInfo,
  },

  mixins: [filtersAndTablesGeneralMixins],

  data() {
    return {
      clientIdentifiers: CLIENT_IDENTIFIERS,
    };
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),

    paginationUnits: {
      get() {
        return this.entity.paginationUnits;
      },
      set(value) {
        if (!_.isEqual(value, this.entity.paginationUnits)) {
          this.setUnitsPagination(value);
          this.loadUnits();
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      setPagination: SET_INCIDENTS_PAGINATION,
      setUnitsPagination: SET_UNITS_PAGINATION,
      setFilters: SET_INCIDENTS_FILTERS,
    }),
    ...mapActions({
      loadItems: ADMIN_LOAD_INCIDENTS,
      loadUnits: ADMIN_LOAD_UNITS,
      showIncidentsTab: SHOW_INCIDENTS_TAB,
      showUnitsTab: SHOW_UNITS_TAB,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    updatePaginationUnits(newPagination) {
      this.paginationUnits = newPagination;
    },

    /**
     * Show units for incident.
     *
     * @param {Object} incident - Incident object
     */
    showUnits(incident) {
      this.showUnitsTab(incident);
      this.loadUnits();
    },

    /**
     * Reloads documents paginated list for current client filter
     */
    async reloadItems() {
      if (!this.filters.clientId) {
        return;
      }
      this.loading = true;

      try {
        await this.loadItems();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
