<template>
  <div>
    <hospital-info
        :hospital="hospital"
        :loading="loading"
        @edit="showEditHospital"
    />

    <v-card
      v-if="$can('view', 'allowAccessNet')"
      class="ma-4"
    >
      <restriction-by-ip-table
          :items="allowedAccessNetItems"
          :loading="allowedAccessNetLoading"
          @edit="showEditAccessRestriction"
          @delete="showDeleteRowPopup"
      />
    </v-card>

    <edit-security-popup
      ref="editSecurityPopup"
      @saved="fetchAllowedAccessNet"
    />
    <my-hospital-edit-popup
      ref="editHospitalPopup"
      @saved="fetchHospitalData"
    />
    <confirmation ref="confirmationPopup" />
  </div>
</template>

<script>

import { SET_TITLE } from '@/store/mutations';
import { mapMutations } from 'vuex';
import RestrictionByIpTable from '@/components/ProfileSettings/RestrictionByIpTable';
import allowedAccessNets from '@/api/allowedAccessNets';
import hospitalsApi from '@/api/hospitals';
import EditSecurityPopup from '@/components/ProfileSettings/EditSecurityPopup';
import Confirmation from '@/components/Confirmation';
import HospitalInfo from '@/components/Hospitals/HospitalInfo';
import MyHospitalEditPopup from '@/components/Hospitals/MyHospitalEditPopup';

export default {
  components: {
    MyHospitalEditPopup,
    HospitalInfo,
    Confirmation,
    EditSecurityPopup,
    RestrictionByIpTable,
  },
  data() {
    return {
      allowedAccessNetItems: [],
      allowedAccessNetLoading: true,
      hospital: null,
      loading: false,
    };
  },
  mounted() {
    this.setTitle('My Hospital');
    this.fetchHospitalData();
    if (this.$can('view', 'allowAccessNet')) {
      this.fetchAllowedAccessNet();
    }
  },
  methods: {
    ...mapMutations({
      setTitle: SET_TITLE,
    }),

    /**
     * Fetch allowed access nets data.
     */
    async fetchHospitalData() {
      try {
        this.loading = true;
        this.hospital = await hospitalsApi.loadHospital(this.$store.state.siteInfo.hospitalId);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Fetch allowed access nets data.
     */
    async fetchAllowedAccessNet() {
      try {
        this.allowedAccessNetLoading = true;
        const response = await allowedAccessNets.getAccessRestrictions();
        this.allowedAccessNetItems = response.results;
      } finally {
        this.allowedAccessNetLoading = false;
      }
    },

    /**
     * Show dialog for Security Settings Access Restriction item editing.
     * If item for editing is not passed it means that creating of new item is requested.
     *
     * @param {object} [item] Item to edit.
     */
    showEditAccessRestriction(item) {
      this.$refs.editSecurityPopup.showPopup(item);
    },

    /**
     * Delete access restriction item and update list.
     * @param net
     * @returns {Promise<void>}
     */
    async deleteAccessRestriction(net) {
      await allowedAccessNets.deleteAccessRestriction({
        netId: net.netId,
      });
      this.fetchAllowedAccessNet();
    },

    /**
     * Show confirmation for deleting access restriction row.
     * @param item Access restriction object
     */
    showDeleteRowPopup(item) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.deleteAccessRestrictionTitle'),
        `Do you really want to delete access restriction ${item.name} with ip ${item.net}?`,
        () => {
          this.deleteAccessRestriction(item);
        },
      );
    },

    showEditHospital() {
      this.$refs.editHospitalPopup.show(this.hospital);
    },
  },
};
</script>
