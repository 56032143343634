<template>
  <v-card class="white elevation-3 ma-4 users">
    <users-toolbar
      :site-role="siteRole"
      :filters="filters"
      :export-loading="exportLoading"
      @filtersUpdate="filtersUpdate"
      @exportToExcel="exportToExcel"
      @addUser="showAddPopup"
    />
    <users-table
      :items="entity.items"
      :pagination="pagination"
      :total-items="entity.totalItems"
      :loading="loading"
      :site-role="siteRole"
      :show-actions-on-admins="$can('doActionsOnAdmins', 'users')"
      @clickEdit="showEditPopup"
      @clickDelete="showDelete"
      @clickRestore="showRestore"
      @update:pagination="updatePagination"
    />
    <users-edit-popup
      ref="editPopup"
      :site-role="siteRole"
      :is-continuing-education="isContinuingEducation"
      :can-create-admins="$can('createAdmins', 'users')"
      :can-update-contact-details="$can('updateContactDetails', 'users')"
      :license-types="entity.licenseTypes"
      @clickDelete="showDelete"
      @saved="reloadItems"
    />
    <confirmation
      ref="confirmationPopup"
      @confirmed="reloadItems"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import _ from 'lodash';
import UsersTable from '@/components/Users/UsersTable';
import UsersToolbar from '@/components/Users/UsersToolbar';
import UsersEditPopup from '@/components/Users/UsersEditPopup';
import Confirmation from '@/components/Confirmation';
import { LOAD_USERS, EXPORT_USERS, LICENSE_TYPES } from '@/store/Users/actions';
import { SET_FILTERS, SET_PAGINATION } from '@/store/Users/mutations';
import { SET_TITLE } from '@/store/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';
import apiUsers from '@/api/users';
import DELETION_STATUSES from '@/enums/deletionStatuses';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';

const { mapState, mapMutations } = createNamespacedHelpers('users');

export default {
  components: {
    UsersTable,
    UsersToolbar,
    UsersEditPopup,
    Confirmation,
  },
  mixins: [filtersAndTablesGeneralMixins],

  data() {
    return {
      exportLoading: false,
    };
  },

  props: {
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      entity: state => state,
    }),
    siteRole() {
      return this.$store.state.siteInfo.role;
    },
    isContinuingEducation() {
      return this.$store.state.siteInfo.clientSiteType === CLIENT_SITE_TYPES.CE_ENTRY;
    },
  },

  mounted() {
    if (this.isDeleted) {
      this.filters.status = DELETION_STATUSES.DELETED;
      this.filtersUpdate(this.filters);
    }

    this.$store.commit(SET_TITLE, this.$t('users.header'));
    this.licenseTypes();
  },

  methods: {
    ...mapMutations({
      setPagination: SET_PAGINATION,
      setFilters: SET_FILTERS,
    }),
    ...mapActions({
      loadItems: `users/${LOAD_USERS}`,
      exportUsers: `users/${EXPORT_USERS}`,
      licenseTypes: `users/${LICENSE_TYPES}`,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Export users by current filter and sort order to excel file.
     */
    async exportToExcel() {
      this.exportLoading = true;
      try {
        await this.exportUsers();
      } finally {
        this.exportLoading = false;
      }
    },

    /**
     * Show edit user popup.
     *
     * @param {Object} user - User object.
     */
    showEditPopup(user) {
      this.$refs.editPopup.showEdit(_.clone(user));
    },

    /**
     * Show add user popup.
     */
    showAddPopup() {
      this.$refs.editPopup.showNew();
    },

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} user - User object.
     */
    showDelete(user) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('users.deleteConfirmationTitle'),
        user.hasPendingFlags
          ? `${this.$t('users.deleteConfirmationText', user)}
            </br></br>
            <span style="color: #e04337">${this.$t('users.deleteConfirmationHasPendingFlagsText', user)}</span>`
          : this.$t('users.deleteConfirmationText', user),
        async () => {
          await apiUsers.deleteUser(user.registryId);
          this.$refs.editPopup.hidePopup();
          this.reloadItems();
        },
        null,
        user.hasPendingFlags,
      );
    },

    /**
     * Show restore confirmation popup.
     *
     * @param {Object} user - User object.
     */
    showRestore(user) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('users.restoreConfirmationTitle'),
        this.$t('users.restoreConfirmationText', user),
        async () => {
          await apiUsers.restoreUser(user.registryId);
          this.reloadItems();
        },
      );
    },
  },
};
</script>
