<template>
  <v-toolbar flat>
    <v-select
        v-model="monthValue"
        :items="months"
        hide-details
        label="Select Month"
        single-line
        class="mr-4"
        style="max-width: 200px"
    />

    <v-select
        v-model="yearValue"
        :items="years"
        hide-details
        label="Select Year"
        single-line
        style="max-width: 200px"
    />

    <v-spacer />

    <template v-if="showExportStatus">
      <span
          v-if="!status || !status.lastExportAt"
          class="mr-2"
      >No Status ( <a
          v-if="!loadingStatus"
          @click="$emit('refreshStatus')"
      >refresh</a>
        <v-progress-circular
            v-if="loadingStatus"
            indeterminate
            size="15"
            width="2"
            color="primary"
        /> )</span>
      <v-tooltip
          v-else-if="status.exportError"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              class="mr-2 nfirs-export-error"
              v-bind="attrs"
              v-on="on"
          >
            Export Error at {{ status.lastExportAt|datetimeToClientTimezone }}
            ( <a
              v-if="!loadingRetry"
              @click="$emit('retry')"
          >retry</a>
              <v-progress-circular
                  v-if="loadingRetry"
                  indeterminate
                  size="15"
                  width="2"
                  color="primary"
              /> )
        </span>
        </template>
        <span>{{ status.exportError }}</span>
      </v-tooltip>
      <span
          v-else
          class="mr-2 nfirs-export-success"
      >Last Export at {{ status.lastExportAt|datetimeToClientTimezone }}</span>
    </template>

    <v-btn
        icon
        color="primary"
        class="ma-2"
        @click="$emit('showHelp')"
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>

    <v-btn
        color="primary"
        class="ma-2"
        @click="$emit('showSettings')"
    >
      <v-icon
          left
          dark
      >
        mdi-cog-outline
      </v-icon>
      Export Settings
    </v-btn>

    <v-menu
        :disabled="downloading"
        offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
            :loading="downloading"
            :disabled="downloading"
            color="primary"
            class="ma-2"
            v-on="on"
        >
          {{ downloadName }}
          <span slot="loader">Downloading...</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$emit('download', 'xls', 'half')">
            <v-list-item-content>
              <v-list-item-title>Half Month</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$emit('download','xls', 'full')">
            <v-list-item-content>
              <v-list-item-title>Full Month</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-menu
        :disabled="sending"
        offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
            :loading="sending"
            :disabled="sending || sendDisabled"
            color="primary"
            class="ma-2"
            v-on="on"
        >
          Send all to emails
          <span slot="loader">Sending...</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$emit('send','xls', 'half')">
            <v-list-item-content>
              <v-list-item-title>Half Month</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('send','xls', 'full')">
            <v-list-item-content>
              <v-list-item-title>Full Month</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

  </v-toolbar>
</template>
<script>
import allMonths from '@/data/Dropdowns/months';

export default {
  name: 'Toolbar',

  props: {
    downloading: {
      type: Boolean,
      required: true,
    },
    sending: {
      type: Boolean,
      required: true,
    },
    downloadName: {
      type: String,
      default: 'Download',
    },
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    sendDisabled: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Object,
      required: false,
    },
    showExportStatus: {
      type: Boolean,
      required: true,
    },
    loadingStatus: {
      type: Boolean,
      required: true,
    },
    loadingRetry: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      yearValue: this.year,
      monthValue: this.month,
    };
  },

  computed: {
    months() {
      const months = allMonths;

      months.forEach((month, key) => {
        months[key].disabled = false;
        if (this.yearValue === new Date().getFullYear()
            && month.value > new Date().getMonth() + 1
        ) {
          months[key].disabled = true;
        }
      });
      return months;
    },

    years() {
      let currentYear = new Date().getFullYear();
      const startYear = currentYear - 5;
      const years = [];
      for (currentYear; currentYear >= startYear; currentYear--) {
        years.push(currentYear);
      }
      return years;
    },
  },

  watch: {
    /**
     * Watch Year property and update month.
     */
    yearValue() {
      if (
        this.yearValue === new Date().getFullYear()
          && this.monthValue > new Date().getMonth() + 1
      ) {
        this.monthValue = new Date().getMonth() + 1;
      }
      this.$emit('update:year', this.yearValue);
    },

    /**
     * Watch month and update prop.
     */
    monthValue() {
      this.$emit('update:month', this.monthValue);
    },
  },
};

</script>
