<template>
  <div :class="$style.toolbar">
    <v-flex xs2>
      <v-autocomplete
        v-model="tableFilters.clientId"
        :items="faxClients"
        :label="$t('faxes.filters.client')"
        item-value="registryId"
        item-text="name"
        single-line
        :menu-props="{'minWidth': 300, 'maxWidth': 300}"
      ></v-autocomplete>
    </v-flex>
    <v-flex xs2>
      <v-text-field
        v-model="tableFilters.sequenceNumber"
        :label="$t('faxes.filters.incident')"
        single-line
        clearable
      ></v-text-field>
    </v-flex>
    <v-flex xs2>
      <v-select
        v-model="tableFilters.status"
        :items="faxStatuses"
        :label="$t('faxes.filters.status')"
        item-value="id"
        item-text="name"
        single-line
      ></v-select>
    </v-flex>
    <v-flex xs2>
      <date-picker
        v-model="tableFilters.dateFrom"
        :label="$t('faxes.filters.dateFrom')"
        :max="maximumDateFrom"
      ></date-picker>
    </v-flex>
    <v-flex xs2>
      <date-picker
        v-model="tableFilters.dateTo"
        :label="$t('faxes.filters.dateTo')"
        :min="minimumDateTo"
      ></date-picker>
    </v-flex>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import { SET_FILTERS } from '@/store/Faxes/mutations';
import DatePicker from '../DatePicker';

const { mapState, mapMutations } = createNamespacedHelpers('faxes');

export default {
  name: 'FaxesToolbar',
  components: {
    DatePicker,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      statuses: state => state.statuses,
    }),
    /**
     * Get maximum date from according to date to (date to - 1 day).
     */
    maximumDateFrom() {
      if (this.tableFilters.dateTo) {
        const dateTo = moment.utc(this.tableFilters.dateTo, 'YYYY-MM-DD');
        const max = dateTo.clone().add(-1, 'days');

        return max.toISOString();
      }
      return null;
    },
    /**
     * Get minimum date to according to date from (date from + 1 day).
     */
    minimumDateTo() {
      if (this.tableFilters.dateFrom) {
        const dateFrom = moment.utc(this.tableFilters.dateFrom, 'YYYY-MM-DD');
        const min = dateFrom.clone().add(1, 'days');

        return min.toISOString();
      }
      return null;
    },
    /**
     * Table filters: clientId, sequenceNumber, status, dateFrom and dateTo.
     */
    tableFilters: {
      get() {
        return this.filters;
      },
      set(value) {
        this.setFilters(value);
      },
    },
    /**
     * Statuses for fax with All item.
     */
    faxStatuses() {
      const allStatusesItem = { id: null, name: 'All' };

      return [allStatusesItem].concat(this.statuses);
    },
    /**
     * Clients list for fax with All item.
     */
    faxClients() {
      const allClientsItem = { registryId: null, name: 'All' };

      return [allClientsItem].concat(this.$store.state.clientsList);
    },
  },
  watch: {
    tableFilters: {
      // eslint-disable-next-line
      handler: _debounce(function () {
        this.$emit('tableUpdate');
      }, 500),
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setFilters: SET_FILTERS,
    }),
  },
};
</script>

<style lang="scss" module>
.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}
</style>
