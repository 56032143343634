<template>
  <div>
    <v-dialog
      v-model="showPopup"
      max-width="800"
      @close="$emit('close')"
    >
      <v-card>
        <v-container v-if="duplicatedIncident">
          <v-card-title class="headline pt-0">
            {{ $t('duplicateIncidents.duplicateIncident') }}:
            {{ duplicatedIncident ? duplicatedIncident.sequenceNumber : null }}
          </v-card-title>
          <v-card-text class="pt-0">
            <v-alert
              v-model="errorAlert"
              type="error"
              outlined
              dismissible
              transition="scale-transition"
            >
              <div v-html="errorMessage" />
            </v-alert>
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-header
              hide-default-footer
            >
              <template v-slot:header="{ props: {  } }">
                <thead class="v-data-table-header">
                  <tr>
                    <th/>
                    <th class="column text-left">
                      {{ $t('duplicateIncidents.tableHeaders.originalIncident') }}
                      <v-btn
                        :disabled="!duplicatedIncident.originalIncident"
                        :loading="downloadOriginalLoading"
                        depressed
                        small
                        @click="downloadOriginal"
                      >
                        {{ $t('duplicateIncidents.tableHeaders.json') }}
                        <v-icon right>cloud_download</v-icon>
                      </v-btn>
                    </th>
                    <th class="column text-left">
                      {{ $t('duplicateIncidents.tableHeaders.duplicateIncident') }}
                      <v-btn
                        :loading="downloadDuplicateLoading"
                        depressed
                        small
                        @click="downloadDuplicate"
                      >
                        {{ $t('duplicateIncidents.tableHeaders.json') }}
                        <v-icon right>cloud_download</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.fieldName"
                  >
                    <td>{{ item.fieldName }}</td>
                    <td>
                      <span :class="item.originalValue !== item.duplicateValue ? 'del' : ''">
                        {{ item.originalValue }}
                      </span>
                    </td>
                    <td>
                      <span :class="item.originalValue !== item.duplicateValue ? 'ins' : ''">
                        {{ item.duplicateValue }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pb-0 pt-0">
            <v-spacer/>
            <v-btn
              :disabled="!duplicatedIncident.originalIncident"
              color="blue darken-1"
              text
              @click.native="diff()"
            >{{ $t('duplicateIncidents.buttons.diff') }}</v-btn>
            <v-btn
              :loading="overwriteButtonLoading"
              color="blue darken-1"
              text
              @click.native="overwriteConfirmation()"
            >{{ $t('duplicateIncidents.buttons.overwrite') }}</v-btn>
            <v-btn
              :disabled="!duplicatedIncident.originalIncident"
              :loading="keepOriginalButtonLoading"
              color="blue darken-1"
              text
              @click.native="keepOriginalConfirmation()"
            >{{ $t('duplicateIncidents.buttons.keepOriginal') }}</v-btn>
            <v-btn
              :disabled="!duplicatedIncident.originalIncident"
              color="blue darken-1"
              text
              @click.native="changeAndCommitConfirmation()"
            >{{ $t('duplicateIncidents.buttons.change') }}</v-btn>
            <v-btn
              color="red darken-1"
              text
              @click.native="showPopup = false"
            >{{ $t('buttons.close') }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <change-and-commit-popup
      ref="changeAndCommitPopup"
      @commited="closeAfterAction"
    />
    <confirmation ref="confirmationPopup" />
  </div>
</template>

<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { createNamespacedHelpers } from 'vuex';
import Confirmation from '@/components/Confirmation';
import i18n from '@/lang/i18n';
import mapFilters from '@/utils/mapFilters';
import ChangeAndCommitPopup from './ChangeAndCommitPopup';
import {
  OVERWRITE,
  KEEP_ORIGINAL,
  COMMIT,
  DOWNLOAD_ORIGINAL_JSON,
  DOWNLOAD_DUPLICATE_JSON,
} from '../../store/DuplicateIncidents/actions';

const { mapActions } = createNamespacedHelpers('duplicateIncidents');

export default {
  name: 'DuplicateIncidentPopup',
  components: {
    ChangeAndCommitPopup,
    Confirmation,
  },
  mixins: [errorAlertMixins],
  data() {
    return {
      showPopup: false,
      duplicatedIncident: null,
      keepOriginalButtonLoading: false,
      overwriteButtonLoading: false,
      downloadOriginalLoading: false,
      downloadDuplicateLoading: false,
      headers: [
        {
          text: '',
          sortable: false,
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.originalIncident'),
          sortable: false,
        },
        {
          text: this.$t('duplicateIncidents.tableHeaders.duplicateIncident'),
          sortable: false,
        },
      ],
      simpleFields: {
        II_IncidentNumber: this.$t(
          'duplicateIncidents.tableHeaders.incidentNumber',
        ),
        II_IncidentStreetName: this.$t(
          'duplicateIncidents.tableHeaders.streetName',
        ),
        II_IncidentStreetNumber: this.$t(
          'duplicateIncidents.tableHeaders.streetNumber',
        ),
        PA_Age: this.$t('duplicateIncidents.tableHeaders.age'),
        PA_Sex: this.$t('duplicateIncidents.tableHeaders.sex'),
        TI_Contact: this.$t('duplicateIncidents.tableHeaders.contact'),
        PI_FirstName: this.$t(
          'duplicateIncidents.tableHeaders.patientFirstName',
        ),
        PI_LastName: this.$t('duplicateIncidents.tableHeaders.patientLastName'),
      },
    };
  },
  computed: {
    /**
     * Get items for table of compare.
     *
     * @return {Array}
     */
    items() {
      const items = [];

      if (!this.duplicatedIncident) {
        return items;
      }

      items.push({
        fieldName: this.$t(
          'duplicateIncidents.tableHeaders.dateTimeSubmission',
        ),
        originalValue: this.duplicatedIncident.originalIncident
          ? this.datetimeToClientTimezone(
            this.duplicatedIncident.originalIncident.incidentDataUpdatedAt,
          )
          : 'N/A',
        duplicateValue: this.datetimeToClientTimezone(
          this.duplicatedIncident.incidentDataUpdatedAt,
        ),
      });

      _.forEach(this.simpleFields, (text, field) => {
        items.push({
          fieldName: text,
          originalValue: this.duplicatedIncident.originalIncident
            ? this.duplicatedIncident.originalIncident.data[field]
            : 'N/A',
          duplicateValue: this.duplicatedIncident.data[field],
        });
      });

      items.push({
        fieldName: this.$t('duplicateIncidents.tableHeaders.jsonFileSize'),
        originalValue: this.duplicatedIncident.originalIncident
          ? this.humanFileSize(
            this.duplicatedIncident.originalIncident.jsonFileSize,
          )
          : 'not found',
        duplicateValue: this.humanFileSize(
          this.duplicatedIncident.jsonFileSize,
        ),
      });

      return items;
    },
  },
  methods: {
    ...mapFilters(['datetimeToClientTimezone', 'humanFileSize']),
    ...mapActions({
      overwrite: OVERWRITE,
      keepOriginal: KEEP_ORIGINAL,
      commit: COMMIT,
      downloadOriginalJson: DOWNLOAD_ORIGINAL_JSON,
      downloadDuplicateJson: DOWNLOAD_DUPLICATE_JSON,
    }),

    /**
     * Show Duplicate incident popup.
     *
     * @param {Object} incident - Duplicate incident object
     */
    show(incident) {
      this.duplicatedIncident = incident;
      this.showPopup = true;
    },

    /**
     * Show confirmation of overwrite incident action.
     */
    overwriteConfirmation() {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('duplicateIncidents.commitAndOwerwriteConfirmationTitle'),
        i18n.t('duplicateIncidents.commitAndOwerwriteConfirmationText'),
        async () => {
          this.overwriteButtonLoading = true;
          await this.overwrite(this.duplicatedIncident);
          this.overwriteButtonLoading = false;
          this.closeAfterAction();
        },
      );
    },

    /**
     * Show confirmation of keep original incident action.
     */
    keepOriginalConfirmation() {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('duplicateIncidents.keepOriginalConfirmationTitle'),
        i18n.t('duplicateIncidents.keepOriginalConfirmationText'),
        async () => {
          this.keepOriginalButtonLoading = true;
          await this.keepOriginal(this.duplicatedIncident);
          this.keepOriginalButtonLoading = false;
          this.closeAfterAction();
        },
      );
    },

    /**
     * Show confirmation of change and commit incident action.
     */
    changeAndCommitConfirmation() {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('duplicateIncidents.changeConfirmationTitle'),
        i18n.t('duplicateIncidents.changeConfirmationText'),
        async () => {
          this.$refs.changeAndCommitPopup.show(this.duplicatedIncident);
        },
      );
    },

    /**
     * Action show diff popup.
     */
    diff() {
      this.showPopup = false;
      this.$emit('showDiff', this.duplicatedIncident);
    },

    /**
     * Close popup after any action.
     */
    closeAfterAction() {
      this.showPopup = false;
      this.$emit('closeAfterAction');
    },

    /**
     * Download original json file.
     */
    async downloadOriginal() {
      this.downloadOriginalLoading = true;
      try {
        await this.downloadOriginalJson(
          this.duplicatedIncident.originalIncident,
        );
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.downloadOriginalLoading = false;
      }
    },

    /**
     * Download duplicate json file.
     */
    async downloadDuplicate() {
      this.downloadDuplicateLoading = true;
      try {
        await this.downloadDuplicateJson(this.duplicatedIncident);
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.downloadDuplicateLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables';

.del {
  color: $diff-del-color;
  background: $diff-del-background-color;
}

.ins {
  color: $diff-ins-color;
  background: $diff-ins-background-color;
}
</style>
