<template>
  <div>
    <v-data-table
      :headers="header"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :loading="loading"
      :height="tableHeight"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      no-data-text="No data available"
      no-results-text="No data available"
      fixed-header
      class="header-nowrap"
    >
      <template v-slot:item.IncidentDateTime="{ item }">
        {{ item.IncidentDate }} {{ item.IncidentTime }}
      </template>
      <template v-slot:item.IncidentType="{ item }">
        {{ item.TriageInfo || item.IncidentType }}
      </template>

      <template v-slot:item.IncidentAddress="{ item }">
        {{ item.IncidentStreetNumber }} {{ item.IncidentStreetName }}
      </template>

      <template v-slot:item.DispatchDateTime="{ item }">
        {{ item.DispatchDate }} {{ item.DispatchTime }}
      </template>
      <template v-slot:item.AvailableDateTime="{ item }">
        {{ item.AvailableDate }} {{ item.AvailableTime }}
      </template>
    </v-data-table>
  </div>

</template>

<script>
import _ from 'lodash';
import viaValuesMap from '@/data/Lookups/via';
import fixedHeader from '@/mixins/fixedHeader';
import reconciliation from '@/api/reconciliation';

export default {
  name: 'SearchTable',
  mixins: [fixedHeader],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    clientId: {
      type: Number,
      default: null,
    },
    showResponseTypeColumns: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viaValuesMap,
      loading: true,
      items: [],
      totalItems: 0,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['IncidentNumber'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
    };
  },
  computed: {
    header() {
      let header = [
        {
          text: 'CAD_IncidentNo',
          value: 'IncidentNumber',
        },
        {
          text: 'CAD_DateTime',
          value: 'IncidentDateTime',
        },
        {
          text: 'CAD_Address',
          value: 'IncidentAddress',
        },
        {
          text: 'CAD_IncidentType',
          value: 'IncidentType',
        },
        {
          text: 'CAD_TransportingUnit',
          value: 'Unit',
        },
      ];

      if (this.showResponseTypeColumns) {
        header = header.concat([
          {
            text: 'CAD_ResponseTypeStart',
            value: 'ResponseTypeStart',
          },
          {
            text: 'CAD_ResponseTypeFinal',
            value: 'ResponseTypeFinal',
          },
        ]);
      }

      return header.concat([
        {
          text: 'CAD_DispatchDateTime',
          value: 'DispatchDateTime',
        },
        {
          text: 'CAD_AvailableDateTime',
          value: 'AvailableDateTime',
        },
      ]);
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.fetchData();
      }
    },
    filters: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    isActive(newValue) {
      if (newValue) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.isActive) {
        return;
      }
      try {
        this.loading = true;
        const params = {
          clientId: this.clientId,
          page: this.pagination.page,
          per_page: this.pagination.itemsPerPage,
          order_by: this.pagination.sortBy[0] || 'IncidentNumber',
          sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        };
        const result = await reconciliation.fetchData('search', {
          ...params,
          ...this.filters,
        });
        this.items = result.results;
        this.totalItems = result.pagination.total;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
