import i18n from '@/lang/i18n';
import hospitalsApi from '@/api/hospitals';
import validatePasswordRegexp from '@/data/Misk/validatePasswordRegexp';

export default {
  /**
   * Validation of unique hospital value.
   */
  validateHospitalUnique: {
    getMessage() {
      return i18n.t('validation.uniqueHospitalValue');
    },
    async validate(value, params) {
      if (value) {
        try {
          await hospitalsApi.validateUnique(value, params[0], params[1]);
          return true;
        } catch (e) {
          return false;
        }
      }
      return false;
    },
  },

  /**
   * Validation for password content
   * (1 capital letter, 1 lowercase letter, 1 digit, 1 special character).
   */
  validatePassword: {
    getMessage() {
      return i18n.t('validation.validatePassword');
    },
    validate(value) {
      return validatePasswordRegexp.test(value);
    },
  },
};
