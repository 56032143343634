<template>
  <div
    v-if="visible"
    :class="$style.container"
  >
    <v-progress-circular
      color="red"
      indeterminate
    />
    <div :class="$style.caption">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 24px;
}

.caption {
  margin-left: 18px;
  font-size: 15px;
}
</style>
