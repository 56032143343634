<template>
  <v-layout
    class="pl-9 pr-9"
    wrap
  >
    <v-flex
      v-if="showClientsFilter"
      xs4
    >
      <v-autocomplete
        v-model="filters.clientId"
        :items="clients"
        :label="$t('importedIncidents.filters.selectClient')"
        item-value="registryId"
        item-text="name"
        single-line
      ></v-autocomplete>
    </v-flex>
    <v-flex
      :class="showClientsFilter ? 'offset-xs1' : ''"
      xs2
      text-left
    >
      <date-picker
        v-model="filters.incidentDate"
        :label="$t('importedIncidents.filters.incidentDate')"
        :max="today"
      ></date-picker>
    </v-flex>
    <v-flex
      xs2
      offset-xs1
      text-left
    >
      <v-text-field
        v-model="filters.incidentNumber"
        :label="$t('importedIncidents.filters.incidentNumber')"
        clearable
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import DatePicker from '../DatePicker';

export default {
  name: 'FlaggedUsersFilter',

  components: {
    DatePicker,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
    showClientsFilter: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      today: moment().toISOString(),
    };
  },

  computed: {
    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      return this.$store.state.clientsList;
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },
};
</script>
