<template>
  <v-toolbar
      flat
      class="mb-4 entries-toolbar"
  >
    <date-range-picker-with-periods
      v-model="dateRangeComputed"
      class="mr-5"
      position="right"
    />
    <v-text-field
        v-model="filtersData.search"
        label="Search"
        hide-details
        class="mr-5"
        style="max-width: 300px"
    />
    <v-autocomplete
        v-if="showAttendeeFilter"
        v-model="filtersData.attendeeId"
        :items="attendees"
        :loading="attendeesLoading"
        label="Attendee"
        hide-details
        style="max-width: 300px"
        clearable
        item-text="name"
        item-value="id"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ data.item.licenseType }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-spacer></v-spacer>
    <v-btn
        color="primary"
        @click="$emit('clickAdd')"
    >+ Add CE Entry</v-btn>
  </v-toolbar>
</template>
<script>
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import ceHours from '@/api/ceHours';

export default {
  name: 'EntriesToolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    showAttendeeFilter: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      filtersData: this.filters,
      dateRange: [],
      attendees: [],
      attendeesLoading: false,
    };
  },
  computed: {
    dateRangeComputed: {
      get() {
        return this.dateRange;
      },
      set(value) {
        this.dateRange = value;
        if (this.dateRange && this.dateRange.length === 2) {
          const [dateFrom, dateTo] = this.dateRange;
          this.filtersData.dateFrom = dateFrom;
          this.filtersData.dateTo = dateTo;
        }
      },
    },
  },
  watch: {
    filtersData: {
      handler() {
        this.$emit('filtersUpdate', this.filtersData);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchAttendees();
  },
  methods: {
    async fetchAttendees() {
      if (this.showAttendeeFilter && !this.attendees.length) {
        this.attendeesLoading = true;
        try {
          this.attendees = await ceHours.getAllAttendees();
        } finally {
          this.attendeesLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.entries-toolbar {
  z-index: 2 !important;
}
</style>
