export const SET_CLIENTS_ITEMS = 'SET_CLIENTS_ITEMS';
export const SET_CLIENTS_FILTERS = 'SET_CLIENTS_FILTERS';
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_NEMSIS_AGENCIES = 'SET_NEMSIS_AGENCIES';

export default {
  /**
   * Save clients list.
   *
   * @param {Object} state - Clients state
   * @param {Object} items - Clients list to set
   */
  [SET_CLIENTS_ITEMS]: (state, items) => {
    state.items = items;
  },

  /**
   * Saves filters of clients.
   *
   * @param {Object} state - Clients state
   * @param {Object} filters - Filters to set
   */
  [SET_CLIENTS_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  /**
   * Save providers list.
   *
   * @param {Object} state - Clients state
   * @param {Array} providers - Providers list to set
   */
  [SET_PROVIDERS]: (state, providers) => {
    state.providers = providers;
  },

  /**
   * Save providers list.
   *
   * @param {Object} state - Clients state
   * @param {Array} templates - Templates list to set
   */
  [SET_TEMPLATES]: (state, templates) => {
    state.templates = templates;
  },

  /**
   * Save Nemsis agencies list.
   *
   * @param {Object} state - Clients state
   * @param {Array} nemsisAgencies - Nemsis agencies list to set
   */
  [SET_NEMSIS_AGENCIES]: (state, nemsisAgencies) => {
    state.nemsisAgencies = nemsisAgencies;
  },
};
