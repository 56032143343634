import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    title: 'Dashboard',
    clients: [],
    totals: [],
  },
  actions,
  mutations,
  getters,
};
