<template>
  <div class="incident-view">
    <v-card
      class="white elevation-3 ma-4"
      :loading="loading"
    >
      <toolbar
        v-if="entity.incident"
        :show-technical-info="$can('seeTechnicalInfo', 'incident')"
        :show-flag-btn="canAddFlag(entity.incident)"
        :show-edit-btn="$can('edit', 'incident') && !entity.incident.readOnly"
        :incident="entity.incident"
        :saving="saving"
        :validating="validating"
        :validation-supports="validationSupports"
        :validated="validated"
        :downloading="downloading || pdfWaiting"
        :edit-mode.sync="editMode"
        :save-disabled="!incidentIsChanged"
        @clickFlag="addFlag(entity.incident)"
        @download="downloadPdf(entity.incident.id, 'general', null)"
        @clickTechnicalInfo="$refs.technicalInfoPopup.show()"
        @cancelEdit="cancelEdit"
        @save="saveIncidentData()"
        @validate="validateIncidentData()"
        @clickEdit="validated = false"
      />
      <v-divider/>
      <v-row>
        <v-col
          md="3"
          class="pt-0 pb-0"
        >
          <categories
              :value.sync="activeTab"
              :height="containersHeight"
              :categories="categories"
          />
        </v-col>

        <v-col
          md="5"
          class="pt-0 pb-0 pr-0"
        >
          <v-tabs-items v-model="activeTab">
            <v-tab-item
                v-for="(item, index) in entity.incidentFields"
                v-bind:key="'tab_' + index"
            >
              <div v-if="activeTab === index">
                <edit-category-fields
                    v-if="editMode"
                    :height="containersHeight"
                    :field-list="item.fields"
                    :incident-data.sync="incidentDataClone"
                />
                <view-category-fields
                  v-else
                  :height="containersHeight"
                  :field-list="item.fields"
                  :incident="entity.incident"
                />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>

        <v-col
          md="4"
          class="pt-0 pb-0 pl-0"
        >
          <div
            class="right-container"
            v-bind:style="{ height: containersHeight + 'px' }"
          >
            <flag-info
              v-if="entity.incident && entity.incident.flag"
              ref="flagInfo"
              :flag="entity.incident.flag"
              :incident="entity.incident"
              :can-edit="canAddFlag(entity.incident)"
              :can-see-participants="$can('seeParticipants', 'flag')"
              :loading="flagInfoLoading"
              class="mb-5"
              @deleteAlert="deleteAlert"
              @deleteLastAlert="deleteLastAlert"
              @clickEdit="addFlag(entity.incident)"
              @clickUnderstand="$refs.respondFlagPopup.show(true)"
              @clickDidNotUnderstand="$refs.respondFlagPopup.show(false)"
              @deleteComment="deleteComment"
              @addComment="$refs.addFlagCommentPopup.show()"
              @closePendingFlag="$refs.closePendingFlagPopup.show()"
            />
            <gallery
              v-if="documentItems.length"
              title="Documents"
              icon="mdi-file-document"
              :items="documentItems"
              :check-webp="true"
              class="mb-5"
              @click="showImage"
            />
            <gallery
              v-if="imagesItems.length"
              title="Images"
              icon="mdi-file-image"
              :items="imagesItems"
              class="mb-5"
              @click="showImage"
            />
            <last-updates-logs
              v-if="entity.incident && entity.incident.incidentAudits.length"
              :items="entity.incident.incidentAudits"
              :loading="auditsLoading"
              class="mb-5"
            />
            <transmission-history
              v-if="entity.incident"
              :items="entity.transmissionHistory || []"
              :loading="loadingTransmissionHistory"
              class="mb-5"
            />
            <files
              v-if="entity.incident"
              :incidentId="entity.incident.id"
              class="mb-5"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <add-flag-popup
        ref="addFlagPopup"
        @sent="reloadFlag()"
    />
    <respond-flag-popup
      v-if="entity.incident"
      ref="respondFlagPopup"
      :incident-id="entity.incident.id"
      @responded="reloadFlag()"
    />
    <confirmation ref="confirmationPopup"/>
    <save-reason-popup ref="saveReasonPopup"/>
    <technical-info-popup
      v-if="entity.incident"
      ref="technicalInfoPopup"
      :incident="entity.incident"
      :show-actions="$store.getters.isImpersonated"
    />
    <view-incident-popup
        ref="viewIncidentPopup"
        @add-flag="addFlag"
    />
    <validation-popup
      v-if="entity.incident"
      ref="validationPopup"
      :incidentId="entity.incident.id"
      @validated="validated = true"
    />
    <pre-save-validation-popup
      ref="preSaveValidationPopup"
      @save="saveIncidentData(true)"
      @startValidation="validateIncidentData"
    />
    <add-flag-comment-popup
      v-if="entity.incident"
      ref="addFlagCommentPopup"
      :incidentId="entity.incident.id"
      @sentComment="reloadFlag()"
    />
    <close-pending-flag-popup
      v-if="entity.incident"
      ref="closePendingFlagPopup"
      :incidentId="entity.incident.id"
      @closedPendingFlag="reloadFlag()"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers, mapActions } from 'vuex';
import {
  LOAD_INCIDENT_FIELDS, LOAD_INCIDENT, RELOAD_FLAG_INFO,
  LOAD_TRANSMISSION_HISTORY, RELOAD_INCIDENT_AUDITS,
} from '@/store/IncidentView/actions';
import { CLEAR_STATE, SET_INCIDENT } from '@/store/IncidentView/mutations';
import Categories from '@/components/IncidentView/Categories';
import ViewCategoryFields from '@/components/IncidentView/ViewCategoryFields';
import Gallery from '@/components/IncidentView/Gallery';
import FlagInfo from '@/components/IncidentView/FlagInfo';
import Toolbar from '@/components/IncidentView/Toolbar';
import incidentFlagMixins from '@/mixins/incidentFlagMixins';
import downloadPdf from '@/mixins/downloadPdf';
import AddFlagPopup from '@/components/FlaggedUsers/AddFlagPopup';
import EditCategoryFields from '@/components/IncidentView/EditCategoryFields';
import LastUpdatesLogs from '@/components/IncidentView/LastUpdatesLogs';
import TransmissionHistory from '@/components/IncidentView/TransmissionHistory';
import Confirmation from '@/components/Confirmation';
import incidents from '@/api/incidents';
import SaveReasonPopup from '@/components/IncidentView/SaveReasonPopup';
import RespondFlagPopup from '@/components/IncidentView/RespondFlagPopup';
import AddFlagCommentPopup from '@/components/IncidentView/AddFlagCommentPopup';
import ClosePendingFlagPopup from '@/components/IncidentView/ClosePendingFlagPopup';
import TechnicalInfoPopup from '@/components/IncidentView/TechnicalInfoPopup';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';
import Files from '@/components/IncidentView/Files';
import flaggedUsers from '@/api/flaggedUsers';
import ValidationPopup from '@/components/IncidentView/ValidationPopup';
import PreSaveValidationPopup from '@/components/IncidentView/PreSaveValidationPopup';

const { mapMutations } = createNamespacedHelpers('incidentView');

export default {
  name: 'IncidentViewClient',
  mixins: [incidentFlagMixins, downloadPdf],
  components: {
    PreSaveValidationPopup,
    ValidationPopup,
    ViewIncidentPopup,
    TechnicalInfoPopup,
    AddFlagCommentPopup,
    ClosePendingFlagPopup,
    RespondFlagPopup,
    SaveReasonPopup,
    Confirmation,
    TransmissionHistory,
    LastUpdatesLogs,
    EditCategoryFields,
    AddFlagPopup,
    Toolbar,
    FlagInfo,
    Gallery,
    ViewCategoryFields,
    Categories,
    Files,
  },
  data() {
    return {
      activeTab: 0,
      windowHeight: window.innerHeight,
      indentHeight: 200,
      loading: false,
      loadingTransmissionHistory: false,
      flagInfoLoading: false,
      auditsLoading: false,
      editMode: false,
      incidentDataClone: null,
      saving: false,
      validating: false,
      validated: false,
    };
  },
  computed: {
    /**
     * Get incident view full state.
     *
     * @return {Object}
     */
    entity() {
      return this.$store.state.incidentView;
    },

    /**
     * Is incident changed.
     *
     * @return {Boolean}
     */
    incidentIsChanged() {
      const origData = _.cloneDeep(this.entity.incident.data);
      _.forEach(this.incidentDataClone, (value, field) => {
        if (!Object.prototype.hasOwnProperty.call(origData, field)) {
          origData[field] = null;
        }
      });
      return !_.isEqualWith(this.incidentDataClone, origData, (value1, value2) => {
        if (this.bothIsEmpty(value1, value2)) {
          return true;
        }
        if (this.equalNumeric(value1, value2)) {
          return true;
        }
        return undefined;
      });
    },

    /**
     * Calculates height of containers.
     *
     * @return {Number}
     */
    containersHeight() {
      return this.windowHeight - this.indentHeight;
    },

    /**
     * Get list of category names.
     *
     * @return {Array}
     */
    categories() {
      return this.entity.incidentFields.map(item => item.categoryName);
    },

    /**
     * Get list of page images
     *
     * @return {Array}
     */
    documentItems() {
      const items = [];
      if (this.entity.incident && this.entity.incident.images) {
        this.entity.incident.images.forEach((item, index) => {
          if (item.type === 'page') {
            items.push({
              id: index,
              src: item.thumbnail_url,
            });
          }
        });
      }
      return items;
    },

    /**
     * Get list of images
     *
     * @return {Array}
     */
    imagesItems() {
      const items = [];
      if (this.entity.incident && this.entity.incident.images) {
        this.entity.incident.images.forEach((item, index) => {
          if (item.type !== 'page') {
            items.push({
              id: index,
              src: item.url,
            });
          }
        });
      }
      return items;
    },

    /**
     * Is validation supports.
     *
     * @return {Boolean}
     */
    validationSupports() {
      if (this.entity.incident && this.entity.incident.validationSupports) {
        return true;
      }
      return false;
    },
  },
  watch: {
    incidentDataClone: {
      handler() {
        this.validated = false;
      },
      deep: true,
    },
  },
  async mounted() {
    this.clearState();
    await this.loadData();
    this.activeTab = 0;
    if (window.location.hash === '#edit') {
      this.editMode = true;
    }
    this.loadTransmissionHistoryData();
  },
  created() {
    window.addEventListener('resize', this.recalculateHeights);
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateHeights);
  },
  methods: {
    ...mapMutations({
      clearState: CLEAR_STATE,
      setIncident: SET_INCIDENT,
    }),
    ...mapActions({
      loadFields: `incidentView/${LOAD_INCIDENT_FIELDS}`,
      loadIncident: `incidentView/${LOAD_INCIDENT}`,
      loadTransmissionHistory: `incidentView/${LOAD_TRANSMISSION_HISTORY}`,
      reloadFlagInfo: `incidentView/${RELOAD_FLAG_INFO}`,
      reloadAudits: `incidentView/${RELOAD_INCIDENT_AUDITS}`,
    }),

    /**
     * Delete flagged user.
     *
     * @param alert - Flag alert object
     */
    deleteAlert(alert) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        `Are you sure you want to delete flagged user "${alert.user.firstName} ${alert.user.lastname}"?`,
        async () => {
          await flaggedUsers.deleteAlert(alert.incidentId, alert.alertId);
          this.$refs.flagInfo.alertDeleted(alert.alertId);
          this.reloadFlag();
        },
      );
    },

    /**
     * Delete last flagged user (delete whole flag).
     *
     * @param alert - Flag alert object
     */
    deleteLastAlert(alert) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'It is the last flagged user, the flag cannot exist without users.'
          + ' Do you confirm deleting the entire flag?',
        async () => {
          await flaggedUsers.deleteFlag(alert.incidentId);
          this.$refs.flagInfo.alertDeleted(alert.alertId);
          this.reloadFlag();
        },
      );
    },

    /**
     * Check two values, and returns true if both is empty.
     *
     * @param value1 - First value
     * @param value2 - Second value
     */
    bothIsEmpty(value1, value2) {
      const firstIsEmpty = value1 === null || value1 === undefined || value1 === '';
      const secondIsEmpty = value2 === null || value2 === undefined || value2 === '';
      return firstIsEmpty && secondIsEmpty;
    },

    /**
     * Check two numeric values with comparing like string.
     *
     * @param value1 - First value
     * @param value2 - Second value
     */
    equalNumeric(value1, value2) {
      const first = typeof value1 === 'number' ? value1.toString() : value1;
      const second = typeof value2 === 'number' ? value2.toString() : value2;
      return first === second;
    },

    /**
     * Reload flag information
     */
    async reloadFlag() {
      try {
        this.flagInfoLoading = true;
        await this.reloadFlagInfo(this.$route.params.id);
      } finally {
        this.flagInfoLoading = false;
      }
    },

    /**
     * Reload incident information
     */
    async reloadIncidentAudits() {
      try {
        this.auditsLoading = true;
        await this.reloadAudits(this.$route.params.id);
      } finally {
        this.auditsLoading = false;
      }
    },

    /**
     * Load all data of incident.
     */
    async loadData() {
      try {
        this.loading = true;
        const promises = [
          this.loadFields(this.$route.params.id),
          this.loadIncident(this.$route.params.id),
        ];
        await Promise.all(promises);
        this.freshClone();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Load transmission history data.
     */
    async loadTransmissionHistoryData() {
      try {
        this.loadingTransmissionHistory = true;
        await this.loadTransmissionHistory(this.$route.params.id);
      } finally {
        this.loadingTransmissionHistory = false;
      }
    },

    /**
     * Refresh cloned data.
     */
    freshClone() {
      this.incidentDataClone = _.cloneDeep(this.entity.incident.data);
    },

    /**
     * Recalculate height of window, handled by change page size.
     */
    recalculateHeights() {
      this.windowHeight = window.innerHeight;
    },

    /**
     * Cancel edit handler.
     */
    cancelEdit() {
      if (!this.incidentIsChanged) {
        this.editMode = false;
        return;
      }
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'Your changes can be lost',
        () => {
          this.editMode = false;
          this.freshClone();
        },
      );
    },

    /**
     * Get changed data to save.
     */
    getDataToSave() {
      const data = {};
      _.forEach(this.incidentDataClone, (value, field) => {
        if (typeof value === 'object' && value !== null) {
          return;
        }
        if (this.bothIsEmpty(value, this.entity.incident.data[field])) {
          return;
        }
        if (value !== this.entity.incident.data[field]) {
          data[field] = value === '' ? null : value;
        }
      });
      return data;
    },

    /**
     * Save incident data.
     */
    async saveIncidentData(skip = false) {
      if (this.validationSupports && !this.validated && !skip) {
        this.$refs.preSaveValidationPopup.show();
        return;
      }
      this.$refs.saveReasonPopup.show(async (reason) => {
        try {
          this.saving = true;
          await incidents.saveIncidentData(this.$route.params.id, {
            reason,
            data: this.getDataToSave(),
          });
          this.entity.incident.data = _.cloneDeep(this.incidentDataClone);
          this.setIncident(this.entity.incident);
          this.freshClone();
          this.editMode = false;
          this.reloadIncidentAudits();
        } finally {
          this.saving = false;
        }
      });
    },

    /**
     * Validate incident data.
     */
    async validateIncidentData() {
      try {
        this.validating = true;
        await incidents.validateIncidentData(this.$route.params.id, {
          data: this.getDataToSave(),
        });
        this.$refs.validationPopup.waitValidationResult();
      } finally {
        this.validating = false;
      }
    },

    /**
     * Show page or image, opens view incident popup.
     */
    showImage(id) {
      this.$refs.viewIncidentPopup.show(this.entity.incident, false, true, false, id);
    },

    /**
     * Delete flag comment.
     *
     * @param comment - Flag comment object
     */
    deleteComment(comment) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'Are you sure you want to delete comment?',
        async () => {
          await flaggedUsers.deleteFlagComment(this.entity.incident.id, comment.id);
          this.reloadFlag();
        },
      );
    },
  },
};
</script>

<style lang="scss">
.right-container {
  background-color: #f5f5f6;
  border-left: 1px solid #e1e1e3;
  padding: 20px;
  overflow-y: scroll;
}
</style>
