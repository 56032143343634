import fileDownload from 'js-file-download';
import flaggedUsers from '@/api/flaggedUsers';

import { SET_FLAGGED_USERS_ITEMS } from './mutations';

export const ADMIN_LOAD_FLAGGED_USERS = 'ADMIN_LOAD_FLAGGED_USERS';
export const CLIENT_LOAD_FLAGGED_USERS = 'CLIENT_LOAD_FLAGGED_USERS';
export const ADMIN_EXPORT_FLAGGED_USERS = 'ADMIN_EXPORT_FLAGGED_USERS';
export const CLIENT_EXPORT_FLAGGED_USERS = 'CLIENT_EXPORT_FLAGGED_USERS';
export const ADMIN_RESEND_FLAGGED_USERS = 'ADMIN_RESEND_FLAGGED_USERS';
export const CLIENT_RESEND_FLAGGED_USERS = 'CLIENT_RESEND_FLAGGED_USERS';
export const LOAD_RECIPIENTS = 'LOAD_RECIPIENTS';

export default {
  /**
   * Export flagged users list for admin role.
   *
   * @param {Object} state - Flagged users state.
   *
   * @return {Promise}
   */
  [ADMIN_EXPORT_FLAGGED_USERS]: ({ state }) => {
    const { pagination, filters } = state;
    const params = {
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const result = flaggedUsers.adminExport(params);

    return result.then(response => fileDownload(response.data, 'flagged-users.xlsx'));
  },

  /**
   * Export flagged users list for admin client role.
   *
   * @param {Object} state - Flagged users state.
   *
   * @return {Promise}
   */
  [CLIENT_EXPORT_FLAGGED_USERS]: ({ state }) => {
    const { pagination, filters } = state;
    const params = {
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const result = flaggedUsers.clientExport(params);

    return result.then(response => fileDownload(response.data, 'flagged-users.xlsx'));
  },

  /**
   * Load flagged users list for admin role.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Flagged users state.
   *
   * @return {Promise}
   */
  [ADMIN_LOAD_FLAGGED_USERS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await flaggedUsers.adminFetchList(params);
    commit(SET_FLAGGED_USERS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Load flagged users list for client role.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Flagged users state.
   *
   * @return {Promise}
   */
  [CLIENT_LOAD_FLAGGED_USERS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await flaggedUsers.clientFetchList(params);
    commit(SET_FLAGGED_USERS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },
};
