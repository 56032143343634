<template>
  <transaction-logs-table />
</template>

<script>
import { mapMutations } from 'vuex';
import { CLEAR_STATE } from '@/store/TransactionLogs/mutations';
import { SET_TITLE } from '@/store/mutations';
import TransactionLogsTable from '@/components/Incidents/TransactionLogsTable';

export default {
  components: { TransactionLogsTable },

  created() {
    this.clearState();
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'Transaction Logs');
  },

  methods: {
    ...mapMutations({
      clearState: `transactionLogs/${CLEAR_STATE}`,
    }),
  },
};
</script>
