<template>
  <eq
    :condition="condition"
    :field="field"
    eq-character="≠"
  />
</template>
<script>

import Eq from '@/components/Monitors/Conditions/Eq';

export default {
  name: 'NotEq',
  components: { Eq },
  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
};
</script>
