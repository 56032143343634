const SEARCH_FIELD_TYPES = {
  STRING: 'string',
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  BOOLEAN: 'boolean',
  BLOOD_GLUCOSE: 'blood-glucose',
  JOULES: 'joules',
  CAD_TYPE: 'cad-type',
  PATIENT_CITY_CODE: 'patient-city-code',
};

export default SEARCH_FIELD_TYPES;
