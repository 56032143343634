<template>
  <v-card class="white elevation-3 ma-4">
    <hospitals-tabs />
    <hospital-types />
  </v-card>
</template>

<script>
import HospitalTypes from '@/components/Hospitals/HospitalsTypes';
import HospitalsTabs from '@/components/Hospitals/HospitalsTabs';

export default {
  components: {
    HospitalTypes,
    HospitalsTabs,
  },
};
</script>
