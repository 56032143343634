<template>
  <div>
    <SftpStatus v-if="!showSiteCodeForm"/>
    <Providers
      v-if="!showSiteCodeForm"
      :providers="entity.providers"
      :show-settings="false"
      :show-cloud-watch-url="false"
      :show-latest-cad-data-link="false"
    />
    <SiteCode v-if="showSiteCodeForm"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Providers from '../../components/CadMonitoring/Providers';
import SftpStatus from '../../components/CadMonitoring/SftpStatus';
import SiteCode from '../../components/CadMonitoring/SiteCode';
import { PUBLIC_FETCH_PROVIDERS } from '../../store/CadMonitoring/actions';
import siteCodeMixins from '../../mixins/siteCodeMixins';

const { mapActions, mapState } = createNamespacedHelpers('cadMonitoring');

export default {
  components: {
    SftpStatus,
    Providers,
    SiteCode,
  },
  mixins: [siteCodeMixins],
  data() {
    return {
      showSiteCodeForm: null,
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },
  mounted() {
    this.showSiteCodeForm = !this.sitecode || this.isAdminSiteCode || this.isHospitalSiteCode;
    if (!this.showSiteCodeForm) {
      this.loadItems({
        clientId: this.$store.state.siteInfo.clientId,
      });
    }
  },
  methods: {
    ...mapActions({
      loadItems: PUBLIC_FETCH_PROVIDERS,
    }),
  },
};
</script>

<style lang="scss">
.expansion-panel .card__text {
  padding: 12px 28px;
}
</style>
