<template>
  <div class="cards-container mt-4">
    <user-card
        :loading="loading"
        :data-loaded="dataLoaded"
        :user-name="userData.firstName + ' ' + userData.lastName"
        :license-type="userData.licenseType"
        :license-type-id="userData.licenseTypeId"
        :national-license="userData.nationalLicense"
        :state-license="userData.stateLicense"
        :local-license="userData.localLicense"
        :width="cardsWidth"
    />
    <licensing-period-card
        :loading="loading"
        :data-loaded="dataLoaded"
        :period-from="userData.periodFrom"
        :period-to="userData.periodTo"
        :months-left="userData.monthsLeft"
        :completed="userData.periodId && userData.hours >= userData.goal"
        :width="cardsWidth"
    />
    <compliance-status-card
        :loading="loading"
        :data-loaded="dataLoaded"
        :percentage="compliancePercentage"
        :is-short-of-hours="userData.isShortOfHours"
        :is-current-period="userData.isCurrentPeriod"
        :width="cardsWidth"
    />
    <compliance-skills-status-card
        v-if="userData.skillsGoal"
        :loading="loading"
        :data-loaded="dataLoaded"
        :percentage="skillsCompliancePercentage"
        :is-current-period="userData.isCurrentPeriod"
        :width="cardsWidth"
    />
    <hours-card
        :loading="loading"
        :data-loaded="dataLoaded"
        :required="userData.goal"
        :applied="userData.hours"
        :needed="neededHours"
        :width="cardsWidth"
    />
  </div>
</template>
<script>
import ComplianceSkillsStatusCard from '@/components/CeEntry/Cards/ComplianceSkillsStatusCard';
import HoursCard from './Cards/HoursCard';
import LicensingPeriodCard from './Cards/LicensingPeriodCard';
import ComplianceStatusCard from './Cards/ComplianceStatusCard';
import UserCard from './Cards/UserCard';

export default {
  name: 'WidgetsRow',
  components: {
    ComplianceSkillsStatusCard,
    UserCard,
    ComplianceStatusCard,
    LicensingPeriodCard,
    HoursCard,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    dataLoaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    compliancePercentage() {
      if (!this.userData.periodId) {
        return 0;
      }
      let percentage = (this.userData.hours * 100) / this.userData.goal;
      percentage = percentage > 100 ? 100 : percentage;
      return parseInt(percentage, 10);
    },
    skillsCompliancePercentage() {
      if (!this.userData.periodId) {
        return 0;
      }
      let percentage = (this.userData.skills * 100) / this.userData.skillsGoal;
      percentage = percentage > 100 ? 100 : percentage;
      return parseInt(percentage, 10);
    },
    cardsCount() {
      return this.userData.skillsGoal ? 5 : 4;
    },
    cardsWidth() {
      const smallSize = this.isPrinting ? 250 : 260;
      return this.cardsCount > 4 ? smallSize : 317;
    },
    neededHours() {
      if (this.userData.goal === null || this.userData.hours === null) {
        return null;
      }
      return this.userData.goal - this.userData.hours < 0
        ? 0
        : this.userData.goal - this.userData.hours;
    },
  },
};
</script>
<style>
.cards-container {
  max-width: 1365px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
