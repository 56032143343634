<template>
  <div class="authorization-logs-table">
    <v-data-table
        :headers="header"
        :items="items || []"
        :options.sync="pagination"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        :height="tableHeight"
        hide-default-header
        fixed-header
    >
      <template v-slot:header="{ props: { headers } }">
        <thead class="v-data-table-header">
        <tr>
          <th
              v-for="header in headers"
              :key="header.text"
              :class="[
                  header.sortable ? 'sortable' : '',
                  header.value === pagination.sortBy[0] ? 'active' : '',
                  header.align === 'left' ? 'text-left' : '',
                  pagination.sortDesc[0] ? 'desc': 'asc'
                ]"
              class="field-col text-left"
              :style="header.width ? 'width: ' + header.width : null"
              @click="header.sortable ? changeSort(header.value) : null"
          >
            <span v-html="header.text"></span>
            <v-icon
                v-if="header.sortable"
                class="v-data-table-header__icon"
                small
            >mdi-arrow-up</v-icon>
          </th>
        </tr>
        <tr>
          <th
              v-for="header in headers"
              :key="'search_' + header.value"
              :class="['filter-col', (header.value === 'created' ? 'daterangepicker-th' : '')]"
          >
            <template v-if="header.searchable">
              <v-text-field
                  v-if="header.value !== 'created'"
                  v-model="filters[header.value]"
                  :label="header.text"
                  prepend-inner-icon="search"
                  clearable
                  hide-details
              ></v-text-field>
              <date-range-picker-with-periods
                  v-if="header.value === 'created'"
                  v-model="dateRange"
                  :clearable="true"
                  :label="header.text"
                  style="max-width: 300px"
                  position="right"
                  hide-details
              />
            </template>
          </th>
        </tr>
        </thead>
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | datetimeToClientTimezone }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import fixedHeader from '@/mixins/fixedHeader';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import moment from 'moment';
import auditLog from '@/api/auditLog';

export default {
  name: 'AuthorizationLogsTable',
  components: { DateRangePickerWithPeriods },
  data() {
    return {
      loading: false,
      debounce: null,
      dateRange: null,
      items: [],
      total: 0,
      filters: {
        sequenceNumber: null,
        username: null,
        action: null,
        incidentNumber: null,
        patientLastName: null,
      },
      header: [
        {
          text: 'User',
          value: 'username',
          sortable: true,
          width: '400px',
          searchable: true,
        },
        {
          text: 'Date Time',
          value: 'created',
          sortable: true,
          searchable: true,
        },
      ],
      pagination: {
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: [true],
        page: 1,
        sortBy: ['created'],
        itemsPerPage: 25,
        totalItems: 0,
      },
    };
  },

  mixins: [fixedHeader],

  watch: {
    dateRange(newValue) {
      const filters = _.cloneDeep(this.filters);
      if (newValue && newValue.length) {
        const fromDate = moment(newValue[0]).startOf('day');
        const toDate = moment(newValue[1]).endOf('day');

        filters.rangeFrom = fromDate.utc().format('YYYY-MM-DD HH:mm:ss') || null;
        filters.rangeTo = toDate.utc().format('YYYY-MM-DD HH:mm:ss') || null;
      } else {
        filters.rangeFrom = null;
        filters.rangeTo = null;
      }
      this.filters = filters;
    },
    filters: {
      handler() {
        this.reloadItemsDebounced();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.reloadItemsDebounced();
      },
      deep: true,
    },
  },

  created() {
    this.reloadItems();
    this.recalculateHeights();
  },

  methods: {
    reloadItemsDebounced() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.reloadItems();
        }, 300);
      }
      this.debounce();
    },

    /**
     * Reloads paginated list for current filter.
     */
    async reloadItems() {
      this.loading = true;
      try {
        const params = {
          page: this.pagination.page,
          per_page: this.pagination.itemsPerPage,
          order_by: this.pagination.sortBy[0],
          sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
          ...this.filters,
        };

        const response = await auditLog.fetchAuthorizationLogs(params);
        this.total = response.pagination.total;
        this.items = response.results;
      } finally {
        this.loading = false;
      }
    },
    changeSort(column) {
      const pagination = _.cloneDeep(this.pagination);
      if (pagination.sortBy[0] === column) {
        if (pagination.sortDesc[0] === true) {
          pagination.sortBy = [];
          pagination.sortDesc = [];
        } else {
          pagination.sortDesc = [true];
        }
      } else {
        pagination.sortBy = [column];
        pagination.sortDesc = [false];
      }
      this.pagination = pagination;
    },
  },

};
</script>

<style lang="scss">
.authorization-logs-table {
  .filter-col {
    font-weight: normal;
    padding-bottom: 10px !important;
  }
  .daterangepicker-th {
    z-index: 100 !important;
  }
}
</style>
