import api from './api';

export default {
  /**
   * Returns all first run devices list.
   *
   * @param {Object} params - List of params for request.
   *
   * @return Promise
   */
  fetchList(params) {
    return api()
      .get('/admin/first-run-devices', { params })
      .then(response => response.data);
  },

  /**
   * Delete first run device.
   *
   * @param {number} id - Identifier of first run device.
   */
  delete(id) {
    return api()
      .delete(`/admin/first-run-devices/${id}`)
      .then(response => response.data);
  },
};
