import hospitalsHeaders from '@/data/TableHeaders/hospitalsHeaders';

export default {
  header: 'Hospitals',
  search: 'Search hospitals',
  types: 'Types',
  error: 'Server error',
  tableHeaders: hospitalsHeaders,
  status: 'Status',
  statuses: ['All', 'Active', 'Deleted'],

  deleteConfirmationTitle: 'Delete hospital',
  deleteConfirmationText: 'Do you really want to delete hospital: {name}?',
  restoreConfirmationTitle: 'Restore hospital',
  restoreConfirmationText: 'Do you want to restore hospital: {name}?',

  editPopup: {
    headerEdit: 'Edit Hospital',
    headerNew: 'Add Hospital',
    name: 'Hospital name',
    code: 'Hospital code (3 letters)',
    address: 'Address',
    city: 'City',
    state: 'State',
    zip: 'ZIP',
    latitude: 'Latitude',
    longitude: 'Longitude',
    types: 'Types',
    email: 'Email',
    sendEmail: 'Send Email',
    phone: 'Phone',
    fax: 'Fax (10 digits)',
    userName: 'User Name',
    password: 'Password',
    retypePassword: 'Retype Password',
  },
  tabs: {
    list: 'List',
    types: 'Types',
  },
};
