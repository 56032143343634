<template>
  <v-card
    elevation="3"
    class="ma-4 materials-page"
  >
    <div class="tabs-div">
      <v-tabs v-model="activeTab">
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          exact
          active-class="primary--text"
          class="subheading text-capitalize tab-item"
        >
          {{ $t(`documents.tabs.${tab}`) }}
        </v-tab>
      </v-tabs>
      <v-divider/>
    </div>
    <v-tabs-items :value="activeTab">
      <v-tab-item>
        <documents-tab
          :items="documents.items"
          :pagination="documentsPagination"
          :total-items="documents.totalItems"
          :loading="loading"
          :show-edit-delete="true"
          @edit="openEditDocumentPopup"
          @delete="openDocumentDeletionPopup"
          @download="download"
          @update:pagination="updateDocumentsPagination"
        >
          <v-row
            slot="filters"
            class="pl-4 pr-4"
          >
            <v-col
              v-if="showClientSelection"
              cols="12"
              sm="3"
            >
              <v-autocomplete
                v-model="clientId"
                :items="clients"
                label="Select Client"
                item-value="registryId"
                item-text="name"
                single-line
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <tree-select
                v-model="documentsFilters.category"
                :options="categories"
                :multiple="false"
                :append-to-body="true"
                :placeholder="$t('documents.categories.selectPlaceholder')"
                :searchable="false"
                class="materials_select"
              >
                <div
                  slot="option-label"
                  slot-scope="{ node }"
                >
                  {{ node.raw.name }}
                </div>
                <div
                  slot="value-label"
                  slot-scope="{ node }"
                >
                  {{ node.raw.name }}
                </div>
              </tree-select>
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-text-field
                v-model="documentsFilters.search"
                :label="$t('documents.search')"
                append-icon="search"
                single-line
              />
            </v-col>
            <v-spacer/>
            <v-btn
              :loading="exportLoading"
              color="primary"
              dark
              class="mb-2 mr-2"
              @click.native="exportToExcel"
            >
              {{ $t('buttons.exportXls') }}
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="mb-2 mr-2"
              @click.native="openEditDocumentPopup(null)"
            >
              {{ $t('buttons.add') }}
            </v-btn>
          </v-row>
        </documents-tab>
      </v-tab-item>
      <v-tab-item v-if="showCountyParamedicTab">
        <v-layout
          class="pl-4 pr-4"
          row
          wrap
        >
          <documents-tab
            :items="commonDocuments.items"
            :pagination="commonDocumentsPagination"
            :total-items="commonDocuments.totalItems"
            :loading="loading"
            :show-edit-delete="false"
            @download="download"
            @update:pagination="updateCommonDocumentsPagination"
          >
            <v-layout
              slot="filters"
              class="pl-4 pr-4"
              row
              wrap
            >
              <v-flex
                class="filter"
                xs3
              >
                <tree-select
                  v-model="commonDocumentsFilters.category"
                  :options="commonCategories"
                  :multiple="false"
                  :append-to-body="true"
                  :placeholder="$t('documents.categories.selectPlaceholder')"
                  :searchable="false"
                  class="materials_select"
                >
                  <div
                    slot="option-label"
                    slot-scope="{ node }"
                  >
                    {{ node.raw.name }}
                  </div>
                  <div
                    slot="value-label"
                    slot-scope="{ node }"
                  >
                    {{ node.raw.name }}
                  </div>
                </tree-select>
              </v-flex>
              <v-flex
                xs2
              >
                <v-text-field
                  v-model="commonDocumentsFilters.search"
                  :label="$t('documents.search')"
                  append-icon="search"
                  single-line
                />
              </v-flex>
              <v-spacer/>

              <v-btn
                :loading="exportLoading"
                color="primary"
                dark
                class="mb-2"
                @click.native="exportToExcel"
              >
                {{ $t('buttons.exportXls') }}
              </v-btn>
            </v-layout>
          </documents-tab>
        </v-layout>
      </v-tab-item>
      <v-tab-item>
        <v-layout
          class="pl-9 pr-9"
          row
          wrap
        >
          <v-flex
            v-if="showClientSelection"
            xs3
          >
            <v-select
              v-model="clientId"
              :items="clients"
              label="Select Client"
              item-value="registryId"
              item-text="name"
              single-line
            />
          </v-flex>
          <v-spacer/>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openEditCategoryPopup(null)"
          >
            {{ $t('buttons.add') }}
          </v-btn>
        </v-layout>
        <categories-tab
          :items="categories"
          :loading="loading"
          @edit="openEditCategoryPopup"
          @delete="openCategoryDeletionPopup"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-if="deleteCategoryPopup"
      :value="deleteCategoryPopup"
      max-width="400"
      @input="deleteCategory(false)"
    >
      <v-card>
        <v-card-title class="headline">
          <h3 class="base-dialog__title">
            {{ $t('documents.deletePopup.title', { type: 'category' }) }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-alert
            :value="isDeleteCategoryError"
            type="error"
            outlined
            dismissible
            transition="scale-transition"
          >
            Could not to delete category while it has active documents.
            To view a list of documents click <a @click="showAppliedDocuments"><span>here</span></a>
          </v-alert>
          {{ $t('documents.deletePopup.body', { name: activeCategory.name, type: 'category' }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="info"
            text
            @click.prevent="deleteCategory(false)"
          >
            {{ $t('documents.buttons.close') }}
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="error"
            text
            @click.prevent="deleteCategory(true)"
          >
            {{ $t('documents.buttons.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <document-edit-popup
      :visible="editDocumentPopup"
      :document="activeDocument"
      :categories="categories"
      @close="closeEditDocumentPopup"
    />
    <category-edit-popup
      :visible="editCategoryPopup"
      :category="activeCategory"
      :client-id="clientId"
      :categories="categories"
      @close="closeEditCategoryPopup"
    />
    <confirmation
      ref="deleteConfirmation"
      @confirmed="loadCategories"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TreeSelect from '@riophae/vue-treeselect';
import DocumentsTab from '@/components/Documents/DocumentsTab';
import DocumentEditPopup from '@/components/Documents/DocumentEditPopup';
import CategoryEditPopup from '@/components/Documents/CategoryEditPopup';
import CategoriesTab from '@/components/Documents/CategoriesTab';
import Confirmation from '@/components/Confirmation';
import {
  SET_COMMON_DOCUMENTS_FILTERS,
  SET_COMMON_DOCUMENTS_PAGINATION,
  SET_DOCUMENTS_FILTERS,
  SET_DOCUMENTS_PAGINATION,
} from '@/store/Documents/mutations';
import {
  COMMON_LOAD_DOCUMENTS,
  COMMON_LOAD_CATEGORIES,
  DELETE_CATEGORY,
  DELETE_DOCUMENT,
} from '@/store/Documents/actions';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  'documents',
);
export default {
  components: {
    DocumentsTab,
    CategoriesTab,
    DocumentEditPopup,
    CategoryEditPopup,
    TreeSelect,
    Confirmation,
  },
  props: {
    showExportXls: {
      type: Boolean,
      required: true,
    },
    showClientSelection: {
      type: Boolean,
      required: true,
    },
    needClientId: {
      type: Boolean,
      required: true,
    },
    showCountyParamedicTab: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeTab: null,
      isDeleteCategoryError: false,
      deleteDocumentPopup: false,
      deleteCategoryPopup: false,
      editDocumentPopup: false,
      editCategoryPopup: false,
      tabs: this.showCountyParamedicTab
        ? ['documents', 'countyParamedic', 'categories']
        : ['documents', 'categories'],
      loading: false,
      exportLoading: false,
      clientId: null,
      activeDocument: null,
      activeCategory: null,
    };
  },
  computed: {
    ...mapState({
      documents: state => state.documents,
      commonDocuments: state => state.commonDocuments,
      categories: state => state.categories,
      commonCategories: state => state.commonCategories,
    }),
    clients() {
      const clients = this.$store.state.clientsList.slice(0);
      clients.unshift({
        registryId: null,
        name: 'LA County Paramedic',
      });

      return clients;
    },
    documentsPagination: {
      get() {
        return this.documents.pagination;
      },
      set(value) {
        this.setDocumentsPagination(value);
        this.reloadDocuments();
      },
    },
    commonDocumentsPagination: {
      get() {
        return this.commonDocuments.pagination;
      },
      set(value) {
        this.setCommonDocumentsPagination(value);
        this.reloadCommonDocuments();
      },
    },
    documentsFilters: {
      get() {
        return this.documents.filters;
      },
      set(value) {
        this.setDocumentsFilters(value);
      },
    },
    commonDocumentsFilters: {
      get() {
        return this.commonDocuments.filters;
      },
      set(value) {
        this.setCommonDocumentsFilters(value);
      },
    },
  },
  watch: {
    clientId() {
      this.documentsFilters.category = null;
      this.loadCategories();
      this.reloadDocuments();
    },
    documentsFilters: {
      handler() {
        this.documentsPagination.page = 1;
        this.reloadDocuments();
      },
      deep: true,
    },
    commonDocumentsFilters: {
      handler() {
        this.commonDocumentsPagination.page = 1;
        this.reloadCommonDocuments();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadCategories();
    this.reloadDocuments();

    if (this.showCountyParamedicTab) {
      this.loadCommonDocuments();
      this.loadCommonCategories();
    }

    if (this.needClientId && this.$store.state.user.clientId) {
      this.clientId = this.$store.state.user.clientId;
    }
  },
  methods: {
    ...mapMutations({
      setDocumentsPagination: SET_DOCUMENTS_PAGINATION,
      setDocumentsFilters: SET_DOCUMENTS_FILTERS,
      setCommonDocumentsPagination: SET_COMMON_DOCUMENTS_PAGINATION,
      setCommonDocumentsFilters: SET_COMMON_DOCUMENTS_FILTERS,
    }),
    ...mapActions({
      deleteDocumentAction: DELETE_DOCUMENT,
      deleteCategoryAction: DELETE_CATEGORY,
      loadCommonDocuments: COMMON_LOAD_DOCUMENTS,
      loadCommonCategories: COMMON_LOAD_CATEGORIES,
    }),
    loadCategories() {
      this.$emit('loadCategories', this.clientId);
    },

    loadDocuments() {
      this.$emit('loadDocuments', this.clientId);
    },

    exportDocuments() {
      this.$emit('exportDocuments', this.clientId);
    },

    updateDocumentsPagination(newPagination) {
      this.documentsPagination = newPagination;
    },

    updateCommonDocumentsPagination(newPagination) {
      this.commonDocumentsPagination = newPagination;
    },

    /**
     * Reloads documents paginated list for current client filter
     */
    async reloadDocuments() {
      this.loading = true;
      try {
        await this.loadDocuments();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Reloads documents paginated list for current client filter
     */
    async reloadCommonDocuments() {
      this.loading = true;
      try {
        await this.loadCommonDocuments();
      } finally {
        this.loading = false;
      }
    },

    /**
     * Export documents list for current client filter to excel file.
     */
    async exportToExcel() {
      this.exportLoading = true;
      try {
        await this.exportDocuments();
      } finally {
        this.exportLoading = false;
      }
    },

    /**
     * Opens edit document popup.
     *
     * @param {Object} document - Document to edit
     */
    async openEditDocumentPopup(document) {
      this.activeDocument = document;
      this.editDocumentPopup = true;
    },

    /**
     * Opens edit category popup.
     *
     * @param {Object} category - Category to edit
     */
    openEditCategoryPopup(category) {
      this.activeCategory = category;
      this.editCategoryPopup = true;
    },

    /**
     * Closes deleting document popup.
     *
     * @param {boolean} isChanged - Shows whether document was changed and list should be reloaded
     */
    closeEditDocumentPopup(isChanged) {
      this.editDocumentPopup = false;
      this.activeDocument = null;

      if (isChanged) {
        this.reloadDocuments();
      }
    },

    /**
     * Closes deleting category popup.
     *
     * @param {boolean} isChanged - Shows whether category was changed and list should be reloaded
     */
    closeEditCategoryPopup(isChanged) {
      this.editCategoryPopup = false;
      this.activeCategory = null;

      if (isChanged) {
        this.loadCategories(this.clientId);
      }
    },

    /**
     * Opens document delete confirmation popup.
     *
     * @param {Object} document - Document which should be confirmed
     *
     * @return void
     */
    openDocumentDeletionPopup(document) {
      this.$refs.deleteConfirmation.showConfirm(
        this.$t('documents.deletePopup.title', { type: 'document' }),
        this.$t('documents.deletePopup.body', {
          name: document.name,
          type: 'document',
        }),
        async () => {
          await this.deleteDocumentAction(document);
          this.reloadDocuments();
        },
      );
    },

    /**
     * Opens category delete confirmation popup.
     *
     * @param {Object} category - Category which should be confirmed
     *
     * @return void
     */
    openCategoryDeletionPopup(category) {
      this.isDeleteCategoryError = false;
      this.activeCategory = category;
      this.deleteCategoryPopup = true;
    },

    /**
     * Deletes active category in case of success user interaction category.
     *
     * @param {boolean} success - Result of user decision about deleting or not
     *
     * @return {Promise<void>}
     */
    async deleteCategory(success) {
      this.isDeleteCategoryError = false;
      if (success) {
        this.loading = true;
        try {
          await this.deleteCategoryAction(this.activeCategory);
          this.loadCategories(this.clientId);
          this.activeCategory = null;
          this.deleteCategoryPopup = false;
        } catch (e) {
          this.isDeleteCategoryError = true;
        } finally {
          this.loading = false;
        }
      } else {
        this.activeCategory = null;
        this.deleteCategoryPopup = false;
      }
    },

    /**
     * Opens given document's file in new window to next downloading.
     *
     * @param {Object} document - Document which file needs to download
     * @param {string} document.fileUrl - File url to download
     */
    download({ fileUrl }) {
      window.open(fileUrl, '_blank');
    },

    /**
     * Navigates to documents tab with filtered documents by active category.
     */
    showAppliedDocuments() {
      this.documentsFilters.category = this.activeCategory.categoryId;
      this.activeCategory = null;
      this.deleteCategoryPopup = false;
      this.activeTab = 0;
    },
  },
};
</script>

<style lang="scss">
.long-beach-tabs div[class='datatable__actions'] {
  float: right;
}

.tabs-div {
  padding-bottom: 20px;
}

.tab-item {
  min-width: 130px;
}

.filter {
  margin-right: 8%;
}

.materials_select {
  margin-top: 14px;
}

.vue-treeselect {
  &__control {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom-color: rgba(0, 0, 0, 0.42);
    cursor: pointer;
  }

  &__value-container {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__control-arrow {
    fill: rgba(0, 0, 0, 0.54);
  }

  &__menu {
    padding-bottom: 0;
    border-radius: 0;
  }

  &__option {
    height: 40px;
  }

  &__label-container {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    position: relative;
    top: 7px;
    padding-left: 4px;
  }
}

.vue-treeselect--single .vue-treeselect__option--selected {
  font-weight: 400;
  color: #e04337;
  background-color: #eee;

  &:hover {
    background-color: #eee;
  }
}

.materials-page .v-select {
  &__slot {
    overflow: hidden;
  }

  &__selection {
    white-space: nowrap;
    overflow: hidden;
  }
}

.vue-treeselect--focused .vue-treeselect__control {
  box-shadow: none !important;
  outline: none !important;
  border-bottom-color: #e04337 !important;
  border-bottom-width: 2px;
}
</style>
