<template>
  <span>
    <template v-if="hasBothRanges">( </template>
    <template v-if="fromValue !== null">
      {{ field.name || condition.field }}
      {{ fromCharacter }}
      "<b>{{ fromValue }}</b>"
    </template>
    <template v-if="hasBothRanges"> AND </template>
    <template v-if="toValue !== null">
      {{ field.name || condition.field }}
      {{ toCharacter }}
      "<b>{{ toValue }}</b>"
    </template>
    Kg
    <template v-if="hasBothRanges"> )</template>
  </span>
</template>
<script>

import RANGE_OPERATORS from '@/enums/rangeOperators';

export default {
  name: 'Weight',

  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fromValue() {
      let value = null;
      if (this.condition[RANGE_OPERATORS.GT]) {
        value = this.condition[RANGE_OPERATORS.GT];
      } else if (this.condition[RANGE_OPERATORS.GTE]) {
        value = this.condition[RANGE_OPERATORS.GTE];
      }
      return value;
    },
    toValue() {
      let value = null;
      if (this.condition[RANGE_OPERATORS.LT]) {
        value = this.condition[RANGE_OPERATORS.LT];
      } else if (this.condition[RANGE_OPERATORS.LTE]) {
        value = this.condition[RANGE_OPERATORS.LTE];
      }
      return value;
    },

    fromCharacter() {
      let value = null;
      if (this.condition[RANGE_OPERATORS.GT]) {
        value = '>';
      } else if (this.condition[RANGE_OPERATORS.GTE]) {
        value = '≥';
      }
      return value;
    },
    toCharacter() {
      let value = null;
      if (this.condition[RANGE_OPERATORS.LT]) {
        value = '<';
      } else if (this.condition[RANGE_OPERATORS.LTE]) {
        value = '≤';
      }
      return value;
    },
    hasBothRanges() {
      return this.fromValue !== null && this.toValue !== null;
    },
  },
};
</script>
