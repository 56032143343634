<template>
  <v-card
    elevation="3"
    class="ma-4 flagged-users"
  >
    <flagged-users-toolbar
      :filters="filters"
      :show-clients-filter="false"
      :show-buttons="!!entity.items.length"
      :export-loading="exportLoading"
      @filtersUpdate="filtersUpdate"
      @exportToExcel="exportToExcel"
      @resendAll="resendAll"
    ></flagged-users-toolbar>
    <flagged-users-table
      :items="entity.items"
      :pagination="pagination"
      :total-items="entity.totalItems"
      :loading="loading"
      :use-impersonate="false"
      @resendFlag="resendFlag"
      @update:pagination="updatePagination"
    ></flagged-users-table>
    <confirmation ref="confirmationPopup"></confirmation>
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import FlaggedUsersTable from '@/components/FlaggedUsers/FlaggedUsersTable';
import FlaggedUsersToolbar from '@/components/FlaggedUsers/FlaggedUsersToolbar';
import Confirmation from '@/components/Confirmation';
import {
  CLIENT_LOAD_FLAGGED_USERS,
  CLIENT_EXPORT_FLAGGED_USERS,
} from '@/store/FlaggedUsers/actions';
import {
  SET_FLAGGED_USERS_FILTERS,
  SET_FLAGGED_USERS_PAGINATION,
} from '@/store/FlaggedUsers/mutations';
import filtersAndTablesGeneralMixins from '@/mixins/filtersAndTablesGeneralMixins';
import flaggedUsersApi from '@/api/flaggedUsers';
import { SHOW_SNACKBAR } from '@/store/actions';

const { mapState, mapMutations } = createNamespacedHelpers('flaggedUsers');

export default {
  components: {
    FlaggedUsersTable,
    FlaggedUsersToolbar,
    Confirmation,
  },
  mixins: [filtersAndTablesGeneralMixins],

  data() {
    return {
      exportLoading: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
  },

  methods: {
    ...mapMutations({
      setPagination: SET_FLAGGED_USERS_PAGINATION,
      setFilters: SET_FLAGGED_USERS_FILTERS,
    }),
    ...mapActions({
      loadItems: `flaggedUsers/${CLIENT_LOAD_FLAGGED_USERS}`,
      exportFlaggedUsers: `flaggedUsers/${CLIENT_EXPORT_FLAGGED_USERS}`,
      showSnackbar: SHOW_SNACKBAR,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Export flagged users by current filter and sort order to excel file.
     */
    async exportToExcel() {
      this.exportLoading = true;
      try {
        await this.exportFlaggedUsers();
      } finally {
        this.exportLoading = false;
      }
    },

    /**
     * Resend flag to flagged user.
     *
     * @param {Object} flaggedUser - Flagged user object.
     */
    resendFlag(flaggedUser) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('flaggedUsers.resendFlagConfirmationTitle'),
        this.$t('flaggedUsers.resendFlagConfirmationText', flaggedUser),
        async () => {
          await flaggedUsersApi.resendFlag(flaggedUser.alertId);
          this.showSnackbar(this.$t('flaggedUsers.flagSent'));
        },
      );
    },

    /**
     * Resend all pending flag to users.
     */
    resendAll() {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('flaggedUsers.resendFlagsConfirmationTitle'),
        this.$t('flaggedUsers.resendFlagsConfirmationText'),
        async () => {
          await flaggedUsersApi.clientResendAll(this.filters);
          this.showSnackbar(this.$t('flaggedUsers.allFlagsSent'));
        },
      );
    },
  },
};
</script>
