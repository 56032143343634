import fileDownload from 'js-file-download';
import documentsApi from '@/api/documents';

import {
  SET_DOCUMENTS,
  SET_CATEGORIES,
  SET_COMMON_DOCUMENTS,
  SET_COMMON_CATEGORIES,
} from './mutations';

export const ADMIN_LOAD_DOCUMENTS = 'ADMIN_LOAD_DOCUMENTS';
export const ADMIN_EXPORT_DOCUMENTS = 'ADMIN_EXPORT_DOCUMENTS';
export const ADMIN_LOAD_CATEGORIES = 'ADMIN_LOAD_CATEGORIES';
export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const LOAD_DOCUMENT = 'LOAD_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const CLIENT_LOAD_CATEGORIES = 'CLIENT_LOAD_CATEGORIES';
export const CLIENT_LOAD_DOCUMENTS = 'CLIENT_LOAD_DOCUMENTS';
export const CLIENT_EXPORT_DOCUMENTS = 'CLIENT_EXPORT_DOCUMENTS';
export const COMMON_LOAD_DOCUMENTS = 'COMMON_LOAD_DOCUMENTS';
export const COMMON_LOAD_CATEGORIES = 'COMMON_LOAD_CATEGORIES';

export default {
  /**
   * Load documents.
   *
   * @param {Function} commit - State commit.
   * @param {Object} commit - Documents state.
   * @param {number} clientId - Client identifier to load categories
   *
   * @return Promise<Object>
   */
  [ADMIN_LOAD_DOCUMENTS]: ({ commit, state }, clientId) => {
    const { pagination, filters } = state.documents;
    const { search, category } = filters;

    const params = {
      clientId,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      search,
      category,
    };

    let result = null;

    if (!clientId) {
      result = documentsApi.fetchCommonList(params);
    } else {
      result = documentsApi.adminFetchList(params);
    }

    return result.then(results => {
      commit(SET_DOCUMENTS, {
        items: results.results,
        totalItems: results.pagination.total,
      });

      if (!clientId) {
        commit(SET_COMMON_DOCUMENTS, {
          items: results.results,
          totalItems: results.pagination.total,
        });
      }

      return results;
    });
  },

  /**
   * Load documents.
   *
   * @param {Function} commit - State commit.
   * @param {Object} commit - Documents state.
   * @param {number} clientId - Client identifier to load categories
   *
   * @return Promise<Object>
   */
  [COMMON_LOAD_DOCUMENTS]: ({ commit, state }) => {
    const { pagination, filters } = state.commonDocuments;
    const { search, category } = filters;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      search,
      category,
    };

    const result = documentsApi.fetchCommonList(params);

    return result.then(results => {
      commit(SET_COMMON_DOCUMENTS, {
        items: results.results,
        totalItems: results.pagination.total,
      });

      return results;
    });
  },

  /**
   * Load client documents.
   *
   * @param {Function} commit - State commit.
   * @param {Object} commit - Documents state.
   *
   * @return Promise<Object>
   */
  [CLIENT_LOAD_DOCUMENTS]: ({ commit, state }) => {
    const { pagination, filters } = state.documents;
    const { search, category } = filters;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      search,
      category,
    };

    const result = documentsApi.clientFetchList(params);

    return result.then(results => {
      commit(SET_DOCUMENTS, {
        items: results.results,
        totalItems: results.pagination.total,
      });

      return results;
    });
  },

  /**
   * Load documents.
   *
   * @param {Function} commit - State commit.
   * @param {Object} commit - Documents state.
   * @param {number} clientId - Client identifier to load categories
   *
   * @return Promise<Object>
   */
  [ADMIN_EXPORT_DOCUMENTS]: ({ state }, clientId) => {
    const { pagination, filters } = state.documents;
    const { search, category } = filters;
    const params = {
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      search,
      category,
    };
    let result;

    if (clientId) {
      params.clientId = clientId;
      result = documentsApi.adminExportDocuments(params);
    } else {
      result = documentsApi.commonExportDocuments(params);
    }

    return result.then(response => fileDownload(response.data, 'documents.xlsx'));
  },

  /**
   * Export client documents.
   *
   * @param {Object} commit - Documents state.
   *
   * @return Promise<Object>
   */
  [CLIENT_EXPORT_DOCUMENTS]: ({ state }) => {
    const { pagination, filters } = state.documents;
    const { search, category } = filters;
    const params = {
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      search,
      category,
    };

    const result = documentsApi.clientExportDocuments(params);

    return result.then(response => fileDownload(response.data, 'documents.xlsx'));
  },

  /**
   * Load documents' categories tree.
   *
   * @param {Function} commit - State commit.
   * @param {number} clientId - Client identifier to load categories
   *
   * @return Promise<Array>
   */
  [ADMIN_LOAD_CATEGORIES]: ({ commit }, clientId) => {
    let result = null;

    if (!clientId) {
      result = documentsApi.commonCategories();
    } else {
      result = documentsApi.adminCategories({ clientId });
    }

    return result.then(results => {
      commit(SET_CATEGORIES, results.results);

      return results.results;
    });
  },

  /**
   * Load common documents' categories tree.
   *
   * @param {Function} commit - State commit.
   * @param {number} clientId - Client identifier to load categories
   *
   * @return Promise<Array>
   */
  [COMMON_LOAD_CATEGORIES]: ({ commit }) => {
    const result = documentsApi.commonCategories();

    return result.then(results => {
      commit(SET_COMMON_CATEGORIES, results.results);

      return results.results;
    });
  },
  /**
   * Load client documents' categories tree.
   *
   * @param {Function} commit - State commit.
   *
   * @return Promise<Array>
   */
  [CLIENT_LOAD_CATEGORIES]: ({ commit }) => {
    const result = documentsApi.clientCategories();

    return result.then(results => {
      commit(SET_CATEGORIES, results.results);

      return results.results;
    });
  },

  /**
   * Creates document.
   *
   * @param {Object} document - Document to create.
   *
   * @return Promise<Array>
   */
  // eslint-disable-next-line no-empty-pattern
  [CREATE_DOCUMENT]: ({}, document) => documentsApi.create(document),

  /**
   * Saves document.
   *
   * @param {Object} document - Document to save.
   *
   * @return Promise<Array>
   */
  // eslint-disable-next-line no-empty-pattern
  [SAVE_DOCUMENT]: ({}, document) => documentsApi.update(document.registryId, document),

  /**
   * Load document by id.
   *
   * @param {Number} document - Document identifier to load
   *
   * @return Promise<Array>
   */
  // eslint-disable-next-line no-empty-pattern
  [LOAD_DOCUMENT]: ({}, id) => documentsApi.load(id),

  /**
   * Deletes document.
   *
   * @param {Object} document - Document to delete.
   *
   * @return Promise<Object>
   */
  // eslint-disable-next-line no-empty-pattern
  [DELETE_DOCUMENT]: ({}, document) => documentsApi.delete(document.registryId),

  /**
   * Creates category.
   *
   * @param {Object} category - Category to create.
   *
   * @return Promise<Object>
   */
  // eslint-disable-next-line no-empty-pattern
  [CREATE_CATEGORY]: ({}, category) => documentsApi.createCategory(category),

  /**
   * Saves category.
   *
   * @param {Object} category - Category to save.
   *
   * @return Promise<Object>
   */
  // eslint-disable-next-line no-empty-pattern
  [SAVE_CATEGORY]: ({}, category) => documentsApi.updateCategory(category.categoryId, category),

  /**
   * Deletes category.
   *
   * @param {Object} document - Category to delete.
   *
   * @return Promise<Object>
   */
  // eslint-disable-next-line no-empty-pattern
  [DELETE_CATEGORY]: ({}, category) => documentsApi.deleteCategory(category.categoryId),
};
