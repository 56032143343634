<template>
  <div class="search-advanced">
    <v-divider />
    <v-container fluid>
      <v-toolbar flat>
        <v-subheader class="pl-0">
          <span class="title">Advanced Search</span>
        </v-subheader>
        <v-spacer/>
        <v-btn
            v-if="showSaveMonitorBtn"
            :disabled="!totalAppliedFilters"
            class="mr-5"
            @click="$emit('save-monitor', filters)"
        >Save Monitor</v-btn>
        <v-btn
          v-if="showCreateMonitorBtn"
          :disabled="!totalAppliedFilters"
          class="mr-5"
          @click="$emit('create-monitor', filters)"
        >Create Monitor</v-btn>
        <preset-manager
            ref="presetManager"
            title="Search Preset"
            :type="presetTypes.SEARCH"
            :presets="entity.searchPresets || []"
            :data="filters"
            :blank-data="[]"
            :loading="loading"
            :pre-selected="preSelectedPreset"
            @selected="setConditions"
            @deleted="deletePresetHandler"
            @updated="updatePresetHandler"
            @added="addPresetHandler"
        />
      </v-toolbar>

      <v-card
          v-for="(group, index) in groups"
          :key="'group_' + index"
          class="mb-5 group-container"
          outlined
          :rounded="false"
          tile
      >
        <v-toolbar
          flat
          dense
          class="group-toolbar"
        >
          <v-btn-toggle
              v-model="group.operator"
              mandatory
              class="operator-select"
          >
            <v-btn
                value="and"
                small
                style="min-width: 100px"
            >
              And
            </v-btn>
            <v-btn
                value="or"
                small
                style="min-width: 100px"
            >
              Or
            </v-btn>
            <v-btn
                value="not"
                small
                style="min-width: 100px"
            >
              Not
            </v-btn>
          </v-btn-toggle>
          <v-btn
            v-if="groups.length > 1"
            icon
            class="mr-2"
            dark
            small
            @click="deleteGroup(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container
            v-if="!group.conditions.length"
            class="mt-0 text-center"
        >
          You do not have any search fields. Please add some fields to perform an advanced query.
        </v-container>
        <div
            v-else
            class="pb-0"
        >
          <v-simple-table class="conditions-table">
            <template v-slot:default>
              <thead>
              <tr>
                <th
                    class="text-left"
                    style="width: 200px;"
                >
                  Field
                </th>
                <th
                    class="text-left"
                    style="width: 200px;"
                >
                  Condition
                </th>
                <th class="text-left">
                  Criteria
                </th>
                <th style="width: 125px;">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, i) in group.conditions"
                  :key="'condition_' + i"
              >
                <td>{{ item.field.name }}</td>
                <td>
                  <v-select
                      v-if="item.field.allowedFilters.length > 1
                  ||(item.field.allowedFilters.length === 1
                    && searchCriteria[item.field.allowedFilters[0]] !== item.field.name
                  )"
                      v-model="item.condition.type"
                      :items="getCriteriaSelectItems(item.field.allowedFilters)"
                      :disabled="item.field.allowedFilters.length === 1"
                      dense
                      hide-details
                      @change="changeType(index, i)"
                  />
                </td>
                <td>
                  <div v-if="componentExists(item.condition.type)">
                    <component
                        v-bind:is="getComponentName(item.condition.type)"
                        :field="item.field"
                        v-bind:condition.sync="item.condition"
                    ></component>
                  </div>
                </td>
                <td class="text-right">
                  <v-btn
                      icon
                      @click="duplicateCondition(group, i)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      @click="deleteCondition(index, group, i)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-card-actions>
          <v-btn
              text
              small
              color="primary"
              @click="showAddFields(index)"
          >
            <v-icon>mdi-plus</v-icon> Add Fields
          </v-btn>
          <v-spacer />
          <v-btn
              v-if="showFindDuplicationsBtn"
              text
              small
              color="blue"
              :disabled="findDuplicateConditionsDisabled"
              title="Available only if"
              @click="showAddDuplicateConditions(index)"
          >
            <v-icon>mdi-plus</v-icon> Add Duplicate Conditions
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="separator">
        <v-btn
            text
            small
            color="blue"
            @click="addGroup"
        >
          <v-icon>mdi-plus</v-icon> Add Condition Group
        </v-btn>
      </div>
    </v-container>

    <confirmation ref="confirmationPopup"/>
    <add-search-fields
      ref="addSearchFields"
      :all-fields="entity.searchFields || []"
      @add-fields="addFields"
    />
    <find-duplicated-patients
      ref="findDuplicatedPatients"
      @apply-conditions="applyFindDuplicatesConditions"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import {
  SET_SEARCH_PRESET,
  SET_ADV_SEARCH_GROUPS,
  SET_ADV_SEARCH_PRESET,
  SET_BASIC_SEARCH_FILTERS,
} from '@/store/Incidents/mutations';
import Confirmation from '@/components/Confirmation';
import AddSearchFields from '@/components/Incidents/AddSearchFields';
import SEARCH_CRITERIA from '@/enums/searchCriteria';
import SEARCH_CRITERIA_TYPES from '@/enums/searchCriteriaTypes';
import PRESET_TYPES from '@/enums/presetTypes';
import EqCriterion from '@/components/Incidents/Criteria/EqCriterion';
import LikeCriterion from '@/components/Incidents/Criteria/LikeCriterion';
import RangeCriterion from '@/components/Incidents/Criteria/RangeCriterion';
import AgeCriterion from '@/components/Incidents/Criteria/AgeCriterion';
import ImageAttached from '@/components/Incidents/Criteria/ImageAttached';
import HasSignature from '@/components/Incidents/Criteria/HasSignature';
import InsightsByUser from '@/components/Incidents/Criteria/InsightsByUser';
import InsightsVitals from '@/components/Incidents/Criteria/InsightsVitals';
import InsightsByProviderImpression from '@/components/Incidents/Criteria/InsightsByProviderImpression';
import PresetManager from '@/components/Incidents/Presets/PresetManager';
import FindDuplicatedPatients from '@/components/Incidents/FindDuplicatedPatients';

const { mapState, mapMutations } = createNamespacedHelpers('incidents');

export default {
  name: 'SearchAdvanced',
  /* eslint-disable vue/no-unused-components */
  components: {
    FindDuplicatedPatients,
    PresetManager,
    AddSearchFields,
    Confirmation,
    eq_criterion: EqCriterion,
    not_eq_criterion: EqCriterion,
    like_criterion: LikeCriterion,
    range_criterion: RangeCriterion,
    age_criterion: AgeCriterion,
    weight_criterion: RangeCriterion,
    image_attached_criterion: ImageAttached,
    has_signature_criterion: HasSignature,
    insights_therapy_criterion: InsightsByUser,
    insights_providerImpression_criterion: InsightsByUser,
    insights_advancedAirways_criterion: InsightsByUser,
    insights_meds_criterion: InsightsByUser,
    insights_vitals_criterion: InsightsVitals,
    insights_transport_criterion: InsightsByUser,
    insights_transportByProviderImpressions_criterion: InsightsByProviderImpression,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSaveMonitorBtn: {
      type: Boolean,
      default: false,
    },
    showCreateMonitorBtn: {
      type: Boolean,
      default: false,
    },
    showFindDuplicationsBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groups: [],
      searchCriteria: SEARCH_CRITERIA,
      presetTypes: PRESET_TYPES,
      blankConditions: {
        eq: {
          type: SEARCH_CRITERIA_TYPES.EQ,
          value: null,
        },
        not_eq: {
          type: SEARCH_CRITERIA_TYPES.NOT_EQ,
          value: null,
        },
        range: {
          type: SEARCH_CRITERIA_TYPES.RANGE,
        },
        age: {
          type: SEARCH_CRITERIA_TYPES.AGE,
          unit: 'Y',
        },
        like: {
          type: SEARCH_CRITERIA_TYPES.LIKE,
          value: null,
        },
        is_null: {
          type: SEARCH_CRITERIA_TYPES.IS_NULL,
        },
        image_attached: {
          type: SEARCH_CRITERIA_TYPES.IMAGE_ATTACHED,
          isAttached: true,
        },
        has_signature: {
          type: SEARCH_CRITERIA_TYPES.HAS_SIGNATURE,
          signed: true,
        },
        insights_therapy: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_THERAPY,
          insight: null,
          focus: null,
        },
        insights_providerImpression: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_PROVIDER_IMPRESSION,
          insight: null,
          focus: null,
        },
        insights_advancedAirways: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_ADVANCED_AIRWAYS,
          insight: null,
          focus: null,
        },
        insights_meds: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_MEDS,
          insight: null,
          focus: null,
        },
        insights_vitals: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_VITALS,
          insight: null,
          focus: null,
        },
        insights_transport: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_TRANSPORT,
          insight: null,
          focus: null,
        },
        insights_transportByProviderImpressions: {
          type: SEARCH_CRITERIA_TYPES.INSIGHTS_TRANSPORT_BY_PROVIDER_IMPRESSIONS,
          insight: null,
          focus: null,
        },
      },
    };
  },
  computed: {
    ...mapState({
      entity: state => state,
    }),
    preSelectedPreset: {
      get() {
        return this.entity.advSearchPreset;
      },
      set(value) {
        this.setAdvSearchPreset(value);
      },
    },
    searchFields() {
      const fields = {};
      const allFields = this.entity.searchFields || [];
      allFields.forEach(category => {
        category.currentFields.forEach(fieldItem => {
          fields[fieldItem.field] = fieldItem;
        });
        category.historicalFields.forEach(fieldItem => {
          fields[fieldItem.field] = fieldItem;
        });
      });
      return fields;
    },
    filters() {
      const filters = [];
      this.groups.forEach(group => {
        const conditions = [];
        const valueTypes = [
          SEARCH_CRITERIA_TYPES.EQ,
          SEARCH_CRITERIA_TYPES.NOT_EQ,
          SEARCH_CRITERIA_TYPES.LIKE,
        ];
        const rangeTypes = [
          SEARCH_CRITERIA_TYPES.RANGE,
          SEARCH_CRITERIA_TYPES.AGE,
          SEARCH_CRITERIA_TYPES.WEIGHT,
        ];

        const insightTypes = [
          SEARCH_CRITERIA_TYPES.INSIGHTS_THERAPY,
          SEARCH_CRITERIA_TYPES.INSIGHTS_PROVIDER_IMPRESSION,
          SEARCH_CRITERIA_TYPES.INSIGHTS_ADVANCED_AIRWAYS,
          SEARCH_CRITERIA_TYPES.INSIGHTS_MEDS,
          SEARCH_CRITERIA_TYPES.INSIGHTS_VITALS,
          SEARCH_CRITERIA_TYPES.INSIGHTS_TRANSPORT,
          SEARCH_CRITERIA_TYPES.INSIGHTS_TRANSPORT_BY_PROVIDER_IMPRESSIONS,
        ];

        group.conditions.forEach(item => {
          const {
            type, value, gt, gte, lt, lte, focus,
          } = item.condition;
          if (valueTypes.includes(type)
              && (value === null || value === undefined || value === '')
          ) {
            return;
          }
          if (rangeTypes.includes(type)
              && (gt === undefined
                  && gte === undefined
                  && lt === undefined
                  && lte === undefined
              )
          ) {
            return;
          }
          if (insightTypes.includes(type) && !focus) {
            return;
          }
          conditions.push(item.condition);
        });
        if (conditions.length) {
          filters.push({
            operator: group.operator,
            conditions,
          });
        }
      });
      return filters;
    },
    totalAppliedFilters() {
      let count = 0;
      this.filters.forEach(group => {
        count += group.conditions.length;
      });
      return count;
    },

    /**
     * Is added any conditions to any groups.
     *
     * @return Boolean
     */
    hasAddedConditions() {
      let hasAddedConditions = false;
      this.groups.forEach(group => {
        if (group.conditions.length) {
          hasAddedConditions = true;
        }
      });
      return hasAddedConditions;
    },

    /**
     * Is added any conditions to any groups.
     *
     * @return Boolean
     */
    findDuplicateConditionsDisabled() {
      const basicFilters = this.entity.basicSearchFilters[this.type];
      return !!(this.hasAddedConditions
          || basicFilters.dateRange
          || basicFilters.sequenceNumbers
          || basicFilters.incidentNumbers);
    },
  },
  mounted() {
    this.groups = this.entity.advSearchGroups;
    if (!this.groups.length) {
      this.addGroup();
    }
  },
  watch: {
    groups(value) {
      this.setAdvSearchGroups(value);
    },
  },
  methods: {
    ...mapMutations({
      setBasicSearchFilters: SET_BASIC_SEARCH_FILTERS,
      setSearchPreset: SET_SEARCH_PRESET,
      setAdvSearchGroups: SET_ADV_SEARCH_GROUPS,
      setAdvSearchPreset: SET_ADV_SEARCH_PRESET,
    }),
    search() {
      this.$emit('search', this.filters);
    },
    getCriteriaSelectItems(allowedFilters) {
      return allowedFilters.map(criterion => ({
        value: criterion,
        text: SEARCH_CRITERIA[criterion],
      }));
    },
    addGroup() {
      this.groups.push({
        operator: 'and',
        conditions: [],
      });
    },
    deleteGroup(index) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'Do you really want to delete this search group? This action cannot be undone.',
        () => {
          this.groups.splice(index, 1);
        },
      );
    },
    deleteCondition(groupIndex, group, conditionIndex) {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'Do you really want to delete this search field? This action cannot be undone.',
        () => {
          group.conditions.splice(conditionIndex, 1);
        },
      );
    },
    duplicateCondition(group, conditionIndex) {
      group.conditions.splice(conditionIndex + 1, 0, _.cloneDeep(group.conditions[conditionIndex]));
    },
    setConditions(data) {
      if (data && !_.isEmpty(data)) {
        this.groups = [];
        data.forEach(groupItem => {
          const conditions = groupItem.conditions.map(conditionItem => ({
            condition: _.cloneDeep(conditionItem),
            field: this.searchFields[conditionItem.field],
          }));
          this.groups.push({
            operator: groupItem.operator,
            conditions,
          });
        });
        this.preSelectedPreset = this.$refs.presetManager.selectedPreset
          ? this.$refs.presetManager.selectedPreset.id
          : null;
      } else {
        this.groups = [];
        this.preSelectedPreset = null;
        this.addGroup();
      }
      this.search();
    },
    reset() {
      this.groups = [];
      this.addGroup();
      this.search();
      this.$refs.presetManager.reset();
    },

    /**
     * Show Add Fields Popup.
     *
     * @param {Number} index Index of search group
     */
    async showAddFields(index) {
      this.$refs.addSearchFields.show(index);
    },

    /**
     * Show Add Duplicate Popup.
     *
     * @param {Number} index Index of search group
     */
    showAddDuplicateConditions(index) {
      this.$refs.findDuplicatedPatients.show(index);
    },

    /**
     * Add list of fields to search group.
     *
     * @param {Array} fields List of fields
     * @param {Number} groupId Identifier of group
     */
    addFields(fields, groupId) {
      fields.forEach(field => {
        this.groups[groupId].conditions.push({
          condition: this.getConditionObject(field, this.searchFields[field].allowedFilters[0]),
          field: this.searchFields[field],
        });
      });
    },

    /**
     * Add conditions to search group by list of field values.
     *
     * @param {Object} fieldValues List of field values
     * @param {Number} groupId Index of search group
     * @param {Array} dateRange DateRange for apply to basic search
     */
    applyFindDuplicatesConditions(fieldValues, groupId, dateRange) {
      _.forEach(fieldValues, (value, field) => {
        const condition = this.getConditionObject(
          field,
          this.searchFields[field].allowedFilters[0],
        );
        condition.value = value;
        this.groups[groupId].conditions.push({
          condition,
          field: this.searchFields[field],
        });
      });
      const basicFilters = this.entity.basicSearchFilters[this.type];
      basicFilters.dateRange = dateRange;
      this.setBasicSearchFilters(this.type, basicFilters);
      this.search();
    },

    /**
     * Change condition type handler.
     *
     * @param {Number} groupId Index of search group
     * @param {Number} conditionId Condition index
     */
    changeType(groupId, conditionId) {
      const { type, field } = this.groups[groupId].conditions[conditionId].condition;
      this.groups[groupId].conditions[conditionId].condition = this.getConditionObject(field, type);
    },

    /**
     * Get condition object.
     *
     * @param {String} field Name of field
     * @param {String} type Type of condition
     */
    getConditionObject(field, type) {
      if (!Object.prototype.hasOwnProperty.call(this.blankConditions, type)) {
        return {
          field,
          type,
        };
      }
      const condition = _.cloneDeep(this.blankConditions[type]);
      condition.field = field;
      return condition;
    },
    getComponentName(type) {
      return `${type}_criterion`;
    },
    componentExists(type) {
      return Object.prototype.hasOwnProperty.call(
        this.$options.components,
        this.getComponentName(type),
      );
    },
    addPresetHandler(preset) {
      const presets = this.entity.searchPresets;
      presets.push(preset);
      this.setSearchPreset(presets);
    },
    deletePresetHandler(index) {
      const presets = _.clone(this.entity.searchPresets);
      presets.splice(index, 1);
      this.setSearchPreset(presets);
    },
    updatePresetHandler(index, preset) {
      const presets = _.clone(this.entity.searchPresets);
      presets[index] = preset;
      this.setSearchPreset(presets);
    },
  },
};
</script>
<style lang="scss">
.search-advanced {
  .operator-select-container {
  }
  .operator-select {
    margin: 0 auto;
  }
  .group-container {
    background-color: #f5f5f6 !important;
  }
  .group-toolbar {
    background-color: #515151 !important;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #a4a4a4;
  }

  .separator:not(:empty)::before {
    margin-right: .5em;
  }

  .separator:not(:empty)::after {
    margin-left: .5em;
  }
  .conditions-table {
    background-color: #f5f5f6 !important;
    th {
      height: 50px;
    }
    td {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}
</style>
