<template>
  <div :class="$style.host">
    <errors-utility-toolbar
      @applyFilter="applyFilter($event)"
    ></errors-utility-toolbar>

    <v-data-table
      ref="dataTable"
      :headers="headers"
      :items="items"
      :expanded.sync="expandedRows"
      :loading="loading"
      :height="tableHeight"
      :class="[$style.table, 'elevation-1']"
      item-key="id"
      fixed-header
      disable-pagination
      hide-default-footer
      disable-sort
      @click:row="toggleExpandRow"
    >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr @click="expand(!isExpanded)">
          <td>{{item.time | datetimeToClientTimezone}}</td>
          <td>{{item.title | trim}}</td>
          <td>
            <div
              v-if="hasPayloadInfo(item.payload)"
              :class="$style.infoCell"
            >
              {{item.payload.info | trim}}
            </div>
            <div
              v-else
              :class="$style.infoCell"
            >
              {{item.rawMessage}}
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <tr>
          <td
            class="pt-1"
            :colspan="headers.length"
          >
            <code
              v-if="hasPayloadInfo(item.payload)"
              :class="$style.expandLog"
            >
              <span :class="$style.logCodeLine">{{item.payload.time}}</span>
              <span :class="$style.logCodeLine">{{item.payload.info}}</span>
              <span :class="$style.logCodeLine">{{item.payload.Hostname}}</span>
            </code>
            <code
              v-else
              :class="$style.rawMessageLog"
            >
              <span :class="$style.rawMessage">{{item.rawMessage}}</span>
            </code>
          </td>
        </tr>
      </template>

      <template v-slot:body.append>
        <tr v-intersect="getNextItems">
          <td
            class="text-center text--disabled"
            :colspan="headers.length"
          >
            <span v-if="hasNextLogs || !initLoadingEnd">Loading newer events...</span>
            <span v-else>No newer events at this moment.
              <span v-if="!pause">{{ `Auto retrying in ${seconds} secs...` }}
                <span
                  :class="$style.pause"
                  @click="pause = true"
                >Pause</span>
              </span>
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="hasErrors"
      :timeout="3000"
    >
      Can not display logs...

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="hasErrors = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_LOGS } from '@/store/ErrorsUtility/actions';
import fixedHeader from '@/mixins/fixedHeader';
import ErrorsUtilityToolbar from './ErrorsUtilityToolbar';

/**
 * Display Errors Utility Table with toolbar.
 */
export default {
  name: 'ErrorsUtilityTable',

  mixins: [fixedHeader],

  props: {
    logStream: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    isActiveTab: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ErrorsUtilityToolbar,
  },

  data: () => ({
    /**
     * If cursor exists it's mean can load next logs.
     */
    cursor: null,
    /**
     * Previous value of cursor before null.
     */
    previousCursor: null,

    /**
     * Items of an Errors utility.
     */
    items: [],

    /**
     * Errors utility loading state.
     */
    loading: false,

    /**
     * State of error utility loading.
     */
    hasErrors: false,

    /**
     * Logs filter params.
     */
    filterParams: {},

    /**
     * List of expanded rows.
     */
    expandedRows: [],

    /**
     * Seconds for countdown to auto reload items.
     */
    seconds: 10,

    /**
     * Timer for countdown.
     */
    timer: null,

    /**
     * Flag for counter is on pause.
     */
    isCounterOnPause: false,

    /**
     * Flag for init loading
     */
    initLoadingEnd: false,

    /**
     * Table headers.
     */
    headers: [
      { text: 'Timestamp', value: 'time', width: 200 },
      { text: 'Title', value: 'title', width: 250 },
      { text: 'Message', value: 'info', width: 600 },
    ],
  }),

  computed: {
    /**
     * Return `true` if can load next items.
     */
    hasNextLogs() {
      return this.cursor != null;
    },

    /**
     * Pause for counter according to active tab.
     */
    pause: {
      get() {
        return !this.isActiveTab || this.isCounterOnPause;
      },
      set(value) {
        this.isCounterOnPause = value;
      },
    },
  },

  destroyed() {
    clearTimeout(this.timer);
  },

  methods: {
    ...mapActions('errorsUtility', {
      getLogs: GET_LOGS,
    }),

    /**
     * Start timer.
     */
    startTimer() {
      this.timer = setInterval(() => {
        if (!this.pause) {
          this.seconds--;
        }
      }, 1000);
    },

    /**
     * Load the next items while scrolling to the end of the table if they exist.
     *
     * @param {IntersectionObserverEntry[]} entries
     */
    async getNextItems(entries) {
      // Set/unset pause according to scroll
      this.pause = !entries[0].isIntersecting;

      if (entries[0].isIntersecting && !this.loading && this.hasNextLogs) {
        const { cursor, results = [] } = await this.loadSystemLogs();

        this.previousCursor = this.cursor;
        this.cursor = cursor;
        this.items = this.items.concat(results);
      }
    },

    /**
     * Load items.
     */
    async getItems() {
      const { cursor, results = [] } = await this.loadSystemLogs(true);

      /**
       * Start timer if this first loading of items.
       */
      if (this.timer === null && cursor === null) {
        this.startTimer();
      }

      this.cursor = cursor;
      this.items = results;
    },

    /**
     * Load system logs.
     *
     * @returns {Promise<Object>}
     */
    async loadSystemLogs(initLoading = false) {
      let result = {};

      this.hasErrors = false;
      this.loading = true;

      try {
        const payload = {
          cursor: this.cursor,
          logStream: this.logStream,
          ...this.filterParams,
        };

        result = await this.getLogs(payload);
      } catch (error) {
        if (error.response) {
          this.hasErrors = true;
        }
      } finally {
        this.loading = false;

        if (initLoading) {
          this.initLoadingEnd = true;
        }
      }

      return result;
    },

    /**
     * Apply filter params.
     *
     * @param {Object} params
     */
    applyFilter(params) {
      this.pause = false;
      this.seconds = 10;
      this.cursor = null;
      this.filterParams = params;

      this.getItems();
    },

    /**
     * Check if the item has a payload info.
     *
     * @params {Object} payload - Log payload.
     */
    hasPayloadInfo(payload) {
      return !!payload.info;
    },

    /**
     * Expand / hide a table row by clicking on it.
     *
     * @param {Object} row - Table row value.
     */
    toggleExpandRow(row) {
      const index = this.expandedRows.indexOf(row);

      if (index === -1) {
        this.expandedRows.push(row);
      } else {
        this.expandedRows.splice(index, 1);
      }
    },
  },
  watch: {
    /**
     * Restart countdown.
     * @param time Current countdown value
     * @returns {Promise<void>}
     */
    async seconds(time) {
      if (time === 0) {
        this.seconds = 10;

        // Loading next items if not a pause and loading
        if (!this.loading && !this.pause) {
          // For loading logs from last request with cursor null response.
          this.cursor = this.previousCursor;

          const { cursor, results = [] } = await this.loadSystemLogs();

          this.cursor = cursor;
          // Add items only if cursor not null - new items.
          if (this.cursor !== null) {
            this.items = this.items.concat(results);
          }
        }
      }
    },
  },
};
</script>

<style module lang="scss">
.host {
  position: relative;

  tr[class="v-data-table__empty-wrapper"] {
    display: none;
  }
}

.table table {
  table-layout: fixed;
}

.infoCell {
  width: 600px;
  white-space: nowrap;;
  text-overflow: ellipsis;
  overflow: hidden;
}

.logCodeLine {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.expandLog {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rawMessageLog {
  display: inline-block;
  width: 100%;
  margin-bottom: 4px;
}

.pause {
  cursor: pointer;
  text-decoration: underline;
}

.rawMessage {
  display: block;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
