<template>
  <v-dialog
      v-model="dialog"
      max-width="400px"
      scrollable
      :transition="false"
  >
    <v-card v-if="loading">
      <v-card-text class="mt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-toolbar flat>
        <span :class="[$style['popup-title']]">
          Not Sent Details
        </span>

        <v-spacer />
        <v-btn
            icon
            @click="dialog = false;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Reason
              </th>
              <th class="text-left">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No Bill</td>
              <td>{{ data.noBill }}</td>
            </tr>
            <tr>
              <td>No Address</td>
              <td>{{ data.noAddress }}</td>
            </tr>
            <tr>
              <td>No Name</td>
              <td>{{ data.noName }}</td>
            </tr>
            <tr>
              <td>Jane/John Doe</td>
              <td>{{ data.johnDoe }}</td>
            </tr>
            <tr>
              <td>No Transport</td>
              <td>{{ data.noTransport }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'BillingNotSentTotalsPopup',
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    async show() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" module>
.popup-title {
  font-size: 22px;
}
</style>
