<template>
  <div>
    <v-data-table
        :headers="header"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :loading="loading"
        :height="tableHeight"
        :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap"
    >
      <template v-slot:item.cadDateTime="{ item }">
        {{ item.cadDateTime | datetime }}
      </template>
      <template v-slot:item.mcIncidentDateTime="{ item }">
        {{ item.mcIncidentDateTime | datetime }}
      </template>
      <template v-slot:item.mcSequenceNumber="{ item }">
        <a
            v-if="item.mcSequenceNumber"
            @click="viewIncident(item)"
        >
          {{ item.mcSequenceNumber }}
        </a>
        <span v-else>{{ item.II_SequenceNumber }}</span>
      </template>
      <template v-slot:item.mcMode="{ item }">
        {{ item.mcMode ? viaValuesMap[item.mcMode] : '' }}
      </template>
    </v-data-table>
    <view-incident-popup
        ref="viewIncidentPopup"
        :show-flag-btn="false"
        :show-nav-btn="false"
        @view-incident="viewIncident"
    />
  </div>

</template>

<script>
import _ from 'lodash';
import viaValuesMap from '@/data/Lookups/via';
import fixedHeader from '@/mixins/fixedHeader';
import nfirsExport from '@/api/nfirsExport';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';

export default {
  name: 'NfirsTable',
  components: { ViewIncidentPopup },
  mixins: [fixedHeader],
  props: {
    dateRange: {
      type: Array,
      required: true,
    },
    totalResults: {
      type: Number,
      required: true,
    },
    dataLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      viaValuesMap,
      loading: true,
      items: [],
      totalItems: 0,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['cadDateTime'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
      header: [
        {
          text: 'CAD_IncidentNo',
          value: 'cadIncidentNumber',
        },
        {
          text: 'CAD_DateTime',
          value: 'cadDateTime',
        },
        {
          text: 'CAD_Address',
          value: 'cadAddress',
        },
        {
          text: 'CAD_IncidentType',
          value: 'cadIncidentType',
        },
        {
          text: 'MC_SequenceNumber',
          value: 'mcSequenceNumber',
        },
        {
          text: 'MC_IncidentDateTime',
          value: 'mcIncidentDateTime',
        },
        {
          text: 'MC_IncidentNumber',
          value: 'mcIncidentNumber',
        },
        {
          text: 'MC_Mode',
          value: 'mcMode',
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.fetchData();
      }
    },

    dateRange() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.dateRange.length) {
        return;
      }
      try {
        this.loading = true;
        this.$emit('update:dataLoading', true);
        const params = {
          page: this.pagination.page,
          per_page: this.pagination.itemsPerPage,
          order_by: this.pagination.sortBy[0] || 'cadDateTime',
          sort_order: this.pagination.sortDesc[0] ? 'desc' : 'asc',
          dateFrom: this.dateRange[0],
          dateTo: this.dateRange[1],
        };
        const result = await nfirsExport.fetchData(params);
        this.items = result.results;
        this.totalItems = result.pagination.total;
        this.$emit('update:totalResults', this.totalItems);
      } finally {
        this.loading = false;
        this.$emit('update:dataLoading', false);
      }
    },

    viewIncident(incident) {
      this.$refs.viewIncidentPopup.show(
        { id: incident.mcId, II_SequenceNumber: incident.mcSequenceNumber },
        true,
        true,
        false,
      );
    },
  },
};
</script>
