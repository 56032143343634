export default {
  tableHeaders: {
    incidentDate: 'Incident Date',
    datePCRUploaded: 'Date PCR Uploaded',
    sentToBilling: 'Sent to Billing Date',
    sequenceNumber: 'Sequence Number',
    incidentNumber: 'Incident Number',
    lastName: 'Last Name',
    mode: 'Mode',
    status: 'Status',
    scheduledOn: 'Scheduled On',
  },
};
