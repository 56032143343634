<template>
  <v-toolbar class="white elevation-3 ma-4">
    <v-autocomplete
        v-if="categoryModel === insightCategories.TRANSPORT_BY_PROVIDER_IMPRESSIONS"
        v-model="providerImpressionModel"
        :items="providerImpressionItems"
        :disabled="loading"
        hide-details
        label="Insight"
        placeholder="Entire Agency"
        class="mr-4"
        clearable
        style="max-width: 300px"
    />
    <v-autocomplete
        v-else
        v-model="userIdModel"
        :items="userItems"
        :disabled="loading"
        hide-details
        label="Insight"
        placeholder="Entire Agency"
        class="mr-4"
        clearable
        style="max-width: 300px"
    />

    <v-select
        v-model="categoryModel"
        :items="categories"
        hide-details
        label="Category"
        class="mr-4"
        style="max-width: 300px"
    />

    <v-autocomplete
        v-model="focusModel"
        :items="focusItems"
        :loading="loadingFocus && !loading"
        :disabled="loading || !focusItems.length"
        hide-details
        label="Focus"
        placeholder="All"
        class="mr-4"
        style="max-width: 300px"
        clearable
    />
  </v-toolbar>
</template>
<script>

import INSIGHT_CATEGORIES from '@/enums/insightCategories';
import INSIGHT_CATEGORY_NAME from '@/enums/insightCategoryNames';

export default {
  name: 'Toolbar',

  props: {
    users: {
      type: Array,
      required: true,
    },
    providerImpressions: {
      type: Array,
      required: true,
    },
    focuses: {
      type: Array,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
    },
    providerImpression: {
      type: String,
      required: false,
    },
    category: {
      type: String,
      required: true,
    },
    focus: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categoryModel: this.category,
      categories: [
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.THERAPY],
          value: INSIGHT_CATEGORIES.THERAPY,
        },
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.PROVIDER_IMPRESSION],
          value: INSIGHT_CATEGORIES.PROVIDER_IMPRESSION,
        },
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.ADVANCED_AIRWAYS],
          value: INSIGHT_CATEGORIES.ADVANCED_AIRWAYS,
        },
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.MEDS],
          value: INSIGHT_CATEGORIES.MEDS,
        },
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.VITALS],
          value: INSIGHT_CATEGORIES.VITALS,
        },
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.TRANSPORT],
          value: INSIGHT_CATEGORIES.TRANSPORT,
        },
        {
          text: INSIGHT_CATEGORY_NAME[INSIGHT_CATEGORIES.TRANSPORT_BY_PROVIDER_IMPRESSIONS],
          value: INSIGHT_CATEGORIES.TRANSPORT_BY_PROVIDER_IMPRESSIONS,
        },
      ],
      insightCategories: INSIGHT_CATEGORIES,
    };
  },

  computed: {
    focusModel: {
      get() {
        return this.focus;
      },
      set(value) {
        this.$emit('update:focus', value);
      },
    },
    userIdModel: {
      get() {
        return this.userId;
      },
      set(value) {
        this.$emit('update:userId', value);
      },
    },
    providerImpressionModel: {
      get() {
        return this.providerImpression;
      },
      set(value) {
        this.$emit('update:providerImpression', value);
      },
    },

    userItems() {
      return this.users.map(user => ({ text: `${user.firstName} ${user.lastname}`, value: user.registryId }));
    },
    providerImpressionItems() {
      return this.providerImpressions.map(item => ({ text: `${item.code} - ${item.name}`, value: item.code }));
    },
    focusItems() {
      if (this.category === INSIGHT_CATEGORIES.VITALS) {
        return this.focuses.map(item => ({ text: item.name, value: item.code }));
      }
      return this.focuses.map(item => ({ text: `${item.code} - ${item.name}`, value: item.code }));
    },
  },

  watch: {
    categoryModel(value) {
      this.focusModel = null;
      this.$emit('update:category', value);
    },
  },
};
</script>
