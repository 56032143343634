import api from './api';

export default {
  /**
     * Search incidents
     *
     * @param {Object} params - Filter params
     *
     * @return Promise<Object>
     */
  searchIncidents(params) {
    return api()
      .get('/entire-search/incidents', { params })
      .then(response => response.data);
  },

  /**
     * Search duplicates.
     *
     * @param {Object} params - Filter params
     *
     * @return Promise<Object>
     */
  searchDuplicates(params) {
    return api()
      .get('/entire-search/duplicates', { params })
      .then(response => response.data);
  },

  /**
   * Search transmitted.
   *
   * @param {Object} params - Filter params
   *
   * @returns {Promise<Object>}
   */
  searchTransmitted(params) {
    return api()
      .get('/entire-search/transmitted', { params })
      .then(response => response.data);
  },

  /**
   * Search airdropped.
   *
   * @param {Object} params - Filter params
   *
   * @returns {Promise<Object>}
   */
  searchAirdropped(params) {
    return api()
      .get('/entire-search/airdropped', { params })
      .then(response => response.data);
  },

  /**
   * Search airdropped.
   *
   * @param {Object} params - Filter params
   *
   * @returns {Promise<Object>}
   */
  searchApiLogs(params) {
    return api()
      .get('/entire-search/api-logs', { params })
      .then(response => response.data);
  },
};
