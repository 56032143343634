<template>
  <div :id="`${clientId}${file}`">
    <v-menu
      :disabled="loading"
      offset-y
      :attach="`div[id='${clientId}${file}'`"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          :loading="loading"
          :disabled="loading"
          icon
          ripple
          v-on="on"
        >
          <v-icon color="grey lighten-1">edit</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click.stop="downloadFile">
          <v-list-item-title>Download</v-list-item-title>
        </v-list-item>
        <v-list-item @click="retryFile">
          <v-list-item-title>Retry</v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="dialog = true">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Confirmation!</v-card-title>
        <v-card-text>Are you sure that you want to delete file?</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click.native="dialog = false"
          >No</v-btn>
          <v-btn
            color="red darken-1"
            text
            @click.native="deleteFile"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { deleteFile, retryFile, downloadFile } from '@/api/cadMonitoring';
import { UPDATE_PROVIDER } from '@/store/CadMonitoring/actions';

export default {
  name: 'FailedFilesActions',
  filters: {},
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      UPDATE_PROVIDER: `cadMonitoring/${UPDATE_PROVIDER}`,
    }),
    retryFile() {
      this.loading = true;
      retryFile(this.clientId, this.file).then(() => {
        this.loading = false;
      });
    },
    deleteFile() {
      this.dialog = false;
      this.loading = true;
      deleteFile(this.clientId, this.file).then(() => {
        this.UPDATE_PROVIDER({ clientID: this.clientId }).then(() => {
          this.loading = false;
        });
      });
    },
    downloadFile() {
      this.loading = true;
      downloadFile(this.clientId, this.file).then(response => {
        this.loading = false;
        window.open(response.url, '_blank');
      });
    },
  },
};
</script>
