<template>
  <v-card-actions>
    <span :class="$style.title">{{ title }}</span>
    <v-spacer />
    <date-range-picker-with-periods
        v-if="periodModel === 'custom'"
        v-model="dateRange"
        :solo="false"
        :dense="false"
        :clearable="false"
        position="left"
        class="mr-4"
    />
    <v-select
        v-model="periodModel"
        :items="[
            {text: 'Last 3 Years', value: 'lastYears'},
            {text: 'Last 3 Months', value: 'lastMonths'},
            {text: 'All Time', value: 'full'},
            {text: 'Custom', value: 'custom'},
          ]"
        hide-details
        label="Time Frame"
        class="mr-5"
        style="max-width: 200px"
    />
    <v-btn
        class="mr-3"
        color="primary"
        :loading="exporting"
        @click="$emit('export')"
    >
      Export XLSX
    </v-btn>
  </v-card-actions>
</template>
<script>
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import moment from 'moment';

export default {
  name: 'TableToolbar',
  components: { DateRangePickerWithPeriods },

  props: {
    title: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    dateFrom: {
      type: String,
      required: false,
    },
    dateTo: {
      type: String,
      required: false,
    },
    exporting: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dateRange: [],
      periodModel: this.period,
    };
  },

  watch: {
    periodModel(value) {
      if (value === 'custom' && !this.dateRange.length) {
        this.dateRange = [
          moment().subtract(1, 'year').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ];
      }
      this.$emit('update:period', value);
    },
    dateRange(value) {
      this.$emit('update:dateFrom', value[0]);
      this.$emit('update:dateTo', value[1]);
    },
  },
};
</script>

<style lang="scss" module>
.title {
  margin-left: 15px;
  font-size: 18px;
}
</style>
