export default {
  createIncidentByJson: 'Create Incident by JSON',
  jsonUploaderDescription:
    'You can upload raw JSON file and save it as incident for any client',
  uploadJson: 'Upload Json',
  header: {
    regular: 'Incidents',
    flagged: 'Flagged Incidents',
    deleted: 'Deleted Incidents',
    unfinished: 'Unfinished Incidents',
  },
  filters: {
    status: 'Status',
    device: 'Device',
  },
};
