<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
    >
      <v-card v-if="loading">
        <v-card-text>
          <div class="pt-3">
            Loading...
            <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <span>Export Settings</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
              v-if="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>

          <v-combobox
              ref="emailsList"
              v-model="emails"
              v-validate="{ 'check-emails': true }"
              :error-messages="errors.collect('emails')"
              name="emails"
              hide-selected
              label="Notification Emails"
              placeholder="Add Emails"
              multiple
              small-chips
              deletable-chips
              append-icon=""
              @change="emailsChanged"
          >
            <template v-slot:selection="data">
              <v-chip
                  :key="data.item"
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  :color="validateEmail(data.item) ? 'gray' : 'primary'"
                  @click:close="data.parent.selectItem(data.item)"
              >
                {{ data.item }}
                <v-icon
                    class="ml-2"
                    small
                    @click="data.parent.selectItem(data.item)"
                >
                  $delete
                </v-icon>
              </v-chip>
            </template>
          </v-combobox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="validateAndSend"
          >
            <template>Save</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { SHOW_SNACKBAR } from '@/store/actions';
import { mapActions } from 'vuex';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import advancedQuery from '@/api/advancedQuery';

export default {
  name: 'SettingsPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      saving: false,
      retryAfterChange: false,
      emails: [],
      dictionary: {
        attributes: {
          name: 'Monitor Name',
          frequency: 'Frequency',
        },
      },
    };
  },

  created() {
    const that = this;
    this.$validator.extend('check-emails', {
      getMessage() {
        return 'Has an invalid email address';
      },
      validate(value) {
        let result = true;
        value.forEach(email => {
          if (!that.validateEmail(email)) {
            result = false;
          }
        });
        return result;
      },
    });
  },

  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    validateEmail(email) {
      if (email.length > 254) {
        return false;
      }
      return !!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    },

    async show() {
      this.hideError();
      this.$validator.reset();
      this.dialog = true;
      this.loading = true;
      try {
        const data = await advancedQuery.getEmails();
        this.emails = data.emails;
      } finally {
        this.loading = false;
      }
    },

    emailsChanged() {
      this.normalizeEmails();
      if (this.retryAfterChange) {
        this.validateAndSend();
      }
    },

    normalizeEmails() {
      this.emails.forEach((email, index) => {
        const emailTrimmed = email.trim();
        if (emailTrimmed) {
          if (emailTrimmed.indexOf(',') !== -1) {
            const emails = emailTrimmed.split(',');
            let counter = 0;
            emails.forEach((subEmail) => {
              const subEmailTrimmed = subEmail.trim();
              if (subEmailTrimmed) {
                counter++;
                if (counter === 1) {
                  this.emails[index] = subEmailTrimmed;
                } else {
                  this.emails.push(subEmailTrimmed);
                }
              }
            });
          } else {
            this.emails[index] = emailTrimmed;
          }
        } else {
          this.emails.slice(index, 1);
        }
      });
    },

    async validateAndSend() {
      if (this.$refs.emailsList.lazySearch && !this.retryAfterChange) {
        this.retryAfterChange = true;
        return;
      }
      this.retryAfterChange = false;
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.saving = true;
      try {
        await advancedQuery.saveEmails(this.emails.length ? this.emails : null);
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.saving = false;
      }
      this.$emit('saved');
    },
  },
};
</script>
