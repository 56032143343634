<template>
  <div>
    <v-card
      class="white elevation-3 ma-4 exports-log"
    >
      <v-tabs
          v-if="showAdvancedQuery"
          v-model="tabIndex"
      >
        <v-tab
            v-for="item in tabItems"
            :key="item.name"
            :disabled="!hasExportData && item.type === 'advancedQuery'"
            exact
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-divider v-if="showAdvancedQuery"/>

      <v-tabs-items :value="tabIndex">
        <v-tab-item>
          <basic-info
            :type="type"
            :has-export-data="hasExportData"
            :not-sent-clickable="type === exportTypes.BILLING"
            :show-total-counters="showTotalCounters"
          />
        </v-tab-item>
        <v-tab-item v-if="showAdvancedQuery">
          <advanced-query ref="advancedQuery" />
        </v-tab-item>
      </v-tabs-items>

    </v-card>
    <confirmation ref="confirmationPopup" />
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import EXPORT_TYPES from '@/enums/exportTypes';
import Confirmation from '@/components/Confirmation';
import AdvancedQuery from '@/components/ExportLog/AdvancedQuery';
import BasicInfo from '@/components/ExportLog/BasicInfo';

export default {
  components: {
    BasicInfo,
    AdvancedQuery,
    Confirmation,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    showAdvancedQuery: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotalCounters: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      tabItems: [
        { name: 'Basic Info', type: 'basic' },
        { name: 'Advanced Query', type: 'advancedQuery' },
      ],
      tabIndex: 0,
      exportTypes: EXPORT_TYPES,
    };
  },

  computed: {
    hasExportData() {
      const { siteInfo } = this.$store.state;
      return siteInfo && (
        (this.type === EXPORT_TYPES.BILLING && siteInfo.hasBillingData)
          || (this.type === EXPORT_TYPES.DHS && siteInfo.hasDhsData)
          || (this.type === EXPORT_TYPES.UNFILTERED && siteInfo.hasUnfilteredData)
      );
    },
  },

  mounted() {
    this.$store.commit(SET_TITLE, this.$t(`exportLog.header.${this.type}`));
  },
};
</script>
