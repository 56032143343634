import api from './api';

export default {
  /**
   * Returns metrics for total incidents.
   *
   * @return Promise<Array>
   */
  getMetrics(params) {
    return api()
      .get('client/metrics', { params })
      .then(response => response.data);
  },

  /**
     * Returns metrics for total incidents.
     *
     * @return Promise<Array>
     */
  getTotal() {
    return api()
      .get('client/metrics/totals/total')
      .then(response => response.data);
  },

  /**
   * Returns metrics for this month incidents incidents.
   *
   * @return Promise<Array>
   */
  getThisMonth() {
    return api()
      .get('client/metrics/totals/this-month')
      .then(response => response.data);
  },

  /**
   * Returns metrics for last month incidents incidents.
   *
   * @return Promise<Array>
   */
  getLastMonth() {
    return api()
      .get('client/metrics/totals/last-month')
      .then(response => response.data);
  },

  /**
   * Returns metrics for last month incidents incidents.
   *
   * @return Promise<Array>
   */
  getYearToDate() {
    return api()
      .get('client/metrics/totals/year-to-date')
      .then(response => response.data);
  },
};
