export default {
  header: 'Recent CAD Data',
  unitsHeader: 'Transporting Units',
  tableHeaders: {
    id: 'ID',
    number: 'Number',
    date: 'Incident Date',
    streetNumber: 'Street Number',
    streetDir: 'Street Dir',
    streetName: 'Street Name',
    streetType: 'Street Type',
    aptNumber: 'Apt Number',
    cityCode: 'City Code',
    zip: 'Zip',
    gps: 'GPS',
    jurisdictionalStation: 'Jurisdictional Station',
    tacticalChannel: 'Tactical Channel',
    triageInfo: 'Triage Info',
    type: 'Type',
    fireBlock: 'Fire Block',
    callCategory: 'Call Category',
    entryDate: 'Entry Date',
    lastUpdatedTime: 'Last Updated',
    responseTypeDate: 'Response Type Date',
    responseTypeStart: 'Response Type Start',
    responseTypeFinal: 'Response Type Final',
  },
  unitsTableHeaders: {
    id: 'ID',
    unit: 'Unit',
    type: 'Type',
    dispatchDate: 'DispatchDate',
    arrivalDate: 'ArrivalDate',
    leftSceneDate: 'LeftSceneDate',
    atFacilityDate: 'AtFacilityDate',
    availableDate: 'AvailableDate',
    enrouteDate: 'EnrouteDate',
    atPatientDate: 'AtPatientDate',
    preemptDate: 'PreemptDate',
    updated: 'UpdatedAt',
    used: 'Used',
    lastUpdatedTime: 'LastUpdatedTime',
    seat1: 'Seat1',
    seat2: 'Seat2',
    seat3: 'Seat3',
    seat4: 'Seat4',
    seat5: 'Seat5',
    seat6: 'Seat6',
    transHospital: 'TransHospital',
    modeOfTransport: 'modeOfTransport',
  },
  filters: {
    selectClient: 'Select Client',
    incidentDate: 'Incident Date',
    incidentNumber: 'Incident Number',
  },
};
