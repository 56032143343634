<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
      @close="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          Verify Phone
        </v-card-title>
        <v-card-text
          class="pt-0"
          style="min-height: 122px"
        >
          <v-alert
              v-model="errorAlert"
              type="error"
              outlined
              dismissible
              transition="scale-transition"
              class="mb-4"
          >
            <div v-html="errorMessage" />
          </v-alert>
          <div v-if="step === 'setPhone'">
            <vue-tel-input-vuetify
                v-if="dialog"
                v-model="phoneModel"
                label="Your phone number"
                :preferred-countries="['US']"
                :disabled-fetching-country="true"
                :error-messages="phoneNumberErrorMessages"
                name="phone"
                outer="mdi-send"
                mode="international"
                @input="updatePhone"
            />

            <div
                v-if="user.phone && showSuggestion"
                class="mt-5 font-italic"
            >
              <b>{{ user.phone }}</b> - Is this your phone number?
              <a @click="setCurrentPhone">Yes</a> / <a @click="hideSuggestion">No</a>
            </div>
          </div>
          <div v-if="step === 'verify'">
            <v-text-field
                ref="code"
                v-model="code"
                v-validate="'required'"
                :error-messages="errors.collect('code')"
                prepend-icon="lock"
                name="code"
                label="SMS Verification Code"
                type="text"
                maxlength="6"
            />

            <i>Please enter the SMS Verification code that was sent to your phone.
              If you did not receive a code, you can retry again after one minute.</i>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-btn
              text
              @click="close"
          >Cancel</v-btn>
          <v-spacer/>
          <v-btn
              v-if="step === 'setPhone'"
              :loading="loading"
              color="primary"
              text
              @click="sendPhoneToVerification"
          >Confirm</v-btn>

          <v-btn
              v-if="step === 'verify'"
              :loading="resending"
              :disabled="loading || resendTime > 0"
              class="login__button"
              color="primary"
              text
              @click="resendCode"
          >
            Resend Code<span
              v-if="resendTime > 0"
              class="ml-1"
          >({{ resendTime }})</span>
          </v-btn>

          <v-btn
              v-if="step === 'verify'"
              :loading="loading"
              color="primary"
              text
              @click="verifyCode"
          >Verify</v-btn>

        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import profile from '@/api/profile';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { SET_USER } from '@/store/mutations';

export default {
  name: 'VerifyPhoneDialog',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      resending: false,
      phoneModel: null,
      phone: null,
      phoneIsValid: true,
      showSuggestion: true,
      step: 'setPhone',
      code: null,
      token: null,
      resendTime: 60,
      timer: null,
      dictionary: {
        attributes: {
          code: 'Verification Code',
        },
      },
    };
  },
  computed: {
    ...mapState(['user']),

    phoneNumberErrorMessages() {
      const messages = this.errors.collect('phone');
      if (!this.phoneIsValid) {
        messages.push('Phone is invalid');
      }
      return messages;
    },
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapMutations({
      setUser: SET_USER,
    }),
    show() {
      this.dialog = true;
      this.loading = false;
      this.resending = false;
      this.phone = null;
      this.phoneModel = null;
      this.phoneIsValid = true;
      this.showSuggestion = true;
      this.step = 'setPhone';
      this.token = null;
      this.code = null;
      this.timer = null;
      this.resendTime = 60;
      this.errors.clear();
      this.$validator.reset();
    },

    /**
     * Close dialog
     */
    close() {
      clearTimeout(this.timer);
      this.dialog = false;
    },

    /**
     * Update phone.
     *
     * @param {String} number - Phone number
     * @param {Object} data - Phone data
     */
    updatePhone(number, data) {
      this.phoneIsValid = data.isValid;
      if (data.isValid === true) {
        this.phone = data.number.e164;
      } else {
        this.phone = '';
      }
    },

    setCurrentPhone() {
      this.phoneModel = this.user.phone;
      this.showSuggestion = false;
    },

    hideSuggestion() {
      this.showSuggestion = false;
    },

    /**
     * Sets phone request and sends verification code.
     */
    async sendPhoneToVerification() {
      if (!this.validatePhone()) {
        return;
      }
      try {
        this.hideError();
        this.loading = true;
        const response = await profile.newPhoneRequest({
          phone: this.phone,
        });
        this.token = response.token;
        this.step = 'verify';
        this.startTimer();
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Verify code from SMS.
     */
    async verifyCode() {
      this.hideError();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.loading = true;
      try {
        await profile.verifySmsCode({
          token: this.token,
          code: this.code,
        });
        const user = await profile.fetchUser();
        this.setUser(user);
        this.$emit('phoneUpdated', this.phone);
        this.close();
      } catch (error) {
        if (error.response.data.errorCode === 'VERIFICATION_CODE_RESEND') {
          this.showError(error.response.data.message);
          clearTimeout(this.timer);
          this.resendTime = 0;
        } else {
          this.parseErrorResponse(error.response);
        }
      } finally {
        this.loading = false;
      }
    },

    /**
     * Validates phone.
     *
     * @return {Boolean}
     */
    validatePhone() {
      if (!this.phone) {
        this.phoneIsValid = false;
      }
      return this.phoneIsValid;
    },

    /**
     * Start timer.
     */
    startTimer() {
      this.resendTime = 60;
      this.timer = setInterval(() => {
        if (this.resendTime > 0) {
          this.resendTime--;
        } else {
          clearTimeout(this.timer);
        }
      }, 1000);
    },

    /**
     * Resend verification code.
     */
    async resendCode() {
      try {
        this.resending = true;
        await profile.sendCode(this.token);
        this.hideError();
        this.startTimer();
      } finally {
        this.resending = false;
      }
    },
  },
};
</script>
