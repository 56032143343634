<template>
  <v-card
      elevation="3"
      class="ma-4"
      :loading="loadingSettings"
  >
    <toolbar
        v-if="this.dateRange.length"
        :status="status"
        :show-export-status="!!settings.interval"
        :loading-retry="loadingRetry"
        :loading-status="loadingStatus"
        :downloading="downloading"
        :date-range.sync="dateRange"
        :disabled-settints-btn="loadingSettings"
        :disabled-download-btn="dataLoading || !totalResults"
        @download="exportNfirsData"
        @showSettings="showSettings"
        @retry="retryExport"
        @refreshStatus="loadNfirsStatus"
    />

    <nfirs-table
      ref="nfirsTable"
      :date-range="dateRange"
      :total-results.sync="totalResults"
      :data-loading.sync="dataLoading"
    />
    <settings-popup
        ref="settingsPopup"
        :settings="settings"
        @saved="loadNfirsSettings"
    />
    <confirmation ref="confirmationPopup"/>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { SET_TITLE } from '@/store/mutations';
import { mapActions } from 'vuex';
import NfirsTable from '@/components/NfirsExport/NfirsTable';
import Toolbar from '@/components/NfirsExport/Toolbar';
import SettingsPopup from '@/components/NfirsExport/SettingsPopup';
import nfirsExport from '@/api/nfirsExport';
import { SHOW_SNACKBAR } from '@/store/actions';
import Confirmation from '@/components/Confirmation';
import moment from 'moment';
import fileDownload from 'js-file-download';

export default {
  name: 'NfirsExport',

  components: {
    Confirmation,
    SettingsPopup,
    Toolbar,
    NfirsTable,
  },

  data() {
    return {
      loadingSettings: false,
      loadingStatus: true,
      loadingRetry: false,
      dataLoading: false,
      totalResults: 0,
      downloading: false,
      dateRange: [],
      settings: {},
      status: null,
      directExportLimit: 5000,
    };
  },

  created() {
    this.$store.commit(SET_TITLE, 'NFIRS Export');
  },

  mounted() {
    this.dateRange = [
      moment().subtract(6, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ];
    this.loadNfirsSettings();
    this.loadNfirsStatus();
  },

  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Load NFIRS export settings.
     */
    async loadNfirsSettings() {
      this.loadingSettings = true;
      try {
        const oldSettings = _.cloneDeep(this.settings);
        this.settings = await nfirsExport.getSettings();
        if (oldSettings.filterCallTypes
            && !_.isEqual(oldSettings.filterCallTypes, this.settings.filterCallTypes)) {
          this.$refs.nfirsTable.fetchData();
        }
      } finally {
        this.loadingSettings = false;
      }
    },

    /**
     * Load NFIRS export status.
     */
    async loadNfirsStatus() {
      this.loadingStatus = true;
      try {
        this.status = await nfirsExport.getStatus();
      } finally {
        this.loadingStatus = false;
      }
    },

    /**
     * Retry export.
     */
    async retryExport() {
      this.loadingRetry = true;
      try {
        await nfirsExport.retryExport();
        this.status = null;
      } finally {
        this.loadingRetry = false;
      }
    },

    /**
     * Download export file.
     */
    async exportNfirsData() {
      if (!this.settings.fdId) {
        this.showSnackbar({
          title: 'Please configure the export first',
          color: 'primary',
        });
        return;
      }
      if (this.totalResults >= this.directExportLimit) {
        this.$refs.confirmationPopup.showConfirm(
          'Warning',
          'Because this export contains > 5k incidents, this report will take ~1 minute to produce. '
            + 'Once the export has been completed, you will receive an email with a link to download the file.',
          async () => {
            await this.exportToEmail();
          },
        );
      } else {
        await this.downloadFile();
      }
    },

    /**
     * Generate and download export file.
     */
    async downloadFile() {
      this.downloading = true;
      try {
        const response = await nfirsExport.export({
          dateFrom: this.dateRange[0],
          dateTo: this.dateRange[1],
        });
        fileDownload(response.data, 'nfirs_export.txt');
      } finally {
        this.downloading = false;
      }
    },

    /**
     * Generate and send export file to email.
     */
    async exportToEmail() {
      this.downloading = true;
      try {
        await nfirsExport.exportToEmail({
          dateFrom: this.dateRange[0],
          dateTo: this.dateRange[1],
        });
        this.showSnackbar('NFIRS Export process is started.');
      } finally {
        this.downloading = false;
      }
    },

    /**
     * Show reconciliation settings.
     */
    showSettings() {
      this.$refs.settingsPopup.show();
    },
  },
};
</script>
