import api from './api';

export default {
  /**
   * Fetch list of all possible recipients.
   *
   * @return Promise<Object>
   */
  fetchRecipients() {
    return api()
      .get('client/flagged-users/recipients')
      .then(response => response.data);
  },

  /**
   * Fetch list of flagged users for admin role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminFetchList(params) {
    return api()
      .get('admin/flagged-users', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of flagged users for client role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientFetchList(params) {
    return api()
      .get('client/flagged-users', { params })
      .then(response => response.data);
  },

  /**
   * Export flagged users to Excel for admin role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminExport(params) {
    return api()
      .get('admin/flagged-users/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Export flagged users to Excel for client role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientExport(params) {
    return api()
      .get('client/flagged-users/export', { params, responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Export flagged users to Excel for admin role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  adminResendAll(params) {
    return api().post('admin/flagged-users/resend-all', params);
  },

  /**
   * Export flagged users to Excel for client role.
   *
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  clientResendAll(params) {
    return api().post('client/flagged-users/resend-all', params);
  },

  /**
   * Resend pending flag to user.
   *
   * @param {int} alertId - Identifier of alert
   *
   * @return Promise
   */
  resendFlag(alertId) {
    return api().post(`incidents-alerts/${alertId}`);
  },

  /**
   * Send flag to incident
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Object} params - Filtering params
   *
   * @return Promise<Object>
   */
  sendFlag(incidentId, params) {
    return api().post(`client/incidents/${incidentId}/flag`, params);
  },

  /**
   * Delete flag
   *
   * @param {Number} incidentId - Incident identifier
   *
   * @return Promise<Object>
   */
  deleteFlag(incidentId) {
    return api().delete(`client/incidents/${incidentId}/flag`);
  },

  /**
   * Delete flagged user.
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Number} alertId - Alert identifier
   *
   * @return Promise<Object>
   */
  deleteAlert(incidentId, alertId) {
    return api().delete(`client/incidents/${incidentId}/flag/alert/${alertId}`);
  },

  /**
   * Resent pending flags
   *
   * @param {Number} incidentId - Incident identifier
   *
   * @return Promise<Object>
   */
  resendPendingAlerts(incidentId) {
    return api().post(`client/incidents/${incidentId}/flag/resend-pending`);
  },

  /**
   * Respond on flag as Understood.
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Object} params - Params for request
   *
   * @return Promise<Object>
   */
  respondFlagUnderstood(incidentId, params) {
    return api().post(`client/incidents/${incidentId}/flag/understood`, params);
  },

  /**
   * Respond on flag as Did not understand.
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Object} params - Params for request
   *
   * @return Promise<Object>
   */
  respondFlagNotUnderstand(incidentId, params) {
    return api().post(`client/incidents/${incidentId}/flag/not-understood`, params);
  },
  /**
   * Add view log.
   *
   * @param {Number} incidentId - Incident identifier
   *
   * @return Promise<Object>
   */
  viewLog(incidentId) {
    return api().post(`client/incidents/${incidentId}/flag/view-log`);
  },

  /**
   * Delete flag comment.
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Number} commentId - Comment identifier
   *
   * @returns Promise<Object>
   */
  deleteFlagComment(incidentId, commentId) {
    return api().delete(`client/incidents/${incidentId}/flag/comments/${commentId}`);
  },

  /**
   * Add comment for flag.
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Object} params - Params for request
   *
   * @return Promise<Object>
   */
  addFlagComment(incidentId, params) {
    return api().post(`client/incidents/${incidentId}/flag/comments`, params);
  },

  /**
   * Close pending alerts for flag.
   *
   * @param {Number} incidentId - Incident number
   * @param {Object} params - Params for request
   *
   * @returns Promise<Object>
   */
  closePendingFlag(incidentId, params) {
    return api().post(`client/incidents/${incidentId}/flag/close-pending`, params);
  },
};
