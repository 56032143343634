import templatesHeaders from '@/data/TableHeaders/templatesHeaders';

export default {
  tableHeaders: templatesHeaders,
  statuses: ['All', 'Active', 'Deleted'],
  search: 'Search',
  status: 'Status',
  defaultTemplate: 'Default Template',
  deleteConfirmationTitle: 'Delete template',
  deleteConfirmationText: 'Do you really want to delete template: {name}?',
  restoreConfirmationTitle: 'Restore template',
  restoreConfirmationText: 'Do you want to restore template: {name}?',
  editPopup: {
    headerEdit: 'Edit Template',
    headerNew: 'Add Template',
    version: 'Template Version',
    sourceCodeVersion: 'Source Code Version',
    description: 'Description',
    invalidVersionMessage: 'Please use semantic versioning format: x.x.x',
    invalidTemplateFile: 'Template file name must be: "template_X.X.X.zip".',
    renderBy: 'Render By',
    fileRequired: 'Template file is required',
    releaseDateRequired: 'Release Date is required',
    mismatchVersions: 'Mismatch versions of edited template and selected file',
    releaseDate: 'Release Date',
  },
};
