<template>
  <div class="invoices-table">
    <v-data-table
        :headers="header"
        :items="invoiceItems"
        :loading="loading"
        :height="tableHeight"
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap"
    >
      <template v-slot:item.client.name="{ item }">
        <a
          v-if="clientClickable"
          @click="$emit('clientClick', item.client)"
        >{{ item.client.name }}</a>
        <span v-else>{{ item.client.name }}</span>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | date }}
      </template>
      <template v-slot:item.amount="{ item }">
        ${{ item.amount | price }}
      </template>
      <template v-slot:item.fiscalDateFrom="{ item }">
        {{ [item.fiscalDateFrom, item.fiscalDateTo] | fiscalRange }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="table-actions float-right">
          <v-btn
              class="mr-3 float-right"
              icon
              @click="$emit('edit', item)"
          >
            <v-icon color="grey darken-2">edit</v-icon>
          </v-btn>

          <v-btn
              v-if="item.renderingStatus === 'rendered'"
              class="float-right mr-4"
              icon
              :href="item.pdfUrl"
              target="_blank"
          >
            <v-icon color="grey darken-2">mdi-file-pdf-box</v-icon>
          </v-btn>

          <v-btn
              v-else
              class="float-right mr-4"
              icon
              :disabled="true"
          >
            <v-icon color="grey darken-2">mdi-timer-sand</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>

</template>

<script>
import moment from 'moment';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'InvoicesTable',

  mixins: [fixedHeader],
  filters: {
    fiscalRange(range) {
      const from = moment(range[0]);
      const to = moment(range[1]);
      return `${from.format('ll')} - ${to.format('ll')}`;
    },
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    clientClickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      additionalIndent: -10,
      header: [
        {
          text: 'Date Created',
          value: 'createdAt',
        },
        {
          text: 'Client',
          value: 'client.name',
        },
        {
          text: 'Date Range',
          value: 'fiscalDateFrom',
        },
        {
          text: 'Amount Billed',
          value: 'amount',
        },
        {
          text: 'Total PCR(s) Billed',
          value: 'totalPCR',
        },
        {
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    invoiceItems() {
      return this.invoices.map(item => {
        const invoice = item;
        invoice.totalPCR = item.estimatedIncidents - item.credit;
        return invoice;
      });
    },
  },
};
</script>
<style lang="scss">
.invoices-table {
  .table-actions {
    width: 100px;
    visibility: hidden;
  }
  tr:hover .table-actions {
    visibility: visible;
  }
}
</style>
