export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_TOTALS = 'SET_TOTALS';

export default {
  [SET_CLIENTS]: (state, data) => {
    state.clients = data;
  },
  [SET_TOTALS]: (state, data) => {
    state.totals = data;
  },
};
