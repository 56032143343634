<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
      scrollable
      :transition="false"
      class="export-details"
  >
    <v-card v-if="loading">
      <v-card-text class="mt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else-if="exportLogData">
      <v-toolbar flat>
        <span :class="[$style['popup-title'], 'mr-5']">
          Export details
        </span>
        <div v-if="exportLogData.status === 1 && exportLogData.comment">
          <v-btn
              v-if="!showError"
              text
              color="primary"
              small
              @click="showError = true;"
          >Show Error details...</v-btn>
          <v-btn
              v-if="showError"
              text
              small
              color="blue"
              @click="showError = false;"
          >Hide Error details</v-btn>
        </div>
        <v-spacer />
        <span class="mr-2">Status:</span>
        <span :class="[$style[getStatusClass(exportLogData.status)], 'mr-5']">
          {{ getStatusText(exportLogData.status) }}
        </span>
        <v-btn
            icon
            @click="dialog = false;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="exportLogData.status === 1 && exportLogData.comment && showError">
        <span
            :class="[$style.error]"
        >
            {{ exportLogData.comment }}
          </span>
      </v-card-text>
      <v-data-table
          :headers="headers"
          :items="exportLogData.exportIncidents"
      >
        <template v-slot:item.sequenceNumber="{ item }">
          <router-link
              v-if="$can('view', 'incident')"
              :to="{ name: routes.INCIDENT_VIEW, params: { id: item.incidentId }}"
              target= '_blank'
          >{{ item.sequenceNumber }}</router-link>
          <span v-else>{{ item.sequenceNumber }}</span>
        </template>
        <template v-slot:item.incidentDateTime="{ item }">
          {{ item.incidentDateTime | date }}
        </template>
        <template v-slot:item.status="{ item }">
          <span :class="[$style[getJobStatusClass(item.status)]]">
            {{ getIncidentJobStatus(item.status) }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>

import exportLog from '@/api/exportLog';
import EXPORT_STATUSES from '@/enums/exportStatuses';
import JOB_STATUSES from '@/enums/jobStatuses';
import ROUTES from '@/enums/routes';

export default {
  name: 'ExportDetailsPopup',
  data() {
    return {
      dialog: false,
      loading: false,
      logId: null,
      exportLogData: null,
      showError: false,
      routes: ROUTES,
      headers: [
        {
          text: this.$t('exportLog.detailsHeaders.sequenceNumber'),
          value: 'sequenceNumber',
          sortable: false,
        },
        {
          text: this.$t('exportLog.detailsHeaders.incidentNumber'),
          value: 'incidentNumber',
          sortable: false,
        },
        {
          text: this.$t('exportLog.detailsHeaders.incidentDate'),
          value: 'incidentDateTime',
          sortable: false,
        },
        {
          text: this.$t('exportLog.detailsHeaders.firstName'),
          value: 'PI_FirstName',
          sortable: false,
        },
        {
          text: this.$t('exportLog.detailsHeaders.lastName'),
          value: 'PI_LastName',
          sortable: false,
        },
        {
          sortable: false,
          value: 'status',
        },
      ],
    };
  },
  methods: {
    async show(logId) {
      this.dialog = true;
      this.loading = true;
      this.showError = false;
      this.logId = logId;
      try {
        this.exportLogData = await exportLog.fetchExportLogData(logId);
      } finally {
        this.loading = false;
      }
    },
    getStatusText(status) {
      const map = {};
      map[EXPORT_STATUSES.SUCCESS] = 'Success';
      map[EXPORT_STATUSES.ERROR] = 'Error';
      map[EXPORT_STATUSES.PENDING] = 'Pending';
      return map[status];
    },
    getStatusClass(status) {
      const map = {};
      map[EXPORT_STATUSES.SUCCESS] = 'status-success';
      map[EXPORT_STATUSES.ERROR] = 'status-error';
      map[EXPORT_STATUSES.PENDING] = 'status-pending';
      return map[status];
    },
    getIncidentJobStatus(status) {
      const map = {};
      map[JOB_STATUSES.FINISHED] = '';
      map[JOB_STATUSES.FAILED] = 'Error';
      map[JOB_STATUSES.QUEUED] = 'Rendering';
      map[JOB_STATUSES.EXECUTING] = 'Rendering';
      return map[status];
    },
    getJobStatusClass(status) {
      const map = {};
      map[JOB_STATUSES.FINISHED] = 'status-success';
      map[JOB_STATUSES.FAILED] = 'status-error';
      map[JOB_STATUSES.QUEUED] = 'status-pending';
      map[JOB_STATUSES.EXECUTING] = 'status-pending';
      return map[status];
    },
  },
};
</script>

<style lang="scss" module>
.popup-title {
  font-size: 22px;
}
.status-success {
  color: green;
  font-weight: bold;
}
.status-error {
  color: red;
  font-weight: bold;
}
.status-pending {
  color: blue;
  font-weight: bold;
}
.error {
  color: red;
}
</style>
