import apiv3 from '@/api/apiv3';
import apiv4 from '@/api/apiv4';
import apiUrl from '@/api/apiUrl';
import clientsApi from '@/api/clients';
import profile from '@/api/profile';
import fetchSiteInfo from '@/api/siteInfo';
import { getAbilitiesForRole } from '@/abilities';
import USER_ROLES from '@/enums/userRoles';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';
import {
  SET_USER,
  SET_SITE_INFO,
  SET_DRAWER,
  SET_DISPLAY_HEADER,
  SET_INITIALIZED,
  SET_INITIALIZING,
  SET_INITIALIZING_USER,
  SET_INITIALIZING_SITE_INFO,
  SET_SITECODE,
  SET_CLIENTS,
  SET_SNACKBAR,
  SET_SNACKBAR_TITLE,
  SET_SNACKBAR_COLOR,
  SET_VERIFY_CONTACT_DATA_DIALOG,
} from './mutations';

export const INIT = 'INIT';
export const REINIT = 'REINIT';
export const CHANGE_SITECODE = 'CHANGE_SITECODE';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const SHOW_VERIFY_CONTACT_DATA_DIALOG = 'SHOW_VERIFY_CONTACT_DATA_DIALOG';

export default {
  /**
   * Init application
   */
  [INIT]: async ({
    commit, getters, state, dispatch,
  }) => {
    if (state.initialized || state.initializing) {
      return;
    }
    const { ability } = getters;
    commit(SET_USER, null);
    commit(SET_DRAWER, false);
    commit(SET_DISPLAY_HEADER, false);
    commit(SET_INITIALIZING, true);
    commit(SET_INITIALIZING_USER, true);
    commit(SET_INITIALIZING_SITE_INFO, true);
    try {
      const userData = await profile.fetchUser();
      ability.update(getAbilitiesForRole(userData.userTypeId));
      commit(SET_USER, userData);
      commit(SET_DRAWER, true);
      commit(SET_DISPLAY_HEADER, true);
      commit(SET_INITIALIZING_USER, false);
      if (ability.can('getAll', 'clients')) {
        dispatch(FETCH_CLIENTS);
      }
    } catch (e) {
      commit(SET_USER, null);
      ability.update(getAbilitiesForRole(USER_ROLES.PUBLIC));
    } finally {
      commit(SET_INITIALIZING_USER, false);
    }

    try {
      const siteInfoData = await fetchSiteInfo();
      commit(SET_SITE_INFO, siteInfoData);
    } catch (e) {
      commit(SET_SITE_INFO, null);
    } finally {
      commit(SET_INITIALIZING_SITE_INFO, false);
    }
    commit(SET_INITIALIZING, false);
    commit(SET_INITIALIZED, true);
    dispatch(SHOW_VERIFY_CONTACT_DATA_DIALOG);
  },
  /**
   * Re init application
   */
  [REINIT]: ({ commit, dispatch }) => {
    commit(SET_INITIALIZED, false);
    dispatch(INIT);
  },

  [CHANGE_SITECODE]: ({ commit }, sitecode) => {
    commit(SET_SITECODE, sitecode);
    apiv3.defaults.baseURL = apiUrl(sitecode, 'v3');
    apiv3.defaults.headers.common = {
      SiteCode: sitecode,
    };

    apiv4.defaults.baseURL = apiUrl(sitecode, 'v4');
    apiv4.defaults.headers.common = {
      SiteCode: sitecode,
    };
  },

  /**
   * Fetches clients list.
   *
   * @param {Function} commit - State commit.
   *
   * @return {Promise<unknown>}
   */
  [FETCH_CLIENTS]: ({ commit }) => clientsApi.fetchList().then(results => {
    commit(SET_CLIENTS, results.results);
  }),

  /**
   * Show snackbar.
   *
   * @param {Function} commit - State commit.
   * @param {String|Object} title - Text of snackbar or object with title and color.
   *
   * @return {Promise<unknown>}
   */
  [SHOW_SNACKBAR]: ({ commit }, payload) => {
    const title = typeof payload === 'string' ? payload : payload.title;
    const color = typeof payload === 'string' ? 'green' : payload.color;
    commit(SET_SNACKBAR_TITLE, title);
    commit(SET_SNACKBAR_COLOR, color);
    commit(SET_SNACKBAR, true);
  },

  /**
   * Show verify contact data dialog.
   *
   * @param {Function} commit - State commit.
   */
  [SHOW_VERIFY_CONTACT_DATA_DIALOG]: ({ state, commit }) => {
    const justLoggedIn = localStorage.getItem('justLoggedIn');
    if (justLoggedIn) {
      delete localStorage.justLoggedIn;
      if (state.user
          && (!state.user.emailVerified || !state.user.phoneVerified)
          && state.siteInfo && state.siteInfo.clientSiteType !== CLIENT_SITE_TYPES.CE_ENTRY
      ) {
        commit(SET_VERIFY_CONTACT_DATA_DIALOG, true);
      }
    }
  },
};
