<template>
  <v-card class="elevation-20">
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title class="font-weight-medium">Do you want to save your IP?</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="text-center">
      Your IP ({{ currentIp }}) is frequently used. <br />
      Do you want to add this IP to whitelist to avoid 2FA verification?
    </v-card-text>
    <v-card-actions>
      <a
        class="ml-3"
        @click="$emit('backToLogin')"
      >Back to Login</a>

      <v-spacer/>

      <v-btn
          :loading="forgeting"
          :disabled="saving"
          class="login__button"
          color="primary"
          @click="forget"
      >
        Forget IP
      </v-btn>

      <v-btn
        :loading="saving"
        :disabled="forgeting"
        class="login__button"
        color="primary"
        @click="save"
      >
        Save Ip
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import auth from '@/api/auth';

export default {
  name: 'SuggestToSaveIpForm',
  props: {
    token: {
      type: String,
      default: null,
      required: false,
    },
    currentIp: {
      type: String,
      default: null,
      required: false,
    },
    confirmIp: {
      type: Boolean,
      default: null,
      required: false,
    },
    hasVerifiedPhone: {
      type: Boolean,
      required: true,
    },
    hasVerifiedEmail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      forgeting: false,
    };
  },

  methods: {
    save() {
      this.saving = true;
      this.$emit('update:confirmIp', true);
      this.sendCode();
    },

    forget() {
      this.forgeting = true;
      this.$emit('update:confirmIp', false);
      this.sendCode();
    },

    /**
     * Resend verification code.
     */
    async sendCode() {
      try {
        if (this.hasVerifiedPhone) {
          await auth.sendSmsCode(this.token);
          this.$emit('smsCodeSent');
        } else {
          await auth.sendEmailCode(this.token);
          this.$emit('emailCodeSent');
        }
      } finally {
        this.saving = false;
        this.forgeting = false;
      }
    },
  },
};
</script>
