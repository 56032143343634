<template>
  <div>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>Forgot Password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form
        v-if="!sent"
        @keyup.native.enter="forgotPasswordRequest"
      >
        <span>
          Provide your User Name below and we will send you a link to your email to reset password.
        </span>
        <v-alert
          :value="errorAlert"
          type="error"
          outlined
          transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>
        <v-text-field
          v-model="sitecode"
          v-validate="'required'"
          :error-messages="errors.collect('sitecode')"
          prepend-icon="domain"
          name="sitecode"
          label="Site Code"
          type="text"
        />
        <v-text-field
          v-model="userName"
          v-validate="'required'"
          :error-messages="errors.collect('userName')"
          prepend-icon="person"
          name="userName"
          label="User Name"
          type="text"
        />
      </v-form>
      <span v-if="sent">The restoration email has been sent</span>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <router-link
        :to="'/login'"
        class="mr-3"
      >Login</router-link>
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="forgotPasswordRequest"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { forgot } from '@/api/password';
import { CHANGE_SITECODE } from '@/store/actions';
import { SET_LOGIN } from '@/store/Login/mutations';
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'Form',
  mixins: [errorAlertMixins],
  data() {
    return {
      sent: false,
      loading: false,
      dictionary: {
        attributes: {
          sitecode: 'Site Code',
          userName: 'User Name',
        },
      },
    };
  },
  computed: {
    sitecode: {
      get() {
        return this.$store.state.sitecode;
      },
      set(value) {
        this.CHANGE_SITECODE(value);
      },
    },
    userName: {
      get() {
        return this.$store.state.login.login;
      },
      set(value) {
        this.$store.commit(`login/${SET_LOGIN}`, value);
      },
    },
  },
  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapActions([CHANGE_SITECODE]),
    forgotPasswordRequest() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        this.loading = true;
        this.hideError();
        forgot(this.userName).then(() => {
          this.sent = true;
          setTimeout(() => {
            this.$router.push('login');
          }, 3000);
        });
      });
    },
  },
};
</script>
