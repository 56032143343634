/**
 * Convert float minutes to time format.
 *
 * @param {Number} minutes - Minutes
 *
 * @return {String}
 */
export default function convertToTime(minutes) {
  const hours = Math.floor(minutes / 60); // Get the number of whole hours
  const remainingMinutes = Math.floor(minutes % 60); // Get the number of remaining minutes
  const remainingSeconds = Math.round((minutes % 1) * 60);
  // Get the number of remaining seconds and round to the nearest integer

  // Format the time
  const formattedHours = hours.toString().padStart(2, '0'); // Add a leading zero if there are less than 10 hours
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0'); // Add a leading zero if there are less than 10 minutes
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0'); // Add a leading zero if there are less than 10 seconds

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`; // Return the formatted time string
}
