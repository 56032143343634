import fileDownload from 'js-file-download';
import usersApi from '@/api/users';

import { SET_ITEMS, SET_LICENSE_TYPES } from './mutations';

export const LOAD_USERS = 'LOAD_USERS';
export const EXPORT_USERS = 'EXPORT_USERS';

export const LICENSE_TYPES = 'LICENSE_TYPES';

export const INCLUDES = {
  REGISTRY: 'registry',
  HAS_PENDING_FLAGS: 'hasPendingFlags',
};

export default {
  /**
   * Export users list.
   *
   * @param {Object} state - Users state.
   *
   * @return {Promise}
   */
  [EXPORT_USERS]: ({ state }) => {
    const { pagination, filters } = state;
    const params = {
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const result = usersApi.export(params);

    return result.then(response => fileDownload(response.data, 'users.xlsx'));
  },

  /**
   * Load users list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Users state.
   *
   * @return {Promise}
   */
  [LOAD_USERS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      include: [INCLUDES.REGISTRY, INCLUDES.HAS_PENDING_FLAGS].join(','),
      ...filters,
    };

    const results = await usersApi.fetchList(params);
    commit(SET_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Load license types.
   *
   * @param {Function} commit - State commit
   *
   * @returns {Promise}
   */
  [LICENSE_TYPES]: async ({ commit }) => {
    const results = await usersApi.getLicenseTypes();
    commit(SET_LICENSE_TYPES, results);

    return results;
  },
};
