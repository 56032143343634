<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="600"
  >
    <v-card>
      <v-container>
        <v-card-title class="headline pt-0">
          {{ isNew
            ? $t('documents.categories.editPopup.new')
            : $t('documents.categories.editPopup.edit')
          }}
        </v-card-title>
        <v-card-text class="pt-0">
          <v-alert
            :value="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
          >
            {{ errorMessage }}
          </v-alert>
          <div
            v-if="loading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              color="grey darken"
              center
              indeterminate
            />
          </div>
          <div v-else>
            <v-layout wrap>
              <v-flex xs12>
                <tree-select
                  v-model="localCategory.parentCategoryId"
                  :multiple="false"
                  :options="categories"
                  :disabled="!isNew"
                  :append-to-body="true"
                  :placeholder="$t('documents.categories.selectPlaceholder')"
                  :searchable="false"
                  class="materials_select materials_select--popup"
                >
                  <div
                    slot="option-label"
                    slot-scope="{ node }"
                  >
                    {{ disableSecondLevel(node).raw.name }}
                  </div>
                  <div
                    slot="value-label"
                    slot-scope="{ node }"
                  >
                    {{ transformLabel(node) }}
                  </div>
                </tree-select>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="localCategory.name"
                  v-validate="{ required: true, max: 100 }"
                  :label="$t('documents.categories.editPopup.name')"
                  :error-messages="errors.collect('name')"
                  name="name"
                />
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-btn
            color="blue darken-1"
            text
            @click.native="close(false)"
          >
            {{ $t('documents.buttons.cancel') }}
          </v-btn>
          <v-btn
            :loading="saving"
            :disabled="saving"
            color="primary"
            text
            @click.native="save"
          >
            {{ $t('documents.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TreeSelect from '@riophae/vue-treeselect';
import { SAVE_CATEGORY, CREATE_CATEGORY } from '@/store/Documents/actions';
import errorAlertMixins from '@/mixins/errorAlertMixins';

const { mapActions } = createNamespacedHelpers('documents');

export default {
  name: 'CategoryEditPopup',
  components: { TreeSelect },
  mixins: [errorAlertMixins],
  props: {
    category: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    clientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      localCategory: {
        categoryId: null,
        name: null,
        parentCategoryId: null,
      },
      saving: false,
      saveError: null,
      loading: false,
    };
  },
  computed: {
    isNew() {
      return !((this.category && this.category.categoryId) || false);
    },
  },
  watch: {
    category: {
      handler(newVal) {
        if (newVal) {
          this.localCategory = Object.assign(this.localCategory, newVal);
        } else {
          this.localCategory = {
            categoryId: null,
            name: null,
            parentCategoryId: null,
          };
        }
        this.errors.clear();
        this.$validator.reset();
      },
      deep: true,
    },
    visible: {
      handler(newVal) {
        if (!newVal) {
          this.localCategory = {
            categoryId: null,
            name: null,
            parentCategoryId: null,
          };
          this.errors.clear();
          this.$validator.reset();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      saveCategory: SAVE_CATEGORY,
      createCategory: CREATE_CATEGORY,
    }),

    /**
     * Validate category values and save if it's ok.
     */
    async save() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      this.saving = true;
      this.errorAlert = false;
      try {
        if (this.isNew) {
          await this.createCategory(
            { clientId: this.clientId, ...this.localCategory },
          );
        } else {
          await this.saveCategory(this.localCategory);
        }

        this.close(true);
      } catch (error) {
        this.parseErrorResponse(error.response);
      } finally {
        this.saving = false;
      }
    },

    /**
     * Makes all actions to close and flush this popup.
     *
     * @param {Boolean} success - Shows whether was successful saving or not
     */
    close(success = false) {
      this.$emit('close', success);
      this.errorAlert = false;
      this.errors.clear();
    },

    /**
     * Disables second nested level to avoid making tree more then 3 level deep.
     *
     * @param {Object} node - Node to check and disabled
     *
     * @return {Object}
     */
    disableSecondLevel(node) {
      if (node.level === 2) {
        // eslint-disable-next-line no-param-reassign
        node.isDisabled = true;
      }

      return node;
    },

    /**
     * Transform category label into appropriate view.
     *
     * @param {Object} node - Category to transform.
     *
     * @return {string}
     */
    transformLabel(node) {
      let label = null;

      if (node.parentNode) {
        label = this.transformLabel(node.parentNode);
      }

      return label ? `${label} / ${node.raw.name}` : node.raw.name;
    },
  },
};
</script>

<style lang="scss">
.materials_select--popup {
  margin-bottom: 24px;
}
</style>
