import pushNotifications from '@/api/pushNotifications';

import {
  SET_PUSH_NOTIFICATIONS_ITEMS,
  SET_PUSH_NOTIFICATIONS_ITEMS_FAILED,
} from './mutations';

export const LOAD_PUSH_NOTIFICATIONS = 'LOAD_PUSH_NOTIFICATIONS';
export const LOAD_PUSH_NOTIFICATIONS_FAILED = 'LOAD_PUSH_NOTIFICATIONS_FAILED';

export default {
  /**
   * Load push notifications list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Push notifications state.
   *
   * @return {Promise}
   */
  [LOAD_PUSH_NOTIFICATIONS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await pushNotifications.fetchList(params);
    commit(SET_PUSH_NOTIFICATIONS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Load failed push notifications list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Push notifications state.
   *
   * @return {Promise}
   */
  [LOAD_PUSH_NOTIFICATIONS_FAILED]: async ({ commit, state }) => {
    const { paginationFailed, filtersFailed } = state;

    const params = {
      page: paginationFailed.page,
      per_page: paginationFailed.itemsPerPage,
      order_by: paginationFailed.sortBy[0],
      sort_order: paginationFailed.sortDesc[0] ? 'desc' : 'asc',
      ...filtersFailed,
    };

    const results = await pushNotifications.fetchList(params);
    commit(SET_PUSH_NOTIFICATIONS_ITEMS_FAILED, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },
};
