<template>
  <div>
    <total-counters
        v-if="showTotalCounters"
        :loading="loadingTotals"
        :data="totals || {}"
        :not-sent-clickable="notSentClickable"
        @notSentClick="showNotSentTotals"
    />

    <v-divider v-if="showTotalCounters" />

    <v-toolbar flat>
      <span :class="[$style['table-title'], 'mr-5']">
        Last Ten Exports
      </span>

      <v-spacer />

      <v-btn
          :disabled="!hasExportData"
          :loading="loadingRunManually"
          color="primary"
          @click="runManually"
      >Run Manually</v-btn>
    </v-toolbar>

    <export-log-table
        ref="exportLogTable"
        :items="items"
        :loading="loadingLog"
        @showDetails="showDetails"
    />

    <billing-not-sent-totals-popup
        ref="notSentPopup"
        :data="notSentTotals || {}"
        :loading="loadingNotSentTotals"
    />

    <confirmation ref="confirmationPopup" />
    <export-details-popup ref="exportDetails"/>
  </div>
</template>
<script>
import TotalCounters from '@/components/ExportLog/TotalCounters';
import exportLog from '@/api/exportLog';
import BillingNotSentTotalsPopup from '@/components/ExportLog/BillingNotSentTotalsPopup';
import Confirmation from '@/components/Confirmation';
import ExportLogTable from '@/components/ExportLog/ExportLogTable';
import ExportDetailsPopup from '@/components/ExportLog/ExportDetailsPopup';

export default {
  name: 'BasicInfo',
  components: {
    ExportDetailsPopup,
    ExportLogTable,
    Confirmation,
    BillingNotSentTotalsPopup,
    TotalCounters,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    notSentClickable: {
      type: Boolean,
      default: false,
    },
    hasExportData: {
      type: Boolean,
      default: false,
    },
    showTotalCounters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingTotals: false,
      loadingNotSentTotals: false,
      loadingRunManually: false,
      loadingLog: false,
      totals: null,
      notSentTotals: null,
      items: [],
    };
  },
  mounted() {
    if (this.showTotalCounters) {
      this.fetchTotalsData();
    }
    this.fetchTableData();
  },

  methods: {
    /**
     * Fetch totals data for current view.
     */
    async fetchTotalsData() {
      this.loadingTotals = true;
      try {
        this.totals = await exportLog.fetchExportLogTotals(this.type);
      } catch (error) {
        if (!error.response || (error.response && error.response.status !== 404)) {
          throw error;
        }
      } finally {
        this.loadingTotals = false;
      }
    },

    /**
     * Show not sent totals.
     */
    async showNotSentTotals() {
      this.loadingNotSentTotals = true;
      this.$refs.notSentPopup.show();
      try {
        this.notSentTotals = await exportLog.fetchBillingNotSentTotals();
      } finally {
        this.loadingNotSentTotals = false;
      }
    },

    /**
     * Run the export manually.
     */
    async runManually() {
      this.$refs.confirmationPopup.showConfirm(
        'Run Export?',
        'Do you really want to run export right now?',
        async () => {
          this.loadingRunManually = true;
          try {
            await exportLog.runManually(this.type);
          } finally {
            this.loadingRunManually = false;
          }
        },
      );
    },

    /**
     * Fetch data for current view.
     */
    async fetchTableData() {
      this.loadingLog = true;
      try {
        this.items = await exportLog.fetchExportLog(this.type, 10);
      } catch (error) {
        if (!error.response || (error.response && error.response.status !== 404)) {
          throw error;
        }
      } finally {
        this.loadingLog = false;
      }
    },

    /**
     * Show details by export log.
     *
     * @param {Number} logId - Log identifier
     */
    showDetails(logId) {
      this.$refs.exportDetails.show(logId);
    },
  },
};
</script>

<style lang="scss" module>
.table-title {
  font-size: 22px;
}
</style>
