import importedIncidents from '@/api/importedIncidents';
import i18n from '@/lang/i18n';

import {
  SET_INCIDENTS_ITEMS,
  SET_ACTIVE_TAB,
  SET_UNITS,
  SET_UNITS_LOADING,
  SET_UNITS_PAGINATION,
  SET_UNITS_FILTER,
  SET_CURRENT_INCIDENT,
} from './mutations';

import { SET_TITLE } from '../mutations';

export const ADMIN_LOAD_INCIDENTS = 'ADMIN_LOAD_INCIDENTS';
export const CLIENT_LOAD_INCIDENTS = 'CLIENT_LOAD_INCIDENTS';
export const SHOW_INCIDENTS_TAB = 'SHOW_INCIDENTS_TAB';
export const SHOW_UNITS_TAB = 'SHOW_UNITS_TAB';
export const ADMIN_LOAD_UNITS = 'ADMIN_LOAD_UNITS';
export const CLIENT_LOAD_UNITS = 'CLIENT_LOAD_UNITS';
export const CREATE = 'CREATE';
export default {
  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Incidents state.
   *
   * @return Promise<Object>
   */
  [ADMIN_LOAD_INCIDENTS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await importedIncidents.adminFetchList(params);
    commit(SET_INCIDENTS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Incidents state.
   *
   * @return Promise<Object>
   */
  [CLIENT_LOAD_INCIDENTS]: async ({ commit, state }) => {
    const { pagination, filters } = state;

    const params = {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      order_by: pagination.sortBy[0],
      sort_order: pagination.sortDesc[0] ? 'desc' : 'asc',
      ...filters,
    };

    const results = await importedIncidents.clientFetchList(params);
    commit(SET_INCIDENTS_ITEMS, {
      items: results.results,
      totalItems: results.pagination.total,
    });

    return results;
  },

  /**
   * Show Incidents tab
   *
   * @param {Function} commit - State commit.
   */
  [SHOW_INCIDENTS_TAB]: ({ commit }) => {
    commit(SET_ACTIVE_TAB, 0);
    commit(SET_TITLE, i18n.t('importedIncidents.header'), { root: true });
  },

  /**
   * Show Incident units tab and load data.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Incidents state.
   * @param {Object} incident - Incident data.
   */
  [SHOW_UNITS_TAB]: ({ commit, state }, incident) => {
    const { paginationUnits } = state;
    paginationUnits.page = 1;
    const filters = {
      clientId: incident.clientId,
      incidentNumber: incident.IncidentNumber,
    };
    commit(SET_CURRENT_INCIDENT, incident);
    commit(SET_UNITS_FILTER, filters);
    commit(SET_UNITS_PAGINATION, paginationUnits);
    commit(SET_UNITS, {
      units: [],
      totalItems: 0,
    });
    commit(SET_ACTIVE_TAB, 1);
    commit(SET_TITLE, i18n.t('importedIncidents.unitsHeader'), { root: true });
  },

  /**
   * Load Incident units.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Incidents state.
   * @param {Object} data - Payload data.
   */
  [ADMIN_LOAD_UNITS]: async ({ commit, state }) => {
    const { paginationUnits, unitsFilters } = state;
    if (unitsFilters.clientId && unitsFilters.incidentNumber) {
      const params = {
        page: paginationUnits.page,
        per_page: paginationUnits.itemsPerPage,
        order_by: paginationUnits.sortBy[0],
        sort_order: paginationUnits.sortDesc[0] ? 'desc' : 'asc',
        clientId: unitsFilters.clientId,
        incidentNumber: unitsFilters.incidentNumber,
      };

      commit(SET_UNITS_LOADING, true);
      try {
        const results = await importedIncidents.adminFetchUnits(params);
        commit(SET_UNITS, {
          units: results.results,
          totalItems: results.pagination.total,
        });
      } finally {
        commit(SET_UNITS_LOADING, false);
      }
    }
  },

  /**
   * Load Incident units.
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - Incidents state.
   * @param {Object} data - Payload data.
   */
  [CLIENT_LOAD_UNITS]: async ({ commit, state }) => {
    const { paginationUnits, unitsFilters } = state;
    if (unitsFilters.clientId && unitsFilters.incidentNumber) {
      const params = {
        page: paginationUnits.page,
        per_page: paginationUnits.itemsPerPage,
        order_by: paginationUnits.sortBy[0],
        sort_order: paginationUnits.sortDesc[0] ? 'desc' : 'asc',
        clientId: unitsFilters.clientId,
        incidentNumber: unitsFilters.incidentNumber,
      };

      commit(SET_UNITS_LOADING, true);
      try {
        const results = await importedIncidents.clientFetchUnits(params);
        commit(SET_UNITS, {
          units: results.results,
          totalItems: results.pagination.total,
        });
      } finally {
        commit(SET_UNITS_LOADING, false);
      }
    }
  },
};
