<template>
  <div class="hospital-incident-view">
    <v-card
        class="white elevation-3 ma-4"
        :loading="loading"
    >
      <toolbar
          v-if="incident"
          :incident="incident"
          :saving="false"
          :validating="false"
          :validation-supports="false"
          :validated="false"
          :downloading="downloading || pdfWaiting"
          :show-flag-btn="false"
          :show-edit-btn="false"
          :show-technical-info="false"
          :edit-mode="false"
          :save-disabled="false"
          @download="downloadPdf(incident.id, 'general', null)"
      />
      <v-divider/>
      <v-row>
        <v-col
            md="3"
            class="pt-0 pb-0"
        >
          <categories
              v-if="categories"
              :value.sync="activeTab"
              :height="containersHeight()"
              :categories="categories"
          />
        </v-col>
        <v-col
            md="5"
            class="pt-0 pb-0 pr-0"
        >
          <v-tabs-items v-model="activeTab">
            <v-tab-item
                v-for="(item, index) in incidentFields"
                v-bind:key="'tab_' + index"
            >
              <div v-if="activeTab === index">
                <view-category-fields
                    :height="containersHeight()"
                    :field-list="item.fields"
                    :incident="incident"
                />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
            md="4"
            class="pt-0 pb-0 pl-0"
        >
          <div
              v-if="incident"
              class="right-container"
              v-bind:style="{ height: containersHeight() + 'px' }"
          >
            <gallery
                v-if="documentItems.length"
                title="Documents"
                icon="mdi-file-document"
                :items="documentItems"
                :check-webp="true"
                class="mb-5"
                @click="showImage"
            />
            <gallery
                v-if="imagesItems.length"
                title="Images"
                icon="mdi-file-image"
                :items="imagesItems"
                :check-webp="true"
                class="mb-5"
                @click="showImage"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <view-incident-popup
        ref="viewIncidentPopup"
        :showFlagBtn="false"
    />
  </div>
</template>

<script>
import hospitalsApi from '@/api/hospitals';
import incidentsApi from '@/api/incidents';
import downloadPdf from '@/mixins/downloadPdf';
import Toolbar from '@/components/IncidentView/Toolbar';
import Categories from '@/components/IncidentView/Categories';
import ViewCategoryFields from '@/components/IncidentView/ViewCategoryFields';
import Gallery from '@/components/IncidentView/Gallery';
import ViewIncidentPopup from '@/components/Incidents/ViewIncidentPopup';

export default {
  name: 'IncidentViewHospital',
  mixins: [downloadPdf],
  components: {
    Toolbar,
    Categories,
    ViewCategoryFields,
    Gallery,
    ViewIncidentPopup,
  },
  data() {
    return {
      loading: false,
      incident: null,
      categories: null,
      incidentFields: null,
      activeTab: 0,
      windowHeight: window.innerHeight,
      indentHeight: 200,
    };
  },

  async mounted() {
    await this.loadData();
  },
  created() {
    window.addEventListener('resize', this.recalculateHeights);
  },
  destroyed() {
    window.removeEventListener('resize', this.recalculateHeights);
  },

  computed: {
    /**
     * Get list of page images
     *
     * @return {Array}
     */
    documentItems() {
      const items = [];

      if (this.incident && this.incident.images) {
        this.incident.images.forEach((item, index) => {
          if (item.type === 'page') {
            items.push({
              id: index,
              src: item.thumbnail_url,
            });
          }
        });
      }

      return items;
    },

    /**
     * Get list of images
     *
     * @return {Array}
     */
    imagesItems() {
      const items = [];

      if (this.incident && this.incident.images) {
        this.incident.images.forEach((item, index) => {
          if (item.type !== 'page') {
            items.push({
              id: index,
              src: item.url,
            });
          }
        });
      }

      return items;
    },
  },

  methods: {
    async loadData() {
      try {
        this.loading = true;
        const incidentParams = { include: 'data' };
        this.incident = await incidentsApi
          .fetchIncident(this.$route.params.id, incidentParams);
        incidentsApi.viewLog(this.$route.params.id);

        this.incidentFields = await hospitalsApi.fetchHospitalIncidentFields(this.$route.params.id);
        this.categories = this.incidentFields.map(item => item.categoryName);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Calculates height of containers.
     *
     * @return {Number}
     */
    containersHeight() {
      return this.windowHeight - this.indentHeight;
    },

    /**
     * Show page or image, opens view incident popup.
     */
    showImage(id) {
      this.$refs.viewIncidentPopup.show(this.incident, false, true, false, id, null, null, true);
    },

    /**
     * Recalculate height of window, handled by change page size.
     */
    recalculateHeights() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
.right-container {
  background-color: #f5f5f6;
  border-left: 1px solid #e1e1e3;
  padding: 20px;
  overflow-y: scroll;
}
</style>
