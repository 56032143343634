<template>
  <div>
    <v-toolbar
      flat
      color="white"
    >
      <h3>{{ $t('profileSettings.security.securityIPTitle') }}</h3>
      <v-spacer></v-spacer>
      <v-btn
          color="error"
          @click="$emit('edit')"
      >{{ $t('buttons.add') }}
      </v-btn>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        :loading="loading"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            color="grey darken-2"
            class="mr-3"
            @click="$emit('edit', item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            color="grey darken-2"
            @click="$emit('delete', item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: 'RestrictionByIpTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        {
          text: 'IP - address',
          value: 'net',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
    };
  },
};
</script>
<style lang="scss" module>
.row {
  display: flex;
  align-items: center;
}
</style>
