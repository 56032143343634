<template>
  <v-card
      :loading="loading"
      rounded="lg"
      light
      :min-height="height"
  >
    <v-toolbar flat>
      <h2>{{ title }}</h2>
      <v-spacer />
    </v-toolbar>

    <v-card-text class="text-center">
      APOT-2 identifies five consecutive time intervals into which transports can be grouped.
      These time intervals are: Less than 10 minutes, between 10 minutes and 20 minutes,
      between 20 minutes and 30 minutes, 30 minutes to 40 minutes, and greater than 40 minutes.
    </v-card-text>

    <vue-apex-charts
        v-if="!loading && series[0].data.length"
        type="bar"
        :height="chartHeight"
        :options="options"
        :series="series || []"
    />
    <div
        v-if="!loading && !series[0].data.length"
        class="text-center"
        v-bind:style="{ marginTop: chartHeight/2-40 + 'px' }"
    >
      No data to show
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';
import dateRangeFormat from './dateRangeFormat';

export default {
  name: 'ApotSecond',
  components: {
    VueApexCharts,
  },
  mixins: [dateRangeFormat],
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    dateRange: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            tools: {
              download: true,
            },
            export: {
              csv: {
                filename: 'apot_2',
                headerCategory: 'Interval',
              },
              svg: {
                filename: 'apot_2',
              },
              png: {
                filename: 'apot_2',
              },
            },
          },
        },
        title: {
          text: '',
          align: 'center',
        },
        subtitle: {
          text: '',
          align: 'center',
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
          formatter: (val) => {
            const percentage = (val * 100) / this.data.total;
            const roundedPercentage = percentage.toFixed(2);
            return [
              `${roundedPercentage}%`,
              `Incidents: ${this.formatNumber(val)}`,
            ];
          },
        },
        xaxis: {
          categories: [],
          position: 'bottom',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          title: {
            text: 'Number of Records',
          },
        },
      },
    };
  },
  computed: {
    series() {
      const series = [];
      const data = this.data.series.map((item) => item.count);
      series.push({
        name: 'Count',
        data,
      });
      return series;
    },

    chartHeight() {
      return this.height - 200;
    },

    options() {
      const options = _.cloneDeep(this.chartOptions);
      options.title.text = 'Count and Percent of Offload Delays by APOT-2'
          + `${this.dateRange ? ` ${this.dateRangeFormat(this.dateRange)}` : ''}`;

      options.subtitle.text = `(total transports = ${this.formatNumber(this.data.total)})`;
      options.xaxis.categories = this.data.series.map((item) => item.key);
      return options;
    },
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>
