<template>
  <div>
    <v-data-table
      :headers="$t('hospitals.tableHeaders')"
      :items="hospitals"
      :loading="loading"
      :options.sync="pagination"
      :server-items-length="paginationInfo.total"
      :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
      :height="tableHeight"
      fixed-header
    >
      <template v-slot:body="{ items }">
        <tbody>
          <v-hover
            v-for="item in items"
            :key="item.registryId"
          >
            <tr
              slot-scope="{ hover }"
              :class="{'deleted': item.deleted}"
            >
              <td>
                <a
                  v-if="!item.deleted"
                  @click="impersonate(item.registryID)"
                >{{ item.name }}</a>
                <span v-if="item.deleted">{{ item.name }}</span>
              </td>
              <td>{{ item.zip }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td class="actions text-right no-wrap">
                <v-icon
                  v-show="hover"
                  color="grey darken-2"
                  class="mr-3"
                  @click="showEdit(item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="!item.deleted"
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showDelete(item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-else
                  v-show="hover"
                  class="mr-3"
                  color="grey darken-2"
                  @click="showRestore(item)"
                >
                  restore
                </v-icon>
              </td>
            </tr>
          </v-hover>
      </tbody></template>
    </v-data-table>
    <confirmation
      ref="confirmationPopup"
      @confirmed="HOSPITALS_SEARCH()"
    />
    <confirmation
        ref="createAdminConfirmationPopup"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import hospitalsApi from '@/api/hospitals';
import Confirmation from '@/components/Confirmation';
import { HOSPITALS_SEARCH, OPEN_HOSPITAL_EDIT } from '@/store/Hospitals/actions';
import { SET_HOSPITALS_PAGINATION } from '@/store/Hospitals/mutations';
import i18n from '@/lang/i18n';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'HospitalsSearchTable',
  components: {
    Confirmation,
  },
  mixins: [fixedHeader],
  data() {
    return {
      dialog: false,
      dialogEdit: false,
      method: null,
      popup: {},
    };
  },
  computed: {
    ...mapState('hospitals', [
      'hospitals',
      'search',
      'loading',
      'paginationInfo',
    ]),
    editHospitalId: () => null,
    pagination: {
      get() {
        return this.$store.state.hospitals.pagination;
      },
      set(value) {
        this.$store.commit(`hospitals/${SET_HOSPITALS_PAGINATION}`, value);
      },
    },
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.updateTableDebounce();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.updateTableDebounce();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateTableDebounce();
  },
  methods: {
    ...mapActions({
      HOSPITALS_SEARCH: `hospitals/${HOSPITALS_SEARCH}`,
      showEdit: `hospitals/${OPEN_HOSPITAL_EDIT}`,
    }),

    /**
     * Show delete confirmation popup.
     *
     * @param {Object} hospital - Hospital object.
     */
    showDelete(hospital) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('hospitals.deleteConfirmationTitle'),
        i18n.t('hospitals.deleteConfirmationText', hospital),
        async () => {
          await hospitalsApi.deleteHospital(hospital.registryID);
        },
      );
    },
    /**
     * Show restore confirmation popup.
     *
     * @param {Object} hospital - Hospital object.
     */
    showRestore(hospital) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('hospitals.restoreConfirmationTitle'),
        i18n.t('hospitals.restoreConfirmationText', hospital),
        async () => {
          await hospitalsApi.restoreHospital(hospital.registryID);
        },
      );
    },

    /**
     * Debounced search hospitals by name.
     */
    /* eslint func-names:0 */
    updateTableDebounce: _.debounce(function () {
      this[HOSPITALS_SEARCH]();
    }, 500),

    /**
     * Open impersonate hospital page.
     *
     * @param {Number} hospitalID - Identifier of hospital.
     * @param {Boolean} createAdmin - Is there a need to create admin before impersonate
     */
    async impersonate(hospitalID, createAdmin = false) {
      try {
        const res = await hospitalsApi.getImpersonateUrl(hospitalID, createAdmin);
        const win = window.open(res.url, '_blank');
        if (win) {
          win.focus();
        }
      } catch (error) {
        if (error.response.data.errorCode === 'NO_HOSPITAL_ADMIN_TO_IMPERSONATE') {
          this.$refs.confirmationPopup.showConfirm(
            'There is no hospital admin',
            'Do you want to create your own admin to impersonate?',
            async () => {
              this.impersonate(hospitalID, true);
            },
          );
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.deleted {
  background-color: $red-light;
}

.actions {
  width: 20%;
}
</style>
